import * as React from 'react';
import { prefixClassName } from '../_conf';
import { Button } from '../FormComponents/Button';
import './styles.scss';

interface BoxProps {
  className?: string;
  contentClassName?: string;
  canDelete?: boolean;
  onDelete?: () => void;
}

const boxClx = prefixClassName('box');

const Box = ({
  children,
  className = '',
  contentClassName = '',
  canDelete = false,
  onDelete = () => {},
}: React.PropsWithChildren<BoxProps>) => (
  <div className={`${boxClx} ${canDelete ? `${boxClx}--can-delete` : ''} ${className}`}>
    <div className={`${boxClx}__content ${contentClassName}`}>{children}</div>
    {canDelete && (
      <Button
        className={`${boxClx}__delete`}
        icon="trash"
        onClick={onDelete}
      />
    )}
  </div>
);

export { Box };
