import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import './_styles.scss';

interface IStencilDataGrid {
  hasTop: boolean;
  hasBottom: boolean;
  rows: number;
}

const clx = prefixClassName('stencil__dg');

const Masker = () => <div className={`${clx}__masker`} />;
const Row = () => (
  <div className={`${clx}__row`}>
    <div>
      <Masker />
      <Masker />
      <Masker />
      <Masker />
      <Masker />
    </div>
  </div>
);

export const StencilDataGrid = ({ hasTop = true, hasBottom = true, rows = 10 }: IStencilDataGrid) => (
  <div className={clx}>
    {hasTop && (
      <div className={`${clx}--top`}>
        <Masker />
        <Masker />
        <Masker />
      </div>
    )}
    <div className={`${clx}--center`}>
      {[...Array(rows)].map((_x, i) => (
        <Row key={i} />
      ))}
    </div>
    {hasBottom && (
      <div className={`${clx}--bottom`}>
        <Masker />
      </div>
    )}
  </div>
);

export default StencilDataGrid;
