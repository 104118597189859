import { Store, Reducer, combineReducers } from 'redux';
import { ReduxActionType, RSA } from 'types';

type RSAReducer<S, T> = Reducer<Readonly<S>, RSA<T>>;

type ReducerMapping<S, T> = {
  [type in ReduxActionType]: RSAReducer<S, T>;
};

export function registerReducerCreator(store: Store, existingReducers: object) {
  const DELETE_STATE_NODE_ACTION = '__registerReducerCreator__/DELETE_STATE_NODE_ACTION';

  const reducersManager = {
    ...existingReducers,
  };
  let combinedReducers = combineReducers(existingReducers);

  const rootReducer = (state, action) => {
    // this action is fired upon unregisting a reducer
    if (action.type === DELETE_STATE_NODE_ACTION) {
      const newState = {
        ...state,
      };
      // erases the state for the unregistered reducer
      newState[action.payload.node] = undefined;
      delete newState[action.payload.node];

      return newState;
    }

    // all other reducers
    return combinedReducers(state, action);
  };

  // sets the new root reducer
  store.replaceReducer(rootReducer);

  return function createReducer<S extends {}>(storeNode: string) {
    const reducers: ReducerMapping<S, any> = {};

    return {
      /**
       * Returns an action dispatcher bound to this reducer function
       * @param type redux action type
       * @param reducerFn reducer function to reduce this action
       */
      reduce: function reduceType<P>(type: ReduxActionType, reducerFn: RSAReducer<S, P>) {
        // https://redux.js.org/style-guide/style-guide#model-actions-as-events-not-setters
        // https://redux.js.org/style-guide/style-guide#write-action-types-as-domain-eventname
        const namespacedType = `${storeNode}/${type as string}`;

        // assigns the reducer function with its type
        // [type as string] because of this bug: https://github.com/Microsoft/TypeScript/issues/24587
        reducers[namespacedType as string] = reducerFn;

        return (payload: P, error?: boolean, meta?: any) => store.dispatch<RSA<P>>({
          type: namespacedType,
          payload,
          error,
          meta,
        });
      },
      register: (initialState: S) => {
        const reducer = (state: Readonly<S> = initialState, action: RSA<any>) => {
          // if a reducer for this action is defined, runs it
          if (reducers[action.type as string]) {
            return reducers[action.type as string](state, action);
          }
          // else just returns the state
          return state;
        };

        reducersManager[storeNode] = reducer;

        combinedReducers = combineReducers(reducersManager);
      },
      unregister: () => {
        // cleans up the sate
        store.dispatch<RSA<{ node: string }>>({
          type: DELETE_STATE_NODE_ACTION, payload: { node: storeNode },
        });
        // recombines the reducers
        reducersManager[storeNode] = undefined;
        delete reducersManager[storeNode];

        combinedReducers = combineReducers(reducersManager);
      },
    };
  };
}
