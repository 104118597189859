import { store, autoEffect } from '@risingstack/react-easy-state';
import type { User } from 'oidc-client';
import { start, save, get } from 'framework/services/localStorage/localStorage';
import i18n from 'i18n';
import { ACTIVE_FULFILLMENT_CENTER, LOCAL_USER_SETTINGS } from 'config';
import { RiskSettings, pickingTargets } from 'framework/modules/orderFulfillment/pages/Settings/interfaces';
import { TimeFormats } from 'framework/components/globalhooks';
import {
  Barcodes,
  BarcodeSourceTypes
} from 'framework/modules/orderFulfillment/pages/MPSSettings/pageParts/MPSSetup.interface';
import { toastWarning } from './toast';

export interface FulfillmentCenter {
  id: string;
  name: string;
  expired: boolean;
  timeZone: string;
  storeIds?: string[];
  pickingType?: string;
  pickingTargetSettings?: pickingTargets;
  ordersAtRiskSettings?: RiskSettings;
  productLocationSchemaId?: string;
  maxItemsPerWaveForImmediatePickPriorityOrders?: number;
  maxOrdersPerWaveForImmediatePickPriorityOrders?: number;
  stripNonPriceEmbeddedBarcodesEnabled?: boolean;
  isPiiCustomerAddressHidden?: boolean;
  hidePiiCustomerAddressSetting?: boolean;
  isPiiCustomerPhoneHidden?: boolean;
  hidePiiCustomerPhoneSetting?: boolean;
  embeddedPriceBarcodePatterns?: Barcodes[],
  scanToToteEnabled?: boolean;
  externalReference?: string;
  pickUnitConversionEnabled?: boolean;
  pickingUnitType?: string;
  conversionUnitType?: string;
  allowUnrecognizedItems?: boolean;
  allowAddOnsFromMps?: boolean;
  activeTide?: string;
  printerConfiguration?: any;
  isSubstitutionPriceWarningDisabled?: boolean;
  mpsLabelBarcodeSource?: BarcodeSourceTypes;
  acknowledgeItemLevelNotes?: boolean;
  acknowledgeOrderLevelNotes?: boolean;
}

export interface UserSettings {
  timeFormat?: TimeFormats;
  convertedTimeFormat?: string;
  dateFormat?: string;
  dateTimeFormat?: string;
  dateTimeFormatOf?: string;
  timeSlotDateTimeFormat?: string;
}

interface AppState {
  /**
   * current browser tab is active (focused/being used)
   */
  isTabActive: boolean;
  isOnline: boolean;
  user: User | undefined;
  userSettings?: UserSettings | undefined;
  canFetchData: boolean;
  fulfillmentCenter: FulfillmentCenter | undefined;
}

const local = store<AppState>({
  isTabActive: !document.hidden,
  isOnline: navigator.onLine,
  user: undefined,
  userSettings: {
    dateTimeFormat: 'HH:mm - MMM, d yyyy',
  },
  canFetchData: true,
  fulfillmentCenter: undefined, // get from localStorage
});

export const appState: Readonly<AppState> = local;

export const setUser = (user: User | undefined) => {
  // gets the fulfillment center from localStorage for the user
  start(user?.profile?.email);
  const savedFC = get<FulfillmentCenter>(ACTIVE_FULFILLMENT_CENTER, true) || {
    id: null, name: null, expired: null, timeZone: '',
  };

  local.user = user;
  local.fulfillmentCenter = savedFC;
};

export const setUserSettings = (userSettings: UserSettings, skipLocalStorage: boolean = false) => {
  local.userSettings = userSettings;

  // Update the user settings in local storage
  if (!skipLocalStorage) {
    save(LOCAL_USER_SETTINGS, userSettings, null, true);
  }
};

export const setFulfillmentCenter = (fc: FulfillmentCenter | undefined, skipLocalStorage: boolean = false) => {
  // Handle loading fc data issues
  if (fc && Object.keys(fc)?.length === 0) return;

  local.fulfillmentCenter = fc;

  // updates localStorage
  if (!skipLocalStorage) {
    save(ACTIVE_FULFILLMENT_CENTER, fc, null, true);
  }
};
export const setDefaultFulfillmentCenter = (fcList: FulfillmentCenter[], prevFC?: string) => {
  setFulfillmentCenter({ ...fcList[0] });
  if (prevFC && prevFC !== '') {
    toastWarning(i18n.t('modules.orderFulfillment.expiredFC', { expired: prevFC, new: fcList[0].name }), 10000);
  }
  if (!prevFC) {
    toastWarning(i18n.t('modules.orderFulfillment.nullFC', { new: fcList[0].name }), 10000);
  }
};

// Effects
document.addEventListener('visibilitychange', () => {
  local.isTabActive = !document.hidden;
});

const updateOnlineStatus = () => {
  local.isOnline = navigator.onLine;
};

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

// updates canFetchData
autoEffect(() => {
  local.canFetchData = !!local.user && local.isOnline && local.isTabActive;
}, [local.isOnline, local.isTabActive, local.user]);
