/* eslint-disable no-nested-ternary */
import { i18n } from 'i18n';
import * as React from 'react';
import { Button, Table } from 'ui';

export const columnsMetaData = () => [
  {
    caption: '',
    customRenderer: (data) => <Table.Column>{data?.checkbox}</Table.Column>
  },
  {
    caption: 'generic.tableColumnsModal.columnLabel',
    customRenderer: (data) => <Table.Column className={data?.className}>{i18n.t(data?.label).toString()}</Table.Column>
  },
  {
    caption: '',
    customRenderer: (data) => <Table.ColumnStatus value={data?.hidden ? 'Hidden' : ''} />
  },
  {
    caption: '',
    customRenderer: () => <></>
  },
];

interface DraggableRowProps {
  data: { id: string; label: string; uniqueId: string; caption: string; hiddenClassName: string; };
  index: number;
  editSequence: boolean;
  hiddenClassName: string;
  lockButtonClassName: string;
  selectedItems: string[];
  disabledList: string[];
  lockedItems: string[];
  allowLocking: boolean;
  lockableIndexes: number[];
  hideColumns: (uniqueId: string) => void;
  lockColumns: (uniqueId: string) => void;
}

export const DraggableRow = (props: DraggableRowProps) => {
  const { data, editSequence, index, selectedItems, lockedItems, lockableIndexes,
    hiddenClassName, lockButtonClassName, disabledList, hideColumns, lockColumns, allowLocking } = props;

  const RowComp = editSequence ? Table.DraggableRow : Table.Row;

  const hidden: boolean = selectedItems.includes(`${data?.uniqueId}`) ?? false;
  const locked: boolean = lockedItems?.includes(`${data?.uniqueId}`) ?? false;

  const lockable: boolean =  lockableIndexes.includes(index) && allowLocking;

  return (
    <RowComp
      index={index}
      draggableId={data?.id}
      dragDisabled={locked}
      className={hidden && hiddenClassName}
      allowPortal
    >
      {editSequence && <Table.DraggableCell />}
      <Table.ColumnCheckbox
        checked={!hidden}
        onChange={hideColumns}
        disabled={
          disabledList.includes(i18n.t(data?.caption))
          || disabledList.includes(data?.caption)
          || locked
        }
        key={`TableColumnsFilter_${data?.uniqueId}`}
        name={`${data?.uniqueId}`}
      />
      <Table.Column className={hidden && hiddenClassName}>{i18n.t(data?.label).toString()}</Table.Column>
      <Table.ColumnStatus align="right" value={hidden ? 'Hidden' : locked ? 'Locked column' : ''} />
      <Table.Column align="right">
        {
          lockable
            && (<Button
              caption={locked ? 'generic.tableColumnsModal.unlockColumn' : 'generic.tableColumnsModal.lockColumn'}
              className={lockButtonClassName}
              link
              testId={`lock-${data.uniqueId}`}
              name={`lock-btn-${data.uniqueId}`}
              onClick={() => lockColumns(data?.uniqueId)}
            />)
        }
      </Table.Column>
    </RowComp>
  );
};