import Authorization from './Authorization';
import {
  selectAccessToken as slctAccesToken,
  selectProfile,
  selectIsLoadingUser,
} from './Authorization.Selectors';
import { NoPermissionPage } from './NoPermissionPage';
import { AuthorizedRoute } from './AuthorizedRoute';

export {
  NoPermissionPage,
  AuthorizedRoute,
  selectProfile,
  selectIsLoadingUser,
};
export const selectAccessToken = (state): string => slctAccesToken(state);
export default Authorization;
export * from './Interfaces';
export * from './useAuthUser';
