import * as React from 'react';
import * as classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import './_style.scss';

interface ILabel {
  children: any;
  type?: string;
}

export class LabelWrapper extends React.PureComponent<ILabel> {
  render() {
    const clx = prefixClassName('grid-score__label');

    return (
      <div className={classnames(clx, this.props.type && `${clx}--${this.props.type}`)}>{this.props.children}</div>
    );
  }
}

export default LabelWrapper;
