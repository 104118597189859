import { call, put } from 'redux-saga/effects';
import { config } from 'config';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { apiClientActions } from 'framework/actions';
import { makeRequest } from 'framework/api/make-request';
import {
  ACTION_RULES_FETCH_RULE_DATA,
  DEFAULT_LOCALE_ID,
  DEFAULT_STORE_ID,
  API_DATA_RULE_DATA,
} from 'framework/modules/searchadmin/constants';
import { transformServerDataIntoFormData } from './helpers';

export function* process({ payload }) {
  const { ruleId } = payload; // In future, this will come from somewhere else
  const localeId = DEFAULT_LOCALE_ID;
  const storeId = DEFAULT_STORE_ID;

  // 1. Request Data
  const { data: responseData = {} } = yield call(makeRequest, {
    name: API_DATA_RULE_DATA,
    method: 'GET',
    url: `${config.apiEndpoints.search}/locale/${localeId}/store/${storeId}/rules/${ruleId}`,
  });

  // 2. Format Data
  // TODO: see how this can be improved,
  const ruleData = transformServerDataIntoFormData(responseData.rule || {});

  // 3. Save Data on State
  yield put(
    apiClientActions.createApiClientStoreRequestAction({
      data: ruleData || undefined,
      name: API_DATA_RULE_DATA,
      hasError: !!responseData.hasError,
      isFetching: false,
      hasResponse: !!responseData.rule,
      // spread response until final shape of response is established
      ...responseData,
    }),
  );
}

export default function* watchSaveRuleAction() {
  yield takeEveryWithProgressBar(ACTION_RULES_FETCH_RULE_DATA, process);
}
