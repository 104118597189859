import { put } from 'redux-saga/effects';
import { apiClientActions, formActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import * as CONST from 'framework/modules/searchadmin/constants';

export default function* watchMerchandisingRelevanceReset() {
  yield takeEveryWithProgressBar(CONST.ACTION_RELEVANCE_ATTRIBUTES_RESET, process);
}

function* process() {
  // 1. Reset Attributes
  yield put(formActions.reset(CONST.FORM_RELEVANCE_RELEVANCE_ATT));

  // 2. Hidde Preview DataGrid
  yield put(
    apiClientActions.clearApiClientStateAction({
      name: CONST.API_DATA_PROVIDER_RELEVANCE_PREVIEW
    })
  );
}
