import {
  ACTION_LISTING_DELETE,
  ACTION_LISTING_UPDATE_STATE,
} from 'framework/modules/merchandising/constants';

export const deleteListingAction = (id: string) => ({
  type: ACTION_LISTING_DELETE,
  payload: { id },
});

export const updateListingStateAction = (id: string, status: string) => ({
  type: ACTION_LISTING_UPDATE_STATE,
  payload: { id, status },
});
