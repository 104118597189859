import * as React from 'react';
import i18n from 'i18n';
import { FilterBar } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { HierarchyFiltersProps, DefaultHierarchyFilterProps } from './Interfaces';
import { DefaultFilterName } from './constants';
import './style.scss';

const rootCls = prefixClassName('hierarchy--filters');

export const DefaultHierarchyFilter = ({ onFilter }: DefaultHierarchyFilterProps) => (
  <FilterBar.Input
    placeholder={i18n.t('modules.stores.filterByStoreName')}
    name={DefaultFilterName}
    testId="searchFilter"
    onFilter={onFilter}
  />
);

export const HierarchyFilters = ({
  onFilterChange,
  filterInitialValues = {},
  formFilterName,
  filterComponents = DefaultHierarchyFilter,
  stretch = 'panel',
  topBorder = 'top-border',
}: HierarchyFiltersProps) => {
  const FilterComponents: any = filterComponents;
  return (
    <FilterBar
      name={formFilterName}
      initialValues={filterInitialValues}
      className={`${rootCls} ${rootCls}--${topBorder}`}
      stretch={stretch}
    >
      <FilterComponents onFilter={onFilterChange} />
    </FilterBar>
  );
};
