import * as React from 'react';
import { connect } from 'react-redux';
import {
  createApiClientMakeRequestAction,
  createApiClientDeleteAction,
} from 'framework/api/apiClient/actions';
import { Props, MapperProps } from '../ApiMakeRequest/ApiMakeRequest.interfaces';
import Content from '../ApiMakeRequest/ApiMakeRequest';

/**
 * @deprecated Use ApiMakeRequest instead
 * ApiMakeRequest and ApiDataProvider use the same logic, but store the data in different way
 */
const mapDispatchToProps = (dispatch, ownProps): MapperProps => ({
  makeRequestAction: (requestDetails) => {
    dispatch(createApiClientMakeRequestAction(requestDetails));
  },
  deleteApiData: () => {
    dispatch(createApiClientDeleteAction({ name: ownProps.name }));
  },
});

const ApiDataProvider = (connect(
  null,
  mapDispatchToProps,
)(Content)) as React.StatelessComponent<Props>;

export default ApiDataProvider;
export { ApiDataProvider };
