import { Uuid } from 'framework/utils/generateId';
import {
  RecommendationDetails,
  RecommendationStatus,
} from 'framework/modules/merchandising/models';
import * as CONST from '../../constants';

export const recommendationDefault: RecommendationDetails = {
  id: Uuid(),
  name: '',
  stores: [],
  priority: 1,
  startDate: null,
  endDate: null,
  locations: [],
  actions: [],
  conditions: [],
  status: RecommendationStatus.Active,
  resultsCount: 1,
  randomize: true,
};

const getFieldValue = (field) => field?.value || field;

export const formatRuleConditions = (conditions = []) => conditions.map((condition) => ({
  [CONST.FIELD_CONDITIONS_RULE]: getFieldValue(condition[CONST.FIELD_CONDITIONS_RULE]),
  [CONST.FIELD_CONDITIONS_PROPERTY]: getFieldValue(condition[CONST.FIELD_CONDITIONS_PROPERTY]),
  [CONST.FIELD_CONDITIONS_VALUE]: condition?.[CONST.FIELD_ACTIONS_VALUE].map((c) => getFieldValue(c))
    || [].map((c) => getFieldValue(c)),
}));

export const formatRuleActions = (actions = []) => actions.map((action) => ({
  [CONST.FIELD_ACTIONS_DISPLAY]: action.resultsCount || 1,
  [CONST.FIELD_FILTERS]: (action?.[CONST.FIELD_FILTERS] || []).map((filter) => ({
    [CONST.FIELD_FILTERS_RULE]: getFieldValue(filter[CONST.FIELD_FILTERS_RULE]),
    [CONST.FIELD_FILTERS_PROPERTY]: getFieldValue(filter[CONST.FIELD_FILTERS_PROPERTY]),
    [CONST.FIELD_FILTERS_VALUE]: (filter?.[CONST.FIELD_FILTERS_VALUE] || []).map((v) => getFieldValue(v)),
  })),
  [CONST.FIELD_ACTIONS_RULE]: getFieldValue(action[CONST.FIELD_ACTIONS_RULE]),
  [CONST.FIELD_ACTIONS_PROPERTY]: getFieldValue(action[CONST.FIELD_ACTIONS_PROPERTY]),
  // Handle single select actions value
  [CONST.FIELD_ACTIONS_VALUE]: action[CONST.FIELD_ACTIONS_VALUE] ? (action[CONST.FIELD_ACTIONS_VALUE].value
    ? [action[CONST.FIELD_ACTIONS_VALUE]]
    : action[CONST.FIELD_ACTIONS_VALUE])?.map((c) => getFieldValue(c)) : [],
  [CONST.FIELD_ACTIONS_SORT]: getFieldValue(action[CONST.FIELD_ACTIONS_SORT]),
  [CONST.FIELD_ACTIONS_PARAMETERS]: action[CONST.FIELD_ACTIONS_PARAMETERS],
  [CONST.FIELD_TAGS]: action[CONST.FIELD_TAGS]
}));
