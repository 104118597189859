import { objectHelper } from 'framework/utils';
import { formSelectors, apiClientSelectors } from 'framework/selectors';
import * as CONST from 'framework/modules/searchadmin/constants';
import * as helpers from '../helpers';

export const getFormValues = (state) => formSelectors.getFormValues(state, CONST.FORM_RULES_CREATE_RULES);
export const getIsFormValid = (state) => formSelectors.getIsFormValid(state, CONST.FORM_RULES_CREATE_RULES);
export const getRuleData = (state) => apiClientSelectors.selectApiClientData(state, CONST.API_DATA_RULE_DATA);

export const getSelectedTerms = (state) => helpers.getSelectedTerms(getFormValues(state));
export const getSelectedStores = (state) => {
  const apiClientState = apiClientSelectors.selectApiClientState(
    state,
    CONST.API_DATA_RULES_OPTIONS,
  );

  const stores = apiClientState.stores || [];
  const storesSelected = helpers.getSelectedStores(getFormValues(state));

  // If we only have the id for an store, find the corresponding object
  if (storesSelected.length > 0) {
    return storesSelected.map((st) => {
      if (typeof st === 'string' || st instanceof String) {
        return stores.find((s) => s.value === st);
      }
      return st;
    });
  }

  return stores;
};

export const getProductsCurrent = (state) => getProducts(state, CONST.API_DATA_RULE_CURRENT_DATA);
export const getProductsPreview = (state) => getProducts(state, CONST.API_DATA_RULE_PREVIEW_DATA);

const getProducts = (state, dataProvider: string) => {
  const productsData = apiClientSelectors.selectApiClientData(state, dataProvider);
  const isFetching = apiClientSelectors.getIsFetching(state, dataProvider);

  // We need to create defautls like this for preview, otherwise we get undefined here and there
  const previewDefaultData = {
    total: 0,
    items: [],
    isFetching,
  };

  return !objectHelper.isEmpty(productsData) ? { ...productsData, isFetching } : previewDefaultData;
};
