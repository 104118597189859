import * as React from 'react';
import * as clx from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Icon, Loader } from 'framework/components/ui';
import i18n from 'i18n';
import { CmsDamFolder, DamMode } from './Types';
import { translations } from './translations';

const componentClass = prefixClassName('dam');
const marginLevel = 40;

interface CmsDamFolderViewProps {
  mode: DamMode;
  data: CmsDamFolder[];
  expandedFolders: string[];
  highlightedFolder: string;
  toggleExpandFolder: (folderName: string) => void;
  toggleHighlight: (folderName: string) => void;
}

interface FolderValueProps {
  level: number;
  folder: CmsDamFolder;
  expanded: boolean;
  highlightedFolder: string;
  toggleExpand: (reference: string) => void;
  toggleHighlight: (reference: string) => void;
}

export const DamContentHierarchy = (props: CmsDamFolderViewProps) => {
  const {
    data: folders,
    mode,
    expandedFolders,
    toggleExpandFolder,
    toggleHighlight,
    highlightedFolder,
  } = props;
  const rootFolders = folders.filter(f => !f.parent);
  return (
    <table className={`${componentClass}__treeview`}>
      <thead>
        <tr>
          <th>
            <span>{i18n.t(translations.selectLocation)}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {renderRows({
          level: 0,
          mode,
          allFolders: folders,
          foldersOfLevel: rootFolders,
          highlightedFolder,
          expandedFolders,
          toggleExpandFolder,
          toggleHighlight,
        })}
      </tbody>
    </table>
  );
};

const renderRows = (props: {
  level: number;
  mode: DamMode;
  allFolders: CmsDamFolder[];
  foldersOfLevel: CmsDamFolder[];
  expandedFolders: string[];
  highlightedFolder: string;
  toggleExpandFolder: (reference: string) => void;
  toggleHighlight: (reference: string) => void;
}) => {
  const {
    allFolders,
    toggleExpandFolder,
    expandedFolders,
    level,
    mode,
    foldersOfLevel,
    toggleHighlight,
    highlightedFolder,
  } = props;
  let rows: any[] = [];

  foldersOfLevel.forEach(folder => {
    const expanded = !!expandedFolders.find(r => r === folder.reference);
    const row = (
      <tr key={folder.reference}>
        <td>
          <CmsDamFolderContent
            level={level}
            folder={folder}
            expanded={expanded}
            highlightedFolder={highlightedFolder}
            toggleExpand={toggleExpandFolder}
            toggleHighlight={toggleHighlight}
          />
        </td>
      </tr>
    );
    rows = [...rows, row];
    if (expanded) {
      let subFolders = allFolders.filter(f => f.parent === folder.reference);

      if (mode === 'file') {
        subFolders = subFolders.filter(s => !s.empty);
      }

      const subRows = renderRows({
        level: level + 1,
        mode,
        expandedFolders,
        toggleExpandFolder,
        highlightedFolder,
        toggleHighlight,
        foldersOfLevel: subFolders,
        allFolders,
      });
      rows = [...rows, ...subRows];
    }
  });

  return rows;
};

const CmsDamFolderContent = ({
  folder,
  toggleExpand,
  expanded,
  level,
  toggleHighlight,
  highlightedFolder,
}: FolderValueProps) => {
  const { busy: expanding, empty, reference } = folder;

  const isHighlighted = highlightedFolder === reference;

  const handleHighlight = () => {
    if (!empty) {
      toggleHighlight(reference);
    }
  };

  return (
    <div
      className={clx(
        `${componentClass}__folder-row`,
        isHighlighted && `${componentClass}__folder--highlighted`,
      )}
      style={{
        marginLeft: level * marginLevel,
      }}
    >
      {expanding ? (
        <span className={`${componentClass}__loader`}>
          <Loader size="xs" />
        </span>
      ) : !empty ? (
        <Icon
          className={clx(`${componentClass}__folder-icon`)}
          name="dropdownArrowRight"
          rotate={expanded ? 90 : null}
          onClick={() => toggleExpand(folder.reference)}
        />
      ) : (
        <></>
      )}
      <div
        className={clx(
          `${componentClass}__folder-title`,
          !empty && `${componentClass}__folder-title--default`,
        )}
        onClick={handleHighlight}
      >
        {!empty && (
          <Icon
            className={clx(
              `${componentClass}__folder-icon`,
              isHighlighted && `${componentClass}__folder-icon--highlighted`,
            )}
            name="folder"
          />
        )}
        <span className={clx(empty && `${componentClass}__empty-content`)}>{folder.name}</span>
      </div>
    </div>
  );
};
