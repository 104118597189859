import * as React from 'react';
import * as classnames from 'classnames';
import { Text } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { MessagePrimitive } from 'framework/components/ui/Primitives/MessagePrimitive';
import { IMessage } from './Message.interface';
import './_style.scss';

const clx = prefixClassName('message');

const Message = (props: IMessage) => {
  const {
    bulletPoints = true, caption, className, children,
  } = props;
  const isArray = Array.isArray(caption);
  const message = Array.isArray(caption) ? (
    caption.map((el, index) => (
      <p className={`${clx}__line`} key={index}>
        <Text caption={el} />
      </p>
    ))
  ) : (
    children || <Text caption={caption} />
  );

  return (
    <MessagePrimitive
      {...props as any}
      caption={message}
      className={classnames(
        clx,
        isArray && `${clx}--multiline`,
        isArray && bulletPoints && `${clx}--bulletpoints`,
        className,
      )}
    />
  );
};

export default Message;
