import { CmsLayoutInspector } from '../CmsComponents.interfaces';

export const storeLayoutEditorFormData = ({ rows, rowIndex, columnIndex, inspector, data }) => {
  return rows.map((row, i) => {
    if (rowIndex !== i) {
      return row;
    }
    return {
      ...row,
      columns: row.columns.map((col, j) => {
        if (columnIndex !== j) {
          return col;
        }

        const insp: CmsLayoutInspector = inspector;
        let payload = data;

        if (!insp.wellknown) {
          payload = {
            data: {
              ...payload
            }
          };
        }

        return {
          ...col,
          ...payload
        };
      })
    };
  });
};
