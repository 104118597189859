import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { productsListRoute, productsEditRoute, productsCreateRoute } from './constants';

export const productsRoutes: AppRoute[] = [
  {
    ...productsListRoute,
    Component: () => import('./pages/ProductsList'),
  },
  {
    ...productsCreateRoute,
    Component: () => import('./pages/ProductsEdit'),
  },
  {
    ...productsEditRoute,
    Component: () => import('./pages/ProductsEdit'),
  },
];
