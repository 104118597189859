import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { promotionCodeListRoute } from './PromotionCodes/PromotionCodesList/route';
import { promotionCodeCreateRoute, promotionCodeEditRoute } from './PromotionCodes/PromotionCodesEdit/route';
import { promotionListRoute, promotionEditRoute, promotionCreateRoute } from './Promotions/constants';

export const promotionsRoutes: AppRoute[] = [
  promotionCodeListRoute,
  promotionCodeCreateRoute,
  promotionCodeEditRoute,
  {
    ...promotionListRoute,
    Component: () => import('./Promotions/PromotionsList'),
  },
  {
    ...promotionEditRoute,
    Component: () => import('./Promotions/PromotionsEdit'),
  },
  {
    ...promotionCreateRoute,
    Component: () => import('./Promotions/PromotionsEdit'),
  },
];
