import { fork } from 'redux-saga/effects';
import { watchApiClientMakeRequest } from 'framework/api/apiClient/saga';
import { sagas as apiMakeRequestSagas } from 'framework/components/services/ApiMakeRequest/store';
import watchSetFormErrorsAction from 'framework/components/ui/FormComponents/Form/sagas/setFormErrorsSaga';

// Sagas from specific modules
import searchadminSagas from 'framework/modules/searchadmin/sagas';
import merchandisingSagas from 'framework/modules/merchandising/sagas';
import productsSagas from 'framework/modules/products/sagas';
import orderFulfillmentSagas from 'framework/modules/orderFulfillment/sagas';
import siteAdminSagas from 'framework/modules/siteadmin/sagas';
import slotManagementSagas from 'framework/modules/slotManagement/sagas';
import storesSagas from 'framework/modules/stores/sagas';
import watchContentManagementActions from 'framework/modules/contentmanagement/sagas';
import { registerSagaCreator } from './sagaCreator';

export default [
  watchApiClientMakeRequest(),
  fork(watchSetFormErrorsAction),
  ...searchadminSagas,
  ...merchandisingSagas,
  ...productsSagas,
  ...siteAdminSagas,
  ...slotManagementSagas,
  ...storesSagas,
  ...watchContentManagementActions,
  ...orderFulfillmentSagas,
  ...apiMakeRequestSagas,
];

export { registerSagaCreator };
