import {
  createContext, useContext, useState,
} from 'react';
import { useModal } from 'framework/components/ui/Modal';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'framework/selectors';
import { addProgressBar } from 'framework/components/ui/ProgressBar/useProgressBar';
import {
  FILEMANAGER_MODAL_ADD_FOLDER, FILEMANAGER_MODAL_MOVE_TO_FOLDER,
  FILEMANAGER_DELETE_CONFIRM_MODAL,
} from './constants';
import { FileFolder, FileFolderType } from './types';
import { fetchFolders, formatChildren } from './services';

type Store = {
  loadingReference?: string;
  initializing: boolean;
  data: FileFolder[];
  rowClick: (row: FileFolder, includeFiles?: boolean) => Promise<boolean>;
  fetchSubFolders: (row: FileFolder, includeFiles: boolean) => Promise<void>;
};
export type DeleteFunc = () => Promise<void> | void;

export const useFileManagerContextInternal = (confirmationModalName?: string) => {
  const [previewableFile, setPreviewableFile] = useState<FileFolder>();
  const [activeFolder, setActiveFolder] = useState<FileFolder>();
  const [forRoot, setForRoot] = useState<boolean>();
  const [store, setStore] = useState<Store>();
  const [deleteMode, setDeleteMode] = useState<FileFolderType>();
  const [onDeleteFunc, setOnDeleteFunc] = useState<DeleteFunc>();

  const { openModal: openFolderModal } = useModal(FILEMANAGER_MODAL_ADD_FOLDER);
  const { openModal: openDeleteConfirmModal } = useModal(confirmationModalName || FILEMANAGER_DELETE_CONFIRM_MODAL);

  const handleOpenFolderModal = (root?: boolean) => () => {
    setForRoot(root);
    openFolderModal();
  };

  const rootFolderModalOpen = useSelector((state) => modalSelectors
    .isModalOpen(state, FILEMANAGER_MODAL_ADD_FOLDER));

  const moveFileModalOpen = useSelector((state) => modalSelectors
    .isModalOpen(state, FILEMANAGER_MODAL_MOVE_TO_FOLDER));

  const prompDelete = (mode: FileFolderType, onDelete: DeleteFunc) => () => {
    setDeleteMode(mode);
    setOnDeleteFunc(() => onDelete);
    openDeleteConfirmModal();
  };

  const onFolderCreated = addProgressBar(async () => {
    store.initializing = true;
    await fetchFolders('', true)
      .then((res) => {
        store.data = formatChildren(res.data ?? []);
      }).finally(() => {
        store.initializing = false;
      });
  });

  const onSubFolderCreated = addProgressBar(async () => {
    store.fetchSubFolders(activeFolder, true);
  });

  const onFileMoved = addProgressBar(async () => {
    // setActiveFolder(undefined);
    setPreviewableFile(undefined);
    store.fetchSubFolders(activeFolder, true);
  });

  return {
    store,
    previewableFile,
    rootFolderModalOpen,
    moveFileModalOpen,
    activeFolder,
    forRoot,
    deleteMode,
    onDeleteFunc,
    setStore,
    setPreviewableFile,
    handleOpenFolderModal,
    onFolderCreated,
    setActiveFolder,
    onFileMoved,
    prompDelete,
    onSubFolderCreated,
  };
};

type FileManagerContextInternal = ReturnType<typeof useFileManagerContextInternal>;
export const FileManagerContext = createContext<Partial<FileManagerContextInternal>>({
} as any);

export const useFileManagerContext = () => useContext(FileManagerContext);
