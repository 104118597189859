import * as React from 'react';
import { StorefrontUiTranslations } from 'framework/modules/siteSettings/translations';
import { Form, Modal } from 'ui';
import { IAdminControlType } from '@thryveai/theme-interfaces/dist';
// eslint-disable-next-line import/no-cycle
import JsonUiGenerator from '../JsonUiGenerator';
import { IComponent } from '../../StoreFrontSettings.interfaces';

export const ADD_OPTION_MODAL = 'addOptionModal';
const ADD_OPTION_FORM = 'addOptionForm';

interface AddOptionModalProps {
  component: Omit<IComponent, 'value'> & {
    title: string;
    value: {
      [k: string]: IComponent & {
        type: IAdminControlType;
      };
    };
  };
  settings: any;
  bannerSettingsMap: any;
  level: number;
  id: string;
  editOption?: any;
  handleAddOption: (key: string, newValue: any) => void;
  handleUpdateOption: (newValue: any) => void;
  closeModal: () => void;
}

export const AddOptionModal = (props: AddOptionModalProps) => {
  const { bannerSettingsMap, component, id, editOption, level, settings,
    handleAddOption, handleUpdateOption, closeModal } = props;

  const [newOption, setNewOption] = React.useState<{}>(editOption);

  const handleFieldChange = (fieldName: string, newValue: string) => {
    setNewOption({
      ...newOption,
      [fieldName]: newValue,
    });
  };
  React.useEffect(() => {
    if (editOption) setNewOption(editOption);
  }, [editOption]);

  return (
    <Modal
      name={ADD_OPTION_MODAL}
      caption={StorefrontUiTranslations.AddOption}
      onDismiss={() => {
        setNewOption(null);
        closeModal();
      }}
      footerOptions={[
        {
          caption: !editOption ? StorefrontUiTranslations.AddOption : StorefrontUiTranslations.UpdateOption,
          formToSubmit: ADD_OPTION_FORM,
          onClick: () => {
            if (editOption) {
              handleUpdateOption(newOption);
              closeModal();
              setNewOption(null);

              return;
            }

            handleAddOption(id, newOption);
            setNewOption(null);
            closeModal();
          },
        },
        {
          caption: 'generic.button.reset',
          formToReset: ADD_OPTION_FORM,
          onClick: () => {
            // Reset edit option of editing an option
            if (editOption) setNewOption(editOption);
          },
          outline: true,
        },
        {
          caption: 'generic.button.cancel',
          onClick: () => {
            closeModal();
            setNewOption(null);
          },
          link: true,
        },
      ]}
    >
      <Form
        name={ADD_OPTION_FORM}
        {
          ...editOption
            && ({
              initialValues: {
                ...Object.keys(editOption).reduce((a, k) => ({ ...a, [`${id}-${k}`]: editOption[k] }), {}),
              },
            })
        }
      >
        {
          JsonUiGenerator(
            component.value,
            settings,
            bannerSettingsMap,
            handleFieldChange,
            level,
            id,
            false,
            true,
            true,
            newOption,
          )
        }
      </Form>
    </Modal>
  );
};