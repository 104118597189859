import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ColumnMetadata, StringObjectMetadata } from 'framework/components/ui/Table/Table.interfaces';
import { get, save } from 'framework/services/localStorage/localStorage';
import { i18n } from 'i18n';
import { Uuid } from 'framework/utils/generateId';
// eslint-disable-next-line import/no-cycle
import {
  Message,
  Modal,
  Switch,
  Table,
  Text,
} from '../../..';
import { COLUMNS_MODAL } from '../../constants';
import { DraggableRow, columnsMetaData } from './ColumnsMetadata';
import './_styles.scss';

const clx = prefixClassName('columns-modal');
export interface ColumnsModalProps {
  columns: StringObjectMetadata;
  onChange: (indices) => void;
  onViewChange?: (view: boolean) => void;
  disable?: string[];
  localStorageEntry?: string;
  singleIndex?: boolean;
  extendTableViewConst?: boolean;
  updateMetaData?: (updatedMetaData: ColumnMetadata) => void;
  closeModal?: () => void;
}

const clxColumns = prefixClassName('filter-bar__columns');
const clxColumnsButtonsBar = `${clxColumns}__buttons-bar`;
const clxColumnsButtonsBarTotal = `${clxColumnsButtonsBar}__total`;
const OF_TABLE_VIEW = 'OF_TABLE_VIEW';

interface MovedItems {
  uniqueId: string;
  newIndex: number;
}

export const ColumnsModal = (props: ColumnsModalProps) => {
  const { 
    columns = [],
    onChange,
    localStorageEntry,
    disable = [],
    onViewChange = null,
    closeModal,
    singleIndex = false,
    updateMetaData,
    extendTableViewConst = false,
  } = props;

  // If extendTableViewConst is true, then concat OF_TABLE_VIEW with localStorageEntry to
  // ensure a unique table view name
  const tableViewLocal: string = extendTableViewConst ? `${OF_TABLE_VIEW}-${localStorageEntry}` : OF_TABLE_VIEW;

  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [editedColumns, setEditedColumns] = React.useState<string[]>([]);
  const [sessionChangedColumns, setSessionChangedColumns] = React.useState<string[]>([]);
  const [lockedColumns, setLockedColumns] = React.useState<string[]>([]);
  const [originalColumns] = React.useState(columns);
  const [defaultReset, setDefaultReset] = React.useState<boolean>(false);

  const [tableView, setTableView] = React.useState<boolean>(false);

  const [movedItems, setMovedItems] = React.useState<MovedItems[]>([]);
  const [sessionMovedItems, setSessionMovedItems] = React.useState<MovedItems[]>([]);

  const calculateTableData = (columnData: StringObjectMetadata) => {
    const data = columnsMetaData && (columnData ?? columnsMetaData()).map((item) => {
      if (!item.canHide && item?.caption?.constructor !== String
        || i18n.t(item?.caption) === i18n.t('generic.actions')) {
        return null;
      }

      return (
        {
          ...item,
          id: Uuid(),
          label: item.caption,
          uniqueId: item.uniqueId,
          key: item.uniqueId,
          disabled: disable.includes(i18n.t(item.caption)) || disable.includes(item.caption),
          hidden: selectedItems.includes(item.uniqueId),
        });
    }).filter((item) => item);

    return data;
  };

  const tableData = calculateTableData(columns);

  const [localValues, setLocalValues] = React.useState<any[]>(tableData);

  const hideColumns = (evt) => {
    const action: boolean = evt.currentTarget.checked;
    const { name: index } = evt.currentTarget;
    const hiddenColumns = [...selectedItems];
    const selectedIndex: number = selectedItems.indexOf(index);

    if (singleIndex) {
      const editedChangedColumns: string[] = [...sessionChangedColumns];

      // If the action is to unhide the column and the column was previously hidden, then it is changed
      // If the action is to hide the column and the column was not previously hidden, then it is changed
      // If none of these cases are true, then the column is not changed so remove from array
      if (action === true && editedColumns.includes(index) || action === false && !editedColumns.includes(index)) {
        editedChangedColumns.push(index);
        setSessionChangedColumns(editedChangedColumns);
      } else {
        const indexPos: number = editedChangedColumns.indexOf(index);
        editedChangedColumns.splice(indexPos, 1);
        setSessionChangedColumns(editedChangedColumns);
      }
    }

    if (selectedIndex < 0) {
      hiddenColumns.push(index);
    } else {
      hiddenColumns.splice(selectedIndex, 1);
    }

    setLocalValues([...localValues].map((v) => {
      if (v.uniqueId === index) return {...v, hidden: selectedIndex < 0};
      return v;
    }));
    setSelectedItems(hiddenColumns);
  };

  // Put these in a callBack so the lockableIndexes are only calculated when localValues changes
  const lockableIndexes = (
    values: any[], lockedColumnsOverride: string[] = lockedColumns,
  ): { start: number[], end: number[] } => {
    // First two columns that can be locked (first two not hidden)
    // 5 or more viewable columns required for locking
    const lockableIndexesStart: number[] = values.reduce((p, c, i) => {
      if (c?.hidden || p.length === 2 || values.length - selectedItems.length === 4) return p;

      // Only allow locking columns in sequence
      const previousUnhidden = values.findIndex((v) => !v.hidden);

      if (i !== previousUnhidden && !lockedColumnsOverride.includes(values[previousUnhidden]?.uniqueId))
        return [...p, null];

      return [...p, i];
    }, []);

    // Last two columns that can be locked (last two not hidden)
    // 5 or more viewable columns required for locking
    const reversedLocalValues: any[] = values.slice().reverse();

    const lockableIndexesEnd: number[] = values.reduceRight((p, c, i) => {
      if (c?.hidden || p.length === 2 || values.length - selectedItems.length === 4) return p;

      // Only allow locking columns in sequence
      // Find the uniqueId of the most previous unhidden column
      const previousUnhidden: number = reversedLocalValues.findIndex((v) => !v.hidden);
      const previousIndexUniqueId: string = reversedLocalValues[previousUnhidden]?.uniqueId;

      // If the most previous unhidden column is not the current column and it is not locked, then
      // add null to reduced array because this column cannot be locked yet
      if (values.findIndex((v) => v.uniqueId === previousIndexUniqueId) !== i
        && !lockedColumnsOverride.includes(previousIndexUniqueId)) return [...p, null];
      
      return [...p, i];
    }, []);

    return { start: lockableIndexesStart, end: lockableIndexesEnd };
  };

  const lockColumns = (uniqueId: string) => {
    const newLocked: string[] = [...lockedColumns];
    const isSelected: number = lockedColumns.indexOf(uniqueId);

    if (isSelected < 0) {
      newLocked.push(uniqueId);
    } else {
      newLocked.splice(isSelected, 1);
    }

    const updatedColumns: any[] = [...localValues.map((v) => {
      if (v.uniqueId === uniqueId) {
        return { ...v, locked: isSelected < 0 };
      }

      return v;
    })];

    setLocalValues(updatedColumns);
    setLockedColumns(newLocked);
  };

  const savedSelection = get(localStorageEntry, true) || '[]';
  const savedTableView = get(tableViewLocal, true) || false;
  const hiddenCheck = JSON.stringify(selectedItems) !== savedSelection;
  const savedLocked = get(`${localStorageEntry}-locked`, true) || '[]';
  const lockedCheck = JSON.stringify(lockedColumns) !== savedLocked;
  const tableViewCheck = onViewChange && tableView !== savedTableView;
  const applyChangesValidation =  (!(sessionMovedItems.length > 0 || defaultReset || hiddenCheck
    || lockedCheck || tableViewCheck));

  const onUpdateMetaData = (movedValues?: any[]) => {
    if (!updateMetaData || movedValues?.length === 0 || movedValues.some((v) => !v)) return;

    updateMetaData(!singleIndex ? [columns[0], ...movedValues, columns[columns.length - 1]] : movedValues);
  };

  const onApplyChanges = (
    overrideSelected?: string[], ignoreStorage: boolean = false, values?: any[], updateMetadata: boolean = true,
  ) => {
    const newHiddenIndexes: string[] = [...Array.from(new Set(!singleIndex ? sessionChangedColumns : selectedItems))]
      .map((uniqueId) => {
        const newIndex = (values ?? localValues).findIndex((v) => v?.uniqueId === uniqueId);

        // If the uniqueId is already a number, then use it for newHiddenIndexes instead
        // This happens when resetting to default
        return (Number.isInteger(Number(uniqueId)) ? Number(uniqueId) : newIndex + 1).toString();
      });

    onChange(overrideSelected ?? newHiddenIndexes);

    // Keep editedColumns state up to date with applied changes
    if (!overrideSelected) {
      setEditedColumns([...selectedItems]);
      setSessionChangedColumns([]);
    }

    if (localStorageEntry && !ignoreStorage) {
      save(localStorageEntry, JSON.stringify(selectedItems), null, true);
      save(`${localStorageEntry}-moved`, JSON.stringify(movedItems), null, true);
      save(`${localStorageEntry}-locked`, JSON.stringify(lockedColumns), null, true);
    }

    const { start, end } = lockableIndexes(values ?? localValues);

    if (updateMetadata) onUpdateMetaData([...values ?? localValues].map((v, i) => ({
      ...v,
      locked: lockedColumns.includes(v.uniqueId),
      leftLockable: start.includes(i),
      rightLockable: end.includes(i),
    })));
    setSessionMovedItems([]);

    if (onViewChange) {
      save(tableViewLocal, tableView, null, true);
      onViewChange(tableView);
    }
    setDefaultReset(false);
  };

  const onDragEnd = (data, newIndex, items) => {
    const { uniqueId } = data;
    const movedColumns = [...movedItems];
    const sessionMoved = [...sessionMovedItems];    

    const updatedIndex: number = newIndex;

    const existingIndex = movedItems.findIndex((v) => v.uniqueId === uniqueId);
    const existingColumn = movedItems.find((v) => v.uniqueId === uniqueId);

    if (!existingColumn) {
      movedColumns.push({ newIndex: updatedIndex, uniqueId });
      sessionMoved.push({ newIndex: updatedIndex, uniqueId });
    } else {
      movedColumns.push({ ...existingColumn, newIndex: updatedIndex });
      sessionMoved.push({ ...existingColumn, newIndex: updatedIndex });
      if (movedColumns.length > 1) movedColumns.splice(existingIndex, 1);
      if (sessionMoved.length > 1) sessionMoved.splice(existingIndex, 1);
    }

    movedColumns.forEach((v, i) => {
      const localIndex: number = items.findIndex((b) => b.uniqueId === v.uniqueId);

      if (localIndex !== -1) {
        movedColumns[i] = { ...movedColumns[i], newIndex: localIndex };
      }
    });

    setMovedItems(movedColumns);
    setSessionMovedItems(sessionMoved);
  };

  const resetTableView = () => {
    if (onViewChange) {
      const localTableView = get(tableViewLocal, true) || false;
      if (JSON.stringify(tableView) !== localTableView) {
        setTableView(JSON.parse(localTableView));
        onViewChange(JSON.parse(localTableView));
      }
    }
  };

  const resetToDefault = () => {
    setMovedItems([]);
    setSessionMovedItems([]);
    setSelectedItems([]);
    setLockedColumns([]);

    const defaultTableData = calculateTableData(originalColumns);

    // When reset to default, hidden columns in listPage need to be reset
    // Get the current index of the hidden columns in order to reset them when clicking apply changes
    const resetColumns: string[] = columns.reduce<string[]>((v, c, i) => {
      if (typeof c === 'object' && c.hidden) {
        return [...v, (i + 1).toString()];
      }

      return v;
    }, []);

    setSessionChangedColumns(resetColumns);

    setLocalValues(defaultTableData.map((v) => ({ ...v, hidden: false })));

    setDefaultReset(true);
    resetTableView();
  };

  const handleViewChange = () => {
    setTableView(!tableView);
  };

  const generateOriginalColumns = (values: any[], updateMetadata: boolean = true) => {
    if (values.length === 0) return;

    if (localStorageEntry) {
      let reorderedColumns = new Array(values.length).fill(null);

      const localDataMoved = get(`${localStorageEntry}-moved`, true) || '[]';

      if (JSON.stringify(movedItems) !== localDataMoved) {
        const parsedData: MovedItems[] = JSON.parse(localDataMoved);

        setMovedItems(parsedData);

        const localMetaData: StringObjectMetadata = [...values];

        parsedData.forEach((data) => {
          const { newIndex, uniqueId } = data;

          const columnIndex = values.findIndex((v) => {
            if (typeof v === 'object') {
              return v.uniqueId === uniqueId;
            }

            return null;
          });

          const columnIndexLocal = localMetaData.findIndex((v) => {
            if (typeof v === 'object') {
              return v.uniqueId === uniqueId;
            }

            return null;
          });

          reorderedColumns[newIndex] = values[columnIndex];
          localMetaData.splice(columnIndexLocal, 1);
        });

        reorderedColumns = reorderedColumns.map((v) => {
          if (v === null && localMetaData.length > 0) {
            const copyLocal = [...localMetaData];
            localMetaData.splice(0, 1);
            return copyLocal[0];
          }

          return v;
        });

        if (reorderedColumns.length !== 0) {
          setLocalValues(reorderedColumns);
          if (updateMetaData) onUpdateMetaData(reorderedColumns);
        }
      }

      const localData = get(localStorageEntry, true) || '[]';
      if (JSON.stringify(selectedItems) !== localData) {
        let parsedData: string[] = JSON.parse(localData);

        const columnsToUse: any[] = reorderedColumns[0] ? reorderedColumns : values;

        // If the data in local storage contains all integers, then it needs to be changed to use uniqueId
        if (parsedData.every((v) => Number.isInteger(Number(v)))) {
          parsedData = [...parsedData].map((v) => {
            if (!Number.isInteger(Number(v))) return v;
            
            // Use passed in values instead of reorderedColumns in case any columns have been moved
            return values[Number(v) - 1]?.uniqueId;
          });
        }

        setSelectedItems(parsedData);
        setEditedColumns(parsedData);

        const newHiddenIndexes: string[] = parsedData.map((uniqueId) => {
          const newIndex = columnsToUse.findIndex((v) => v?.uniqueId === uniqueId);

          return (newIndex + 1).toString();
        });

        const updatedColumns: any[] = [...columnsToUse].map((v) => {
          if (parsedData.includes(v.uniqueId)) return {...v, hidden: true};
          return v;
        });
        
        reorderedColumns = updatedColumns;

        if (singleIndex) {
          onApplyChanges(newHiddenIndexes, true, reorderedColumns, updateMetadata);
        } else {
          onChange(newHiddenIndexes);
        }

        setLocalValues(reorderedColumns);
      }
      
      const localDataLocked = get(`${localStorageEntry}-locked`, true) || '[]';
      let updatedLocked: any[] = [];
      const lockedI: number[] = [];

      if (JSON.stringify(lockedColumns) !== localDataLocked && onViewChange) {
        const locked: string[] = JSON.parse(localDataLocked);

        setLockedColumns(locked);
        
        // Use either reorderedColumns or values to update locked columns from local storage
        updatedLocked = [...(reorderedColumns[0] !== null ? reorderedColumns : values).map((v, i) => {
          if (locked.includes(v.uniqueId)) {
            lockedI.push(i);
            return {
              ...v,
              locked: true,
            };
          }
          return v;
        })];

        const { start, end } = lockableIndexes(updatedLocked, locked);

        updatedLocked = [...updatedLocked].map((v, i) => ({
          ...v,
          leftLockable: start?.includes(i),
          rightLockable: end?.includes(i),
        }));
      }

      if (updatedLocked.length !== 0) {
        setLocalValues(updatedLocked);
        if (updateMetaData) onUpdateMetaData(updatedLocked);
      }

      resetTableView();
    }
  };

  const resetColumns = () => {
    setSessionChangedColumns([]);
    setMovedItems([]);
    setSessionMovedItems([]);

    const defaultTableData = calculateTableData(columns);
    // Don't update metadata on reset click
    generateOriginalColumns(defaultTableData, false);

    setDefaultReset(false);
    resetTableView();
  };

  React.useEffect(() => {
    if (!onViewChange) return;

    const { start, end } = lockableIndexes(localValues);

    // If responsive table is available and it is turned off, then no columns are lockable
    const lockableUniqueIds: string[] = onViewChange && !tableView
      ? []
      : [...start, ...end].map((index: number) => {
        return localValues[index]?.uniqueId;
      });

    // If any of the currently locked columns is not in the lockableUniqueIds, then unlock it
    lockedColumns.forEach((uniqueId: string) => {
      if (!lockableUniqueIds.includes(uniqueId)) {
        lockColumns(uniqueId);
      }
    });
  }, [lockableIndexes]);

  React.useEffect(() => {
    generateOriginalColumns(localValues);
  }, []);

  return (
    <Modal
      name={COLUMNS_MODAL}
      className={`${clx}`}
      closeOnUnmount
      caption="generic.tableColumnsModal.title"
      onDismiss={() => {
        closeModal();
      }}
      footerOptions={[
        { caption: 'generic.button.applyChanges',
          disabled: applyChangesValidation,
          onClick: () => {
            onApplyChanges();
            closeModal();
          } 
        },
        { caption: 'generic.button.reset', 
          onClick: () => resetColumns(), disabled: applyChangesValidation, outline: true, 
        },
        { caption: 'generic.button.resetDefault', 
          onClick: () => resetToDefault(), 
          outline: true, 
        }
      ]}
    >
    <>
      <Message
        type="info"
        className="mrg-btm-20 stretchToModalWidth"
        showLabel={false}
        caption={[
          'generic.tableColumnsModal.Message1',
          ...onViewChange ? ['generic.tableColumnsModal.Message2'] : [],
        ]}
      />
        {onViewChange && 
          <div>
            <Switch
              name="tableView"
              caption="generic.compact"
              onChange={() => handleViewChange()}
              initValue={tableView}
              explainerText="generic.tableColumnsModal.compactView"
              labelInline
              grid
            />
          </div>
        }
        <Table
          data={localValues}
          draggable 
          draggableId="id"
          tableName="columnsTable"
          editSequence
          setExternalData={(items: any[]) => {
            setLocalValues(items);
          }}
          onDragEnd={(_id: string, index: number, data, items) => { onDragEnd(data, index, items); }}
          columnMetadata={columnsMetaData}
          cellRenderer={(_b, rowData, _m, i) => {
            const { start, end } = lockableIndexes(localValues);
            return (
              <DraggableRow 
                data={rowData}
                hideColumns={hideColumns}
                selectedItems={selectedItems}
                lockColumns={lockColumns}
                lockedItems={lockedColumns}
                disabledList={disable}
                editSequence
                hiddenClassName={`${clx}-hidden-column-name`}
                lockButtonClassName={`${clx}-lock-column-button`}
                allowLocking={!!onViewChange && tableView}
                lockableIndexes={[...start, ...end]}
                index={i}
                key={i}
              />
            );
          }}
        />
        <div className={clxColumnsButtonsBar}>
          <div className={clxColumnsButtonsBarTotal}>
            <Text
              caption="generic.tableColumnsFilter.columnsCount"
              i18nOptions={{
                num: columns?.length - selectedItems.length,
                tot: columns?.length,
              }}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};