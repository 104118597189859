import CONST from '../constants';
import { IModalSearchQuery } from '../types';

export const openModalAction = () => ({
  type: CONST.ACTION_MODAL_OPEN,
});

export const hideModalAction = () => ({
  type: CONST.ACTION_MODAL_CLOSE,
});

export const searchProductsAction = (data: IModalSearchQuery) => ({
  type: CONST.ACTION_MODAL_SEARCH,
  payload: data,
});
