import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text, Loader } from 'framework/components/ui';
import { ICalendar, ICalendarPrivate } from './Calendar.interfaces';
import { CalendarContext } from './Calendar.context';
import * as Comp from './components';
import { ConnectedToolbar } from './components/Toolbar/ToolbarOptions';
import './_style.scss';
import HeaderGutterTooltip from './components/HeaderGutterTooltip/HeaderGutterTooltip';

const clx = prefixClassName('calendar');

const localizer = momentLocalizer(moment);

interface Props extends ICalendar, ICalendarPrivate {}

class Calendar extends React.PureComponent<Props, any> {
  static defaultProps: Partial<Props> = {
    events: [],
    views: ['day', 'week'],
    navigationShow: true,
    toolBarShow: true,
    defaultDate: new Date(),
    defaultView: 'week',
    noDataSelected: false,
    noDataSelectedMessage: 'components.calendar.noDataMessage',
    isLoading: false,
    disabled: false,
    hideCalendar: false,
    onView: () => null,
    onNavigate: () => null,
    hasInstacartSlots: false,
    hasOrphanedSlots: false,
  };

  componentDidMount() {
    this.props.updateSelectorView(this.props.defaultView);
    this.props.updateSelectorDate(this.props.defaultDate);
  }

  componentDidUpdate() {
    if (this.props.onViewChange) this.props.onViewChange(this.props.view);
  }

  componentWillUnmount() {
    this.props.deleteCalendarData();
  }

  /**
   * handles the selection of an existing event in the calendar
   */
  handleSelectEvent = (e) => {
    const { updateSelectorEvent, onSelectEvent } = this.props; // from mapDispatchToProps
    updateSelectorEvent(e.id);

    if (onSelectEvent) {
      onSelectEvent(e);
    }
  };

  handleView = (e) => {
    this.props.updateSelectorView(e);
    // this.props.onView(e);
    this.handleNavigate(this.props.date);
  };

  handleNavigate = (e) => {
    this.props.updateSelectorDate(e);
    this.props.onNavigate(e);
  };

  setHour = (h: number) => h && new Date(2017, 10, 0, h, 0, 0);

  render() {
    const {
      name,
      date,
      template,
      headerData,
      className,
      events,
      navigationShow,
      toolBarShow,
      toolBarOptions = [],
      view,
      views,
      defaultDate,
      defaultView,
      minHour,
      maxHour,
      noDataSelected,
      noDataSelectedMessage,
      isLoading,
      onNavigate,
      onView,
      hasInstacartSlots,
      hasOrphanedSlots,
    } = this.props;

    // Strat weeks on Monday
    moment.locale('ko', {
      week: {
        dow: 1,
        doy: 1,
      },
    });

    return (
      <CalendarContext.Provider
        value={{
          template,
          data: { headerData },
        }}
      >
        <div className={classNames(clx, className)}>
          <ConnectedToolbar
            calendarName={name}
            viewOptions={views}
            options={toolBarOptions}
            show={toolBarShow}
            showNavigation={navigationShow}
            onNavigate={onNavigate}
            onView={onView}
          />
          <BigCalendar
            selectable={false}
            min={this.setHour(minHour)}
            max={this.setHour(maxHour)}
            views={views}
            localizer={localizer}
            view={view || defaultView}
            defaultView={defaultView}
            defaultDate={defaultDate}
            date={date}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={this.handleSelectEvent}
            onNavigate={this.handleNavigate}
            onView={this.handleView}
            components={{
              event: Comp.Event,
              eventWrapper: Comp.EventWrapper,
              toolbar: () => null,
              // toolbar: Comp.Toolbar({ show: !!toolBarShow, options: toolBarOptions }),

              day: {
                event: Comp.Event,
                header: Comp.HeaderWeek,
              },
              week: {
                event: Comp.Event,
                header: Comp.HeaderWeek,
              },
              timeGutterHeader: () => (
                <HeaderGutterTooltip hasInstacartTimeslot={hasInstacartSlots} hasOrphanedSlots={hasOrphanedSlots} />
              ),
            }}
          />
          {(noDataSelected || isLoading) && (
            <div className={`${clx}__infoOverlay`}>
              {isLoading && <Loader />}
              {!isLoading && noDataSelected && <Text caption={noDataSelectedMessage} />}
            </div>
          )}
        </div>
      </CalendarContext.Provider>
    );
  }
}

export default Calendar;
