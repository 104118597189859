import { call, put, select } from 'redux-saga/effects';
import { toastError, toastSuccess } from 'stores/toast';
import { config } from 'config';
import { getApiClientData } from 'framework/api/apiClient/service';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import {
  apiClientSelectors,
  calendarSelectors,
  formSelectors,
  tabsSelectors,
} from 'framework/selectors';
import { apiClientActions } from 'framework/actions';
import { TimeslotTranslations } from 'framework/modules/slotManagement/translations';
import * as CONST from 'framework/modules/slotManagement/constants';
import { getSelectValue, getSelectValues } from 'framework/components/ui/FormComponents/Select';
import { goToSlotTemplateEditRoute } from 'framework/modules/slotManagement/service';
import {
  TABS_ID, FORM_EDIT_CALENDAR, FORM_STORES, FORM_SCHEDULE, FORM_DETAILS_NAME,
  FORM_DETAILS_SHOPPINGMODE, SlotTemplateEditTabs, FORM_DETAILS_AVAILABLE_STORES,
} from '../../pageParts/constants';
// eslint-disable-next-line import/no-cycle
import { ACTIONS } from '..';

const formatTime = (co?: string) => {
  if (co?.includes('.')) {
    // Format -> 1.12:30:00
    return co;
  }
  if (co?.includes(':')) {
    // Format -> 12:30:00
    const [h, m] = co.split(':');
    const hours = parseInt(h, 10);
    const d = Math.floor(hours / 24);

    return `${d}.${hours % 24}:${m}`;
  }

  return co;
};

export function* process({ payload }) {
  const { isSchedule = false, removedStores = [] } = payload;
  const endpoint = config.apiEndpoints.slotTemplatesv2;
  const activeTab = isSchedule === true
    ? SlotTemplateEditTabs.schedule
    : yield select(tabsSelectors.getOpenTab, TABS_ID);
  const calendarEvents: ReturnType<typeof calendarSelectors.getCalendarEvents> = yield select(
    calendarSelectors.getCalendarEvents,
    FORM_EDIT_CALENDAR,
  ) || [];
  const formValues = yield select(
    formSelectors.getFormFieldValues,
    CONST.FORM_SLOTMANAGEMENT_EDIT_DETAILS,
  );
  const formSchedulingValues = yield select(
    formSelectors.getFormFieldValues,
    CONST.FORM_SLOTMANAGEMENT_SCHEDULING,
  );

  const templateInitialData = yield select(
    apiClientSelectors.selectApiClientData,
    CONST.API_DATA_SLOTS_TEMPLATES_EDIT,
  );

  const { templateId } = templateInitialData;

  // 1. Show Loaders
  yield put(
    apiClientActions.createApiClientUpdateStateAction({
      name: CONST.API_DATA_SLOTS_TEMPLATES_EDIT,
      isFetching: true,
    }),
  );
  // 2. Save Template
  const endpointPath = isSchedule
    ? `/templates/${templateId}/assign`
    : `/templates/${templateId}`;

  const body: any = {
    ...(!isSchedule && ({ allowedStores: templateInitialData.allowedStoreIds || [] })),

    // Data - Slots
    slots: templateInitialData.slots || [],

    // Data - Schedule
    stores: templateInitialData.storeIds || [],
    ranges: templateInitialData.ranges || [],

    // Data - Details
    templateName: templateInitialData.templateName,
    shoppingModeId: templateInitialData.shoppingModeId,
  };

  switch (activeTab) {
    // Tab - Slots
    case SlotTemplateEditTabs.edit:
      body.slots = calendarEvents.map((event) => {
        const data = event.data || {};
        const deliveryAreas = data.deliveryAreas || [];

        return {
          day: data.day % 7,
          start: data.start,
          end: data.end,
          newOrdersCutoff: formatTime(data.newOrdersCutoff),
          amendOrdersCutoff: formatTime(data.amendOrdersCutoff),
          pickingStartingTime: formatTime(data.pickingStartingTime),
          pickByTime: formatTime(data.pickByTime),
          fulfillmentType: getSelectValue(data.fulfillmentType),
          capacity: data.infiniteCapacity ? 0 : data.capacity,
          price: data.price,
          tags: getSelectValues(data.tags || []),
          deliveryAreas: deliveryAreas.map((d) => d.id),
        };
      });
      break;

    // Tab - Schedule
    case SlotTemplateEditTabs.schedule: {
      const stores = formSchedulingValues[FORM_STORES] || [];
      const scheduleRanges = formSchedulingValues[FORM_SCHEDULE] || [];
      const ranges = scheduleRanges
        .filter((r) => r.iniTime && r.endTime && r.iniTime <= r.endTime)
        .map((r) => ({
          start: {
            day: r.iniTime.getDate(),
            month: r.iniTime.getMonth() + 1,
          },
          end: {
            day: r.endTime.getDate(),
            month: r.endTime.getMonth() + 1,
          },
        }));

      body.assignedStores = stores.map((s) => s.value || s);
      body.ranges = ranges;
      body.removedStores = removedStores;
      break;
    }

    // Tab - Details
    case SlotTemplateEditTabs.details:
      body.templateName = formValues[FORM_DETAILS_NAME];
      body.shoppingMode = formValues[FORM_DETAILS_SHOPPINGMODE];
      body.allowedStores = formValues[FORM_DETAILS_AVAILABLE_STORES];
      break;
    default:
  }

  // FIXME: remove this on the future.
  // areas and removedAreas neeeds to be sent for saving properly,
  // but the functionality does't exist yet
  body.slots = body.slots.map((s) => ({ ...s, areas: [], removedAreas: [] }));

  const saveTemplateSlotsPayload = {
    name: 'TemplateUpdate',
    method: 'put',
    url: endpoint + endpointPath,
    body,
  };

  const responseData = yield call(getApiClientData, saveTemplateSlotsPayload, true);

  if (responseData.success) {
    // 3. Reload content on the page
    toastSuccess(TimeslotTranslations.SlotTemplateUpdateSuccess);
    goToSlotTemplateEditRoute(templateId, activeTab);
  } else {
    // Update the loading state to false when the call fails
    yield put(
      apiClientActions.createApiClientUpdateStateAction({
        name: CONST.API_DATA_SLOTS_TEMPLATES_EDIT,
        isFetching: false,
      }),
    );
    toastError(responseData?.response?.data?.detail || TimeslotTranslations.SlotTemplateSaveError);
  }
}

export default function* watchSaveSlotTemplateDataAction() {
  yield takeEveryWithProgressBar(ACTIONS.TEMPLATE_SAVE_DATA, process);
}
