import { Uuid } from 'framework/utils/generateId';
import { CmsLayoutInspector } from '../CmsComponents.interfaces';
import { fixDefaultValues } from './utils.FixDefaultValues';

export function getItems(inspector) {
  let items = [];
  if (inspector.name === 'TwoProductCard') {
    items = [
      { type: 'Template', data: {} },
      { type: 'Template', data: {} },
    ];
  } else if (inspector.name === 'NextAvailableTimeSlot') {
    items = [
      {
        type: 'WelcomeTimeSlotItem',
        headerText: 'Welcome',
        bodyText:
          '<p><strong>Sign In</strong> or <strong>Register</strong> to reserve a timeslot and start shopping</p>',
        buttonTextA: 'Sign In',
        buttonTextB: 'Register',
      },
      {
        type: 'NextTimeSlotItem',
        headerText: 'Hi {{Customer.FirstName}}, schedule your next  {{Order.Fulfillment.Type}} ',
        bodyText: 'Next Available Timeslot:',
        buttonTextA: 'Reserve {{Order.Fulfillment.Type}} Time',
        buttonTextB: 'View More Times',
      },
      {
        type: 'ReservedTimeSlotItem',
        headerText: 'Hi {{Customer.FirstName}}, your timeslot has been reserved',
        bodyText: 'Reserved {{Order.Fulfillment.Type}} Time:',
        buttonTextA: 'Review Cart',
        buttonTextB: 'Change Time',
      },
      {
        type: 'SoldOutTimeSlotItem',
        buttonTextA: 'Change Mode',
        buttonTextB: 'Change Store',
        headerText: 'Hi {{Customer.FirstName}}, unfortunately, there are no delivery times available at the moment',
      },
    ];
  } else {
    items = [
      { type: 'Template', data: {} },
      { type: 'Template', data: {} },
      { type: 'Template', data: {} },
    ];
  }
  return items;
}

// Usage:

export const initializeLayout = (inspector: CmsLayoutInspector) => {
  // add new column, and then add new component
  let payload: any = { type: inspector.name, componentName: inspector.name };

  if (inspector.wellknown) {
    switch (inspector.name) {
      case 'Carousel':
      case 'CarouselSmall':
        payload = {
          ...payload,
          items: [],
        };
        break;
      case 'TreeBanner':
      case 'NextAvailableTimeSlot':
      case 'TwoProductCard':
        payload = {
          ...payload,
          items: getItems(inspector),
        };
        break;
      case 'LargePromotion':
      case 'SmallPromotion':
        payload = {
          isRandomPromotion: true,
          ...payload,
        };
        break;
      case 'Recommendation':
        payload = {
          referenceName: Uuid(),
          titleAlignment: 'Center',
          ...payload,
        };
        break;
      case 'ProductCardStory':
      case 'Circular':
      case 'ProductListing':
        payload = {
          referenceName: Uuid(),
          ...payload,
        };
        break;
      default:
        payload = {
          ...payload,
        };
        break;
    }
  } else {
    payload = {
      ...payload,
      type: 'Template',
      data: fixDefaultValues(inspector, {}),
    };
  }

  return payload;
};
