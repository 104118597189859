import * as React from 'react';
import classnames from 'classnames';
import { InputGroup, TextField, Button } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import * as _debounce from 'lodash.debounce';
import i18n from 'i18n';
import { ISearchBar } from './search.interface';
import './_style.scss';
import { Tooltip } from '../Tooltip';

const clx = prefixClassName('search-bar');

interface SearchBarState {
  showToolTip: boolean;
  searchTerm: string;
}

export class SearchBar extends React.Component<ISearchBar, SearchBarState> {
  debouncedSearch;

  static defaultProps: Partial<ISearchBar> = {
    placeholder: 'components.searchBar.placeholder',
    disabled: false,
    isSearchable: true,
    searchOnKeyDown: true,
    debounceTime: 400,
    searchFieldSize: 'large',
    iconName: 'search',
  };

  constructor(props) {
    super(props);
    this.state = {
      showToolTip: false,
      searchTerm: null,
    };
    const { debounceTime } = this.props;
    this.debouncedSearch = _debounce(this.onSearchHandler, debounceTime);
  }

  componentWillUnmount() {
    this.debouncedSearch.cancel();
  }

  onSearchHandler = (searchTerm?: string) => {
    const { searchFunction } = this.props;
    // Do not repeat search if the searchTerm hasn't actually changed when clicking search icon
    if (this.allowSearch() && searchTerm !== this.state?.searchTerm) searchFunction();
  };

  onSearchClickKey = (e) => {
    if (e.key === 'Enter') {
      this.onSearchHandler();
      e.preventDefault();
    }
  };

  allowSearch = () => {
    const { disabled, isSearchable } = this.props;
    return !disabled && isSearchable;
  };

  render() {
    const {
      name,
      className,
      placeholder,
      disabled,
      searchFieldSize,
      iconName,
      searchOnKeyDown,
      disabledToolTip,
    } = this.props;
    const { showToolTip } = this.state;

    const handleTooltipAction = (val: boolean): void => {
      if (disabled) {
        this.setState({ showToolTip: val });
      }
    };

    return (
      <span
        onMouseOver={() => handleTooltipAction(true)}
        onMouseOut={() => handleTooltipAction(false)}
        onFocus={() => handleTooltipAction(true)}
        onBlur={() => handleTooltipAction(false)}
      >
        <InputGroup className={classnames(clx, className)}>
          {disabledToolTip && disabled && (
            <Tooltip toolTipClassName="mwg-search-bar__tooltip" position="up-centre" active={showToolTip}>
              {i18n.t(disabledToolTip)}
            </Tooltip>
          )}
          <TextField
            name={name}
            clearable
            placeholder={placeholder}
            size={searchFieldSize}
            onKeyPress={this.onSearchClickKey}
            onChange={searchOnKeyDown
              ? (e) => {
                this.debouncedSearch();
                if (this.state) this.setState({ searchTerm: e });
              }
              : null}
            autocomplete={false}
            disabled={disabled}
          />

          <Button
            icon={iconName}
            color="main"
            onClick={() => { this.onSearchHandler(this.state?.searchTerm); }}
            disabled={!this.allowSearch()}
            // disabled={this.isSearchable()}

            outline
            iconSize="sm"
          />
        </InputGroup>
      </span>
    );
  }
}
