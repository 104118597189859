import * as React from 'react';
import { Switch } from 'ui';
import { featureFlag } from 'featureFlag';
import { ICheckboxInterface } from '../StoreFrontSettings.interfaces';
import ContactSupport from './ContactSupport';

const CheckboxType = (props: ICheckboxInterface) => {
  const {
    component, field, handleChange, value, id,
  } = props;
  
  const hasValue = value !== undefined && value !== null;

  return (
    <>
      {!hasValue && <ContactSupport />}
      <Switch
        caption={component.title}
        name={`${id}-${field}`}
        labelInline
        size="tiny"
        onChange={() => handleChange(field, !value)}
        value={value}
        explainerText={component.description}
        initValue={value}
        {
          ...featureFlag({
            support: () => ({...component.helpLink && ({
              explainerLink: {
                caption: 'generic.readMore',
                to: component.helpLink,
                external: true,
              }})}),
            prod: () => {},
          })
        }
        disabled={!hasValue}
        grid
      />
    </>
  );
};

export default CheckboxType;
