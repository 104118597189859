import { createSelector } from 'reselect';
import { i18n } from 'i18n';
import { apiClientSelectors, formSelectors } from 'framework/selectors';
import { API_DATA_LISTING_DATA, API_LISTING_SITES } from 'framework/modules/merchandising/constants';
import { Listing, ListingStatus, ApiStoreRecommendation, ListingRule } from 'framework/modules/merchandising/models';
import { ListingTranslations as translation } from 'framework/modules/merchandising/translations';
import * as CONST from '../constants';
import { ATTRIBUTE_INITIALS } from '../constants';

// *************** //
// Original values //
// *************** //

const getSitesData = (state: any) => apiClientSelectors.selectApiClientData(state, API_LISTING_SITES);
const getListingInfo = (state: any) => apiClientSelectors.selectApiClientData(state, API_DATA_LISTING_DATA);

export const getListingData = createSelector<any, any, Listing>([getListingInfo], (recInfo) => {
  const { listing = {} } = recInfo;
  const { locations = [] } = listing;
  const sortBy = listing[CONST.FIELD_SORTBY] || null;

  return {
    ...listing,
    actions: [
      ...(listing.actions?.map((action) => {
        return {
          ...action,
          // Add selected provider parameters to default values
          ...(action.externalProviderParameters
            ? Object.entries(action.externalProviderParameters)?.reduce(
                (params, param) => ({ ...params, [`-value-${param[0]}`]: param[1] }),
                {}
              )
            : {
                externalProviderParameters: {},
              }),
        };
      }) ?? []),
    ],
    [CONST.FIELD_SORTBY]: sortBy || undefined,
    locations: locations.map((loc) => ({
      label: loc.label || loc.id || loc,
      value: loc.id || loc,
    })),
  };
});

export const getListingStores = createSelector<any, any, ApiStoreRecommendation[]>(
  [getListingData],
  (recData) => recData.stores || []
);

export const getListingStoresId = createSelector<any, ApiStoreRecommendation[], string[]>(
  [getListingStores],
  (stores) => stores.map((st) => st.id).sort()
);

// *************** //
// General details //
// *************** //

export const isFetching = (state): boolean =>
  apiClientSelectors.getIsFetching(state, API_DATA_LISTING_DATA) ||
  apiClientSelectors.getIsFetching(state, API_LISTING_SITES);

export const isEditMode = createSelector<any, Listing, boolean>([getListingData], (recData) => !!recData.id);

interface RecPropsRules {
  [key: string]: RecPropsRule;
}
interface RecPropsRule {
  label: string;
  hasValues: boolean;
}
const formatOptionRules = (rules: RecPropsRules = {}) => {
  const opRules = {};

  Object.keys(rules).forEach((key) => {
    opRules[key] = [];

    Object.keys(rules[key]).forEach((k) => {
      const r: RecPropsRule = rules[key][k];

      opRules[key].push({
        value: k,
        label: r.label || k,
        hasValues: r.hasValues || false,
      });
    });
  });

  return opRules;
};
export const getOptions = createSelector([getListingInfo, getSitesData], (recState, recSites) => {
  const sites = recSites.items || [];

  return {
    actionsRule: formatOptionRules(recState.actionOptions),
    actionsType: recState.actionDisplay || [],
    actionsTypeValues: recState.actionOptionsValues || [],
    conditionsRule: formatOptionRules(recState.conditionOptions),
    conditionsType: recState.conditionDisplay || [],

    orgHierarchy: recState.storesOrgHierarchy,
    sortByOptions: recState.sortByOptions,
    actionsSortByOptions: recState.actionsSortByOptions,
    externalProviderParametersOptions: recState.externalProviderParametersOptions,
    productAttributesOptions: recState.productAttributesFilterOptions,
    locations: recState.locationOptions || [],
    status: [
      {
        value: ListingStatus.Active,
        caption: i18n.t('generic.status.active'),
        colorMapping: 'Active',
      },
      {
        value: ListingStatus.Inactive,
        caption: i18n.t('generic.status.inactive'),
        colorMapping: 'Inactive',
      },
    ],
    sites: sites.map((site) => ({
      value: site.id,
      label: site.name,
    })),
  };
});

const formatLocations = (locations = []) => locations.map((loc) => loc.value || loc);

export const getFormValuesDetails = (state) => {
  const formValues = formSelectors.getFormFieldValues(state as never, CONST.FORM_DETAILS);
  const sortBy = formValues[CONST.FIELD_SORTBY] || null;

  return {
    ...formValues,
    [CONST.FIELD_LOCATIONS]: formatLocations(formValues[CONST.FIELD_LOCATIONS]),
    [CONST.FIELD_SORTBY]: sortBy,
  };
};

export const getFormValuesRules = (state) => {
  const formValues = formSelectors.getFormFieldValues(state as never, CONST.FORM_RULES);
  const actions = formValues?.actions?.map((action) => {
    const filtersWithAttributes: any[] = action?.filters?.map(
      (item) => item?.appliesOnProperty?.value ?? item?.appliesOnProperty
    );

    const filtersWithoutAttributes: any[] = action?.filters
      ?.map((item) => {
        return {
          appliesOnProperty: item?.appliesOnProperty?.value ?? item?.appliesOnProperty,
          applyActionOperation: item?.applyActionOperation?.value ?? item?.applyActionOperation,
          appliesOnValues: Array.isArray(item?.appliesOnValues)
            ? item?.appliesOnValues
            : [item?.appliesOnValues?.toString()],
        };
      })
      .filter((item) => {
        return !item?.appliesOnProperty?.includes(ATTRIBUTE_INITIALS);
      });
    const isAttributeValues =
      action?.appliesOnProperty?.value === ListingRule.Attributes ||
      action?.appliesOnProperty === ListingRule.Attributes;

    return {
      ...action,
      filters: filtersWithoutAttributes,
      ...(isAttributeValues
        ? {
            appliesOnValues: filtersWithAttributes,
            filters: action?.filters?.map((item) => {
              return {
                appliesOnProperty: item?.appliesOnProperty?.value ?? item?.appliesOnProperty,
                applyActionOperation: item?.applyActionOperation?.value ?? item?.applyActionOperation,
                appliesOnValues: Array.isArray(item?.appliesOnValues)
                  ? item?.appliesOnValues
                  : [item?.appliesOnValues?.toString()],
              };
            }),
          }
        : {}),
    };
  });

  return {
    ...formValues,
    actions,
    [CONST.FIELD_LOCATIONS]: formatLocations(formValues[CONST.FIELD_LOCATIONS]),
  };
};

const getOptionLabel = (options, value) => {
  const option = options.find((op) => op.value === value) || {};

  return option.label || '';
};

const getOperationLabel = (options, operation, property) => {
  const op = options?.[property] || [].find((b) => b.value === operation) || {};

  return op.label;
};

export const getListingActionsFormatted = createSelector([getListingData, getOptions], (recData, options) => {
  const actions = recData.actions || [];
  const productAttributeOptions = options.productAttributesOptions;

  // Format action rules to be more readable
  return actions.map((ac: any) => {
    let values = ac?.[CONST.FIELD_FILTERS_VALUE];
    let filters = ac?.[CONST.FIELD_FILTERS];
    // We separate the attribute filters from filters in the text translation formatting
    if (getOptionLabel(options.actionsType, ac.appliesOnProperty) === CONST.FIELD_ACTIONS_PROPERTY_ATTRIBUTES) {
      values = values
        .filter((value) => value.includes(ATTRIBUTE_INITIALS))
        .map((value) => {
          return productAttributeOptions.find((option) => option.appliesOnProperty === value)?.displayName;
        });
      filters = ac?.[CONST.FIELD_FILTERS].filter((option) => !option.appliesOnProperty.includes(ATTRIBUTE_INITIALS));
    }

    return i18n.t(translation.RuleFormatted, {
      count: (filters || []).length,
      results: ac.resultsCount,
      type: getOptionLabel(options.actionsType, ac.appliesOnProperty),
      operation: getOperationLabel(options.actionsRule, ac.applyActionOperation, ac.appliesOnProperty),
      values: (values || []).join(', '),
    });
  });
});
