import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Header } from 'ui';
import withErrorCatcher from 'framework/components/hoc/withErrorCatcher';
import './_style.scss';

const className = prefixClassName('grid-score-default-tpl');

export class DataGridScoreDefaultTemplate extends React.Component<any> {
  renderRelevanceData = () => {
    const { tooltipData: { relevanceExplanation = {} } = {} } = this.props;
    const fields = (relevanceExplanation && relevanceExplanation.fields) || [];

    return fields.map(eachField => {
      return (
        <div className={`${className}__relevance`} key={eachField.field}>
          <h5>{eachField.field}:</h5>
          <span className="mwg-body-regular">Score: {eachField.score}</span>
          <span className="mwg-body-regular">Field Content: {eachField.fieldContent}</span>
          <span className="mwg-body-regular">
            Match: {eachField.match && this.renderMatchValue(eachField.match)}
          </span>
        </div>
      );
    });
  };

  renderMetricsData = () => {
    const { tooltipData: { metricsExplanation = {} } = {} } = this.props;
    const fields = (metricsExplanation && metricsExplanation.fields) || [];

    return fields.map(eachField => {
      return (
        <div className={`${className}__relevance`} key={eachField.field}>
          <span className="mwg-body-regular">
            {eachField.field} Score: {eachField.score}
          </span>
        </div>
      );
    });
  };

  renderMatchValue = matchObj => {
    return Object.keys(matchObj)
      .map(each => `${each}  (${matchObj[each]})`)
      .join(',');
  };

  render() {
    const { tooltipData } = this.props;

    return Object.keys(tooltipData).length > 0 ? (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <div className={className} tabIndex={0}>
        <Header
          caption="modules.merchandising.searchResults.tooltipScoreText_01"
          headerLevel={4}
          className={`${className}__explainer`}
        />
        <Header
          caption="modules.merchandising.searchResults.tooltipScoreText_02"
          headerLevel={5}
          className={`${className}__explainer`}
        />
        <h5>
          Score:
          <br />
          <span className="mwg-body-regular">{tooltipData.score}</span>
        </h5>
        <h5>
          Score Explanation:
          <br />
          <span className="mwg-body-regular">{tooltipData.scoreExplanation}</span>
        </h5>
        {this.renderRelevanceData()}
        <br />
        {tooltipData.metricsExplanation && (
          <>
            <h5>Metrics Explanation:</h5>
            <span className="mwg-body-regular">Score: {tooltipData.metricsExplanation.score}</span>
            <span className="mwg-body-regular">
              Score Explanation: {tooltipData.metricsExplanation.scoreExplanation}
            </span>
            {this.renderMetricsData()}
          </>
        )}
        <br />
        {tooltipData.ruleExplanation && (
          <div>
            <h5>Rules Explanation:</h5>
            <span className="mwg-body-regular">Score: {tooltipData.ruleExplanation.score}</span>
            <br />
            <span className="mwg-body-regular">
              Score Explanation:
              {tooltipData.ruleExplanation.scoreExplanation}
            </span>
            <br />
            <span className="mwg-body-regular">Rule Id: {tooltipData.ruleExplanation.ruleId}</span>
            <br />
            <span className="mwg-body-regular">
              Rule Name: {tooltipData.ruleExplanation.ruleName}
            </span>
          </div>
        )}
      </div>
    ) : (
      '--'
    );
  }
}

export default withErrorCatcher(DataGridScoreDefaultTemplate);
