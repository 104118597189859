import { AuthorizationClaims, UserAccessRights } from 'framework/authorization/Interfaces';
import { AppRoute, AppRouteClaims } from 'framework/components/navigation/Navigation.interfaces';

import { PATH_CMS_BASEURL } from './contentemanagement.constants';
import { cmsUrls } from './Core/cmsUrls';

const pageClaims: AppRouteClaims = [
  AuthorizationClaims.CONTENTMANAGEMENT_PAGES,
  { [AuthorizationClaims.STORES_SITE]: UserAccessRights.Read },
  { [AuthorizationClaims.PROMOTIONS_PROMOTIONS]: UserAccessRights.Read },
  { [AuthorizationClaims.MERCHANDISING_RECIPES]: UserAccessRights.Read },
  { [AuthorizationClaims.DAM]: UserAccessRights.ReadWrite },
  { [AuthorizationClaims.CMS_PREVIEW_SESSIONS]: UserAccessRights.ReadWrite },
  { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
  { [AuthorizationClaims.SHOPPINGMODE_ADMIN]: UserAccessRights.Read },
  { [AuthorizationClaims.SHOPPINGMODE_ADMIN_STORES]: UserAccessRights.Read },
  { [AuthorizationClaims.STORES_STORE]: UserAccessRights.Read },
  { [AuthorizationClaims.SITESETTINGS_SEGMENTATION]: UserAccessRights.Read },
];

const emailClaims: AppRouteClaims = [
  { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
  AuthorizationClaims.CONTENTMANAGEMENT_EMAILS,
  { [AuthorizationClaims.DAM]: UserAccessRights.ReadWrite },
];

const termClaims: AppRouteClaims = [
  AuthorizationClaims.CONTENTMANAGEMENT_TERMS,
  AuthorizationClaims.CMS_TERMS,
  { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
];

export const cmsAllPagesRoute: AppRoute = {
  authClaim: pageClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.pages.allPagesUrl}`,
  pageTitle: 'modules.contentmanagement.pages.allpages.title',
  Component: () => import('./pages/Pages'),
};

export const cmsEditPageRoute: AppRoute = {
  authClaim: pageClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.pages.pageDocumentEditUrl}`,
  pageTitle: 'modules.contentmanagement.edit_page',
  Component: () => import('./pages/EditPage'),
  parent: cmsAllPagesRoute,
};

export const cmsCreatePageRoute: AppRoute = {
  authClaim: pageClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.pages.pageDocumentCreateUrl}`,
  pageTitle: 'modules.contentmanagement.create_page',
  Component: () => import('./pages/CreatePage'),
  parent: cmsAllPagesRoute,
};

export const cmsAllEmailsRoute: AppRoute = {
  authClaim: emailClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.emails.allEmailsUrl}`,
  pageTitle: 'modules.contentmanagement.emails.allemails',
  Component: () => import('./pages/Emails'),
};
export const cmsEditEmailRoute: AppRoute = {
  authClaim: emailClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.emails.emailDocumentEditUrl}`,
  pageTitle: 'modules.contentmanagement.emails.edit_email',
  Component: () => import('./pages/EditEmail'),
  parent: cmsAllEmailsRoute,
};
export const cmsCreateEmailRoute: AppRoute = {
  authClaim: emailClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.emails.emailDocumentCreateUrl}`,
  pageTitle: 'modules.contentmanagement.emails.create_email',
  Component: () => import('./pages/CreateEmail'),
  parent: cmsAllEmailsRoute,
};

export const allTermsPageRoute: AppRoute = {
  authClaim: termClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.terms.allTermsUrl}`,
  pageTitle: 'modules.contentmanagement.all_term_dictionaries',
  Component: () => import('./Terms/TermsHierachyPage'),
};

export const termsEditPageRoute: AppRoute = {
  authClaim: termClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.terms.termsDictionaryEditUrl}`,
  pageTitle: 'modules.contentmanagement.edit_term_dictionary',
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./Terms/TermsEditPage'),
  parent: allTermsPageRoute,
};

export const termsCreatePageRoute: AppRoute = {
  authClaim: termClaims,
  path: `${PATH_CMS_BASEURL}${cmsUrls.terms.termsDictionaryCreateUrl}`,
  pageTitle: 'modules.contentmanagement.add_term_dictionary',
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./Terms/TermsEditPage'),
  parent: allTermsPageRoute,
};

export const allNotificationsRoute: AppRoute = {
  authClaim: [
    AuthorizationClaims.CONTENTMANAGEMENT_NOTIFICATIONS,
    { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
  ],
  path: `${PATH_CMS_BASEURL}${cmsUrls.notifications.all}`,
  pageTitle: 'modules.contentmanagement.allNotifications',
  Component: () => import('./Notifications'),
};

export const editNotificationRoute: AppRoute = {
  authClaim: [
    AuthorizationClaims.CONTENTMANAGEMENT_NOTIFICATIONS,
    { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
  ],
  parent: allNotificationsRoute,
  path: `${PATH_CMS_BASEURL}${cmsUrls.notifications.edit}`,
  pageTitle: 'modules.contentmanagement.editNotification',
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./Notifications/NotificationsEdit'),
};

export const createNotificationRoute: AppRoute = {
  authClaim: [
    AuthorizationClaims.CONTENTMANAGEMENT_NOTIFICATIONS,
    { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
  ],
  parent: allNotificationsRoute,
  path: `${PATH_CMS_BASEURL}${cmsUrls.notifications.create}`,
  pageTitle: 'modules.contentmanagement.createNotification',
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./Notifications/NotificationsEdit'),
};

export const allPrintedDocumentsRoute: AppRoute = {
  authClaim: [
    AuthorizationClaims.CONTENTMANAGEMENT_PRINTED_DOCUMENTS,
    { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
    { [AuthorizationClaims.DAM]: UserAccessRights.ReadWrite },
  ],
  path: `${PATH_CMS_BASEURL}${cmsUrls.printeddocuments.all}`,
  pageTitle: 'modules.contentmanagement.printedDocuments.allPrintedDocuments',
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./PrintedDocuments'),
};

export const editPrintedDocumentsRoute: AppRoute = {
  authClaim: [
    AuthorizationClaims.CONTENTMANAGEMENT_PRINTED_DOCUMENTS,
    { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
  ],
  parent: allPrintedDocumentsRoute,
  path: `${PATH_CMS_BASEURL}${cmsUrls.printeddocuments.edit}`,
  pageTitle: 'modules.contentmanagement.printedDocuments.editPrintedDocuments',
  Component: () => import('./pages/EditPrintedDocument'),
};

export const cmsRoutes: AppRoute[] = [
  cmsAllPagesRoute,
  cmsEditPageRoute,
  cmsCreatePageRoute,
  cmsAllEmailsRoute,
  cmsEditEmailRoute,
  cmsCreateEmailRoute,
  allTermsPageRoute,
  termsEditPageRoute,
  termsCreatePageRoute,
  allNotificationsRoute,
  editNotificationRoute,
  createNotificationRoute,
  allPrintedDocumentsRoute,
  editPrintedDocumentsRoute,
];
