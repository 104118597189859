import {
  AppRouteBase,
  ExtendedClaims,
  ModuleConfig,
  RequiredFeatures,
  RouteGroup,
} from 'framework/components/navigation/Navigation.interfaces';
import { featureFlag } from 'featureFlag';
import { ensureFeature, ensureFeatures } from 'stores/features';
import { resolvePageGroups } from 'framework/components/navigation/UseNavigation';
import contentmanagement from './contentmanagement';
import slotManagement from './slotManagement';
import stores from './stores';
import products from './products';
import searchadmin from './searchadmin';
import merchandising from './merchandising';
import promotions from './promotions';
import orderManagement from './orderManagement';
import crm from './crm';
import orderFulfillment from './orderFulfillment';
import siteadmin from './siteadmin';
import { ReportsModule } from './reports';
import sitesettings from './siteSettings';

// All modules available in our app
const modules = async (): Promise<ModuleConfig[]> => {
  const allModules: ModuleConfig[] = [
    contentmanagement,
    slotManagement,
    stores,
    products,
    searchadmin,
    merchandising,
    promotions,
    orderManagement,
    crm,
    orderFulfillment,
    siteadmin,
    ReportsModule,
    sitesettings,
  ];

  // Filter out modules with no pageGroups after features check
  const filterUnusedModule = (module: ModuleConfig) => ((module.pageGroups as RouteGroup[]) ?? []).length !== 0;

  const localModules = (module: ModuleConfig): ModuleConfig => {
    const { pageGroups } = module;
    let isDevEnv: boolean = false;

    // Check the current environment
    featureFlag({
      dev: () => {
        isDevEnv = true;
      },
      prod: () => {},
    });

    const moduleFeatureCheck = (features: RequiredFeatures[], extendedClaims, routes, alwaysShowInDev, route) => {
      let localExtendedClaims: ExtendedClaims[] = extendedClaims;

      // Loop through all required features or claims with features for the route
      features.forEach((feature) => {
        // Check if the feature is enabled
        const isFeatureEnabled: boolean = ensureFeature(feature.feature);

        // If the feature is enabled, add the related extended claims to localExtendedClaims
        // If the feature is disabled, but the alwaysShowInDev is true and the current environment is development
        // then add the related claims to localExtendedClaims
        if ((isFeatureEnabled && extendedClaims) || (!isFeatureEnabled && isDevEnv && alwaysShowInDev)) {
          localExtendedClaims = localExtendedClaims?.concat(feature.extendedClaims);
        }
      });

      routes.push({ ...route, extendedClaims: localExtendedClaims });
      return routes;
    };

    return {
      ...module,
      pageGroups: (pageGroups as RouteGroup[])
        .map((gr) => {
          return {
            ...gr,
            routes: gr.routes.reduce<AppRouteBase[]>((routes: AppRouteBase[], route): AppRouteBase[] => {
              const { requiredFeatures, alwaysShowInDev, extendedClaims, claimUsedByFeatures } = route;

              // If there are no required features and feature based claim for this route, then return route without changes
              if (!requiredFeatures && !claimUsedByFeatures) return [...routes, route];

              // If claim by Feature prop is used, add claims to extended claim based on features while still showing the module
              if (claimUsedByFeatures) {
                return moduleFeatureCheck(claimUsedByFeatures, extendedClaims, routes, alwaysShowInDev, route);
              }

              if (!ensureFeatures(requiredFeatures) && !(isDevEnv && alwaysShowInDev)) return [...routes];

              // Extended claims needed for route
              return moduleFeatureCheck(requiredFeatures, extendedClaims, routes, alwaysShowInDev, route);
            }, []),
          };
          // Filter out page groups with zero routes after checking features
        })
        .filter((gr) => gr.routes?.length !== 0),
    };
  };

  return (await Promise.all(allModules.map(resolvePageGroups))).map(localModules).filter(filterUnusedModule);
};

export default modules;
