export const account = {
  path: [
    {
      d:
        'M8.5 8.5a4.262 4.262 0 0 0 4.25-4.25A4.262 4.262 0 0 0 8.5 0a4.262 4.262 0 0 0-4.25 4.25A4.262 4.262 0 0 0 8.5 8.5zm0 2.125c-2.869 0-8.5 1.381-8.5 4.25V17h17v-2.125c0-2.869-5.631-4.25-8.5-4.25z',
    },
  ],
  transform: 'translate(4 4)',
  viewBox: '0 0 25 25',
};

export const alarm = {
  path: [
    {
      d:
        'M20.171 4.427L15.722.694l-1.247 1.48 4.448 3.732 1.248-1.48zM6.516 2.173L5.278.693.828 4.418l1.249 1.48 4.439-3.724zm4.468 4.459H9.533v5.802l4.594 2.756.725-1.19-3.868-2.291V6.632zM10.5 2.763a8.705 8.705 0 0 0-8.704 8.704 8.698 8.698 0 0 0 8.704 8.704 8.705 8.705 0 0 0 0-17.408zm0 15.474a6.765 6.765 0 0 1-6.77-6.77 6.765 6.765 0 0 1 6.77-6.77 6.765 6.765 0 0 1 6.77 6.77 6.765 6.765 0 0 1-6.77 6.77z',
    },
  ],
  transform: 'translate(2 2)',
  viewBox: '0 0 25 25',
};

export const arrowDown = {
  path: [
    {
      d: 'M16.667 8.333l-1.47-1.468-5.822 5.812V0H7.292v12.677L1.479 6.854 0 8.334l8.333 8.333z',
    },
  ],
  transform: 'translate(4 4)',
  viewBox: '0 0 25 25',
};

export const arrowUp = {
  path: [
    {
      d: 'M.167 8.5l1.468 1.469 5.823-5.813v12.677h2.084V4.156l5.812 5.823 1.48-1.479L8.5.167z',
    },
  ],
  transform: 'translate(4 4)',
  viewBox: '0 0 25 25',
};

export const barcode = {
  path: [
    {
      d:
        'M0 0h2v12H0V0zm12 0h2v12h-2V0zM5 0h3v12H5V0zm10 0h3v12h-3V0zM3 0h1v12H3V0zm6 0h1v12H9V0zm10 0h1v12h-1V0z',
    },
  ],
  transform: 'translate(3 7)',
  viewBox: '0 0 25 25',
};

export const bell = {
  path: [
    {
      d:
        'M22,19 L22,20 L4,20 L4,19 L6,17 L6,11 C6,7.9 8.03,5.17 11,4.29 C11,4.19 11,4.1 11,4 C11,2.8954305 11.8954305,2 13,2 C14.1045695,2 15,2.8954305 15,4 C15,4.1 15,4.19 15,4.29 C17.97,5.17 20,7.9 20,11 L20,17 L22,19 M15,21 C15,22.1045695 14.1045695,23 13,23 C11.8954305,23 11,22.1045695 11,21',
    },
  ],
  transform: 'translate(0 0)',
  viewBox: '0 0 25 25',
};

export const calendar = {
  path: [
    {
      d:
        'M5.7 9.13H3.8v1.827h1.9V9.13zm4.75 0h-1.9v1.827h1.9V9.13zm3.8 0h-1.9v1.827h1.9V9.13zm2.639-7.03h-1.056V0h-2.11v2.1H5.277V0H3.167v2.1H2.11c-1.172 0-2.1.945-2.1 2.1L0 18.9C0 20.055.94 21 2.111 21H16.89A2.112 2.112 0 0 0 19 18.9V4.2c0-1.155-.95-2.1-2.111-2.1zm.211 17.074H1.9V7.304h15.2v11.87z',
    },
  ],
  transform: 'translate(3 2)',
  viewBox: '0 0 25 25',
};

export const calendarRange = {
  path: [
    {
      d:
        'M5.7 9.13H3.8v1.827h1.9V9.13zm4.75 0h-1.9v1.827h1.9V9.13zm3.8 0h-1.9v1.827h1.9V9.13zm2.639-7.03h-1.056V0h-2.11v2.1H5.277V0H3.167v2.1H2.11c-1.172 0-2.1.945-2.1 2.1L0 18.9C0 20.055.94 21 2.111 21H16.89A2.112 2.112 0 0 0 19 18.9V4.2c0-1.155-.95-2.1-2.111-2.1zm.211 17.074H1.9V7.304h15.2v11.87z',
    },
  ],
  transform: 'translate(3 2)',
  viewBox: '0 0 25 25',
};

export const cart = {
  path: [
    {
      d:
        'M6 16c-1.1 0-1.99.9-1.99 2S4.9 20 6 20s2-.9 2-2-.9-2-2-2zM0 0v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H6.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 19 2H4.21l-.94-2H0zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z',
    },
  ],
  transform: 'translate(2 3)',
  viewBox: '0 0 25 25',
};

export const cartAdded = {
  path: [
    {
      d:
        'M6 16c-1.1 0-1.99.9-1.99 2S4.9 20 6 20s2-.9 2-2-.9-2-2-2zM0 0v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H6.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49A1.003 1.003 0 0 0 19 2H4.21l-.94-2H0zm16 16c1.1 0 2 .9 2 2s-.9 2-2 2-1.99-.9-1.99-2 .89-2 1.99-2zM15 4.77L11.5 8 8 4.77h2V2h3v2.77h2z',
    },
  ],
  transform: 'translate(2 3)',
  viewBox: '0 0 25 25',
};

export const cartOff = {
  path: [
    {
      d:
        'M21.783 20.783L2.655 1.655 1.917.917l-.7-.7L0 1.434l4.207 4.207 2.118 4.466-1.294 2.348c-.153.268-.24.585-.24.92 0 1.054.863 1.917 1.917 1.917h7.15l1.322 1.322a1.914 1.914 0 0 0 1.112 3.47c.642 0 1.207-.317 1.552-.806L20.566 22l1.217-1.217zM7.11 13.375a.237.237 0 0 1-.24-.24l.029-.115.862-1.562h2.262l1.917 1.917H7.11zm7.791-1.917c.719 0 1.351-.393 1.677-.987l3.431-6.22a.961.961 0 0 0-.843-1.418h-12.9l8.635 8.625zM6.708 16.25a1.914 1.914 0 0 0-1.907 1.917c0 1.054.853 1.916 1.907 1.916a1.922 1.922 0 0 0 1.917-1.916 1.922 1.922 0 0 0-1.917-1.917z',
    },
  ],
  transform: 'translate(1 2)',
  viewBox: '0 0 25 25',
};

export const cartPlus = {
  path: [
    {
      d:
        'M10 8h2V5h3V3h-3V0h-2v3H7v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S4.9 21 6 21s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-9.83-3.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L18.42 3h-.01l-1.1 2-2.76 5H7.53l-.13-.27L5.16 5l-.95-2-.94-2H0v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H6.42c-.13 0-.25-.11-.25-.25z',
    },
  ],
  transform: 'translate(2 2)',
  viewBox: '0 0 25 25',
};

export const checkmark = {
  path: [
    {
      d: 'M6 11.2L1.8 7 .4 8.4 6 14 18 2 16.6.6z',
    },
  ],
  transform: 'translate(3 5)',
  viewBox: '0 0 25 25',
};

export const clipboardCheck = {
  path: [
    {
      d: 'M19.889 4.1h-4.412A3.177 3.177 0 0 0 12.5 2a3.177 3.177 0 0 0-2.977 2.1H5.111A2.112 2.112 0 0 0 3 6.2v14.7c0 1.155.95 2.1 2.111 2.1H19.89A2.112 2.112 0 0 0 22 20.9V6.2c0-1.155-.95-2.1-2.111-2.1zm-7.389 0c.58 0 1.056.473 1.056 1.05a1.056 1.056 0 0 1-2.112 0c0-.578.475-1.05 1.056-1.05zm-2.111 14.7-4.222-4.2 1.488-1.48 2.734 2.709 6.956-6.92 1.488 1.491-8.444 8.4z',
    },
  ],
  transform: 'translate(0 0)',
  viewBox: '0 0 25 25',
};

export const clock = {
  path: [
    {
      d:
        'M9.5 0C4.275 0 0 4.275 0 9.5S4.275 19 9.5 19 19 14.725 19 9.5 14.725 0 9.5 0zm0 17.1c-4.18 0-7.6-3.42-7.6-7.6s3.42-7.6 7.6-7.6 7.6 3.42 7.6 7.6-3.42 7.6-7.6 7.6zm.475-12.35H8.55v5.7l4.94 3.04.76-1.235L9.975 9.69V4.75z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const close = {
  path: [
    {
      d: 'M19 1.9L17.1 0 9.5 7.6 1.9 0 0 1.9l7.6 7.6L0 17.1 1.9 19l7.6-7.6 7.6 7.6 1.9-1.9-7.6-7.6z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const del = {
  path: [
    {
      d:
        'M9.5 0C4.275 0 0 4.275 0 9.5S4.275 19 9.5 19 19 14.725 19 9.5 14.725 0 9.5 0zm4.75 12.92l-1.33 1.33-3.42-3.42-3.42 3.42-1.33-1.33L8.17 9.5 4.75 6.08l1.33-1.33L9.5 8.17l3.42-3.42 1.33 1.33-3.42 3.42 3.42 3.42z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const desktop = {
  path: [
    {
      d:
        'M19.09 0H1.91A1.91 1.91 0 0 0 0 1.9v11.4c0 1.045.86 1.9 1.91 1.9h6.68v1.9H6.683V19h7.636v-1.9H12.41v-1.9h6.682A1.91 1.91 0 0 0 21 13.3V1.9C21 .855 20.14 0 19.09 0zm0 13.3H1.91V1.9h17.18v11.4z',
    },
  ],
  transform: 'translate(2 3)',
  viewBox: '0 0 25 25',
};

export const down = {
  path: [
    {
      d: 'M10.185 12.247H6.67V.108H4.33v12.14H.815L5.5 17.102z',
    },
  ],
  transform: 'translate(7 4)',
  viewBox: '0 0 25 25',
};

export const drag = {
  path: [
    {
      d:
        'M0 0h2v2H0V0zm0 3h2v2H0V3zm0 3h2v2H0V6zm0 3h2v2H0V9zm0 3h2v2H0v-2zM3 0h2v2H3V0zm0 3h2v2H3V3zm0 3h2v2H3V6zm0 3h2v2H3V9zm0 3h2v2H3v-2z',
    },
  ],
  transform: 'translate(10 5)',
  viewBox: '0 0 25 25',
};

export const drivesite = {
  path: [
    {
      d:
        'M18.667 0H2.333C1.05 0 0 1.181 0 2.625v15.75C0 19.819 1.05 21 2.333 21h16.334C19.95 21 21 19.819 21 18.375V2.625C21 1.181 19.95 0 18.667 0zm-5.834 18.375h-10.5v-5.25h10.5v5.25zm0-6.563h-10.5v-5.25h10.5v5.25zm5.834 6.563H14V6.562h4.667v11.813z',
    },
  ],
  viewBox: '0 0 21 21',
};

export const dropdownArrowDown = {
  path: [
    {
      d: 'M11.483.6L6.5 5.2 1.517.6 0 2l6.5 6L13 2z',
    },
  ],
  transform: 'translate(6 9)',
  viewBox: '0 0 25 25',
};

export const dropdownArrowLeft = {
  path: [
    {
      d: 'M8 1.528L6.478 0 0 6.5 6.478 13 8 11.473 3.055 6.5z',
    },
  ],
  transform: 'translate(9 6)',
  viewBox: '0 0 25 25',
};

export const dropdownArrowRight = {
  path: [
    {
      d: 'M1.6 0L0 1.517 5.257 6.5 0 11.483 1.6 13l6.857-6.5z',
    },
  ],
  transform: 'translate(9 6)',
  viewBox: '0 0 25 25',
};

export const dropdownArrowUp = {
  path: [
    {
      d: 'M6.5 0L0 6.857l1.517 1.6L6.5 3.2l4.983 5.257L13 6.857z',
    },
  ],
  transform: 'translate(6 8)',
  viewBox: '0 0 25 25',
};

export const email = {
  path: [
    {
      d:
        'M18 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V2l8 5 8-5v2z',
    },
  ],
  transform: 'translate(4 5)',
  viewBox: '0 0 25 25',
};

export const equal = {
  path: [
    {
      d: 'M17.117 4.857h-17V.271h17v4.586zm0 8.414h-17V8.686h17v4.585z',
    },
  ],
  transform: 'translate(6 8)',
  viewBox: '0 0 25 25',
};

export const eye = {
  path: [
    {
      d:
        'M10.5.469C5.727.469 1.651 3.384 0 7.5c1.651 4.116 5.727 7.031 10.5 7.031S19.349 11.616 21 7.5C19.349 3.384 15.273.469 10.5.469zm0 11.719c-2.635 0-4.773-2.1-4.773-4.688 0-2.588 2.138-4.688 4.773-4.688s4.773 2.1 4.773 4.688c0 2.588-2.138 4.688-4.773 4.688zm0-7.5c-1.585 0-2.864 1.256-2.864 2.812 0 1.556 1.28 2.813 2.864 2.813 1.585 0 2.864-1.257 2.864-2.813 0-1.556-1.28-2.813-2.864-2.813z',
    },
  ],
  transform: 'translate(2 5)',
  viewBox: '0 0 25 25',
};

export const eyeOff = {
  path: [
    {
      d:
        'M11.5 4.708A4.793 4.793 0 0 1 16.292 9.5c0 .623-.125 1.207-.345 1.754l2.798 2.798A11.325 11.325 0 0 0 22.032 9.5c-1.658-4.207-5.75-7.188-10.542-7.188-1.341 0-2.625.24-3.814.671l2.07 2.07a4.651 4.651 0 0 1 1.754-.345zM1.917 2.092l2.185 2.185.44.44A11.312 11.312 0 0 0 .959 9.5c1.658 4.207 5.75 7.188 10.542 7.188 1.485 0 2.904-.288 4.197-.805l.403.402 2.808 2.798 1.217-1.217L3.134.875 1.917 2.092zm5.3 5.3l1.485 1.485a2.704 2.704 0 0 0-.077.623 2.871 2.871 0 0 0 2.875 2.875c.21 0 .422-.029.623-.077l1.485 1.486a4.75 4.75 0 0 1-2.108.508A4.793 4.793 0 0 1 6.708 9.5c0-.757.192-1.466.508-2.108zm4.13-.748l3.018 3.019.02-.153a2.871 2.871 0 0 0-2.875-2.875l-.163.01z',
    },
  ],
  transform: 'translate(1 3)',
  viewBox: '0 0 25 25',
};

export const file = {
  path: [
    {
      d:
        'M16.889 0H2.11C.95 0 0 .95 0 2.111V16.89C0 18.05.95 19 2.111 19H16.89C18.05 19 19 18.05 19 16.889V2.11C19 .95 18.05 0 16.889 0zm-2.111 6.333H4.222v-2.11h10.556v2.11zm0 4.223H4.222V8.444h10.556v2.112zm-3.167 4.222H4.222v-2.111h7.39v2.11z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const fileDocument = {
  path: [
    {
      d:
        'M1.875 0C.844 0 .009.855.009 1.9L0 17.1C0 18.145.834 19 1.866 19h11.259C14.156 19 15 18.145 15 17.1V5.7L9.375 0h-7.5zm6.563 6.65V1.425l5.156 5.225H8.438z',
    },
  ],
  transform: 'translate(5 3)',
  viewBox: '0 0 25 25',
};

export const filter = {
  path: [
    {
      d: 'M6.611 12h3.778v-2H6.61v2zM0 0v2h17V0H0zm2.833 7h11.334V5H2.833v2z',
    },
  ],
  transform: 'translate(4 7)',
  viewBox: '0 0 25 25',
};

export const flag = {
  path: [
    {
      d: 'M9.4 2L9 0H0v17h2v-7h5.6l.4 2h7V2z',
    },
  ],
  transform: 'translate(5 4)',
  viewBox: '0 0 25 25',
};

export const folder = {
  path: [
    {
      d:
        'M8.4 0H2.1C.945 0 0 .956 0 2.125v12.75C0 16.044.945 17 2.1 17h16.8c1.155 0 2.1-.956 2.1-2.125V4.25c0-1.169-.945-2.125-2.1-2.125h-8.4L8.4 0z',
    },
  ],
  transform: 'translate(2 4)',
  viewBox: '0 0 25 25',
};

export const formatAlignLeft = {
  path: [
    {
      d:
        'M12.375 12.375H.875v1.917h11.5v-1.917zm0-7.667H.875v1.917h11.5V4.708zm-11.5 5.75h17.25V8.542H.875v1.916zm0 7.667h17.25v-1.917H.875v1.917zm0-17.25v1.917h17.25V.875H.875z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const formatBold = {
  path: [
    {
      d:
        'M9.6 7.275c1.083-.718 1.842-1.896 1.842-2.99C11.442 1.866 9.488 0 6.977 0H0v15h7.859C10.192 15 12 13.179 12 10.94c0-1.63-.96-3.022-2.4-3.665zM3.349 2.679h3.349c.926 0 1.674.717 1.674 1.607 0 .889-.748 1.607-1.674 1.607h-3.35V2.679zm3.907 9.642H3.349V9.107h3.907c.926 0 1.674.718 1.674 1.607 0 .89-.748 1.607-1.674 1.607z',
    },
  ],
  transform: 'translate(7 5)',
  viewBox: '0 0 25 25',
};

export const formatListBullet = {
  path: [
    {
      d:
        'M1.5 6.188A1.31 1.31 0 0 0 .187 7.5 1.31 1.31 0 0 0 1.5 8.813 1.31 1.31 0 0 0 2.813 7.5 1.31 1.31 0 0 0 1.5 6.187zm0-5.25A1.31 1.31 0 0 0 .187 2.25 1.31 1.31 0 0 0 1.5 3.563 1.31 1.31 0 0 0 2.813 2.25 1.31 1.31 0 0 0 1.5.937zm0 10.648a1.163 1.163 0 1 0-.001 2.327 1.163 1.163 0 0 0 .001-2.327zm2.625 2.039h12.25v-1.75H4.125v1.75zm0-5.25h12.25v-1.75H4.125v1.75zm0-7v1.75h12.25v-1.75H4.125z',
    },
  ],
  transform: 'translate(4 5)',
  viewBox: '0 0 25 25',
};

export const formatClear = {
  path: [
    {
      d:
        'M2.134.792L.917 2.009l6.68 6.68-2.368 5.52h2.875L9.61 10.7l5.424 5.424 1.217-1.217L2.402 1.05 2.134.792zm2.616 0v.172l2.702 2.703h2.3l-.69 1.61 2.013 2.012 1.543-3.622h5.549V.792H4.75z',
    },
  ],
  transform: 'translate(2 4)',
  viewBox: '0 0 25 25',
};

export const formatColor = {
  path: [
    {
      d:
        'M10.542.875L5.27 14.292h2.156L8.5 11.417h5.99l1.073 2.875h2.157L12.458.875h-1.916zM9.219 9.5L11.5 3.434 13.78 9.5H9.22zM0 17.167h23V21H0v-3.833z',
    },
  ],
  transform: 'translate(1 3)',
  viewBox: '0 0 25 25',
};

export const formatIndentDecrease = {
  path: [
    {
      d:
        'M8.542 14.292h9.583v-1.917H8.542v1.917zM.875 9.5l3.833 3.833V5.667L.875 9.5zm0 8.625h17.25v-1.917H.875v1.917zm0-17.25v1.917h17.25V.875H.875zm7.667 5.75h9.583V4.708H8.542v1.917zm0 3.833h9.583V8.542H8.542v1.916z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const formatIndentIncrease = {
  path: [
    {
      d:
        'M.875 18.125h17.25v-1.917H.875v1.917zm0-12.458v7.666L4.708 9.5.875 5.667zm7.667 8.625h9.583v-1.917H8.542v1.917zM.875.875v1.917h17.25V.875H.875zm7.667 5.75h9.583V4.708H8.542v1.917zm0 3.833h9.583V8.542H8.542v1.916z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const formatItalic = {
  path: [
    {
      d: 'M4 0v3h2.21l-3.42 8H0v3h8v-3H5.79l3.42-8H12V0z',
    },
  ],
  transform: 'translate(7 6)',
  viewBox: '0 0 25 25',
};

export const formatListNumber = {
  path: [
    {
      d:
        'M0 11.375h1.79v.438H.894v.874h.894v.438H0V14h2.684v-3.5H0v.875zM.895 3.5h.894V0H0v.875h.895V3.5zM0 6.125h1.61L0 7.963v.787h2.684v-.875h-1.61l1.61-1.838V5.25H0v.875zM4.474.875v1.75H17V.875H4.474zm0 12.25H17v-1.75H4.474v1.75zm0-5.25H17v-1.75H4.474v1.75z',
    },
  ],
  transform: 'translate(4 6)',
  viewBox: '0 0 25 25',
};

export const formatUnderline = {
  path: [
    {
      d:
        'M7.5 14.292a5.755 5.755 0 0 0 5.75-5.75V.875h-2.396v7.667A3.358 3.358 0 0 1 7.5 11.896a3.358 3.358 0 0 1-3.354-3.354V.875H1.75v7.667a5.755 5.755 0 0 0 5.75 5.75zM.792 16.208v1.917h13.416v-1.917H.792z',
    },
  ],
  transform: 'translate(5 4)',
  viewBox: '0 0 25 25',
};

export const griddle = {
  path: [
    {
      d: 'M0 0h2v2H0V0zm0 3h2v2H0V3zm0 3h2v2H0V6zm0 3h2v2H0V9zm0 3h2v2H0v-2zM3 0h2v2H3V0zm0 3h2v2H3V3zm0 3h2v2H3V6zm0 3h2v2H3V9zm0 3h2v2H3v-2z',
    },
  ],
  transform: 'translate(10 5)',
  viewBox: '0 0 25 25',
};

export const image = {
  path: [
    {
      d:
        'M19 16.889V2.11C19 .95 18.05 0 16.889 0H2.11C.95 0 0 .95 0 2.111V16.89C0 18.05.95 19 2.111 19H16.89C18.05 19 19 18.05 19 16.889zM5.75 11.5l2.679 3 3.75-4.5L17 16H2l3.75-4.5z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const inbox = {
  path: [
    {
      d:
        'M27.022 0H3.361C1.486 0 .017 1.503.017 3.378L0 27.022A3.362 3.362 0 0 0 3.36 30.4h23.662a3.388 3.388 0 0 0 3.378-3.378V3.378A3.377 3.377 0 0 0 27.022 0zm0 20.267h-6.755a5.07 5.07 0 0 1-5.067 5.066 5.07 5.07 0 0 1-5.067-5.066H3.361V3.377h23.661v16.89z',
    },
  ],
  transform: 'translate(2 2)',
  viewBox: '0 0 36 36',
};

export const info = {
  path: [
    {
      d:
        'M9.5 0C4.275 0 0 4.275 0 9.5S4.275 19 9.5 19 19 14.725 19 9.5 14.725 0 9.5 0zm.95 14.25h-1.9v-5.7h1.9v5.7zm0-7.6h-1.9v-1.9h1.9v1.9z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const keyboardReturn = {
  path: [
    {
      d: 'M15 12l-1.42-1.42L17.17 7H0V0h2v5h15.17l-3.59-3.58L15 0l6 6z',
    },
  ],
  transform: 'translate(2 8)',
  viewBox: '0 0 25 25',
};

export const language = {
  path: [
    {
      d:
        'M9.5 0C4.275 0 0 4.275 0 9.5S4.275 19 9.5 19 19 14.725 19 9.5 14.725 0 9.5 0zm6.555 5.7H13.3c-.285-1.235-.76-2.28-1.33-3.42 1.71.665 3.23 1.805 4.085 3.42zM9.5 1.9c.76 1.14 1.425 2.375 1.805 3.8h-3.61C8.075 4.37 8.74 3.04 9.5 1.9zm-7.315 9.5A6.125 6.125 0 0 1 1.9 9.5c0-.665.095-1.33.285-1.9h3.23c-.095.665-.095 1.235-.095 1.9 0 .665.095 1.235.095 1.9h-3.23zm.76 1.9H5.7c.285 1.235.76 2.28 1.33 3.42-1.71-.665-3.23-1.805-4.085-3.42zM5.7 5.7H2.945c.95-1.615 2.375-2.755 4.085-3.42C6.46 3.42 5.985 4.465 5.7 5.7zm3.8 11.4c-.76-1.14-1.425-2.375-1.805-3.8h3.61c-.38 1.33-1.045 2.66-1.805 3.8zm2.185-5.7h-4.37c-.095-.665-.19-1.235-.19-1.9 0-.665.095-1.235.19-1.9h4.465c.095.665.19 1.235.19 1.9 0 .665-.19 1.235-.285 1.9zm.285 5.32c.57-1.045 1.045-2.185 1.33-3.42h2.755c-.855 1.615-2.375 2.755-4.085 3.42zm1.71-5.32c.095-.665.095-1.235.095-1.9 0-.665-.095-1.235-.095-1.9h3.23c.19.57.285 1.235.285 1.9 0 .665-.095 1.33-.285 1.9h-3.23z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const launch = {
  path: [
    {
      d:
        'M16.889 16.889H2.11V2.11H9.5V0H2.111C.95 0 0 .95 0 2.111V16.89C0 18.05.95 19 2.111 19H16.89C18.05 19 19 18.05 19 16.889V9.5h-2.111v7.389zM11.61 0v2.111h3.8L5.067 12.456l1.477 1.477L16.89 3.59v3.8H19V0h-7.389z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const link = {
  path: [
    {
      d:
        'M1.805 4.5c0-1.539 1.32-2.79 2.945-2.79h3.8V0h-3.8C2.128 0 0 2.016 0 4.5S2.128 9 4.75 9h3.8V7.29h-3.8c-1.624 0-2.945-1.251-2.945-2.79zm3.895.9h7.6V3.6H5.7v1.8zM14.25 0h-3.8v1.71h3.8c1.624 0 2.945 1.251 2.945 2.79 0 1.539-1.32 2.79-2.945 2.79h-3.8V9h3.8C16.872 9 19 6.984 19 4.5S16.872 0 14.25 0z',
    },
  ],
  transform: 'translate(3 8)',
  viewBox: '0 0 25 25',
};

export const lock = {
  path: [
    {
      d:
        'M12.75 7h-.875V5.25A4.377 4.377 0 0 0 7.5.875 4.377 4.377 0 0 0 3.125 5.25V7H2.25C1.288 7 .5 7.787.5 8.75v8.75c0 .962.788 1.75 1.75 1.75h10.5c.963 0 1.75-.788 1.75-1.75V8.75c0-.963-.787-1.75-1.75-1.75zM7.5 14.875c-.963 0-1.75-.787-1.75-1.75s.787-1.75 1.75-1.75 1.75.787 1.75 1.75-.787 1.75-1.75 1.75zM10.213 7H4.788V5.25A2.715 2.715 0 0 1 7.5 2.538a2.715 2.715 0 0 1 2.713 2.712V7z',
    },
  ],
  transform: 'translate(5 2)',
  viewBox: '0 0 25 25',
};

export const mapMarker = {
  path: [
    {
      d:
        'M12.5 2.917c-3.709 0-6.708 3-6.708 6.708 0 5.031 6.708 12.458 6.708 12.458s6.708-7.427 6.708-12.458c0-3.709-3-6.708-6.708-6.708zm0 9.104a2.397 2.397 0 1 1 .002-4.794 2.397 2.397 0 0 1-.002 4.794z',
    },
  ],
  transform: null,
  viewBox: '0 0 25 25',
};

export const menu = {
  path: [
    {
      d: 'M0 12h19v-2H0v2zm0-5h19V5H0v2zm0-7v2h19V0H0z',
    },
  ],
  transform: 'translate(3 7)',
  viewBox: '0 0 25 25',
};

export const minus = {
  path: [
    {
      d: 'M13 2H0V0h13z',
    },
  ],
  transform: 'translate(6 12)',
  viewBox: '0 0 25 25',
};

export const mobile = {
  path: [
    {
      d:
        'M11.143.01L1.857 0C.836 0 0 .86 0 1.91v17.18C0 20.14.836 21 1.857 21h9.286C12.164 21 13 20.14 13 19.09V1.91c0-1.05-.836-1.9-1.857-1.9zm0 17.172H1.857V3.818h9.286v13.364z',
    },
  ],
  transform: 'translate(6 2)',
  viewBox: '0 0 25 25',
};

export const more = {
  path: [
    {
      d:
        'M2 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z',
    },
  ],
  transform: 'translate(11 5)',
  viewBox: '0 0 25 25',
};

export const pencil = {
  path: [
    {
      d:
        'M0 15.25V19h3.75L14.81 7.94l-3.75-3.75L0 15.25zM17.71 5.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
    },
  ],
  transform: 'translate(4 3)',
  viewBox: '0 0 25 25',
};

export const plus = {
  path: [
    {
      d: 'M19 10.857h-8.143V19H8.143v-8.143H0V8.143h8.143V0h2.714v8.143H19z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const product = {
  path: [
    {
      d:
        'M11.241 10.666L19 6.317v9.79L10.272 21v-9.79l.97-.544zm1.58-8.78L3.855 6.915.494 5.028 9.464 0l3.357 1.887zm5.61 3.142l-5.628 3.158-3.339 1.87-3.357-1.883-.511-.257 8.966-5.027 3.868 2.139zM4.917 11.515V9.132l3.747 2.096v9.72L0 16.09V6.37L3.312 8.22v2.492l1.606.802z',
    },
  ],
  transform: 'translate(3 2)',
  viewBox: '0 0 25 25',
};

export const promotion = {
  path: [
    {
      d:
        'M30.339 15.36L16.625 1.646a3.029 3.029 0 0 0-2.149-.884H3.81A3.057 3.057 0 0 0 .762 3.81v10.666c0 .838.335 1.6.899 2.164l13.714 13.714c.549.549 1.31.884 2.149.884.838 0 1.6-.335 2.148-.899L30.34 19.672a2.98 2.98 0 0 0 .9-2.148c0-.838-.351-1.615-.9-2.164zM6.095 8.381A2.283 2.283 0 0 1 3.81 6.095 2.283 2.283 0 0 1 6.095 3.81a2.283 2.283 0 0 1 2.286 2.285 2.283 2.283 0 0 1-2.286 2.286z',
    },
  ],
  transform: 'matrix(-1 0 0 1 32 0)',
  viewBox: '0 0 32 32',
};

export const puzzle = {
  path: [
    {
      d:
        'M16.738 9.048h-1.357v-3.62c0-.995-.814-1.809-1.81-1.809H9.952V2.262A2.24 2.24 0 0 0 7.69 0 2.24 2.24 0 0 0 5.43 2.262v1.357h-3.62C.815 3.619 0 4.433 0 5.429v3.438h1.357A2.433 2.433 0 0 1 3.8 11.31a2.433 2.433 0 0 1-2.443 2.442H0v3.438C0 18.186.814 19 1.81 19h3.438v-1.357A2.433 2.433 0 0 1 7.69 15.2a2.433 2.433 0 0 1 2.443 2.443V19h3.438c.996 0 1.81-.814 1.81-1.81v-3.619h1.357A2.24 2.24 0 0 0 19 11.31a2.24 2.24 0 0 0-2.262-2.262z',
    },
  ],
  transform: 'translate(4 2)',
  viewBox: '0 0 25 25',
};

export const radioBlank = {
  path: [
    {
      d:
        'M9.5 0C4.256 0 0 4.256 0 9.5S4.256 19 9.5 19 19 14.744 19 9.5 14.744 0 9.5 0zm0 17.1a7.598 7.598 0 0 1-7.6-7.6c0-4.199 3.401-7.6 7.6-7.6s7.6 3.401 7.6 7.6-3.401 7.6-7.6 7.6z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const radioMarked = {
  path: [
    {
      d:
        'M9.5 5.125A4.377 4.377 0 0 0 5.125 9.5 4.377 4.377 0 0 0 9.5 13.875 4.377 4.377 0 0 0 13.875 9.5 4.377 4.377 0 0 0 9.5 5.125zM9.5.75C4.67.75.75 4.67.75 9.5s3.92 8.75 8.75 8.75 8.75-3.92 8.75-8.75S14.33.75 9.5.75zm0 15.75c-3.867 0-7-3.133-7-7s3.133-7 7-7 7 3.133 7 7-3.133 7-7 7z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const redo = {
  path: [
    {
      d:
        'M10 1c-2.524 0-4.81.99-6.571 2.6L0 0v9h8.571L5.124 5.38C6.448 4.22 8.134 3.5 10 3.5c3.371 0 6.238 2.31 7.238 5.5l2.257-.78C18.171 4.03 14.43 1 10 1z',
    },
  ],
  transform: 'matrix(-1 0 0 1 22 8)',
  viewBox: '0 0 25 25',
};

export const right = {
  path: [
    {
      d: 'M16.99 4.5L13.413 0v3.375H0v2.25h13.413V9z',
    },
  ],
  transform: 'translate(4 8)',
  viewBox: '0 0 25 25',
};

export const search = {
  path: [
    {
      d:
        'M15 13.2h-.96l-.36-.36c1.2-1.32 1.92-3.12 1.92-5.04 0-4.32-3.48-7.8-7.8-7.8S0 3.48 0 7.8s3.48 7.8 7.8 7.8c1.92 0 3.72-.72 5.04-1.92l.36.36V15l6 6 1.8-1.8-6-6zm-7.2 0c-3 0-5.4-2.4-5.4-5.4 0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4z',
    },
  ],
  transform: 'translate(2 2)',
  viewBox: '0 0 25 25',
};

export const settings = {
  path: [
    {
      d:
        'M16.61 10.503c0-.287.097-.573.097-.955s0-.669-.097-.955l2.036-1.623c.193-.191.193-.382.097-.573l-1.939-3.342c-.097-.095-.29-.19-.581-.095l-2.423.955c-.485-.382-1.066-.669-1.648-.955L11.765.477C11.862.191 11.57 0 11.377 0H7.5c-.193 0-.484.191-.484.382L6.628 2.96c-.581.19-1.066.573-1.647.955L2.655 2.96c-.291-.096-.485 0-.679.19L.038 6.493c-.097.096 0 .382.194.573l2.035 1.528c0 .286-.097.573-.097.955s0 .668.097.955L.232 12.126c-.194.19-.194.382-.097.572l1.938 3.342c.097.096.291.191.582.096l2.423-.955c.484.382 1.066.668 1.647.955l.388 2.482c0 .191.194.382.484.382h3.877c.194 0 .484-.191.484-.382l.388-2.482c.582-.287 1.163-.573 1.648-.955l2.423.955c.193.095.484 0 .581-.191l1.938-3.342c.097-.19.097-.477-.097-.573l-2.229-1.527zM9.44 12.889c-1.842 0-3.392-1.527-3.392-3.341 0-1.814 1.55-3.342 3.392-3.342 1.841 0 3.392 1.528 3.392 3.342s-1.55 3.341-3.392 3.341z',
    },
  ],
  transform: 'translate(3 3)',
  viewBox: '0 0 25 25',
};

export const starHalf = {
  path: [
    {
      d:
        'M21 7.244l-7.55-.617L10.5 0 7.55 6.627 0 7.244l5.728 4.727L4.01 19l6.489-3.728 6.489 3.727-1.717-7.029L21 7.244zm-10.5 6.152V4.095l1.796 4.036 4.599.376-3.489 2.878 1.046 4.281-3.952-2.27z',
    },
  ],
  transform: 'translate(2 3)',
  viewBox: '0 0 25 25',
};

export const store = {
  path: [
    {
      d:
        'M19.833 0H1.167v2.375h18.666V0zM21 11.875V9.5l-1.167-5.938H1.167L0 9.5v2.375h1.167V19h11.666v-7.125H17.5V19h2.333v-7.125H21zm-10.5 4.75h-7v-4.75h7v4.75z',
    },
  ],
  transform: 'translate(2 3)',
  viewBox: '0 0 25 25',
};

export const swapHorizontal = {
  path: [
    {
      d:
        'M4.222 6.429L0 10.714 4.222 15v-3.214h7.39V9.643h-7.39V6.429zM19 4.286L14.778 0v3.214h-7.39v2.143h7.39v3.214L19 4.286z',
    },
  ],
  transform: 'translate(3 5)',
  viewBox: '0 0 25 25',
};

export const tablet = {
  path: [
    {
      d:
        'M14.763 0H2.237C1.002 0 0 .98 0 2.188v16.625C0 20.02 1.002 21 2.237 21h12.526C15.998 21 17 20.02 17 18.812V2.188C17 .98 15.998 0 14.763 0zM8.5 20.125c-.743 0-1.342-.586-1.342-1.313 0-.726.6-1.312 1.342-1.312.743 0 1.342.586 1.342 1.313 0 .726-.6 1.312-1.342 1.312zm6.71-3.5H1.79v-14h13.42v14z',
    },
  ],
  transform: 'translate(4 2)',
  viewBox: '0 0 25 25',
};

export const text = {
  path: [
    {
      d:
        'M3.756 10.4h5.488l.987 2.6h2.305L7.378 0H5.732L.464 13H2.77l.987-2.6zM6.5 2.364l2.085 5.909h-4.06L6.5 2.363z',
    },
  ],
  transform: 'translate(6 6)',
  viewBox: '0 0 25 25',
};

export const thumbUp = {
  path: [
    {
      d:
        'M0 19h3.818V7.6H0V19zM21 8.55c0-1.045-.86-1.9-1.91-1.9h-6.013l.955-4.37v-.285c0-.38-.191-.76-.382-1.045L12.6 0 6.3 6.27c-.382.285-.573.76-.573 1.33v9.5c0 1.045.86 1.9 1.91 1.9h8.59c.764 0 1.432-.475 1.718-1.14l2.864-6.745c.096-.19.096-.475.096-.665v-1.9H21c0 .095 0 0 0 0z',
    },
  ],
  transform: 'translate(2 2)',
  viewBox: '0 0 25 25',
};

export const trash = {
  path: [
    {
      d:
        'M1.071 16.889c0 1.161.965 2.111 2.143 2.111h8.572c1.178 0 2.143-.95 2.143-2.111V4.222H1.07V16.89zM15 1.056h-3.75L10.179 0H4.82L3.75 1.056H0v2.11h15v-2.11z',
    },
  ],
  transform: 'translate(5 3)',
  viewBox: '0 0 25 25',
};

export const truck = {
  path: [
    {
      d:
        'M18.137 3.75L21 7.5v4.689h-1.91c0 1.555-1.279 2.811-2.863 2.811-1.585 0-2.864-1.255-2.864-2.811H7.636C7.636 13.744 6.358 15 4.772 15 3.19 15 1.91 13.745 1.91 12.189H0V1.875C0 .845.86 0 1.91 0h13.363v3.75h2.864zM4.772 13.597c.793 0 1.433-.63 1.433-1.407 0-.78-.64-1.407-1.433-1.407-.792 0-1.431.628-1.431 1.407 0 .777.64 1.407 1.431 1.407zm12.887-8.44h-2.387V7.5h4.258l-1.87-2.343zm-1.431 8.44c.792 0 1.431-.63 1.431-1.407 0-.78-.64-1.407-1.431-1.407-.794 0-1.433.628-1.433 1.407 0 .777.64 1.407 1.433 1.407zM7.572 1.517v2.255h-3.28v3.382h3.28v2.255l3.826-3.946-3.826-3.946z',
    },
  ],
  transform: 'translate(2 6)',
  viewBox: '0 0 25 25',
};

export const undo = {
  path: [
    {
      d:
        'M10 1c-2.524 0-4.81.99-6.571 2.6L0 0v9h8.571L5.124 5.38C6.448 4.22 8.134 3.5 10 3.5c3.371 0 6.238 2.31 7.238 5.5l2.257-.78C18.171 4.03 14.43 1 10 1z',
    },
  ],
  transform: 'translate(3 8)',
  viewBox: '0 0 25 25',
};

export const up = {
  path: [
    {
      d: 'M10.185 12.244H6.671V.108H4.33v12.136H.815L5.5 17.098z',
    },
  ],
  transform: 'matrix(1 0 0 -1 7 21)',
  viewBox: '0 0 25 25',
};

export const question = {
  path: [
    {
      d:
        'M8 0c4.457 0 8 3.543 8 8s-3.543 8-8 8a7.95 7.95 0 0 1-8-8c0-4.457 3.543-8 8-8zm2.067 4.27c-.544-.453-1.28-.702-2.075-.702-.745 0-1.459.27-2.013.76a2.94 2.94 0 0 0-.976 1.873.856.856 0 0 0 .859.943c.441 0 .809-.325.858-.757a1.27 1.27 0 0 1 1.273-1.119c.945 0 1.281.553 1.281 1.026 0 .196-.44.607-.732.88-.55.513-1.174 1.096-1.174 1.921v.482c0 .469.387.85.863.85a.859.859 0 0 0 .863-.85v-.482c.035-.125.426-.491.636-.688.567-.53 1.27-1.187 1.27-2.113 0-.803-.331-1.522-.933-2.024zm-1.854 7.015a.858.858 0 1 0 .002 1.717.858.858 0 0 0-.002-1.717z',
    },
  ],
  transform: 'translate(2 7)',
  viewBox: '0 0 25 25',
};

export const feedback = {
  path: [
    {
      d:
        'M14.4 0H1.6C.72 0 .008.72.008 1.6L0 16l3.2-3.2h11.2c.88 0 1.6-.72 1.6-1.6V1.6c0-.88-.72-1.6-1.6-1.6z',
    },
  ],
  transform: 'translate(2 7)',
  viewBox: '0 0 25 25',
};

export const whatsnew = {
  path: [
    {
      d:
        'M1.875 0C.844 0 .009.855.009 1.9L0 17.1C0 18.145.834 19 1.866 19h11.259C14.156 19 15 18.145 15 17.1V5.7L9.375 0h-7.5zm6.563 6.65V1.425l5.156 5.225H8.438z',
    },
  ],
  transform: 'translate(2 6) scale(.85 .85)',
  viewBox: '0 0 25 25',
};

export const report = {
  path: [
    {
      d:
        'M9 27.61c0-1.634 0-3.305.032-4.975V18.774v-5.309c0-.594.255-.89.732-.89h3.504c.477 0 .732.296.732.89v14.218c0 .594-.287.891-.796.891H9.828c-.605 0-.828-.26-.828-.965zm9.032.012c0-1.613 0-3.262.031-4.985v-1.869c0-.99-.031-4.288-.031-7.88C18 8.162 18 2.774 18 1.455c0-.586.253-.88.728-.88h3.48c.476 0 .729.294.729.88.031 1.906.031 5.864.031 10.225C23 17.91 23 24.983 23 27.695c0 .586-.285.88-.791.88h-3.355c-.6 0-.822-.257-.822-.953zM27 27.599c0-1.65 0-3.337.032-5.025v-4.012-7.088c0-.6.255-.9.732-.9h3.504c.477 0 .732.3.732.9v16.2c0 .6-.287.9-.796.9h-3.376c-.605 0-.828-.262-.828-.975zM4.73 18.574c.546 0 .77.291.77.91v8.181c0 .618-.256.91-.801.91H1.3c-.577 0-.801-.292-.801-.946V23.01v-3.528c0-.618.256-.909.77-.909H4.73z',
    },
  ],
  transform: 'translate(1 3) scale(.85 .90)',
  viewBox: '0 0 30 30',
};

export const buyXgetY = {
  path: [
    {
      d: 'M99.246 1.862c1.781 0 3.239 1.457 3.239 3.238v11.334c0 .89-.357 1.7-.956 2.299L86.958 33.304c-.583.583-1.392.94-2.283.94-.89 0-1.7-.357-2.283-.956L71.06 21.955c-.6-.583-.955-1.393-.955-2.283 0-.89.372-1.717.955-2.3L85.63 2.803c.583-.584 1.393-.94 2.283-.94h11.333zm-68.632 8.166V25.3l-13.616 7.633V17.66l1.512-.85 12.104-6.782zm-29.64.081l5.166 2.889v3.887l2.507 1.251v-3.718l5.845 3.27V32.85L.974 25.273V10.109zm53.197-2.433v7.255h6.698v4.842h-6.698v7.57H49.07v-7.57h-6.717v-4.842h6.717V7.676h5.102zm-30.48-2.997l6.034 3.337-8.779 4.928-5.208 2.915-5.236-2.936-.798-.4L23.69 4.678zM15.738.173l5.236 2.943-13.987 7.843-5.243-2.943L15.738.173zM96.818 5.1c-1.344 0-2.429 1.085-2.429 2.429 0 1.344 1.085 2.428 2.429 2.428 1.344 0 2.428-1.084 2.428-2.428 0-1.344-1.084-2.429-2.428-2.429z',
    },
  ],
  transform: 'translate(10 14)',
  viewBox: '0 0 122 64',
};

export const bulk = {
  path: [
    {
      d: 'M70.042 40.962v12.531L58.87 59.756V47.225l1.24-.697 9.932-5.566zm45 0v12.531l-11.172 6.263V47.225l1.24-.697 9.932-5.566zm-90 0v12.531L13.87 59.756V47.225l1.24-.697 9.932-5.566zm20.68.067l4.24 2.37v3.19l2.056 1.026v-3.05l4.796 2.682v12.442L45.722 53.47V41.029zm45 0l4.24 2.37v3.19l2.056 1.026v-3.05l4.796 2.682v12.442L90.722 53.47V41.029zm-90 0l4.24 2.37v3.19l2.056 1.026v-3.05l4.796 2.682v12.442L.722 53.47V41.029zm63.64-4.456l4.95 2.738-7.202 4.043-4.274 2.393-4.296-2.41-.655-.328 11.477-6.436zm45 0l4.95 2.738-7.202 4.043-4.274 2.393-4.296-2.41-.655-.328 11.477-6.436zm-90 0l4.95 2.738-7.202 4.043-4.274 2.393-4.296-2.41-.655-.328 11.477-6.436zm38.474-3.697l4.297 2.415-11.477 6.435-4.302-2.415 11.482-6.435zm45 0l4.297 2.415-11.477 6.435-4.302-2.415 11.482-6.435zm-90 0l4.297 2.415-11.477 6.435-4.302-2.415 11.482-6.435zM48.042 8.962v12.531L36.87 27.756V15.225l1.24-.697 9.932-5.566zm44 0v12.531L80.87 27.756V15.225l1.24-.697 9.932-5.566zm-68.32.067l4.24 2.37v3.19l2.056 1.026v-3.05l4.796 2.682v12.442L23.722 21.47V9.029zm44 0l4.24 2.37v3.19l2.056 1.026v-3.05l4.796 2.682v12.442L67.722 21.47V9.029zm-25.36-4.456l4.95 2.738-7.202 4.043-4.274 2.393-4.296-2.41-.655-.328 11.477-6.436zm44 0l4.95 2.738-7.202 4.043-4.274 2.393-4.296-2.41-.655-.328 11.477-6.436zM35.836.876l4.297 2.415-11.477 6.435-4.302-2.415L35.836.876zm44 0l4.297 2.415-11.477 6.435-4.302-2.415L79.836.876z',
    },
  ],
  viewBox: '0 0 122 64',
};

export const bundle = {
  path: [
    {
      d: 'M29.64 10.028V25.3l-13.616 7.633V17.66l1.512-.85 12.104-6.782zm72 0V25.3l-13.616 7.633V17.66l1.512-.85 12.104-6.782zm72 0V25.3l-13.616 7.633V17.66l1.512-.85 12.104-6.782zM0 10.109l5.166 2.889v3.887l2.507 1.251v-3.718l5.845 3.27V32.85L0 25.273V10.109zm72 0l5.166 2.889v3.887l2.507 1.251v-3.718l5.845 3.27V32.85L72 25.273V10.109zm72 0l5.166 2.889v3.887l2.507 1.251v-3.718l5.845 3.27V32.85L144 25.273V10.109zM53.197 7.676v7.255h6.698v4.842h-6.698v7.57h-5.103v-7.57h-6.716v-4.842h6.716V7.676h5.103zm72 0v7.255h6.698v4.842h-6.698v7.57h-5.103v-7.57h-6.716v-4.842h6.716V7.676h5.103zM22.717 4.679l6.034 3.337-8.779 4.928-5.208 2.915-5.236-2.936-.798-.4 13.987-7.844zm72 0l6.034 3.337-8.779 4.928-5.208 2.915-5.236-2.936-.798-.4 13.987-7.844zm72 0l6.034 3.337-8.779 4.928-5.208 2.915-5.236-2.936-.798-.4 13.987-7.844zM14.764.173L20 3.116 6.013 10.959.77 8.016 14.764.173zm72 0L92 3.116l-13.987 7.843-5.243-2.943L86.764.173zm72 0L164 3.116l-13.987 7.843-5.243-2.943L158.764.173z',
    },
  ],
  transform: 'translate(-25 14)',
  viewBox: '0 0 122 64',
};

export const target = {
  path: [
    {
      d: 'M21.84 0c12.062 0 21.84 9.778 21.84 21.84s-9.778 21.84-21.84 21.84S0 33.902 0 21.84 9.778 0 21.84 0zm0 5.956c-8.772 0-15.884 7.112-15.884 15.884s7.112 15.884 15.884 15.884 15.884-7.112 15.884-15.884S30.612 5.956 21.84 5.956zm0 4.964c6.03 0 10.92 4.89 10.92 10.92s-4.89 10.92-10.92 10.92-10.92-4.89-10.92-10.92 4.89-10.92 10.92-10.92zm0 5.956a4.964 4.964 0 1 0 0 9.928 4.964 4.964 0 0 0 0-9.928z',
    },
  ],
  viewBox: '0 0 52 52',
};

export const warning = {
  path: [
    { d: 'M0 19h21L10.5 0 0 19zm11.455-3h-1.91v-2h1.91v2zm0-4h-1.91V8h1.91v4z' },
  ],
  viewBox: '0 0 25 25',
};

export const folderOutline = {
  path: [
    {
      d: 'M18.9 2.125h-8.4L8.4 0H2.1C.945 0 .01.956.01 2.125L0 14.875C0 16.044.945 17 2.1 17h16.8c1.155 0 2.1-.956 2.1-2.125V4.25c0-1.169-.945-2.125-2.1-2.125zm0 12.75H2.1V4.25h16.8v10.625z',
    },
  ],
  viewBox: '0 -4 25 25',
};

export const columns = {
  path: [
    {
      d: 'M8 0h3c.552 0 1 .448 1 1v17c0 .552-.448 1-1 1H8c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1zM1 0h3c.552 0 1 .448 1 1v17c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1zm14 0h3c.552 0 1 .448 1 1v17c0 .552-.448 1-1 1h-3c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1z',
    },
  ],
  transform: 'translate(4 4)',
  viewBox: '0 0 25 25',
};

export const grid = {
  path: [
    {
      d: 'M1 0h6c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1zm11 0h6c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1zM1 11h6c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1v-6c0-.552.448-1 1-1zm11 0h6c.552 0 1 .448 1 1v6c0 .552-.448 1-1 1h-6c-.552 0-1-.448-1-1v-6c0-.552.448-1 1-1z',
    },
  ],
  transform: 'translate(4 4)',
  viewBox: '0 0 25 25',
};

export const share = {
  path: [
    {
      d: 'M15.833 14.843c-.802 0-1.52.317-2.069.812L6.238 11.28c.053-.242.095-.485.095-.738s-.042-.495-.095-.738l7.442-4.333c.57.527 1.32.854 2.153.854C17.586 6.325 19 4.913 19 3.163S17.586 0 15.833 0c-1.752 0-3.166 1.413-3.166 3.163 0 .253.042.495.095.738L5.32 8.233C4.75 7.706 4 7.38 3.167 7.38 1.414 7.38 0 8.792 0 10.542s1.414 3.163 3.167 3.163c.834 0 1.583-.327 2.153-.854l7.516 4.385c-.053.222-.085.454-.085.686 0 1.697 1.383 3.078 3.082 3.078 1.7 0 3.083-1.381 3.083-3.078 0-1.698-1.383-3.079-3.083-3.079z',
    },
  ],
  transform: 'translate(4 4)',
  viewBox: '0 0 25 25',
};

export const speechBubble = {
  path:[
    {
      d: 'M19.4,5 L6.6,5 C5.72,5 5.008,5.72 5.008,6.6 L5,21 L8.2,17.8 L19.4,17.8 C20.28,17.8 21,17.08 21,16.2 L21,6.6 C21,5.72 20.28,5 19.4,5 Z'
    },
  ], 
  viewBox: '0 0 25 25',
};