import * as React from 'react';
import { ITabProps, Tabs, TABS_URL_PARAM } from 'framework/components/ui/Tabs';
import { ITabStepsProps, ITabStep } from './TabSteps.interfaces';

export const TabStepsRendererContainer = (props: ITabStepsProps) => {
  const {
    id,
    data,
    sticky,
    onLastTabFinish,
    initStore,
    clearStore,
    tabstepsInfo,
    displayCurrentStep,
    removeTabs,
    updateCurrentStepIndex,
    summary,
    isEditMode = false,
    allowPrevious = false,
    keepAnchors = false,
    className,
    defaultTab,
    disabled,
    onBeforeChange,
    unshiftLastAnchor,
  } = props;

  const getCurrentStep = () => tabstepsInfo?.steps[tabstepsInfo?.currentStep];

  const getTabs = () => data.map((tab, i) => {
    const step: ITabStep = tabstepsInfo?.steps[i];

    const TabStep: ITabProps = {
      ...tab,
      completed: !isEditMode && step?.isCompleted,
      disabled: !isEditMode && !step?.isEnabled,
    };

    return TabStep;
  });

  const afterTabChanged = (tabsid, stepid) => {
    // This event makes sense when the user clicks on a previous step
    // which is already completed to keep the Tabsteps current index in sync

    // TODO: This should be removed --> for now it looks like
    // reducer is not fired when user goes back to a previous page (see tabs.handleTabChange)

    updateCurrentStepIndex({
      tabsId: tabsid,
      stepId: stepid,
    });
  };

  const tabs = !tabstepsInfo ? [] : getTabs();

  let openTab = null;

  if (tabstepsInfo?.steps.length) {
    openTab = tabstepsInfo?.steps[tabstepsInfo?.currentStep]?.id;
  }

  React.useEffect(() => {
    initStore(props);

    return () => {
      removeTabs(id);
      clearStore(id);
    };
  }, []);

  React.useEffect(() => {
    const selectedTab = new URLSearchParams(window.location.search).get(TABS_URL_PARAM);

    if (selectedTab && openTab === null) {
      updateCurrentStepIndex({
        tabsId: id,
        stepId: selectedTab,
      });
    } else if (!isEditMode || allowPrevious) {
      const current = getCurrentStep();

      displayCurrentStep(id, current?.id, keepAnchors);
    }
  }, [id, openTab]);

  React.useEffect(() => {
    const previousStep: number = tabstepsInfo?.previousStep;

    // call the onTabComplete function for a tab if defined
    if (data[previousStep]?.onTabComplete
      && tabstepsInfo?.steps[previousStep]?.isCompleted
      && (previousStep + 1) !== data.length) {
      data[previousStep]?.onTabComplete();
    }

    return () => {
      const currentStep: number = tabstepsInfo?.currentStep;

      // call the onTabComplete and onLastTabFinish functions for the final tab
      if ((currentStep + 1) === data.length) {
        if (data[currentStep]?.onTabComplete) {
          data[currentStep]?.onTabComplete();
        }

        if (onLastTabFinish) {
          onLastTabFinish();
        }
      }
    };
  }, [tabstepsInfo?.previousStep]);

  return tabs.length ? (
    <Tabs
      id={id}
      data={tabs}
      sticky={sticky}
      openTab={openTab}
      afterTabChanged={afterTabChanged}
      summary={summary}
      isEditMode={isEditMode}
      className={className}
      defaultTab={defaultTab}
      disabled={disabled}
      onBeforeChange={onBeforeChange}
      unshiftLastAnchor={unshiftLastAnchor}
    />
  ) : <></>;
};

export default TabStepsRendererContainer;
