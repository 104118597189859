import { fetchData, fileUpload } from 'framework/api/fetch-data';
import { config } from 'config';
import { CmsDamFolder } from './Types';
import { translations } from './translations';
import i18n from 'i18n';
import * as uniqid from 'uniqid';
import * as get from 'lodash.get';


export const uploadFile = async ({ file, folders, path, overwrite, storeFolders }) => {
  setFolderBusy({ folders, busy: true, reference: path, storeFolders });

  const formData: any = new FormData();
  formData.append('file', file);

  return await fileUpload({
    url: `${config.apiEndpoints.cms}/dam/${path}`,
    method: 'POST',
    query: {
      overwrite
    },
    body: formData
  }).finally(() => {
    setFolderBusy({ folders, busy: false, reference: path, storeFolders });
  });
};

export const setFolderBusy = ({ folders, reference, busy, storeFolders }) => {
  const folderData: any[] = folders.map(folder => {
    if (folder.reference !== reference) {
      return folder;
    }
    return {
      ...folder,
      busy
    };
  });
  storeFolders(folderData);
};

export const fetchWithProgress = async ({ data, storeData, includeFiles }) => {
  await fetchFolders({ data, storeData, includeFiles });
};

export const fetchSubFolders = async ({ data, storeData, reference, includeFiles }) => {
  const isProcessed = !!data.find(f => f.reference === reference && f.processed);
  if (isProcessed) {
    return;
  }
  const expandingInProgress = data.filter(f => f.busy).length > 0;

  if (!expandingInProgress) {
    setFolderBusy({ folders: data, reference, busy: true, storeFolders: storeData });
    await fetchFolders({ reference, data, storeData, includeFiles });
  }
};

const fetchFolders = async ({ reference = null, data, storeData, includeFiles }) => {
  const response = await fetchData({
    url: `${config.apiEndpoints.cms}/dam${reference ? `/${reference}` : ''}`,
    query: {
      type: includeFiles ? undefined : 'directory'
    }
  });

  let responseData: any[] = Array.isArray(response.data)
    ? response.data
    : [];

  if (reference) {
    // const emptyFolder = includeFiles
    //   ? !responseData.length
    //   : !responseData.filter(d => d.type === 'directory').length;

    const noSubFolder = !responseData.filter(d => d.type === 'directory').length;

    if (noSubFolder) {
      responseData = [
        ...responseData,
        {
          name: i18n.t(translations.noContent),
          empty: true,
          parent: reference,
          type: 'directory',
          reference: uniqid()
        }
      ];
    }

    const noFile = !!responseData.filter(d => d.type === 'file');
    if (noFile) {
      responseData = [
        ...responseData,
        {
          name: i18n.t(translations.noContent),
          empty: true,
          parent: reference,
          type: 'file',
          reference: uniqid()
        }
      ];
    }

    const folderData = [
      ...data,
      ...assignParent({ reference, folders: responseData })
    ].map((f: CmsDamFolder) => {
      return {
        ...f,
        processed: f.reference === reference ? true : f.processed,
        busy: false
      } as CmsDamFolder;
    });

    // store folders into context
    storeData(folderData);
  } else {
    storeData(responseData);
  }
};

const assignParent = ({ reference, folders }) => {
  return folders.map(folder => {
    return {
      ...folder,
      parent: reference
    };
  });
};

export const deleteFile = async ({ reference, setUploadError }) => {
  const response = await fetchData({
    url: `${config.apiEndpoints.cms}/dam/${reference}`,
    method: 'DELETE'
  });
  if (response.error) {
    const error = get(response.error, 'error.errors.fileName', response.error);
    setUploadError(error);
  }
  return response.success;
};
