import * as React from 'react';
import {
  LayoutFlexBox, Modal, Form, TextArea,
} from 'ui';
import * as CONST from '../../constants';
import { Props, Actions } from './ModalCloseSlot.interfaces';

const ModalCloseSlot = (props: Props & Actions) => {
  const {
    isOpen,

    // Form props
    initialValues = {},

    // Actions
    addCloser,
    deleteCloser,
    closeModal,
  } = props;

  const formAction = isOpen ? addCloser : deleteCloser;

  return (
    <Modal
      name={CONST.MODAL_CLOSE_SLOT}
      size="small"
      caption={
        isOpen
          ? 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.title'
          : 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.titleClose'
      }
      messages={[
        {
          type: 'warning',
          caption: 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.message_01',
        },
      ]}
      footerOptions={[
        {
          show: !!isOpen,
          onClick: formAction,
          caption: 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.btnAdd',
        },
        {
          show: !isOpen,
          onClick: formAction,
          caption: 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.btnDelete',
        },
        {
          onClick: closeModal,
          outline: true,
          caption: 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.btnCancel',
        },
      ]}
    >
      <LayoutFlexBox flexWrap="wrap" className="mrg-btm-20">
        <Form name={CONST.FORM_CLOSE_SLOT} onSubmit={formAction} initialValues={initialValues}>
          <TextArea
            name={CONST.FIELD_CLOSE_SLOT_NOTE}
            optional
            caption="modules.slotManagement.storeCalendar.edit.modalCloseSlot.fieldNoteLabel"
            placeholder="modules.slotManagement.storeCalendar.edit.modalCloseSlot.fieldNotePlaceholder"
            explainerText="modules.slotManagement.storeCalendar.edit.modalCloseSlot.fieldNoteExplainer"
          />
        </Form>
      </LayoutFlexBox>
    </Modal>
  );
};

export default ModalCloseSlot;
