import { fork } from 'redux-saga/effects';

import watchMerchandisingRelevanceSaveChanges from './saveChanges';
import watchMerchandisingRelevanceClearData from './clearPageData';
import watchMerchandisingRelevanceReset from './reset';
import watchMerchandisingRelevanceResetToGlobal from './resetToGlobal';
import watchMerchandisingRelevanceGetAttributes from './getAttributes';
import {
  watchMerchandisingRelevanceGetSearchCurrent,
  watchMerchandisingRelevanceGetSearchPreview
} from './getSearchResults';
import { watchGetCurrentScore, watchGetPreviewScore } from './getProductScore';

export default [
  fork(watchMerchandisingRelevanceSaveChanges),
  fork(watchMerchandisingRelevanceClearData),
  fork(watchMerchandisingRelevanceReset),
  fork(watchMerchandisingRelevanceResetToGlobal),
  fork(watchMerchandisingRelevanceGetAttributes),
  fork(watchMerchandisingRelevanceGetSearchCurrent),
  fork(watchMerchandisingRelevanceGetSearchPreview),
  watchGetCurrentScore(),
  watchGetPreviewScore()
];
