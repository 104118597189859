import config, { baseApiUrl } from 'config';
import { FeatureState } from './types';
import { fetchData } from '../../framework/api/fetch-data';

export const isCustomersV2Enabled = async (useApiUrl: boolean = true): Promise<FeatureState> => {
  if (!baseApiUrl && useApiUrl) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData({
    url: `${config.apiEndpoints.featureFlags}/customers_v2`,
    method: 'HEAD',
  });

  // Status 200 = enabled, 404 = disabled
  return {
    isEnabled: result.status === 200,
  };
};