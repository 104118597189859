import { AuthorizationClaims, ensureClaims } from 'framework/authorization';
import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { rolesListRoute, userListRoute, userCreateRoute, roleCreateRoute } from './routes';

const SiteAdminModule: ModuleConfig = {
  name: 'siteadmin',
  appMenuTitle: 'navigation.siteAdmin',
  appMenuIcon: 'lock',
  modulePath: '/siteadmin',
  pageGroups: async () => {
    return [
      {
        title: 'modules.siteadmin.users',
        routes: [
          userListRoute,
          userCreateRoute,
        ],
      },
      {
        title: 'modules.siteadmin.roles',
        routes: [
          rolesListRoute,
          ...(ensureClaims(AuthorizationClaims.FULLACCESS) ? [roleCreateRoute] : []),
        ],
      },
    ];
  }
};

export default SiteAdminModule;
