// store Page after having been created
export const storePage = (state, action) => {
  return {
    ...state,
    loadedPage: {
      ...state.loadedPage,
      ...action.payload
    }
  };
};
