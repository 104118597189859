import * as React from 'react';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text } from 'framework/components/ui';
import { IHeaderWeek } from './HeaderWeek.interfaces';
import { HeaderWeekAdditional } from './HeaderDetails';
import './_style.scss';
import { useCalendar } from '../../Calendar.context';

const clx = prefixClassName('cal__headerWeek');
export const weekDays = [
  'components.calendar.weekDays.label.sunday',
  'components.calendar.weekDays.label.monday',
  'components.calendar.weekDays.label.tuesday',
  'components.calendar.weekDays.label.wednesday',
  'components.calendar.weekDays.label.thursday',
  'components.calendar.weekDays.label.friday',
  'components.calendar.weekDays.label.saturday',
];

export const weekDaysShort = [
  'components.calendar.weekDays.labelShort.sunday',
  'components.calendar.weekDays.labelShort.monday',
  'components.calendar.weekDays.labelShort.tuesday',
  'components.calendar.weekDays.labelShort.wednesday',
  'components.calendar.weekDays.labelShort.thursday',
  'components.calendar.weekDays.labelShort.friday',
  'components.calendar.weekDays.labelShort.saturday',
];

export const months = [
  'components.calendar.months.jan',
  'components.calendar.months.feb',
  'components.calendar.months.mar',
  'components.calendar.months.apr',
  'components.calendar.months.may',
  'components.calendar.months.jun',
  'components.calendar.months.jul',
  'components.calendar.months.aug',
  'components.calendar.months.sep',
  'components.calendar.months.oct',
  'components.calendar.months.nov',
  'components.calendar.months.dec',
];

export const isCurrentDay = (d: Date) => {
  const todaysDate = new Date();

  return d.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
};

export const LiveCalendarHeader = ({ date }: { date: Date }) => {
  const dateNum = date.getDate();
  const weekDay = weekDaysShort[date.getDay()];
  const month = months[date.getMonth()];

  return (
    <>
      <span className={`${clx}__day`}>
        <Text caption={weekDay} /> {dateNum}{' '}
      </span>
      <Text tag="span" className={`${clx}__month`} caption={month} />
    </>
  );
};

export const SlotTemplateHeader = ({ date }: { date: Date }) => {
  const weekDay = weekDays[date.getDay()];

  return (
    <span className={`${clx}__day--long`}>
      <Text caption={weekDay} />
    </span>
  );
};

export const HeaderWeek = (props: IHeaderWeek) => {
  const { date } = props;
  const { template } = useCalendar();

  return (
    <span className={classNames(clx, isCurrentDay(date) && `${clx}--currentDay`)}>
      {template === 'liveCalendar' && <LiveCalendarHeader date={date} />}
      {template === 'slotTemplates' && <SlotTemplateHeader date={date} />}
      <HeaderWeekAdditional date={date} />
    </span>
  );
};
