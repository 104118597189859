import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import TextFieldPrimitive, {
  PrimitiveTextProps,
} from 'framework/components/ui/FormComponents/Primitives/TextFieldPrimitive';
import { formField } from 'framework/components/hoc/formField';
import { Icon } from 'ui';
import classNames from 'classnames';
import './_style.scss';

export interface TextFieldProps extends PrimitiveTextProps {
  clearable?: boolean;
  hasError?: boolean;
  onClear?: () => void;
  suffix?: string;
}

const cn = prefixClassName('form-field');

export const TextField = (props: TextFieldProps) => {
  const {
    className,
    clearable,
    hasError,
    value,
    autocomplete = false,
    onChange,
    onClear,
    disabled,
    suffix,
  } = props;
  const shouldShowClearable = clearable && value && value !== '';

  const handleClearableOnClick = (e: Event) => {
    e.preventDefault();
    onChange('');
    if (onClear) {
      onClear();
    }
  };

  return (
    <>
      <TextFieldPrimitive
        {...props}
        autocomplete={autocomplete}
        className={classNames(
          cn,
          clearable && `${cn}--clearable`,
          hasError && `${cn}--error`,
          disabled && `${cn}--disabled`,
          className,
        )}
      />
      {shouldShowClearable && (
        <Icon
          name="del"
          className={`${cn}__clearable`}
          onClick={handleClearableOnClick}
          title="generic.options.delete"
        />
      )}
      {suffix && (
        <div className={`${cn}__suffix`}>{suffix}</div>
      )}
    </>
  );
};

export default formField(TextField);
