import * as React from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../../store/selectors';
import * as actions from '../../../store/actions';
import { ViewSelector } from './Toolbar.viewSelector';

const mapStateToProps = (state, ownProps) => ({
  view: selectors.getCalendarView(state, ownProps.calendarName),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectorView: (v: string) => dispatch(actions.updateSelectorView(ownProps.calendarName, v)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSelector) as any as any as React.StatelessComponent<any>;
