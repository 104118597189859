import classNames from 'classnames';
import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button, Text, Header, LayoutFlexBoxItem, LayoutFlexBox } from 'ui';
import { prefixClassName } from '../../_conf';
import { IMenu, INotificationLine } from './Menu.interfaces';
import { MenuButton } from './MenuButton';
import { NotificationLine } from './NotificationLine/NotificationLine';
import '../_styles.scss';
import { NotificationsTranslations } from './translations';

const clx = prefixClassName('notifications-menu');

export const Menu = (props: IMenu) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    className,
    notificationsSeen = [],
    notificationsNotSeen = [],
    onToggle,
    buttonText,
    onMarkAllAsSeen,
    onNotificationClick,
    onClickAllNotifications,
  } = props;

  // Combine notifications
  // Only show the first 5, with the not seen notifications always being prioritized by being spread fist
  const combinedNotifications: INotificationLine[] = [...notificationsNotSeen, ...notificationsSeen].slice(0, 5);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    onToggle?.(!isOpen);
  };
  const onOutsideClickHandler = () => {
    setIsOpen(false);
  };

  const handleClick = (notification: INotificationLine) => {
    onNotificationClick(notification);
  };

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClickHandler} disabled={!isOpen}>
      <div className={classNames(clx, isOpen && `${clx}--open`, className)}>
        <MenuButton
          className={`${clx}__button`}
          onClick={toggleOpen}
          active={isOpen}
          text={buttonText}
          icon="bell"
          notificationsNotSeenTotal={notificationsNotSeen.length}
        />
        {isOpen && (
          <div className={`${clx}__optionsContainer`}>
            <LayoutFlexBox
              alignContent="start"
              justifyContent="start"
              alignItems="center"
              flexItemsWidth="100%"
              className="mrg-btm-10"
            >
              <LayoutFlexBoxItem>
                <Header headerLevel={4} caption={NotificationsTranslations.Notifications} />
              </LayoutFlexBoxItem>
              {notificationsNotSeen.length > 0 && (
                <LayoutFlexBoxItem flexGrow={1}>
                  <Button
                    link
                    caption={NotificationsTranslations.MarkAllSeen}
                    className={`${clx}__link-button`}
                    onClick={onMarkAllAsSeen}
                    testId="markAllAsSeenBtn"
                  />
                </LayoutFlexBoxItem>
              )}
            </LayoutFlexBox>
            <ul className={`${clx}__optionsList`}>
              {combinedNotifications.length === 0 ? (
                <Text
                  caption={NotificationsTranslations.EmptyNotifications}
                  tag="div"
                  className={`${clx}__emptyList`}
                />
              ) : (
                combinedNotifications.map((notification: INotificationLine, key) => (
                  <NotificationLine
                    key={key}
                    {...notification}
                    seen={notification.seen}
                    onClick={() => handleClick(notification)}
                    className={classNames(`${clx}__option`, notification.className)}
                  />
                ))
              )}
            </ul>
            {combinedNotifications.length > 0 && (
              <Button
                link
                caption={NotificationsTranslations.AllNotifications}
                className={classNames(`${clx}__link-button`, 'mrg-top-20')}
                onClick={onClickAllNotifications}
                testId="allNotificationsBtn"
              />
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Menu;
