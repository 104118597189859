import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { inProgress } from 'framework/components/ui/ProgressBar/ProgressBar.actions';

/**
 * Handles nested levels of Progress bar.
 * If 2 functions that have a progress bar were called, the progress bar will end only when the last one finishes
 */
let localProgress = 0;

/**
 * Shows a progress bar while your function is running. Usage:
 *
 * function* fetchData(arg1, arg2) { ... }
 *
 * const fetchDataWithProgressBar = withProgressBar(fetchData);
 *
 * fetchDataWithProgressBar(1, 2); // will be wrapped with a progress bar
 * @param generator generator function - your process to be wrapped by a progress bar
 */
export function withProgressBar(generator) {
  return function* withProgressBarG(...args) {
    // Starts the progress bar
    localProgress += 1;
    yield put(inProgress(true));

    try {
      yield* generator(...args);
    } finally {
      // Ends the progress bar
      localProgress -= 1;
      if (localProgress === 0) {
        localProgress = 0;
        yield put(inProgress(false));
      }
    }
  };
}

export function* takeEveryWithProgressBar(action, generator) {
  yield takeEvery(action, withProgressBar(generator));
}
export function* takeLatestWithProgressBar(action, generator) {
  yield takeLatest(action, withProgressBar(generator));
}
