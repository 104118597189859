import { CheckboxPrimitive } from '../../FormComponents/Primitives';
import { Kebab } from '../../Kebab';
import { Img } from '../../Img';
import TableText from './TableText/TableText';
import TableButton from './TableButton/TableButton';

const tableCells: any = {
  TableImage: Img,
  TableText,
  Checkbox: CheckboxPrimitive,
  Button: TableButton,
  Img,
  Kebab,
};

export default tableCells;
