export default (
  state,
  payload: {
    calendarName: string;
    isLoading: boolean;
  }
) => {
  const { calendarName, isLoading } = payload;

  return {
    ...state,
    [calendarName]: {
      ...state[calendarName],
      isLoading: !!isLoading
    }
  };
};
