import i18n from 'i18n';
import { PluginItem, ToolbarButtons, MenuConfiguration } from './Types';

export const COLOR_MENU = 'color';
export const FORE_COLOR_MENU_ITEM = 'forecolor';
export const BACK_COLOR_MENU_ITEM = 'backcolor';

export const defaultPlugins: PluginItem[] = [
  'fullpage',
  'searchreplace',
  'directionality',
  'visualblocks',
  'visualchars',
  'fullscreen',
  'image',
  'link',
  'media',
  'table',
  'charmap',
  'hr',
  'anchor',
  'advlist',
  'lists',
  'wordcount',
  'imagetools',
  'textpattern',
  'noneditable',
  'code',
  'quickbars',
];

export const defaultToolbarButtons: ToolbarButtons = {
  format: ['bold', 'italic', 'underline', 'strikethrough', 'formatselect', 'removeformat'],
  insert: ['link', 'dam', 'media', 'pageembed'],
  align: ['alignleft', 'aligncenter', 'alignright'],
  lists: ['numlist', 'bullist', 'outdent', 'indent'],
  code: ['code', 'dataPlaceholder'],
  [COLOR_MENU]: [FORE_COLOR_MENU_ITEM, BACK_COLOR_MENU_ITEM],
};

export const defaultCustomMenus: MenuConfiguration = {
  insert: {
    title: () => i18n.t('modules.contentmanagement.editor.menus.insert'),
    items: [['dam', 'link', 'media', 'pageembed', 'inserttable', 'hr', 'anchor', 'charmap']],
  },
  format: {
    title: () => i18n.t('modules.contentmanagement.editor.menus.format'),
    items: [
      ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'code'],
      ['formats', 'blocks', 'align'],
      ['removeformat'],
      [FORE_COLOR_MENU_ITEM, BACK_COLOR_MENU_ITEM],
    ],
  },
};
