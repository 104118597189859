import { config } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { compareAsc } from 'framework/utils/helpers';
import { ISelectOptions } from '../Select';

export interface Timezone {
  id: string;
  utcOffset: number;
}

export const fetchTimezones = async () => {
  const { data } = await fetchData<Timezone[]>({ url: config.apiEndpoints.referencedata.timezones });
  const timezones: ISelectOptions[] = data.length ? [...data].sort(compareAsc('id')).map((tz) => ({
    value: tz.id,
    label: `(UTC ${tz.utcOffset}) ${tz.id.replace(/[/_]/g, ' ')}`,
  })) : [];

  return timezones;
};
