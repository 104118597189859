import './style.scss';
import * as React from 'react';
import {
  Form,
  LayoutFlexBox,
  LayoutFlexBoxItem,
  Panel,
  PasswordField,
  StatusDropdown,
  TextField,
  TimezoneSelector,
} from 'ui';
import { validators } from 'framework/components/hoc/formField';
import { prefixClassName } from 'framework/components/ui/_conf';
import { useUserInfo } from 'framework/components/globalhooks';
import { i18n } from 'i18n';
import { AuthorizationClaims, ensureClaims } from 'framework/authorization';
import { SITEADMIN_STATUS, USER_DETAILS_FORM } from '../CommonPage/CommonPage.Constants';
import { CommonPageDetailProps, CommonPageDetailActions, commonPageStatus } from './CommonPageDetails.Interfaces';
import { CommonPageDetailsTranslation } from './CommonPageDetails.Translation';

const userDetailsClassName = prefixClassName('siteadmin-user-details');

export const CommonPageDetails = ({
  user = null,
  currentUserProfile,
  editFlow,
  workInProgress,
  saveUser,
}: CommonPageDetailProps & CommonPageDetailActions) => {
  const translation = 'modules.contentmanagement.tab_editdetails.edit_details_form';
  const handleSaveUser = (formValues) => saveUser({ formValues });
  const userInfo = useUserInfo({ value: currentUserProfile.sub });
  
  const userCanSeePassWordField: boolean = user ? userInfo?.id === user?.id || ensureClaims(AuthorizationClaims.POWER_USER) || ensureClaims(AuthorizationClaims.CUSTOMER_USER_PASSWORD) : true;

  return (
    <LayoutFlexBoxItem className={userDetailsClassName}>
      <Form name={USER_DETAILS_FORM} initialValues={user} onSubmit={handleSaveUser} stencilled={workInProgress}>
        <Panel
          messages={[{ caption: 'modules.siteadmin.createUserPage.tab1Message', showLabel: false }]}
          title="modules.siteadmin.createUserPage.tab1Title"
          subtitle="modules.siteadmin.createUserPage.linkUsageInfo"
        >
          <LayoutFlexBox flexDirection="column">
            <LayoutFlexBoxItem width="50%">
              <TextField
                className="field-control"
                caption="components.form.firstName"
                name="firstName"
                placeholder="modules.siteadmin.firstNamePlaceholder"
                mandatory
                grid
              />
            </LayoutFlexBoxItem>
            <LayoutFlexBoxItem width="50%">
              <TextField
                className="field-control"
                caption="components.form.familyName"
                name="familyName"
                placeholder="modules.siteadmin.familyNamePlaceholder"
                mandatory
                grid
              />
            </LayoutFlexBoxItem>
            <LayoutFlexBoxItem width="100%">
              <TextField
                className="field-control"
                caption="components.form.email"
                explainerText="components.form.emailExplainer"
                name="email"
                placeholder="modules.siteadmin.emailPlaceholder"
                mandatory
                grid
                validate={[validators.isEmail]}
              />
            </LayoutFlexBoxItem>
            {userCanSeePassWordField &&
              <LayoutFlexBoxItem width="100%">
                <PasswordField
                  caption="components.form.password"
                  name="password"
                  grid
                  placeholder="modules.siteadmin.passwordPlaceholder"
                  mandatory={!user}
                  className={`${userDetailsClassName}__password field-control`}
                />
              </LayoutFlexBoxItem>
            }

            <LayoutFlexBoxItem width="100%">
              <TimezoneSelector
                className="field-control"
                name="timeZone"
                caption={`${translation}.timezone.caption`}
                explainerText={`${translation}.timezone.explanatoryText`}
                mandatory
                searchable
                grid
                suggestions={[userInfo.timeZone]}
              />
            </LayoutFlexBoxItem>
          </LayoutFlexBox>

          <Panel.Footer
            options={[
              {
                formToSubmit: USER_DETAILS_FORM,
                caption: editFlow
                  ? 'modules.siteadmin.form.saveUserDetails'
                  : 'modules.siteadmin.form.saveContinueAssignStores',
              },
              {
                formToReset: USER_DETAILS_FORM,
                caption: 'components.form.reset',
              },
            ]}
          >
            {user && (
              <StatusDropdown
                labelInline
                caption={SITEADMIN_STATUS}
                name="status"
                options={[
                  {
                    caption: i18n.t(CommonPageDetailsTranslation.Active),
                    value: commonPageStatus.Active,
                    colorMapping: commonPageStatus.Active,
                  },
                  {
                    caption: i18n.t(CommonPageDetailsTranslation.Inactive),
                    value: commonPageStatus.Inactive,
                    colorMapping: commonPageStatus.Inactive,
                  },
                ]}
              />
            )}
          </Panel.Footer>
        </Panel>
      </Form>
    </LayoutFlexBoxItem>
  );
};
