import Form from './Form.wrapper';
import * as actions from './Form.actions';
import reducer from './Form.reducer';
import * as selectors from './Form.selectors';
import * as types from './Form.interface';

export default Form;
export {
  actions, reducer, selectors, types,
};
export { handleFormErrors } from './Form.Error';
