import * as React from 'react';
import { formField } from 'framework/components/hoc/formField';
import { SelectPrimitive } from '../Primitives/SelectPrimitive';
import { ISelectProps } from './interfaces';

export const Select = (props: ISelectProps) => {
  const { value, searchable = false, onBlur } = props;

  // We need to do this: https://github.com/erikras/redux-form/issues/1185#issuecomment-227121795
  const onBlurHandler = () => {
    onBlur(value);
  };

  return (
    <SelectPrimitive multi={false} {...props} onBlur={onBlurHandler} searchable={searchable} />
  );
};

export default formField(Select);
