import { call, select, put } from 'redux-saga/effects';
import * as CONST from 'framework/modules/searchadmin/constants';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { config } from 'config';
import { makeRequest } from 'framework/api/make-request';
import { formSelectors } from 'framework/selectors';
import { tooltipActions } from 'framework/actions';
import { processRulesDataForPreview } from './helpers';

/**
 * Gets the current product score
 */
export function watchGetCurrentScore() {
  return takeEveryWithProgressBar(
    CONST.ACTION_RULES_CURRENT_SCORE_EXPLANATION,
    fetchScore.bind(null, getCommomRequestParts),
  );
}

/**
 * Gets the preview product score
 */
export function watchGetPreviewScore() {
  return takeEveryWithProgressBar(
    CONST.ACTION_RULES_PREVIEW_SCORE_EXPLANATION,
    fetchScore.bind(null, getCommomRequestPartsWithPreview),
  );
}

function* getCommomRequestParts(payload) {
  const { productId } = payload;
  const formData = yield select(formSelectors.getFormValues, CONST.FORM_RULES_CREATE_RULES);
  const localeId = CONST.DEFAULT_LOCALE_ID;
  const storeId = (formData.previewStoreId && formData.previewStoreId.value) || CONST.DEFAULT_STORE_ID;

  return {
    url: `${
      config.apiEndpoints.search
    }/locale/${localeId}/store/${storeId}/products/${productId}/explain`,
    body: {
      q: formData.searchForProducts,
      take: 10,
      skip: 0,
    },
  };
}

function* getCommomRequestPartsWithPreview(payload) {
  const formData = yield select(formSelectors.getFormValues, CONST.FORM_RULES_CREATE_RULES);
  const configurationOverride = processRulesDataForPreview(formData);
  const commonRequest = yield call(getCommomRequestParts, payload);

  return {
    url: commonRequest.url,
    body: {
      ...commonRequest.body,
      configurationOverride,
    },
  };
}

export function* fetchScore(getRequestFn, { type, payload }) {
  const request = yield call(getRequestFn, payload);

  // Make Request
  const { data = {} } = yield call(makeRequest, {
    name: type,
    method: 'POST',
    url: request.url,
    body: request.body,
  });

  // Store Tooltip Data in store
  yield put(tooltipActions.storeTooltipData({ id: payload.productId, data }));
}
