import * as React from 'react';
import clx from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { /* Loader, */ Message } from 'framework/components/ui';
import { DamContentHierarchy } from './DamContentHierarchy';
import { DamContentGrid } from './DamContentGrid';
import { DamUploadFile } from './DamUploadFile';
import { DamMode } from './Types';

const cssClass = prefixClassName('dam');

interface DamContentProp {
  mode: any;
  folders: any;
  loading: any;

  files: any;

  toggleExpandFolder: any;
  expandedFolders;

  highlightedFolder: any;
  toggleHighlightFolder: any;

  overWriteFile: any;
  setOverWriteFile: any;

  uploadError: any;

  disableDelete: any;

  onFileDeleted: any;
  onFileSelected: any;
}

export const DamContent = ({
  mode,
  folders,
  loading,

  files,

  toggleExpandFolder,
  expandedFolders,

  highlightedFolder,
  toggleHighlightFolder,

  overWriteFile,
  setOverWriteFile,

  uploadError,

  disableDelete,

  onFileDeleted,
  onFileSelected,
}: DamContentProp) => {
  const displayError = !loading && uploadError;
  const displayUpload = mode === DamMode.directory;
  const displayHierarchy = displayUpload ? !loading && !!folders : !!folders;

  return (
    <>
      {displayError && <Message type="error" className={`${cssClass}__error`} caption={uploadError} />}
      {displayHierarchy && (
        <div className={`${cssClass}__content`}>
          <div
            className={clx(
              `${cssClass}__treeview-container`,
              !displayUpload && `${cssClass}__treeview-container--file-mode`
            )}
          >
            <DamContentHierarchy
              mode={mode}
              data={folders}
              highlightedFolder={highlightedFolder}
              expandedFolders={expandedFolders}
              toggleExpandFolder={toggleExpandFolder}
              toggleHighlight={toggleHighlightFolder}
            />
          </div>
          {/* {displayLoader && <Loader size='lg' />} */}
          {!displayUpload && (
            <div className={`${cssClass}__files-container`}>
              <DamContentGrid
                files={files}
                selectFile={onFileSelected}
                deleteFile={onFileDeleted}
                disableDelete={disableDelete}
                loading={loading}
              />
            </div>
          )}
        </div>
      )}
      {!loading && displayUpload && (
        <DamUploadFile
          overWriteFile={overWriteFile}
          toggleOverWriteFile={() => setOverWriteFile(!overWriteFile)}
          onFileSelected={onFileSelected}
        />
      )}
    </>
  );
};
