import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import * as CONST from './constants';
import {
  orderListRoute,
  personalShoppersRoutes,
  personalShoppersCreateRoutes,
  locationServicesRoute,
  // reportsRoute,
  settingsRoute,
  printingSettingsRoute,
  mpsSettingsRoute,
  productionCentersRoute,
  productionCentersCreateRoute,
  locationServicesCreateRoute,
  fcListRoute,
  printOrderSummaryListRoute,
  wavesListRoute,
} from './routes';

const OrderFulfillmentModule: ModuleConfig = {
  name: 'orderFulfillment',
  appMenuTitle: 'navigation.orderFulfillment',
  appMenuIcon: 'inbox',
  modulePath: CONST.PATH_MAIN,
  pageGroups: [
    {
      title: 'modules.orderFulfillment.fulfillmentCenters.groupTitle',
      routes: [fcListRoute],
      bottomDivider: true,
    },
    {
      title: 'modules.orderFulfillment.ordersList.groupTitle',
      routes: [orderListRoute, wavesListRoute, printOrderSummaryListRoute],
    },
    {
      title: 'modules.orderFulfillment.personalShoppers.groupTitle',
      routes: [personalShoppersRoutes, personalShoppersCreateRoutes],
    },
    {
      title: 'modules.orderFulfillment.locationServices.groupTitle',
      routes: [locationServicesRoute, locationServicesCreateRoute],
    },
    {
      title: 'modules.orderFulfillment.productionCenters.groupTitle',
      routes: [productionCentersRoute, productionCentersCreateRoute],
    },
    // {
    //   title: 'modules.orderFulfillment.reports.groupTitle',
    //   routes: [reportsRoute],
    // },
    {
      title: 'modules.orderFulfillment.settings.groupTitle',
      routes: [settingsRoute, printingSettingsRoute, mpsSettingsRoute],
    },
    // {
    //   title: 'modules.orderFulfillment.documentSetup.groupTitle',
    //   hideFromRoleAssociation: true,
    //   routes: [documentsSettingsRoute],
    // },
  ],
};

export default OrderFulfillmentModule;
