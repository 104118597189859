const DEFAULT_TIMEOUT = 1000; // 1 second
const DEFAULT_RETRIES = 5;

export async function retryPromise<T>(
  fn: () => Promise<T>,
  timeout: number = DEFAULT_TIMEOUT,
  times: number = DEFAULT_RETRIES,
) {
  let retries = times;
  return new Promise<T>((resolve, reject) => {
    const retryLoop = () => {
      fn().then(resolve).catch(() => {
        if (retries) {
          retries -= 1;
          setTimeout(retryLoop, timeout);
        } else {
          reject();
        }
      });
    };
    retryLoop();
  });
}
