import { call, select } from 'redux-saga/effects';
import { config, DEFAULT_LOCALE_ID } from 'config';
import { objectHelper } from 'framework/utils';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest } from 'framework/api/make-request';
import uuidv4 from 'uuid/v4';
import { PATH_RULES_EDIT_FULLPATH, ACTION_RULES_SAVE_RULE } from 'framework/modules/searchadmin/constants';
import { history } from 'framework/store';
import { toastError, toastSuccess } from 'stores/toast';
import { processRulesDataForSave } from './helpers';
import { selectors } from '../redux';
import { positionsStore } from '../components/Actions/ActionsPosition/ActionsPosition';

export function* process({ payload }) {
  const formData = yield select(selectors.getFormValues);
  const localeId = DEFAULT_LOCALE_ID; // In future, this will come from somewhere else

  // 1. Make Save Request
  const processedRulesData = processRulesDataForSave(formData);
  const ruleId = payload.ruleId || uuidv4();

  const { success, error = {} } = yield call(makeRequest, {
    name: 'saveRule',
    method: 'PUT',
    body: { ...processedRulesData },
    url: config.apiEndpoints.searchRules.update.replace('{localeId}', localeId).replace('{ruleId}', ruleId),
  });

  // 2. Navigate to Rules List Page
  if (success) {
    // TODO: create proper module that would expose history object
    toastSuccess('modules.merchandising.rules.list.saveSuccess');
    // reset new positions array
    positionsStore.newPositions = [];
    if (!payload.ruleId) {
      history.push(`${PATH_RULES_EDIT_FULLPATH}/${ruleId}`);
    }
  } else {
    const { errors }: { errors: { value: string | string[] } } = error;

    if (objectHelper.isEmpty(errors ?? {})) {
      // Generic Error message
      toastError('modules.merchandising.rules.list.messageError.update');
    } else {
      Object.values(errors).forEach((value) => {
        toastError(value);
      });
    }
  }
}

export default function* watchSaveRuleAction() {
  yield takeEveryWithProgressBar(ACTION_RULES_SAVE_RULE, process);
}
