import * as React from 'react';
import { Currency, useCurrencies } from 'framework/components/globalhooks';
import { IformField } from 'framework/components/hoc/formField';
import { compareAsc } from 'framework/utils/helpers';
import { Select, ISelectProps, ISelectOptions } from '../Select';

type Props = ISelectProps & IformField;

const CurrencySelector = (props: Props) => {
  const currencies: ISelectOptions[] = useCurrencies()
    .map((currency: Currency) => ({
      label: `${currency.name} (${currency.code})`,
      value: currency.code
    }))
    .filter((value, index, arr) => arr.findIndex(v => v.label === value.label) === index)
    .sort(compareAsc('label'));

  return (
    <Select
      options={currencies}
      {...props}
    />
  );
};

export default CurrencySelector;
