import * as React from 'react';
import { Page } from 'framework/components/ui';
import { PageProps } from 'framework/components/ui/Page/types';
import { CommonPageTabs } from './CommonPage.Tabs';
import { CommonPageProps, CommonPageActions } from './CommonPage.Interfaces';
import { useCommonPage } from './CommonPage.Functions';

type Props = PageProps<any> & CommonPageProps & CommonPageActions;

export const CommonPage = (props: Props) => {
  const {
    userid, loadUser, clearUser, storePageFlow, user, pageInfo, ...rest
  } = props;
  useCommonPage({ ...props });
  const PageParts = {
    content: CommonPageTabs,
  };
  return (
    <Page {...rest} pageParts={PageParts} />
  );
};
