import React from 'react';
import {
  HierarchyFilters,
  useHierarchyFilters,
  HierarchyFiltersProps,
  DefaultFilterName,
} from 'framework/components/ui/HierarchyFilters';
import { StoresFilter, StoresFilterProps } from './StoresFilter';

interface IStoresFilterSearchableProps {
  hasFilter?: boolean;
}

export type StoresFilterSearchableProps<TValues = {}> = StoresFilterProps &
Omit<HierarchyFiltersProps<TValues>, 'onFilterChange'> & IStoresFilterSearchableProps;

export function StoresFilterSearchable<TValues>({
  orgEndpoint,
  formFilterName = DefaultFilterName,
  filterComponents,
  customFilterFunction,
  stretch = 'panel',
  topBorder = 'top-border',
  onFetchStores,
  onChange,
  disabled,
  additionalColumns,
  className,
  hasFilter = true,
  actions,
  showBulkActions,
  ...rest
}: StoresFilterSearchableProps<TValues>) {
  const {
    handleOnFetchStores, onFilter, filtered: filteredData, isFiltered,  handleOnChange,
  } = useHierarchyFilters<TValues>({
    customFilterFunction,
    formFilterName,
  });

  return (
    <>
      {hasFilter && (
        <HierarchyFilters
          onFilterChange={onFilter}
          filterComponents={filterComponents}
          formFilterName={formFilterName}
          stretch={stretch}
          topBorder={topBorder}
        />
      )}
      <StoresFilter
        {...rest}
        orgEndpoint={orgEndpoint}
        isFiltered={isFiltered}
        onChange={handleOnChange(onChange)}
        filteredData={filteredData}
        onFetchStores={handleOnFetchStores(onFetchStores)}
        stretch={stretch}
        disabled={disabled}
        additionalColumns={additionalColumns}
        className={className}
        actions={actions}
        showBulkActions={showBulkActions}
      />
    </>
  );
}
