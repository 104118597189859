import * as React from 'react';
import {
  LayoutFlexBox,
  LayoutFlexBoxItem,
  Modal,
  Form,
  TimePicker,
  DatePicker,
  NumericField,
  TagsField,
  TextField,
  Select,
} from 'ui';
import * as CONST from '../../constants';
import { Props, Actions } from './ModalEditSlot.interfaces';
import { FulfillmentTypes } from '../../../SlotsTemplatesEdit/pageParts/constants';

const ModalEditSlot = (props: Props & Actions) => {
  const {
    // Data
    isOpen,
    isExpired = false,
    initialValues = {},

    // Form props
    // hasOrders = false,
    formIsEditMode = true,

    // Actions
    saveSlot,
    closeSlot,
    fulfilmentType,
  } = props;

  const footerOptions = isExpired ? undefined : [
    {
      formToSubmit: CONST.FORM_EDIT_SLOT,
      caption: 'modules.slotManagement.storeCalendar.edit.modalEditSlot.optionSave',
      show: formIsEditMode,
    },
    ...(isExpired ? [] : [{
      caption: isOpen
        ? 'modules.slotManagement.storeCalendar.edit.modalEditSlot.optionClose'
        : 'modules.slotManagement.storeCalendar.edit.modalEditSlot.optionOpen',
      onClick: closeSlot,
      outline: true,
    }]),
    {
      formToReset: CONST.FORM_EDIT_SLOT,
      show: formIsEditMode,
    },
  ];

  return (
    <Modal
      name={CONST.MODAL_EDIT_SLOT}
      caption={isExpired
        ? 'modules.slotManagement.storeCalendar.edit.modalEditSlot.titleExpired'
        : 'modules.slotManagement.storeCalendar.edit.modalEditSlot.title'
      }
      footerOptions={footerOptions}
    >
      <LayoutFlexBox flexWrap="wrap" className="mrg-btm-20">
        <Form name={CONST.FORM_EDIT_SLOT} onSubmit={saveSlot} initialValues={initialValues}>
          <TextField
            name={CONST.FIELD_EDIT_SLOT_SHOPPINGID}
            className="mrg-btm-15"
            caption='modules.slotManagement.shoppingMode'
            disabled
            explainerText
          />
          <Select
            grid
            name={CONST.FIELD_EDIT_SLOT_FULFILLMENT}
            caption="modules.slotManagement.templates.edit.modalSlot.fieldFulfillmentLabel"
            className="mrg-btm-15"
            placeholder="modules.slotManagement.templates.edit.modalSlot.fieldFulfillmentPlaceholder"
            explainerText="modules.slotManagement.templates.edit.modalSlot.fieldFulfillmentDescription"
            disabled
            clearable={false}
          />
          <LayoutFlexBox className="mrg-btm-15">
            <LayoutFlexBoxItem flexGrow={0} flexBasis="25%" className="mrg-right-10">
              <TimePicker
                readOnly
                name={CONST.FIELD_EDIT_SLOT_TIME_INI}
                caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldTimeIniLabel"
              />
            </LayoutFlexBoxItem>
            <LayoutFlexBoxItem flexGrow={0} flexBasis="75%">
              <TimePicker
                readOnly
                name={CONST.FIELD_EDIT_SLOT_TIME_END}
                caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldTimeEndLabel"
                explainerText="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldTimeEndExplainer"
                explainerWidth={68}
              />
            </LayoutFlexBoxItem>
          </LayoutFlexBox>

          {fulfilmentType?.toLowerCase() !== FulfillmentTypes.Instacart.toLowerCase()
            ? (<NumericField
                grid
                mandatory
                disabled={!formIsEditMode}
                min={0}
                max={1000}
                allowDecimals={false}
                name={CONST.FIELD_EDIT_SLOT_CAPACITY}
                caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityLabel"
                placeholder="0"
                explainerText="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityExplainer"
              />)
            : (<TextField
                name={`${CONST.FIELD_EDIT_SLOT_CAPACITY}-unlimited`}
                caption="modules.slotManagement.templates.edit.modalSlot.fieldCapacityLabel"
                placeholder="generic.unlimited"
                explainerText="modules.slotManagement.templates.edit.modalSlot.fieldCapacityDescription"
                disabled
              />)
          }

          <NumericField
            grid
            disabled
            name={CONST.FIELD_EDIT_SLOT_ORDERS}
            caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityOrdersLabel"
            placeholder="0"
            explainerText=" "
          />

          {fulfilmentType?.toLowerCase() !== FulfillmentTypes.Instacart.toLowerCase()
            ? (<NumericField
                grid
                disabled
                name={CONST.FIELD_EDIT_SLOT_CAPACITY_AVAILABLE}
                caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityRemainingLabel"
                placeholder="0"
                explainerText=" "
              />)
            : (<TextField
                name={`${CONST.FIELD_EDIT_SLOT_CAPACITY_AVAILABLE}-unlimited`}
                caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityRemainingLabel"
                placeholder="generic.unlimited"
                disabled
                explainerText=" "
              />)
          }
          
          <NumericField
            grid
            disabled
            name={CONST.FIELD_EDIT_SLOT_PRICE}
            caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldPriceLabel"
            placeholder="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldPricePlaceholder"
            explainerText=" "
          />

          <DatePicker
            grid
            disabled
            name={CONST.FIELD_EDIT_SLOT_CUTOFF}
            caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCutoffLabel"
            placeholder="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCutoffPlaceholder"
            explainerText="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCutoffExplainer"
          />

          <DatePicker
            grid
            disabled
            name={CONST.FIELD_EDIT_SLOT_PICK_BY_TIME}
            caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldPickByTimeLabel"
            placeholder="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldPickByTimePlaceholder"
            explainerText="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldPickByTimeExplainer"
          />

          <TagsField
            grid
            disabled
            name={CONST.FIELD_EDIT_SLOT_TAGS}
            caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldTagsLabel"
            placeholder="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldTagsPlaceholder"
            explainerText="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldTagsExplainer"
          />
        </Form>
      </LayoutFlexBox>
    </Modal>
  );
};

export default ModalEditSlot;
