import { prefixClassName } from 'framework/components/ui/_conf';

export const SHARED_CSS = prefixClassName('shared-manager');
export const SHARED_MANAGER_MODAL = 'GLOBAL_MANAGER_MODAL';
export const SHARED_MANAGER_SELECT_COMPONENT_MODAL = 'SHARED_MANAGER_SELECT_COMPONENT_MODAL';
export const SHARED_COMPONENTS_DOCUMENT_TYPE_URL = 'sharedcomponents';
export const SHARED_COMPONENTS_DOCUMENT_TYPE = 'SharedComponent';
export const MODAL_HELPER = 'MODAL_HELPER';
export const HISTORY_MODAL = 'HISTORY_MODAL';
export const LOCATIONS_MODAL = 'LOCATIONS_MODAL';
export const LOCATIONS_FORM_MODAL = 'LOCATIONS_FORM_MODAL';
export const RETURN_KEY = 13;
export const COMPONENT_NAME_FORM = 'COMPONENT_NAME_FORM';
export const MOVE_TO_FOLDER_FORM = 'MOVE_TO_FOLDER_FORM';
