import { fork } from 'redux-saga/effects';
import { watchContentManagementEvents } from './contentmanagement_sagas';
import sagaCmsContent from './_sagaCmsContent';
import sagaCmsCreatePage from './CreatePage';
import sagaCmsSeo from './_sagaCmsSeo';
import sagaCmsPageVariant from './PageVariant';
import sagaCmsPageHierarchy from './_sagaCmsPageHierarchy';
import sagaCmsPageDetails from './SavePageDetails';
import watchCmsPageUpdateStatus from './_sagaCmsPageStatus';
import watchCreateVariant from './CreateVariant';
import { watchDeletePage } from './DeletePage';
import { watchSaveCmsContent } from './SaveCmsContent';
import { watchSaveScheduleData } from './SaveScheduleData';

export default [
  fork(watchContentManagementEvents),
  fork(sagaCmsContent),
  fork(sagaCmsCreatePage),
  fork(sagaCmsSeo),
  fork(sagaCmsPageVariant),
  fork(sagaCmsPageHierarchy),
  fork(sagaCmsPageDetails),
  fork(watchCmsPageUpdateStatus),
  fork(watchCreateVariant),
  fork(watchDeletePage),
  fork(watchSaveCmsContent),
  fork(watchSaveScheduleData)
];
