import { put, takeEvery, select } from 'redux-saga/effects';
import { calendarActions, modalActions } from 'framework/actions';
import { formSelectors } from 'framework/selectors';
import { VIEWCALENDAR_SLOT_CLOSE_TOGGLE } from '../actions.constants';
import * as CONST_PAGE from '../../constants';
import { selectors } from '..';

export function* process() {
  const formValues = yield select(formSelectors.getFormValues, CONST_PAGE.FORM_CLOSE_SLOT);
  const selectedSlot: any = yield select(selectors.getEventSelected);
  const selectedSlotData: any = yield select(selectors.getEventSelectedData);

  const updatedSlot: any = {
    ...selectedSlot,
    data: {
      ...selectedSlotData,
      isClosed: !selectedSlotData.isClosed,
      isClosedNotes: formValues[CONST_PAGE.FIELD_CLOSE_SLOT_NOTE],
    },
  };

  yield put(modalActions.createCloseModalAction(CONST_PAGE.MODAL_CLOSE_SLOT));
  yield put(calendarActions.addEvent(CONST_PAGE.CALENDAR_NAME, updatedSlot));
}

export default function* watchEditCalendarSlotCloseToggleAction() {
  yield takeEvery(VIEWCALENDAR_SLOT_CLOSE_TOGGLE, process);
}
