import { put, call } from 'redux-saga/effects';
import { takeEveryWithConfirmation } from 'framework/sagas/extensions/confirmation';
import { withProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest } from 'framework/api/make-request';
import * as get from 'lodash.get';
import { toastError, toastSuccess } from 'stores/toast';
import {
  ACTION_CMS_DELETE_PAGE,
  DELETE_CMS_PAGE,
  RELOAD_PAGE_HIERARCHY,
} from '../contentemanagement.constants';
import { config } from '../../../../config';
import * as cmsActions from '../actions';

function* processDeletePage({ payload }) {
  const { documentId, variantId } = payload;
  let response = yield call(makeRequest, {
    method: 'DELETE',
    url: `${config.apiEndpoints.cms}/documents/${documentId}/variants/${variantId}`,
    name: DELETE_CMS_PAGE,
  });

  if (response.error) {
    yield put(toastError(response.error, 5000));
  } else {
    yield put(toastSuccess('generic.data.deletedSuccess'));

    // refresh page hierarchy
    response = yield call(makeRequest, {
      method: 'GET',
      url: `${config.apiEndpoints.cms}/folders/pages`,
      name: RELOAD_PAGE_HIERARCHY,
    });

    if (!response.error) {
      yield put(
        cmsActions.storePagesHierarchy({
          cmsPagesHierarchy: get(response, 'data.children', []),
        }),
      );
    }
  }
}

export function* watchDeletePage() {
  yield takeEveryWithConfirmation(ACTION_CMS_DELETE_PAGE, withProgressBar(processDeletePage));
}
