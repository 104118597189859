import { ISelectPrimitiveOptionsProps } from 'framework/components/ui/FormComponents/Primitives/SelectPrimitive/SelectPrimitive.interface';
import * as React from 'react';
import { Icon, LayoutFlexBox, LayoutFlexBoxItem, Select, Multiselect, TagsField } from 'ui';
import { FormAction } from 'redux-form';
import { featureFlag } from '../../../../../../../featureFlag';

interface IConditionsTemplate {
  field: string;
  fieldIndex: number;
  fieldValues: any;
  remove: Function;
  stores: ISelectPrimitiveOptionsProps<string>[];
  fetchCurrentData: () => void;
  setFieldValueToNull: Function;
  conditionFieldOptions: ISelectPrimitiveOptionsProps<string>[];
  filterConditionOperationOptions: ISelectPrimitiveOptionsProps<string>[];
  changeFormValue: (field: string, newValue: any, touch?: boolean, persistentSubmitErrors?: boolean) => FormAction;
}

export default (props: IConditionsTemplate) => {
  const {
    field,
    fieldIndex,
    fieldValues,
    remove,
    stores,
    fetchCurrentData,
    setFieldValueToNull,
    conditionFieldOptions,
    filterConditionOperationOptions,
    changeFormValue,
  } = props;

  const onDeleteClickHandler = () => {
    remove(fieldIndex);
    fetchCurrentData();
  };

  const onSearchTermChangeHandler = (e) => {
    if (e.value === 'store.storeId') {
      setFieldValueToNull(`${field}.condition_search_terms_textarea`);
    } else if (e.value === 'store.defaultCategory.category.raw') {
      setFieldValueToNull(`${field}.condition_search_terms_select`);
    }
  };

  const onSelectStoreHandler = (conditionStores) => {
    featureFlag({
      prod: () => {},
      dev: () => {
        if (conditionStores?.length === 1) {
          changeFormValue('previewStoreId', conditionStores[0].value);
        }
      },
    });

    fetchCurrentData();
  };

  const conditionTerm = (fieldValues.field && fieldValues.field.value) || fieldValues.field;

  const isStoreSelected = conditionTerm === 'store.storeId';

  return (
    <LayoutFlexBox flexWrap="nowrap">
      <LayoutFlexBoxItem className="mwg-widget mrg-btm-20" flexBasis="90%">
        <LayoutFlexBox justifyContent="space-between">
          <LayoutFlexBoxItem flexBasis="60%" className="mrg-right-10">
            <Select
              mandatory
              caption="modules.merchandising.rules.create.andLabel"
              labelInline
              name={`${field}.field`}
              clearable={false}
              options={conditionFieldOptions}
              placeholder="Select..."
              onChange={onSearchTermChangeHandler}
            />
          </LayoutFlexBoxItem>

          <LayoutFlexBoxItem flexBasis="35%">
            <Select
              mandatory
              name={`${field}.operation`}
              clearable={false}
              options={filterConditionOperationOptions}
              placeholder="Select..."
              onChange={fetchCurrentData}
            />
          </LayoutFlexBoxItem>
        </LayoutFlexBox>
        {isStoreSelected ? (
          <Multiselect
            mandatory
            searchable
            name={`${field}.condition_search_terms_select`}
            className="mrg-top-20"
            options={stores}
            onChange={(conditionStores) => onSelectStoreHandler(conditionStores)}
          />
        ) : (
          <TagsField
            mandatory
            name={`${field}.condition_search_terms_textarea`}
            className="mrg-top-20"
            divider=","
            onChange={fetchCurrentData}
          />
        )}
      </LayoutFlexBoxItem>
      <LayoutFlexBoxItem flexBasis="10%">
        <Icon
          onClick={onDeleteClickHandler}
          className="mrg-auto mrg-top-20 block cursor-pointer"
          name="trash"
          size="sm"
          color="#96a0ab"
        />
      </LayoutFlexBoxItem>
    </LayoutFlexBox>
  );
};
