import { call, put, select } from 'redux-saga/effects';
import { takeLatestWithProgressBar } from 'framework/sagas/extensions/progress';
import * as CONST from 'framework/modules/searchadmin/constants';
import { formSelectors } from 'framework/selectors';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { apiClientActions } from 'framework/actions';
import { actions } from '../index';
import { config } from 'config';

export default function* watchMerchandisingRelevanceGetAttributes() {
  yield takeLatestWithProgressBar(CONST.ACTION_RELEVANCE_ATTRIBUTES_GET, process);
}

function* process({ payload }) {
  const { storeId = CONST.DEFAULT_STORE_ID, locales = CONST.DEFAULT_LOCALE_ID } = payload;

  yield put(
    apiClientActions.clearApiClientStateAction({ name: CONST.API_DATA_PROVIDER_RELEVANCE_CURRENT })
  );
  yield put(
    apiClientActions.clearApiClientStateAction({ name: CONST.API_DATA_PROVIDER_RELEVANCE_PREVIEW })
  );

  // 1. Make Request
  const response = yield call(makeRequestAndSaveState, {
    name: CONST.API_DATA_PROVIDER_RELEVANCE_ATTRIBUTES,
    method: 'GET',
    url: `${config.apiEndpoints.searchRelevance}/locale/${locales}/store/${storeId}`
  });

  if (response.success) {
    // 2. Get Up-to-date data for Current DataGrid
    const formDataGeneral = yield select(
      formSelectors.getFormValues,
      CONST.FORM_RELEVANCE_RELEVANCE_GENERAL
    );
    const formDataAtts = yield select(
      formSelectors.getFormValues,
      CONST.FORM_RELEVANCE_RELEVANCE_ATT
    );

    yield put(
      actions.getSearchResultsCurrent({
        storeId,
        locales,
        query: formDataGeneral.searchForProducts,
        attributes: formDataAtts.attributes.map(a => ({
          id: a.id,
          relevance: a.value
        }))
      })
    );
  }
}
