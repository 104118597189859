import * as React from 'react';
import * as classnames from 'classnames';
import { prefixClassName } from '../_conf';
import { IDivider } from './Divider.interfaces';
import './_styles.scss';

const clx = prefixClassName('divider');

export const Divider = (props: IDivider) => {
  const { className } = props;

  return <hr className={classnames(clx, className)} />;
};

export default Divider;
