import { ContentManagementReduxState } from 'framework/modules/contentmanagement/contentmanagement.interfaces';
import { SaveLayoutWidthArgs } from './Interfaces';

export const reducer = (state: ContentManagementReduxState, action:{
  type: string,
  payload: SaveLayoutWidthArgs
}) => {
  const { payload: { type: width } } = action;
  const { rowEditorData } = state;
  return {
    ...state,
    rowEditorData: {
      ...rowEditorData,
      width,
    },
  };
};
