import * as React from 'react';
import { errorHandler } from 'framework/services/errorHandler';
import { Text } from 'ui';

const CHUNK_LOAD_ERROR = 'chunkErrorReload';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

interface ErrorCatcherState {
  hasError: boolean;
  hasChunkError: boolean;
}

export const withErrorCatcher = <T extends {}>(Component: React.ComponentType<T>) => class WithErrorCatcher extends React.Component<T, ErrorCatcherState> {
  static displayName = `WithErrorCatcher(${getDisplayName(Component)})`;

  constructor(props) {
    super(props);
    this.state = { hasError: false, hasChunkError: false };
  }

  componentDidUpdate() {
    const { hasError, hasChunkError } = this.state;
    
    if (hasError) return;

    if (window?.sessionStorage && !hasChunkError) {
      window.sessionStorage.setItem(CHUNK_LOAD_ERROR, 'false');
    }
  }

  componentDidCatch(error) {
    errorHandler(error);

    if (window?.sessionStorage && error?.name && error.name === 'ChunkLoadError') {
      const hasRefreshed: boolean = JSON.parse(
        window.sessionStorage.getItem(CHUNK_LOAD_ERROR) || 'false'
      );

      if (!hasRefreshed) {
        window.sessionStorage.setItem(CHUNK_LOAD_ERROR, 'true');
        
        window.location.reload();
      }

      this.setState({ hasChunkError: true });

      return;
    }

    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;

    return hasError
      ? <div className="mrg-left-20">
          <Text tag="h3" caption="generic.crash" />
        </div>
      : <Component {...this.props} />;
  }
};

export default withErrorCatcher;
