import { safeGet } from 'framework/utils';
import * as helpers from './_helpers';

export default (
  state,
  payload: {
    calendarName: string;
    view: string;
    newDate: Date;
  }
) => {
  const { calendarName, newDate = new Date() } = payload;
  const stateCalendar = safeGet(state, calendarName, {});
  const view = safeGet(stateCalendar, 'settings.view');
  let dateIni;
  let dateEnd;

  // TODO: 'month' and 'agenda' scenarios are not considered

  switch (view) {
    case 'day': {
      dateIni = helpers.getDayPrevious(newDate);
      dateEnd = helpers.getDayNext(newDate);
      break;
    }
    case 'month':
    default: {
      dateIni = helpers.getMonday(newDate);
      dateEnd = helpers.getSunday(newDate);
    }
  }

  return {
    ...state,
    [calendarName]: {
      ...stateCalendar,
      settings: {
        ...stateCalendar.settings,
        date: newDate,
        dateIni,
        dateEnd
      }
    }
  };
};
