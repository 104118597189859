import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
// Need to import label like that or it will brake test, need to figure out why
import Label from 'framework/components/ui/FormComponents/Label/Label';
import { Text } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import withErrorCatcher from 'framework/components/hoc/withErrorCatcher';
import withStencils from 'framework/components/hoc/withStencils';
import { selectors as formSelectors } from 'framework/components/ui/FormComponents/Form';
import { ILinkProps } from 'framework/components/ui/Link/Link.interfaces';
import { LinkPrimitiveProps } from 'framework/components/ui/Link/Link';
import { IformField } from './fieldWrapper.interface';
import { Feedback } from './Feedback';

const classNamePrefix = prefixClassName('form-field-wrapper');
const gridClassNamePrefix = prefixClassName('form-field-wrapper-grid');
const classNameFormField = prefixClassName('form-field');

interface RenderLabelProps {
  caption: string;
  name: string;
  className: string;
}

interface RenderExplainerTextProps {
  explainerText: any;
  clsNamePrefix?: string;
  labelInline?: boolean;
  explainerWidth?: number;
  explainerTextUnderInput?: boolean;
  explainerLink?: Partial<ILinkProps & LinkPrimitiveProps>;
}

interface GridInputProps
  extends React.PropsWithChildren<{
    additionalLabel?: string; // This will render a text on the right side of the input field.
    name: string;
    caption: string;
    optional: boolean;
    explainerText: string;
    propsToFeedback;
    button: React.ComponentType;
    classNameLabel: string;
    classNameAdditionalLabel?: string;
    explainerLink?: Partial<ILinkProps & LinkPrimitiveProps>;
  }> {}

const RenderLabel = ({ caption, name, className }: RenderLabelProps) => (
  <>{caption && <Label caption={caption} name={name} className={className} />}</>
);

export const RenderExplainerText = React.memo(
  ({
    explainerText,
    clsNamePrefix,
    explainerWidth,
    labelInline,
    explainerTextUnderInput = false,
    explainerLink,
  }: RenderExplainerTextProps) => {
    // If explainerText is not provided.
    if (!explainerText) {
      return <></>;
    }

    // Translate and render text if explainerText is provided.
    if (typeof explainerText === 'string') {
      const style = labelInline ? {} : { width: `${explainerWidth}%` };
      return (
        <Text
          caption={explainerText}
          tag="span"
          className={
            explainerTextUnderInput ? `${clsNamePrefix}__explainer-under-input` : `${clsNamePrefix}__explainer`
          }
          style={style}
          url={explainerLink}
        />
      );
    }

    // Render what has been provided as is in all other cases.
    return explainerText;
  }
);

export const RenderOptionalText = () => (
  <Text tag="span" caption="components.form.optionalValue" className={`${classNamePrefix}__optional`} />
);

const GridInput = ({
  additionalLabel,
  caption,
  optional,
  propsToFeedback,
  button,
  explainerText,
  classNameLabel,
  classNameAdditionalLabel,
  name,
  children,
  explainerLink,
}: GridInputProps) => (
  <>
    <div className={`${gridClassNamePrefix}__label`}>
      <RenderLabel caption={caption} name={name} className={classNameLabel} />
      {optional && caption && <RenderOptionalText />}
    </div>
    <div className={classNames(`${gridClassNamePrefix}__input`, `${gridClassNamePrefix}__wrappedComponent`)}>
      {children}
      {button}
    </div>
    {additionalLabel && (
      <div className={`${gridClassNamePrefix}__additionalLabel`}>
        <RenderLabel caption={additionalLabel} name={name} className={classNameAdditionalLabel} />
      </div>
    )}
    {explainerText && (
      <RenderExplainerText
        explainerText={explainerText}
        explainerLink={explainerLink}
        clsNamePrefix={gridClassNamePrefix}
      />
    )}
    <Feedback className={`${gridClassNamePrefix}__feedback`} {...propsToFeedback} />
  </>
);

// TODO: work on props for this
export class RenderField extends React.Component<IformField> {
  static displayName = 'FieldWrapper';

  static defaultProps: Partial<IformField> = {
    hasError: false,
    optional: false,
    labelInline: false,
    explainerWidth: 50,
  };

  renderExplainerText = (txt?: string) => {
    const { labelInline, explainerWidth, explainerTextUnderInput } = this.props;

    return (
      <RenderExplainerText
        explainerText={txt}
        explainerTextUnderInput={explainerTextUnderInput}
        clsNamePrefix={classNamePrefix}
        labelInline={labelInline}
        explainerWidth={explainerWidth}
      />
    );
  };

  render() {
    const {
      additionalLabel,
      caption,
      className,
      classNameAdditionalLabel,
      errorMessage,
      infoMessage,
      explainerText,
      explainerWidth,
      explainerLink,
      labelInline,
      explainerTextUnderInput,
      optional,
      size,
      meta,
      input,
      wrappedComponent: WrappedComponent,
      block = false,
      grid = false,
      button = <></>,
      hasError: hasErrorProp,
      classNameLabel,
      initValue,
    } = this.props;

    const hasError = hasErrorProp || ((meta.touched || meta.dirty) && meta.invalid);

    const wrapperClass = classNames(
      classNamePrefix,
      grid && gridClassNamePrefix,
      { [`${classNamePrefix}--lbl-inline`]: labelInline },
      { [`${gridClassNamePrefix}--lbl-inline`]: grid && labelInline },
      { [`${classNamePrefix}--optional`]: optional },
      { [`${classNamePrefix}--explainer`]: explainerText },
      { [`${classNamePrefix}--error`]: hasError },
      className
    );

    const formFieldSizeClass = size ? `${classNameFormField}--${size}` : null;
    const propsToComponent = {
      ...input, // from ReduxForm Field component
      ...meta, // from ReduxForm Field component
      ...this.props,
      className: formFieldSizeClass,
      hasError,
      ...(initValue && { value: initValue }),
    };
    // Refactor of fieldWrapped and addition of Feedback component broke this component and error
    // messages from redux-form were ignored
    let errorMessageCombined = [];
    if (meta.error) {
      if (Array.isArray(meta.error)) {
        errorMessageCombined = errorMessageCombined.concat(meta.error);
      } else {
        errorMessageCombined.push(meta.error);
      }
    }
    if (typeof errorMessage !== 'undefined' && errorMessage !== null) {
      if (Array.isArray(errorMessage)) {
        errorMessageCombined = errorMessageCombined.concat(errorMessage);
      } else {
        errorMessageCombined.push(errorMessage);
      }
    }
    const propsToFeedback = {
      messageError: errorMessageCombined,
      messageInfo: infoMessage,
      hasError: propsToComponent.hasError,
    };

    let classNameForFieldWrapper = null;
    if (grid) {
      if (additionalLabel && explainerText) {
        // If the field has both of additional label and explainer text.
        classNameForFieldWrapper = classNames(
          wrapperClass,
          `${gridClassNamePrefix}--has-additional-label-and-explainer`,
          size && `${gridClassNamePrefix}--${size}`
        );
      } else if (!additionalLabel && explainerText) {
        // If the field has only explainer text.
        classNameForFieldWrapper = classNames(
          wrapperClass,
          `${gridClassNamePrefix}--has-explainer`,
          size && `${gridClassNamePrefix}--${size}`
        );
      } else if (additionalLabel && !explainerText) {
        // If the field has only additional label.
        classNameForFieldWrapper = classNames(
          wrapperClass,
          `${gridClassNamePrefix}--has-additional-label`,
          size && `${gridClassNamePrefix}--${size}`
        );
      } else {
        // If the field only has its input.
        classNameForFieldWrapper = classNames(wrapperClass, size && `${gridClassNamePrefix}--${size}`);
      }
    } else {
      classNameForFieldWrapper = classNames(
        wrapperClass,
        explainerText && !explainerTextUnderInput && `${gridClassNamePrefix}--has-explainer`,
        size && `${gridClassNamePrefix}--${size}`
      );
    }

    // TODO: Refactor this. Put labelinline logic into separate components, same for not inline
    return (
      <div className={classNameForFieldWrapper}>
        {grid && (
          <GridInput
            additionalLabel={additionalLabel}
            caption={caption}
            optional={optional}
            explainerText={explainerText}
            explainerLink={explainerLink}
            button={button}
            propsToFeedback={propsToFeedback}
            name={input.name}
            classNameLabel={classNameLabel}
            classNameAdditionalLabel={classNameAdditionalLabel}
          >
            <WrappedComponent {...propsToComponent} />
          </GridInput>
        )}
        {!grid &&
          (labelInline ? (
            <>
              {optional && <RenderOptionalText />}
              <div className={`${classNamePrefix}__label-and-field`}>
                <RenderLabel name={input.name} caption={caption} className={classNameLabel} />
                <div className={`${classNamePrefix}__field-and-feedback`}>
                  <div className={classNames(block && 'display-block', `${classNamePrefix}__wrappedComponent`)}>
                    <WrappedComponent {...propsToComponent} />
                    {button}
                  </div>
                  <Feedback {...propsToFeedback} />
                  {explainerTextUnderInput && this.renderExplainerText(explainerText)}
                </div>
                {!explainerTextUnderInput && this.renderExplainerText(explainerText)}
              </div>
            </>
          ) : (
            <>
              <RenderLabel name={input.name} caption={caption} className={classNameLabel} />
              {optional && <RenderOptionalText />}
              <div className={`${classNamePrefix}__field-and-feedback`}>
                <div className={`${classNamePrefix}__wrappedComponent`}>
                  <div style={{ width: explainerText ? `${100 - explainerWidth}%` : '100%' }}>
                    <WrappedComponent {...propsToComponent} />
                    {button}
                  </div>
                  {this.renderExplainerText(explainerText)}
                </div>
                <Feedback {...propsToFeedback} />
              </div>
            </>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const stencilled =
    typeof ownProps.stencilled !== 'undefined'
      ? ownProps.stencilled
      : formSelectors.getIsFormStencilled(state, ownProps);

  return {
    stencilled,
  };
};

export default withErrorCatcher(connect(mapStateToProps)(withStencils('form-field')(RenderField)));
