import { DEFAULT_LOCALE_ID, DEFAULT_STORE_ID, getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { processRulesDataForSave, transformServerDataIntoFormData } from './helpers';
import { SearchRule } from '../../../Rules/models/searchRule';

interface Conflicts {
  items: SearchRule[];
}

interface TermsConditionsArgs {
  label: string;
  value: string;
  isDisabled: boolean;
  name: string;
}

interface TermsConditionsOperationArgs {
  label: string;
  value: string;
  isDisabled: boolean;
  name: string;
}

export interface FetchRuleConflictsArgs {
  name: string;
  previewStoreId: string;
  termsConditions: TermsConditionsArgs,
  termsCondition_operation: TermsConditionsOperationArgs,
  termsCondition_terms: string[];
}

export const checkRuleConflicts = async (formData: FetchRuleConflictsArgs) => {
  const localeId = DEFAULT_LOCALE_ID; // In future, this will come from somewhere else

  const processedRulesData = processRulesDataForSave(formData);

  const { data } = await fetchData<Conflicts>({
    body: processedRulesData,
    url: getApiUrl('/search/rules/conflicts?localeId={localeId}', { localeId }),
    method: 'POST',
  });

  return data;
};

export const fetchRule = async (ruleId: string): Promise<FetchRuleConflictsArgs> => {
  const localeId = DEFAULT_LOCALE_ID;
  const storeId = DEFAULT_STORE_ID;

  const { data } = await fetchData<{ rule: SearchRule }>({
    url: getApiUrl('/search/locale/{localeId}/store/{storeId}/rules/{ruleId}', { localeId, storeId, ruleId }),
    method: 'GET',
  });

  const ruleData: FetchRuleConflictsArgs = transformServerDataIntoFormData(data?.rule || {});

  return ruleData;
};
