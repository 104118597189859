import * as React from 'react';
import { i18n } from 'i18n';

export interface WithTranslationWrapped {
  /**
   * Translated text
   */
  caption: string;
}

type ComponentWrapped<T> = React.ComponentType<WithTranslationWrapped & T>;

interface WithTranslationProps {
  /**
   * Key to be translated
   */
  caption: string,
  i18nOptions?: {
    [propToReplace: string]: string | number;
  },
}

export function withTranslation<T>(W: ComponentWrapped<T>): React.ComponentType<WithTranslationProps & T> {
  return (props: WithTranslationProps & T) => {
    const { caption, i18nOptions } = props;
    const translation = i18n.t(caption, i18nOptions);

    return <W {...props} caption={translation} />;
  };
}
