import { takeEvery } from 'redux-saga/effects';
import { Store } from 'redux';
import { RSA, ReduxActionType, RSADispatcher } from 'types';

type RunType<T> = (action: RSA<T>) => any;

type CreateSagaType<T> = {
  /**
   * redux action type
   */
  type: ReduxActionType,
  /**
   * saga's "take" function to be used.
   * I.e.: takeLatest, takeLeading, takeEveryWithProgressBar, takeEveryWithConfirmation, etc...
   * Defaults to takeEvery
   */
  takeFn?: Function,
  /**
   * Run this generator function when the action is dispatched
   */
  run: RunType<T>,
};

/**
 * Registers a saga creator function
 * @param store Redux store
 * @param sagaMiddleware Saga middleware
 */
export function registerSagaCreator(store: Store, sagaMiddleware) {
  return function createSaga<T>({
    type,
    takeFn = takeEvery,
    run,
  }: CreateSagaType<T>) {
    const actionDispatcher: RSADispatcher<T> = (payload, error, meta) => store.dispatch<RSA<T>>({
      type,
      payload,
      error,
      meta,
    });

    const saga = function* sagaGenerator() {
      yield takeFn(type, run);
    };

    // register saga in the middleware
    sagaMiddleware.run(saga);

    return actionDispatcher;
  };
}
