/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable default-case */
import {
  take, fork, put, select,
} from 'redux-saga/effects';
import { actions } from 'framework/api/apiClient';
import { formSelectors } from 'framework/selectors';
import { modalActions } from 'framework/actions';
import * as cmsActions from '../actions';
import * as constants from '../contentemanagement.constants';
import { ContentManagementState } from '../contentmanagement.interfaces';

export function* watchContentManagementEvents() {
  while (true) {
    const action = yield take([
      actions.API_CLIENT_UPDATE_STATE,
      constants.ACTION_CMS_SAVE_LAYOUT_EDITOR,
      modalActions.CLOSE_MODAL,
    ]);
    yield fork(handleAction, action);
  }
}

function* handleAction(action: any) {
  switch (action.type) {
    case constants.ACTION_CMS_SAVE_LAYOUT_EDITOR:
      {
        // 1. get form value
        const data = yield select(formSelectors.getFormValues, constants.FORM_LAYOUT_INSPECTOR);

        // 2. update redux from form values
        yield put(
          cmsActions.storeLayoutEditorFormDataAction({
            rowIndex: action.payload.rowIndex,
            columnIndex: action.payload.columnIndex,
            type: action.payload.type,
            data,
          }),
        );
      }
      break;

    case modalActions.CLOSE_MODAL:
      {
        const shouldReopenLayoutEditor: boolean = yield select(
          (state: ContentManagementState) => state.contentmanagement.shouldReopenLayoutEditor,
        );

        if (action.payload === constants.CMS_SELECT_COMPONENTS_MODAL) {
          if (shouldReopenLayoutEditor) {
            yield put(modalActions.createOpenModalAction(constants.CMS_LAYOUT_EDITOR_MODAL));
            yield put(cmsActions.shouldReopenLayoutEditorModalAction({ shouldReopen: false }));
          }
        }
        if (action.payload === constants.CMS_LAYOUT_EDITOR_MODAL) {
          // clear RowEditorData
          if (!shouldReopenLayoutEditor) {
            yield put(cmsActions.clearRowEditorDataAction());
            yield put(
              cmsActions.setActiveColumnIndexAction({
                activeColumnIndex: 0,
              }),
            );
            yield put(
              cmsActions.setCarouselIsEditingAction({
                isEditing: false,
              }),
            );
          }
        }
      }
      break;
  }
}
