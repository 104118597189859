import { StorefrontUiTranslations } from 'framework/modules/siteSettings/translations';
import * as React from 'react';
import { Message } from 'ui';

const ContactSupport = () => (
  <Message
    caption={StorefrontUiTranslations.IntegrationMessage}
    type="notification"
    className="stretchToPanelWidth"
  />
);

export default ContactSupport;
