import React from 'react';
import i18n from 'i18n';
import { Text, Button, Info } from 'ui';
import { clx } from './constants';
import { SummaryItemProps } from './Summary.interfaces';
import { prefixClassName } from '../_conf';

const orderClassName = prefixClassName('order-information');

const ItemValue = (value: any, key?: string | number) => (
  <span key={key} className={`${clx}__item__value`}>
    {value}
  </span>
);

const ItemsList = (values = [], { limit = 5, showMore = false }) => {
  const listSize = values.length;
  const [showAll, setShowAll] = React.useState(false);
  const showToggleButton = showMore && listSize > limit;
  const items = showAll ? values : values.slice(0, limit);

  return (
    <>
      {items.map((val, i) => ItemValue(val, i))}
      {!showToggleButton && listSize > limit && i18n.t('generic.plusXMore', { x: listSize - limit })}
      {showToggleButton && (
        <Button
          link
          className={`${clx}__toogle__items`}
          caption={showAll ? 'generic.showLess' : 'generic.showMore'}
          onClick={() => setShowAll((prev) => !prev)}
        />
      )}
    </>
  );
};

const Item = ({
  label,
  value,
  className,
  i18nOptions,
  tooltipLabel,
  ...listProps
}: SummaryItemProps) => (
  <div className={`${clx}__item ${className}`}>
    {label && <Text caption={label} i18nOptions={i18nOptions} tag="span" className={`${clx}__item__label`} />}
    {tooltipLabel && <Info caption={tooltipLabel} className={`${orderClassName}__tooltip-header__icon`} />}
    {Array.isArray(value) ? ItemsList(value, listProps) : ItemValue(value)}
  </div>
);

export { Item };
