import * as React from 'react';
import classNames from 'classnames';
// eslint-disable-next-line import/no-cycle
import { store } from '@risingstack/react-easy-state';
// eslint-disable-next-line import/no-cycle
import { TABLE_DEFAULT_NAME } from './TableDefaults';
import TableHeader from './TableHeader';
import { tableClassName } from './constants';
// eslint-disable-next-line import/no-cycle
import { SeparateTableInterface } from './SingleTable';
import {HorizontalScrollButtons} from '../HorizontalScrollButtons';

const scrollStore = store<{ scroll: number }>();

const ResponsiveTable = <T extends {}>(props: SeparateTableInterface<T>) => {
  const { noBorder, tableName, autoid, data, headerRenderer, draggableId, onDragEnd,
    emptyText, ifEmpty, cellRenderer, originalWord, replacementWord, metadata, updateRowPosition,
    filteredData, TBody, TableRow, hiddenColumns, className, isEmpty } = props;

  const [showLeftShadow, setShowLeftShadow] = React.useState<boolean>(false);
  const [showRightShadow, setShowRightShadow] = React.useState<boolean>(false);
  const [scrollPos, setScrollPos] = React.useState<number>(scrollStore.scroll);

  // Find the first two locked columns
  const leftLocked: number[] = metadata.reduce<number[]>((full, _current, i) => {
    // Column is left locked if not the first column and locked is true
    if (full.length !== 2 && i !== 0 && _current.locked && _current.leftLockable) {
      return [...full, i];
    }

    return full;
  }, []);

  // Find the last two locked columns
  const rightLocked: number[] = metadata.reduce<number[]>((full, _current, i) => {
    // Column is right locked if not the last column and locked is true and it's not left locked
    if (full.length !== 2 && i !== metadata.length - 1 && _current.locked
        && _current.rightLockable && !leftLocked.includes(i)) {
      return [...full, i];
    }

    return full;
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    setScrollPos(e.currentTarget?.scrollLeft);
    scrollStore.scroll = e.currentTarget?.scrollLeft;
  };

  let middleContainer = document.getElementById('scrolling-container');
  const table = document.getElementById(`${tableName}-middle`);
  const tableScrollRef = React.useRef(null);

  const leftTableWidth: number = document.getElementById('left-table')?.clientWidth;
  const rightTableWidth: number = document.getElementById('right-table')?.clientWidth;

  React.useEffect(() => {
    if (scrollStore.scroll) middleContainer?.scrollTo({ left: scrollStore.scroll });
  }, [middleContainer?.clientWidth]);

  React.useEffect(() => {
    if (!middleContainer) {
      middleContainer = document.getElementById('scrolling-container');
    }

    setShowLeftShadow(scrollPos && scrollPos !== 0 && scrollPos > 1);
    setShowRightShadow(
      Math.round(scrollPos + middleContainer?.clientWidth) !== middleContainer?.scrollWidth
      && Math.round(scrollPos + middleContainer?.clientWidth) + 1 !== middleContainer?.scrollWidth
      && Math.round(scrollPos + middleContainer?.clientWidth) + -1 !== middleContainer?.scrollWidth,
    );
  }, [middleContainer?.scrollLeft, table?.getBoundingClientRect()?.width,
    middleContainer?.offsetWidth, middleContainer?.clientWidth, scrollPos]);

  return (
    <div className={`${tableClassName}--table-wrapper`}>
      <div style={{width: 'fit-content', zIndex: 1}} id="left-table">
        <table
          className={
            classNames(
              tableClassName,
              className,
              noBorder && `${tableClassName}--no-border`,
              `${tableClassName}--left-column`,
              showLeftShadow && `${tableClassName}--shadow-right`,
            )
          }
          id={`${tableName}-first`}
          data-autoid={autoid}
        >
          <TableHeader
            data={data}
            tableName={tableName ? `${tableName}-first` : TABLE_DEFAULT_NAME}
            metadata={metadata}
            hiddenColumns={metadata.reduce<any>((full, _current, i) => {
              if (i !== 0 && !leftLocked.includes(i)) {
                return [...full, i];
              }

              return full;
            }, [])}
            cellRenderer={headerRenderer}
          />
          <TBody onDragEnd={draggableId ? updateRowPosition : onDragEnd}>
            {isEmpty && ifEmpty(metadata, emptyText, leftLocked.length === 2)}
            {!isEmpty &&
              [...(filteredData || data)].map((singleRow: any, i: number) => (
                <TableRow key={i}>
                  {cellRenderer(tableName ? `${tableName}-first` : TABLE_DEFAULT_NAME, singleRow, metadata, i,
                    metadata.reduce<any>((full, _current, n) => {
                      if (n !== 0 && !leftLocked.includes(n)) {
                        return [...full, n];
                      }

                      return full;
                    }, []), originalWord, replacementWord)}
                </TableRow>
              ))}
          </TBody>
        </table>
      </div>
      <HorizontalScrollButtons
        scrollContainerRef={tableScrollRef}
        leftTableWidth={leftTableWidth}
        rightTableWidth={rightTableWidth}
        emptyTable={data.length === 0}
      />
      <div
        onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {handleScroll(e);}}
        className={`${tableClassName}--scrolling-container`}
        id="scrolling-container"
        ref={tableScrollRef}
      >
        <table
          className={
            classNames(
              tableClassName,
              className,
              noBorder && `${tableClassName}--no-border`,
              `${tableClassName}--middle-columns`,
            )}
          id={`${tableName}-middle`}
          data-autoid={autoid}
        >
          <TableHeader
            data={data}
            tableName={tableName ? `${tableName}-middle` : TABLE_DEFAULT_NAME}
            metadata={metadata}
            hiddenColumns={[...hiddenColumns, ...metadata.reduce<any>((full, _current, i) => {
              // Remove locked columns from center table
              if (i === 0 || i === metadata.length - 1 || _current.locked) {
                return [...full, i];
              }

              return full;
            }, [])]}
            cellRenderer={headerRenderer}
          />
          <TBody onDragEnd={draggableId ? updateRowPosition : onDragEnd}>
            {isEmpty && ifEmpty(metadata, emptyText, leftLocked.length !== 2)}
            {!isEmpty &&
              [...(filteredData || data)].map((singleRow: any, i: number) => (
                <TableRow key={i}>
                  {cellRenderer(tableName ? `${tableName}-middle` : TABLE_DEFAULT_NAME, singleRow, metadata, i,
                    [...hiddenColumns, ...metadata.reduce<any>((full, _current, n) => {
                      if (n === 0 || n === metadata.length - 1 || _current.locked) {
                        return [...full, n];
                      }

                      return full;
                    }, [])], originalWord, replacementWord)}
                </TableRow>
              ))}
          </TBody>
        </table>
      </div>

      <div style={{width: 'fit-content'}}  id="right-table">
        <table
          className={classNames(
            tableClassName,
            className,
            noBorder && `${tableClassName}--no-border`,
            `${tableClassName}--right-column`,
            showRightShadow && `${tableClassName}--shadow-left`,
          )}
          id={`${tableName}-last`}
          data-autoid={autoid}
        >
          <TableHeader
            data={data}
            tableName={tableName ? `${tableName}-last` : TABLE_DEFAULT_NAME}
            metadata={metadata}
            hiddenColumns={metadata.reduce<any>((full, _current, i) => {
              if (i !== metadata.length - 1 && !rightLocked.includes(i)) {
                return [...full, i];
              }

              return full;
            }, [])}
            cellRenderer={headerRenderer}
          />
          <TBody onDragEnd={draggableId ? updateRowPosition : onDragEnd}>
            {isEmpty && ifEmpty(metadata, emptyText, false)}
            {!isEmpty &&
              [...(filteredData || data)].map((singleRow: any, i: number) => (
                <TableRow key={i}>
                  {cellRenderer(tableName ? `${tableName}-last` : TABLE_DEFAULT_NAME, singleRow, metadata, i,
                    metadata.reduce<any>((full, _current, n) => {
                      if (n !== metadata.length - 1 && !rightLocked.includes(n)) {
                        return [...full, n];
                      }

                      return full;
                    }, []), originalWord, replacementWord)}
                </TableRow>
              ))}
          </TBody>
        </table>
      </div>
    </div>
  );
};

export default ResponsiveTable;
export { ResponsiveTable };
