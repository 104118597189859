/* eslint-disable import/no-cycle */
import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { ITabStepsTab } from 'framework/components/ui/TabSteps/TabSteps.interfaces';
import { LayoutFlexBoxItem, TabSteps } from 'ui';
import { usePageContext } from 'framework/components/ui/Page/PageContext';
import { BaseModalPreventNavigation } from 'framework/components/ui/ModalPreventNavigation/BaseModalPreventNavigation';
import { defaultModalName } from 'framework/components/ui/ModalPreventNavigation/ModalPreventNavigation';
import { modalActions } from 'framework/actions';
import { useTabs } from 'framework/components/ui/Tabs/useTabs';
import { UserPageFlow, CommonPageProps } from './CommonPage.Interfaces';
import { CommonPageDetails } from '../CommonPageDetails';
import { CommonPageStores } from '../CommonPageStores';
import { CommonPageRoles } from '../CommonPageRoles';
import {
  SITEADMIN_USER_TABS_DETAILS,
  SITEADMIN_USER_TABS_ROLES,
  SITEADMIN_USER_TABS_STORES,
  SITEADMIN_USER_TABS,
} from './CommonPage.Constants';
import { getUser } from './CommonPage.Selectors';

const translation = 'modules.siteadmin';

type CommonPageTabsProps = {
  user?: { firstName: string; familyName: string, id :number };
  flow?: UserPageFlow;
};

const CommonPageTabsComponent = (props: CommonPageTabsProps) => {
  const { flow, user } = props;
  const isEditFlow = flow === 'edit';
  const { updatePageTitle } = usePageContext();

  const dispatch = useDispatch();
  const openModal = () => dispatch(modalActions.createOpenModalAction(defaultModalName));
  const closeModal = () => dispatch(modalActions.createCloseModalAction(defaultModalName));

  const [canChangeTab, setCanChangeTab] = React.useState<boolean>(true);
  const [targetTab, setTargetTab] = React.useState<string>(undefined);
  const { openTab } = useTabs('SITEADMIN_USER_TABS');

  React.useEffect(() => {
    let title = 'modules.siteadmin.userCreatePageTitle';
    if (isEditFlow && !!user) {
      title = `${user.firstName} ${user.familyName}`;
    }
    updatePageTitle({ pageTitle: title });
  }, [user]);

  const onTabDataChange = (dirty:boolean) => {
    setCanChangeTab(!dirty);
  };

  const tabData: ITabStepsTab[] = [
    {
      id: SITEADMIN_USER_TABS_DETAILS,
      label: isEditFlow ? `${translation}.createUserPage.editUserDetailsTab` : `${translation}.createUserPage.tab1`,
      content: <CommonPageDetails editFlow={isEditFlow} />,
    },
    {
      id: SITEADMIN_USER_TABS_STORES,
      label: isEditFlow ? `${translation}.assignStores` : `${translation}.assignStoresStep`,
      content: <CommonPageStores editFlow={isEditFlow} onStoreChange={onTabDataChange} />,
    },
    {
      id: SITEADMIN_USER_TABS_ROLES,
      label: isEditFlow ? `${translation}.createUserPage.editUserRolesTab` : `${translation}.createUserPage.tab3`,
      content: <CommonPageRoles editFlow={isEditFlow} onRolesChange={onTabDataChange}/>,
    },
  ];

  const onBeforeChange = (tabId: string) => {
    setTargetTab(tabId);
    if (!canChangeTab) {
      openModal();
      return false;
    }
    return true;
  };

  const modalOnAcceptNavigation = () => {
    setCanChangeTab(false);
    closeModal();
  };
  const modalOnCancelNavigation = () => {
    setCanChangeTab(true);
    closeModal();
    openTab(targetTab);
  };
  const modalOnDismiss = () => {
    setCanChangeTab(false);
    closeModal();
  };

  return (
    <LayoutFlexBoxItem>
      <TabSteps
        id={SITEADMIN_USER_TABS}
        data={tabData}
        isEditMode={isEditFlow}
        onBeforeChange={onBeforeChange}
      />
      <BaseModalPreventNavigation
        modalName={defaultModalName}
        onAcceptNavigation={modalOnAcceptNavigation}
        onCancelNavigation={modalOnCancelNavigation}
        onDismiss={modalOnDismiss}
        title="components.modalPreventNavigation.title"
      />
    </LayoutFlexBoxItem>
  );
};

const mapStateToProps = (state): CommonPageProps => ({
  user: getUser(state),
  flow: state.siteadmin.pageFlow,
});

export const CommonPageTabs: React.FC<CommonPageTabsProps> = connect(mapStateToProps)(CommonPageTabsComponent);
