import { getGrowthBookOverrideFlags } from './getGrowthBookOverrideFlags';

export const handleGrowthBookOverrides = (gbInstance) => {
  const originalPayload = gbInstance.getPayload();
  try {
    /**
     * Example User-Agent with feature flags:
     * Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36 X-AQA-Feature-Flags-Override=feature-flag-1:true;feature-flag-2:true
     */
    const newOverrides = getGrowthBookOverrideFlags(window?.navigator?.userAgent)
      ?.split(';')
      ?.reduce((overrideFlags, currentFlag) => {
        const [key, value] = currentFlag.split(':');
        overrideFlags[key] = { defaultValue: value === 'true' };
        return overrideFlags;
      }, {});
    if (!newOverrides || !Object.values(newOverrides)?.length) return;
    gbInstance.setPayload({
      ...originalPayload,
      features: {
        ...originalPayload.features,
        ...newOverrides,
      },
    });
  } catch {
    gbInstance.setPayload(originalPayload);
  }
};
