import * as React from 'react';
import classnames from 'classnames';
import { isDate, isString } from 'framework/utils/helpers';
import { prefixClassName } from 'framework/components/ui/_conf';
import { i18n } from 'i18n';
import { ReadOnlyField } from '../ReadOnlyField';
import { ITimePickerPrimitive } from './TimePickerPrimitive.interfaces';
import './_style.scss';

const clx = prefixClassName('timepicker');

const TimePickerPrimitive = (props: ITimePickerPrimitive) => {
  const {
    className,
    caption,
    errorclassName,
    disabled = false,
    value,
    readOnly = false,
    placeholder,
    onChange,
    defaultValue = '00',
  } = props;

  const handleChange = (time) => {
    const t = time.target.value;
    const [h, m] = t.split(':');

    onChange({ hours: h, minutes: m });
  };

  const formatNum = (num: number = 0) => {
    const s = `00${num}`;
    return s.substr(s.length - 2);
  };

  const timeValue = (val: any = {}) => {
    let h;
    let m;

    if (isDate(val)) {
      const date = new Date(val);
      h = date.getUTCHours();
      m = date.getUTCMinutes();
    } else if (isString(val)) {
      // '12:34' format
      [h, m] = val.split(':');
    } else {
      h = val.hours || defaultValue;
      m = val.minutes || defaultValue;
    }

    return `${formatNum(h)}:${formatNum(m)}`;
  };

  const formatedValue = timeValue(value);

  return readOnly ? (
    <ReadOnlyField value={formatedValue} placeholder={placeholder} />
  ) : (
    <input
      type="time"
      required
      aria-label={i18n.t(caption)}
      disabled={disabled}
      onChange={handleChange}
      value={formatedValue}
      className={classnames(clx, className, errorclassName)}
    />
  );
};

export default TimePickerPrimitive;
