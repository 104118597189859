import { call, select, put } from 'redux-saga/effects';
import { config } from 'config';
import { toastListActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest } from 'framework/api/make-request';
import { RecommendationDetails } from 'framework/modules/merchandising/models';
import {
  ACTION_RECOMMENDATION_SAVE_RULES,
  PATH_RECOMMENDATIONS_EDIT_FULLPATH,
} from 'framework/modules/merchandising/constants';
import { history } from 'framework/store';
import { Recommendation as translation } from 'framework/modules/merchandising/translations';
import { getFormValuesRules, getRecommendationStoresId } from '../selectors';
import { FIELD_ACTIONS, FIELD_CONDITIONS } from '../../constants';
import { recommendationDefault, formatRuleActions, formatRuleConditions } from './helpers';

function* process({ payload: { id } }) {
  const formValues = yield select(getFormValuesRules);
  const stores = yield select(getRecommendationStoresId);

  // 1. Make Save Request
  const recommendation: RecommendationDetails = {
    ...recommendationDefault,
    ...formValues,
    stores,
    actions: formatRuleActions(formValues[FIELD_ACTIONS]),
    conditions: formatRuleConditions(formValues[FIELD_CONDITIONS]),
    id,
  };

  const { success } = yield call(makeRequest, {
    name: 'saveRecommendationDetails',
    method: 'PUT',
    url: `${config.apiEndpoints.recommendations}/${recommendation.id}`,
    body: { ...recommendation },
  });

  // 2. Navigate to Rules List Page
  if (success) {
    // 2.1 stay on recommendation page??
    history.push(`${PATH_RECOMMENDATIONS_EDIT_FULLPATH}/${recommendation.id}?tab=rule`);
    yield put(toastListActions.addMessage(translation.SaveSuccess));
  } else {
    yield put(toastListActions.addMessageError(translation.SaveError));
  }
}

export function watchSaveRules() {
  return takeEveryWithProgressBar(ACTION_RECOMMENDATION_SAVE_RULES, process);
}
