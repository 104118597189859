import { FieldArrayFieldsProps } from 'redux-form';
import { toastError } from '../../../../../../../../../stores/toast';
import { ProductPosition, ProductScore } from '../../../../../../models/product';
import { positionsStore } from '../ActionsPosition';

export const addDynamicFieldPosition = (
  productsList: ProductScore[],
  fields: FieldArrayFieldsProps<ProductPosition>,
  fetchCurrentData: () => void,
  overridePosition?: number
) => {
  const allFields = fields?.getAll() || [];
  const max = allFields.reduce((val, current) => (val > current.position ? val : current.position), allFields.length);

  // Keep track of changed positions, avoids duplicates
  positionsStore.newPositions = Array.from(
    new Set([...(positionsStore.newPositions ?? []), ...productsList.map((v) => v.productId)])
  );

  const isValidOverridePosition = typeof overridePosition === 'number' && overridePosition >= 0;

  if (
    isValidOverridePosition &&
    allFields?.length !== 0 &&
    allFields?.some((v) => v.position === overridePosition + 1)
  ) {
    // If the override position is already used, then do not allow the change
    toastError('modules.merchandising.rules.createAndEdit.duplicateProductErrorMessage');

    return;
  }

  const existingProductPosition: number = allFields.findIndex((v) => v.product.value === productsList[0].sku);
  if (isValidOverridePosition && existingProductPosition !== -1) {
    // If overridePosition is defined and there is an existingProductPosition, that means that product was
    // drag and dropped and the field needs to be updated
    const movedProduct: ProductScore = productsList[0];
    // Add field with new position
    fields.push({
      product: { value: movedProduct.productId, label: `${movedProduct.name} - ${movedProduct.sku}` },
      position: overridePosition + 1,
    });

    // Remove previous field since it is no longer accurate
    fields.remove(existingProductPosition);

    fetchCurrentData();

    return;
  }

  productsList
    .filter((p) => !allFields.some((f) => f.product?.value === p.productId))
    .forEach((p, index) => {
      fields.push({
        product: { value: p.productId, label: `${p.brand} ${p.name} - ${p.sku}` },
        // Use the overridePosition if defined for drag and drop
        position: isValidOverridePosition ? overridePosition + 1 : max + index + 1,
      });
    });

  fetchCurrentData();
};
