import * as React from 'react';
import * as classnames from 'classnames';
import { Kebab } from 'framework/components/ui';
// eslint-disable-next-line import/no-cycle
import * as Templates from './templates';
import { IEventComponent } from './Event.interfaces';
import './_style.scss';

export const clx = 'cal_event';

class Event extends React.Component<IEventComponent> {
  static defaultProps: Partial<IEventComponent> = {
    event: {},
    disabled: false,
  };

  render() {
    const { className, disabled, title, event } = this.props;
    const { template, options = [] } = event;
    let content;

    switch (template) {
      case 'slotTemplate':
        content = <Templates.SlotTemplate {...event} />;
        break;
      case 'liveCalendar':
      case 'liveCalendarOrphan':
        content = <Templates.LiveCalendar {...event} />;
        break;
      case 'eventBlocker':
        content = <Templates.Blocker {...event} />;
        break;
      case 'default':
      default:
        content = <Templates.SlotTemplate title={title} />;
    }

    return (
      <span className={classnames(clx, template && `${clx}--template`, disabled && `${clx}--disabled`, className)}>
        <div className={`${clx}__content`}>{content}</div>
        {!!options.length && <Kebab className="event__kebabMenu" menuDirection="upLeft" options={options} />}
      </span>
    );
  }
}

export default Event;
