import * as classNames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Props } from './LayoutFlexBox.interfaces';
import './_styles.scss';

const clx = prefixClassName('flexbox-container');

export default class LayoutFlexBox extends React.Component<Props> {
  getFlexContainerProperties = () => {
    const {
      alignContent,
      alignItems,
      flexDirection,
      flexWrap,
      justifyContent,
      flexItemsWidth
    } = this.props;

    return {
      alignContent: alignContent && this.getFlexContainerAlignContent(alignContent),
      alignItems: alignItems && this.getFlexContainerAlignItems(alignItems),
      flexDirection: flexDirection && this.getFlexContainerDirection(flexDirection),
      flexWrap: flexWrap && this.getFlexContainerWrap(flexWrap),
      justifyContent: justifyContent && this.getFlexContainerJustifyContent(justifyContent),
      width: flexItemsWidth
    } as any;
  };

  getFlexContainerDirection = (direction: string = 'row') => {
    const directionOpt = {
      column: 'column',
      'column-reverse': 'column-reverse',
      row: 'row',
      'row-reverse': 'row-reverse'
    };
    return directionOpt[direction];
  };

  getFlexContainerWrap = (wrap: string = 'nowrap') => {
    const wrapOpt = {
      nowrap: 'nowrap',
      wrap: 'wrap',
      'wrap-reverse': 'wrap-reverse'
    };
    return wrapOpt[wrap];
  };

  getFlexContainerJustifyContent = (justifyContent: string = 'flex-start') => {
    const justifyContentOpt = {
      center: 'center',
      'flex-end': 'flex-end',
      'flex-start': 'flex-start',
      'space-around': 'space-around',
      'space-between': 'space-between'
    };
    return justifyContentOpt[justifyContent];
  };

  getFlexContainerAlignItems = (alignItems: string = 'stretch') => {
    const alignItemsOpt = {
      baseline: 'baseline',
      center: 'center',
      'flex-end': 'flex-end',
      'flex-start': 'flex-start',
      stretch: 'stretch'
    };
    return alignItemsOpt[alignItems];
  };

  getFlexContainerAlignContent = (alignContent: string = 'stretch') => {
    const alignContentOpt = {
      center: 'center',
      'flex-end': 'flex-end',
      'flex-start': 'flex-start',
      'space-around': 'space-around',
      'space-between': 'space-between',
      stretch: 'stretch'
    };

    return alignContentOpt[alignContent];
  };

  render() {
    const { className } = this.props;
    const styles = this.getFlexContainerProperties();

    return (
      <div className={classNames(clx, className)} style={styles}>
        {this.props.children}
      </div>
    );
  }
}
