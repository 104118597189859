import * as CONST from 'framework/modules/searchadmin/constants';

export function saveRuleAction(ruleId?) {
  return {
    type: CONST.ACTION_RULES_SAVE_RULE,
    payload: { ruleId },
  };
}

/**
 * Fetches both Current and Preview products for the new specified rules
 * @param data new rules
 */
export function fetchRuleProducts(data?: any) {
  return {
    type: CONST.ACTION_RULES_PREVIEW_FETCH_CURRENT_DATA,
    payload: { data },
  };
}

export function fetchCurrentScoreExplanation(productId) {
  return {
    type: CONST.ACTION_RULES_CURRENT_SCORE_EXPLANATION,
    payload: { productId },
  };
}

export function fetchPreviewScoreExplanation(productId) {
  return {
    type: CONST.ACTION_RULES_PREVIEW_SCORE_EXPLANATION,
    payload: { productId },
  };
}

export const clearPageData = () => ({
  type: CONST.ACTION_RULES_CLEAR_PAGE_DATA,
});
