import { select, put, call } from 'redux-saga/effects';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest } from 'framework/api/make-request';
import { config } from '../../../../config';
import * as constants from '../contentemanagement.constants';
import { Uuid } from 'framework/utils/generateId';
import { selectors as formSelectors } from 'framework/components/ui/FormComponents/Form';
import * as cmsActions from '../actions';
import { ContentManagementState } from '../contentmanagement.interfaces';
import { actions as toastListActions } from 'framework/components/ui/ToastList';
import { history } from 'framework/store';
import { CmsPageContent } from '../CmsComponents.interfaces';
import { processFormErrors } from './_SagaUtils';
import * as get from 'lodash.get';
import { fixPreviewUrl } from '../utils/utils.fixPreviewUrl';
import { formActions } from 'framework/actions';

export default function* sagaCmsCreatePage() {
  yield takeEveryWithProgressBar(constants.ACTION_CMS_CREATE_PAGE_DOCUMENT, process);
}

function* process() {
  // retrieve selected folder hierarchy
  const folderid = yield select(
    (state: ContentManagementState) => state.contentmanagement.folderId
  );

  if (!folderid) {
    yield put(
      toastListActions.addMessageError(
        'modules.contentmanagement.tab_editdetails.edit_details_form.select_location',
        5000
      )
    );
  } else {
    // post and create document
    let response: any = yield postDocument();

    if (!response.error) {
      const { documentid } = response;

      // add document to folders
      if (!response.error) {
        response = yield addDocumentToFolders({ documentid: response.documentid, folderid });
      }

      // reaload document to get defaultVariantId
      if (!response.error) {
        response = yield call(makeRequest, {
          name: constants.ACTION_CMS_LOAD_DOCUMENT,
          url: `${config.apiEndpoints.cms}/documents/${documentid}`
        });

        const { defaultVariantId } = response.data;

        yield put(
          cmsActions.storeCmsPageInfo({
            pageId: documentid,
            variantId: defaultVariantId,
            pageCreated: true
          })
        );
        yield put(formActions.reset(constants.CMSPAGE_TAB_EDITDETAIL_FORM));
        history.push(`/contentmanagement/page/${documentid}/variants/${defaultVariantId}`);
      }
    }

    if (response.error) {
      yield processFormErrors(response.error, constants.CMSPAGE_TAB_EDITDETAIL_FORM, [
        'displayName',
        'referenceName',
        'url'
      ]);
    }
  }
}

function* postDocument() {
  const values = yield select(formSelectors.getFormValues, constants.CMSPAGE_TAB_EDITDETAIL_FORM);

  // fix page url
  const url: string = values.url ? fixPreviewUrl(values.url) : values.url;

  // collect document data
  const document = {
    ...values,
    url,
    cmsContent: {
      type: 'Page',
      rows: []
    } as CmsPageContent
  };

  const documentid = Uuid();

  // post a document
  const response = yield call(makeRequest, {
    body: { id: documentid, ...document },
    method: 'POST',
    url: config.apiEndpoints.cmsDocuments,
    name: constants.ACTION_CMS_CREATE_PAGE_DOCUMENT
  });

  return {
    ...response,
    documentid
  };
}

function* addDocumentToFolders({ documentid, folderid }) {
  let response = yield call(makeRequest, {
    body: {
      documentId: documentid
    },
    method: 'PUT',
    url: `${config.apiEndpoints.cms}/folders/pages/folder/${folderid}/document`,
    name: constants.ACTION_CMS_ADD_DOCUMENT_TO_FOLDER
  });
  if (!response.error) {
    // refresh page hierarchy
    response = yield call(makeRequest, {
      url: `${config.apiEndpoints.cms}/folders/pages`,
      name: constants.ACTION_CMS_UPDATE_PAGE_HIERARCHY
    });

    // refresh redux pages hierarchy
    yield put(
      cmsActions.storePagesHierarchy({
        cmsPagesHierarchy: get(response, 'data.children', [])
      })
    );
  }
  return response;
}
