/* eslint-disable  */
import { useSelector } from 'react-redux';
import { ModuleConfig, GetRouteGroupFunc, RouteGroup } from 'framework/components/navigation/Navigation.interfaces';
import {
  selectProfile,
  selectIsLoadingUser, selectAccessToken, ensureClaims
} from 'framework/authorization';
import appModules from 'framework/modules';
import { TOKEN_NOT_FOUND } from 'framework/authorization/Authorization.Selectors';
import { useState, useEffect } from 'react';
import { AppFeatures } from 'stores/features/types';

// resolve menus page-groups, including dynamic groups with async call
export const resolvePageGroups = async (module: ModuleConfig): Promise<ModuleConfig> => {
  const fetchable = module.pageGroups && typeof module.pageGroups === 'function';

  const getRouteGroup = fetchable ? module
    .pageGroups as GetRouteGroupFunc : async () => Promise
    .resolve(module.pageGroups as RouteGroup[] ?? []);

  const pageGroups = await getRouteGroup();

  return {
    ...module,
    pageGroups,
  };
};

export const useNavigation = (featuresState: AppFeatures) => {
  const [modules, setModules] = useState<ModuleConfig[]>([]);

  const profile: any = useSelector(selectProfile);
  const isLoadingUser = useSelector(selectIsLoadingUser);
  const access_token = useSelector(selectAccessToken);

  const filterAuthorizedModules = (module: ModuleConfig) => (module.pageGroups as RouteGroup[] ?? [])
    .some((gr) => gr.routes.some((r) => !r.authClaim || ensureClaims(r.authClaim)));

  const getAuthorizedModules = async () => {
    const hasFullAccess = profile.fullaccess || profile.poweruser;
    const allAppModules: ModuleConfig[] = await appModules();

    if (hasFullAccess) {
      return allAppModules;
    }
    return (await Promise.all(allAppModules.map(resolvePageGroups)))
      .filter(filterAuthorizedModules);
  };

  useEffect(() => {
    const canFetch = !!access_token && access_token !== TOKEN_NOT_FOUND && !isLoadingUser;
    if (canFetch) {
      getAuthorizedModules().then((res) => setModules(res));
    }
  }, [isLoadingUser, access_token, featuresState?.isLoading]);
  return {
    authorisedModules: modules,
  };
};
