import * as React from 'react';
import { IFetchDataRequest } from 'framework/api/fetch-data';
import { ConnectedTable, RenderRow } from '../ConnectedTable/ConnectedTable';
import { ColumnMetadata } from '../Table/Table.interfaces';
import { onDataFetchedType } from '../ConnectedTable/useApiTableData';
import { usePageContext } from '../Page/PageContext';

export interface FilterProps<F> {
  onFilter: (filters: F) => void;
  selected: F;
  /**
   * use this property to get filter options received from the API
   */
  fetchedData?: any;
  className?: string;
}

export interface ConnectedListPageProps<F> {
  Filter?: React.ComponentType<FilterProps<F>>;
  Gap?: React.ComponentType<any>;
  metadata: ColumnMetadata;
  renderRow?: RenderRow;
  getRequestDetails: (filters: F) => IFetchDataRequest;
  onDataFetched?: onDataFetchedType;
}

/**
 * This component is meant to standarize the way filters and pagination work accross the application
 * and reduce the boilerplate the developer has to write all the time a new page is created.
 * The filters and pagination are persisted when the user navigates within the same page group.
 * I.e.: user wants to view/edit one table item and than comes back to the listing page
 */
const ConnectedListPage = <F extends {}>({
  Filter,
  Gap,
  metadata,
  getRequestDetails,
  onDataFetched,
  renderRow,
}: ConnectedListPageProps<F>) => {
  const { usePageState } = usePageContext();
  // initializes the page perstitent state
  const { pageState, saveState } = usePageState({
    internalFilters: undefined as F,
    pagination: undefined,
  });

  // initializes the component local state
  const [filters, setFilters] = React.useState(pageState.internalFilters as F);
  // fetched data
  const [fetchedData, setFetchedData] = React.useState(undefined);

  const willFilter = (newFilters: F) => {
    // updates page persistent state
    saveState((prev) => ({ ...prev, internalFilters: newFilters }));
    // update component state
    setFilters(newFilters);
  };

  const pageChanged = (pagination) => saveState((prev) => ({ ...prev, pagination }));

  const internalOnDataFetched = async (data, skipTake) => {
    const processedData = onDataFetched ? await onDataFetched(data, skipTake) : data;
    setFetchedData(processedData);
    return processedData;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestDetails = React.useMemo(() => getRequestDetails(filters), [filters]);

  return (
    <>
      {Filter && <Filter onFilter={willFilter} selected={filters} fetchedData={fetchedData} />}
      {Gap && <Gap />}
      <ConnectedTable
        columnMetadata={metadata}
        requestDetails={requestDetails}
        renderRow={renderRow}
        pagination={pageState.pagination}
        onPageChanged={pageChanged}
        onDataFetched={internalOnDataFetched}
      />
    </>
  );
};

export { ConnectedListPage };
