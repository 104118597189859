import * as React from 'react';
import withTooltip from 'framework/components/hoc/withTooltip';
import { Icon } from 'framework/components/ui';

const InfoIcon = () => (
  <Icon
    name="info"
    size="sm"
    title="components.info.title"
  />
);

export default withTooltip(InfoIcon);
