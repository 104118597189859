import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { AppRouteBase, AppRouteClaims } from 'framework/components/navigation/Navigation.interfaces';
import { goToRoute } from 'framework/components/navigation/service';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { Features } from 'stores/features';
import { OrderTranslations } from './translations';

// Paths
export const PATH_MAIN = '/orderManagement';
export const PATH_ORDERS = '/orders';
export const PATH_ORDERS_EDIT = 'edit'; // path needs /:orderId
export const LIST_URL_FULL = `${PATH_MAIN}${PATH_ORDERS}`;
export const PATH_ORDERS_EDIT_FULLPATH = `${PATH_MAIN}${PATH_ORDERS}/${PATH_ORDERS_EDIT}`; // path needs /:orderID

// DataGrids
export const DATA_GRID_ORDER_ITEMS_NOTES = 'DataGridOrderItemsNotes';
export const DATA_GRID_ORDER_ITEMS_MAIN_BODY = 'DataGridOrderItemsMainBody';

// Forms
export const FORM_ORDER_DETAILS_ORDER_ITEMS = 'FormOrderDetailsOrderItems';

export const TERMS_REASON_CODES = 'terms_reason-codes';

const ordersClaims: AppRouteClaims = {
  [AuthorizationClaims.ORDERMANAGEMENT_ORDERS]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
  [AuthorizationClaims.PAYMENTS_PAYMENT]: UserAccessRights.Read,
  [AuthorizationClaims.ORDERMANAGEMENT_DELIVERYINTEGRATION]: UserAccessRights.Read,
};

export const ordersListRoute: AppRouteBase = {
  path: '/orderManagement/orders',
  pageTitle: OrderTranslations.Orders,
  menuTitle: OrderTranslations.AllOrders,
  authClaim: ordersClaims,
  extendedClaims: [
    {
      label: OrderTranslations.ManageOrders,
      claims: ordersClaims,
    },
    {
      label: OrderTranslations.EnterPOSTotalAmountManually,
      claims: {
        [AuthorizationClaims.ORDERMANAGEMENT_POSTOTAL]: UserAccessRights.ReadWrite,
      },
    },
    {
      label: OrderTranslations.UpdateChargeAmount,
      claims: {
        [AuthorizationClaims.ORDERMANAGEMENT_UPDATE_CHARGE_AMOUNT]: UserAccessRights.ReadWrite,
      },
    },
    {
      label: OrderTranslations.RefundPayments,
      claims: {
        [AuthorizationClaims.PAYMENTS_PAYMENT]: UserAccessRights.ReadWrite,
        [AuthorizationClaims.CONTENTMANAGEMENT_TERMS]: UserAccessRights.Read,
      },
    },
    {
      label: OrderTranslations.CustomerComments,
      claims: {
        [AuthorizationClaims.CUSTOMER_COMMENTS]: UserAccessRights.Read,
      },
    },
    {
      label: OrderTranslations.CustomerViewSubscriptions,
      claims: {
        [AuthorizationClaims.CUSTOMER_VIEW_SUBSCRIPTIONS_CLAIM]: UserAccessRights.Read,
      },
    },
  ],
};

export const goToOrdersListRoute = () => goToRoute(ordersListRoute);

export const orderDetailsRoute: AppRouteBase = {
  pageTitle: OrderTranslations.OrderDetails,
  path: '/orderManagement/orders/edit/:orderId',
  authClaim: ordersClaims,
  parent: ordersListRoute,
};

export const goToOrdersDetailsRoute = (orderId: string) => goToRoute(orderDetailsRoute, { orderId });

export const failedSubmissionsClaims = {
  ...ordersClaims,
  [AuthorizationClaims.ORDERMANAGEMENT_FAILEDSUBMISSIONS]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.ORDERMANAGEMENT_DELIVERYINTEGRATION]: UserAccessRights.ReadWrite,
};

export const deliverySolutionsFailedSubmissionsRoute: AppRouteBase = {
  path: '/orderManagement/failedSubmissions/deliverySolutions',
  pageTitle: OrderTranslations.DeliverySolutions,
  authClaim: failedSubmissionsClaims,
  requiredFeatures: [{ feature: Features.DeliverySolutions }],
};

export const zetesFailedSubmissionsRoute: AppRouteBase = {
  path: '/orderManagement/failedSubmissions/zetes',
  pageTitle: OrderTranslations.Zetes,
  authClaim: failedSubmissionsClaims,
  requiredFeatures: [{ feature: Features.ZetesIntegration }],
};

export const deliverySolutionsFailedSubmissionsDetailsRoute: AppRouteBase = {
  pageTitle: OrderTranslations.OrderDetails,
  path: '/orderManagement/failedSubmissions/deliverySolutions/edit/:orderId',
  authClaim: failedSubmissionsClaims,
  parent: deliverySolutionsFailedSubmissionsRoute,
  requiredFeatures: [{ feature: Features.DeliverySolutions }],
};

export const fulfillmentFailedSubmissionsRoute: AppRouteBase = {
  path: '/orderManagement/failedSubmissions/fulfillment',
  pageTitle: OrderTranslations.Fulfillment,
  authClaim: failedSubmissionsClaims,
};

export const fulfillmentFailedSubmissionsDetailsRoute: AppRouteBase = {
  pageTitle: OrderTranslations.OrderDetails,
  path: '/orderManagement/failedSubmissions/fulfillment/edit/:orderId',
  authClaim: failedSubmissionsClaims,
  parent: fulfillmentFailedSubmissionsRoute,
};

export enum ActionResult {
  Success,
  ErrorCallingAction,
  CheckAgainLater,
}
