import * as React from 'react';
import { Text } from 'ui';
import Wrapper from './label.wrapper';

export class Campaign extends React.PureComponent {
  render() {
    return (
      <Wrapper type="campaign">
        <Text caption="generic.dataGrid.score.campaign" />
      </Wrapper>
    );
  }
}

export default Campaign;
