import * as React from 'react';
import { Modal } from 'ui';
import { DEFAULT_LOCALE_ID } from 'config';
import { CategoriesHierarchy } from './CategoriesHierarchy';
import { CategoryHierarchyNode } from './types';
import { fetchCategories } from './api/categories';
import { transformToCategoryHierarchyNode, flatNodes, getSelectedNodeIds } from './service';

interface Props {
  onSelectCategories: (categories: CategoryHierarchyNode[]) => void;
  handleClose: () => void;
  breadCrumbs: string[];
  name: string;
}

export const CategorySearchModal = (props: Props) => {
  const {
    onSelectCategories, handleClose, breadCrumbs, name,
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const [nodes, setNodes] = React.useState([] as CategoryHierarchyNode[]);
  const [selectedNodes, setSelectedNodes] = React.useState<CategoryHierarchyNode[]>([]);
  const allNodes = React.useMemo<CategoryHierarchyNode[]>(() => flatNodes(nodes), [nodes]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>();

  React.useEffect(() => {
    fetchCategories({ localeId: DEFAULT_LOCALE_ID }).then((categories) => {
      const hierarchyNodes: CategoryHierarchyNode[] = transformToCategoryHierarchyNode(categories);
      setNodes(hierarchyNodes);
      setIsLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setSelectedIds(getSelectedNodeIds(breadCrumbs, allNodes));
  }, [isLoading, breadCrumbs]);

  const handleSelectCategories = () => {
    const newBreadCrumbs: CategoryHierarchyNode[] = selectedNodes
      .filter((node, i, input) => input.findIndex((n) => n.id === node.id) === i);

    setSelectedIds(selectedNodes.map((node: CategoryHierarchyNode) => node.id));

    onSelectCategories(newBreadCrumbs);
    handleClose();
  };

  return (<Modal
    name={name}
    caption="modules.merchandising.components.categoryModalSearch.titleDefault"
    size="large"
    onDismiss={handleClose}
    messages={[{ showLabel: false, caption: 'modules.merchandising.components.categoryModalSearch.message_01' }]}
    footerOptions={[
      {
        caption: 'generic.button.select',
        onClick: handleSelectCategories,
        disabled: selectedNodes.length === 0,
      },
      {
        caption: 'generic.button.cancel',
        outline: true,
        onClick: handleClose,
      },
    ]}
  >
    <CategoriesHierarchy
      nodes={nodes}
      onCategoriesChange={setSelectedNodes}
      isLoading={isLoading}
      selectedIds={selectedIds}
    />
  </Modal>);
};

export default CategorySearchModal;
