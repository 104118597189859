export const PERSONAL_SHOPPER_FORM = 'PERSONAL_SHOPPER_FORM';
export const SECTORS_API_DATA = 'SECTORS_API_DATA';
export const PERSONAL_SHOPPERS_API_DATA = 'PERSONAL_SHOPPERS_API_DATA';
export const PERSONAL_SHOPPERS_WAVES_API_DATA = 'PERSONAL_SHOPPERS_WAVES_API_DATA';
export const MODAL_ABANDON_WAVE = 'MODAL_ABANDON_WAVE';
export const PS_WAVES_REDUX_PATH = [`${PERSONAL_SHOPPERS_WAVES_API_DATA}`, 'data'];

export enum TabUrls {
  Details = 'information',
  Waves = 'waves-history',
}
