import React from 'react';
import { formField } from 'framework/components/hoc/formField';
import { WrappedComponentProps } from 'framework/components/hoc/formField/fieldWrapper.interface';
import { OrgHierarchy } from '../../models/orghierarchy';
import { StoresFilterSearchable } from '../StoresFilter';

interface StoresHierarchyField {
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  caption?: string;
  /**
   * default true, only set to true if useRetailerStoreId is false
   */
  useStoreId?: boolean;
  /**
   * default false, only set to true if useStoreId is false
   */
  useRetailerStoreId?: boolean;
  leavesOnly?: boolean;
  disabled?: boolean;
  orgEndpoint?: string;
  singleSelect?: boolean;
  stretch?: 'panel' | 'modal';
  hasFilter?: boolean;
  selectLeavesOnly?: boolean;
  showHeader?: boolean;
  className?: string;
  selectedStores?: any;
  mandatory?: boolean;
  onStoresSelected?: (stores: OrgHierarchy[]) => void;
}

const StoresHierarchy = ({
  value, onChange, useStoreId = true, useRetailerStoreId = false, leavesOnly = true,
  disabled = false, name, orgEndpoint, onStoresSelected, ...rest
}: StoresHierarchyField & WrappedComponentProps) => {
  const [idKey, setIdKey] = React.useState<string>();

  const handleClick = (nodes: OrgHierarchy[]) => {
    if (onStoresSelected) onStoresSelected(nodes);
    const storesSelected = nodes
    // Send only storeId or retailerStoreId, not nodes
      .filter((n) => !leavesOnly
        || (n.children && n.children.length === 0 && (n.value?.retailerStoreId || n.value?.storeId)))
      .map((n) => (idKey ? n.value[idKey] : n.id))
      .sort();
    onChange(storesSelected);
  };

  React.useEffect(() => {
    if (useStoreId) {
      setIdKey('storeId');
    } else if (useRetailerStoreId) {
      setIdKey('retailerStoreId');
    }
  }, []);

  return (
    <StoresFilterSearchable
      useStoreId={useStoreId}
      useRetailerStoreId={useRetailerStoreId}
      selectedStores={value}
      onChange={handleClick}
      disabled={disabled}
      formFilterName={name}
      orgEndpoint={orgEndpoint}
      {...rest}
    />
  );
};

export const StoresField = formField(StoresHierarchy) as React.ComponentType<StoresHierarchyField>;
