/* eslint-disable import/named */
import * as constants from '../contentemanagement.constants';
import { ContentManagementReduxState } from '../contentmanagement.interfaces';
import { CmsPageColumn } from '../CmsComponents.interfaces';
import { storePage } from './reducer.storepage';
import { clearCurrentPage } from './reducer.clearCurrentPage';
import { setEditRowIndex } from './reducer.setEditRowIndex';
import { addLayoutToPage } from './reducer.addLayoutToPage';
import { addColumnContent } from './reducer.addColumnContent';
import { removeRow } from './reducer.removeRow';
import { toggleDisplayPromotionsCarousel } from './reducer.toggleDisplayPromotionsCarousel';
import { reorderRows } from './reducer.reorderRows';
import { storeLayoutEditorFormData } from './reducer.storeLayoutEditorFormData';
import { STORE_EXPANDED_IDS, CLEAR_EXPANDED_IDS } from '../pages/common/actions';
import { storeExpandedIds, clearExpandedIds } from './reducerCmsAllPages';
import { SET_INITIAL_CONTENT, REVERT_CONTENT_CHANGES } from '../TabsNavigation/actions';
import { reducer as layoutWidthReducer, SAVE_LAYOUT_WIDTH } from '../common/Layoutwidth';
import { SharedComponentMetadata } from '../actions';
import { resolveTypeOfComponentFromRow, rowIsCarouselBased } from '../common/PageBuilderEngine/PageBuilder/utils';
import { detachSharedComponentFromReducer } from './detachSharedComponent';
import {
  CarouselTimeoutScreenTypes,
  TimeoutActionProps,
} from '../common/LayoutBuilder/InspectorBuilders/RowBuilder/Interfaces';
import { layoutReducer, SAVE_LAYOUT } from '../common/LayoutBuilder/LayoutType';
import { SAVE_LAYOUT_TEXT } from '../common/LayoutText/constants';
import { layoutReducer as layoutTextReducer } from '../common/LayoutText/reducer';

const initial: ContentManagementReduxState = {
  templates: {},
  activeColumnIndex: 0,
  carouselColumnIndex: 0,
  shouldReopenLayoutEditor: false,
  cmsPages: {
    expandedIds: [],
  },
};

const FORM_VALUE_CHANGED = '@@redux-form/CHANGE';

export const contentManagement = (
  state: ContentManagementReduxState = initial,
  action
): ContentManagementReduxState => {
  switch (action.type) {
    case constants.ACTION_CMS_STORE_PAGE: {
      return storePage(state, action);
    }
    case constants.ACTION_CMS_CLEAR_CURRENTPAGE: {
      return clearCurrentPage(state);
    }
    case constants.ACTION_CMS_SET_EDITROWINDEX: {
      return setEditRowIndex(state, action);
    }

    case constants.ACTION_CMS_ADD_LAYOUT_TO_PAGE: {
      return addLayoutToPage(state, action);
    }

    case constants.ACTION_CMS_REMOVE_ROW: {
      return removeRow(state, action);
    }

    case constants.ACTION_CMS_TOGGLE_DISPLAY_PROMOTIONS_CAROUSEL: {
      return toggleDisplayPromotionsCarousel(state, action);
    }

    case constants.ACTION_CMS_REORDER_ROWS: {
      return reorderRows(state, action);
    }

    // Store pageid which is the Url id
    case constants.ACTION_CMS_STORE_PAGEINFO: {
      const { pageId, variantId, ...rest } = action.payload;
      return {
        ...state,
        pageId,
        variantId,
        ...rest,
      };
    }

    case constants.ACTION_CMS_CLEAR_PAGECREATED_FLAG: {
      // remove pageCreated from store
      const { pageCreated, ...rest } = state;
      return {
        ...rest,
      };
    }

    case constants.ACTION_CMS_STORE_PAGES_HIERARCHY:
    case constants.ACTION_CMS_UPDATE_STORED_PAGE_HIERARCHY: {
      return {
        ...state,
        cmsPagesHierarchy: [...action.payload.cmsPagesHierarchy],
      } as any;
    }

    // Store dynamically loaded retailer templates
    // see CmsLoader
    case constants.ACTION_CMS_STORE_TEMPLATES:
      return {
        ...state,
        templates: {
          ...state.templates,
          ...action.payload.templates,
        },
      };

    case constants.ACTION_CMS_STORE_LAYOUT_EDITOR_FORMDATA: {
      return storeLayoutEditorFormData(state, action);
    }

    case constants.ACTION_CMS_SET_ACTIVE_COLUMNINDEX:
      return {
        ...state,
        activeColumnIndex: action.payload.activeColumnIndex,
      };

    case constants.ACTION_CMS_SHOULDREOPEN_LAYOUTEDITOR_MODAL: {
      return {
        ...state,
        shouldReopenLayoutEditor: action.payload.shouldReopen,
      };
    }

    case constants.ACTION_CMS_INIT_ROWEDITOR_DATA:
      return {
        ...state,
        rowEditorData: action.payload.data,
      };

    case constants.ACTION_CMS_CLEAR_ROWEDITOR_DATA:
      return {
        ...state,
        rowEditorData: undefined,
      };

    case constants.ACTION_CMS_ADD_EMPTY_COLUMN:
      return {
        ...state,
        rowEditorData: {
          ...state.rowEditorData,
          columns: [
            ...state.rowEditorData.columns,
            {
              type: 'Template',
              data: {},
            },
          ],
        },
      };

    case constants.ACTION_CMS_REMOVE_COLUMN:
      return {
        ...state,
        rowEditorData: {
          ...state.rowEditorData,
          columns: state.rowEditorData.columns.filter((_, i) => i !== action.payload.columnIndex),
        },
      };

    // Removes all deleted shared components by filtering them out of cmsContent
    case constants.ACTION_CMS_REMOVE_DELETED_TYPE: {
      // Filtering out all possible deleted shared components
      const filtered = state.loadedPage.cmsContent.rows.map((row) => ({
        // Check for columns / slides that are not deleted shared components
        ...row,
        columns:
          row.columns?.length > 0 && rowIsCarouselBased(row)
            ? // If container is carousel, grab items
              [
                {
                  ...row.columns[0],
                  items: row.columns[0]?.items.filter((item) => item.type !== 'DocumentPlaceholder'),
                },
              ]
            : // If container is a row, grab columns
              row.columns?.filter((column) => column?.type !== 'DocumentPlaceholder'),
      }));
      return {
        ...state,
        loadedPage: {
          ...state.loadedPage,
          cmsContent: {
            ...state.loadedPage.cmsContent,
            rows: filtered
              .filter((row) => {
                // If component doesn't have a component key, it's a new component.
                if (!row.componentKey || (row.componentKey && row.columns.length === 0)) return true;
                return row.columns?.length > 0 && rowIsCarouselBased(row)
                  ? row.columns[0].items.length !== 0
                  : row.columns?.length !== 0;
                // Filtering out any row that is a deleted shared component
              })
              .filter((row) => row.type !== 'DocumentPlaceholder'),
          },
        },
      };
    }

    case constants.ACTION_CMS_REMOVE_COLUMN_PAGE_VIEW: {
      const { rowIndex, columnIndex, carouselBased } = action.payload;
      const { loadedPage } = state;

      return {
        ...state,
        loadedPage: {
          ...loadedPage,
          cmsContent: {
            ...loadedPage.cmsContent,
            rows: [
              ...loadedPage.cmsContent.rows.map((row, i) => {
                if (i === rowIndex) {
                  return {
                    ...row,
                    columns: carouselBased
                      ? [{ ...row.columns[0], items: row.columns[0].items.filter((_d, cI) => cI !== columnIndex) }]
                      : row.columns.filter((_d, rI) => rI !== columnIndex),
                  };
                }

                return row;
              }),
            ],
          },
        },
      };
    }

    case constants.ACTION_CMS_ADD_COLUMN_CONTENT: {
      return addColumnContent(state, action);
    }

    case constants.ACTION_INSERT_SHARED_COMPONENT_COLUMN: {
      const { colIndex, data } = action.payload;
      return {
        ...state,
        rowEditorData: {
          ...state.rowEditorData,
          columns: state.rowEditorData.columns.map((col, i) => {
            if (i !== colIndex) {
              return col;
            }
            if (!col.items) {
              return {
                ...col,
                ...data,
              };
            }
            return {
              ...col,
              items: col.items.map((item, j) => {
                if (j !== colIndex) {
                  return item;
                }
                return {
                  ...item,
                  ...data,
                };
              }),
            };
          }),
        },
      };
    }

    case constants.ACTION_CMS_STORE_ROWEDITOR_DATA: {
      const { index, data } = action.payload;
      return {
        ...state,
        loadedPage: {
          ...state.loadedPage,
          cmsContent: {
            ...state.loadedPage.cmsContent,
            rows: state.loadedPage.cmsContent.rows.map((row, i) => {
              if (index !== i) {
                return row;
              }
              return {
                ...row,
                ...data,
              };
            }),
          },
        },
      };
    }

    case constants.ACTION_CREATE_SHARED_COMPONENT_PLACEHOLDER: {
      const { rowIndex, colIndex, shared } = action.payload as SharedComponentMetadata;

      return {
        ...state,
        loadedPage: {
          ...state.loadedPage,
          cmsContent: {
            ...state.loadedPage.cmsContent,
            rows: state.loadedPage.cmsContent.rows.map((row, i) => {
              if (rowIndex !== i) {
                return {
                  ...row,
                };
              }

              const type = resolveTypeOfComponentFromRow(row);

              if (colIndex === undefined) {
                if (type === 'Row') {
                  return {
                    ...row,
                    ...shared,
                  };
                }

                return {
                  ...row,
                  columns: [
                    {
                      ...row.columns[0],
                      ...shared,
                    },
                  ],
                };
              }

              return {
                ...row,
                columns: row.columns.map((col, _index) => {
                  // non-carousel based layouts
                  if (!rowIsCarouselBased(row)) {
                    if (_index !== colIndex) {
                      return {
                        ...col,
                      };
                    }

                    return {
                      ...col,
                      ...shared,
                    };
                  }

                  // carousel bases layouts
                  return {
                    ...col,
                    items: col.items.map((colItem, __index) => {
                      if (__index !== colIndex) {
                        return {
                          ...colItem,
                        };
                      }
                      return {
                        ...colItem,
                        ...shared,
                      };
                    }),
                  };
                }),
              };
            }) as never,
          },
        },
      };
    }

    case constants.DETACH_SHARED_COMPONENT: {
      const { rowIndex, colIndex } = action.payload;
      return detachSharedComponentFromReducer(rowIndex, colIndex, state as unknown as ContentManagementReduxState);
    }

    case FORM_VALUE_CHANGED: {
      const rowIsEditing =
        state.rowEditorData !== undefined &&
        state.rowEditorData !== null &&
        action.meta.form === constants.FORM_LAYOUT_INSPECTOR;

      if (!rowIsEditing) {
        return state;
      }

      const value = action.payload;
      const propname = action.meta.field;

      const columnIndex = state.isCarouselEditing ? state.carouselColumnIndex : state.activeColumnIndex;

      return {
        ...state,
        rowEditorData: {
          ...state.rowEditorData,
          columns: state.rowEditorData.columns.map((col: CmsPageColumn, i) => {
            if (columnIndex !== i) {
              return col;
            }
            const { type = 'Template' } = col;

            if (type !== 'Template') {
              return {
                ...col,
                [propname]: value,
              };
            }

            return {
              ...col,
              data: {
                ...col.data,
                [propname]: value,
              },
            };
          }),
        },
      };
    }

    case constants.ACTION_CMS_UPDATE_CAROUSEL_DATA: {
      const { rowIndex, colIndex, rowData, largeTimeout, mediumTimeout } = action.payload;

      const newState = {
        ...state,
        loadedPage: {
          ...state.loadedPage,
          // page content
          cmsContent: {
            ...state.loadedPage.cmsContent,

            // for each row
            rows: state.loadedPage.cmsContent.rows.map((row, i) => {
              // find row of rowIndex
              if (rowIndex !== i) {
                return row;
              }
              return {
                ...row,
                width: rowData.width,
                settings: rowData.settings,
                // for each column
                columns: row.columns.map((col: any, j) => {
                  // find column of colIndex
                  if (colIndex !== j) {
                    return col;
                  }

                  // replace Carousel.items with updated data
                  return {
                    ...col,
                    items: rowData.columns,
                    largeTimeout,
                    mediumTimeout,
                    smallTimeout: mediumTimeout,
                  };
                }),
              };
            }),
          },
        },
      };

      return newState;
    }

    case constants.ACTION_CMS_SET_CAROUSEL_COLUMNINDEX: {
      return {
        ...state,
        carouselColumnIndex: action.payload.carouselColumnIndex,
      };
    }

    case constants.ACTION_CMS_SET_CAROUSEL_TIMEOUT: {
      const { screenType, timeout }: TimeoutActionProps = action.payload;

      const finalTimeout: string = timeout?.toString();

      return !finalTimeout
        ? {
            ...state,
            ...(!finalTimeout && {
              largeTimeout: '0',
              mediumTimeout: '0',
              smallTimeout: '0',
            }),
          }
        : {
            ...state,
            ...(screenType === CarouselTimeoutScreenTypes.Desktop && { largeTimeout: finalTimeout }),
            ...(screenType === CarouselTimeoutScreenTypes.Mobile && {
              mediumTimeout: finalTimeout,
              smallTimeout: finalTimeout,
            }),
          };
    }

    case constants.ACTION_CMS_SET_CAROUSEL_IS_EDITING: {
      return {
        ...state,
        isCarouselEditing: action.payload.isEditing,
        carouselColumnIndex: action.payload.isEditing ? state.carouselColumnIndex : 0,
        editingSubContainerType: action.payload.isEditing ? state.editingSubContainerType : undefined,
      };
    }

    case constants.ACTION_CMS_SET_EDIT_SHARED_COMPONENT: {
      return {
        ...state,
        sharedComponentOpen: action.payload.component,
      };
    }

    case constants.ACTION_CMS_STORE_HIGHLIGHTED_FOLDERID: {
      return {
        ...state,
        folderId: action.payload.folderId,
      };
    }

    case STORE_EXPANDED_IDS: {
      return storeExpandedIds(state, action);
    }

    case CLEAR_EXPANDED_IDS: {
      return clearExpandedIds(state, action);
    }

    case SET_INITIAL_CONTENT: {
      const content = action.payload ?? {};
      return {
        ...state,
        initialCmsContent: {
          ...JSON.parse(JSON.stringify(content)),
        },
      };
    }

    case REVERT_CONTENT_CHANGES: {
      const { initialCmsContent } = state;
      return storePage(state, {
        type: REVERT_CONTENT_CHANGES,
        payload: {
          cmsContent: {
            ...initialCmsContent,
          },
        },
      });
    }

    case constants.ACTION_CMS_SET_INSERT_LAYOUT_INDEX: {
      const { insertIndex } = action.payload;
      return {
        ...state,
        insertIndex,
      };
    }

    case constants.ACTION_SET_SELECTED_COMPONENT_IN_MANAGER: {
      const { selectedDocumentedInManager } = action.payload;
      return {
        ...state,
        ...(selectedDocumentedInManager && { selectedDocumentedInManager }),
      };
    }

    case constants.ACTION_SET_ALL_PAGES_STATE: {
      const { lastSelectedId, filterByName, filterByStatus, filterByScheduleStatus, documentsHierarchy } =
        action.payload;

      return {
        ...state,
        cmsPages: {
          ...state.cmsPages,
          ...(lastSelectedId && { lastSelectedId }),
          ...((filterByName || filterByName?.length === 0) && {
            filterByName: filterByName?.length > 0 ? filterByName : '',
          }),
          ...((filterByStatus || filterByStatus?.length === 0) && {
            filterByStatus: filterByStatus?.length > 0 ? filterByStatus : '',
          }),
          ...((filterByScheduleStatus || filterByScheduleStatus?.length === 0) && {
            filterByScheduleStatus: filterByScheduleStatus?.length > 0 ? filterByScheduleStatus : '',
          }),
          ...(documentsHierarchy && { documentsHierarchy }),
        },
      };
    }

    case SAVE_LAYOUT_WIDTH: {
      return {
        ...state,
        ...layoutWidthReducer(state, action),
      };
    }

    case SAVE_LAYOUT_TEXT: {
      return {
        ...state,
        ...layoutTextReducer(state, action),
      };
    }

    case SAVE_LAYOUT: {
      return {
        ...state,
        ...layoutReducer(state, action),
      };
    }

    case constants.ACTION_SET_SUBCONTAINER_EDITING: {
      const { editingSubContainerType } = action.payload;
      return {
        ...state,
        editingSubContainerType,
      };
    }

    default:
      return state;
  }
};
