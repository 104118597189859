import { fork } from 'redux-saga/effects';

import fetchSlotTemplateSaga from './fetchSlotTemplateSaga';
import fetchCalendarIniDataAction from './fetchCalendarIniDataSaga';
import deleteSlotSelectedSaga from './deleteSlotSelectedSaga';
import editSlotTemplateSaga from './editSlotTemplateSaga';
import saveSlotTemplateDataSaga from './saveDataSaga';

export default [
  fork(fetchSlotTemplateSaga),
  fork(fetchCalendarIniDataAction),
  fork(deleteSlotSelectedSaga),
  fork(editSlotTemplateSaga),
  fork(saveSlotTemplateDataSaga)
];
