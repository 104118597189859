/* eslint-disable import/no-cycle */
import * as React from 'react';
import classNames from 'classnames';
import { prefixClassName } from '../../../_conf';
import { Multiselect, Select as Slct } from '../../..';

const clx = prefixClassName('filter-bar__input');

export interface SelectOption {
  value: string;
  label: string;
}
interface SelectProps {
  className?: string;
  placeholder: string;
  name: string;
  options: SelectOption[];
  value?: string;
  onChange: (option?: SelectOption) => void;
  searchable?: boolean;
  readOnly?: boolean;
  multiSelect?: boolean;
}

const Select = ({
  className,
  options,
  onChange,
  placeholder,
  name,
  value,
  readOnly = false,
  searchable = false,
  multiSelect = false,
}: SelectProps) => (
  !multiSelect 
    ? <Slct
      labelInline
      className={classNames(clx, className)}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      value={value}
      options={options}
      searchable={searchable}
      readOnly={readOnly}
    />
    : <Multiselect
        labelInline
        className={classNames(clx, className)}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        value={value}
        options={options}
        searchable={searchable}
        readOnly={readOnly}
  />
);

export { Select };
