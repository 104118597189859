import * as React from 'react';
import classNames from 'classnames';
import { LayoutFlexBoxItem, Message, Table, Text } from 'ui';
import { withStencils } from 'framework/components/hoc/withStencils';
// eslint-disable-next-line import/no-cycle
import { IGrid } from './DataGridDouble.interface';
import type { ProductScore } from '../../models/product';
import { ProductRow } from '../ProductRow/ProductRow';
import { positionsStore } from '../../common/Rules/components/Actions/ActionsPosition/ActionsPosition';
import { actionsStore } from '../../common/Rules/components/Actions/ActionsComponent';
import { addDynamicFieldPosition } from '../../common/Rules/components/Actions/ActionsPosition/utils/addDynamicFieldPosition';

export const StencilledTable = withStencils('data-grid')(Table);

const ContentGrid = (props: IGrid) => {
  const {
    title,
    className: clx,
    name,
    type = 'current',
    selectProduct,
    loadScoreExplanation,
    products = {
      total: 0,
      list: [],
      isFetching: false,
    },
    scoreLabels = ['score', 'campaign', 'customPlacement', 'moved'],
    draggable = false,
    fetchCurrentData,
    flexBasis = '48%',
  } = props;

  const { isFetching, total, list, items } = products;

  const data = items || list || [];
  const { length } = data;
  const getTitle = title || `generic.dataGridDouble.${type}.title`;

  const onSelectProduct = (e: React.MouseEvent, product: ProductScore) => {
    e.preventDefault();

    selectProduct(product);
  };

  const positionActionExist = actionsStore?.fields?.find(
    (v) => v?.field?.value === 'position' || v?.field === 'position'
  );

  const showDraggable = draggable && !!positionActionExist;

  return (
    <LayoutFlexBoxItem
      flexGrow={1}
      flexBasis={flexBasis}
      className={classNames(clx, isFetching && `${clx}--isFetching`, type && `${clx}--${type}`)}
    >
      {draggable && (
        <Message
          showLabel={false}
          caption="modules.merchandising.searchResults.dragAndDropExplainer"
          className="mrg-btm-20"
        />
      )}
      <StencilledTable
        tableName={name}
        data={data}
        {...(actionsStore?.fields?.length === 0 && { emptyText: 'modules.merchandising.searchResults.actionsNeeded' })}
        stencilled={isFetching}
        {...(showDraggable && {
          draggable: true,
          draggableId: 'sku',
          onDragEnd: (_id, index, movedProduct: any) => {
            addDynamicFieldPosition([movedProduct], positionsStore.fields, fetchCurrentData, index);
          },
        })}
        editSequenceCaption="generic.button.editSequence"
        columnMetadata={[
          Table.EmptyHeader,
          Table.EmptyHeader,
          Table.EmptyHeader,
          ...(data.length === 0
            ? [Table.EmptyHeader, Table.EmptyHeader, Table.EmptyHeader, Table.EmptyHeader, Table.EmptyHeader]
            : []),
        ]}
        cellRenderer={(_t, p: ProductScore, _c, index) => (
          <ProductRow
            product={p}
            selectProduct={onSelectProduct}
            loadScoreExplanation={loadScoreExplanation}
            draggable={draggable}
            // This for the drag and drop if position action exist
            showDraggable={showDraggable}
            index={index}
            // Product changes are already saved if not in newPositions array
            saved={!positionsStore.newPositions?.includes(p.productId)}
            scoreLabels={scoreLabels}
          />
        )}
        headerRenderer={() => (
          <Table.Row>
            <Table.Head colSpan={showDraggable ? 4 : 2} className={`${clx}__header`}>
              <Text caption={getTitle} tag="span" className={`${clx}__header__title`} />
              <Text
                caption="generic.dataGridDouble.showingXofY"
                i18nOptions={{ x: length, y: total }}
                tag="span"
                className={`${clx}__header__pager`}
              />
            </Table.Head>
            <Table.Head align="right">
              <Text caption="generic.dataGridDouble.score" />
            </Table.Head>
            {draggable && (
              <>
                <Table.Head align="right">
                  <Text caption="generic.dataGridDouble.unitSales" />
                </Table.Head>
                <Table.Head align="right">
                  <Text caption="generic.dataGridDouble.revenue" />
                </Table.Head>
                <Table.Head align="right">
                  <Text caption="generic.dataGridDouble.changes" />
                </Table.Head>
              </>
            )}
          </Table.Row>
        )}
      />
    </LayoutFlexBoxItem>
  );
};

export default ContentGrid;
