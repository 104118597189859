import i18next, { WithT } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { errorHandler } from 'framework/services/errorHandler';

const i18n: WithT = {
  t: (s) => s,
};

const translationPath = `locales/{{lng}}/{{ns}}.json?${new Date().getTime()}`;

const initTranslations = () => i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    debug: false,
    defaultNS: 'translations',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    react: {
      wait: true,
    },
    nsSeparator: '|',
    backend: {
      loadPath: process.env.NODE_ENV !== 'production' ? `/${translationPath}` : translationPath,
    },
  })
  .then((t) => {
    i18n.t = t;
  })
  .catch(errorHandler);

/**
 * @deprecated use `i18n.t(caption, options)` instead
 */
const i18nWithParams = (text: string, data: {}) => i18n.t(text, data);

/**
 * @deprecated use named import instead `import { i18n } from 'i18n'`;
 */
export default i18n;
export { initTranslations, i18n, i18nWithParams };
