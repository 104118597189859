import { call, put } from 'redux-saga/effects';
import * as CONST from 'framework/modules/searchadmin/constants';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { config } from 'config';
import { makeRequest } from 'framework/api/make-request';
import { tooltipActions } from 'framework/actions';

/**
 * Gets the current product score
 */
export function watchGetCurrentScore() {
  return takeEveryWithProgressBar(
    CONST.ACTION_RELEVANCE_CURRENT_SCORE,
    fetchScore.bind(null, getCommomRequestParts)
  );
}

/**
 * Gets the preview product score
 */
export function watchGetPreviewScore() {
  return takeEveryWithProgressBar(
    CONST.ACTION_RELEVANCE_PREVIEW_SCORE,
    fetchScore.bind(null, getCommomRequestPartsWithPreview)
  );
}

function getCommomRequestParts(payload) {
  const { productId } = payload;
  const {
    storeId = CONST.DEFAULT_STORE_ID,
    localeId = CONST.DEFAULT_LOCALE_ID,
    query = '',
    take: t = 10,
    skip = 0
  } = payload.data;

  return {
    url: `${
      config.apiEndpoints.search
    }/relevance/locale/${localeId}/store/${storeId}/products/${productId}/explain`,
    body: {
      q: query,
      take: t,
      skip
    }
  };
}

function getCommomRequestPartsWithPreview(payload) {
  const commonRequest = getCommomRequestParts(payload);
  const { attributes } = payload.data;

  return {
    url: commonRequest.url,
    body: {
      ...commonRequest.body,
      configurationOverride: {
        overrideType: 'Merge',
        relevancyConfiguration: attributes
      }
    }
  };
}

export function* fetchScore(getRequestFn, { type, payload }) {
  const request = yield call(getRequestFn, payload);

  // Make Request
  const { data = {} } = yield call(makeRequest, {
    name: type,
    method: 'POST',
    url: request.url,
    body: request.body
  });

  // Store Tooltip Data in store
  yield put(tooltipActions.storeTooltipData({ id: payload.productId, data }));
}
