import { call, takeEvery } from 'redux-saga/effects';
import { apiClientActions } from 'framework/actions';
import * as CONST from 'framework/modules/searchadmin/constants';

export default function* watchMerchandisingRelevanceClearData() {
  yield takeEvery(CONST.ACTION_RELEVANCE_ATTRIBUTES_CLEAR_DATA, merchandisingRelevanceClearData);
}

function* merchandisingRelevanceClearData() {
  yield call(apiClientActions.clearApiClientStateAction, {
    name: CONST.API_DATA_PROVIDER_RELEVANCE_ATTRIBUTES
  });
  yield call(apiClientActions.clearApiClientStateAction, {
    name: CONST.API_DATA_PROVIDER_RELEVANCE_CURRENT
  });
  yield call(apiClientActions.clearApiClientStateAction, {
    name: CONST.API_DATA_PROVIDER_RELEVANCE_PREVIEW
  });
}
