import * as React from 'react';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Props } from './LayoutFlexBoxItem.interaces';
import './_styles.scss';

const clx = prefixClassName('flexbox-item');

export const LayoutFlexBoxItem = (props: Props) => {
  const getFlexItemAlignSelf = (alignSelf: string = 'auto') => {
    const alignSelfOpt = {
      baseline: 'baseline',
      center: 'center',
      'flex-end': 'flex-end',
      'flex-start': 'flex-start',
      stretch: 'stretch',
    };

    return alignSelfOpt[alignSelf];
  };

  const getFlexItemProperties = () => {
    const {
      width, alignSelf, flexBasis, flexGrow, flexShrink, order,
    } = props;

    return {
      alignSelf: alignSelf && getFlexItemAlignSelf(alignSelf),
      flexBasis,
      flexGrow,
      flexShrink,
      order,
      width,
    };
  };

  const { children, className } = props;
  const styles = getFlexItemProperties();

  return (
    <div style={styles} className={classNames(clx, className)}>
      {children}
    </div>
  );
};

export default LayoutFlexBoxItem;