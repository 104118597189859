import * as React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import classNames from 'classnames';
import { SubTab } from '../SubTab';
import * as selectors from '../../Tabs.selectors';

const clx = prefixClassName('tabsSub');
const clxWrapper = `${clx}__wrapper`;

interface AnchorProps {
  anchors: {};
  onAnchorClick: () => {};
  ifFixed: boolean;
  selectedAnchor: string;
  unshiftLastAnchor?: boolean;
}

export const Anchors = (props: AnchorProps) => {
  const {
    anchors = {}, onAnchorClick, ifFixed = false, selectedAnchor, unshiftLastAnchor,
  } = props;
  const hasAnchors = Object.keys(anchors).length > 0;

  const renderAnchors = Object.keys(anchors).map((anchor) => (
      <SubTab
        key={anchor}
        label={anchor}
        onClick={onAnchorClick}
        className={anchor === selectedAnchor ? `${clx}__tab--selected` : `${clx}__tab`}
      />
  ));

  // If unshiftLastAnchor is true, move the last anchor to the beginning
  if (renderAnchors.length > 0 && unshiftLastAnchor) {
    renderAnchors.unshift(renderAnchors.pop());
  }

  return (
    hasAnchors && (
      <div className={classNames(clxWrapper, ifFixed && `${clxWrapper}--fixed`)}>
        <Icon name="keyboardReturn" className={`${clx}__icon`} />
        <div className={clx}>{renderAnchors}</div>
      </div>
    )
  );
};

const mapStateToProps = (state, ownProps) => ({
  anchors: selectors.getRegisteredAnchorsMemoized(state, ownProps.tabGroupId),
});

export default connect(mapStateToProps)(Anchors);
