import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import { CrumbItem, CrumbItemProps } from '../CrumbItem';
import './styles.scss';

const BaseClassName = conf.prefixClassName('breadcrumbs');
/**
 * This is the top Page Breadcrumbs of the application (see Page.tsx)
 * It renders breadcrumbs as a 'ol' list of Link
 * breadcrumbs are received from redux store
 */

const getCrumbs = (appRoute: CrumbItemProps, lastCrumbs = []): CrumbItemProps[] => {
  if (appRoute.parent) {
    return [...getCrumbs(appRoute.parent, lastCrumbs), appRoute];
  }
  return [appRoute, ...lastCrumbs];
};

export const AppCrumbs = ({ appRoute }: { appRoute: CrumbItemProps }) => {
  const crumbs = getCrumbs(appRoute);

  if (appRoute.path !== '/') {
    // adds home to crumbs
    crumbs.unshift({ path: '/', pageTitle: 'navigation.home' });
  }

  return (
    <div className={BaseClassName}>
      <div className={`${BaseClassName}__listing`}>
        {crumbs.map((crumb) => <CrumbItem key={crumb.path} appRoute={crumb} />)}
      </div>
    </div>
  );
};
