import * as React from 'react';
import classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IformField } from 'framework/components/hoc/formField';
import { Translator } from 'framework/components/hoc/withTranslation';
import { Tooltip } from 'ui';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { TrackEventProps } from 'framework/applicationMonitoring/interface';
import { useLocation } from 'react-router-dom';
import TextFieldComponent, { TextFieldProps } from '../TextField';
import ButtonComponent from '../Button';
import { IconNames } from '../../Icon';
import './_style.scss';

const RETURN_KEY = 13;
const className = prefixClassName('filter-field');

interface FilterFieldProps extends TextFieldProps, IformField {
  caption?: string;
  onFilter: (term: string) => void;
  filterOnKeyPress?: boolean;
  icon?: IconNames;
  iconBg?: 'default' | 'main';
  disabledToolTip?: string;
  trackEventProps?: TrackEventProps;
}

const FilterField = (props: FilterFieldProps) => {
  const {
    iconBg, icon, filterOnKeyPress, onFilter, disabled, disabledToolTip, trackEventProps, name
  } = props;
  let input;

  const { pathname } = useLocation();

  const [showToolTip, setShowToolTip] = React.useState(false);

  const filter = () => {
    ApplicationMonitoring.trackEvent(trackEventProps?.name || pathname, trackEventProps?.value || 'Filter', trackEventProps?.label || name );
    onFilter(input.value);
  };

  const onClear = () => {
    ApplicationMonitoring.trackEvent(trackEventProps?.name || pathname, trackEventProps?.value || 'Filter Clear', trackEventProps?.label || name );
    onFilter('');
  };

  const onKeyPress = (e) => {
    if (e.which === RETURN_KEY || e.charCode === RETURN_KEY) {
      e.preventDefault();
      filter();
    } else if (filterOnKeyPress) {
      filter();
    }
  };

  const handleTooltipAction = (val: boolean): void => {
    if (disabledToolTip && disabled) {
      setShowToolTip(val);
    }
  };

  return (
    <span
      className="mwg-filter-field__container"
      onMouseOver={() => handleTooltipAction(true)}
      onMouseOut={() => handleTooltipAction(false)}
      onFocus={() => handleTooltipAction(true)}
      onBlur={() => handleTooltipAction(false)}
    >
      {disabledToolTip && disabled && (
        <Tooltip toolTipClassName="mwg-filter-field__tooltip" position="up-centre" active={showToolTip}>
          <Translator caption={disabledToolTip} render={(text) => <>{text}</>} />
        </Tooltip>
      )}
      <TextFieldComponent
        {...props}
        className={classnames(className, iconBg && `${className}--${iconBg}`)}
        labelInline
        onKeyPress={onKeyPress}
        onClear={onClear}
        setRef={(node) => {
          input = node;
        }}
        disabled={disabled}
        button={<ButtonComponent disabled={disabled} icon={icon || 'filter'} outline onClick={filter} />}
        clearable
      />
    </span>
  );
};

export default FilterField;
