import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { history } from 'framework/store';
import { Props, Actions } from './ModalPreventNavigation.interfaces';
import { BaseModalPreventNavigation } from './BaseModalPreventNavigation';

export const defaultModalName = 'modal_PreventNavigation';

export const ModalPreventNavigation = (props: Props & Actions) => {
  const [nextLocation, setNextLocation] = React.useState(null);
  const [continueWithoutSaving, setContinueWithoutSaving] = React.useState(false);

  const { closeModal, openModal, blockNavigation } = props;

  React.useEffect(() => {
    if (continueWithoutSaving) {
      history.push(nextLocation);
    }
  }, [continueWithoutSaving, nextLocation, closeModal]);

  const handleBlockedNavigation = (location) => {
    const shouldBlockNavigation = !continueWithoutSaving;
    if (shouldBlockNavigation) {
      setNextLocation(location.pathname);
      openModal();
      return false;
    }
    return true;
  };

  return (
    blockNavigation && (
      <>
        <Prompt when={blockNavigation} message={handleBlockedNavigation} />
        <BaseModalPreventNavigation
          modalName={defaultModalName}
          onAcceptNavigation={closeModal} // stay on this page
          onCancelNavigation={() => { setContinueWithoutSaving(true); closeModal(); }} // continue without saving and close modal
        />
      </>
    )
  );
};
