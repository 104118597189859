import {
  all, call, put, select,
} from 'redux-saga/effects';
import { checkEditedObjects } from 'framework/utils/helpers';
import { IMakeRequest, makeRequest } from 'framework/api/make-request';
import { toastListActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { config } from 'config';
import { createApiClientUpdateStateAction } from 'framework/api/apiClient/actions';
import { formSelectors } from 'framework/selectors';
import { actionTypes } from '../actions';
import { DeliveryAreaType } from '../DeliveryArea.interface';

const STORES_SAVE_DELIVERY_AREAS = actionTypes.API_PROVIDER_SAVE_DELIVERY_AREAS;
const { API_PROVIDER_GET_DELIVERY_AREAS } = actionTypes;

const baseUri = config.apiEndpoints.api;

// TODO: Reexport translations in module to shorten string in src
const errorMessage = (action: 'save' | 'delete') => toastListActions.addMessageError(
  `modules.stores.storeSetup.orderFulfillment.deliveryArea.editDeliveryArea.errors.${action}`,
);

function* saveDeliveryArea(deliveryArea: DeliveryAreaType) {
  const request: IMakeRequest = {
    name: STORES_SAVE_DELIVERY_AREAS,
    url: `${baseUri}/deliveryareas`,
    method: 'PUT',
    body: {
      ...deliveryArea,
      // #techdebt: remove this hackery once the API consolidates these 2 names.
      polygons: deliveryArea.areaPolygons,
      areaId: deliveryArea.id,
      name: deliveryArea.areaName,
    },
  };
  const { error } = yield call(makeRequest, request);
  if (error) {
    yield put(errorMessage('save'));
  }
}

function* deleteDeliveryArea(deliveryArea) {
  const request: IMakeRequest = {
    name: STORES_SAVE_DELIVERY_AREAS,
    url: `${baseUri}/store/${deliveryArea.storeId}/deliveryareas/${deliveryArea.id}`,
    method: 'DELETE',
  };
  const { hasError } = yield call(makeRequest, request);
  if (hasError) {
    yield put(errorMessage('delete'));
  }
}

function* process({ payload }) {
  const { formName, previous } = payload;

  const formValues = yield select(formSelectors.getFormValues, formName);
  const updated = formValues.deliveryAreas || [];

  const toDelete = previous.filter((d) => !updated.map((s) => s.id).includes(d.id));

  const editedDeliveryAreas = checkEditedObjects(previous, updated, 'id', toDelete, true);

  yield all(editedDeliveryAreas.map((deliveryArea) => call(saveDeliveryArea, deliveryArea)));
  yield all(toDelete.map((deliveryArea) => call(deleteDeliveryArea, deliveryArea)));

  yield put(
    createApiClientUpdateStateAction({
      data: updated,
      name: API_PROVIDER_GET_DELIVERY_AREAS,
      hasError: false,
      isFetching: false,
    }),
  );
}

export default function* watchSaveDeliveryAreasAction() {
  yield takeEveryWithProgressBar(actionTypes.STORES_SAVE_DELIVERY_AREAS, process);
}
