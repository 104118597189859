import { connect } from 'react-redux';
import { modalActions } from 'framework/actions';
import { formSelectors } from 'framework/selectors';
import { ModalPreventNavigation, defaultModalName } from './ModalPreventNavigation';
import { Props, Actions } from './ModalPreventNavigation.interfaces';

export function formBlocker(state: any, forms: string | string[]) {
  if (!forms) {
    return false;
  }
  const formNames = typeof forms === 'string' ? [forms] : forms;
  return formNames.some((name) => formSelectors.getIsFormDirty(state, name));
}

const mapStateToProps = (state, ownProps) => {
  const { subscribeToForm, blockNavigation: customBlockNavigation } = ownProps;

  return {
    blockNavigation: customBlockNavigation || formBlocker(state, subscribeToForm),
  };
};

const mapDispatchToProps = (dispatch): Actions => ({
  openModal: () => dispatch(modalActions.createOpenModalAction(defaultModalName)),
  closeModal: () => dispatch(modalActions.createCloseModalAction(defaultModalName)),
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalPreventNavigation) as any) as React.StatelessComponent<Props>;
