import * as React from 'react';
import classNames from 'classnames';
import FilterBar from '../FilterBar';
import { FilterBarSettings } from './Table.interfaces';

interface TableFilterInterface {
  tableName: string;
  filterBarSettings: FilterBarSettings;
  handleOnFilter: (value: string) => void;
}

export const TableFilter = ({ tableName, filterBarSettings, handleOnFilter }: TableFilterInterface) => (
  <FilterBar
    name={`filter-${tableName}-bar`}
    initialValues={{}}
    className={
      classNames(filterBarSettings.className, filterBarSettings.stretchToModalWidth && 'stretchFilterBarToModalWidth')
    }
  >
    <FilterBar.Input
      placeholder={filterBarSettings.filterPlaceholder}
      name={`filter-${tableName}`}
      testId={`filter${tableName}`}
      onFilter={handleOnFilter}
    />
  </FilterBar>
);