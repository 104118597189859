import * as React from 'react';
import { safeGet } from 'framework/utils';
import { Text } from 'framework/components/ui';
import { i18n } from 'i18n';
// eslint-disable-next-line import/no-cycle
import { clx } from '../Event';

const localePath = 'components.calendar.events.template.slotTemplate.';

const formatTime = (val: string) => {
  // return val if it is not in a time format
  if (!val?.includes('.') && !val?.includes(':')) {
    return val;
  }

  if (val?.includes('.')) {
    // Format -> 1.12:30:00
    const [day, time] = val.split('.');
    const [h, m] = time ? time.split(':') : [];

    return `${day}d ${h}h ${m}m`;
  }

  // Format -> 12:30:00
  const [h, m] = val ? val.split(':') : [];

  return `${h}h ${m}m`;
};

const EventSlotTemplate = React.memo((props: any) => {
  const { data = {} } = props;
  const {
    capacity,
    newOrdersCutoff,
    pickByTime,
    defaultPickByTime,
    tags = [],
    fulfillmentTypeDisplayName,
    price = 0,
    deliveryAreas = [],
    infiniteCapacity,
  } = data;

  const renderProp = (label: string, value: any) =>
    !!value && (
      <li>
        <Text caption={label} />: {value}
      </li>
    );
  const renderPropsArray = (label: string, values: string[] = []) =>
    !!values.length && (
      <li>
        <Text caption={label} />: {values.join(', ')}
      </li>
    );

  return (
    <ul className={`${clx}__list`}>
      {renderProp(`${localePath}fulfillment`, safeGet(fulfillmentTypeDisplayName, 'label', fulfillmentTypeDisplayName))}
      {renderProp(`${localePath}capacity`, infiniteCapacity ? i18n.t('generic.unlimited') : capacity)}
      {renderProp(`${localePath}price`, price || '0')}
      {renderProp(`${localePath}cutOff`, formatTime(newOrdersCutoff))}
      {renderProp(`${localePath}pickByTime`, formatTime(pickByTime ?? defaultPickByTime ?? 'Default'))}
      {renderPropsArray(`${localePath}tags`, tags)}
      {renderPropsArray(
        `${localePath}areas`,
        deliveryAreas.map((d) => d.value || d)
      )}
    </ul>
  );
});

export default EventSlotTemplate;
