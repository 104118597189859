import { select, put } from 'redux-saga/effects';
import { calendarActions } from 'framework/actions';
import { apiClientSelectors } from 'framework/selectors';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import * as CONST from 'framework/modules/slotManagement/constants';
import * as CONST_PAGE from '../../pageParts/constants';
import { getDate, getMappedDeliveryAreas } from '../selectors';
// eslint-disable-next-line import/no-cycle
import { ACTIONS } from '..';

function* process() {
  const templateInitialData = yield select(
    apiClientSelectors.selectApiClientData,
    CONST.API_DATA_SLOTS_TEMPLATES_EDIT,
  );

  // 1. fetch data into redux -> Calendar
  const events = templateInitialData.slots || [];
  const stores = yield select(getMappedDeliveryAreas);
  const defaultPickByTime = templateInitialData.defaultPickByTime || null;

  yield put(
    calendarActions.setInitialValues(
      CONST_PAGE.FORM_EDIT_CALENDAR,
      events.map((event) => {
        const deliveryAreas = event.deliveryAreas || [];
        const fulfillmentType = event.fulfillmentType
          ? {
            label: event.fulfillmentType,
            value: event.fulfillmentType,
          }
          : null;

        return {
          id: event.slotId,
          template: 'slotTemplate',
          start: getDate(event.day, event.start),
          end: getDate(event.day, event.end),
          data: {
            ...event,
            fulfillmentType,
            deliveryAreas: deliveryAreas.map((da) => ({
              id: da,
              value: stores[da]?.value ?? da,
            })),
            defaultPickByTime,
          },
        };
      }),
    ),
  );
}

export default function* watchFetchCalendarIniDataAction() {
  yield takeEveryWithProgressBar(ACTIONS.TEMPLATE_FETCH_DATA_CALENDAR, process);
}
