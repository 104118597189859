export const CREATE_URL_PARAM = 'create';
export const STORES_PATH = '/stores';
export const TABS_GROUP_ID = 'store_tabs';
export const TAB_DETAILS = 'details';
export const TAB_ATTRIBUTES = 'store-attributes';
export const TAB_ORDER_FULFILLMENT = 'order-fulfillment';
export const TAB_FULFILLMENT_CENTERS = 'fulfillment-centers';
export const TAB_FULFILLMENT_PROVIDERS = 'fulfillment-providers';
export const FULFILLMENT_CENTER_FORM = 'FULFILLMENT_CENTER_FORM';
export const FULFILLMENT_CENTER_MODAL = 'FULFILLMENT_CENTER_MODAL';
export const DELETE_FULFILLMENT_CENTER_MODAL = 'DELETE_FULFILLMENT_CENTER_MODAL';
export const STORES_FULFILLMENT_CENTER_API_DATA = 'STORES_FULFILLMENT_CENTER_API_DATA';
export const STORES_FULFILLMENT_PROVIDER = 'STORES_FULFILLMENT_PROVIDER';
export const DS_INTEGRATION_API_DATA = 'DS_INTEGRATION_API_DATA';
export const DELIVERY_INTEGRATION_API_DATA = 'DELIVERY_INTEGRATION_API_DATA';
export const CRUD_RESOURCE_ACTION = 'StoresOrderFulfillmentCrudResource';
export const TAB_DELIVERY_AREA = 'delivery-area';
export const DS_INTEGRATION_STATUS = 'DS_INTEGRATION_STATUS';
export const DELIVERY_INTEGRATION_STATUS = 'DELIVERY_INTEGRATION_STATUS';
export const TAB_COUPONS = 'coupons';
export const ZETES = 'ZETES';