import React from 'react';
import i18n from 'i18n';
import { isValidFormat } from 'framework/components/hoc/formField/validators';
import { useModal } from 'framework/components/ui/Modal';
import { view } from '@risingstack/react-easy-state';
import { Modal, Form, TextField } from 'ui';
import { useTabsNavigation } from '../TabsNavigation';
import { FILEMANAGER_DUPLICATE_FILE_NAME_MODAL, FILEMANAGER_FILE_RENAME_MODAL } from './constants';

const clxDamTranslation = 'modules.contentmanagement.dam';

type FileManagerFileDuplicateModalProps = {
  handleOnUpload: (overwrite?: boolean, newFileName?: string) => void,
  uploadableFiles: File[];
  cancelHandler: () => void;
};

export const FileManagerFileDuplicateModal = view(({
  handleOnUpload, uploadableFiles, cancelHandler,
}: FileManagerFileDuplicateModalProps) => {
  const [overwritename, setOverwritename] = React.useState<string>();
  const { addNavigationCondition } = useTabsNavigation();

  const {
    openModal: openRenameFileModal,
    closeModal: closeRenameFileModal,
  } = useModal(FILEMANAGER_FILE_RENAME_MODAL);

  const validateFileName = ({ fileName }) => {
    if (fileName === '') {
      return { fileName: 'generic.formsValidation.mandatory' };
    }

    const validateImageName = isValidFormat(/^$|^[a-zA-Z0-9-_]*$\b/, 'generic.formsValidation.invalidImageName');

    if (validateImageName(fileName)) {
      return { fileName: `${clxDamTranslation}.invalidFileName` };
    }

    return null;
  };

  return (
    <Modal
      name={FILEMANAGER_DUPLICATE_FILE_NAME_MODAL}
      caption={`${clxDamTranslation}.fileDuplicateModalCaption`}
      messages={[
        {
          type: 'warning',
          showLabel: true,
          caption: i18n.t(`${clxDamTranslation}.fileDuplicateModalWarning`, { fileName: uploadableFiles[0]?.name }),
        },
      ]}
      footerOptions={[
        {
          caption: `${clxDamTranslation}.fileDuplicateReplaceButton`,
          onClick: () => {
            closeRenameFileModal();
            cancelHandler();
            handleOnUpload(true);
            addNavigationCondition({
              key: 'page-content',
              value: false,
            });
          },
          outline: true,
          testId: 'replaceFileOption',
        },
        {
          caption: `${clxDamTranslation}.fileDuplicateRenameFile`,
          onClick: openRenameFileModal,
          outline: true,
          testId: 'renameFileOption',
        },
        {
          caption: 'generic.button.cancel',
          onClick: cancelHandler,
          link: true,
          testId: 'uploadCancel',
        },
      ]}
    >
      <Modal
        name={FILEMANAGER_FILE_RENAME_MODAL}
        caption={`${clxDamTranslation}.fileDuplicateRenameFile`}
        footerOptions={[
          {
            caption: `${clxDamTranslation}.fileDuplicateRenameFile`,
            formToSubmit: 'fileNameOverwrite',
            testId: 'renameFile',
          },
          {
            caption: 'generic.button.cancel',
            onClick: closeRenameFileModal,
            outline: true,
            testId: 'renameCancel',
          },
        ]}
      >
        <div className={`${clxDamTranslation}__overwrite_modal`}>
          <Form
            name="fileNameOverwrite"
            validate={validateFileName}
            onSubmit={() => {
              closeRenameFileModal();
              cancelHandler();
              setOverwritename('');
              handleOnUpload(false, `${overwritename}.${/(?:\.([^.]+))?$/.exec(uploadableFiles[0]?.name)[1]}`);
            }}
          >
            <TextField
              name="fileName"
              caption={`${clxDamTranslation}.fileRenameFileName`}
              explainerText={`${clxDamTranslation}.FileRenameFileNameHint`}
              onChange={(e) => setOverwritename(e.target.value)}
              mandatory
            />
          </Form>
        </div>
      </Modal>
    </Modal>
  );
});
