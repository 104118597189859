import * as actions from 'framework/api/apiClient/actions';

export const API_MAKE_REQUEST = 'API_MAKE_REQUEST';

export const apiMakeRequest = payload => ({
  type: API_MAKE_REQUEST,
  payload
});

export const deleteApiData = actions.clearApiClientStateAction;
