import { takeEvery, put } from 'redux-saga/effects';
import { setSubmitFailed, stopSubmit, touch } from 'redux-form';
import { SET_FORM_ERRORS } from '../Form.actions';

export function* process({ payload }) {
  const form = payload.name;
  const fields = Object.keys(payload.errors || {});

  yield put(touch(form, ...fields));
  yield put(stopSubmit(form, { ...(payload.errors || {}) }));
  yield put(setSubmitFailed(form, ...fields));
}

export default function* watchSetFormErrorsAction() {
  yield takeEvery(SET_FORM_ERRORS, process.bind(null));
}
