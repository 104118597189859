import Select from './Select';
import { ISelectProps, ISelectOptions } from './interfaces';

export default Select;
export { Select, ISelectProps, ISelectOptions };

export function getSelectValue<T>(val: (ISelectOptions<T> | T)): T {
  return (
    val && Object.prototype.hasOwnProperty.call(val, 'value') ? (val as ISelectOptions<T>).value : val as T
  );
}

export function getSelectValues<T = string>(values: (ISelectOptions<T> | T)[]) {
  return (values || []).map(getSelectValue);
}
