export const cmsUrls = {
  pages: {
    allPagesUrl: '/allpages',
    pageDocumentEditUrl: '/pages/edit/:documentId/variants/:variantId',
    pageDocumentCreateUrl: '/pages/createpage',
  },
  emails: {
    allEmailsUrl: '/allemails',
    emailDocumentEditUrl: '/emails/edit/:documentId/variants/:variantId',
    emailDocumentCreateUrl: '/emails/createemail',
  },
  terms: {
    allTermsUrl: '/allterms',
    termsDictionaryEditUrl: '/terms/edit/:documentId/variants/:variantId',
    termsDictionaryCreateUrl: '/terms/createterm',
  },
  notifications: {
    all: '/notifications',
    edit: '/notifications/:documentId/:variantId',
    create: '/notifications/create',
  },
  printeddocuments: {
    all: '/printeddocuments',
    edit: '/printeddocuments/edit/:documentId/variants/:variantId',
    create: '/printeddocuments/create'
  },
};
