import { OidcProfile } from 'framework/components/globalhooks';

export interface CommonPageDetailProps {
  user?: any;
  currentUserProfile?: OidcProfile;
  editFlow?: boolean;
  workInProgress?: boolean;
}

export enum commonPageStatus {
  Inactive = 'Inactive',
  Active = 'Active'
}

export interface CommonPageDetailActions {
  saveUser?: ({ formValues }) => void;
}
