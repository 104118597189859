import { baseApiUrl, getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { ensureClaims, AuthorizationClaims, UserAccessRights } from 'framework/authorization';
import { FeatureState } from './types';

export const isWebhooksEnabled = async (): Promise<FeatureState> => {
  const userHasAccess = ensureClaims({
    [AuthorizationClaims.SITESETTINGS_WEBHOOKS]: UserAccessRights.Read,
  });

  if (!userHasAccess) {
    return {
      isEnabled: false,
    };
  }
  
  if (!baseApiUrl) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData<{ isEnabled: boolean }>({
    url: getApiUrl('/webhookscustomers'),
  });

  return {
    isEnabled: result.success && result?.data?.isEnabled || false,
  };
};
