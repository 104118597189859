import * as React from 'react';
import { AppCrumbs, LayoutFlexBoxItem } from 'ui';
import { AppRouteBase } from 'framework/components/navigation/Navigation.interfaces';
import * as conf from 'framework/components/ui/_conf';

const BaseClassName = conf.prefixClassName('page-crumbs');

export const PageCrumbs = ({ appRoute }: { appRoute: AppRouteBase }) => (
  <LayoutFlexBoxItem className={BaseClassName} flexGrow={1} flexBasis="50%">
    <AppCrumbs appRoute={appRoute} />
  </LayoutFlexBoxItem>
);
