import * as React from 'react';
import { Modal } from 'ui';
import { BaseProps } from './ModalPreventNavigation.interfaces';

export const BaseModalPreventNavigation = (props: BaseProps) => {
  const {
    title = 'components.modalPreventNavigation.title',
    warningMessage = 'components.modalPreventNavigation.warningMessage',
    btnAccept = 'components.modalPreventNavigation.btnAccept',
    btnCancel = 'components.modalPreventNavigation.btnCancel',
    modalName,
    onCancelNavigation,
    onAcceptNavigation,
    onDismiss,
  } = props;

  return (
    <Modal
      name={modalName}
      size="small"
      caption={title}
      messages={[
        {
          type: 'warning',
          caption: warningMessage,
        },
      ]}
      onDismiss={onDismiss}
      footerOptions={[
        {
          onClick: onAcceptNavigation,
          outline: true,
          caption: btnAccept,
        },
        {
          onClick: onCancelNavigation,
          outline: true,
          caption: btnCancel,
        },
      ]}
    >
      <div className="mrg-btm-60" />
    </Modal>
  );
};
