enum MerchandisingTranslations {
  Merchandising = 'modules.merchandisingModule.title',
  Recommendations = 'modules.merchandisingModule.recommendations.title',
  RecommendationList = 'modules.merchandisingModule.recommendations.list.title',
  RecommendationMenu = 'modules.merchandisingModule.recommendations.list.menu',
  RecommendationCreate = 'modules.merchandisingModule.recommendations.create.title',
  RecommendationEdit = 'modules.merchandisingModule.recommendations.edit.title',
  GlobalListingsAndRecommendations = 'modules.siteadmin.claims.globalRecommendationsAndListings',

  RecipesTitle = 'modules.merchandisingModule.recipes.title',
  RecipesList = 'modules.merchandisingModule.recipes.list.title',
  RecipesMenu = 'modules.merchandisingModule.recipes.list.menu',
  RecipesCreate = 'modules.merchandisingModule.recipes.create.title',
  RecipesEdit = 'modules.merchandisingModule.recipes.edit.title',
  GlobalRecipes = 'modules.siteadmin.claims.globalRecipes',

  Actions = 'modules.merchandisingModule.recommendations.list.actions',
  WithParameters = 'modules.merchandisingModule.listings.edit.withParameters',
  AddParameter = 'modules.merchandisingModule.listings.edit.addParameter',
  EmptyParameters = 'modules.merchandisingModule.listings.edit.emptyParameters',
  SelectAttribute='modules.merchandisingModule.listings.edit.selectAttribute',
  ReferenceName = 'modules.merchandisingModule.listings.edit.referenceName',
  AddAttribute = 'modules.merchandisingModule.listings.edit.addAttribute',
  DeleteConfirmation = 'modules.merchandisingModule.recommendations.list.deleteConfirmation',
  DeleteConfirmationWarning = 'modules.merchandisingModule.recommendations.list.deleteConfirmationWarning',
  DeleteSuccessfully = 'modules.merchandisingModule.recommendations.list.deleteSuccessfully',
  DeleteError = 'modules.merchandisingModule.recommendations.list.deleteError',
  StatusUpdateSuccessfully = 'modules.merchandisingModule.recommendations.list.updateSuccessfully',
  StatusUpdateError = 'modules.merchandisingModule.recommendations.list.updateError',
  ConfirmButton = 'modules.merchandisingModule.recommendations.list.confirmButton',
  CancelButton = 'modules.merchandisingModule.recommendations.list.cancelButton',
}

enum Recommendation {
  FilterByKeyword = 'modules.merchandisingModule.recommendations.list.filterByKeyword',
  FilterByStatus = 'modules.merchandisingModule.recommendations.list.filterByStatus',
  FilterByStore = 'modules.merchandisingModule.recommendations.list.filterByStore',
  FilterByLocation = 'modules.merchandisingModule.recommendations.list.filterByLocation',
  Information = 'modules.merchandisingModule.recommendations.edit.information',
  Details = 'modules.merchandisingModule.recommendations.edit.details',
  Name = 'modules.merchandisingModule.recommendations.edit.name',
  NameDescription = 'modules.merchandisingModule.recommendations.edit.nameDescription',
  Headline = 'modules.merchandisingModule.recommendations.edit.headline',
  HeadlineDescription = 'modules.merchandisingModule.recommendations.edit.headlineDescription',
  Locations = 'modules.merchandisingModule.recommendations.edit.locations',
  LocationsContainer = 'modules.merchandisingModule.recommendations.list.locationContainer',
  LocationsHint01 = 'modules.merchandisingModule.recommendations.edit.locationsHint_01',
  LocationsCount = 'modules.merchandisingModule.recommendations.edit.locationsCount',
  LocationsAssigned = 'modules.merchandisingModule.recommendations.edit.locationsAssigned',
  LocationsDescriptions = 'modules.merchandisingModule.recommendations.edit.locationsDescription',
  DateIni = 'modules.merchandisingModule.recommendations.edit.dateIni',
  DateEnd = 'modules.merchandisingModule.recommendations.edit.dateEnd',
  DateEndDescription = 'modules.merchandisingModule.recommendations.edit.dateEndDescription',
  Priority = 'modules.merchandisingModule.recommendations.edit.priority',
  PriorityDescription = 'modules.merchandisingModule.recommendations.edit.priorityDescription',
  StoresAssigned = 'modules.merchandisingModule.recommendations.edit.storesAssigned',
  Stores = 'modules.merchandisingModule.recommendations.edit.stores',
  StoresCount = 'modules.merchandisingModule.recommendations.edit.storesCount',
  StoresHint01 = 'modules.merchandisingModule.recommendations.edit.storesHint_01',
  StoresHint02 = 'modules.merchandisingModule.recommendations.edit.storesHint_02',
  StoresHint03 = 'modules.merchandisingModule.recommendations.edit.storesHint_03',
  Status = 'modules.merchandisingModule.recommendations.edit.status',
  SaveAndContinue = 'modules.merchandisingModule.recommendations.edit.saveAndContinue',
  Cancel = 'generic.options.cancel',
  Rule = 'modules.merchandisingModule.recommendations.edit.rule',
  Conditions = 'modules.merchandisingModule.recommendations.edit.conditions',
  ConditionsAdd = 'modules.merchandisingModule.recommendations.edit.conditionsAdd',
  ConditionsAnd = 'modules.merchandisingModule.recommendations.edit.conditionsAnd',
  ConditionsDescription = 'modules.merchandisingModule.recommendations.edit.conditionsDescription',
  ConditionStoresIs = 'modules.merchandisingModule.recommendations.edit.conditionStoresIs',
  ConditionLocationIs = 'modules.merchandisingModule.recommendations.edit.conditionLocationIs',
  Actions = 'modules.merchandisingModule.recommendations.edit.actions',
  ActionsAdd = 'modules.merchandisingModule.recommendations.edit.actionsAdd',
  ActionsDescription = 'modules.merchandisingModule.recommendations.edit.actionsDescription',
  ActionDisplay = 'modules.merchandisingModule.recommendations.edit.actionDisplay',
  ActionDisplayAdditional = 'modules.merchandisingModule.recommendations.edit.actionDisplayAdditional',
  ActionProperty = 'modules.merchandisingModule.recommendations.edit.actionProperty',
  ActionPropertyPlaceholder = 'modules.merchandisingModule.recommendations.edit.actionProperty_placeholder',
  ActionRule = 'modules.merchandisingModule.recommendations.edit.actionRule',
  ActionRulePlaceholder = 'modules.merchandisingModule.recommendations.edit.actionRule_placeholder',
  ActionValues = 'modules.merchandisingModule.recommendations.edit.actionValues',
  ActionValuesPlaceholder = 'modules.merchandisingModule.recommendations.edit.actionValues_placeholder',
  Filters = 'modules.merchandisingModule.recommendations.edit.filters',
  FiltersAdd = 'modules.merchandisingModule.recommendations.edit.filtersAdd',
  AddTags = 'modules.merchandisingModule.recommendations.edit.addTags',
  WithTags = 'modules.merchandisingModule.recommendations.edit.withTags',
  FiltersFilterBy = 'modules.merchandisingModule.recommendations.edit.filtersFilterBy',
  ProductsToDisplay = 'modules.merchandisingModule.recommendations.edit.productsToDisplay',
  ProductsToDisplayDescription = 'modules.merchandisingModule.recommendations.edit.productsToDisplayDescription',
  SortBy = 'modules.merchandisingModule.recommendations.edit.sortBy',
  SortByPlaceholder = 'modules.merchandisingModule.recommendations.edit.sortByPlaceholder',
  SortByDescription = 'modules.merchandisingModule.recommendations.edit.sortByDescription',
  RuleFormatted = 'modules.merchandisingModule.recommendations.edit.ruleFormatted',
  SaveSuccess = 'modules.merchandisingModule.recommendations.saveSuccess',
  SaveError = 'modules.merchandisingModule.recommendations.saveError',
  Randomize = 'modules.merchandisingModule.recommendations.edit.randomize',
  RandomizeDescription = 'modules.merchandisingModule.recommendations.edit.randomizeDescription',
}

enum Recipes {
  // Grid page
  FilterByKeyword = 'modules.merchandisingModule.recipes.list.filterByKeyword',
  FilterByStatus = 'modules.merchandisingModule.recipes.list.filterByStatus',
  FilterByStore = 'modules.merchandisingModule.recipes.list.filterByStore',
  FilterByLocal = 'modules.merchandisingModule.recipes.list.filterByLocal',
  Status = 'modules.merchandisingModule.recipes.list.gridStatus',
  Actions = 'modules.merchandisingModule.recipes.list.gridActions',
  ActionUpdateSuccess = 'modules.merchandisingModule.recipes.list.actionUpdateSuccess',
  ActionUpdateError = 'modules.merchandisingModule.recipes.list.actionUpdateError',
  ActionDeleteSuccess = 'modules.merchandisingModule.recipes.list.actionDeleteSuccess',
  ActionDeleteError = 'modules.merchandisingModule.recipes.list.actionDeleteError',
  ActionDeleteConfirmTitle = 'modules.merchandisingModule.recipes.list.actionDeleteConfirmationTitle',
  ActionDeleteConfirmTitleWarning = 'modules.merchandisingModule.recipes.list.deleteWarning',
  ConfirmButton = 'modules.merchandisingModule.recipes.list.confirmButton',
  CancelButton = 'modules.merchandisingModule.recipes.list.cancelButton',
  /* -------------- */
  /* - Information -*/
  /* -------------- */
  Information = 'modules.merchandisingModule.recipes.edit.information.title',
  InformationSave = 'modules.merchandisingModule.recipes.edit.information.btnSave',
  InformationSaveSuccess = 'modules.merchandisingModule.recipes.edit.information.messageSuccess',
  InformationSaveError = 'modules.merchandisingModule.recipes.edit.information.messageError',
  RecipeUniqueIdError = 'modules.merchandisingModule.recipes.edit.recipeUniqueIdError',
  RecipeUniqueIdUpdateError = 'modules.merchandisingModule.recipes.edit.recipeUniqueIdUpdateError',
  RecipeId = 'modules.merchandisingModule.recipes.edit.recipeId',
  RecipeIdDesc = 'modules.merchandisingModule.recipes.edit.recipeIdDesc',
  Name = 'modules.merchandisingModule.recipes.edit.name',
  NameDescription = 'modules.merchandisingModule.recipes.edit.nameDesc',
  Details = 'modules.merchandisingModule.recipes.edit.details',
  Description = 'modules.merchandisingModule.recipes.edit.description',
  DescriptionExplainer = 'modules.merchandisingModule.recipes.edit.descriptionDesc',
  Author = 'modules.merchandisingModule.recipes.edit.author',
  AuthorExplainer = 'modules.merchandisingModule.recipes.edit.authorDesc',
  PrepTime = 'modules.merchandisingModule.recipes.edit.prepTime',
  PrepTimeLabel = 'modules.merchandisingModule.recipes.edit.prepTimeLabel',
  PrepTimeExplainer = 'modules.merchandisingModule.recipes.edit.prepTimeDesc',
  CookTime = 'modules.merchandisingModule.recipes.edit.cookTime',
  CookTimeLabel = 'modules.merchandisingModule.recipes.edit.cookTimeLabel',
  CookTimeExplainer = 'modules.merchandisingModule.recipes.edit.cookTimeDesc',
  Servings = 'modules.merchandisingModule.recipes.edit.servings',
  ServingsExplainer = 'modules.merchandisingModule.recipes.edit.servingsDesc',
  Calories = 'modules.merchandisingModule.recipes.edit.calories',
  CaloriesExplainer = 'modules.merchandisingModule.recipes.edit.caloriesDesc',
  Category = 'modules.merchandisingModule.recipes.edit.category',
  CategoryCount = 'modules.merchandisingModule.recipes.edit.categoryCount',
  CategoryDescription = 'modules.merchandisingModule.recipes.edit.categoryDescriptionDesc',
  NutritionTypes = 'modules.merchandisingModule.recipes.edit.nutritionTypes',
  NutritionTypesCount = 'modules.merchandisingModule.recipes.edit.nutritionTypesCount',
  NutritionTypesExplainer = 'modules.merchandisingModule.recipes.edit.nutritionDesc',
  DateIni = 'modules.merchandisingModule.recipes.edit.dateIni',
  DateEnd = 'modules.merchandisingModule.recipes.edit.dateEnd',
  DateExplainer = 'modules.merchandisingModule.recipes.edit.dateDesc',
  Priority = 'modules.merchandisingModule.recipes.edit.priority',
  PriorityExplainer = 'modules.merchandisingModule.recipes.edit.priorityDesc',
  // Content
  Content = 'modules.merchandisingModule.recipes.edit.content.title',
  ContentHint = 'modules.merchandisingModule.recipes.edit.content.hint01',
  VideoUrl = 'modules.merchandisingModule.recipes.edit.content.videoUrl',
  VideoUrlExplainer = 'modules.merchandisingModule.recipes.edit.content.videoDesc',
  ImageGallery = 'modules.merchandisingModule.recipes.edit.content.imgGallery',
  ImageGalleryDesc = 'modules.merchandisingModule.recipes.edit.content.imgGalleryDesc',
  ImageFeature = 'modules.merchandisingModule.recipes.edit.content.imgFeature',
  ImageFeatureDesc = 'modules.merchandisingModule.recipes.edit.content.imgFeatureDesc',
  ImageBadge = 'modules.merchandisingModule.recipes.edit.content.imgBadge',
  ImageBadgeDesc = 'modules.merchandisingModule.recipes.edit.content.imgBadgeDesc',
  ImageDesktop = 'modules.merchandisingModule.recipes.edit.content.imgDesktop',
  ImageMobile = 'modules.merchandisingModule.recipes.edit.content.imgMobile',
  ImgNameAllDevices = 'modules.merchandisingModule.recipes.edit.content.imgNameAllDevices',
  ImgNameAllMobile = 'modules.merchandisingModule.recipes.edit.content.imgNameAllMobile',
  ImgNameAllBigDevices = 'modules.merchandisingModule.recipes.edit.content.imgNameAllBigDevices',
  // Directions
  Directions = 'modules.merchandisingModule.recipes.edit.directions',
  // Ingredients
  Ingredients = 'modules.merchandisingModule.recipes.edit.ingredients.title',
  IngredientsAdd = 'modules.merchandisingModule.recipes.edit.ingredients.btnAdd',
  IngredientsHint01 = 'modules.merchandisingModule.recipes.edit.ingredients.hint01',
  IngredientsHint02 = 'modules.merchandisingModule.recipes.edit.ingredients.hint02',
  IngredientsHint03 = 'modules.merchandisingModule.recipes.edit.ingredients.hint03',
  IngredientsHint04 = 'modules.merchandisingModule.recipes.edit.ingredients.hint04',
  IngredientsName = 'modules.merchandisingModule.recipes.edit.ingredients.name',
  IngredientsNamePlaceholder = 'modules.merchandisingModule.recipes.edit.ingredients.namePlaceholder',
  IngredientsSize = 'modules.merchandisingModule.recipes.edit.ingredients.size',
  IngredientsTerm = 'modules.merchandisingModule.recipes.edit.ingredients.term',
  IngredientsSkus = 'modules.merchandisingModule.recipes.edit.ingredients.skus',
  IngredientsSkusPlaceholder = 'modules.merchandisingModule.recipes.edit.ingredients.skusPlaceholder',
  // Nutritional Information
  NutritionalInfo = 'modules.merchandisingModule.recipes.edit.nutritionalInfo',
  // Stores
  Sites = 'modules.merchandisingModule.recipes.edit.sites',
  Stores = 'modules.merchandisingModule.recipes.edit.stores',
  StoresCount = 'modules.merchandisingModule.recommendations.edit.storesCount',
  StoresSummary = 'modules.merchandisingModule.recipes.edit.storesSummary',
  StoresHint01 = 'modules.merchandisingModule.recipes.edit.storesHint01',
  StoresHint02 = 'modules.merchandisingModule.recipes.edit.storesHint02',
  StoresHint03 = 'modules.merchandisingModule.recipes.edit.storesHint03',
  /* ------------ */
  /* ---- SEO ----*/
  /* -------------*/
  SEO = 'modules.merchandisingModule.recipes.edit.seo.name',
  SEOTitle = 'modules.merchandisingModule.recipes.edit.seo.title',
  SEOMessage = 'modules.merchandisingModule.recipes.edit.seo.hint',
  SEOSave = 'modules.merchandisingModule.recipes.edit.seo.btnSave',
  SEOSaveSuccess = 'modules.merchandisingModule.recipes.edit.seo.messageSuccess',
  SEOSaveError = 'modules.merchandisingModule.recipes.edit.seo.messageError',
  MetaTitle = 'modules.merchandisingModule.recipes.edit.seo.metaTitle',
  MetaTitleExplainer = 'modules.merchandisingModule.recipes.edit.seo.metaTitleDesc',
  MetaDescription = 'modules.merchandisingModule.recipes.edit.seo.metaDescription',
  MetaDescriptionExplainer = 'modules.merchandisingModule.recipes.edit.seo.metaDescriptionDesc',
  MetaKeywords = 'modules.merchandisingModule.recipes.edit.seo.metaKeyword',
  MetaKeywordsPlaceholder = 'modules.merchandisingModule.recipes.edit.seo.metaKeywordPlaceholder',
  MetaKeywordsExplainer = 'modules.merchandisingModule.recipes.edit.seo.metaKeywordDesc',
  MetaTags = 'modules.merchandisingModule.recipes.edit.seo.metaTags',
  MetaTagsAdd = 'modules.merchandisingModule.recipes.edit.seo.metaTagsBtnAdd',
  MetaTagsName = 'modules.merchandisingModule.recipes.edit.seo.metaTagsName',
  MetaTagsContent = 'modules.merchandisingModule.recipes.edit.seo.metaTagsContent',
  FeaturedMobileImage = 'modules.merchandisingModule.recipes.featuredMobileImage',
}

const ListingTranslations = {
  ...Recommendation,
  Listings: 'modules.merchandisingModule.listings.listings',
  AllListings: 'modules.merchandisingModule.listings.allListings',
  CreateListing: 'modules.merchandisingModule.listings.createListing',
  EditListing: 'modules.merchandisingModule.listings.editListing',
  AssociatedListingContainer: 'modules.merchandisingModule.listings.associatedListingContainer',
  AssociatedContainer: 'modules.merchandisingModule.listings.associatedListingContainer',
  DeleteConfirmation: 'modules.merchandisingModule.listings.deleteConfirmation',
  DeleteConfirmationWarning: 'modules.merchandisingModule.listings.deleteConfirmationWarning',
  ConfirmButton: 'modules.merchandisingModule.listings.confirmButton',
  CancelButton: 'modules.merchandisingModule.listings.cancelButton',
  DeleteSuccessfully: 'modules.merchandisingModule.listings.deleteSuccessfully',
  DeleteError: 'modules.merchandisingModule.listings.deleteError',
  StatusUpdateSuccessfully: 'modules.merchandisingModule.listings.updateSuccessfully',
  StatusUpdateError: 'modules.merchandisingModule.listings.updateError',
  // FilterByKeyword: 'modules.merchandisingModule.listings.list.filterByKeyword',
  // FilterByStatus: 'modules.merchandisingModule.listings.list.filterByStatus',
  // FilterByStore: 'modules.merchandisingModule.listings.list.filterByStore',
  // FilterByLocation: 'modules.merchandisingModule.listings.list.filterByLocation',
  Information: 'modules.merchandisingModule.listings.edit.information',
  Details: 'modules.merchandisingModule.listings.edit.details',
  Name: 'modules.merchandisingModule.listings.edit.name',
  NameDescription: 'modules.merchandisingModule.listings.edit.nameDescription',
  Headline: 'modules.merchandisingModule.listings.edit.headline',
  HeadlineDescription: 'modules.merchandisingModule.listings.edit.headlineDescription',
  Site: 'modules.merchandisingModule.listings.edit.site',
  SiteDescription: 'modules.merchandisingModule.listings.edit.siteDescription',
  Locations: 'modules.merchandisingModule.listings.edit.locations',
  LocationsContainer: 'modules.merchandisingModule.listings.list.locationContainer',
  LocationsHint01: 'modules.merchandisingModule.listings.edit.locationsHint_01',
  LocationsCount: 'modules.merchandisingModule.listings.edit.locationsCount',
  LocationsAssigned: 'modules.merchandisingModule.listings.edit.locationsAssigned',
  LocationsDescriptions: 'modules.merchandisingModule.listings.edit.locationsDescription',
  DateIni: 'modules.merchandisingModule.listings.edit.dateIni',
  DateEnd: 'modules.merchandisingModule.listings.edit.dateEnd',
  DateEndDescription: 'modules.merchandisingModule.listings.edit.dateEndDescription',
  Priority: 'modules.merchandisingModule.listings.edit.priority',
  PriorityDescription: 'modules.merchandisingModule.listings.edit.priorityDescription',
  StoresAssigned: 'modules.merchandisingModule.listings.edit.storesAssigned',
  Stores: 'modules.merchandisingModule.listings.edit.stores',
  StoresCount: 'modules.merchandisingModule.listings.edit.storesCount',
  StoresHint01: 'modules.merchandisingModule.listings.edit.storesHint_01',
  StoresHint02: 'modules.merchandisingModule.listings.edit.storesHint_02',
  StoresHint03: 'modules.merchandisingModule.listings.edit.storesHint_03',
  Status: 'modules.merchandisingModule.listings.edit.status',
  SaveAndContinue: 'modules.merchandisingModule.listings.edit.saveAndContinue',
  Rule: 'modules.merchandisingModule.listings.edit.rule',
  Conditions: 'modules.merchandisingModule.listings.edit.conditions',
  ConditionsAdd: 'modules.merchandisingModule.listings.edit.conditionsAdd',
  ConditionsAnd: 'modules.merchandisingModule.listings.edit.conditionsAnd',
  ConditionsDescription: 'modules.merchandisingModule.listings.edit.conditionsDescription',
  ConditionStoresIs: 'modules.merchandisingModule.listings.edit.conditionStoresIs',
  ConditionLocationIs: 'modules.merchandisingModule.listings.edit.conditionLocationIs',
  Actions: 'modules.merchandisingModule.listings.edit.actions',
  ActionsAdd: 'modules.merchandisingModule.listings.edit.actionsAdd',
  ActionsDescription: 'modules.merchandisingModule.listings.edit.actionsDescription',
  ActionDisplay: 'modules.merchandisingModule.listings.edit.actionDisplay',
  ActionDisplayAdditional: 'modules.merchandisingModule.listings.edit.actionDisplayAdditional',
  ActionProperty: 'modules.merchandisingModule.listings.edit.actionProperty',
  ActionProperty_placeholder: 'modules.merchandisingModule.listings.edit.actionProperty_placeholder',
  ActionRule: 'modules.merchandisingModule.listings.edit.actionRule',
  ActionRule_placeholder: 'modules.merchandisingModule.listings.edit.actionRule_placeholder',
  ActionValues: 'modules.merchandisingModule.listings.edit.actionValues',
  ActionValues_placeholder: 'modules.merchandisingModule.listings.edit.actionValues_placeholder',
  Filters: 'modules.merchandisingModule.listings.edit.filters',
  FiltersAdd: 'modules.merchandisingModule.listings.edit.filtersAdd',
  FiltersFilterBy: 'modules.merchandisingModule.listings.edit.filtersFilterBy',
  ProductsToDisplay: 'modules.merchandisingModule.listings.edit.productsToDisplay',
  ProductsToDisplayDescription: 'modules.merchandisingModule.listings.edit.productsToDisplayDescription',
  SelectProductBy: 'modules.merchandisingModule.listings.edit.selectProductsBy',
  SortBy: 'modules.merchandisingModule.listings.edit.sortBy',
  SortBy_placeholder: 'modules.merchandisingModule.listings.edit.sortBy_placeholder',
  SortByDescription: 'modules.merchandisingModule.listings.edit.sortByDescription',
  RuleFormatted: 'modules.merchandisingModule.listings.edit.ruleFormatted',
  SaveError: 'modules.merchandisingModule.listings.edit.saveError',
};

export {
  MerchandisingTranslations,
  Recommendation,
  Recipes,
  ListingTranslations,
};
export default MerchandisingTranslations;
