import React from 'react';
import { i18n } from 'i18n';
import { ensureClaims } from 'framework/authorization';
import type { GridAction } from 'types';
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import { ISelectOptions } from 'framework/components/ui/FormComponents/Select';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ButtonSubmit, Select, Form } from 'ui';
import { OrgHierarchy } from '../../models/orghierarchy';

import './_styles.scss';

const clx = prefixClassName('bulk-actions');

const BULK_ACTIONS_FORM_STORES = 'BULK_ACTIONS_FORM_STORES';

interface BulkActionsForm {
  bulkAction: ISelectOptions;
}

export const BulkActions = (
  {
    actions,
    selectedStores = [],
    searchable,
  }: { actions: GridAction<{}>[], selectedStores: OrgHierarchy[], searchable?: boolean },
) => {
  const { reset, getValues } = useForm(BULK_ACTIONS_FORM_STORES);
  const localSelectedStores = selectedStores?.filter((e) => e.value.retailerStoreId);

  const options: ISelectOptions[] = React.useMemo(() => {
    if (actions && actions.length > 0 && localSelectedStores?.length > 0) {
      const { bulkAction } = getValues<BulkActionsForm>();

      const availableActions = actions.filter((a) => a.bulkAction
        && ensureClaims(a.authorize)
        && !localSelectedStores?.some((i) => a.config(i).disabled || a.config(i).hidden)).map((a) => ({
        label: i18n.t(a.config(localSelectedStores[0])?.label),
        value: a.id,
      }));

      // if there is a bulk action selected, but it is not available, resets the form
      if (bulkAction?.value) {
        const selectedAction = availableActions.find((a) => a.value === bulkAction.value);
        if (!selectedAction) {
          setTimeout(reset, 50);
        }
      }
      return availableActions;
    }
    // resets the form if no options are available
    setTimeout(reset, 50);
    return [];
  }, [actions]);

  const onSubmitBulkAction = ({ bulkAction }: BulkActionsForm) => {
    if (bulkAction?.value) {
      const action = actions.find((a) => a.id === bulkAction.value);

      action.bulkAction(localSelectedStores);
    }
  };


  const disabled = !options.length || localSelectedStores?.length < 1;
  return (
    <Form 
      name={BULK_ACTIONS_FORM_STORES}
      onSubmit={onSubmitBulkAction} 
      className={disabled ? `${clx}--disabled` : `${clx}--enabled`}>
      <Select
        className={`${clx}__select`}
        placeholder="generic.selectActionDefault"
        name="bulkAction"
        options={options}
        disabled={disabled}
        labelInline
        searchable={searchable}
      />
      <ButtonSubmit
        className={`${clx}__confirm`}
        caption="generic.button.confirm"
        formToSubmit={BULK_ACTIONS_FORM_STORES}
        disabled={disabled}
        outline
      />
    </Form>
  );
};
