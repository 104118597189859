import * as React from 'react';
import * as classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ButtonList } from 'framework/components/ui';
import { IStickyBar } from './StickBar.interfaces';
import './_style.scss';

const clx = prefixClassName('sticky-bar');

export default (props: IStickyBar) => {
  const { children, options, className } = props;

  return (
    <div className={classnames(clx, className)}>
      {children} {options && <ButtonList buttons={options} />}
    </div>
  );
};
