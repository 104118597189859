import * as React from 'react';
import classnames from 'classnames';
import { withAuthorize } from 'framework/authorization/withAuthorize';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Icon, Text } from 'ui';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { i18n } from 'i18n';
import { IButtonProps } from './Button.interface';
import './_style.scss';

const clx = prefixClassName('btn');

export class ButtonComp extends React.Component<IButtonProps> {
  static defaultProps: Partial<IButtonProps> = {
    block: false,
    disabled: false,
    propagate: false,
    outline: false,
    styleless: false,
    iconPosition: 'right',
    type: 'button',
    show: true,
  };

  onClickHandler = (e: any) => {
    const { onClick, propagate, link, caption, form, trackEventProps } = this.props;

    if (link) {
      ApplicationMonitoring.trackEvent(
        trackEventProps?.name || window.location.pathname,
        trackEventProps?.value || 'Button Link',
        trackEventProps?.label || caption || form
      );
    }
    // ONLY if propagate is true, pass the event object to callback
    if (propagate) {
      onClick(e);
    } else {
      onClick();
    }
  };

  onMouseLeaveHandler = (e: any) => {
    const { onMouseLeave, propagate } = this.props;
    // ONLY if propagate is true, pass the event object to callback
    if (propagate) {
      onMouseLeave(e);
    } else {
      onMouseLeave();
    }
  };

  onMouseEnterHandler = (e: any) => {
    const { onMouseEnter, propagate } = this.props;
    // ONLY if propagate is true, pass the event object to callback
    if (propagate) {
      onMouseEnter(e);
    } else {
      onMouseEnter();
    }
  };

  render() {
    const {
      block,
      caption,
      className,
      style,
      color,
      disabled,
      form,
      icon,
      iconPosition,
      iconSize,
      iconColor,
      onClick,
      onMouseLeave,
      onMouseEnter,
      onMouseDown,
      onMouseUp,
      onMouseOut,
      outline,
      show,
      styleless,
      link,
      title,
      type,
      children,
      testId,
      headerPrimary,
      id,
    } = this.props;

    let newTestID = testId;
    if (headerPrimary) {
      newTestID = 'headerPrimary';
    } else if (caption && outline) {
      newTestID = caption;
    }

    const buttonContent =
      children ||
      (iconPosition === 'left' ? (
        <>
          {icon && <Icon name={icon} className={`${clx}__icon`} size={iconSize} color={iconColor} />}
          {caption && <Text caption={caption} />}
        </>
      ) : (
        <>
          {caption && <Text caption={caption} />}
          {icon && <Icon name={icon} className={`${clx}__icon`} size={iconSize} color={iconColor} />}
        </>
      ));
    return (
      show && (
        <button
          className={classnames(
            clx,
            block && `${clx}--block`,
            disabled && `${clx}--disabled`,
            outline && `${clx}--outline`,
            styleless && `${clx}--styleless`,
            link && `${clx}--link`,
            color && `${clx}--${color}`,
            caption ? `${clx}--has-caption` : null,
            icon && [`${clx}--icon`, `${clx}--icon--${icon}`, `${clx}--icon-position-${iconPosition}`],
            className
          )}
          style={style}
          // eslint-disable-next-line react/button-has-type
          type={type}
          title={title}
          form={form}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseOut={onMouseOut}
          onBlur={onMouseOut}
          onClick={onClick && this.onClickHandler}
          onMouseEnter={onMouseEnter && this.onMouseEnterHandler}
          onMouseLeave={onMouseLeave && this.onMouseLeaveHandler}
          disabled={disabled}
          data-testid={testId ?? newTestID}
          id={id}
          aria-label={title || icon || i18n.t('generic.ariaLabel.button')}
        >
          {buttonContent}
        </button>
      )
    );
  }
}

export const Button = withAuthorize(ButtonComp);
export { clx as className };
export default Button;
