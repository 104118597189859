import { ITabGroupProps, ITabProps } from 'framework/components/ui/Tabs/Tabs.interface';

export interface ITabSteps extends ITabGroupProps {
  /** TabSteps name */
  id: string;

  /** array of Steps (see <Tabs /> component) */
  data: ITabStepsTab[];
  sticky?: boolean;
  summary?: React.ReactNode;
  isEditMode?: boolean;
  allowPrevious?: boolean;
  keepAnchors?: boolean;
  onLastTabFinish?: () => void;
}

export interface ITabStepsTab extends ITabProps {
  onTabComplete?: () => void;
}

export interface ITabStepsProps extends ITabSteps {
  tabstepsInfo?: ITabStepsInfo;

  initStore?: (steps: ITabSteps) => void;
  completeCurrentStep?: (id: string) => void;
  clearStore?: (id: string) => void;
  displayCurrentStep?: (tabsId, tabId, keepAnchors) => void;
  removeTabs?: (tabsId) => void;
  updateCurrentStepIndex?: (
    data: {
      tabsId: string;
      stepId: string;
    }
  ) => void;
  define?: (args: any) => void;
}

export interface ITabStepsInfo {
  id: string;
  currentStep: number;
  previousStep?: number;
  steps: ITabStep[];
  openTab?: string;
}
export interface ITabStep {
  id: string;
  index: number;
  isEnabled?: boolean;
  isCompleted?: boolean;
}

export type ITabStepsState = ITabStepsInfo[];

export const INIT_TABSTEPS_STORE = 'INIT_TABSTEPS_STORE';
export const COMPLETE_TABSTEPS_CURRENT = 'COMPLETE_TABSTEPS_CURRENT';
export const GO_TO_TABSTEPS_PREVIOUS = 'GO_TO_TABSTEPS_PREVIOUS';
export const REMOVE_TABSTEPS_INFO = 'REMOVE_TABSTEPS_INFO';
export const UPDATE_TABSTEPS_CURRENTINDEX = 'UPDATE_TABSTEPS_CURRENTINDEX';
