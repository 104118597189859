import * as React from 'react';
import { FieldArray } from 'redux-form';
import withErrorCatcher from 'framework/components/hoc/withErrorCatcher';

interface IDynamicFieldsProps {
  name: string;
  component?: React.ReactNode | React.ComponentType;
}

export const DynamicFields = (props: IDynamicFieldsProps) => (
  <FieldArray {...props as any} />
);

export default withErrorCatcher(DynamicFields);
