const persistedState = {};
let lastStateName;

type Dispatch<A> = (value: A) => void;
type SetStateAction<S> = S | ((prevState: S) => S);
export type UsePageState<S> = {
  pageState: S,
  saveState: Dispatch<SetStateAction<S>>,
  clearState: () => void
};

/**
 *
 * @param stateName
 * @param initialState
 */
function usePageState<S>(stateName: string, initialState?: S): UsePageState<S> {
  persistedState[stateName] = persistedState[stateName] || initialState;

  const saveState = (arg: SetStateAction<S>) => {
    // typescript shows an error for arg(persistedState[stateName]), but it is a ts bug, the code works fine
    const newState = (typeof arg === 'function' ? arg(persistedState[stateName]) : arg) as S;
    persistedState[stateName] = newState;
  };

  const clearState = () => {
    persistedState[stateName] = undefined;
    delete persistedState[stateName];
  };

  return {
    pageState: persistedState[stateName],
    saveState,
    clearState,
  };
}

/**
 * Cleans the last state and returns a new hook bound to the new state name
 * @param newStateName
 */
export const willChangeState = (newStateName?: string) => {
  if (lastStateName !== newStateName) {
    persistedState[lastStateName] = undefined;
    delete persistedState[lastStateName];
    lastStateName = newStateName;
  }

  return <S>(initialState?: S) => usePageState(newStateName, initialState);
};
