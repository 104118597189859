import * as React from 'react';
import i18n from 'i18n';
import { useEffectSkipFirstRun } from 'helpers/hooks';
import {
  Form,
  Header,
  LayoutFlexBox,
  LayoutFlexBoxItem,
  Message,
  Panel,
  TextField,
  DatePicker,
  Select,
  ConfirmationModal,
  StatusDropdown,
  Button,
} from 'ui';
import {
  DEFAULT_STORE_ID,
  DEFAULT_LOCALE_ID,
  API_DATA_RULES_OPTIONS,
  FORM_RULES_CREATE_RULES,
  PATH_MAIN,
  PATH_SEARCH_SETTINGS,
  RULES_CONFLICT_MODAL,
} from 'framework/modules/searchadmin/constants';
import { useModal } from 'framework/components/ui/Modal/useModal';
import ApiDataProvider from 'framework/components/services/ApiDataProvider';
import { prefixClassName } from 'framework/components/ui/_conf';
import { usePageContext } from 'framework/components/ui/Page/PageContext';
import { checkRuleConflicts } from './sagas/fetchRuleConflicts';
import { SearchAdminTranslations } from '../../translations';
// eslint-disable-next-line import/no-cycle
import { ConditionsWidget, ActionsWidget, Preview } from './components';
import { SearchRule } from '../../Rules/models/searchRule';
import { RuleConflictModal } from './components/ConflictModal/RuleConflictModal';
import * as helpers from './helpers';
import './styles.scss';
import { positionsStore } from './components/Actions/ActionsPosition/ActionsPosition';
// eslint-disable-next-line import/no-cycle
import { rulePaginationStore } from '../../components/DataGridDouble/DataGridDouble';
import { useForm } from '../../../../components/ui/FormComponents/Form/useForm';

const clx = prefixClassName('searchAdmin-rules');

export enum PreviewViews {
  CurrentAndPreview = 'CurrentAndPreview',
  PreviewOnly = 'PreviewOnly',
}

export const RuleCreateEditContent = (props: any) => {
  const {
    ruleId,
    previewStoreId,
    existingValues = {},
    isFetching = false,

    // Actions
    saveRule,
    deleteRule,
    fetchRuleData,
    setDefaultStorePreview,
    enablePreviewButton,
  } = props;

  const { getValues, changeValue } = useForm(FORM_RULES_CREATE_RULES);
  const formData: { searchForProducts: string } = getValues();

  const isEditMode = !!ruleId;
  const isDefaultStore = previewStoreId === DEFAULT_STORE_ID;

  const { openModal, closeModal } = useModal(RULES_CONFLICT_MODAL);
  const { updatePageTitle } = usePageContext();

  const [conflictingRule, setConflictingRule] = React.useState<SearchRule[] | null>(null);
  const [view, setView] = React.useState<PreviewViews>(PreviewViews.CurrentAndPreview);

  React.useEffect(() => {
    if (ruleId) {
      fetchRuleData();
    }
  }, [ruleId, fetchRuleData]);

  const getStoresSelected = () => {
    const { storesSelected = [] } = props;
    const defaultStore = [
      { value: DEFAULT_STORE_ID, label: i18n.t('modules.merchandising.rules.create.defaultStore') },
    ];

    // Add Defalut seletable value
    const storesOptions = defaultStore.concat(storesSelected);

    // Remove 'undefined' options
    return storesOptions.filter((store) => !!store);
  };

  const getDefaultStore = () => {
    const defaultStores = helpers.getSelectedStores(existingValues);
    return (defaultStores && defaultStores[0]) || DEFAULT_STORE_ID;
  };

  useEffectSkipFirstRun(() => {
    const storesSelected = getStoresSelected();

    // The list of stores used on preview changes dinamically.
    // - If the selected store on preview does not existe on options, switch to default
    if (previewStoreId !== DEFAULT_STORE_ID) {
      if (!storesSelected.find((op) => op.value === previewStoreId)) {
        setDefaultStorePreview();
      }
    }
  }, [previewStoreId, props.storesSelected]);

  const getInitialValues = () => {
    const initialValues = existingValues;

    initialValues.previewStoreId = getDefaultStore();

    return initialValues;
  };

  const initialValues = getInitialValues();
  const storesSelected = getStoresSelected();

  const handleSubmit = async (e) => {
    if (!e.enabled) {
      saveRule();
    } else {
      const result = await checkRuleConflicts(e);

      const ruleConflictsWithoutCurrent = result?.items?.filter((item) => item.id !== ruleId);

      if (ruleConflictsWithoutCurrent?.length) {
        setConflictingRule(ruleConflictsWithoutCurrent);

        openModal();
      } else {
        saveRule();
      }
    }
  };

  const handleSaveRule = () => {
    saveRule();
    closeModal();
  };

  const handleCancelSave = () => {
    closeModal();

    setConflictingRule(null);
  };

  React.useEffect(() => {
    if (existingValues?.name) {
      updatePageTitle({
        pageTitleDetails: existingValues.name,
      });
    }
  }, [existingValues?.name]);

  return (
    <LayoutFlexBoxItem className={clx}>
      <ApiDataProvider
        endpoint="search"
        endpointPath={`/locale/${DEFAULT_LOCALE_ID}/rules/options`}
        name={API_DATA_RULES_OPTIONS}
      />
      <Form
        name={FORM_RULES_CREATE_RULES}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        stencilled={isFetching}
      >
        <Panel
          title="modules.merchandising.rules.create.panelTitle"
          messages={[
            {
              caption: 'modules.merchandising.rules.create.message_01',
              showLabel: false,
            },
          ]}
        >
          <LayoutFlexBoxItem width="70%" className="form-field-spacing mrg-top-20">
            <TextField
              caption="modules.merchandising.rules.create.description.label"
              explainerText="modules.merchandising.rules.create.description.explainer"
              placeholder="modules.merchandising.rules.create.description.placeholder"
              name="name"
              mandatory
              autocomplete={false}
            />
          </LayoutFlexBoxItem>
          <LayoutFlexBoxItem width="75%" className="form-field-spacing">
            <LayoutFlexBox>
              <LayoutFlexBoxItem flexBasis="35%" className="mrg-right-10">
                <DatePicker
                  rangeEndDate="endTime"
                  caption="modules.merchandising.rules.create.startTime.label"
                  name="startTime"
                  optional
                />
              </LayoutFlexBoxItem>
              <LayoutFlexBoxItem flexBasis="65%">
                <DatePicker
                  rangeStartDate="startTime"
                  caption="modules.merchandising.rules.create.endTime.label"
                  explainerText="modules.merchandising.rules.create.endTime.explainer"
                  name="endTime"
                  optional
                />
              </LayoutFlexBoxItem>
            </LayoutFlexBox>
          </LayoutFlexBoxItem>

          <Message
            caption="modules.merchandising.rules.create.message_02"
            className="stretchToPanelWidth"
            showLabel={false}
          />
          <LayoutFlexBox className="mrg-top-35">
            <LayoutFlexBoxItem className={`${clx}__rulesPanel`} flexGrow={0}>
              <Header
                caption="modules.merchandising.rules.createAndEdit.searchRulesHeading"
                headerLevel={2}
                className="mrg-top-0"
              />
              <ConditionsWidget
                {...props}
                changeFormValue={changeValue}
                searchForProducts={formData.searchForProducts}
              />
              <ActionsWidget {...props} />
            </LayoutFlexBoxItem>
            <LayoutFlexBoxItem flexBasis="auto" flexGrow={1} flexShrink={1}>
              <Button
                caption={
                  view === PreviewViews.CurrentAndPreview
                    ? 'modules.merchandising.searchResults.dragAndDropView'
                    : 'modules.merchandising.searchResults.currentAndPreviewView'
                }
                onClick={() => {
                  setView(
                    view === PreviewViews.CurrentAndPreview ? PreviewViews.PreviewOnly : PreviewViews.CurrentAndPreview
                  );
                }}
                outline
                iconPosition="left"
                icon="formatListBullet"
                iconSize="sm"
                className={`${clx}__view-button`}
              />
              <Header
                caption="modules.merchandising.rules.createAndEdit.searchRulesResultHeading"
                headerLevel={2}
                className="mrg-top-0"
              />
              <Select
                searchable
                name="previewStoreId"
                className="mrg-btm-10"
                caption="modules.merchandising.rules.createAndEdit.previewFilterStores"
                options={storesSelected}
                clearable={false}
                readOnly={storesSelected.length < 2}
                infoMessage={
                  isDefaultStore && {
                    caption: 'modules.merchandising.components.filtersStore.filterStoreInfo',
                    linkText: 'modules.merchandising.components.filtersStore.filterStoreInfoLink',
                    linkTo: `${PATH_MAIN}${PATH_SEARCH_SETTINGS}`,
                  }
                }
              />
              <Preview enablePreview={enablePreviewButton} view={view} />
            </LayoutFlexBoxItem>
          </LayoutFlexBox>

          <Panel.Footer
            options={[
              {
                formToSubmit: FORM_RULES_CREATE_RULES,
                caption: isEditMode
                  ? 'modules.merchandising.rules.createAndEdit.btnUpdateRule'
                  : 'modules.merchandising.rules.createAndEdit.btnCreateRule',
              },
              {
                formToReset: FORM_RULES_CREATE_RULES,
                caption: 'modules.merchandising.rules.createAndEdit.btnDiscard',
                // Reset position and pagination stores
                onClick: () => {
                  positionsStore.newPositions = [];
                  rulePaginationStore?.pagination?.updatePagination({ skip: 0, take: 20 });
                },
              },
              {
                caption: 'modules.merchandising.rules.createAndEdit.btnDelete',
                outline: true,
                onClick: deleteRule,
                show: isEditMode,
              },
            ]}
          >
            <StatusDropdown
              caption={SearchAdminTranslations.Status}
              labelInline
              name="enabled"
              options={[
                {
                  caption: i18n.t(SearchAdminTranslations.Inactive),
                  value: false,
                },
                {
                  caption: i18n.t(SearchAdminTranslations.Active),
                  value: true,
                },
              ]}
            />
          </Panel.Footer>
        </Panel>
      </Form>
      <RuleConflictModal
        conflictingRules={conflictingRule}
        saveRule={handleSaveRule}
        handleCancelSave={handleCancelSave}
      />
      <ConfirmationModal message="modules.merchandising.rules.delete" />
    </LayoutFlexBoxItem>
  );
};

export default RuleCreateEditContent;
