import * as React from 'react';
import * as classNames from 'classnames';
import { LabelPrimitive, ILabelPrimitive } from 'framework/components/ui/FormComponents/Primitives';
import { prefixClassName } from 'framework/components/ui/_conf';

const clx = prefixClassName('form-field-wrapper__lbl');

export const Label = React.memo((props: ILabelPrimitive) => {
  const { className, caption, name } = props;

  return <LabelPrimitive caption={caption} className={classNames(clx, className)} name={name} />;
});

export default Label;
