import { fork } from 'redux-saga/effects';

import fetchSlotCalendarSaga from './fetchSlotCalendarSaga';
import editCalendarSlotSaga from './editCalendarSlotSaga';
import editCalendarSlotCloseSaga from './editCalendarSlotCloseSaga';
import editCalendarBlockerSaga from './editCalendarBlockerSaga';
import saveDataSaga from './saveDataSaga';

export default [
  fork(fetchSlotCalendarSaga),
  fork(editCalendarBlockerSaga),
  fork(editCalendarSlotSaga),
  fork(editCalendarSlotCloseSaga),
  fork(saveDataSaga)
];
