import { useRef } from 'react';
import { useFileManager } from '../FileManager/useFileManager';
import { ExplorerMode } from '../FileManager/types';

const RICHTEXT_FILEMANAGER = 'RICHTEXT_FILEMANAGER';

export const useDamModalRichEditor = () => {
  const { openFileManager, closeFileManager, modalOpen } = useFileManager({
    explorerModalName: RICHTEXT_FILEMANAGER,
  });

  const editorRef: any = useRef();

  const handleOpenDam = (editor) => {
    editorRef.current = editor;
    openFileManager(ExplorerMode.EXPLORE);
  };

  const handleFileUploaded = (file) => {
    const { url } = file;
    if (editorRef.current) {
      editorRef.current.insertContent(`<img src="${url}" />`);
    }
    closeFileManager();
  };

  return {
    modalOpen,
    fileManagerName: RICHTEXT_FILEMANAGER,
    openDam: (editor) => handleOpenDam(editor),
    onFileUploaded: handleFileUploaded,
  };
};
