// ROUTES PATHS
export const PATH_MAIN = '/sitesettings';

/* Relative paths, to use in menu */
export const PATH_IDENTITY_PROVIDERS = '/identityproviders';
export const PATH_IDENTITY_PROVIDERS_CREATE = `${PATH_IDENTITY_PROVIDERS}/create`;
export const PATH_IDENTITY_PROVIDERS_EDIT = `${PATH_IDENTITY_PROVIDERS}/edit`;
export const PATH_DELIVERY_SOLUTIONS = '/deliverysolutions';
export const PATH_STOREFRONT_SETTINGS = '/storefrontsettings';
export const PATH_MOBILE_APP_SETTINGS = '/mobileappsettings';
export const PATH_SHARED_APP_SETTINGS = '/storefrontandmobilesettings';
export const PATH_STOREFRONT_THEMES = '/storefrontthemes';
export const PATH_STOREFRONT_IMAGES = '/storefrontimages';
export const PATH_STS_SETTINGS = '/stsconfiguration';
export const PATH_STS_IMAGES = '/stsimages';
export const PATH_STS_THEME = '/ststheme';
export const PATH_ALL_SEGMENTS = '/segments';
export const PATH_CREATE_SEGMENT = '/segments/create';
export const PATH_EDIT_SEGMENT = '/segments/edit/:segmentId';

/* Absolute paths, to use in buttons and redirections */
export const PATH_IDENTITY_PROVIDERS_CREATE_FULLPATH = `${PATH_MAIN}${PATH_IDENTITY_PROVIDERS_CREATE}`;
export const PATH_IDENTITY_PROVIDERS_EDIT_FULLPATH = `${PATH_MAIN}${PATH_IDENTITY_PROVIDERS_EDIT}`;
export const PATH_STOREFRONT_THEMES_EDIT_FULLPATH = `${PATH_MAIN}${PATH_STOREFRONT_THEMES}`;
export const PATH_STOREFRONT_IMAGES_EDIT_FULLPATH = `${PATH_MAIN}${PATH_STOREFRONT_IMAGES}`;

export const APPLICATION_I18N_PREFIX = 'generic.applications.';
