import { hasSameItems } from 'framework/utils/helpers';
import { OrgHierarchy } from '../../models/orghierarchy';

const compareIds = (store: OrgHierarchy, id: string) => store.id === id;
const compareRetailerStoreIds = (store: OrgHierarchy, storeId: string) => store?.value?.retailerStoreId === storeId;
const compareStoreIds = (store: OrgHierarchy, storeId: string) => store?.value?.storeId === storeId;

export const findStore = (store: OrgHierarchy, id, compareFunc = compareIds): OrgHierarchy => {
  if (compareFunc(store, id)) {
    return store;
  }
  if (store.children) {
    const stores = store.children.map((s) => findStore(s, id, compareFunc)).filter((s) => !!s);
    if (stores.length) {
      return stores[0];
    }
  }
  return null;
};

/**
 * Returns a flat array of Stores
 * @param store parent store
 * @param ids store ids to retrieve
 */
export const getStoresById = (store: OrgHierarchy, ids): OrgHierarchy[] => (
  ids.map((id) => findStore(store, id)).filter((s) => s)
);

/**
 * returns only top select nodes
 * @param root root node
 * @param selectedNodes selected nodes
 */
export const getTopStoreNodes = (root: OrgHierarchy, selectedNodes: OrgHierarchy[]) => {
  if (!selectedNodes.length) {
    return [];
  }
  let stack = [...root.children];
  const topNodes: OrgHierarchy[] = [];

  while (stack.length) {
    const node = stack.pop();
    const isSelected = !!selectedNodes.find((n) => n.id === node.id);
    if (isSelected) {
      topNodes.push(node);
    } else if (Array.isArray(node.children)) {
      stack = [...stack, ...node.children];
    }
  }
  return topNodes;
};

/**
 * Checks if store has storeId
 */
export const hasStoreId = (store: OrgHierarchy) => !!store.value.storeId;

const sortHierarchy = (a: OrgHierarchy, b: OrgHierarchy) => {
  // sort alphabetically
  const term1 = a.value.description?.toLowerCase();
  const term2 = b.value.description?.toLowerCase();
  if (term1 < term2) {
    return -1;
  }
  if (term1 > term2) {
    return 1;
  }
  return 0;
};

/**
 * Sorts all the nested levels of children in the store
 * @param store
 * @returns the store sorted
 */
export const sortStores = (store: OrgHierarchy): OrgHierarchy => {
  const sorted = { ...store };
  const children = sorted.children || [];
  sorted.children = [...children].sort(sortHierarchy);
  sorted.children.forEach(sortStores);

  return sorted;
};

/**
 * Returns a flat array of Stores
 * @param store parent store
 * @param storeIds storeIds to retrieve
 */
export const getStoresFromIds = (store: OrgHierarchy, storeIds, useStoreId?: boolean): OrgHierarchy[] => (
  storeIds ? storeIds.map((id) => findStore(store, id, useStoreId? compareStoreIds: compareRetailerStoreIds)).filter((s) => s) : []
);

// fix parent node: if parent node was previously selected, it should be unselected if at least 1 of its children is unselected
// in this way, parent will be displayed as "intermediate"

const fixSelection = (node: OrgHierarchy, selection: OrgHierarchy[]): OrgHierarchy[] => {
  // recursively find selected children
  const children = (node.children || []).reduce<OrgHierarchy[]>((acc, n) => [
    ...acc, ...fixSelection(n, selection)], []);

  const selected = !!selection.find((s) => s.id === node.id);

  // if current node is selected then
  if (selected) {
    // if current node has children (is not leaf then)
    if (node?.children.length) {
      const selectedChildren = children.filter((t) => !!(node.children || []).find((n) => n.id === t.id));
      // if all its immediate children are all selected, then parent sould also be selected
      if (hasSameItems(node.children, selectedChildren)) {
        return [{ ...node }, ...children];
      }
    } else {
      return [{ ...node }];
    }
  }
  // if node is not selected, then returned current selection
  return children;
};

/**
 * see https://mi9retail.atlassian.net/browse/V8-19994
 * this function removes invalid node ids which have been deleted in backend
 * SOS: this is a workaround until bug is fixed in backend
 */
export const fixInvalidSelectionSync = (nodeIds: string[], rootStore: OrgHierarchy) => {
  let selection = nodeIds.map((id) => (
    findStore(rootStore, id, compareIds)
  )).filter((n) => !!n);
  selection = [rootStore].reduce((acc, n) => [...acc, ...fixSelection(n, selection)], []);
  return selection.map((s) => s.id).filter((n) => !!n);
};
