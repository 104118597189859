import classnames from 'classnames';
import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { isFunction } from 'framework/utils/helpers';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { GaActions } from 'framework/applicationMonitoring/interface';
import { useWindow } from 'framework/utils/useWindow';
import { i18n } from 'i18n';
import { prefixClassName } from '../_conf';
import { IKebab, KebabMenuDirections } from './Kebab.interfaces';
import { KebabButton } from './KebabButton';
import { KebabOption } from './KebabOption';
import { KebabSingleOption } from './KebabSingleOption';
import './_style.scss';

const clx = prefixClassName('kebab');

export const Kebab = (props: IKebab) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [direction, setDirection] = React.useState<string>('downLeft');
  const kebabRef = React.useRef();
  const {
    menuDirection = 'downLeft',
    disabled = false,
    className,
    optionsData,
    options = [],
    keepDropdown = false,
    onToggle,
  } = props;
  const { location: { pathname = '' } } = useWindow();

  const onOutsideClickHandler = () => {
    setIsOpen(false);
  };

  const isHidden = (op) => {
    if (!op) {
      return true;
    }
    if (optionsData && isFunction(op.hidden)) {
      return op.hidden(optionsData);
    }
    return op.hidden;
  };

  const isDisabled = (op) => {
    if (!op) {
      return true;
    }
    if (optionsData && isFunction(op.disabled)) {
      return op.disabled(optionsData);
    }
    return op.disabled;
  };

  // Get visible options list.
  const opt = options.filter((op) => !isHidden(op));

  if (!opt || opt.length === 0) {
    return null;
  }

  const toggleOpen = (e?: unknown) => {
    // copy prop
    let menuDirectionCopy = `${menuDirection}`;
    // get horizontal direction
    const horiontalDirection = menuDirection.includes(KebabMenuDirections.Left)
      ? KebabMenuDirections.Left
      : KebabMenuDirections.Right;
    // get kebab positions
    const kebabBoundingClientRect = (kebabRef as any).current?.getBoundingClientRect();
    // check if kebabBoundingClientRect exists
    if (kebabBoundingClientRect) {
      // get closest modal content
      const closestElement = (kebabRef as any).current?.closest('.mwg-modal__content');
      // calc the dropdown position
      const dropdownVerticalPosition = kebabBoundingClientRect.top
        + kebabBoundingClientRect.height + opt.length * 41 + 22;
      // check if too close to bottom of the screen or closest modal and adjust
      if (
        dropdownVerticalPosition >= document.documentElement.clientHeight
        || dropdownVerticalPosition >= closestElement?.getBoundingClientRect().bottom
      ) {
        menuDirectionCopy = `${KebabMenuDirections.Up}${horiontalDirection}`;
      }
    }

    setDirection(menuDirectionCopy);
    setIsOpen(!isOpen);
    onToggle?.(!isOpen, e);
  };

  const handleClick = (fn: (e?: any) => void, e?: unknown) => {
    if (optionsData) {
      fn(optionsData);
    } else {
      fn();
    }

    toggleOpen(e);
  };

  const displaySingleOption = opt.length === 1 && !keepDropdown;

  return displaySingleOption ? (
    <KebabSingleOption
      label={opt[0].label}
      disabled={isDisabled(opt[0])}
      linkTo={opt[0].linkTo}
      onClick={(e) => !opt[0].linkTo && handleClick(opt[0].onClick, e)}
      helperText={opt[0].helperText}
      className={classnames(`${clx}__singleOption`, opt[0].className)}
      hidden={isHidden(opt[0])}
    />
  ) : (
    <OutsideClickHandler onOutsideClick={onOutsideClickHandler} disabled={!isOpen}>
      <div
        className={classnames(
          clx,
          `${clx}--menu-${direction}`,
          isOpen && `${clx}--open`,
          disabled && `${clx}--disabled`,
          className,
        )}
        ref={kebabRef}
      >
        <KebabButton
          className={`${clx}__button`}
          disabled={disabled}
          onClick={(e) => {
            ApplicationMonitoring.trackEvent(pathname, GaActions.ActionMenuOption, 'Toggle');
            toggleOpen(e);
          }}
          active={isOpen}
        />
        {isOpen && (
          <div className={`${clx}__optionsList`}>
            {opt.map((option, key) => (
              <KebabOption
                key={key}
                label={option.label}
                linkTo={option.linkTo}
                index={key}
                onClick={(e) => {
                  ApplicationMonitoring.trackEvent(
                    pathname,
                    GaActions.ActionMenuOption,
                    i18n.t(option?.label as string || option?.testId)
                  );
                  if (!option.linkTo) {handleClick(option.onClick, e);}
                }}
                disabled={isDisabled(option)}
                helperText={option.helperText}
                className={classnames(`${clx}__option`, option.className)}
                hidden={isHidden(option)}
                testId={option.testId}
              />
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Kebab;
