import { select, call, put } from 'redux-saga/effects';
import * as uuidv4 from 'uuid/v4';
import { formSelectors } from 'framework/selectors';
import { toastListActions, formActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { getRawValues } from 'framework/components/ui/FormComponents/Form/service';
import { SAVE_PRODUCT_ACTION, SaveProductActionReturn } from '../actions';
import { ProductDetails } from '../../../models/product';
import { createProduct, patchProduct } from '../../../api/products';
import { FORM_NAME } from '../constants';
import { ProductTranslations } from '../../../translations';
import { goToEdit } from '../../../service';
import { mapError } from './errorService';

function* save({ previousProduct, eTag }: SaveProductActionReturn) {
  const formFields = yield select(formSelectors.getFormFieldValues, FORM_NAME);
  const product = getRawValues(formFields) as ProductDetails;
  let { id } = previousProduct;
  let result;
  if (id) {
    const requestData = {
      op: 'replace',
      path: '/status',
      value: product?.status,
    };
    result = yield call(patchProduct, product?.id, [requestData], eTag);
  } else {
    id = uuidv4();
    product.id = id;
    result = yield call(createProduct, product);
  }

  if (result.success) {
    yield put(toastListActions.addMessage(ProductTranslations.SaveSuccess));
    goToEdit(product);
  } else {
    const { globalErrors, fieldErrors } = mapError(result.error);

    if (globalErrors) {
      yield put(
        toastListActions.addToastlistMessage(
          globalErrors.map(caption => ({
            id: Math.random(),
            caption,
            type: 'error'
          }))
        )
      );
    }

    if (fieldErrors) {
      yield put(
        formActions.createSetFormErrorsAction({
          name: FORM_NAME,
          errors: fieldErrors
        })
      );
    }
  }
}

export function saveProduct() {
  return takeEveryWithProgressBar(SAVE_PRODUCT_ACTION, save);
}