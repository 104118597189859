import * as React from 'react';
import * as classnames from 'classnames';
import { FilterBar, LayoutFlexBoxItem, Select } from 'ui';
import { ApiDataProvider } from 'framework/components/services/ApiDataProvider';
import {
  API_DATA_PROVIDER_FILTER_STORE_OPTIONS,
  PATH_MAIN,
  PATH_SEARCH_SETTINGS
} from 'framework/modules/searchadmin/constants';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IFiltersStore } from './FiltersStore.interfaces';
import './_style.scss';

const clx = prefixClassName('searchAdmin__filtersStore');

const FiltersStore = (props: IFiltersStore) => {
  const {
    className,
    showLinkToSettings = false,
    filterStoreLabel = 'modules.merchandising.components.filtersStore.filterStore',
    filterStoreName = 'storeName',
    filterLocaleLabel = 'modules.merchandising.components.filtersStore.filterLocale',
    filterLocaleName = 'locales',
    ApiDataProviderConf = {
      endpoint: 'search',
      endpointPath: '/relevance/options',
      name: API_DATA_PROVIDER_FILTER_STORE_OPTIONS
    },
    filterStoreOptions = {},
    onChange
  } = props;

  return (
    <FilterBar.Old className={classnames(clx, className)}>
      <ApiDataProvider
        endpoint={ApiDataProviderConf.endpoint}
        endpointPath={ApiDataProviderConf.endpointPath}
        name={ApiDataProviderConf.name}
      />
      <LayoutFlexBoxItem flexBasis="40%" className={`${clx}__dropdown`}>
        <Select
          labelInline
          searchable
          clearable={false}
          caption={filterStoreLabel}
          name={filterStoreName}
          options={filterStoreOptions.stores}
          onChange={onChange}
          infoMessage={
            showLinkToSettings && {
              caption: 'modules.merchandising.components.filtersStore.filterStoreInfo',
              linkText: 'modules.merchandising.components.filtersStore.filterStoreInfoLink',
              linkTo: `${PATH_MAIN}${PATH_SEARCH_SETTINGS}`
            }
          }
        />
      </LayoutFlexBoxItem>
      <LayoutFlexBoxItem flexBasis="20%" className={`${clx}__dropdown`}>
        <Select
          labelInline
          clearable={false}
          caption={filterLocaleLabel}
          onChange={onChange}
          name={filterLocaleName}
          options={filterStoreOptions.locales}
          readOnly
        />
      </LayoutFlexBoxItem>
    </FilterBar.Old>
  );
};

export default FiltersStore;
