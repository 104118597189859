import React from 'react';
import { DualSelect, Panel } from 'ui';
import { SITEADMIN_ASSIGN_ROLES_TO_USER } from '../CommonPage/CommonPage.Constants';
import { useCommonPageRoles } from './CommonPageRoles.Functions';
import { CommonPageRolesProps, CommonPageRolesActions } from './CommonPageRoles.Interfaces';

const translation = 'modules.siteadmin.createUserPage';

export const CommonPageRoles = ({
  user,
  saveRoles,
  onRolesChange,
}: CommonPageRolesProps & CommonPageRolesActions) => {
  const {
    dirty,
    loadingRoles,
    availableRoles,
    assignRoles,
    addRoles,
    removeRoles,
    resetRoles,
    filterRoles,
  } = useCommonPageRoles({
    user,
  });

  const handleSaveRoles = () => {
    saveRoles({ roles: assignRoles.map((r) => r.id) });
    onRolesChange(false);
  };

  const onAddRoles = (params: any) => {
    addRoles(params);
    onRolesChange(true);
  };

  const onRemoveRoles = (params: any) => {
    removeRoles(params);
    onRolesChange(true);
  };

  const onResetRoles = () => {
    resetRoles();
    onRolesChange(false);
  };

  return (
    <Panel
      messages={[{ caption: [`${translation}.tab3Message`, `${translation}.tab3Message_2`], showLabel: false }]}
      title={`${translation}.tab3Title`}
      subtitle={`${translation}.linkUsageInfo`}
    >
      <DualSelect
        addAllItemsCaption={`${translation}.addAllRoles`}
        addAllFilteredItemsCaption={`${translation}.addAllFilteredRoles`}
        addItemsCaption={`${translation}.addRoles`}
        availableItems={availableRoles}
        assignedItems={assignRoles}
        instructions={`${translation}.rolesInstructions`}
        name={SITEADMIN_ASSIGN_ROLES_TO_USER}
        removeAllItemsCaption={`${translation}.removeAllRoles`}
        removeAllFilteredItemsCaption={`${translation}.removeAllFilteredRoles`}
        removeItemsCaption={`${translation}.removeRoles`}
        onAddItems={onAddRoles}
        onRemoveItems={onRemoveRoles}
        isLoading={loadingRoles}
        onFilter={(filterBy) => filterRoles(filterBy)}
      />

      <Panel.Footer
        options={[
          {
            caption: `${translation}.saveRoles`,
            onClick: handleSaveRoles,
            disabled: !dirty || loadingRoles,
          },
          {
            caption: 'components.form.reset',
            disabled: !dirty,
            outline: true,
            onClick: onResetRoles,
          },
        ]}
      />
    </Panel>
  );
};
