export enum PromotionsTranslations {
  Promotions = 'modules.promotions.promotions',
  GlobalPromotions = 'modules.siteadmin.claims.globalPromotions',
  PromotionCodes = 'modules.promotions.promotionCodes',
  AllPromotionCodes = 'modules.promotions.allPromotionCodes',
  CreatePromotionCode = 'modules.promotions.createPromotionCode',
  PromotionCodeName = 'modules.promotions.promotionCodeName',
  NumberOfUses = 'modules.promotions.numberOfUses',
  MaximumNumberOfUsesPerCustomer = 'modules.promotions.maximumNumberOfUsesPerCustomer',
  PromotionCode = 'modules.promotions.promotionCode',
  Status = 'modules.promotions.status',
  Actions = 'generic.actions',
  FilterByKeyword = 'generic.filterByKeyword',
  FilterByStatus = 'generic.filterByStatus',
  ActivateMessage = 'modules.promotions.activateMessage',
  ActivateMessagePlural = 'modules.promotions.activateMessage_plural',
  DeactivateMessage = 'modules.promotions.deactivateMessage',
  DeactivateMessagePlural = 'modules.promotions.deactivateMessage_plural',
  ChangeToActive = 'generic.options.StatusChangeToActive',
  ChangeToInactive = 'generic.options.StatusChangeToInactive',
  DoNotChangeToActive = 'modules.promotions.doNotActivate',
  DoNotChangeToInactive = 'modules.promotions.doNotDeactivate',
  Delete = 'generic.options.delete',
  DoNotDeletePromotion = 'modules.promotions.doNotDeletePromotion',
  DeletePromotion = 'modules.promotions.deletePromotion',
  PromoCodeStatusUpdateSuccess = 'modules.promotions.promoCodeStatusUpdateSuccess',
  PromoCodeStatusUpdateError = 'modules.promotions.promoCodeStatusUpdateError',
  PromoCodeDeleteSuccess = 'modules.promotions.promoCodeDeleteSuccess',
  PromoCodeDeleteError = 'modules.promotions.promoCodeDeleteError',
  PromotionCodeNameHint = 'modules.promotions.promotionCodeNameHint',
  MaximumNumberOfUses = 'modules.promotions.maximumNumberOfUses',
  MaximumNumberOfUsesHint = 'modules.promotions.maximumNumberOfUsesHint',
  MaximumNumberOfUsesPerCustomerHint = 'modules.promotions.maximumNumberOfUsesPerCustomerHint',
  PromotionCodesHint = 'modules.promotions.promotionCodesHint',
  AddACode = 'modules.promotions.addACode',
  SavePromotionCode = 'modules.promotions.savePromotionCode',
  Reset = 'generic.button.reset',
  Cancel = 'generic.button.cancel',
  Active = 'generic.status.active',
  Inactive = 'generic.status.inactive',
  LimitReached = 'generic.status.limitReached',
  XCodes = 'modules.promotions.xCodes',
  CurrentUsages = 'modules.promotions.currentUsages',
  CurrentUsagesHint = 'modules.promotions.currentUsagesHint',
  SavePromocodeSuccess = 'modules.promotions.savePromocodeSuccess',
  SavePromocodeError = 'modules.promotions.savePromocodeError',
  CodesAlreadyInUse = 'modules.promotions.codesAlreadyInUse',
  AllPromotions = 'modules.promotions.allPromotions',
  PromotionStatusUpdateSuccess = 'modules.promotions.promotionStatusUpdateSuccess',
  PromotionStatusUpdateError = 'modules.promotions.promotionStatusUpdateError',
  PromotionDeleteError = 'modules.promotions.promotionDeleteError',
  PromotionDeleteSuccess = 'modules.promotions.promotionDeleteSuccess',
  XStores = 'modules.promotions.XStores',
  DisplayName = 'modules.promotions.displayName',
  ShortDescription = 'modules.promotions.shortDescription',
  StartDate = 'modules.promotions.startDate',
  EndDate = 'modules.promotions.endDate',
  Priority = 'modules.promotions.priority',
  FilterByStore = 'modules.promotions.filterByStore',
  CreatePromotion = 'modules.promotions.createPromotion',
  EditPromotion = 'modules.promotions.editPromotion',
  PromotionInformation = 'modules.promotions.promotionInformation',
  SavePromotion = 'modules.promotions.SavePromotion',
  DisplayNameHint = 'modules.promotions.displayNameHint',
  RetailerPromotionID = 'modules.promotions.retailerPromotionID',
  RetailerPromotionIDHint = 'modules.promotions.retailerPromotionIDHint',
  ShortDescriptionHint = 'modules.promotions.shortDescriptionHint',
  AdditionalQualifierText = 'modules.promotions.additionalQualifierText',
  AdditionalQualifierTextHint = 'modules.promotions.additionalQualifierTextHint',
  TermsAndConditionsText = 'modules.promotions.termsAndConditionsText',
  TermsAndConditionsTextHint = 'modules.promotions.termsAndConditionsTextHint',
  PriorityHint = 'modules.promotions.priorityHint',
  PromotionDetails = 'modules.promotions.promotionDetails',
  AssingStores = 'modules.promotions.assingStores',
  DateRangeHint = 'modules.promotions.dateRangeHint',
  DateRangeHintStoreTime = 'modules.promotions.dateRangeHintStoreTime',
  SelectStoresHint1 = 'modules.promotions.selectStoresHint1',
  SelectStoresHint2 = 'modules.promotions.selectStoresHint2',
  SelectStoresHint3 = 'modules.promotions.selectStoresHint3',
  ImageGallery = 'modules.promotions.imageGallery',
  ImageGalleryDesc = 'modules.promotions.imageGalleryDesc',
  ImgNameAllDevices = 'modules.promotions.imgNameAllDevices',
  ImageFeature = 'modules.promotions.imageFeature',
  ImageFeatureDesc = 'modules.promotions.imageFeatureDesc',
  Stores = 'modules.promotions.stores',
  PromotionType = 'modules.promotions.promotionType',
  SavePromotionSuccess = 'modules.promotions.savePromotionSuccess',
  SavePromotionError = 'modules.promotions.savePromotionError',
  PromotionRule = 'modules.promotions.promotionRule',
  Cart = 'modules.promotions.cart',
  CartDesc = 'modules.promotions.cartDesc',
  Product = 'modules.promotions.product',
  ProductDesc = 'modules.promotions.productDesc',
  Target = 'modules.promotions.target',
  TargetDesc = 'modules.promotions.targetDesc',
  SelectPromotionType = 'modules.promotions.selectPromotionType',
  PromotionCodeNames = 'modules.promotions.promotionCodeNames',
  PromotionCodeNamesHint = 'modules.promotions.promotionCodeNamesHint',
  POSBarcode = 'modules.promotions.POSBarcode',
  POSBarcodeHint = 'modules.promotions.POSBarcodeHint',
  CombineCartPromotion = 'modules.promotions.combineCartPromotion',
  CombineCartPromotionHint = 'modules.promotions.combineCartPromotionHint',
  CombineProductPromotion = 'modules.promotions.combineProductPromotion',
  CombineProductPromotionHint = 'modules.promotions.combineProductPromotionHint',
  OnlyAvailableToLoyaltyCustomers = 'modules.promotions.onlyAvailableToLoyaltyCustomers',
  OnlyAvailableToLoyaltyCustomersHint = 'modules.promotions.onlyAvailableToLoyaltyCustomersHint',
  WhenCartValueIsMoreThan = 'modules.promotions.whenCartValueIsMoreThan',
  Get = 'modules.promotions.get',
  AndGet = 'modules.promotions.andGet',
  AddAnotherOffer = 'modules.promotions.addAnotherOffer',
  AddTier = 'modules.promotions.addTier',
  AddTargetGroup = 'modules.promotions.addTargetGroup',
  AndTarget = 'modules.promotions.andTarget',
  Identifier = 'modules.promotions.identifier',
  EmailGroup = 'modules.promotions.emailGroup',
  Segments = 'modules.promotions.segments',
  ExternallyProvidedGroup = 'modules.promotions.externallyProvidedGroup',
  addSegmentationPlaceholder = 'modules.promotions.addSegmentationPlaceholder',
  addSegmentationWarning = 'modules.promotions.addSegmentationWarning',
  Off = 'modules.promotions.off',
  Points = 'modules.promotions.points',
  PointsPerSpend = 'modules.promotions.pointsPerSpend',
  SaveAndContinueToRules = 'modules.promotions.saveAndContinueToRules',
  AddBarcode = 'modules.promotions.addBarcode',
  MaxUsagesPerCustomerValidation = 'modules.promotions.maxUsagesPerCustomerValidation',
  ShowInProductListing = 'modules.promotions.showInProductListing',
  ShowInProductListingHint = 'modules.promotions.showInProductListingHint',
  CombineGeneric = 'modules.promotions.combinePromotion',
  CombineGenericHint = 'modules.promotions.combinePromotionHint',
  Limit = 'modules.promotions.limit',
  LimitHint = 'modules.promotions.limitHint',
  LimitPerSku = 'modules.promotions.limitPerSku',
  LimitPerSkuHint = 'modules.promotions.limitPerSkuHint',
  Unlimited = 'modules.promotions.unlimited',
  Spend = 'modules.promotions.spend',
  On = 'modules.promotions.on',
  From = 'modules.promotions.from',
  RequiredSKUs = 'modules.promotions.requiredSKUs',
  ProductPromotionConditionHint1 = 'modules.promotions.productPromotionConditionHint1',
  TargetPromotionConditionHint1 = 'modules.promotions.requiredSkusHint1',
  TargetPromotionConditionHint2 = 'modules.promotions.targetPromotionConditionHint2',
  AddSKU = 'modules.promotions.addSKU',
  AddBrand = 'modules.promotions.addBrand',
  AddAttribute = 'modules.promotions.addAttribute',
  Bulk = 'modules.promotions.bulk',
  BulkDesc = 'modules.promotions.bulkDesc',
  Buy = 'modules.promotions.buy',
  ForAll = 'modules.promotions.forAll',
  BulkPromotionConditionHint1 = 'modules.promotions.bulkPromotionConditionHint1',
  BulkPromotionConditionHint2 = 'modules.promotions.bulkPromotionConditionHint2',
  BundlePromotionConditionHint1 = 'modules.promotions.bundlePromotionConditionHint1',
  Bundle = 'modules.promotions.bundle',
  BundleDesc = 'modules.promotions.bundleDesc',
  BundleGroupHeading = 'modules.promotions.bundleGroupHeading',
  BundleGroupHeadingExplainer = 'modules.promotions.bundleGroupHeadingExplainer',
  AddAnotherGroup = 'modules.promotions.addAnotherGroup',
  AndBuy = 'modules.promotions.andBuy',
  BuyXGetY = 'modules.promotions.buyXGetY',
  BuyXGetYDesc = 'modules.promotions.buyXGetYDesc',
  OnCheapestFrom = 'modules.promotions.onCheapestFrom',
  OfferPromoteHint = 'modules.promotions.offerPromoteHint',
  MoneyOffValidation = 'modules.promotions.moneyOffValidation',
  TierValueValidation = 'modules.promotions.tierValueValidation',
  BuyFrom = 'modules.promotions.buyFrom',
  BuyXGetYConditionHint2 = 'modules.promotions.bulkPromotionConditionHint2',
  ServiceFeeOfferHint = 'modules.promotions.serviceFeeOfferHint',
  ShoppingMode = 'modules.promotions.shoppingMode',
  ShoppingModeHint = 'modules.promotions.shoppingModeHint',
  RedeemWithPoints = 'modules.promotions.redeemWithPoints',
  RedeemWithPointstHint = 'modules.promotions.redeemWithPointsHint',
  ServiceFee = 'modules.promotions.serviceFee',
  AddServiceFee = 'modules.promotions.addServiceFee',
  MissedPromotionThreshold = 'modules.promotions.missedPromotionThreshold',
  MissedPromotionThresholdHint = 'modules.promotions.missedPromotionThresholdHint',
  OrderCount = 'modules.promotions.orderCount',
  OrderCountHint = 'modules.promotions.orderCountHint',
  BuyXGetYRequiredOffer = 'modules.promotions.buyXGetYRequiredOffer',
  Yes = 'generic.yes',
  No = 'generic.no',
  UndefinedPromotion = 'modules.promotions.undefinedPromotion',
  CartPromotion = 'modules.promotions.cart',
  ProductPromotion = 'modules.promotions.product',
  BuyXGetYPromotion = 'modules.promotions.buyXGetY',
  Custom = 'modules.promotions.custom',
  BulkPromotion = 'modules.promotions.bulk',
  BundlePromotion = 'modules.promotions.bundle',
  TargetPromotion = 'modules.promotions.target',
  BuyXOfSelectedY = 'modules.promotions.buyXFromSelectedY',
  AndBuyXOfSelectedY = 'modules.promotions.andBuyXFromSelectedY',
  SpendXOnSelectedY = 'modules.promotions.spendXOnSelectedY',
  BuyFromSelectedX = 'modules.promotions.buyFromSelectedX',
  requiredSkus = 'modules.promotions.SKUs',
  requiredCategories = 'modules.promotions.categories',
  requiredBrands = 'modules.promotions.brands',
  requiredAttributes = 'modules.promotions.attributes',
  moneyOffX = 'modules.promotions.moneyOffX',
  pointsEarnedX = 'modules.promotions.pointsEarnedX',
  percentageOffX = 'modules.promotions.percentageOffX',
  percentageOffDeliveryX = 'modules.promotions.percentageOffDeliveryX',
  moneyOffDeliveryX = 'modules.promotions.moneyOffDeliveryX',
  fixedPriceX = 'modules.promotions.fixedPriceX',
  moneyOffXFrom = 'modules.promotions.moneyOffXFrom',
  percentageOffXFrom = 'modules.promotions.percentageOffXFrom',
  fixedPriceXFrom = 'modules.promotions.fixedPriceXFrom',
  LoyaltyNumberPrefix = 'modules.promotions.loyaltyNumberPrefix',
  LoyaltyNumberPrefixHint = 'modules.promotions.loyaltyNumberPrefixHint',
  PromotionUniqueIdError = 'modules.promotions.promotionUniqueIdError',
  PromotionUniqueIdUpdateError = 'modules.promotions.promotionUniqueIdUpdateError',
  AddMinimumCartTotal = 'modules.promotions.addMinimumCartTotal',
  HaveMinimumCartTotalOf = 'modules.promotions.haveMinimumCartTotalOf',
  ErrorFetchingPromotion = 'modules.promotions.errorFetchingPromotion',
}
