import { IN_PROGRESS } from './ProgressBar.types';

const initialState = false;

const inProgress = (state = initialState, action) => {
  switch (action.type) {
    case IN_PROGRESS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default inProgress;
