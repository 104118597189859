import { put, takeEvery } from 'redux-saga/effects';
import { apiClientActions } from 'framework/actions';
import * as CONST from 'framework/modules/searchadmin/constants';

function* process() {
  yield put(
    apiClientActions.clearApiClientStateAction({
      name: CONST.API_DATA_RULE_DATA
    })
  );
  yield put(
    apiClientActions.clearApiClientStateAction({
      name: CONST.API_DATA_RULE_CURRENT_DATA
    })
  );
  yield put(
    apiClientActions.clearApiClientStateAction({
      name: CONST.API_DATA_RULE_PREVIEW_DATA
    })
  );
}

export function* watchClearData() {
  yield takeEvery(CONST.ACTION_RULES_CLEAR_PAGE_DATA, process);
}
