/* eslint-disable no-param-reassign */
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import clx from 'classnames';
import { Button } from 'ui';
import ReactDOM from 'react-dom';
import { prefixClassName } from '../_conf';
import {
  tableClassName,
  emptyCell,
  tableRow,
  tableRowDragged,
  tableRowMoved,
} from './constants';
import { Column } from './TableColumn';

interface Base {
  children?: React.ReactNode;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  onClick?: () => void;
}

const trEven = prefixClassName('tr-even');
const trOdd = prefixClassName('tr-odd');
const expanderBtn = prefixClassName('expander-btn');
const expanderBtnExpanded = `${expanderBtn}--expanded`;

export const Row = ({ className = '', onClick, children }: Base) => (
  <tr className={`${tableRow}  ${className}`} onClick={onClick} >{children}</tr>
);

interface DraggableRowProps extends Base {
  draggableId: string;
  index: number;
  moved?: boolean;
  dragDisabled?: boolean;
  allowPortal?: boolean;
}
export const DraggableRow = ({
  draggableId,
  index,
  className,
  children,
  moved,
  dragDisabled,
  allowPortal,
}: DraggableRowProps) => (
  <Draggable
    draggableId={draggableId}
    index={index}
    isDragDisabled={dragDisabled}
    key={draggableId}
  >
    {(provided, snapshot) => {
      const content = (<tr
        key={draggableId}
        className={clx(
          tableRow,
          className,
          {
            [tableRowDragged]: snapshot.isDragging,
            [tableRowMoved]: moved,
            [`${tableClassName}__dragPortal`]: allowPortal && snapshot.isDragging,
            [`${tableClassName}__dragPortal-even`]: allowPortal && !(index % 2),
          },
        )}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
      {children}
    </tr>);

      if (allowPortal && snapshot.isDragging) {
        return ReactDOM.createPortal(content, document.body);
      }
      return (content);
    }}
  </Draggable>
);

interface MultiRowProps extends Base {
  index: number;
}

export const MultiRow = ({ className, children, index }: MultiRowProps) => (
  <Row className={`${className || ''} ${index % 2 === 0 ? trOdd : trEven}`}>{children}</Row>
);

export const DeletableRow = ({ className, children }: Base) => (
  <Row className={clx(className, `${tableClassName}__row--deletable`)}>{children}</Row>
);

interface ExpandableRowProps extends MultiRowProps {
  expanded?: boolean;
  colSpan: number;
  Content: React.ComponentType;
  disabled?: boolean;
  showExpandable?: boolean;
}

export const ExpandableRow = ({
  className,
  expanded,
  Content,
  index,
  colSpan,
  children,
  disabled,
  showExpandable = true
}: ExpandableRowProps) => {
  const [isExpanded, setIsExpanded] = React.useState(expanded || false);

  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <>
      <MultiRow className={className} index={index}>
        <Column className={emptyCell}>
          {showExpandable && <Button
            className={clx(expanderBtn, { [expanderBtnExpanded]: isExpanded })}
            icon="dropdownArrowRight"
            onClick={() => setIsExpanded((p) => !p)}
            disabled={disabled}
          />}
        </Column>
        {children}
      </MultiRow>
      {isExpanded && (
        <MultiRow className={className} index={index}>
          <Column className={emptyCell} />
          <Column colSpan={colSpan}>
            <Content />
          </Column>
        </MultiRow>
      )}
    </>
  );
};
