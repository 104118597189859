import * as ACTIONS from './actions.constants';

// Sagas
export const saveTemplateDataAction = (isSchedule?: boolean, removedStores?: string[]) => ({
  type: ACTIONS.TEMPLATE_SAVE_DATA,
  payload: {
    isSchedule,
    removedStores,
  },
});

export const fetchTemplateDataAction = (templateId: string) => ({
  type: ACTIONS.TEMPLATE_FETCH_DATA,
  payload: {
    templateId,
  },
});

export const fetchCalendarIniData = () => ({
  type: ACTIONS.TEMPLATE_FETCH_DATA_CALENDAR,
});

export const editSlotAction = (deliveryAreas?: string[]) => ({
  type: ACTIONS.TEMPLATE_EDIT_SLOT,
  payload: deliveryAreas,
});

export const deleteSlotSelected = () => ({
  type: ACTIONS.TEMPLATE_DEL_SLOT_SELECTED,
});
