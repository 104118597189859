import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { view } from '@risingstack/react-easy-state';
import uuid from 'uuid/v4';
import { prefixClassName } from 'framework/components/ui/_conf';
import {
  Explorer, Table, Icon, Text, Loader, Modal, Form, TextField, Img, Button,
} from 'ui';
import config from 'config';

import { fetchData } from 'framework/api/fetch-data';
import { useModal } from 'framework/components/ui/Modal/useModal';
import { addProgressBar } from 'framework/components/ui/ProgressBar/useProgressBar';
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import { IconNames } from 'framework/components/ui/Icon';

import { folderTextValidator } from 'framework/components/hoc/formField/validators';
import { ExplorerSelection } from 'framework/components/ui/Explorer/types';
import { i18n } from 'i18n';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { TrackEventProps } from 'framework/applicationMonitoring/interface';
import { useLocation } from 'react-router-dom';
import { removeSpace } from 'framework/utils/helpers';
import {
  FileFolder, FileFolderType, UploaderFileManagerProps, KebabAction,
} from './types';
import {
  FILEMANAGER_MODAL_ADD_FOLDER, FILEMANAGER_MODAL_ADD_FOLDER_FORM,
} from './constants';
import { FileManagerHierarchy } from './FileManagerHierarchy';
import { useExplorerFileManager, useUploaderFileManager } from './useInternalFileManager';
import { useFileManagerContext, DeleteFunc } from './FileManagerContext';
import { deleteFileFolder } from './services';

const clx = prefixClassName('');
const cssClass = prefixClassName('file-manager');

const RETURN_KEY = 13;
const FILES_PER_LINE = 4;

type DirectoryActionsProps = {
  isLoading: boolean,
  isEmpty?: boolean,
  handleOpenFolderModal: (root?: boolean) => () => void,
  prompDelete: (mode: FileFolderType, onDelete: DeleteFunc) => () => void,
  onActiveFolderDelete: () => void
};
const getDirectoryActions = ({
  isLoading, isEmpty, handleOpenFolderModal, prompDelete, onActiveFolderDelete,
}: DirectoryActionsProps): KebabAction[] => {
  if (isLoading) {
    return [
      {
        label: <Loader size="xs" />,
        onClick: undefined,
      },
    ];
  }
  return [
    {
      label: 'modules.contentmanagement.addSubFolder',
      onClick: handleOpenFolderModal(true),
    },
    ...(isEmpty ? [{
      label: 'generic.delete',
      onClick: prompDelete(FileFolderType.DIRECTORY, onActiveFolderDelete),
    }] : []),
  ];
};

type ViewMode = 'columns' | 'grid';

type ViewModeButtonProps = {
  type: ViewMode;
  mode: ViewMode,
  onClick: React.Dispatch<React.SetStateAction<ViewMode>>;
  trackEventProps?: TrackEventProps;
};
const ViewModeButton = ({ type, mode, onClick: setMode, trackEventProps }: ViewModeButtonProps) => {
  const { pathname } = useLocation();

  const handleOnClick = () => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Explorer view mode',
      trackEventProps?.label || type
    );

    setMode(type);
  };

  return (
    <Button
      icon={type as never}
      outline
      className={classnames(`${cssClass}__icon-mode`, { [`${cssClass}__icon-mode--disable`]: mode !== type })}
      onClick={handleOnClick}
    />
  );
};

type FileManagerExplorerProps = Pick<ReturnType<typeof useExplorerFileManager>,
'store' | 'previewableFile' | 'handleExplorerRowClick' | 'openMoveModal' > & {
  onFileDelete: (file: FileFolder) => Promise<void>,
  onActiveFolderDelete: () => void;
  onFileSelected?: (file: FileFolder) => void;
  fileSelectable: boolean;
  currentFolder?: FileFolder;
  onCurrentFolderSelect?: (folder: FileFolder) => void;
  requiredFilesFormat?: string[];
};

const getColumnActions = (type: FileFolderType, isLoading: boolean, isEmpty?: boolean, prompDelete?:any, handleOpenFolderModal?: any, onActiveFolderDelete?: any, openMoveModal?:any): Array<KebabAction> => {
  if (type === FileFolderType.FILE) {
    return [
      {
        label: 'modules.contentmanagement.moveFile',
        onClick: openMoveModal,
      },
    ];
  }
  return getDirectoryActions({
    isLoading,
    isEmpty,
    prompDelete,
    handleOpenFolderModal,
    onActiveFolderDelete,
  });
};

export const FileManagerExplorerView = view((props: FileManagerExplorerProps) => {
  const { currentFolder, onCurrentFolderSelect, ...rest } = props;
  const [mode, setMode] = useState<ViewMode>('columns');
  const { setPreviewableFile } = useFileManagerContext();
  const [localCurrentFolder, setLocalCurrentFolder] = useState<FileFolder>(props.currentFolder);

  useEffect(() => {
    if (mode === 'grid') {
      setPreviewableFile(undefined);
    }
  }, [mode]);

  const onFolderChange = (folder: FileFolder) => {
    setLocalCurrentFolder(folder);
  };

  return (
    <>
      <div className={`${cssClass}__viewmode-buttons`}>
          <ViewModeButton type="columns" mode={mode} onClick={setMode} />
          <ViewModeButton type="grid" mode={mode} onClick={setMode} />
        </div>

      {mode === 'columns' && (
        <FileManagerExplorerColumnView
        currentFolder={localCurrentFolder}
        onCurrentFolderSelect={onFolderChange}
        {...rest}
        />
      )}

      {mode === 'grid' && (
        <FileManagerExplorerGridView
        currentFolder={localCurrentFolder}
        onCurrentFolderSelect={onFolderChange}
        {...rest}/>
      )}
    </>
  );
});

const FileManagerExplorerColumnView = view(({
  store, handleExplorerRowClick, openMoveModal, previewableFile, onFileSelected, onFileDelete,
  onActiveFolderDelete, fileSelectable, onCurrentFolderSelect, currentFolder, requiredFilesFormat
}: FileManagerExplorerProps) => {
  const { setStore, handleOpenFolderModal, prompDelete } = useFileManagerContext();
  const [externalSelectionArray, setExternalSelectionArray] = useState<ExplorerSelection[]>();

  const selectFolders = (folder: FileFolder, level: number): ExplorerSelection[] => {
    if (!folder) {
      return undefined;
    }
    const newLevel = level - 1;
    if (folder.parent) {
      const pathStep = selectFolders(folder.parent, newLevel);
      pathStep.push({ id: folder.reference, level });
      return pathStep;
    }
    return [{ id: folder.reference, level }];
  };

  useEffect(() => {
    setStore(store as never);
  }, []);

  useEffect(() => {
    if (currentFolder) {
      const depth = currentFolder.reference.split('/').length - 2;
      const stepPath = selectFolders(currentFolder, depth);
      setExternalSelectionArray(stepPath);
    }
  }, []);

  const handleExplorerRowClickIntermediary = (row: FileFolder) => {
    onCurrentFolderSelect(row);
    return handleExplorerRowClick(row);
  };

  const handleOnExplorerSelection = ({ selection, row }) => {
    setExternalSelectionArray(selection);
    handleExplorerRowClick(row);
    onCurrentFolderSelect(row);
  };

  return (
    <Explorer<FileFolder>
      data={store.data}
      idProp="reference"
      preview={
        previewableFile ? (
          <PreviewFile
            fileSelectable={fileSelectable}
            preview={previewableFile}
            onPreviewSelect={() => onFileSelected?.(previewableFile)}
            onPreviewDelete={() => onFileDelete(previewableFile)}
          />
        ) : undefined
      }
      onRowClick={handleExplorerRowClickIntermediary}
      onExplorerSelection={handleOnExplorerSelection}
      onRowClass={() => `${cssClass}__row-explorer`}
      columnMetadata={['', '']}
      renderColumns={({ row, isSelected }) => [
        <Table.Column key="td-content" className={`${cssClass}__td-content`}>
          <div className={`${cssClass}__content`}>
            {store.isLoading(row.reference) ? (
              <Loader size="xs" />
            ) : (
              <Icon
                name={store.resolveIcon(row)}
                className={classnames(`${cssClass}__icon`, isSelected && `${cssClass}__icon--selected`)}
              />
            )}
            <Text tag="div" caption={row.name} className={`${cssClass}__label`} />
            <div />
          </div>
        </Table.Column>,

        <Table.ColumnKebab
          key="td-action"
          keepDropdown
          options={getColumnActions(
            row.type,
            store.isLoading(row.reference),
            !row.children?.length,
            prompDelete,
            handleOpenFolderModal,
            onActiveFolderDelete,
            openMoveModal
          )}
          className={`${clx}Table-cell--no-ellipsis`}
        />,
      ]}
      externalSelection={externalSelectionArray || undefined}
      requiredFilesFormat={requiredFilesFormat}
    />
  );
});

const FileManagerExplorerGridView = view(({
  store,
  fileSelectable,
  handleExplorerRowClick: onExplorerRowClick,
  onFileDelete,
  onFileSelected,
  onCurrentFolderSelect,
  currentFolder,
}: FileManagerExplorerProps) => {
  const [activeFiles, setActiveFiles] = useState<FileFolder[]>([]);
  const [activeFolder, setActiveFolder] = useState<FileFolder>(currentFolder);

  const handleFolderHighlight = (folder: FileFolder) => {
    if (folder) {
      onExplorerRowClick(folder).then((res) => {
        setActiveFiles(res.filter((f) => f.type === FileFolderType.FILE));
        setActiveFolder(folder);
        onCurrentFolderSelect(folder);
      });
    }
  };

  useEffect(() => {
    handleFolderHighlight(activeFolder);
  }, []);

  return (
    <div className={`${cssClass}__gridview`}>
      <div className={`${cssClass}__gridview__hierarchy`}>
        <FileManagerHierarchy
          hideHeaders
          displayEmptyRow={false}
          data={store.data}
          isLoading={store.isLoading}
          resolveIcon={store.resolveIcon}
          onHierarchyFolderHighlight={handleFolderHighlight}
          onHierarchyRowExpanding={onExplorerRowClick}
          activeFolder={activeFolder}
        />
      </div>
      <div className={`${cssClass}__gridview__gridpane`}>
        <FileManagerGridView
          files={activeFiles}
          fileSelectable={fileSelectable}
          activeFolder={activeFolder}
          onFileDelete={onFileDelete}
          onFileSelected={onFileSelected}
          refresh={handleFolderHighlight}
        />
      </div>
    </div>
  );
});

type FileManagerGridViewProps = {
  files: FileFolder[];
  fileSelectable: boolean;
  activeFolder?: FileFolder;
  onFileDelete: (file: FileFolder) => Promise<void>;
  onFileSelected?: (file: FileFolder) => void;
  refresh: (row: FileFolder) => void;
};
const FileManagerGridView = view(({
  files, onFileDelete, refresh, activeFolder, fileSelectable, onFileSelected,
}: FileManagerGridViewProps) => {
  const rootClassName = `${cssClass}__gridview__gridpane__files`;
  const [clipboardCopyFeedback, setClipboardCopyFeedback] = React.useState<string|boolean>(false);
  const handleCopyToClipboard = (file: FileFolder) => {
    navigator.clipboard.writeText(file.url).then(() => {
      setClipboardCopyFeedback(file.url);
    }).finally(() => {
      setTimeout(() => setClipboardCopyFeedback(false), 5000);
    });
  };

  const { prompDelete } = useFileManagerContext();

  const handleDeleteFile = (file: FileFolder) => () => {
    onFileDelete(file).then(() => {
      refresh(activeFolder);
    });
  };

  const buildLines = () => {
    let lines: Array<FileFolder[]> = [];
    files.forEach((file, i) => {
      const indexOfLine = Math.floor(i / FILES_PER_LINE);

      // init new line of files
      if (lines.length <= indexOfLine) {
        lines = [...lines, []];
      }

      // add new file to current line (index)
      lines[indexOfLine] = [...lines[indexOfLine], file];
    });
    return lines;
  };

  const buildRows = (lines: Array<FileFolder[]>) => lines.map((line) => (
    <div key={uuid()} className={`${rootClassName}__line`} >
      {line.map((file) => (
        <div key={uuid()} className={`${rootClassName}__line__file`}>
          <div className={`${rootClassName}__line__file__image`}>
            <Img height="" width="" src={file.url} />
          </div>
          <div className={`${rootClassName}__line__file__name`}>
            {file.name}
          </div>
          <div className={`${rootClassName}__line__file__url`}>
            {file.reference}
          </div>
          <div className={`${rootClassName}__line__file__actions`}>
          <Button className={`${cssClass}__copy-to-clipboard`} onClick={() => handleCopyToClipboard(file)} link>
            <Text caption={i18n.t('generic.copyToClipboard')} tag="span" />
            {clipboardCopyFeedback === file.url && (
              <Text
                className={`${cssClass}__copy-to-clipboard__success`}
                caption={i18n.t('generic.copyToClipboardSuccess')}
                tag="span"
              />
            )}
          </Button>
            <Button
              styleless
              caption="generic.options.delete"
              onClick={prompDelete(FileFolderType.FILE, handleDeleteFile(file))}
              className={`${rootClassName}__line__file__actions__delete`}
            />
            {fileSelectable && (
              <Button
                caption="Select file"
                onClick={() => onFileSelected?.(file)}
                className={`${rootClassName}__line__file__actions__select`}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  ));

  const lines = buildLines();
  const rows = buildRows(lines);

  return (
    <div className={rootClassName}>{rows}</div>
  );
});

export const FileManagerExplorer = view(({
  store, handleExplorerRowClick, openMoveModal, previewableFile, onFileSelected, onFileDelete,
  onActiveFolderDelete, fileSelectable,
}: FileManagerExplorerProps) => {
  const { setStore, handleOpenFolderModal, prompDelete } = useFileManagerContext();

  useEffect(() => {
    setStore(store as never);
  }, []);

  return (
    <Explorer<FileFolder>
      data={store.data}
      idProp="reference"
      preview={
        previewableFile ? (
          <PreviewFile
            fileSelectable={fileSelectable}
            preview={previewableFile}
            onPreviewSelect={() => onFileSelected?.(previewableFile)}
            onPreviewDelete={() => onFileDelete(previewableFile)}
          />
        ) : undefined
      }
      onRowClick={handleExplorerRowClick}
      onRowClass={() => `${cssClass}__row-explorer`}
      columnMetadata={['', '']}
      renderColumns={({ row, isSelected }) => [
        <Table.Column key="td-content" className={`${cssClass}__td-content`}>
          <div className={`${cssClass}__content`}>
            {store.isLoading(row.reference) ? (
              <Loader size="xs" />
            ) : (
              <Icon
                name={store.resolveIcon(row)}
                className={classnames(`${cssClass}__icon`, isSelected && `${cssClass}__icon--selected`)}
              />
            )}
            <Text tag="div" caption={row.name} className={`${cssClass}__label`} />
            <div />
          </div>
        </Table.Column>,

        <Table.ColumnKebab
          key="td-action"
          keepDropdown
          options={getColumnActions(
            row.type,
            store.isLoading(row.reference),
            !row.children?.length,
            prompDelete,
            handleOpenFolderModal,
            onActiveFolderDelete,
            openMoveModal
          )}
        />,
      ]}
    />
  );
});

export const FileManagerUpload = view(({ uploadableFiles: files, onDeleteFile, ...rest }: UploaderFileManagerProps) => {
  const {
    store,
    handleDropFiles,
    handleHighlightFolder,
    handleRowExpanding,
  } = useUploaderFileManager({ uploadableFiles: files, ...rest });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop: handleDropFiles });

  const {
    setStore, handleOpenFolderModal, prompDelete, activeFolder,
  } = useFileManagerContext();

  useEffect(() => {
    setStore(store as never);
  }, []);

  return (
    <div className={`${cssClass}__uploader`}>
      <div className={`${cssClass}__uploader__dropzone`}>
        <div
          className={
            classnames(`${cssClass}__uploader__base-style`,
              isDragActive && `${cssClass}__uploader__base-style--active`,
              isDragAccept && `${cssClass}__uploader__base-style--accept`,
              isDragReject && `${cssClass}__uploader__base-style--reject`)
          }
          {...getRootProps()}>
          <input {...getInputProps()} />
          <p className={`${cssClass}__uploader__prompt`} ><Text caption="modules.contentmanagement.dragAndDrop" /></p>
        </div>

        {files.length ? <FilesPreview files={files} onDeleteFile={onDeleteFile} /> : <></>}

      </div>

      {store.initializing ? (
        <div className={`${cssClass}__loader`}>
          <Loader size="lg" />
        </div>
      ) : (
        <FileManagerHierarchy
          wider
          data={store.data}
          isLoading={store.isLoading}
          resolveIcon={store.resolveIcon}
          onHierarchyRowExpanding={handleRowExpanding}
          onHierarchyFolderHighlight={handleHighlightFolder}
          onKebabActions={(row: FileFolder) => getDirectoryActions({
            isEmpty: !row.children?.length,
            isLoading: store.isLoading(row.reference),
            prompDelete,
            handleOpenFolderModal,
            onActiveFolderDelete: () => {
              deleteFileFolder(activeFolder.reference).then(() => {
                store.initializeStore(true);
              });
            },
          })}
        />
      )}
    </div>
  );
});

type Callback<T, K = FileFolder> = (row: K) => T;
type ColumnContentProps = {
  row: FileFolder;
  isSelected: Callback<boolean>;
  isExpanded: Callback<boolean>;
  isLoading: Callback<boolean, string>;
  resolveIcon: Callback<IconNames>;
  toggleExpanded: Callback<() => void>;
  toggleSelect: Callback<{
    onClick: () => void;
  }>;
};
export const ColumnContent = ({
  row, isExpanded, isLoading, isSelected, resolveIcon, toggleExpanded, toggleSelect,
}: ColumnContentProps) => {
  const calcIndent = (_row?: FileFolder) => {
    if (!_row?.parent) {
      return 0;
    }
    const indent = calcIndent(_row.parent);
    return indent + 60;
  };

  return (
    <div style={{ marginLeft: calcIndent(row) }}
         className={classnames(`${cssClass}__treeview-handle`,
           isSelected(row) && `${cssClass}__treeview-handle--selected`,
           row.parent && isExpanded(row.parent) && `${cssClass}__treeview-handle--expanded`)}
    >
      {row.type === FileFolderType.FILE ? (
        <Text tag="span" className={`${cssClass}__treeview--empty`} caption={row.name} />
      ) : (
          <>
            { isLoading(row.reference) ? <Loader size="xs" />
              : <Icon onClick={toggleExpanded(row)}
                      className={classnames(`${cssClass}__treeview__arrow-icon`)}
                      name="dropdownArrowRight"
                      rotate={isExpanded(row) ? 90 : null}
                />
            }
            <Icon name={resolveIcon(row)} className={classnames(`${cssClass}__icon`)} />
              <div {...toggleSelect(row)}>
                <Text tag="span" caption={row.name} />
              </div>
            </>
      ) }
    </div>
  );
};

type FilePreviewProps = {
  files: File[];
  onDeleteFile: (file: File) => void;
};
const FilesPreview = ({ files, onDeleteFile }: FilePreviewProps) => (
  <Table
      noBorder
      data={files}
      className={`${cssClass}__files-preview`}
      columnMetadata={
        [
          'modules.contentmanagement.uploadableFiles',
          'modules.contentmanagement.fileSize',
          '',
        ]}
      cellRenderer={(_, file: File) => (
        <Table.DeletableRow>
          <Table.ColumnText caption={file.name} />
          <Table.ColumnText caption={`${file.size}`} />
          <Table.ColumnDelete onDelete={() => onDeleteFile(file)} />
        </Table.DeletableRow>
      )}
    />
);

type NewDirectoryName = {
  newDirectoryName: string;
};
type FileManagerAddFolderProps = {
  parentFolder?: string;
  onFolderCreated: () => void;
};
export const FileManagerAddFolder = ({
  parentFolder, onFolderCreated,
}: FileManagerAddFolderProps) => {
  const { submit } = useForm(FILEMANAGER_MODAL_ADD_FOLDER_FORM);
  const { closeModal } = useModal(FILEMANAGER_MODAL_ADD_FOLDER);

  const addFolder = addProgressBar(async ({ newDirectoryName }: NewDirectoryName) => {
    const modifiedNewDirectoryName = removeSpace(newDirectoryName, '-');
    const res = await fetchData({
      url: `${config.apiEndpoints.cms}/dam/${parentFolder ?? ''}`,
      method: 'POST',
      body: {
        newDirectoryName: modifiedNewDirectoryName,
      },
    });

    if (res.success) {
      closeModal();
      onFolderCreated();
    }
    return res;
  });

  return (
    <Modal
      size="small"
      name={FILEMANAGER_MODAL_ADD_FOLDER}
      caption={`modules.contentmanagement.${parentFolder ? 'addSubFolder' : 'addFolder'}`}
      closeOnUnmount
      footerOptions={[
        { caption: 'generic.button.save', formToSubmit: FILEMANAGER_MODAL_ADD_FOLDER_FORM },
      ]}
    >
      <Form
        name={FILEMANAGER_MODAL_ADD_FOLDER_FORM}
        onSubmitHandler={addFolder}
        mapErrorsToFields={(errors: {newDirectoryName: string[]}) => ({
          newDirectoryName: errors.newDirectoryName.length
            ? errors.newDirectoryName[0] : 'generic.formsValidation.formSubmissionFailed',
        })}
      >
        <TextField
            name="newDirectoryName"
            caption="modules.contentmanagement.folderName"
            placeholder="modules.contentmanagement.enterFolderName"
            grid
            validate={[folderTextValidator]}
            onKeyPress={(e) => {
              if (e.which === RETURN_KEY || e.charCode === RETURN_KEY) {
                e.preventDefault();
                submit();
              }
            }}
          />
      </Form>
    </Modal>
  );
};

type PreviewProps = {
  preview: FileFolder;
  fileSelectable: boolean;
  onPreviewSelect?: () => void;
  onPreviewDelete: () => void;
};

const PreviewFile = ({
  preview, fileSelectable, onPreviewSelect, onPreviewDelete,
}: PreviewProps) => {
  const { prompDelete } = useFileManagerContext();
  const [clipboardCopyFeedback, setClipboardCopyFeedback] = React.useState<boolean>(false);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(preview.url).then(() => {
      setClipboardCopyFeedback(true);
    }).finally(() => {
      setTimeout(() => setClipboardCopyFeedback(false), 5000);
    });
  };
  return (
    <div className={`${cssClass}__preview`}>
      <div className={`${cssClass}__preview-content`}>
        <Img className={`${cssClass}__preview-img`} src={preview.url} height="" width="" />
      </div>
      <div className={`${cssClass}__preview-info`}>
        <Text caption={preview.name} className={`${cssClass}__preview-filename`} tag="span" />
        <Text caption={preview.reference} className={`${cssClass}__preview-reference`} tag="span" />
        <Button className={`${cssClass}__copy-to-clipboard`} onClick={handleCopyToClipboard} link>
          <Text caption={i18n.t('generic.copyToClipboard')} tag="span" />
          {clipboardCopyFeedback && (
            <Text
              className={`${cssClass}__copy-to-clipboard__success`}
              caption={i18n.t('generic.copyToClipboardSuccess')}
              tag="span"
            />
          )}
        </Button>
        <Button
          className={`${cssClass}__preview-delete`}
          caption="generic.delete"
          link
          onClick={prompDelete(FileFolderType.FILE, onPreviewDelete)}
        />
        {fileSelectable && (
          <div className={`${cssClass}__preview-select`}>
            <Button caption="modules.contentmanagement.selectFile" onClick={onPreviewSelect} />
          </div>
        )}
      </div>
    </div>
  );
};
