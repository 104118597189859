import * as React from 'react';
import { i18n } from 'i18n';
import { ReadOnlyField } from '../ReadOnlyField';
import { INumericFieldPrimitive } from './NumericFieldPrimitive.interfaces';

const NumericFieldPrimitive = (props: INumericFieldPrimitive) => {
  const {
    className,
    disabled = false,
    name,
    placeholder,
    readOnly = false,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    value,
    step = 1,
    min,
    max,
    id,
    ariaLabel,
    caption,
  } = props;

  return readOnly ? (
    <ReadOnlyField value={value} placeholder={placeholder} className={className} />
  ) : (
    <input
      type="number"
      disabled={disabled}
      name={name}
      id={id ?? name}
      placeholder={placeholder}
      // If min is 0, then do a null check
      {...(((min === 0 && value !== null) || value) && { value: Number.isNaN(value) ? '' : value })}
      step={step}
      min={min}
      max={max}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      className={className}
      pattern="[0-9]*"
      inputMode="numeric"
      aria-label={ariaLabel || i18n.t(caption) || i18n.t('generic.ariaLabel.number')}
    />
  );
};

export default NumericFieldPrimitive;
