export default (
  state,
  payload: {
    calendarName: string;
    eventId: string;
  }
) => {
  const { calendarName, eventId } = payload;

  return {
    ...state,
    [calendarName]: {
      ...state[calendarName],
      settings: {
        ...state[calendarName]?.settings,
        selectedEvent: eventId
      }
    }
  };
};
