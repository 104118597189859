import { connect } from 'react-redux';
import Modal from './ModalBulkEditSlot';
import { actions } from '../../store';

export interface Actions {
  saveSlot: () => any;
}

const mapDispatchToProps = (dispatch): Actions => ({
  saveSlot: () => dispatch(actions.updateCalendarSlots()),
});

export default connect(null, mapDispatchToProps)(Modal);
