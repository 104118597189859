import * as React from 'react';
import config from 'config';
import { fetchData } from 'framework/api/fetch-data';

export interface Country {
  name: string;
  code: string;
  threeLetterCode: string;
  numeric: string;
}

const useCountries = () => {
  const [countries, setCountries] = React.useState([]);
  let isAborted = false;
  const fetchCountries = async () => {
    const { data, success } = await fetchData<Country[]>({
      url: `${config.apiEndpoints.referencedata.countries}`,
    });
    if (!isAborted) {
      setCountries(success ? data : []);
    }
  };

  React.useEffect(() => {
    fetchCountries();
    return () => {
      isAborted = true;
    };
  }, []);

  return countries;
};

export { useCountries };
