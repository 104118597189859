import { fetchData } from 'framework/api/fetch-data';

/**
 * @deprecated Use makeRequest instead
 * @param requestDetails
 * @param includeResponseDetails
 */
export function getApiClientData(requestDetails, includeResponseDetails = false) {
  return fetchData({
    ...requestDetails,
    method: requestDetails.method || 'get'
  })
    .then(response => {
      if (includeResponseDetails) {
        return {
          success: response.success,
          data: response.data
        };
      }
      return response.data;
    })
    .catch(error => {
      return {
        hasError: true,
        message: error.message || 'Default error message here', // TODO: figure this out
        response: error.response
      };
    });
}
