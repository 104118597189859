import { setOpenTab, removeTabs } from 'framework/components/ui/Tabs/Tabs.actions';
import { ITabSteps } from './TabSteps.interfaces';
import { getTabStepsInfo } from './TabSteps.selector';
import {
  initStore,
  completeCurrentStep,
  clearStore,
  updateCurrentStepIndex,
} from './TabSteps.actions';

export const mapState = (state, ownProps) => ({
  tabstepsInfo: getTabStepsInfo(state, ownProps.id),
});

export const mapDispatch = (dispatch) => ({
  // add new tabsteps data to redux
  initStore: (steps: ITabSteps) => {
    dispatch(initStore(steps));
  },

  // mark current step as completed
  completeCurrentStep: (id: string) => {
    dispatch(completeCurrentStep(id));
  },

  // display next step when previous step has been completed
  displayCurrentStep: (tabsId, tabId, keepAnchors) => {
    dispatch(setOpenTab(tabsId, tabId, keepAnchors));
  },

  // remove inner Tabs data from redux
  removeTabs: (tabsId) => {
    dispatch(removeTabs(tabsId));
  },

  // remove tabsteps data from redux
  clearStore: (id: string) => {
    dispatch(clearStore(id));
  },

  updateCurrentStepIndex: (payload: { tabsId: string; stepId: any }) => {
    dispatch(updateCurrentStepIndex(payload));
  },
});
