import store from 'framework/store';
import { useDispatch } from 'framework/utils/useDispatch';
import { completeCurrentStep, goToPreviousStep } from '../TabSteps/TabSteps.actions';
import { setOpenTab } from './Tabs.actions';
import { getOpenTab } from './Tabs.selectors';

const useTabs = (tabGroup: string) => {
  const dispatch = useDispatch();

  return {
    getOpenedTab: (): string => getOpenTab(store.getState(), tabGroup),
    openTab(tabId: string) {
      dispatch(setOpenTab(tabGroup, tabId));
    },
    tabSteps: {
      // go to next tab if not at the last tab
      goToNextTab() {
        dispatch(completeCurrentStep(tabGroup));
      },
      // go to previous tab if not at the fist tab
      goToPreviousTab() {
        dispatch(goToPreviousStep(tabGroup));
      },
      getTabStepsInfo: () => store.getState().tabsteps.find((tabs) => tabs.id === tabGroup),
      // returns true if the currentStep is the length of the steps array
      isCurrentFinalStep: (): boolean => {
        const tabStepsInfo = store.getState().tabsteps.find((tabs) => tabs.id === tabGroup);
        return tabStepsInfo?.currentStep === tabStepsInfo?.steps?.length - 1;
      }
    }
  };
};

export { useTabs };
