/* eslint-disable no-nested-ternary */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Header, Icon, MessageList } from 'ui';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { IModalProps } from './Modal.interface';
import Footer from './Modal.footer';
import { modalClass } from './constants';
import './_style.scss';
import { IModalEventActionType } from '../../../applicationMonitoring/interface';

const ESC_KEY = 27;

export class Modal extends React.Component<IModalProps> {
  static defaultProps: Partial<IModalProps> = {
    canClose: true,
    onDismiss: () => ({}),
    size: 'large',
    closeOnUnmount: true,
    forceClose: true,
  };

  private hasTrackedOpen = false;

  private hasTrackedClose = false;

  eventHandlerAttached = false;

  componentDidUpdate() {
    const { isVisible, totalModalsOpen } = this.props;
    if (!this.eventHandlerAttached && isVisible) {
      document.addEventListener('keydown', this.onKeyDown);
      this.eventHandlerAttached = true;
    }
    if (this.eventHandlerAttached && !isVisible) {
      document.removeEventListener('keydown', this.onKeyDown);
      this.eventHandlerAttached = false;
    }

    // Toggle body class
    if (isVisible) {
      this.sendTrackingEvent('open');
      document.body.classList.add(`${modalClass}--opened`);
    } else if (totalModalsOpen === 0) {
      document.body.classList.remove(`${modalClass}--opened`);
    }
  }

  componentWillUnmount() {
    const { totalModalsOpen, closeOnUnmount, forceClose } = this.props;
    if (totalModalsOpen === 0) {
      document.removeEventListener('keydown', this.onKeyDown);
      document.body.classList.remove(`${modalClass}--opened`);
    }

    if (closeOnUnmount && forceClose) {
      this.willCloseModal();
    }
  }

  sendTrackingEvent = (action: IModalEventActionType) => {
    const { name } = this.props;
    if (action === 'open' && !this.hasTrackedOpen) {
      ApplicationMonitoring.modalViewEvent(name, 'open');
      this.hasTrackedOpen = true;
      this.hasTrackedClose = false;
    }
    if (action === 'close' && !this.hasTrackedClose) {
      ApplicationMonitoring.modalViewEvent(name, 'close');
      this.hasTrackedOpen = false;
      this.hasTrackedClose = true;
    }
  };

  onKeyDown = (e) => {
    const { isVisible, canClose } = this.props;
    if (canClose && e.keyCode === ESC_KEY && isVisible) {
      this.sendTrackingEvent('close');
      this.willCloseModal();
    }
  };

  willCloseModal = (e?: any) => {
    const { onDismiss, closeModal, forceClose } = this.props;
    onDismiss(e);
    if (forceClose) {
      closeModal();
    }
  };

  onDismissClickHandler = (e?: any) => {
    const { canClose } = this.props;
    if (canClose) {
      this.sendTrackingEvent('close');
      this.willCloseModal(e);
    }
  };

  render() {
    const {
      caption,
      captionDetails,
      children,
      className,
      classNameContent,
      isVisible,
      size,
      messages,
      footerOptions,
      footerComponent,
      headerControls: ModalHeaderControls,
      canClose,
      modalHeader: ModalHeader,
      protectedBackgroundClose,
      headerOrientation = 'horizontal',
      modalOverflow,
    } = this.props;

    const headerControlsFunc = typeof ModalHeaderControls === 'function';

    return isVisible
      ? ReactDOM.createPortal(
          <div
            className={classnames(
              modalClass,
              `${modalClass}--${size} `,
              messages && `${modalClass}--hasMessages`,
              className
            )}
          >
            {!protectedBackgroundClose && (
              <div
                className={`${modalClass}__overlay-close`}
                onClick={this.onDismissClickHandler}
                onKeyDown={this.onKeyDown}
                role="button"
                tabIndex={0}
                aria-label="Close modal"
              />
            )}
            <div
              className={classnames(
                `${modalClass}__content`,
                classNameContent,
                modalOverflow && `${modalClass}__modal_overflow`
              )}
            >
              {canClose ? (
                <Icon
                  name="close"
                  size="sm"
                  className={`${modalClass}__dismiss-icon`}
                  onClick={this.onDismissClickHandler}
                />
              ) : (
                <></>
              )}
              <div className={`${modalClass}__header`}>
                {ModalHeader ? (
                  typeof ModalHeader === 'function' ? (
                    <ModalHeader />
                  ) : (
                    ModalHeader
                  )
                ) : (
                  <>
                    <Header
                      caption={caption}
                      headerLevel={2}
                      className={`${modalClass}__header-title`}
                      captionDetails={captionDetails}
                      orientation={headerOrientation}
                    />
                    {headerControlsFunc ? <ModalHeaderControls /> : ModalHeaderControls}
                  </>
                )}
              </div>
              <MessageList className={`${modalClass}__messages`} messages={messages} />
              {size === 'large' && !messages && <hr className={`${modalClass}__spacer stretchWidth`} />}
              {children}
            </div>
            <Footer options={footerOptions} Component={footerComponent} />
          </div>,
          document.getElementById('mwg-modal-root')
        )
      : null;
  }
}

export default Modal;
