import { put, select, call } from 'redux-saga/effects';
import { toastListActions, formActions } from 'framework/actions';
import { formSelectors } from 'framework/selectors';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest } from 'framework/api/make-request';
import { config } from 'config';
import { fixPreviewUrl } from '../utils/utils.fixPreviewUrl';
import { getLoadedPage, processFormErrors } from './_SagaUtils';
import { PageDocument } from '../contentmanagement.interfaces';
import * as constants from '../contentemanagement.constants';
import { storePageDocumentAction } from '../actions';

function* process() {
  const loadedPage: PageDocument = yield select(getLoadedPage);
  const formValues = yield select(
    formSelectors.getFormValues,
    constants.CMSPAGE_TAB_EDITDETAIL_FORM,
  );
  const {
    referenceName, url, varyBy, displayName, title,
  } = formValues;

  const urlValue = url ? fixPreviewUrl(url) : undefined;

  // save document related data
  let response = yield call(makeRequest, {
    body: {
      type: 'page',
      referenceName,
      url: urlValue,
      title,
    },
    method: 'PUT',
    name: constants.ACTION_CMS_SAVE_PAGEDETAILS_DOCUMENT_DATA,
    url: `${config.apiEndpoints.cms}/documents/${loadedPage.documentId}`,
  });

  // save variant related data
  if (!response.error) {
    response = yield call(makeRequest, {
      body: {
        variant: {
          varyBy,
          displayName,
          language: loadedPage.language,
          storeHierarchyNodes: loadedPage.storeHierarchyNodes,
        }
      },
      method: 'PUT',
      name: constants.ACTION_CMS_SAVE_PAGEDETAILS_VARIANT_DATA,
      url: `${config.apiEndpoints.cmsDocuments}/documents/${loadedPage.documentId}/variants/${
        loadedPage.variantId
      }`,
    });

    if (!response.error) {
      yield put(toastListActions.addMessage('generic.data.savedSuccess'));
      // store redux store
      yield put(
        storePageDocumentAction({
          referenceName,
          url: urlValue,
          displayName,
          varyBy,
          title,
        }),
      );

      yield put(formActions.reset(constants.CMSPAGE_TAB_EDITDETAIL_FORM));
    }
  }

  if (response.error) {
    yield processFormErrors(response.error, constants.CMSPAGE_TAB_EDITDETAIL_FORM, [
      'displayName',
      'referenceName',
      'url',
    ]);
  }
}

export default function* sagaPageDetails() {
  yield takeEveryWithProgressBar(constants.ACTION_CMS_SAVE_PAGEDETAILS, process);
}
