/* eslint-disable import/no-cycle */
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { goToRoute } from 'framework/components/navigation/service';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { CREATE_URL_PARAM, STORES_PATH } from './common/constants';
import { StoreHierarchyTranslations, StoresTranslations } from './translations';
import { constants } from './constants';

export const storeClaims = {
  [AuthorizationClaims.STORES_STORE]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_ATTRIBUTE]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_SITE]: UserAccessRights.Read,
  [AuthorizationClaims.SHOPPINGMODE_ADMIN]: UserAccessRights.Read,
  [AuthorizationClaims.SHOPPINGMODE_ADMIN_STORES]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.PRODUCTS_CATEGORY]: UserAccessRights.Read,
  [AuthorizationClaims.DELIVERYAREASADMIN_DELIVERYAREAS]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.ORDER_FULFILLMENT]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.SITESETTINGS_DELIVERYSOLUTIONS]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.ORDERMANAGEMENT_DELIVERYINTEGRATION]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_PROVIDER_CONFIGURATION]: UserAccessRights.ReadWrite,
};

export const storesListRoute: AppRoute = {
  pageTitle: 'modules.stores.storeList.pageTitle',
  menuTitle: 'modules.stores.storeList.menuTitle',
  path: `/storeManagement${STORES_PATH}`,
  authClaim: storeClaims,
  Component: () => import('./pages/StoreList'),
};

export const storesEditRoute: AppRoute = {
  pageTitle: StoresTranslations.EditPageTitle,
  path: `/storeManagement${STORES_PATH}/:id`,
  authClaim: storeClaims,
  Component: () => import('./pages/StoreEdit'),
  parent: storesListRoute,
};

export const storesCreateRoute: AppRoute = {
  pageTitle: 'modules.stores.storeSetup.createPageTitle',
  menuTitle: 'modules.stores.storeSetup.createPageTitle',
  path: `/storeManagement${STORES_PATH}/${CREATE_URL_PARAM}`,
  authClaim: storeClaims,
  Component: () => import('./pages/StoreCreate'),
  parent: storesListRoute,
};

const siteClaims = [AuthorizationClaims.STORES_SITE, { [AuthorizationClaims.STORES_STORE]: UserAccessRights.Read }];

export const sitesListRoute: AppRoute = {
  pageTitle: 'modules.stores.sites',
  menuTitle: 'modules.stores.allSites',
  path: '/storeManagement/allsites',
  authClaim: siteClaims,
  Component: () => import('./pages/SiteList'),
};

export const siteEditRoute: AppRoute = {
  pageTitle: 'modules.stores.editSite',
  menuTitle: 'modules.stores.editSite',
  path: '/storeManagement/sites/edit/:id',
  authClaim: siteClaims,
  parent: sitesListRoute,
  Component: () => import('./pages/SiteEdit'),
};

export const siteCreateRoute: AppRoute = {
  pageTitle: 'modules.stores.newSite',
  menuTitle: 'modules.stores.newSite',
  path: constants.newSiteUrl,
  authClaim: siteClaims,
  parent: sitesListRoute,
  Component: () => import('./pages/SiteEdit'),
};

export const attributeListPageRoute: AppRoute = {
  pageTitle: 'modules.stores.storeAttributeList',
  menuTitle: 'modules.stores.allStoreAttributes',
  path: constants.attributesUrl,
  authClaim: AuthorizationClaims.STORES_ATTRIBUTE,
  Component: () => import('./pages/Attributes'),
};

export const attributeEditPageRoute: AppRoute = {
  pageTitle: 'modules.stores.editAttribute',
  menuTitle: 'modules.stores.editAttribute',
  path: constants.attributeEditUrl,
  authClaim: AuthorizationClaims.STORES_ATTRIBUTE,
  parent: attributeListPageRoute,
  Component: () => import('./pages/Attributes/AttributeEditPage'),
};

export const goToAttributeEditPageRoute = (id: string) => goToRoute(attributeEditPageRoute, { id });

export const attributeNewPageRoute: AppRoute = {
  pageTitle: 'modules.stores.newAttribute',
  menuTitle: 'modules.stores.createStoreAttribute',
  path: constants.attributeCreateUrl,
  authClaim: AuthorizationClaims.STORES_ATTRIBUTE,
  parent: attributeListPageRoute,
  Component: () => import('./pages/Attributes/AttributeEditPage'),
};

export const manageStoreHierarchy: AppRoute = {
  pageTitle: StoreHierarchyTranslations.ManageHierarchy,
  menuTitle: StoreHierarchyTranslations.ManageHierarchy,
  path: constants.manageHierarchyUrl,
  authClaim: [AuthorizationClaims.STORES_STORE, AuthorizationClaims.STORES_ORGHIERARCHY],
  Component: () => import('./pages/OrgHierarchy'),
};

export const storesRoutes: AppRoute[] = [
  storesListRoute,
  storesEditRoute,
  storesCreateRoute,
  sitesListRoute,
  siteEditRoute,
  siteCreateRoute,
  attributeListPageRoute,
  attributeEditPageRoute,
  attributeNewPageRoute,
  manageStoreHierarchy,
];
