import {
  take, fork, select, put,
} from 'redux-saga/effects';
import { actions as apiClientActions, types } from 'framework/api/apiClient';
import { actions as toastListActions } from 'framework/components/ui/ToastList';
import { tabsActions } from 'framework/actions';
import * as constants from '../contentemanagement.constants';
import { ContentManagementState, PageDocument } from '../contentmanagement.interfaces';
import { getLoadedPage, displayMessageErrors } from './_SagaUtils';
import * as cmsActions from '../actions';
import { setInitialContent } from '../TabsNavigation/actions';

const forever = true;

// Save changes made to an existing Page
export default function* sagaCmsContent() {
  while (forever) {
    const action = yield take([
      constants.ACTION_CMS_PAGE_SAVE_CONTENT,
      apiClientActions.API_CLIENT_UPDATE_STATE,
      constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT,
      constants.ACTION_CMS_LOAD_DOCUMENT_FOR_INFO,
    ]);
    yield fork(process, action);
  }
}

function* process(action) {
  switch (action.type) {
    case constants.ACTION_CMS_PAGE_SAVE_CONTENT:
      {
        yield saveOrCreateCmsContent();
      }
      break;

    case constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT:
      {
        yield loadDocumentVariant(action);
      }
      break;

    case apiClientActions.API_CLIENT_UPDATE_STATE:
      {
        if (!action.payload.isFetching && !action.payload.hasError) {
          switch (action.payload.name) {
            case constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT:
              {
                // after load document variant, store it
                yield storeDocumentVariant(action);

                // if page created then display content tab
                const page = yield select((state: ContentManagementState) => ({
                  created: state.contentmanagement.pageCreated,
                }));

                if (page.created) {
                  yield put(
                    tabsActions.setOpenTab(
                      constants.CMS_PAGE_TABS,
                      constants.CMSPAGE_TAB_CMS_CONTENT,
                    ),
                  );
                  yield put(cmsActions.clearPageCreatedFlag());
                }
              }
              break;

            case constants.ACTION_CMS_LOAD_DOCUMENT_FOR_INFO:
              {
                yield loadDocumentVariantContent(action);
              }
              break;

            case constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT_CONTENT:
              {
                yield storeDocumentVariantContent(action);
              }
              break;

            case constants.ACTION_CMS_PAGE_PUBLISH:
              {
                yield put(toastListActions.addMessage('generic.data.savedSuccess'));
                yield put(
                  apiClientActions.clearApiClientStateAction({
                    name: constants.ACTION_CMS_PAGE_PUBLISH,
                  }),
                );
              }
              break;
          }
        } else if (action.payload && action.payload.hasError) {
          yield displayMessageErrors({
            action,
            error: action.payload.message,
            names: [
              constants.ACTION_CMS_PAGE_SAVE_CONTENT,
              constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT,
              constants.ACTION_CMS_LOAD_DOCUMENT_FOR_INFO,
              constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT_CONTENT,
              constants.ACTION_CMS_PAGE_SAVE_CONTENT,
              constants.ACTION_CMS_PAGE_PUBLISH,
            ],
          });
        }
      }
      break;
  }
}

function* saveOrCreateCmsContent() {
  const values: any = yield select((state: ContentManagementState) => ({
    pageId: state.contentmanagement.pageId,
    variantId: state.contentmanagement.variantId,
  }));
  const loadedPage: PageDocument = yield select(getLoadedPage);

  const payload: types.IMakeRequestActionPayloadProps = {
    method: 'put',
    endpoint: constants.PATH_CMS_ENDPOINT,
    endpointPath: `/documents/${values.pageId}/variants/${values.variantId}/content`,
    name: constants.ACTION_CMS_PAGE_SAVE_CONTENT,
  };
  yield put(
    apiClientActions.createApiClientMakeRequestAction({
      ...payload,
      body: {
        cmsContent: loadedPage.cmsContent,
      },
    }),
  );

  yield put(
    apiClientActions.clearApiClientStateAction({
      name: constants.ACTION_CMS_PAGE_SAVE_CONTENT,
    }),
  );
}

function* loadDocumentVariant(action: any) {
  const { pageId, variantId } = action.payload;
  yield put(cmsActions.clearCurrentPageAction());
  yield put(cmsActions.storeCmsPageInfo({ pageId, variantId }));

  // get variant
  yield put(
    apiClientActions.createApiClientMakeRequestAction({
      name: constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT,
      endpoint: constants.PATH_CMS_ENDPOINT,
      endpointPath: `/documents/${pageId}/variants/${variantId}`,
    }),
  );
}

function* storeDocumentVariant(action: any) {
  const {
    documentId,
    displayName,
    language,
    storeHierarchyNodes,
    varyBy,
    status,
    schedule,
    isProtected,
  } = action.payload;

  yield put(
    cmsActions.storePageDocumentAction({
      type: 'Page',
      documentId,
      displayName,
      language,
      storeHierarchyNodes,
      varyBy,
      status,
      schedule,
      isProtected,
    }),
  );

  // load document to retrieve defaultVariantId
  yield loadCmsDocumentForInfo();

  yield put(
    apiClientActions.clearApiClientStateAction({
      name: constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT,
    }),
  );
}

function* loadCmsDocumentForInfo() {
  const values: any = yield select((state: ContentManagementState) => ({
    documentId: state.contentmanagement.loadedPage.documentId,
  }));

  yield put(
    apiClientActions.createApiClientMakeRequestAction({
      name: constants.ACTION_CMS_LOAD_DOCUMENT_FOR_INFO,
      endpoint: constants.PATH_CMS_ENDPOINT,
      endpointPath: `/documents/${values.documentId}`,
    }),
  );
}

function* loadDocumentVariantContent(action) {
  const values: any = yield select((state: ContentManagementState) => ({
    pageId: state.contentmanagement.pageId,
    variantId: state.contentmanagement.variantId,
  }));

  const {
    defaultVariantId, referenceName, url, title,
  } = action.payload;

  yield put(
    cmsActions.storePageDocumentAction({
      defaultVariantId,
      referenceName,
      url,
      title,
    }),
  );

  // get variant content
  yield put(
    apiClientActions.createApiClientMakeRequestAction({
      name: constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT_CONTENT,
      endpoint: constants.PATH_CMS_ENDPOINT,
      endpointPath: `/documents/${values.pageId}/variants/${values.variantId}/content`,
    }),
  );

  yield put(
    apiClientActions.clearApiClientStateAction({
      name: constants.ACTION_CMS_LOAD_DOCUMENT_FOR_INFO,
    }),
  );
}

function* storeDocumentVariantContent(action: any) {
  const { cmsContent = {}, variantId } = action.payload;
  yield put(
    cmsActions.storePageDocumentAction({
      cmsContent,
      variantId,
    }),
  );
  yield put(setInitialContent({
    content: {
      ...cmsContent,
    },
  }));

  yield put(
    apiClientActions.clearApiClientStateAction({
      name: constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT_CONTENT,
    }),
  );
}
