import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import { CheckboxPrimitive as CheckBox } from 'framework/components/ui/FormComponents/Primitives/CheckboxPrimitive';
import { Text } from 'ui';
import classNames from 'classnames';
import { HierarchyHeadersProps } from './hierarchy.interfaces';

const hierarchyClassName = conf.prefixClassName('hierarchy');

export const HierarchyTableHeaders = (props: HierarchyHeadersProps) => (
  <thead>
    <tr className={`${hierarchyClassName}__header-row`}>
      {props.columns.map((column, i) => {
        let HeaderColumnHandle: any = column.title ? <Text caption={column.title} /> : null;
        if (i === 0) {
          if (props.selectable && !props.singleSelect) {
            HeaderColumnHandle = (
              <div className={`${hierarchyClassName}__header-select`}>
                <CheckBox
                  value={props.allSelected}
                  onChange={props.handleAllSelected}
                  className={`${hierarchyClassName}__header-select__chkbox`}
                  name="hierarchySelectAll"
                />
                {HeaderColumnHandle}
              </div>
            );
          } else {
            HeaderColumnHandle = (
              <div className={`${hierarchyClassName}__header-text`}>{HeaderColumnHandle}</div>
            );
          }
        }
        return (
          <th
            key={i}
            className={classNames(`${hierarchyClassName}__th-header`, column.className)}
          >
            {HeaderColumnHandle}
          </th>
        );
      })}
    </tr>
  </thead>
);
