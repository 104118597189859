import * as React from 'react';
import * as classnames from 'classnames';
import { Icon } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IReadOnlyField } from './ReadOnly.interfaces';

const clx = prefixClassName('form-field');

const ReadOnlyField = React.memo((props: IReadOnlyField) => {
  const {
    className,
    classNameField,
    value,
    placeholder,
    icon,
    labelledby,
  } = props;
  const elementProps = labelledby ? { 'aria-labelledby': labelledby } : {};

  return (
    <div className={className} {...elementProps}>
      <span
        className={classnames(
          clx,
          `${clx}--read-only`,
          !value && `${clx}--isEmpty`,
          classNameField,
        )}
      >
        {value || placeholder}
        {icon && <Icon name={icon} className={`${clx}--read-only__icon`} />}
      </span>
    </div>
  );
});

export default ReadOnlyField;
