import { connect } from 'react-redux';
import { actions } from 'framework/modules/searchadmin/common/Rules/redux';
import { getSelectValue } from 'framework/components/ui/FormComponents/Select';
import { getIsFetching } from 'framework/api/apiClient/selectors';
import { API_DATA_RULE_CURRENT_DATA, API_DATA_RULE_PREVIEW_DATA } from 'framework/modules/searchadmin/constants';
import PreviewContent from './PreviewContent';
import { selectors } from '../../redux';

const mapStateToProps = (state) => ({
  showGrids: !!selectors.getFormValues(state).searchForProducts,
  searchTerm: selectors.getFormValues(state).searchForProducts,
  previewCurrentData: selectors.getProductsCurrent(state),
  previewData: selectors.getProductsPreview(state),
  previewStoreId: getSelectValue(selectors.getFormValues(state).previewStoreId),
  isLoading: getIsFetching(state, API_DATA_RULE_PREVIEW_DATA) || getIsFetching(state, API_DATA_RULE_CURRENT_DATA),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPreviewCurrentData: (data) => {
    dispatch(actions.fetchRuleProducts(data));
  },
  clearPageData: () => dispatch(actions.clearPageData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewContent);
