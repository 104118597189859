import { put, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { modalActions } from 'framework/actions';
import {
  CONFIRMATION_MODAL_NAME,
  CONFIRMATION_MODAL_ACTION,
} from 'framework/components/ui/ConfirmationModal/constants';

/**
 * Shows a confirmation dialog before executing the action. Usage:
 *
 * function* deleteData(arg1, arg2) { ... }
 *
 * const deleteDataWithConfirmation = withConfirmation(deleteData);
 *
 * deleteDataWithConfirmation(1, 2); // will request the user's confirmation before executing
 * @param generator generator function - your process to be wrapped by a progress bar
 */
export function withConfirmation(generator) {
  return function* withConfirmationG(...args) {
    // 1. Show confirmation modal
    yield put(modalActions.createOpenModalAction(CONFIRMATION_MODAL_NAME));

    // 2. Waits for the user's selection
    const { confirmation } = yield take(CONFIRMATION_MODAL_ACTION);

    // 3. Closes the modal
    yield put(modalActions.createCloseModalAction(CONFIRMATION_MODAL_NAME));

    // 4. If the user confirmed, excutes the generator passed
    if (confirmation) {
      yield* generator(...args);
    }
  };
}

export function withConfirmationDelayedClose(generator) {
  return function* withConfirmationG(...args) {
    // 1. Show confirmation modal
    yield put(modalActions.createOpenModalAction(CONFIRMATION_MODAL_NAME));

    // 2. Waits for the user's selection
    const { confirmation } = yield take(CONFIRMATION_MODAL_ACTION);

    // 3. If the user confirmed, excutes the generator passed, close the modal when finished
    if (confirmation) {
      yield* generator(...args);
    }
    // 4. Closes the modal
    yield put(modalActions.createCloseModalAction(CONFIRMATION_MODAL_NAME));
  };
}

// **takeEvery** Spawns a saga on each action dispatched to the Store that matches the action
export function* takeEveryWithConfirmation(action, generator) {
  yield takeEvery(action, withConfirmation(generator));
}

// **takeLeading** Spawns a saga on each action dispatched to the Store that matches the action. After spawning a task once, it blocks until spawned saga completes and then starts to listen for the action again.
export function* takeLeadingWithConfirmation(action, generator) {
  yield takeLeading(action, withConfirmationDelayedClose(generator));
}
