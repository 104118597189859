/* eslint-disable no-nested-ternary */
import { IconNames } from 'framework/components/ui/Icon';
import { FileExtension, MissingData } from './types';

export const FILEMANAGER_MODAL = 'FILEMANAGER_MODAL';
export const FILEMANAGER_MODAL_ADD_FOLDER = 'FILEMANAGER_MODAL_ADD_FOLDER';
export const FILEMANAGER_MODAL_ADD_FOLDER_FORM = 'FILEMANAGER_MODAL_ADD_FOLDER_FORM';
export const FILEMANAGER_MODAL_MOVE_TO_FOLDER = 'FILEMANAGER_MODAL_MOVE_TO_FOLDER';
export const FILEMANAGER_DELETE_CONFIRM_MODAL = 'FILEMANAGER_DELETE_CONFIRM_MODAL';
export const FILEMANAGER_DUPLICATE_FILE_NAME_MODAL = 'FILEMANAGER_DUPLICATE_FILE_NAME_MODAL';
export const FILEMANAGER_FILE_RENAME_MODAL = 'FILEMANAGER_FILE_RENAME_MODAL';

const images: FileExtension[] = ['gif', 'jpg', 'png', 'svg'];
const files: FileExtension[] = ['pdf'];
const texts: FileExtension[] = ['txt'];

export const fileExtensionMapping = ([...images, ...files, ...texts]).reduce<{
  [name in FileExtension]: IconNames
}>((acc, ex) => ({
  ...acc,
  [ex]: images.includes(ex) ? 'image' : (files.includes(ex) ? 'fileDocument' : 'file'),
}), {} as any);

export const missingDataWarning: {
  [name in MissingData] : string
} = {
  [MissingData.TARGET_DIRECTORY]: 'modules.contentmanagement.selectTargetFolder',
  [MissingData.UPLOAD_FILE]: 'modules.contentmanagement.selectUploadFolder',
  [MissingData.PARENT_DIRECTORY]: 'modules.contentmanagement.selectParentFolder',
};
