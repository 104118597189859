import { ColumnMetadata } from 'framework/components/ui/Table/Table.interfaces';

export const LIST_VIEW_TRANSLATION_BASE = 'modules.slotManagement.listView';

export enum ListViewTypes {
  Calendar = 'calendar',
  Templates = 'Templates',
}

export const listViewColumnMetadata = (
  type: ListViewTypes,
  selectedSlots,
  setSelectedSlots,
  sortedEvents
): ColumnMetadata => [
  ...(type === ListViewTypes.Templates
    ? [
        {
          caption: {
            component: 'Checkbox',
            componentOptions: {
              id: 'checkboxHeader',
              name: 'all',
              onChange: (e) => {
                setSelectedSlots(e.target.checked ? sortedEvents : []);
              },
              value: () => selectedSlots?.length === sortedEvents?.length,
            },
          },
        },
      ]
    : []),
  ...(type === ListViewTypes.Calendar
    ? [
        {
          caption: {
            component: 'Checkbox',
            componentOptions: {
              id: 'checkboxHeader',
              name: 'all',
              onChange: (e) => {
                setSelectedSlots(e.target.checked ? sortedEvents : []);
              },
              value: () => selectedSlots?.length === sortedEvents?.length,
            },
          },
        },
      ]
    : []),
  {
    caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.time`,
    id: 'time',
  },
  {
    caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.shoppingMode`,
    id: 'shoppingMode',
  },
  {
    caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.fulfillmentProvider`,
    id: 'time',
  },
  ...(type === ListViewTypes.Calendar
    ? [
        {
          caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.ordersPlaced`,
          id: 'ordersPlaced',
        },
      ]
    : []),
  {
    caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.totalCapacity`,
    id: 'totalCapacity',
  },
  ...(type === ListViewTypes.Calendar
    ? [
        {
          caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.remainingCapacity`,
          id: 'remainingCapacity',
        },
      ]
    : []),
  {
    caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.price`,
    id: 'price',
  },
  {
    caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.tags`,
    id: 'tags',
  },
  {
    caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.cutOff`,
    id: 'cutOff',
  },
  ...(type === ListViewTypes.Calendar
    ? [
        {
          caption: '',
          id: 'closedSlot',
        },
      ]
    : []),
  ...(type === ListViewTypes.Calendar
    ? [
        {
          caption: `${LIST_VIEW_TRANSLATION_BASE}.columns.closingNote`,
          id: 'closingNote',
        },
      ]
    : []),
];
