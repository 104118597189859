/* eslint-disable no-restricted-syntax */
import { i18n } from 'i18n';
import * as isEqual from 'lodash.isequal';

// General
export const isDate = (v) => v && v instanceof Date;
export const isString = (v) => v && (typeof v === 'string' || v instanceof String);
export const isObject = (v) => v && v.constructor === Object;
export const isArray = (v) => v && Array.isArray(v);
export const isFunction = (v) => v && typeof v === 'function';
// eslint-disable-next-line no-restricted-globals
export const isValidDate = (d: any) => !isNaN(d) && d instanceof Date;

// Array helpers
const insertElementToArray = (array, element, index) => array.splice(index, 0, element);

const compareAsc =
  (key: string) =>
    (a: any, b: any): number => {
      let result = 0;
      if (a[key] < b[key]) {
        result = -1;
      } else if (a[key] > b[key]) {
        result = 1;
      }
      return result;
    };

const compareDesc =
  (key: string) =>
    (a: any, b: any): number => {
      let result = 0;
      if (a[key] < b[key]) {
        result = 1;
      } else if (a[key] > b[key]) {
        result = -1;
      }
      return result;
    };

// Object helpers
export const ObjectIsEmpty = (obj: object) => !!(Object.keys(obj).length === 0 && isObject(obj));

export const  checkKeyValuePairsInMainObject = (mainObj, secondObj) => {
  return Object.entries(secondObj).every(([key, value]) => {
    // eslint-disable-next-line no-prototype-builtins
    return mainObj.hasOwnProperty(key) && mainObj[key] === value;
  });
};

const objectHelper = {
  isEmpty: ObjectIsEmpty,
};

export { compareAsc, compareDesc, insertElementToArray, objectHelper };

// Number helpers
export const truncateDecimals = (val: number | string, decimalsCount: number) => {
  const regx = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalsCount}})?`);
  return val.toString().match(regx);
};
export const gaussianRounding = (value: number, precision: number = 2) => {
  const decimals = precision || 0;
  const m = 10 ** decimals;
  const expo = +(decimals ? value * m : value).toFixed(8); // Avoid rounding errors
  const integer = Math.floor(expo);
  const fraction = expo - integer;
  const e = 1e-8; // Allow for rounding errors in f
  let r: number;
  if (fraction > 0.5 - e && fraction < 0.5 + e) {
    r = integer % 2 === 0 ? integer : integer + 1;
  } else {
    r = Math.round(expo);
  }
  return decimals ? r / m : r;
};

/**
 * Checks if all the items in the arrays are the same, independent of order
 */
export const hasSameItems = (arr1: any[], arr2: any[], sortFn = undefined) =>
  isEqual([...arr1].sort(sortFn), [...arr2].sort(sortFn));

/**
 * Returns a flat array from the provided array of objects with children
 * @param nodes array of objects, should contain a children property
 * @param identifier string used to identify what property holds the children array
 */
export const flattenArray = <T extends {}>(nodes: T[], identifier: string): T[] => {
  let result: T[] = [];

  nodes.forEach((a) => {
    result.push(a);
    if (Array.isArray(a[identifier])) {
      result = result.concat(flattenArray(a[identifier], identifier));
    }
  });

  return result;
};

/**
 * Use this function to check for objects that have been edited
 * @param originalArray array containing the original objects
 * @param newArray array containing objects that could've been edited
 * @param identifier object property to match objects from both arrays (must be unique)
 * @param deletedItems array containing items to delete from the originalArray and not compare
 * @param checkNewItems true to allow checking for new items, false to not include new items in returned array
 * @returns edited objects
 */
export const checkEditedObjects = (
  originalArray: any[],
  newArray: any[],
  identifier: string,
  deletedItems?: any[],
  checkNewItems: boolean = true
): any[] => {
  const editedItems: any[] = [];

  // remove items that were deleted from original array (only if provided)
  // avoids checking for changes in deleted objects
  const itemsToCompare: any[] = originalArray?.filter(
    (d) => !deletedItems?.map((s) => s[identifier]).includes(d[identifier])
  );

  itemsToCompare.forEach((item) => {
    const compareItem: any = newArray.find((compare) => item[identifier] === compare[identifier]);

    if (compareItem && !isEqual(item, compareItem)) {
      editedItems.push(compareItem);
    }
  });

  if (checkNewItems) {
    // check for new items and add them to editedItems
    newArray.forEach((item) => {
      const foundItem: any = originalArray.find((compare) => item[identifier] === compare[identifier]);

      // if the item in the newArray is not found in the originalArray, then it is new
      if (!foundItem) {
        editedItems.push(item);
      }
    });
  }

  return editedItems;
};

// file helpers
export const renameFile = (file: File, newFileName: string) => {
  return new File([file], newFileName, {
    type: file.type,
    lastModified: file.lastModified,
  });
};

export const convertBooleanToYesNo = (booleanValue: any): string =>
  booleanValue === 'true' || booleanValue === true ? i18n.t('generic.yes') : i18n.t('generic.no');

export const capitalizeFirstLetter = (text: string): string => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return '';
};

export const isStringsArray = (arr) => {
  if (Array.isArray(arr)) {
    return arr.every((i) => typeof i === 'string');
  }
  return false;
};

// replace blank spaces with the dash
export const removeSpace = (text: string, symbol: string): string => text.replace(/ /g, symbol);

export const removeSpaceAndSpecialCharacter = (string) => {
  return string
    .replace(/[^\w\s.-]/gi, '') // remove special characters
    .trim() // remove leading and trailing whitespaces
    .replace(/\s+/g, '-'); // replace spaces with dash
};

export const safelyDecode = (str: string): string => {
  try {
    return decodeURI(str);
  } catch (e) {
    return str;
  }
}