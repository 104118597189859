import { call, put, delay } from 'redux-saga/effects';
import { config } from 'config';
import { calendarActions } from 'framework/actions';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { withProgressBar } from 'framework/sagas/extensions/progress';
import { takeEveryWithConfirmation } from 'framework/sagas/extensions/confirmation';
import { toastError, toastSuccess } from 'stores/toast';
import { CALENDAR_NAME } from '../../../constants';
import { VIEWCALENDAR_SAVE_DATA } from '../../actions.constants';
// eslint-disable-next-line import/no-cycle
import { actions } from '../..';
// eslint-disable-next-line import/no-cycle
import { dataFormated } from './_helpers';

export function* process() {
  // 1. Show Loaders - Calendar
  yield put(calendarActions.setLoader(CALENDAR_NAME, true));

  // 2. Make Request
  const data = yield call(dataFormated);
  const responseData = yield call(makeRequestAndSaveState, {
    name: 'saveDataTimeSlot',
    method: 'PUT',
    url: config.apiEndpoints.slotCalendar,
    body: data,
  });

  if (responseData.success) {
    // 3. Load updated content
    yield put(toastSuccess('modules.slotManagement.storeCalendar.calendarUpdateSuccess'));
    yield delay(2000);
    yield put(actions.fetchCalendarDataAction());
    yield put(calendarActions.reset(CALENDAR_NAME));
  } else {
    yield put(calendarActions.setLoader(CALENDAR_NAME, false));

    const blockingSlotOverlapError = 'One or more blocking slot overlaps with another blocking slot';
    yield put(
      toastError(
        responseData?.error?.title === blockingSlotOverlapError
          ? blockingSlotOverlapError
          : 'modules.slotManagement.storeCalendar.calendarUpdateError'
      )
    );
  }
}

export default function* watchFetchSlotTemplatesAction() {
  yield takeEveryWithConfirmation(VIEWCALENDAR_SAVE_DATA, withProgressBar(process));
}
