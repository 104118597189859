export enum SynonymTranslations {
  Synonyms = 'modules.merchandising.synonyms.list.title',
  AllSynonyms = 'modules.merchandising.synonyms.list.titleMenu',
  CreateSynonym = 'modules.merchandising.synonyms.create.titleMenu',
  CreateSynonymBtn = 'modules.merchandising.synonyms.create.titleMenu',
  ApplyPendingChanges = 'modules.merchandising.synonyms.list.btnApplyPendingChanges',
  FilterByKeyword = 'modules.merchandising.synonyms.list.filters.keywordPlaceholder',
  Synonym = 'modules.merchandising.synonyms.list.dataGrid.synonym',
  Status = 'modules.merchandising.synonyms.list.dataGrid.status',
  Actions = 'modules.merchandising.synonyms.list.dataGrid.actions',
  ChangeStatusToActive = 'modules.merchandising.synonyms.list.dataGrid.options.enable',
  ChangeStatusToInactive = 'modules.merchandising.synonyms.list.dataGrid.options.disable',
  Delete = 'generic.options.delete',
  ListMessage1 = 'modules.merchandising.synonyms.list.message_01',
  ListMessage2 = 'modules.merchandising.synonyms.list.message_02',
  ApplyPendingChangesInProgress = 'modules.merchandising.synonyms.list.applyingPendingChanges',
  ChangesMade = 'modules.merchandising.synonyms.list.changesMade',
  SaveError = 'modules.merchandising.synonyms.list.saveError',
  SaveSynonym = 'modules.merchandising.synonyms.createAndEdit.content.btnSave',
  SaveChanges = 'modules.merchandising.synonyms.createAndEdit.content.btnSaveChanges',
  DeleteSynonym= 'modules.merchandising.synonyms.createAndEdit.content.btnDeleteSynonym',
  Reset = 'generic.button.reset',
  Cancel = 'generic.button.cancel',
  SynonymDetails = 'modules.merchandising.synonyms.createAndEdit.content.title',
  Locale = 'modules.merchandising.synonyms.createAndEdit.content.fieldLocaleLabel',
  SynonymsLabel = 'modules.merchandising.synonyms.createAndEdit.content.equivalent.synonyms.label',
  SynonymsPlaceholder = 'modules.merchandising.synonyms.createAndEdit.content.equivalent.synonyms.placeholder',
  SynonymsExplainer = 'modules.merchandising.synonyms.createAndEdit.content.equivalent.synonyms.explainer',
  TwoOrMoreTermsRequired = 'modules.merchandising.synonyms.createAndEdit.twoOrMoreTermsRequired',
  Active = 'generic.status.active',
  Inactive = 'generic.status.inactive',
  Pending = 'generic.status.pending',
}
