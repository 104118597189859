import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { hasSameItems } from 'framework/utils/helpers';
import { HierarchyProps } from './hierarchy.interfaces';
import { useHierarchyStore } from './hierarchy.store';
import { HierarchyProvider } from './hierarchy.provider';
import { HierarchyTable } from './hierarchy.table';
import './style.scss';

const clx = prefixClassName('hierarchy');

const useHierarchyTreeView = (props: HierarchyProps) => {
  const store = useHierarchyStore({
    ...props,
    data: props.data || [],
  });

  // update highlighted row
  React.useEffect(() => {
    if (store.highlightedId !== props.highlightedId) {
      store.highlightRow({
        id: props.highlightedId,
      });
    }
  }, [props.highlightedId]);

  React.useEffect(() => {
    if (props.onHighlightRow) {
      props.onHighlightRow(store.highlightedId);
    }
  }, [store.highlightedId]);

  // reset selectedIds
  React.useEffect(() => {
    if (!hasSameItems(store.selectedIds || [], props.selectedIds || [])) {
      store.setSelectedIds(props.selectedIds);
    }
  }, [props.selectedIds]);

  // on check/uncheck node, run callback if provided
  React.useEffect(() => {
    if (props.onSelectionChanged) {
      props.onSelectionChanged(store.selectedIds);
    }
  }, [store.selectedIds]);

  // reset hierarchy data
  React.useEffect(() => {
    if (!hasSameItems(store.data || [], props.data)) {
      store.updateData({ data: props.data });
    }
  }, [props.data]);

  React.useEffect(() => {
    if (props.onExpandCollapseIds) {
      props.onExpandCollapseIds(store.expandedIds || []);
    }
  }, [store.expandedIds]);

  return store;
};

export const HierarchyTreeView = (props: HierarchyProps) => {
  const store = useHierarchyTreeView(props);
  const { stretch = 'panel', height = 'auto', className, hidden = false } = props;

  // prepare context
  const context = {
    ...props,
    ...store,
  };

  return (
    <div className={`${clx} ${clx}--${stretch} ${clx}--${height} ${hidden && `${clx}--hidden`} ${className}`}>
      <HierarchyProvider {...context}>
        <HierarchyTable {...props} />
      </HierarchyProvider>
    </div>
  );
};
