import config, { baseApiUrl } from 'config';
import { FeatureState } from './types';
import { fetchData } from '../../framework/api/fetch-data';
import { AuthorizationClaims, ensureClaims } from '../../framework/authorization';

export const isRecommendationsActionFlagsEnabled = async (useApiUrl: boolean = true): Promise<FeatureState> => {
  // This is an administrator account only feature
  const userHasAccess = ensureClaims(AuthorizationClaims.POWER_USER);

  if (!userHasAccess || (!baseApiUrl && useApiUrl)) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData({
    url: `${config.apiEndpoints.featureFlags}/recommendations_action_flags`,
    method: 'HEAD',
  });

  // Status 200 = enabled, 404 = disabled
  return {
    isEnabled: result.status === 200,
  };
};
