import { ITabStepsState, ITabStepsInfo } from './TabSteps.interfaces';

const getTabSteps = (state): ITabStepsState => state.tabsteps;
export const getTabGroups = (state) => state.tabGroups || {};
export const getTabStepsInfo = (state, stepsId): ITabStepsInfo => {
  const tabsteps = getTabSteps(state);
  const steps = tabsteps ? tabsteps.find((t) => t.id === stepsId) : null;
  const tabs = getTabGroups(state)[stepsId];
  if (tabs && steps) {
    steps.openTab = tabs.openTab;
  }
  return steps;
};
