import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { featureFlag } from '../../../featureFlag';
import * as CONST from './constants';
import {
  identityProviders,
  createIdentityProvider,
  deliverySolutions,
  mobileAppSettings,
  storefrontSettings,
  stsSettings,
  storefrontThemes,
  storefrontImages,
  stsImages,
  stsTheme,
  webhookListing,
  createWebhook,
  createShoppingRule,
  orderPrefrencesListing,
  createOrderPreferences,
  storefrontAndMobileAppSettings,
  allSegments,
  createSegment,
} from './routes';
import {
  IdentityProviderTranslations,
  DeliveryProvidersTranslations,
  StorefrontUiTranslations,
  StsTranslations,
  WebhookTranslations,
  ShoppingRuleTranslations,
  OrderPreferencesTranslation,
  MobileAppUiTranslations,
  SharedSettingsTranslations,
  SegmentsTranslations,
} from './translations';

const pageGroups = [
  {
    title: IdentityProviderTranslations.SingleSignOn,
    routes: [identityProviders, createIdentityProvider],
  },
  {
    title: DeliveryProvidersTranslations.DeliveryProviders,
    routes: [deliverySolutions],
  },
  {
    title: SegmentsTranslations.GroupTitle,
    routes: [allSegments, createSegment],
  },
  {
    title: WebhookTranslations.Webhooks,
    routes: [webhookListing, createWebhook],
  },
  {
    title: OrderPreferencesTranslation.OrderPreferences,
    routes: [orderPrefrencesListing, createOrderPreferences],
  },
  {
    title: StorefrontUiTranslations.GroupTitle,
    hideFromRoleAssociation: true,
    routes: [
      storefrontSettings,
      ...featureFlag({
        dev: () => [],
        prod: () => [],
        support: () => [storefrontThemes, storefrontImages],
      }),
    ],
  },
  {
    title: MobileAppUiTranslations.GroupTitle,
    hideFromRoleAssociation: true,
    routes: [mobileAppSettings],
  },
  {
    title: SharedSettingsTranslations.GroupTitle,
    hideFromRoleAssociation: true,
    routes: [storefrontAndMobileAppSettings],
  },
];

const SiteSettings: ModuleConfig = {
  name: 'siteSettings',
  appMenuTitle: IdentityProviderTranslations.AppMenuTitle,
  appMenuIcon: 'settings',
  modulePath: CONST.PATH_MAIN,
  pageGroups: async () => {
    return [
      ...pageGroups,
      ...featureFlag({
        dev: () => [
          {
            title: ShoppingRuleTranslations.ShoppingRules,
            routes: [createShoppingRule],
          },
        ],
        prod: () => [],
        support: () => [
          {
            title: StsTranslations.GroupTitle,
            hideFromRoleAssociation: true,
            routes: [stsSettings, stsTheme, stsImages],
          },
        ],
      }),
    ];
  },
};

export default SiteSettings;
