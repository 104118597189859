/* eslint-disable react/prop-types */
import * as React from 'react';
import { Button } from 'ui';
import { store } from '@risingstack/react-easy-state';
import ActionsTemplate from './ActionsTemplate';

export const actionsStore = store<{ fields: any[] }>();

export default (props) => {
  const { fields } = props;

  const addDynamicField = () => {
    fields.push({});
  };

  React.useEffect(() => {
    actionsStore.fields = fields.getAll();
  }, [fields]);

  return (
    <div>
      {fields.map((field, index, fieldsRef) => (
        <ActionsTemplate
          key={field}
          field={field}
          remove={fields.remove}
          fieldIndex={index}
          fieldValues={fieldsRef.get(index)}
          actionFieldOptions={props.actionFieldOptions}
          stores={props.stores}
          conditionFieldOptions={props.conditionFieldOptions}
          setFieldValueToNull={props.setFieldValueToNull}
          actionOperationOptions={props.actionOperationOptions}
          fetchCurrentData={props.fetchCurrentData}
        />
      ))}
      <Button onClick={addDynamicField} caption="modules.merchandising.rules.create.btnAddAction" icon="plus" outline />
    </div>
  );
};
