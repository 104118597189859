import * as CONST from '../constants';

interface CRUDOperationPayload {
  successCallback?: (response:any)=>void;
  errorCallback?: (response:any)=>void;
  refreshResource?: string;
}
export interface CRUDPayload {
  resource:string;
  opType:string;
  payload?: CRUDOperationPayload | any
}
export const createCrudResourceAction = (payload: CRUDPayload, config: any) => ({
  type: CONST.CRUD_RESOURCE_ACTION,
  payload,
  config,
});
