import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ITabGroupProps } from './Tabs.interface';
import * as actions from './Tabs.actions';
import { getOpenTab } from './Tabs.selectors';
import { Tabs } from './Tabs';

const mapStateToProps = (state, ownProps) => ({
  tabGroupId: ownProps.id,
  openTab: getOpenTab(state, ownProps.id),
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onTabChange: actions.setOpenTab,
    onSubTabChange: actions.setOpenSubTab,
    onRegisterAnchor: actions.registerAnchor,
    onUnRegisterAnchor: actions.unRegisterAnchor,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Tabs as any)) as any) as React.StatelessComponent<ITabGroupProps>;
