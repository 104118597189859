import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import Toast from 'framework/components/ui/Toast';
import { IToastListProps } from './ToastList.interfaces';
import './_style.scss';

const clx = prefixClassName('toast-list');

export const ToastList = ({ messages = [], dismissToast }: IToastListProps) => (
  <div className={clx}>
    {messages.map((message) => (
      <Toast
        message={{
          caption: message.caption,
          type: message.type,
          id: message.id,
          onClose: () => dismissToast(message),
        }}
        key={message.id}
      />
    ))}
  </div>
);
