import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ButtonList } from 'framework/components/ui';
import { IToolbarCustomOptions } from './Toolbar.interfaces';
import { navigate } from '../../utils/constants';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions';
import { ViewSelector } from './viewSelector';
import { calToolbarClx } from './constants';
import './_style.scss';

const Toolbar = (props: IToolbarCustomOptions) => {
  const handleNavigate = (e: Date) => {
    // from map state to props
    props.updateSelectorDate(e);
    if (props.onNavigate) {
      props.onNavigate(e);
    }
  };

  const navigateAction = (event?: string) => {
    const { view, date } = props;
    const calDate = date;
    const skips = {
      day: 1,
      week: 7,
      month: 30,
    };

    const skipDays = skips[view] || 0;
    let newDate;

    switch (event) {
      case navigate.NEXT: {
        newDate = moment(calDate).add(skipDays, 'days').toDate();
        break;
      }
      case navigate.PREVIOUS: {
        newDate = moment(calDate)
          .add(-1 * skipDays, 'days')
          .toDate();
        break;
      }
      case navigate.TODAY: {
        newDate = new Date();
        break;
      }
      default: {
        newDate = date || new Date();
      }
    }

    handleNavigate(new Date(newDate));
  };

  const goNext = () => navigateAction(navigate.NEXT);
  const goPrev = () => navigateAction(navigate.PREVIOUS);
  const goToday = () => navigateAction(navigate.TODAY);

  const viewAction = () => {
    navigateAction();
    if (props.onView) {
      props.onView();
    }
  };

  const {
    className = '',
    calendarName,
    show,
    showNavigation = true,
    leftOptions = [],
    rightOptions = [],
    viewOptions,
    setListView,
    view,
    bulkAction,
  } = props;

  return show ? (
    <div className={`${calToolbarClx} ${className}`}>
      {bulkAction}
      <ButtonList buttons={leftOptions} />
      <ViewSelector
        options={viewOptions}
        calendarName={calendarName}
        onChange={viewAction}
        setListView={setListView}
        view={view}
      />
      <ButtonList buttons={rightOptions} />
      {showNavigation && (
        <ButtonList
          buttons={[
            {
              outline: true,
              caption: 'components.calendar.btnToday',
              onClick: goToday,
            },
            {
              outline: true,
              icon: 'dropdownArrowLeft',
              onClick: goPrev,
            },
            {
              outline: true,
              icon: 'dropdownArrowRight',
              onClick: goNext,
            },
          ]}
          className={`${calToolbarClx}__navigation`}
        />
      )}
    </div>
  ) : null;
};

const mapStateToProps = (state, ownProps) => ({
  view: selectors.getCalendarView(state, ownProps.calendarName),
  date: selectors.getCalendarDate(state, ownProps.calendarName),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectorDate: (d) => dispatch(actions.updateSelectorDate(ownProps.calendarName, d)),
});

export const ConnectedToolbar = connect(mapStateToProps, mapDispatchToProps)(Toolbar);
