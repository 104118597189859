import {
  CmsLayoutType,
  LayoutSelectGrouping,
  CarouselBasedContainer,
} from 'framework/modules/contentmanagement/CmsComponents.interfaces';

export const threeBannerLayouts: CmsLayoutType[] = ['SmallPromotion', 'StandardContentBlock1', 'StandardContentBlock2'];
export const layoutGroups: LayoutSelectGrouping[] = [
  'containers',
  'largeComponents',
  'smallComponents',
  'basicElements',
];
export const carouselBasedContainers: CarouselBasedContainer[] = [
  'CarouselSmall',
  'TreeBanner',
  'TwoProductCard',
  'NextAvailableTimeSlot',
];
