import { put, select } from 'redux-saga/effects';
import { calendarActions, modalActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { MODAL_TEMPLATE_EDIT_SLOT } from 'framework/modules/slotManagement/constants';
import { FORM_EDIT_CALENDAR } from '../../pageParts/constants';
import { ACTIONS, selectors } from '..';

function* process() {
  const selectedEvent = yield select(selectors.getSelectedSlot);

  // 1. Remove Slot from Calendar
  yield put(calendarActions.removeEvent(FORM_EDIT_CALENDAR, selectedEvent.id));

  // 2. Hide Modal
  yield put(modalActions.createCloseModalAction(MODAL_TEMPLATE_EDIT_SLOT));
}

export default function* watchDeleteSlotSelectedAction() {
  yield takeEveryWithProgressBar(ACTIONS.TEMPLATE_DEL_SLOT_SELECTED, process);
}
