import Banner from './ApplicationSettingsBanner';
import CheckboxType from './Subcomponents/CheckboxType';
import ContactSupport from './Subcomponents/ContactSupport';
import Document from './ApplicationSettingsDocument';
import DropdownType from './Subcomponents/DropdownType';
import JsonUiGenerator from './Subcomponents/JsonUiGenerator';
import List from './ApplicationSettingsList';
import MultidropdownType from './Subcomponents/MultipleDropdownType';
import ObjectTypeComponent from './Subcomponents/ObjectTypeComponent';
import InputType from './Subcomponents/InputType';
import StoreType from './Subcomponents/StoreType/StoreType';

export default {
  Banner,
  CheckboxType,
  ContactSupport,
  Document,
  DropdownType,
  JsonUiGenerator,
  List,
  MultidropdownType,
  ObjectTypeComponent,
  InputType,
  StoreType,
};
