import config, { baseApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { AuthorizationClaims, ensureClaims } from 'framework/authorization';
import { FeatureState } from './types';

export const segmentationIsEnabled = async (): Promise<FeatureState> => {
  const userHasAccess = ensureClaims(AuthorizationClaims.SITESETTINGS_SEGMENTATION);

  if (!userHasAccess || !baseApiUrl) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData({
    url: `${config.apiEndpoints.featureFlags}/Segmentation`,
    method: 'HEAD',
  });

  return {
    isEnabled: result.status === 200,
  };
};
