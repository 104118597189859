import { AuthorizationClaims, UserAccessRights } from 'framework/authorization/Interfaces';

// Api Data Providers
export const API_PROVIDER_GET_ORDERS = 'ApiProviderGetFulfillmentOrders';
export const API_PROVIDER_FILTER_ORDERS = 'ApiProviderFilterFulfillmentOrders';
export const API_PROVIDER_GET_ORDER_DETAILS = 'ApiProviderGetFulfillmentOrderDetails';
export const API_PROVIDER_GET_ORDERS_COUNT = 'API_PROVIDER_GET_ORDERS_COUNT';
export const API_PROVIDER_GET_ORDER_DETAILS_ITEMS = 'API_PROVIDER_GET_ORDER_DETAILS_ITEMS';
export const API_PROVIDER_GET_ORDER_WAVES = 'API_PROVIDER_GET_ORDER_WAVES';
export const API_PROVIDER_GET_WAVE_DETAILS = 'API_PROVIDER_GET_WAVE_DETAILS';
export const API_PROVIDER_GET_CUSTOMER_COMMENTS = 'API_PROVIDER_GET_CUSTOMER_COMMENTS';
export const MODAL_CHANGE_FULFILLMENT_CENTER = 'MODAL_CHANGE_FULFILLMENT_CENTER';
export const API_PROVIDER_GET_STATUSES = 'API_PROVIDER_GET_STATUSES';
// Paths
export const PATH_MAIN = '/orderFulfillment';
export const PATH_ORDERS = '/orders';
export const PATH_PRINT_ORDER_SUMMARY_LIST = '/printordersummaries';
export const PATH_REMOVED_PRINT_ORDER_SUMMARY_LIST = '/removedprintordersummaries';
export const PATH_NO_STORES = '/noStores';
export const PATH_ORDER_DETAILS = '/:fcId/:orderId/:referenceName';
export const PATH_WAVES = '/waves';
export const PATH_WAVE_DETAILS = '/:fcId/:waveId';
export const PATH_PERSONAL_SHOPPERS = '/personalshoppers';
export const PATH_LOCATION_SERVICES = '/locationmapping';
export const PATH_PERSONAL_SHOPPER_DETAILS = '/:personalShopperId/:fullName';
export const PATH_PERSONAL_SHOPPER_CREATE = '/new';
export const PATH_REPORTS = '/reports';
export const PATH_SETTINGS = '/setup';
export const PATH_PRINTING_SETTINGS = '/printingsetup';
export const PATH_MPS_SETTINGS = '/mpssetup';
export const PATH_PRODUCTION_CENTERS_LIST = '/productioncenters';
export const PATH_PRODUCTION_CENTERS_CREATE = '/productioncenterscreate';
export const PATH_PRODUCTION_CENTERS_EDIT = '/productioncentersedit/:id';
export const PATH_FULFILLMENT_CENTERS_LIST = '/fulfillmentcenters';
// DataGrid
export const ORDER_FULFILLMENT_GET_ORDERS = 'DataGridOrderFulfillmentOrderList';
export const ORDER_FULFILLMENT_GET_ORDER_DETAILS_ITEMS = 'ORDER_FULFILLMENT_GET_ORDER_DETAILS_ITEMS';
export const ORDER_FULFILLMENT_GET_ORDER_DETAILS = 'DataGridOrderFulfillmentOrderDetails';
export const ORDER_FULFILLMENT_SET_ORDERS = 'DataGridOrderFulfillmentOrderListData';
export const ORDER_FULFILLMENT_GET_STATUSES = 'ORDER_FULFILLMENT_GET_STATUSES';
export const ORDER_FULFILLMENT_ERROR_ORDERS = 'ApiProviderGetFulfillmentOrdersError';
export const ORDER_FULFILLMENT_ERROR_ORDER_DETAILS = 'ApiProviderGetFulfillmentOrderDetailsError';
export const ORDER_FULFILLMENT_FILTER_ORDERS = 'DataGridOrderFulfillmentFilterOrderList';
export const ORDER_FULFILLMENT_DATE_FORMAT_BACKEND = 'hh:mm:ssA - MMMM DD, YYYY';
export const ORDER_FULFILLMENT_DATE_FORMAT_FRONTEND = 'HH:mm:ss - MMMM DD, YYYY';
export const PERSONAL_SHOPPERS_STATS_TABLE = 'PERSONAL_SHOPPERS_STATS_TABLE';
export const PERSONAL_SHOPPERS_TABLE = 'PersonalShoppersList';
// DataGrid actions
export const ORDER_FULFILLMENT_CHANGE_ORDER_STATUS = 'DataGridOrderFulfillmentOrderListChangeOrderStatus';
export const ORDER_FULFILLMENT_CHANGE_PICKBY_DATE = 'DataGridOrderFulfillmentOrderListChangePickByDate';
export const ORDER_FULFILLMENT_CHANGE_ORDER_PICKING_METHOD = 'ORDER_FULFILLMENT_CHANGE_ORDER_PICKING_METHOD';
export const ORDER_FULFILLMENT_FORCE_UPDATE_ORDER = 'orderFulfillmentForceUpdateOrder';
// Stats
export const ORDER_FULFILLMENT_GET_STATS = 'orderFulfillmentGetStats';
export const ORDER_FULFILLMENT_GET_STATS_ITEMS = 'ORDER_FULFILLMENT_GET_STATS_ITEMS';

// CRUD action
export const CRUD_RESOURCE_ACTION = 'CRUD_RESOURCE_ACTION';

// order details action
export const ORDER_FULFILLMENT_CHANGE_ORDER_DEETAILS_STATUS = 'ORDER_FULFILLMENT_CHANGE_ORDER_DEETAILS_STATUS';
export const API_PROVIDER_UPDATE_ORDER_DETAILS = 'API_PROVIDER_UPDATE_ORDER_DETAILS';
export const ORDER_FULFILLMENT_CHANGE_ORDER_DEETAILS_PICKBY_DATE = 'ORDER_FULFILLMENT_CHANGE_ORDER_DEETAILS_PICKBY_DATE';

// FC data
export const API_PROVIDER_PICKING_TARGETS = 'API_PROVIDER_PICKING_TARGETS';

// external reference error code
export const EXTERNALREF_DUPLICATE = 'externalReferenceNameAlreadyInUse';

export const DUPLICATE_RETAILER_ID = 'retailerIdAlreadyInUse';

// setup claims
export const setupClaims = [
  { [AuthorizationClaims.ORDER_FULFILLMENT_SETUP]: UserAccessRights.ReadWrite },
];
// productin reports claims
export const productionReportsClaims = [
  { [AuthorizationClaims.ORDER_FULFILLMENT_PRODUCTION_REPORTS]: UserAccessRights.ReadWrite },
];

export const APP_IS_VISIBLE = 'APP_IS_VISIBLE';
