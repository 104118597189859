import { connect } from 'react-redux';
import { actions as formActions, selectors as formSelectors } from '../Form';
import { ButtonReset } from './ButtonReset';
import { IButtonReset, IButtonResetPrivate } from './ButtonReset.interfaces';

const mapStateToProps = (state, { disabled, formToReset }: IButtonReset) => ({
  disabled: (disabled !== undefined) ? disabled : !formSelectors.getIsResetReady(state, formToReset as never),
  // !!ownProps.disabled || !formSelectors.getIsResetReady(state, ownProps.formToReset as any)
});

const mapDispatchToProps = (dispatch, ownProps): IButtonResetPrivate => ({
  resetForm: () => dispatch(formActions.reset(ownProps.formToReset)),
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(ButtonReset) as any) as React.StatelessComponent<IButtonReset>;
