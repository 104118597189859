import * as CONST from 'framework/modules/merchandising/constants';

export const saveListingDetails = (id) => ({
  type: CONST.ACTION_LISTING_SAVE_DETAILS,
  payload: { id },
});

export const saveListingRules = (id) => ({
  type: CONST.ACTION_LISTING_SAVE_RULES,
  payload: { id },
});
