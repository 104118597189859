/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { i18n } from 'i18n';
import * as React from 'react';
import { Icon, StatusIndicator } from 'ui';
import classnames from 'classnames';
import { INotificationLine } from '../Menu.interfaces';
import './_styles.scss';
import { Statuses } from 'framework/components/ui/Status';
import { formatDate } from 'framework/utils/datetimes';

export const NotificationLine = (props: INotificationLine) => {
  const { name, createdAt, impact, impactOverride, onClick, type, className, seen, description } = props;

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  const onKeyPress = (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      onClick();
    }
  };

  const infoText = typeof name === 'string' && i18n.t(name);

  return (
    <li>
      <div
        role="button"
        title={infoText}
        aria-label={infoText}
        onClick={onClickHandler}
        className={classnames(className, `${className}--disabled`)}
        onKeyDown={onKeyPress}
        tabIndex={0}
      >
        <div className={`${className}__icon ${className}__icon__${type.name}`}>
          <Icon name={type.icon} />
        </div>

        <div className={`${className}__data`}>
          <div className={`${className}__data__name`}>{name}</div>
          {!seen && <StatusIndicator caption={Statuses.New} />}
          <div className={`${className}__data__date`}>{formatDate(createdAt.toDateString())}</div>
        </div>
        <div className={`${className}__description`}>{description ?? impactOverride ?? impact}</div>
      </div>
    </li>
  );
};

export default NotificationLine;
