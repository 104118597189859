export enum Statuses {
  Active = 'Active',
  Picked = 'Picked',
  Fulfilled = 'Fulfilled',
  Complete = 'Complete',
  OversizedItem = 'Oversized Item',
  PaymentComplete = 'Payment Complete',
  Placed = 'Placed',
  Published = 'Published',
  PartiallyPicked = 'Partially Picked',
  Substituted = 'Substituted',
  Picking = 'Picking',
  PaperPicking = 'Paper Picking',
  Fulfilling = 'Fulfilling',
  Processing = 'Processing',
  Scheduled = 'Scheduled',
  Pending = 'Pending',
  SentToPicking = 'Sent To Picking',
  ReceivedInPicking = 'Received In Picking',
  ReadyForPicking = 'Ready For Picking',
  PaymentPending = 'PaymentPending',
  PaymentCaptured = 'Payment Captured',
  WaitingForPOSResult = 'Waiting for POS result',
  POSExportPending = 'POS Export Pending',
  POSExportComplete = 'POS Export Success',
  POSExportFailed = 'POS Export Failed',
  Draft = 'Draft',
  Info = 'Info',
  OutOfStock = 'Out Of Stock',
  Unavailable = 'Unavailable',
  Refunded = 'Refunded',
  PartiallyRefunded = 'Partially refunded',
  PaymentFailed = 'Payment Failed',
  Rejected = 'Rejected',
  Unpublished = 'Unpublished',
  Cancelled = 'Cancelled',
  LimitReached = 'Limit Reached',
  Expired = 'Expired',
  Locked = 'Locked',
  LockedColumn = 'Locked',
  Inactive = 'Inactive',
  ReadOnly = 'Read Only',
  Success = 'Success', // Not the developer
  Failed = 'Failed',
  Skipped = 'Skipped',
  Printed = 'Printed',
  Hidden = 'Hidden',
  Preferred = 'Preferred',
  New = 'New',
}
