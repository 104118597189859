/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { view } from '@risingstack/react-easy-state';
import {
  Modal, Button, Loader, Message,
} from 'ui';
import { useModal } from 'framework/components/ui/Modal';
import { prefixClassName } from 'framework/components/ui/_conf';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useLocation } from 'react-router-dom';
import {
  FILEMANAGER_MODAL, missingDataWarning, FILEMANAGER_DUPLICATE_FILE_NAME_MODAL,
} from './constants';
import {
  FileManagerProps, ExplorerMode, MissingData,
} from './types';
import { FileManagerUpload, FileManagerExplorerView } from './FileManagerComponents';

import './FileManager.scss';
import { useExplorerFileManager, useRootFileManager } from './useInternalFileManager';
import { useFileManagerContext } from './FileManagerContext';
import { FileManagerContextProvider } from './FileManagerContextProvider';
import { FileManagerFileDuplicateModal } from './FileManagerFileDuplicateModal';
import { useFileManagerProvider } from './useFileManager';

const clxDamTranslation = 'modules.contentmanagement.dam';
const cssClass = prefixClassName('file-manager');

const FileManagerFn = view(({
  mode,
  explorerModalName = FILEMANAGER_MODAL,
  fileSelectable = true,
  onDismissModal,
  onFilesUploaded,
  onFileSelected,
  trackEventProps,
  requiredFilesFormat
}: FileManagerProps) => {
  const {
    displayMode,
    missingData,
    uploadableFiles,
    setDisplayMode,
    closeRootModal,
    setDestinationFolder,
    setUploadableFiles,
    handleClearMissingData,
    handleUploadFile,
    handleDeleteFile,
  } = useRootFileManager({ mode, explorerModalName });

  const {
    store,
    previewableFile,
    openMoveModal,
    handleFileDelete,
    setPreviewableFile,
    handleExplorerRowClick,
    handleActiveFolderDelete,
  } = useExplorerFileManager({ explorerModalName });

  const { handleOpenFolderModal } = useFileManagerContext();

  const { pathname } = useLocation();

 const { setRequiredFilesFormat } = useFileManagerProvider();
  const {
    openModal: openOverwriteConfirmModal,
    closeModal: closeOverwriteConfirmModal,
  } = useModal(FILEMANAGER_DUPLICATE_FILE_NAME_MODAL);

  const toggleDisplayMode = (_mode: ExplorerMode) => () => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Toggle explorer mode',
      trackEventProps?.label || _mode
    );
    setDisplayMode(_mode);
    if (_mode === ExplorerMode.UPLOAD) {
      setPreviewableFile(undefined);
    }
  };

  const handleOnUpload = (overwrite: boolean = false, newFileName?: string) => {
    if (newFileName) {
      setUploadableFiles([{ ...uploadableFiles[0], name: newFileName, size: uploadableFiles[0].size }]);
    }
    handleUploadFile(overwrite, newFileName).then((res) => {
      if (res?.fileName) {
        openOverwriteConfirmModal();
      } else if (res && onFilesUploaded) {
        onFilesUploaded([res]);
      }
    });
  };

  const footerOptions = (displayMode === ExplorerMode.EXPLORE
        && missingData === MissingData.PARENT_DIRECTORY)
    ? {
      footerComponent: () => <Message caption={missingDataWarning[MissingData.PARENT_DIRECTORY]} type="error"/>,
    }
    : (displayMode === ExplorerMode.UPLOAD)
      ? {
        footerComponent: () => (
            <ModalFooterButtons
              missingData={missingData}
              onCancel={closeRootModal}
              onUpload={handleOnUpload}
            />
        ),
      }
      : {};
  return (
    <Modal
      name={explorerModalName}
      caption="modules.contentmanagement.fileManager"
      onDismiss={() => {
        if(onDismissModal)onDismissModal();
        if(setRequiredFilesFormat)setRequiredFilesFormat([]);
      }}
      closeOnUnmount
      className={cssClass}
      headerControls={(
        <div className="mrg-left-40">
          <ButtonAddFolder onClick={handleOpenFolderModal()} />
          {displayMode === ExplorerMode.UPLOAD ? (
            <ButtonExploreFile onClick={toggleDisplayMode(ExplorerMode.EXPLORE)} />
              ) : (<ButtonUploadFile onClick={toggleDisplayMode(ExplorerMode.UPLOAD)} />)}
        </div>
      )}
      {...footerOptions}
    >
      <FileManagerFileDuplicateModal
        handleOnUpload={handleOnUpload}
        uploadableFiles={uploadableFiles}
        cancelHandler={closeOverwriteConfirmModal}
      />

      {store.initializing ? (
        <div className={`${cssClass}__loader`}>
          <Loader size="lg" />
        </div>
      )
        : (
        <>
          {displayMode === ExplorerMode.EXPLORE && (
            <FileManagerExplorerView
              store={store}
              fileSelectable={fileSelectable}
              previewableFile={previewableFile}
              handleExplorerRowClick={handleExplorerRowClick}
              openMoveModal={openMoveModal}
              onFileSelected={onFileSelected}
              onFileDelete={handleFileDelete}
              onActiveFolderDelete={handleActiveFolderDelete}
              requiredFilesFormat={requiredFilesFormat}
            />
          )}
          {displayMode === ExplorerMode.UPLOAD && (
            <FileManagerUpload
              uploadableFiles={uploadableFiles}
              onDeleteFile={handleDeleteFile}
              onDestinationSelected={setDestinationFolder}
              onDropFiles={setUploadableFiles}
              onClearMissingData={handleClearMissingData}
            />
          )}
        </>
        )
      }
    </Modal>
  );
});

export const FileManager = ({ confirmationModalName, ...rest }: FileManagerProps) => (
  <FileManagerContextProvider confirmationModalName={confirmationModalName}>
    <FileManagerFn {...rest}/>
  </FileManagerContextProvider>
);

type ModalFooterButtonsProps = {
  missingData?: MissingData;
  onUpload: () => void;
  onCancel: () => void;
};
const ModalFooterButtons = ({ missingData, onUpload, onCancel }: ModalFooterButtonsProps) => (
  <div className={`${cssClass}__footer`}>
    <div className={`${cssClass}__footer--warning`}>
      {missingData ? (<Message caption={missingDataWarning[missingData]} type="error"/>) : null}
    </div>

    <div>
      <Button caption={`${clxDamTranslation}.upload`} onClick={onUpload} testId="upload" />
      <Button caption="generic.button.cancel" outline onClick={onCancel} />
    </div>
  </div>
);

type FolderActionProps = {
  onClick: () => void;
};
const ButtonAddFolder = ({ onClick }: FolderActionProps) => <Button caption="modules.contentmanagement.addFolder"
  outline onClick={onClick} />;
const ButtonUploadFile = ({ onClick }: FolderActionProps) => <Button className="mrg-left-20"
  caption="modules.contentmanagement.uploadFiles" outline onClick={onClick} />;

const ButtonExploreFile = ({ onClick }: FolderActionProps) => <Button className="mrg-left-20"
  caption="modules.contentmanagement.useExistingFile" outline onClick={onClick} />;