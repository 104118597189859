import * as React from 'react';
import * as clx from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { CmsDamFolder } from './Types';
import { Img, Button, Loader } from 'framework/components/ui';
import uuid from 'uuid/v4';

const componentClass = prefixClassName('dam');
const MAXITEMS_PER_LINE = 4;

export const DamContentGrid = (props: {
  files: CmsDamFolder[];
  selectFile;
  deleteFile;
  disableDelete;
  loading;
}) => {
  const notEmpty = props.files.filter(f => !f.empty).length > 0;
  const files = notEmpty ? props.files.filter(f => !f.empty) : props.files;

  let LineItems: any[][] = [];

  files.forEach((file, fileIndex) => {
    const lineIndex = Math.floor(fileIndex / MAXITEMS_PER_LINE);

    if (LineItems.length <= lineIndex) {
      LineItems = [...LineItems, []];
    }
    LineItems[lineIndex] = [...LineItems[lineIndex], file];
  });

  let Lines = [];

  LineItems.forEach(files => {
    const LineOfFiles = (
      <div
        key={uuid()}
        className={clx(
          `${componentClass}__files-panel__line`,
          files.length === MAXITEMS_PER_LINE && `${componentClass}__files-panel__line--full`
        )}
      >
        {files.map(file => (
          <FileItem
            key={uuid()}
            file={file}
            selectFile={props.selectFile}
            deleteFile={props.deleteFile}
            disableDelete={props.disableDelete}
          />
        ))}
      </div>
    );
    Lines = [...Lines, LineOfFiles];
  });
  return <div className={clx(`${componentClass}__files-panel`, props.loading && `${componentClass}__files-panel--loading`)}>
    {props.loading && <Loader size='lg' />}
    {Lines}
  </div>;
};

const FileItem = (props: { file: CmsDamFolder; selectFile; deleteFile; disableDelete }) => {
  const {
    file,
    file: { empty }
  } = props;

  return (
    <div className={`${componentClass}__file-item`}>
      {empty ? (
        <span className={`${componentClass}__empty-content`}>{file.name}</span>
      ) : (
          <>
            <div
              onClick={() => props.selectFile(file)}
              className={`${componentClass}__file-item__clickable`}
            >
              <div className={`${componentClass}__file-item__image`}>
                <Img height="" width="" src={file.url} />
              </div>
              <div className={`${componentClass}__file-item__name`}>{file.name}</div>
              <div className={`${componentClass}__file-item__url`}>{file.reference}</div>
            </div>
            {!props.disableDelete && <Button
              styleless
              onClick={() => {
                props.deleteFile(file);
              }}
              caption="generic.options.delete"
              className={`${componentClass}__file-item__delete`}
            />}

          </>
      )}
    </div>
  );
};
