import { sagas as relevanceAttributesSagas } from 'framework/modules/searchadmin/pages/RankingSettings/redux';
import defaultSettingsSagas from 'framework/modules/searchadmin/pages/DefaultSettings/sagas';
import rulesSagas from 'framework/modules/searchadmin/common/Rules/sagas';
import rulesEditSagas from 'framework/modules/searchadmin/pages/RulesEditRule/sagas';
import modalProductSearchSagas from 'framework/modules/searchadmin/components/ModalProductSearch/sagas';

export default [
  ...defaultSettingsSagas,
  ...relevanceAttributesSagas,
  ...rulesSagas,
  ...rulesEditSagas,
  ...modalProductSearchSagas,
];
