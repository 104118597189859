import { API_CLIENT_MAKE_REQUEST, createApiClientUpdateStateAction } from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getApiClientData } from './service';
import { config } from 'config';

export function watchApiClientMakeRequest() {
  return takeEvery(API_CLIENT_MAKE_REQUEST, apiDataProviderMakeRequest);
}

export function* apiDataProviderMakeRequest(data) {
  const { payload } = data;

  const endpointUrl = config.apiEndpoints[payload.endpoint];

  if (!endpointUrl) {
    // TODO: this should be passed to some Logger
    console.error(`Provided endpoint: ${payload.endpoint} is not recognised.`);

    // dispatch action to update state
    yield put(
      createApiClientUpdateStateAction({
        data: undefined,
        name: payload.name,
        hasError: true,
        isFetching: false
      })
    );
    return;
  }

  const url = endpointUrl + (payload.endpointPath || '');

  const requestDetails = {
    ...payload,
    url
  };

  // TODO: Get current state of ApiProvider, this will help with re-rendering

  // Set isFetching to true
  yield put(
    createApiClientUpdateStateAction({
      name: payload.name,
      hasError: undefined,
      isFetching: true
    })
  );

  // Make request
  const responseData = yield call(getApiClientData, requestDetails, payload.includeResponseDetails);
  yield put(
    createApiClientUpdateStateAction({
      data: responseData.items || undefined,
      name: requestDetails.name,
      hasError: !!responseData.hasError,
      isFetching: false,
      hasResponse: !!responseData.items,
      // spread response until final shape of response is established
      ...responseData
    })
  );
}
