import * as React from 'react';
import classnames from 'classnames';
import { FileManager, useFileManager } from 'framework/shared';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Img, Button, Text, TextField } from 'ui';
import { toastError } from 'stores/toast';
import { i18n } from 'i18n';
import { ExplorerMode, FileFolder } from 'framework/modules/contentmanagement/FileManager/types';
import { IImageField, IImage } from './ImageField.interfaces';

import './style.scss';

const clx = prefixClassName('form-field-imagePicker');

export const checkFileInDam = (file, requiredFilesFormat) => {
  const set = new Set(requiredFilesFormat);
  // eslint-disable-next-line no-restricted-syntax
  for (const word of file.name.split('.')) {
    if (set.has(word)) {
      return true;
    }
  }
  return false;
};

export const ImageField = (props: IImageField) => {
  const {
    name,
    description,
    imageNameCaption = 'components.imageField.imageName',
    value = {} as IImage,
    onChange = () => null,
    recommendedSize,
    disabled,
    hasAltText = false,
    requiredFilesFormat = [],
  } = props;

  const { url, alt, title } = value;
  const modalName = `fileManager_${name}`;

  const hasImage: boolean = !!url;

  const { mode: explorerMode, openFileManager, closeFileManager } = useFileManager({ explorerModalName: modalName });

  const handleClose = () => closeFileManager();

  const handleUpdateImg = (img) => {
    const { url: imgUrl, name: imgName } = img;
    if (requiredFilesFormat.length > 0 && !checkFileInDam(img, requiredFilesFormat)) {
      const joinedFormat = requiredFilesFormat.join(',');
      toastError(i18n.t('components.imageField.invalidImageFormat', { joinedFormat }));
      return;
    }

    onChange({
      url: imgUrl,
      title: imgName,
    });

    handleClose();
  };

  const removeValue = () => {
    onChange({});
  };

  const handleOpenModalUpload = () => openFileManager(ExplorerMode.UPLOAD);
  const handleOpenModalAdd = () => openFileManager(ExplorerMode.EXPLORE);

  return (
    <>
      <FileManager
        explorerModalName={modalName}
        mode={explorerMode}
        onFileSelected={handleUpdateImg}
        onFilesUploaded={(files: FileFolder[]) => handleUpdateImg(files[0])}
        requiredFilesFormat={requiredFilesFormat}
        confirmationModalName={`${modalName}-confirmation`}
      />
      <div className={clx}>
        {description && <Text tag="span" caption={description} className={`${clx}__description`} />}

        <div>
          <Text caption={imageNameCaption} />
          <span className={classnames(`${clx}__imageName`, !hasImage && `${clx}__imageName--empty`)}>
            {hasImage ? title : <Text caption="components.imageField.imageEmpty" />}
            <Button
              caption="components.imageField.delete"
              link
              onClick={removeValue}
              show={hasImage}
              disabled={disabled}
            />
          </span>
        </div>
        {recommendedSize && (
          <Text
            tag="div"
            className={`${clx}__recommendedSize`}
            caption="components.imageField.recommendedSize"
            i18nOptions={{ recommendedSize }}
          />
        )}

        {hasImage && (
          <>
            <div className={`${clx}__imgWrapper`}>
              <Img src={url} alt={alt} className={`${clx}__img`} width="" height="" />
            </div>
            {hasAltText && (
              <div className={`${clx}__optionsGroup`}>
                <TextField
                  name={`${name}.alt`}
                  mandatory
                  caption="components.imageField.altText"
                  size="tiny"
                  onChange={(e) => onChange({ url, title, alt: e.target.value })}
                />
              </div>
            )}
          </>
        )}

        <div className={`${clx}__optionsGroup`}>
          <Button
            className={`${clx}__optionsGroup__btn`}
            caption="components.imageField.upload"
            link
            onClick={handleOpenModalUpload}
            disabled={disabled}
          />
          <Button
            className={`${clx}__optionsGroup__btn`}
            caption="components.imageField.add"
            link
            onClick={handleOpenModalAdd}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
