import * as React from 'react';
import './TableText.scss';
import { Text } from 'ui';
import LinkPrimitive from 'framework/components/ui/Primitives/LinkPrimitive';
import { withLoading } from 'framework/components/hoc/withLoading';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ITableText } from './TableText.interfaces';

const loadingClass = prefixClassName('loading');

const TableText = (props: ITableText) => {
  const renderLines = (items: object[]) => items.map(
    (
      {
        text,
        link,
        defaultVal = '',
        linebreak,
        className = props.className,
        clickHandler,
        title,
        clickHandlerParams = [],
      }: any,
      i,
    ) => {
      const linkClass = link ? 'mwg-table--cells-text-link' : '';
      const TextComp = (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span
          role={clickHandler ? 'button' : null}
          onKeyDown={clickHandler ? () => {} : null}
          onClick={clickHandler ? () => clickHandler(...clickHandlerParams) : null}
          key={i}
          className={`${className || `mwg-table--cells-text-line${i}`} ${linkClass}`}
        >
          <Text caption={text !== '' ? text : defaultVal} linebreak={linebreak} />
        </span>
      );
      return link ? (
        <LinkPrimitive key={`mwg-grid-link${title}`} className={linkClass} to={link} title={title}>
          {TextComp}
        </LinkPrimitive>
      ) : (
        TextComp
      );
    },
  );
  const { lines } = props;
  return <>{renderLines(lines)}</>;
};

export default withLoading({ size: 'sm', className: loadingClass, wrapperClassName: `${loadingClass}--no-padding` })(TableText);
