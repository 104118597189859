import * as classnames from 'classnames';
import * as React from 'react';
import * as uniqid from 'uniqid';
import { prefixClassName } from 'framework/components/ui/_conf';
import RadioItem from './RadioGroup.Item';
import { IRadioGroup, IRadioItem } from './RadioGroup.interfaces';
import './_style.scss';

const classNameWrapper = prefixClassName('radioGroup');
const classNameItem = `${classNameWrapper}__item`;

export class RadioGroup extends React.Component<IRadioGroup> {
  static defaultProps: IRadioGroup = {
    data: [],
    disabled: false,
    displayInline: false,
    displayAsPills: false,
    onChange: () => null,
  };

  renderItems() {
    const { data, name, disabled, onChange, itemClassName } = this.props;
    const { value } = this.props;
    const radioName = uniqid('radioGroup_');

    return data.map((radioItem: IRadioItem, index) => {
      const key = `${radioName}__item_${index}`;
      return (
        <RadioItem
          key={key}
          id={key}
          name={name}
          value={radioItem.value}
          className={classNameItem}
          itemClassName={itemClassName}
          label={radioItem.label}
          checked={value === radioItem.value}
          disabled={disabled || radioItem.disabled}
          onChange={onChange}
          childLabel={radioItem.childLabel}
          explainerText={radioItem.explainerText}
        />
      );
    });
  }

  render() {
    const { data, className, displayInline, displayAsPills } = this.props;

    return data.length !== 0 ? (
      <ul
        className={classnames(
          classNameWrapper,
          displayAsPills && `${classNameWrapper}--pillsDisplay`,
          displayInline && `${classNameWrapper}--inline`,
          className,
        )}
      >
        {this.renderItems()}
      </ul>
    ) : null;
  }
}

export default RadioGroup;
