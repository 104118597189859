import * as React from 'react';
import { Link } from 'framework/components/ui/Link';
import { ensureClaims } from 'framework/authorization';
import { AppRouteBase } from './Navigation.interfaces';
import { getRouteUrl } from './service';

type ILinkProps = React.ComponentProps<typeof Link>;

export interface RouteLinkProps extends Omit<ILinkProps, 'to'> {
  appRoute: AppRouteBase;
  params?: {
    [param:string]: string,
  };
}

const RouteLink = ({ appRoute, params, ...linkProps }: RouteLinkProps) => {
  const disabled = React.useMemo(() => (
    !ensureClaims(appRoute.authClaim) || linkProps.disabled
  ), [appRoute, linkProps.disabled]);

  return (
    <Link
      {...linkProps}
      to={getRouteUrl(appRoute, params)}
      disabled={disabled}
    />
  );
};

export { RouteLink };
