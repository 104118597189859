import { isObject } from 'framework/utils/helpers';

const hasOwnProperty = (obj: any, key: string) => Object.prototype.hasOwnProperty.call(obj, key);

/**
 * The Select component creates props with { value: '', label: '', ... }
 * This function gets the correct value for the property in this case.
 * For an input Input: {
 *   name: 'Test',
 *   age: 15,
 *   country: { value: 'IE', label: 'Ireland' },
 *   hobbies: ['sleep', 'eat'],
 *   more: {
 *     company: 'mi9',
 *     role: { value: 'FED', label: 'Front-end developer' },
 *     value: 10
 *   },
 *   empty: {},
 *   nullProp: null,
 *   emptyString: '',
 *   zero: 0,
 *   boolean: false
 * }
 * The output is: {
 *   name: 'Test',
 *   age: 15,
 *   country: 'IE',
 *   hobbies: ['sleep', 'eat'],
 *   more: {
 *     company: 'mi9',
 *     role: 'FED',
 *     value: 10
 *   },
 *   emptyString: '',
 *   zero: 0,
 *   boolean: false
 * }
 * @param obj
 */
export const getRawValues = obj => {
  const values = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (isObject(value)) {
      if (hasOwnProperty(value, 'value') && hasOwnProperty(value, 'label')) {
        values[key] = value.value;
      } else if (Object.keys(value).length) {
        values[key] = getRawValues(value);
      }
    } else if (value !== null && value !== undefined) {
      values[key] = value;
    }
  });

  return values;
};
