import * as React from 'react';

import { Button } from 'ui';

const TableButton = (props: any) => {
  const { clickHandler, clickHandlerParams = [] } = props;
  return (
    <Button
      {...props}
      onClick={() => {
        clickHandler(clickHandlerParams);
      }}
    />
  );
};

export default TableButton;
