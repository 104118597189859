import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import stencilsMapping from './stencilsMapping';
import { StencilTypes } from './withStencils.interface';
import './_styles.scss';

const className = prefixClassName('stencil');

type StencilledProps = {
  stencilled: boolean;
  hasTop?: boolean;
  hasBottom?: boolean;
  fullWidth?: boolean;
  rows?: number;
};
type StencilledComponent<K> = StencilledProps & K ;

function getDisplayName(WrappedComponent) {
  return WrappedComponent?.displayName || WrappedComponent?.name || 'Component';
}

export function withStencils(componentType?: StencilTypes) {
  function wrapComponent<K>(component: React.ComponentType<K>): React.ComponentType<StencilledComponent<K>> {
    const Component = component;
    const stencils = (props: StencilledComponent<K>) => {
      const {
        stencilled = false, hasTop, hasBottom, fullWidth, rows,
      } = props;
      if (!stencilled) {
        return <Component {...props} />;
      }
      return componentType ? (
        <div className={`${className} ${fullWidth && `${className}-full-width`}`}>
          {stencilsMapping(componentType, hasTop, hasBottom, rows)}
        </div>
      ) : (
          <div className={`${className}`}>{stencilsMapping('default')}</div>
      );
    };
    stencils.displayName = `withStencil(${getDisplayName(Component)})`;
    return stencils;
  }
  return wrapComponent;
}

export default withStencils;
