import { call, select, put } from 'redux-saga/effects';
import { config } from 'config';
import { toastListActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest } from 'framework/api/make-request';
import { ListingDetails } from 'framework/modules/merchandising/models';
import {
  ACTION_LISTING_SAVE_RULES,
  PATH_LISTINGS_FULLPATH,
} from 'framework/modules/merchandising/constants';
import { history } from 'framework/store';
import { ListingTranslations } from 'framework/modules/merchandising/translations';
import { getFormValuesRules, getListingStoresId } from '../selectors';
import { FIELD_ACTIONS, FIELD_CONDITIONS } from '../../constants';
import { listingDefault, formatRuleActions, formatRuleConditions } from './helpers';

function* process({ payload: { id } }) {
  const formValues = yield select(getFormValuesRules);
  const stores = yield select(getListingStoresId);

  // 1. Make Save Request
  const listing: ListingDetails = {
    ...listingDefault,
    ...formValues,
    stores,
    actions: formatRuleActions(formValues[FIELD_ACTIONS]),
    conditions: formatRuleConditions(formValues[FIELD_CONDITIONS]),
    id,
  };

  const { success } = yield call(makeRequest, {
    name: 'saveListingDetails',
    method: 'PUT',
    url: `${config.apiEndpoints.listings}/${listing.id}`,
    body: { ...listing },
  });

  // 2. Navigate to Rules List Page
  if (success) {
    // 2.1 stay on the rule tab??
    history.push(`${PATH_LISTINGS_FULLPATH}/${listing.id}?tab=rule`);
    yield put(toastListActions.addMessage(ListingTranslations.SaveSuccess));
  } else {
    yield put(toastListActions.addMessageError(ListingTranslations.SaveError));
  }
}

export function watchSaveRules() {
  return takeEveryWithProgressBar(ACTION_LISTING_SAVE_RULES, process);
}
