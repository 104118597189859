import * as React from 'react';
import * as classnames from 'classnames';
import { Button, ButtonReset, ButtonSubmit } from 'framework/components/ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IButtonList } from './ButtonList.interfaces';
import './_style.scss';

const clx = prefixClassName('btnList');
const rightAlign = `${clx}--text-right`;

const ButtonList = (props: IButtonList) => {
  const { className, buttons = [], textRight } = props;

  const renderButtons = () => buttons.map((btn, key) => {
    // @ts-ignore
    if (btn.formToReset) {
      // @ts-ignore
      return <ButtonReset key={key} {...btn} />;
    }
    // @ts-ignore
    if (btn.formToSubmit) {
      // @ts-ignore
      return <ButtonSubmit key={key} {...btn} />;
    }

    return <Button key={key} {...btn} />;
  });

  return (
    <div className={classnames(clx, className, textRight && rightAlign)}>
      {!!buttons.length && renderButtons()}
    </div>
  );
};

export default ButtonList;
