import * as React from 'react';
import { withTranslation } from './withTranslation';

type TranslatorFnProps = {
  children: (caption: string) => JSX.Element;
};
const TranslatorFn = withTranslation<TranslatorFnProps>(({
  caption, children,
}) => children(caption));

export type TranslatorProps = {
  caption: string;
  options?: object;
  render: (text: string) => JSX.Element;
};
// translate with render props
export const Translator: React.FC<TranslatorProps> = ({ render, ...rest }: any) => (
  <TranslatorFn {...rest}>
    {(text) => render(text)}
  </TranslatorFn>
);
