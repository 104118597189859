export type KebabMenuDirection = 'downLeft' | 'downRight' | 'upLeft' | 'upRight';
export enum KebabMenuDirections {
  Up = 'up',
  Down = 'down',
  Left = 'Left',
  Right = 'Right',
}

export interface IKebabOption {
  label: React.ReactNode;
  linkTo?: string;
  onClick?: (e?: unknown) => void;
  disabled?: boolean;
  helperText?: string;
  className?: string;
  hidden?: boolean | ((...args) => boolean);
  index?: number;
  parentRef?: string;
  testId?: string;
}
export interface IKebab {
  label?: string;
  disabled?: boolean;
  className?: string;
  options: IKebabOption[];
  optionsData?: any;
  keepDropdown?: boolean;
  menuDirection?: KebabMenuDirection;
  onToggle?: (isOpen: boolean, e?: unknown) => void;
}
export interface IKebabButton {
  helperText?: string;
  className?: string;
  disabled?: boolean;
  onClick: (e?: unknown) => void;
  active?: boolean;
  testId?: string;
}
