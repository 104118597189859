import { prefixClassName } from 'framework/components/ui/_conf';

export const CALENDAR_NAME = 'slotManagementLiveSlot';
export const MODAL_ORPHAN = 'slotManagementLiveSlotOrphan';

// Calendar Filters
export const FORM_FILTER = 'slotManagementLiveSlotFilter';
export const FIELD_FILTER_STORE = 'slotManagementLiveSlotFilterStore';
export const FIELD_FILTER_SHOPPINGMODE = 'slotManagementLiveSlotFilterShoppingMode';
export const MODAL_SELECT_STORE = 'ModalStoreSelection';

// Edit Slot
export const MODAL_EDIT_SLOT = 'slotManagementLiveSlotEdit';
export const FORM_EDIT_SLOT = 'slotManagementLiveSlotEdit';
export const FORM_EDIT_BULK_SLOT = 'slotManagementLiveSlotBulkEdit';
export const FIELD_EDIT_SLOT_TIME_INI = 'slotManagementLiveSlotTimeIni';
export const FIELD_EDIT_SLOT_TIME_END = 'slotManagementLiveSlotTimeEnd';
export const FIELD_EDIT_SLOT_CAPACITY = 'slotManagementLiveSlotCapacityTotal';
export const FIELD_EDIT_SLOT_CAPACITY_AVAILABLE = 'slotManagementLiveSlotCapacityAvailable';
export const FIELD_EDIT_SLOT_ORDERS = 'slotManagementLiveSlotOrders';
export const FIELD_EDIT_SLOT_PRICE = 'slotManagementLiveSlotPrice';
export const FIELD_EDIT_SLOT_CUTOFF = 'slotManagementLiveSlotCutoff';
export const FIELD_EDIT_SLOT_PICK_BY_TIME = 'slotManagementLiveSlotPickByTime';
export const FIELD_EDIT_SLOT_TAGS = 'slotManagementLiveSlotTags';
export const FIELD_EDIT_SLOT_SHOPPINGID = 'shoppingModeId';
export const FIELD_EDIT_SLOT_FULFILLMENT = 'slotEditFulfillment';

// Blocking Slot
export const MODAL_BLOCKER_SLOT = 'slotManagementLiveSlotBlocker';
export const FORM_BLOCKER_SLOT = 'slotManagementLiveSlotBlocker';
export const FIELD_BLOCKER_SLOT_SHOPPINGMODE = 'slotManagementLiveSlotBlockerShoppingMode';
export const FIELD_BLOCKER_SLOT_DAY = 'slotManagementLiveSlotBlockerDay';
export const FIELD_BLOCKER_SLOT_TIME_INI = 'slotManagementLiveSlotBlockerTimeIni';
export const FIELD_BLOCKER_SLOT_TIME_END = 'slotManagementLiveSlotBlockerTimeEnd';
export const FIELD_BLOCKER_SLOT_NOTE = 'slotManagementLiveSlotBlockerNote';

// Close Slot
export const MODAL_CLOSE_SLOT = 'slotManagementLiveSlotClose';
export const FORM_CLOSE_SLOT = 'slotManagementLiveSlotClose';
export const FIELD_CLOSE_SLOT_NOTE = 'slotManagementLiveSlotCloseNote';

// Slot Calendar Error
export const TIMELINE_NOT_FOUND = 'TimeLineNotFound';

// Bulk modals
export const MODAL_BULK_EDIT_SLOT_CAPACITY = 'slotManagementLiveSlotBulkEditCapacity';

export const eventTemplates = {
  slot: 'liveCalendar',
  orphanSlot: 'liveCalendarOrphan',
  blocker: 'eventBlocker',
};

export const liveSlotClassName = prefixClassName('liveSlotCalendar');
