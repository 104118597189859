import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import './_styles.scss';

const clx = prefixClassName('stencil-panel');

interface IStencilPanel {
  rows: number;
}

export const StencilPanel = ({ rows = 7 }: IStencilPanel) => {
  const paragraphs = [];

  // Outer loop to create parent
  for (let i = 0; i < rows; i += 1) {
    paragraphs.push(<div key={i} className={`${clx}__p`} />);
  }

  return (
    <div className={clx}>
      <div className={`${clx}__t`} />
      {paragraphs}
    </div>
  );
};

export default StencilPanel;
