import { types } from 'framework/api/apiClient';

export const API_CLIENT_MAKE_REQUEST = 'API_CLIENT_MAKE_REQUEST';
export const API_CLIENT_STORE_REQUEST = 'API_CLIENT_STORE_REQUEST';
export const API_CLIENT_UPDATE_STATE = 'API_CLIENT_UPDATE_STATE';
export const API_CLIENT_CLEAR_STATE = 'API_CLIENT_CLEAR_STATE';
export const API_CLIENT_DELETE_DATA = 'API_CLIENT_DELETE_DATA';
export const API_CLIENT_IS_FETCHING_SET = 'API_CLIENT_IS_FETCHING_SET';
export const API_CLIENT_FORCE_STATE_UPDATE = 'API_CLIENT_FORCE_STATE_UPDATE';

/**
 * @deprecated Use makeRequestAndSaveState instead
 */
export const createApiClientMakeRequestAction = (
  payload: types.IMakeRequestActionPayloadProps,
) => ({
  payload,
  type: API_CLIENT_MAKE_REQUEST,
});

export const createApiClientStoreRequestAction = (payload) => ({
  payload,
  type: API_CLIENT_STORE_REQUEST,
});

export const createApiClientUpdateStateAction = (payload) => ({
  payload,
  type: API_CLIENT_UPDATE_STATE,
});

export const clearApiClientStateAction = (payload: { name: string }) => ({
  payload,
  type: API_CLIENT_CLEAR_STATE,
});

export const createApiClientDeleteAction = (payload: { name: string }) => ({
  payload,
  type: API_CLIENT_DELETE_DATA,
});

export const setIsFetching = (apiName: string, isFetching: boolean = true) => ({
  payload: { name: apiName, isFetching },
  type: API_CLIENT_IS_FETCHING_SET,
});
