import React from 'react';
import * as isEqual from 'lodash.isequal';
import { config } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { IDualSelectItem } from 'framework/components/ui/DualSelect/DualSelect.interfaces';
import { addProgressBar } from 'framework/components/ui/ProgressBar/useProgressBar';
import * as sortBy from 'lodash.sortby';
import { CommonPageRolesProps } from './CommonPageRoles.Interfaces';

export const useCommonPageRoles = ({ user }: CommonPageRolesProps) => {
  const [loadingRoles, setLoadingRoles] = React.useState(false);
  const [userRoles, setUserRoles] = React.useState<IDualSelectItem[]>(user ? user.roles || [] : []);
  const [roles, setRoles] = React.useState({
    availableRoles: [],
    assignedRoles: [],
    allRoles: [],
  });

  const filterAvailableRoles = (
    target: any[], values: any[],
  ) => target.filter((t) => !values.find((v) => v.id === t.id));

  const fetchRoles = addProgressBar((searchTerm?: string) => {
    setLoadingRoles(true);
    return fetchData<any>({
      url: config.apiEndpoints.roles,
      query: {
        skip: 0,
        take: 1000, // exceptionally large amount
        search: searchTerm,
      },
    }).finally(() => setLoadingRoles(false));
  });

  React.useEffect(() => {
    fetchRoles().then((res) => {
      const allRoles = res?.data?.items ? res.data.items : [];
      const assignedRoles: IDualSelectItem[] = [...(roles?.assignedRoles ? roles.assignedRoles : []), ...userRoles];
      setRoles({
        allRoles,
        availableRoles: filterAvailableRoles(allRoles, assignedRoles),
        assignedRoles: userRoles,
      });
    });
    // eslint-disable-next-line
  }, [userRoles]);

  const filterRoles = (searchTerm?: string) => {
    fetchRoles(searchTerm).then((res) => {
      const allRoles: IDualSelectItem[] = res?.data?.items ? res.data.items : [];
      const assignedRoles = [...(roles?.assignedRoles ? roles.assignedRoles : []), ...userRoles];
      setRoles({
        allRoles,
        availableRoles: filterAvailableRoles(allRoles, assignedRoles),
        assignedRoles: roles?.assignedRoles,
      });
    });
  };

  const addRoles = (newRoles: IDualSelectItem[]) => {
    setRoles({
      ...roles,
      availableRoles: filterAvailableRoles(roles.availableRoles, newRoles),
      assignedRoles: [...roles.assignedRoles, ...newRoles],
    });
  };

  const removeRoles = (removableRoles: IDualSelectItem[]) => {
    setRoles({
      ...roles,
      availableRoles: [...roles.availableRoles, ...removableRoles],
      assignedRoles: filterAvailableRoles(roles.assignedRoles, removableRoles),
    });
  };

  const resetRoles = () => {
    setRoles({
      ...roles,
      availableRoles: filterAvailableRoles(roles.allRoles, userRoles),
      assignedRoles: userRoles,
    });
  };

  const equalArrays = (list1: any[], list2: any[]) => isEqual(list1, list2);

  const dirty = !equalArrays(
    sortBy(userRoles.map((r) => r.name)),
    sortBy(roles.assignedRoles.map((r) => r.name)),
  );

  React.useEffect(() => {
    if (user) {
      setUserRoles(user.roles);
    }
  }, [user]);

  return {
    dirty,
    addRoles,
    removeRoles,
    resetRoles,
    loadingRoles,
    filterRoles,
    filterAvailableRoles,
    availableRoles: roles.availableRoles,
    assignRoles: roles.assignedRoles,
  };
};
