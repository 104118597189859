
export const reorderRows = (state, action) => ({
  ...state,
  loadedPage: {
    ...state.loadedPage,
    cmsContent: {
      ...state.loadedPage.cmsContent,
      rows: action.payload,
    },
  },
});
