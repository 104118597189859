export const CALENDAR_SET_INITIAL_EVENTS = 'CALENDAR_SET_INITIAL_EVENTS';
export const CALENDAR_SET_LOADER = 'CALENDAR_SET_LOADER';
export const CALENDAR_ADD_EVENT = 'CALENDAR_ADD_EVENT';
export const CALENDAR_ADD_EVENTS = 'CALENDAR_ADD_EVENTS';
export const CALENDAR_REMOVE_EVENT = 'CALENDAR_REMOVE_EVENT';
export const CALENDAR_RESET_VALUES = 'CALENDAR_RESET_VALUES';
export const CALENDAR_CLEAR_VALUES = 'CALENDAR_CLEAR_VALUES';
export const CALENDAR_CLEAR_EVENTS = 'CALENDAR_CLEAR_EVENTS';

export const CALENDAR_UPDATE_SELECTOR_EVENT = 'CALENDAR_UPDATE_SELECTOR_EVENT';
export const CALENDAR_UPDATE_SELECTOR_VIEW = 'CALENDAR_UPDATE_SELECTOR_VIEW';
export const CALENDAR_CLEAR_SELECTOR_EVENT = 'CALENDAR_CLEAR_SELECTOR_EVENT';
export const CALENDAR_UPDATE_SELECTOR_DATE = 'CALENDAR_UPDATE_SELECTOR_DATE';
