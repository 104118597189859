import React from 'react';
import { IEventComponent } from 'framework/components/ui/Calendar/components/Event/Event.interfaces';
import { CalendarSettings } from 'framework/components/ui/Calendar/store/selectors';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Icon, Info, Table, Text } from 'ui';
import './_styles.scss';
import 'framework/components/ui/Calendar/_style.scss';
import 'framework/components/ui/Calendar/components/EventWrapper/_style.scss';

import {
  capacityDetailsProps,
  usageData,
  usageDetails,
  usagePerShoppingModeProps,
} from 'framework/components/ui/Calendar/components/HeaderWeek/HeaderWeek.interfaces';
import { compareDates, formatDateTime, formatTime, getWeekDay } from 'framework/utils/datetimes';
import { CalendarContext } from 'framework/components/ui/Calendar/Calendar.context';
import classNames from 'classnames';
import { CalendarTemplate } from 'framework/components/ui/Calendar/Calendar.interfaces';
import { months, weekDays, weekDaysShort } from 'framework/components/ui/Calendar/components/HeaderWeek/HeaderWeek';
import { connect } from 'react-redux';
import { ISelectOptions } from 'framework/components/ui/FormComponents/Select';
import { i18n } from 'i18n';
import { shoppingModeType } from 'framework/components/ui/Calendar/components/EventWrapper/EventWrapper';
import { listViewColumnMetadata, ListViewTypes } from './constants';
import { selectors } from '../../SlotsTemplatesEdit/redux';
import { FulfillmentTypes } from '../../SlotsTemplatesEdit/pageParts/constants';
import { toSentenceCase } from 'framework/utils/string';

const clx = prefixClassName('calendarListView');

const isCurrentDay = (d: Date) => {
  const todaysDate = new Date();

  return d.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
};

const bulkActions = (selectedSlots, event, handleSelectSlot) => (
  <Table.ColumnCheckbox
    className={`${clx}__list-checkbox`}
    checked={!!selectedSlots?.find((s) => s.id === event.id)}
    onChange={() => {
      handleSelectSlot(event);
    }}
  />
);

interface CalendarListViewProps {
  calendarEvents: IEventComponent[];
  calendarSettings: CalendarSettings;
  headerData?: any;
  template?: CalendarTemplate;
  listViewType: ListViewTypes;
  selectedSlots?: IEventComponent[];
  setSelectedSlots?: (slots: IEventComponent[]) => void;
  onEventClick: (event: IEventComponent) => void;
  optionTags: ISelectOptions[];
}

const CalendarListView = (props: CalendarListViewProps) => {
  const {
    calendarEvents,
    calendarSettings,
    headerData,
    template,
    selectedSlots,
    setSelectedSlots,
    onEventClick,
    listViewType,
    optionTags,
  } = props;

  const [daysToggled, setDaysToggled] = React.useState<{ [key: number]: boolean }>({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  });

  const handleSelectSlot = (slot: IEventComponent) => {
    const slotIndex: number = selectedSlots?.findIndex((s) => s.id === slot.id);

    if (slotIndex > -1) {
      const editedSelectedSlots: IEventComponent[] = [...selectedSlots];

      editedSelectedSlots.splice(slotIndex, 1);

      setSelectedSlots(editedSelectedSlots);
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  const getTooltipBody = (smData: usagePerShoppingModeProps[]) =>
    smData.length === 0
      ? null
      : smData.map((d, i) => {
          const path = 'components.calendar.headerInfo.usageCount.tooltip.';
          const { value } = d;
          const { slotCapacity = 0, slotTaken = 0, slotRemaining = 0 } = value;

          return (
            <div className={`${clx}__tooltip`} key={i}>
              <span>{d.key}</span>
              <ul>
                <Text tag="li" i18nOptions={{ count: slotCapacity }} caption={`${path}capacity`} />
                <Text tag="li" i18nOptions={{ count: slotTaken }} caption={`${path}taken`} />
                <Text tag="li" i18nOptions={{ count: slotRemaining }} caption={`${path}remaining`} />
              </ul>
            </div>
          );
        });

  const renderUsage = (day) => {
    const weekDay = getWeekDay(day);
    const dayInfo = headerData.find((d) => d.day === weekDay) || ({} as usageData);
    const { total = {} as usageDetails, usagePerShoppingMode = [] } = dayInfo;
    const { slotTaken = '-', slotCapacity = '-' } = total;

    return (
      <>
        <Text
          caption="components.calendar.headerInfo.usageCount.title"
          i18nOptions={{
            taken: slotTaken,
            capacity: slotCapacity,
          }}
        />
        <Info tooltipBody={getTooltipBody(usagePerShoppingMode)} showOnHover />
      </>
    );
  };

  const renderCapacity = (date) => {
    const dayInfo = headerData.find((d) => d.day.getDay() === date.getDay()) || ({} as capacityDetailsProps);
    const { capacity = '-', fulfillmentType } = dayInfo;

    return (
      <>
        <Text caption="components.calendar.headerInfo.capacityCount.title" i18nOptions={{ capacity }} />
        {fulfillmentType === FulfillmentTypes.Instacart && (
          <Text
            caption={` ${i18n.t('components.calendar.headerInfo.capacityCount.excludingInstacart')}`}
            tag="span"
            className="secondary-color"
          />
        )}
      </>
    );
  };

  const handleOnClick = (event: IEventComponent) => {
    onEventClick(event);
  };

  // filter events when in day view
  // sort events by time and date
  const sortedEvents: IEventComponent[] =
    calendarSettings.view === 'day'
      ? [...calendarEvents.filter((event) => event.start.getDate() === calendarSettings.date.getDate())]
      : [...calendarEvents]
          .sort((a, b) => a?.start?.getTime() - b.start.getTime())
          .sort((a, b) => {
            return compareDates(a.start, b.start, true) ? 1 : 0;
          });

  const handleDayClick = (day: number) => {
    setDaysToggled({
      ...daysToggled,
      [day]: !daysToggled[day],
    });
  };

  const generateDayHeader = (event: IEventComponent, index: number) => {
    let dayHeader = <></>;

    // if calendar view is set to week, then generate a header for every day of the week
    if (event?.start?.getDay() !== sortedEvents[index - 1]?.start?.getDay() || index === 0) {
      const newDay = new Date(event.start);

      dayHeader = (
        <td
          colSpan={listViewType === ListViewTypes.Templates ? 8 : 12}
          className={classNames(`${clx}__date-usage-header`, isCurrentDay(newDay) && `${clx}__current-day`)}
          onClick={() => handleDayClick(event?.data?.day)}
          role="none"
        >
          <span className={`${clx}__day`}>
            <Text
              caption={
                listViewType === ListViewTypes.Calendar ? weekDaysShort[newDay.getDay()] : weekDays[newDay.getDay()]
              }
            />{' '}
            {listViewType === ListViewTypes.Calendar && newDay.getDate()}{' '}
          </span>

          {listViewType === ListViewTypes.Templates && (
            <span className={`${clx}__slot-usage`}>{renderCapacity(event.start)}</span>
          )}
          {listViewType === ListViewTypes.Calendar && (
            <>
              <Text tag="span" className={`${clx}__month`} caption={months[newDay.getMonth()]} />
              <span className={`${clx}__slot-usage`}>{renderUsage(event.start)}</span>
            </>
          )}
          <Icon
            name={daysToggled[event?.data?.day] ? 'dropdownArrowUp' : 'dropdownArrowDown'}
            className={`${clx}__date-usage-header__arrow`}
          />
        </td>
      );
    }

    return dayHeader;
  };

  return (
    <CalendarContext.Provider
      value={{
        template,
        data: { headerData },
      }}
    >
      <div className={clx}>
        <Table
          columnMetadata={listViewColumnMetadata(listViewType, selectedSlots, setSelectedSlots, sortedEvents)}
          data={sortedEvents}
          cellRenderer={(_t, event: IEventComponent, _b, index: number) => {
            const sm = shoppingModeType[event.data.shoppingModeId];
            const tags = event.data?.tags?.map(
              (iTag: any) => iTag?.label ?? optionTags.find((eachTag) => eachTag.value === iTag)?.label ?? iTag
            );

            return (
              <>
                {generateDayHeader(event, index)}
                <Table.Row
                  className={classNames(
                    !daysToggled[event.data?.day || new Date(event.start)?.getDay()] && `${clx}__hidden`,
                    event.data.isClosed && `${clx}__closed-slot`,
                    event.modified && `${clx}__modified__${listViewType}`,
                    event.template === 'eventBlocker' && `${clx}__blocking-slot`
                  )}
                >
                  {listViewType === ListViewTypes.Templates && (
                    <Table.ColumnCheckbox
                      className={`${clx}__list-checkbox`}
                      checked={!!selectedSlots?.find((s) => s.id === event.id)}
                      onChange={() => {
                        handleSelectSlot(event);
                      }}
                    />
                  )}
                  {listViewType === ListViewTypes.Calendar && bulkActions(selectedSlots, event, handleSelectSlot)}
                  <Table.Column dataCell onClick={() => handleOnClick(event)}>
                    <div
                      className={classNames(
                        `${clx}__type-indicator`,
                        sm &&
                          event.data.fulfilmentType !== FulfillmentTypes.Instacart &&
                          event.data.fulfillmentType?.value !== FulfillmentTypes.Instacart &&
                          `${clx}--shoppingMode-${sm}`,
                        `${clx}__type-indicator__${event.data.fulfilmentType ?? event.data.fulfillmentType?.value} `,
                        event.data.isExpired && `${clx}__type-indicator__expired`,
                        event.modified && `${clx}__type-indicator__modified`,
                        event.template === 'eventBlocker' && `${clx}__type-indicator__modified`
                      )}
                    />
                    {`${formatDateTime(event.start, null, null, null, true, true)} - ${formatDateTime(
                      event.end,
                      null,
                      null,
                      null,
                      true,
                      true
                    )}`}
                  </Table.Column>
                  <Table.Column onClick={() => handleOnClick(event)}>
                    {event.data.shoppingModeId && toSentenceCase(shoppingModeType[event.data.shoppingModeId])}
                  </Table.Column>
                  <Table.Column onClick={() => handleOnClick(event)}>
                    {event.data.fulfillmentTypeDisplayName ??
                      event.data.fulfillmentType?.label ??
                      event.data.fulfilmentType?.label ??
                      event.data.fulfillmentType ??
                      event.data.fulfilmentType ??
                      ''}
                  </Table.Column>
                  {listViewType === ListViewTypes.Calendar && (
                    <Table.Column onClick={() => handleOnClick(event)}>{event.data.orders ?? ''}</Table.Column>
                  )}
                  <Table.Column onClick={() => handleOnClick(event)}>
                    {(event.data.fulfillmentType?.value?.toLowerCase() ||
                      event.data.fulfilmentType?.value?.toLowerCase() ||
                      event.data.fulfillmentType?.toLowerCase() ||
                      event.data.fulfilmentType?.toLowerCase()) === FulfillmentTypes.Instacart.toLowerCase()
                      ? i18n.t('generic.unlimited').toString()
                      : event.data.capacity ?? ''}
                  </Table.Column>
                  {listViewType === ListViewTypes.Calendar && (
                    <Table.Column onClick={() => handleOnClick(event)}>
                      {event.data.infiniteCapacity
                        ? i18n.t('generic.unlimited').toString()
                        : event.data.availableCapacity ?? ''}
                    </Table.Column>
                  )}
                  <Table.Column onClick={() => handleOnClick(event)}>{event.data.price ?? ''}</Table.Column>
                  <Table.Column onClick={() => handleOnClick(event)}>{tags?.join(',') ?? ''}</Table.Column>
                  <Table.Column onClick={() => handleOnClick(event)}>
                    {event.data.newOrdersCutoff || event.data.cutoffTime
                      ? `${
                          event.data.newOrdersCutoff
                            ? formatTime(event.data.newOrdersCutoff)
                            : formatDateTime(event.data.cutoffTime, null, null, null, true)
                        }`
                      : ''}
                  </Table.Column>
                  {listViewType === ListViewTypes.Calendar && (
                    <Table.Column onClick={() => handleOnClick(event)}>
                      {event.data.isClosed ? i18n.t('components.calendar.events.template.liveCalendar.closed') : ''}
                    </Table.Column>
                  )}
                  {listViewType === ListViewTypes.Calendar && (
                    <Table.Column onClick={() => handleOnClick(event)}>{event.data.note}</Table.Column>
                  )}
                </Table.Row>
              </>
            );
          }}
        />
      </div>
    </CalendarContext.Provider>
  );
};

const mapStateToProps = (state) => {
  const slotOptions = selectors.getSlotOptions(state);
  return {
    optionTags: slotOptions.tags || [],
  };
};
export default connect(mapStateToProps, null)(CalendarListView);
