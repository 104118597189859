import React from 'react';
import { view } from '@risingstack/react-easy-state';
import {
  Modal, Message, Button, Loader,
} from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { FileManagerHierarchy } from './FileManagerHierarchy';
import { FILEMANAGER_MODAL_MOVE_TO_FOLDER, missingDataWarning } from './constants';
import { useMoveFolderFileManager, MoveFolderFileManagerProps } from './useInternalFileManager';

const cssClass = prefixClassName('file-manager');

export const FileManagerMoveFileModal = view(({
  movableFile, onFileMoved,
}: MoveFolderFileManagerProps) => {
  const {
    store, missingData, handleRowExpanding, handleHighlightFolder, handleMoveFile,
  } = useMoveFolderFileManager({ movableFile, onFileMoved });

  return (
    <Modal
        closeOnUnmount
        name={FILEMANAGER_MODAL_MOVE_TO_FOLDER}
        caption="modules.contentmanagement.selectFolder"
        footerComponent={() => (
          <div className={`${cssClass}__footer`}>
            <div className={`${cssClass}__footer--warning`}>
              {missingData ? (<Message caption={missingDataWarning[missingData]} type="error"/>) : null}
            </div>
            <Button caption="modules.contentmanagement.move" onClick={handleMoveFile} testId="moveFile" />
          </div>
        )}
      >
        {store.initializing ? (
              <div className={`${cssClass}__loader`}>
                <Loader size="lg" />
              </div>
        ) : (
              <FileManagerHierarchy
                data={store.data}
                skipActive
                label="modules.contentmanagement.folder"
                isLoading={store.isLoading}
                resolveIcon={store.resolveIcon}
                onHierarchyRowExpanding={handleRowExpanding}
                onHierarchyFolderHighlight={handleHighlightFolder}
              />
        )}
    </Modal>
  );
});
