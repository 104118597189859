import { appState } from '../../stores/app';
import userManager from './userManager';

export const renewSession = async () => {
  await userManager.signinSilent().catch(() => userManager.signoutRedirect({ id_token_hint: appState.user?.id_token }));
  const { FS } = window;
  if (FS) {
    FS.identify(false);
  }
};
