import {
  UserProfile, UserAccessRights, AuthAccessClaim,
} from './Interfaces';
/**
  @param profile
  @param claim: claim can be either a string (e.g stores.orghierarchy)
                or an object (e.g { "stores.orghierarchy": "rw" })
* */
export const claimExists = (profile: UserProfile, claim: AuthAccessClaim) => {
  const isObject = typeof claim === 'object';
  const claimValue = isObject ? (Object.entries(claim)[0][0]) : claim;
  const accessRight: UserAccessRights = isObject ? Object.entries(claim)[0][1]
    : UserAccessRights.ReadWrite;

  if (!profile[claimValue]) {
    return false;
  }

  const profileClaims: UserAccessRights[] = Array.isArray(profile[claimValue])
    ? profile[claimValue] : [profile[claimValue]] as any;

  return !!profileClaims.find((v) => v === UserAccessRights.ReadWrite || v === accessRight);
};
