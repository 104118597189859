import { createBrowserHistory, History, LocationDescriptor, LocationDescriptorObject, parsePath } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { urlParam } from 'featureFlag';
import { loadUser } from 'redux-oidc';
import userManager from 'framework/utils/userManager';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { config } from 'config';
import createRootReducer, { registerReducerCreator } from './reducers';
import allSagas, { registerSagaCreator } from './sagas';
import { parseQueryString, buildQueryString } from './utils/queryString';

type CreateHistory<O, H> = (options?: O) => History & H;

/**
 * Preserve url params based on the included url params in the current location url
 *
 * @param history history object
 * @param preserve array of url parameters to preserve
 * @param location current path location object
 * @returns edited path location object
 */
function preserveUrlParameters(
  history: History,
  preserve: string[],
  location: LocationDescriptorObject,
): LocationDescriptorObject {
  const currentQuery = parseQueryString(history.location.search);
  const localLocation: LocationDescriptorObject = { ...location };

  if (currentQuery) {
    const preservedQuery: { [key: string]: unknown } = {};

    // if the preserve url parameter is in the currentQuery, then add it to the
    // preservedQuery object
    preserve.forEach((p) => {
      const v = currentQuery[p];

      if (v) {
        preservedQuery[p] = v;
      }
    });

    if (localLocation.search) {
      Object.assign(preservedQuery, parseQueryString(localLocation.search));
    }

    localLocation.search = buildQueryString(preservedQuery, false);
  }

  return localLocation;
}

/**
 * Creates object that describes the current browsing location
 */
function createLocationObject(location: LocationDescriptor, state?: any): LocationDescriptorObject {
  return typeof location === 'string' ? { ...parsePath(location), state } : location;
}

/**
 * Create the history object needed for routing
 * Overwrite history's push() and replace() methods to preserve url parameters
 *
 * @param createHistory createBrowserHistory function from history package
 * @param queryParameters url parameters to be preserved if included
 * @returns history object
 */
export function createPreserveQueryHistory<O, H>(
  createHistory: CreateHistory<O, H>,
  urlParameters: string[],
): CreateHistory<O, H> {
  return (options?: O) => {
    const history = createHistory(options);

    const oldPush = history.push;
    const oldReplace = history.replace;

    // keep specified url parameters when using the history.push() function
    history.push = (path: LocationDescriptor, state?: any) =>
      oldPush.apply(history, [preserveUrlParameters(history, urlParameters, createLocationObject(path, state))]);

    // keep specified url parameters when using the history.replace() function
    history.replace = (path: LocationDescriptor, state?: any) =>
      oldReplace.apply(history, [preserveUrlParameters(history, urlParameters, createLocationObject(path, state))]);

    return history;
  };
}

// Create history
export const history = createPreserveQueryHistory(createBrowserHistory, [urlParam])({
  basename: config.baseUrl,
});

// Create Saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = createRootReducer(history);

const store = createStore(
  combineReducers(reducers),
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware) as any),
);
loadUser(store, userManager);

// then run the saga
sagaMiddleware.run(function* gen() {
  yield all(allSagas);
});

// Lets keep Redux state on window, ReduxTools are a bit flaky sometimes
window.ReduxStore = store;

const createReducer = registerReducerCreator(store, reducers);
const createSaga = registerSagaCreator(store, sagaMiddleware);

export default store;
export { createReducer, createSaga };
