import * as React from 'react';
import { createSelector } from 'reselect';
import { hasSameItems } from 'framework/utils/helpers';
import { HierarchyContextStoreProps, HierarchyProps, HierarchyNode } from './hierarchy.interfaces';

const TOGGLEROW = 'TOGGLEROW';
const EXPANDROW = 'EXPANDROW';
const COLLAPSEROW = 'COLLAPSEROW';
const TOGGLECHECK = 'TOGGLECHECK';
const INITDATA = 'INITDATA';
const SET_INITIALSELECTIONS = 'SET_INITIALSELECTIONS';
const HIGHLIGHT_ROW = 'HIGHLIGHT_ROW';
const RESET = 'RESET';
const RESET_SELECTIONS = 'RESET_SELECTIONS';
const RESET_EXPANDED_IDS = 'RESET_EXPANDED_IDS';
const CHECK_ALL = 'CHECK_ALL';
const UNCHECK_ALL = 'UNCHECK_ALL';

const initNodesData = (state: Partial<HierarchyContextStoreProps>, nodes: HierarchyNode[], parent?: HierarchyNode) => {
  const { selectedIds } = state;
  let selection = [...selectedIds];

  // init nodes: add parent key
  const dataNodes = nodes.reduce((acc: HierarchyNode[], node: HierarchyNode) => {
    const currentNode = { ...node };
    currentNode.parent = parent;
    if (!state.selectableParent) {
      const parentIsSelected = !!parent && !!selectedIds.find((id) => id === parent.id);
      if (parentIsSelected) {
        selection = [...selection, currentNode.id];
      }
    }

    if (currentNode.children) {
      const reslt = initNodesData(state, currentNode.children, currentNode);
      currentNode.children = reslt.dataNodes;
      const ids = reslt.selection.filter((id) => !selection.find((s) => s === id));
      selection = [...selection, ...ids];
    }
    return [...acc, currentNode];
  }, []);

  return {
    dataNodes,
    selection,
  };
};

const checkAll = (state: Partial<HierarchyContextStoreProps>) => {
  let selectedIds = [];
  let stack = (state.data || []).filter((n) => !n.parent);
  while (stack.length) {
    const node = stack.pop();
    selectedIds = [...selectedIds, node.id];
    if (node.children) {
      stack = [...stack, ...node.children];
    }
  }
  return selectedIds;
};

const foundInSelection = (selection: string[]) => (chld) => selection.some((id) => chld.id === id);

// check row, including children
const checkRow = (node: HierarchyNode, state: Partial<HierarchyContextStoreProps>, singleSelect?: boolean) => {
  let { selectedIds } = state;
  let stack = [node];

  if (singleSelect) {
    selectedIds = [node.id];
  } else {
    // check children
    while (stack.length) {
      const current = stack.pop();
      selectedIds = [...selectedIds, current.id];

      if (current.children) {
        stack = [...stack, ...current.children];
      }
    }

    // check parent if: at least 1 child selected (selectableParent), or all children selected (default)
    let { parent } = node;

    while (parent) {
      const shouldSelectParent = (state.selectableParent && parent.children.some(foundInSelection(selectedIds)))
        || parent.children.every(foundInSelection(selectedIds));

      if (shouldSelectParent) {
        selectedIds = [...selectedIds, parent.id];
      }

      const { parent: previousParent } = parent;
      parent = previousParent;
    }
  }

  return selectedIds;
};

// uncheck row (parents and children)
const uncheckRow = (node: HierarchyNode, state: Partial<HierarchyContextStoreProps>) => {
  let { selectedIds } = state;
  let stack = [node];

  // uncheck children
  while (stack.length) {
    const current = stack.pop();
    selectedIds = selectedIds.filter((id) => id !== current.id);
    if (current.children) {
      stack = [...stack, ...current.children];
    }
  }

  // uncheck parents
  if (node.parent) {
    let current = node.parent;
    const findFunc = (curr) => (id) => id !== curr.id;

    while (current) {
      if (state.selectableParent) {
        // remove parent only if no child selected
        const removeParent = !current.children.some(foundInSelection(selectedIds));
        if (removeParent) {
          selectedIds = selectedIds.filter(findFunc(current));
        }
      } else {
        selectedIds = selectedIds.filter(findFunc(current));
      }
      current = current.parent;
    }
  }

  return selectedIds;
};

// find whether at least one descendant is checked
const isIntermediary = (
  node: HierarchyNode,
) => createSelector([(s: any) => s.selectedIds], (selectedIds) => {
  let stack: HierarchyNode[] = node.children ? [...node.children] : [];
  while (stack.length) {
    const current = {
      ...stack.pop(),
    };
    const found = !!selectedIds.find((id) => id === current.id);
    if (found) {
      return true;
    }
    if (current.children) {
      stack = [...stack, ...current.children];
    }
  }
  return false;
});

const hierarchyReducer = (state: Partial<HierarchyContextStoreProps>, action: any): any => {
  switch (action.type) {
    // init nodes data
    case INITDATA: {
      const initialData = initNodesData(state, action.payload.data);

      return {
        ...state,
        selectedIds: initialData.selection,
        data: initialData.dataNodes,
      };
    }
    case SET_INITIALSELECTIONS: {
      const selectedIds = action.payload || [];

      return {
        ...state,
        selectedIds,
        initial: {
          ...state.initial,
          selectedIds,
        },
      };
    }

    // expand or collapse row
    case TOGGLEROW: {
      const shouldExpand = !state.expandedIds.find((id) => id === action.payload.targetId);
      return {
        ...state,
        expandedIds: shouldExpand
          ? [...state.expandedIds, action.payload.targetId]
          : state.expandedIds.filter((id) => id !== action.payload.targetId),
      };
    }

    case EXPANDROW: {
      return {
        ...state,
        expandedIds: [...state.expandedIds, action.payload.targetId]
      };
    }

    case COLLAPSEROW: {
      return {
        ...state,
        expandedIds: state.expandedIds.filter((id) => id !== action.payload.targetId)
      };
    }

    // check, uncheck rows
    case TOGGLECHECK: {
      const { node, singleSelect } = action.payload;
      const shouldSelect = singleSelect || !state.selectedIds.find((id) => id === node.id);
      return {
        ...state,
        selectedIds: shouldSelect ? checkRow(node, state, singleSelect) : uncheckRow(node, state),
      };
    }

    case HIGHLIGHT_ROW: {
      const nodeId = action.payload.node.id;
      return {
        ...state,
        highlightedId: state.highlightedId !== nodeId ? nodeId : undefined,
      };
    }

    case RESET: {
      // Reset ALL
      return {
        ...state,
        ...state.initial,
      };
    }

    case RESET_SELECTIONS: {
      // Reset wich checkboxes are checked
      return {
        ...state,
        selectedIds: state.initial.selectedIds,
      };
    }

    case RESET_EXPANDED_IDS: {
      // reset expanded Ids
      return {
        ...state,
        expandedIds: action.payload.expandedIds,
      };
    }

    case CHECK_ALL: {
      return {
        ...state,
        allSelected: true,
        selectedIds: checkAll(state),
      };
    }

    case UNCHECK_ALL: {
      return {
        ...state,
        allSelected: false,
        selectedIds: [],
      };
    }

    default:
      return state;
  }
};

export const useHierarchyStore = (props: HierarchyProps): Partial<HierarchyContextStoreProps> => {
  // initialize reducer hook
  const [state, dispatch] = React.useReducer<any>(hierarchyReducer, {
    allSelected: false,
    data: props.data,
    columns: props.columns,
    nodeIcon: props.nodeIcon,
    leafIcon: props.leafIcon,
    expandedIds: props.expandedIds || [],
    selectedIds: props.selectedIds || [],
    selectable: props.selectable,
    selectableParent: props.selectableParent,
    highlightable: props.highlightable,
    highlightedId: props.highlightedId,
    initial: {
      expandedIds: props.expandedIds || [],
      selectedIds: props.selectedIds || [],
      highlightedId: props.highlightedId,
    },
    allowSingleLeaf: props.allowSingleLeaf,
    hideIntermediary: props.hideIntermediary,
  }) as any;

  // on mount, initialize nodes data
  React.useEffect(() => {
    dispatch({
      type: INITDATA,
      payload: {
        data: props.data,
      },
    });
  }, []);

  // update ExpandedIds
  React.useEffect(() => {
    const expandedIds = props.expandedIds || [];
    if (!hasSameItems(state.expandedIds, expandedIds)) {
      dispatch({
        type: RESET_EXPANDED_IDS,
        payload: {
          expandedIds,
        },
      });
    }
  }, [props.expandedIds]);

  // return state and provider context methods
  return {
    ...state,
    rowIsExpanded: (rowId) => !!state.expandedIds.find((id) => id === rowId),
    rowIsSelected: (rowId, parentId, allowSingleLeaf) => {
      if (!allowSingleLeaf && parentId) {
        return state.selectable
          && !!state.selectedIds.find((id) => id === rowId)
          && !!state.selectedIds.find((id) => id === parentId);
      }

      return state.selectable && !!state.selectedIds.find((id) => id === rowId);
    },
    rowIsIntermediary: (node) => isIntermediary(node)(state),
    rowHasAllChildrenSelected: (node) => (
      !!node?.children && node.children.length > 0
      && node?.children.every((child) => !!state.selectedIds.find((id) => id === child.id))
    ),

    // toggle row action
    toggleRow: (targetId: string) => dispatch({
      type: TOGGLEROW,
      payload: {
        targetId,
      },
    }),

    expandRow: (targetId: string) => dispatch({
      type: EXPANDROW,
      payload: {
        targetId,
      },
    }),

    collapseRow: (targetId: string) => dispatch({
      type: COLLAPSEROW,
      payload: {
        targetId,
      },
    }),

    // toggle check action
    toggleCheck: (node: HierarchyNode, singleSelect?: boolean) => dispatch({
      type: TOGGLECHECK,
      payload: { node, singleSelect },
    }),

    toggleCheckAll: () => {
      dispatch({
        type: state.allSelected ? UNCHECK_ALL : CHECK_ALL,
      });
    },

    // highlight selected row
    highlightRow: (node: HierarchyNode) => {
      dispatch({
        type: HIGHLIGHT_ROW,
        payload: { node },
      });
    },

    reset: () => {
      dispatch({
        type: RESET,
      });
    },

    resetSelectedIds: () => {
      dispatch({
        type: RESET_SELECTIONS,
      });
    },

    setSelectedIds: (selectedIds?: string[]) => {
      dispatch({
        type: SET_INITIALSELECTIONS,
        payload: selectedIds,
      });
    },

    updateData: ({ data }) => {
      dispatch({
        type: INITDATA,
        payload: {
          data,
        },
      });
    },
  };
};

export const exportTopSelectionIds = ({ data, selectedIds }) => {
  let stack = [...data];
  const topIds = [];

  if (selectedIds.length === 0) {
    return [];
  }

  while (stack.length) {
    const current = stack.pop();
    const isSelected = !!selectedIds.find((id) => id === current.id);

    if (!isSelected) {
      if (Array.isArray(current.children)) {
        stack = [...stack, ...current.children];
      }
    } else {
      topIds.push(current.id);
    }
  }
  return topIds;
};

export const exportSelectionNodes = ({ hierarchyNodes, selectedIds }) => {
  const nodesSelected = [];

  if (selectedIds.length === 0) {
    return [];
  }

  const nodesRecursiveSearch = (nodes) => {
    nodes.forEach((node) => {
      // Add the node to the array if selected
      if (selectedIds.includes(node.id)) {
        const { children, ...nodeWithoutChildren } = node;

        nodesSelected.push(nodeWithoutChildren);
      }

      if (Array.isArray(node.children)) {
        nodesRecursiveSearch(node.children);
      }
    });
  };

  nodesRecursiveSearch(hierarchyNodes);
  return nodesSelected;
};

export const isDirty = (
  selectedIds: string[] = [], initialIds: string[] = [],
) => !hasSameItems(selectedIds, initialIds);
