import { createSelector } from 'reselect';
import {
  API_DATA_SLOTS_TEMPLATES_EDIT,
  API_DATA_SLOTS_TEMPLATES_EDIT_OPTIONS,
} from 'framework/modules/slotManagement/constants';
import type { IEventComponent } from '../components/Event/Event.interfaces';

export type CalendarSettings = {
  date?: Date | null;
  view?: null | 'week' | 'day';
  selectedEvent: string;
};

type CalendarState = {
  calendar: {
    isLoading?: boolean;
    events?: {
      [id: string]: IEventComponent;
    };
    eventsOriginal?: {};
    eventsDeleted?: number[];
    settings?: CalendarSettings;
    hasInstacartSlots?: boolean;
  };
  fulfillmentTypes: { label: string; value: string; infiniteCapacity: boolean }[];
  shoppingModeId: string;
};

export const getCalendarState = (state: any, calendarName: string): CalendarState => {
  return {
    calendar: state?.calendar?.[calendarName] || {},
    fulfillmentTypes: state?.apiClientData?.[API_DATA_SLOTS_TEMPLATES_EDIT_OPTIONS]?.fulfillmentTypes,
    shoppingModeId: state?.apiClientData?.[API_DATA_SLOTS_TEMPLATES_EDIT]?.data?.shoppingModeId,
  };
};

/** *************** */
// Events by type //
/** *************** */
export const getCalendarEvents = createSelector(
  [getCalendarState],
  ({ calendar, fulfillmentTypes, shoppingModeId }) => {
    const events = calendar.events || {};

    return Object.keys(events).map((key) => {
      return {
        ...events[key],
        data: {
          ...events[key]?.data,
          ...(events[key]?.template === 'slotTemplate' ? { shoppingModeId } : {}),
          infiniteCapacity:
            fulfillmentTypes?.find((v) => {
              return v.value === (events[key]?.data?.fulfillmentType?.value || events[key]?.data?.fulfillmentType);
            })?.infiniteCapacity || false,
        },
      };
    });
  }
);
export const getOriginalEvents = createSelector([getCalendarState], ({ calendar }) => {
  const events = calendar.eventsOriginal || {};

  return Object.keys(events).map((key) => events[key]);
});

export const getUpdatedEvents = createSelector([getCalendarEvents], (events) =>
  events.filter((event) => !!event.modified)
);

export const getDeletedEventsIds = createSelector([getCalendarState], ({ calendar }) => calendar.eventsDeleted || []);

export const getDeletedEvents = createSelector(
  [getOriginalEvents, getDeletedEventsIds],
  (eventsOriginal, eventsDeleted) => eventsOriginal.filter((event) => eventsDeleted.includes(event.id))
);

export const getCalendarEventById = (state: any, calendarName: string, eventId: string) =>
  getCalendarEvents(state, calendarName).find((event) => event.id === eventId);

/** ******* */
//  Info  //
/** ******* */
export const getCalendarSettings = createSelector(
  [getCalendarState],
  ({ calendar }) => (calendar.settings || {}) as CalendarSettings
);
export const getCalendarDate = createSelector([getCalendarSettings], (settings) => settings?.date || null);
export const getCalendarView = createSelector([getCalendarSettings], (settings) => settings?.view || null);
export const getSelectedEvent = (state: any, calendarName: string) => {
  const selectedEventId = getCalendarSettings(state, calendarName).selectedEvent || null;

  return getCalendarEventById(state, calendarName, selectedEventId);
};
export const getHasInstacartSlots = (state: any, calendarName: string) => {
  return state.calendar[calendarName]?.hasInstacartSlots || false;
};

export const getHasOrphanedSlots = (state: any, calendarName: string) => {
  return state.calendar[calendarName]?.hasOrphanedSlots || false;
};

/** ******* */
// States //
/** ******* */
export const isLoading = createSelector([getCalendarState], ({ calendar }) => !!calendar.isLoading);

export const isPristine = createSelector(
  [getDeletedEventsIds, getUpdatedEvents],
  (eventsDeleted, eventsUpdated) => !(!!eventsDeleted.length || !!eventsUpdated.length)
);

export const isDirty = (state: any, calendarName: string) => !isPristine(state, calendarName);
