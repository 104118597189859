import * as React from 'react';
import { Text, Icon } from 'ui';
import Wrapper from './label.wrapper';

interface SavedProps {
  saved?: boolean;
}

export const Saved = ({ saved = true }: SavedProps) => {
  return (
    <Wrapper type="saved">
      <Icon name="swapHorizontal" rotate={90} />
      <Text caption={saved ? 'generic.dataGrid.score.saved' : 'generic.dataGrid.unsaved'} />
    </Wrapper>
  );
};

export default Saved;
