import * as React from 'react';
import { Link, Text } from 'framework/components/ui';
import * as conf from 'framework/components/ui/_conf';
import { getRoutePath, resolveTitle } from 'framework/components/navigation/service';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { GaActions, TrackEventProps } from 'framework/applicationMonitoring/interface';

const className = conf.prefixClassName('breadcrumbs__listing__item');

type TitleType = string | (() => string);

export interface CrumbItemProps {
  pageTitle: TitleType;
  breadcrumbTitle?: TitleType;
  path: string;
  parent?: CrumbItemProps;
}

interface Props {
  appRoute: CrumbItemProps;
  trackEventProps?: TrackEventProps;
}

export const CrumbItem = ({ appRoute, trackEventProps }: Props) => {
  const match = useRouteMatch(appRoute.path);
  const resolvedTitle = resolveTitle(appRoute.breadcrumbTitle || appRoute.pageTitle);
  const isLastCrumb = match?.isExact;
  const { pathname } = useLocation();

  return (
    <>
      {!isLastCrumb ? (
        <Link
          to={getRoutePath(appRoute.path)}
          className={className}
          caption={resolvedTitle}
          onClick={() =>
            ApplicationMonitoring.trackEvent(
              trackEventProps?.name || pathname,
              trackEventProps?.value || GaActions.Breadcrumb,
              trackEventProps?.label || resolvedTitle
            )
          }
        />
      ) : (
        <Text className={className} tag="span" caption={resolvedTitle} />
      )}
    </>
  );
};
