import { reducer as oidcReducer } from 'redux-oidc';
import { connectRouter } from 'connected-react-router';
import apiClientReducer from 'framework/api/apiClient/reducer';
import { reducer as formReducer } from 'redux-form';
import { reducer as formMWGReducer } from 'framework/components/ui/FormComponents/Form';
import inProgress from 'framework/components/ui/ProgressBar/ProgressBar.reducer';
import { reducer as calendarReducer } from 'framework/components/ui/Calendar';
import tabGroups from 'framework/components/ui/Tabs/Tabs.reducer';
import tabsteps from 'framework/components/ui/TabSteps/TabSteps.reducer';
import { reducer as modal } from 'framework/components/ui/Modal';
import { contentManagementReducer as contentmanagement } from 'framework/modules/contentmanagement';
import { reducer as tooltipReducer } from 'framework/components/ui/Tooltip';
import { siteadmin } from 'framework/modules/siteadmin/common/CommonPage/CommonPage.Reducer';
import pollingstore from 'framework/modules/stores/common/StoreDetails/PoolingReducer';
import { registerReducerCreator } from './reducerCreator';

export default (history) => ({
  apiClientData: apiClientReducer,
  calendar: calendarReducer,
  form: formReducer,
  // There is no simple way to dispact custom actions to redux-forms, create our own
  formMWG: formMWGReducer,
  inProgress,
  oidc: oidcReducer,
  router: connectRouter(history),
  tabGroups,
  tabsteps,
  tooltip: tooltipReducer,
  modal,
  siteadmin,
  contentmanagement,
  pollingstore,
});

export { registerReducerCreator };
