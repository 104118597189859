import { config, baseApiUrl, getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { OrgHierarchyRoot } from '../models/orghierarchy';

export const fetchStores = async (orgEndpoint?: string): Promise<OrgHierarchyRoot> => {
  const stores = await fetchData<OrgHierarchyRoot>({
    url: orgEndpoint ?? config.apiEndpoints.orghierarchies,
  });
  return stores.data;
};

export const fetchStorePath = async (storeId: string): Promise<string[]> => {
  const path = await fetchData<string[]>({
    url: `${config.apiEndpoints.orghierarchies}/pathtostore/${storeId}`,
  });
  return path.data;
};

export const fetchpolygonsEnabeld = async (): Promise<any> => {
  const result = await fetchData({
    url: `${baseApiUrl}/api/deliveryareas/polygons/enabled`,
    method: 'HEAD',
  });
  return result;
};

export const addNonStoreNode = async (folderDescription: string, parentNodeId: string): Promise<boolean> => {
  const result = await fetchData({
    url: `${config.apiEndpoints.orghierarchies}/node/${parentNodeId}/children`,
    method: 'POST',
    body: {
      description: folderDescription,
    },
  });

  return result.success;
};

export const updateNodeDescription = async (folderDescription: string, nodeId: string): Promise<boolean> => {
  const result = await fetchData({
    url: `${config.apiEndpoints.orghierarchies}/node/${nodeId}/description`,
    method: 'PUT',
    body: {
      description: folderDescription,
    },
  });

  return result.success;
};

export const updateNodeLocation = async (nodeId: string, toParentNodeId: string): Promise<boolean> => {
  const result = await fetchData({
    url: `${config.apiEndpoints.orghierarchies}/node/${nodeId}/move/${toParentNodeId}`,
    method: 'PUT',
  });

  return result.success;
};

export const deleteNonStoreNode = async (nodeId: string): Promise<boolean> => {
  const result = await fetchData({
    url: getApiUrl('orghierarchy/node/{nodeId}', {
      nodeId
    }),
    method: 'DELETE',
  });

  return result.success;
};