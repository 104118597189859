import * as React from 'react';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ILoader } from './Loader.interfaces';
import './_styles.scss';

const clx = prefixClassName('loader');

const Loader = (props: ILoader) => {
  const { className, size, wrapperClassName = '' } = props;

  return (
    <div className={classNames(`${clx}__wrapper`, wrapperClassName)}>
      <div className={classNames(clx, size && `${clx}--${size}`, className)} />
    </div>
  );
};

export default Loader;
export { Loader };
