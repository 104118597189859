import * as React from 'react';
import { Button } from 'ui';
import './_styles.scss';

interface IPaginationDropdownProps {
  className?: string;
  pageSize?: number;
  recordCount?: number;
  onLoadMore?: (e) => void;
  visibleRecordsCount?: number;
}

export class PaginationLoadMore extends React.Component<IPaginationDropdownProps> {
  onSetPageSizeHandler = () => {
    this.props.onLoadMore(this.props.visibleRecordsCount + this.props.pageSize);
  };

  render() {
    const { className, recordCount, visibleRecordsCount } = this.props;

    const disabled = visibleRecordsCount === recordCount;

    const pageSize = visibleRecordsCount;

    return (
      <>
        <span className={`${className}--overview`}>
          Showing {pageSize} of {recordCount}
        </span>
        <span className={`${className}--page-selection`}>
          <Button
            caption="generic.dataGrid.loadMoreButton"
            onClick={this.onSetPageSizeHandler}
            disabled={disabled}
            outline
          />
        </span>
      </>
    );
  }
}
