import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { ensureClaims } from 'framework/authorization';
import {
  storesListRoute,
  storesCreateRoute,
  sitesListRoute,
  attributeListPageRoute,
  siteCreateRoute,
  attributeNewPageRoute,
  manageStoreHierarchy,
  storeClaims,
} from './routes';
import { StoreHierarchyTranslations } from './translations';

const StoresModule: ModuleConfig = {
  name: 'stores',
  appMenuTitle: 'navigation.stores',
  appMenuIcon: 'store',
  modulePath: '/storeManagement',
  additionalClaims: [
    {
      menuTitle: 'modules.siteadmin.claims.fulfillmentProvider',
      authClaim: {
        [AuthorizationClaims.STORES_PROVIDER_CONFIGURATION]: UserAccessRights.ReadWrite,
        ...storeClaims,
      },
    },
  ],
  pageGroups: async () => {
    return [
      {
        title: 'modules.stores.storeList.pageTitle',
        routes: [storesListRoute, storesCreateRoute],
      },
      {
        title: 'modules.stores.sites',
        routes: [sitesListRoute, siteCreateRoute],
      },
      {
        title: 'modules.stores.storeAttributeList',
        routes: [attributeListPageRoute, attributeNewPageRoute],
      },
      ...(ensureClaims(AuthorizationClaims.FULLACCESS)
        ? [
          {
            title: StoreHierarchyTranslations.EditPageTitle,
            routes: [manageStoreHierarchy],
            hideFromRoleAssociation: true,
          },
        ]
        : []),
    ];
  },
};

export default StoresModule;
