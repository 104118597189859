import * as React from 'react';
import { DynamicFields, LayoutFlexBox, LayoutFlexBoxItem, Select, Text, TagsField, Info } from 'ui';
import { i18n } from 'i18n';
import ConditionsComponent from './ConditionsComponent';
import '../_styles.scss';
import { featureFlag } from '../../../../../../../featureFlag';

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    displayIsFixedCondition,
    termConditionOperationOptions,
    fetchCurrentData,
    searchForProducts,
    changeFormValue,
  } = props;

  const onTermChangeHandler = (terms) => {
    featureFlag({
      prod: () => {},
      dev: () => {
        if (!searchForProducts && terms?.length === 1) {
          changeFormValue('searchForProducts', terms[0]);
        }
      },
    });

    fetchCurrentData();
  };

  return (
    <LayoutFlexBoxItem flexGrow={1} className="mrg-btm-40">
      <Text caption="modules.merchandising.rules.create.conditions" tag="strong" />
      <Info tooltipBody={i18n.t('modules.merchandising.rules.create.conditionsTooltip')} toolTipPosition="up-right" />
      <LayoutFlexBoxItem className="mwg-widget mrg-btm-20" width="90%">
        <LayoutFlexBox justifyContent="space-between">
          <LayoutFlexBoxItem flexBasis="60%">
            <Select
              name="termsCondition"
              clearable={false}
              options={[
                { label: 'All Search Term(s)', value: 'all' },
                { label: 'Search Term(s)', value: 'search' },
              ]}
              placeholder="components.select.placeholder"
              mandatory
            />
          </LayoutFlexBoxItem>
          {displayIsFixedCondition && (
            <LayoutFlexBoxItem flexBasis="35%">
              <Select
                name="termsCondition_operation"
                clearable={false}
                options={termConditionOperationOptions}
                placeholder="components.select.placeholder"
                onChange={fetchCurrentData}
                mandatory
              />
            </LayoutFlexBoxItem>
          )}
        </LayoutFlexBox>

        {displayIsFixedCondition && (
          <TagsField
            name="termsCondition_terms"
            className="mrg-top-20"
            divider=","
            onChange={(terms) => onTermChangeHandler(terms)}
            mandatory
          />
        )}
      </LayoutFlexBoxItem>
      <LayoutFlexBoxItem flexGrow={1}>
        <DynamicFields name="filterConditions" component={ConditionsComponent} {...props} />
      </LayoutFlexBoxItem>
    </LayoutFlexBoxItem>
  );
};
