import { formatDate as formatD } from 'framework/utils/datetimes';

export const formatDate = (date: Date) => formatD(date);

export const dateSetTime = (date: Date, time: any) => {
  let h;
  let m;
  if (typeof time.getMonth === 'function') {
    const timeDate = new Date(time);
    h = timeDate.getHours();
    m = timeDate.getMinutes();
  } else {
    h = time.hours;
    m = time.minutes;
  }

  const d = new Date(date);

  d.setHours(h);
  d.setMinutes(m);

  return d;
};
