/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classnames from 'classnames';
import * as React from 'react';
import { isArray } from 'framework/utils/helpers';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Icon, Pill } from 'ui';
import i18n from 'i18n';
import { ITagsField } from './TagsField.interfaces';
import './_style.scss';

const clx = prefixClassName('tags-field');

const KEY_ENTER = 13;
const KEY_BACKSPACE = 8;

const TagsField = (props: ITagsField) => {
  const {
    value = [],
    placeholder,
    divider = ' ',
    name,
    className,
    readOnly = false,
    disabled = false,
    // Actions
    onChange = () => null,
    onInput = () => null,
    onRemoveAll = () => null,
    onFocus = () => null,
    onBlur = () => null,
    labelledby,
    scrollable = false,
    preventDefaultOnEnter = false,
  } = props;
  const myRef = React.useRef();
  const [suggestion, setSuggestion] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);

  const isEditable = !disabled && !readOnly;
  const hasTags = value && value.length > 0;

  const setValue = (values: string[] = []) => {
    // Remove duplicated values
    const val = Array.from(new Set(values)).filter((v) => v !== '');
    onChange(val);
  };
  const addTags = (values: string | string[]) => {
    const valuesArray = [...value];
    const newValues = Array.isArray(values) ? values : [values];

    newValues.forEach((v) => {
      // Remove duplicated values
      valuesArray.push(v.trim());
    });

    setValue(valuesArray);
    setSuggestion('');
  };

  const removeAllTags = () => {
    setValue([]);
    onRemoveAll();
  };

  const removeTag = (index: number) => {
    setValue(value.filter((_x, i) => i !== index));
  };

  const focusInput = () => isEditable && myRef.current.focus();

  const onChangeHandle = (e) => {
    setSuggestion(e.target.value);
    onInput(e.target.value);
  };

  const onFocusHandler = () => {
    setIsFocused(true);
    onFocus();
  };

  const onBlurHandler = () => {
    addTags(suggestion);
    setIsFocused(false);
    onBlur();
  };

  const onPaste = () => {
    focusInput();
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case KEY_BACKSPACE:
        if (hasTags && suggestion === '') {
          removeTag(value.length - 1);
        }
        break;
      case KEY_ENTER:
        addTags(suggestion);
        if (preventDefaultOnEnter) e.preventDefault();
        break;
      default:
        break;
    }
  };
  React.useEffect(() => {
    // Add the suggestions as a value/Pill when the divider symbol is used.
    // It will work on type values and copyPasting values too.

    if (isArray(divider)) {
      if (divider.some((d) => suggestion.includes(d))) {
        addTags(suggestion.split(new RegExp(divider.join('|'), 'g')));
      }
    } else if (suggestion.includes(divider as string)) {
      addTags(suggestion.split(divider as string));
    }
    /* eslint-disable-next-line */
  }, [suggestion]);

  const renderPills = () =>
    value.map((m, index) => (
      <Pill
        key={m}
        caption={m}
        className={`${clx}__tag`}
        onClose={() => removeTag(index)}
        readOnly={readOnly}
        disabled={disabled}
      />
    ));

  const aria = labelledby ? { 'aria-labelledby': labelledby } : {};

  return (
    <div
      className={classnames(
        clx,
        hasTags ? `${clx}--hasTags` : `${clx}--hasNoTags`,
        isFocused && `${clx}--focused`,
        readOnly && `${clx}--readOnly`,
        disabled && `${clx}--disabled`,
        scrollable && 'overflow-auto',
        className
      )}
    >
      <div className={`${clx}__bg`} onClick={focusInput} />
      <div className={`${clx}__content`}>
        {hasTags && (
          <>
            {renderPills()}

            {isEditable && (
              <Icon
                className={`${clx}__close-icon`}
                name="del"
                title="components.tagsField.btnClear"
                onClick={removeAllTags}
              />
            )}
          </>
        )}
        {isEditable && (
          <div className={`${clx}__input__wrapper`}>
            <input
              type="text"
              placeholder={hasTags ? '' : i18n.t(placeholder || 'components.tagsField.placeholder')}
              id={name}
              name={name}
              aria-label={name}
              autoComplete="off"
              value={suggestion}
              className={`${clx}__input`}
              ref={myRef}
              disabled={!isEditable}
              onKeyDown={handleKeyDown}
              onChange={onChangeHandle}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
              onPaste={onPaste}
              {...aria}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TagsField;
