import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { goToRoute } from 'framework/components/navigation/service';
import { PromotionsTranslations } from '../../translations';
import { promotionCodeListRoute } from '../PromotionCodesList/route';

export const promotionCodeCreateRoute: AppRoute = {
  path: '/promotions/promotionCodes/create',
  authClaim: AuthorizationClaims.PROMOTIONS_PROMOCODES,
  pageTitle: PromotionsTranslations.CreatePromotionCode,
  menuTitle: PromotionsTranslations.CreatePromotionCode,
  parent: promotionCodeListRoute,
  Component: () => import('./index'),
};

export const gotToPromoCodeCreateRoute = () => goToRoute(promotionCodeCreateRoute);

export const promotionCodeEditRoute: AppRoute = {
  path: '/promotions/promotionCodes/:id',
  authClaim: AuthorizationClaims.PROMOTIONS_PROMOCODES,
  pageTitle: PromotionsTranslations.PromotionCode,
  parent: promotionCodeListRoute,
  Component: () => import('./index'),
};

export const goToPromoCodeEditRoute = (id: string) => goToRoute(promotionCodeEditRoute, { id });
