import * as React from 'react';
import clx from 'classnames';
import { Modal, Button } from 'framework/components/ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ConfirmationModalFn } from 'framework/components/ui/ConfirmationModal/ConfirmationModal';
import { DamsProps, DamMode, DAM_MODAL } from './Types';
import { translations } from './translations';
import { DamContent } from './DamContent';
import { useDamInternals } from './useDamInternals';
import './Dam.scss';

const cssClass = prefixClassName('dam');

const DamSwitchMode = ({ title, onClick }: { title: string, onClick: () => void }) => (
  <div className={`${cssClass}__switch-mode`}>
    <Button caption={title} outline onClick={onClick} />
  </div>
);

export const DamModal = (props: DamsProps) => {
  const {
    modalName = DAM_MODAL,
    mode: displayMode, onDismiss, onFileUploaded, disableDelete,
  } = props;
  const hooks = useDamInternals({ mode: displayMode, onDismiss, onFileUploaded });
  const { internalMode, setInternalMode } = hooks;
  const isUploadMode = internalMode === DamMode.directory;
  const footerActions: any[] = isUploadMode
    ? [
      {
        caption: translations.upload,
        onClick: hooks.handleSaveFile,
      },
      {
        caption: 'generic.button.cancel',
        onClick: hooks.closeDam,
        outline: true,
      },
    ]
    : [];

  const damSwitchTitle = !isUploadMode ? translations.uploadNew : translations.useExisting;

  return (
    <>
      <ConfirmationModalFn
        warning={translations.confirmDeleteFile}
        onConfirmDelete={hooks.deleteFile}
        onCancelDelete={() => hooks.toggleConfirmDeleteFileModal(false)}
      />
      <Modal
        className={clx(cssClass, !isUploadMode && `${cssClass}--file-mode`)}
        name={modalName}
        caption={isUploadMode ? translations.uploadNew : translations.contentAssets}
        footerOptions={footerActions}
        headerControls={
          <DamSwitchMode
            title={damSwitchTitle}
            onClick={() => setInternalMode(!isUploadMode ? DamMode.directory : DamMode.file)}
          />
        }
        onDismiss={hooks.handleOnDismiss}
      >
        <DamContent
          mode={internalMode}
          {...hooks as any}
          onFileSelected={hooks.handleFileSelected}
          onFileDeleted={hooks.handleConfirmDeleteFile}
          disableDelete={disableDelete}
        />
      </Modal>
    </>
  );
};
