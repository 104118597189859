import { i18n } from 'i18n';
import * as moment from 'moment';
import { Validator } from 'redux-form';
import { IPercentageFieldProps } from './index.interface';

// TODO: Should this error contain field name too?
export const handleValidation = (isValid: boolean, message: string) => (isValid ? undefined : i18n.t(message));

export const hasValidFormat = (value: any, regExFormat: any) => {
  const ipRegEx = new RegExp(regExFormat);

  return ipRegEx.test(value);
};

export const mandatory = (value) => {
  // When is an array, check if it is empty
  const valid = Array.isArray(value) ? !!value.length : !!value;

  return handleValidation(valid, 'generic.formsValidation.mandatory');
};

/* ********************* */
/* --- Value Formats --- */
/* ********************* */
export const isIpAddressOrDNS = (value: any) => {
  const hasAlphaChars = value.match(new RegExp(/[a-zA-Z]/));
  const isIP = hasValidFormat(value, /\b(?:\d{1,3}\.){3}\d{1,3}\b/);

  return handleValidation(hasAlphaChars ? true : isIP, 'generic.formsValidation.ipAddress');
};
export const isAlphaNumeric = (value: any) => {
  const isValidAlphaNumeric = hasValidFormat(value, /^$|^[a-zA-Z0-9_]*$\b/);

  return handleValidation(isValidAlphaNumeric, 'generic.formsValidation.alphaNumeric');
};

const mailRegEx =
  // eslint-disable-next-line max-len
  /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
export const isEmail = (value: any = '') => {
  const isValid = hasValidFormat(value, mailRegEx);
  return handleValidation(isValid, 'generic.formsValidation.emailFormat');
};

export const isEmailArray = (emailArray: string[] = []) => {
  const hasInvalidEmail: boolean = emailArray.some((email) => !hasValidFormat(email, mailRegEx));
  return handleValidation(!hasInvalidEmail, 'generic.formsValidation.emailFormat');
};

export const isNumericPINCode = (value: any) => {
  const isPINNumeric = hasValidFormat(value, /^$|^[0-9]{4}$/);

  return handleValidation(isPINNumeric, 'generic.formsValidation.numericPIN');
};

export const isEmpty = (val) => val === undefined || val === null || val === '' || val.length === 0;

const handleEmpty =
  (fn: Validator): Validator =>
  (value, ...args) => {
    if (isEmpty(value)) {
      return undefined;
    }

    return fn(value, ...args);
  };

export const minimumLength = (minLength: number) =>
  handleEmpty((value) =>
    value.length < minLength ? i18n.t('generic.formsValidation.minimumLength', { minLength }) : undefined
  );

export const maxLengthTags = (maxLength: number) =>
  handleEmpty((value: string[]) => {
    const isValid: boolean = value.every((value) => value.length <= maxLength);

    return !isValid ? i18n.t('generic.formsValidation.maxLengthTags', { maxLength }) : undefined;
  });

export const maxTagsTotal = (maxLength: number) =>
  handleEmpty((value: string[]) =>
    value.length > maxLength ? i18n.t('generic.formsValidation.maxTagsTotal', { maxLength }) : undefined
  );

export const isValidFormat = (regExFormat: any, msg = 'generic.formsValidation.validFormat') =>
  handleEmpty((value) => {
    // User can send custom RegEx conditions
    // validate = {[ validators.itValidFormat(RegExRule) ]}
    let isValid: boolean;

    if (Array.isArray(value)) {
      isValid = value.every((value) => hasValidFormat(value, regExFormat));
    } else {
      isValid = hasValidFormat(value, regExFormat);
    }

    return handleValidation(isValid, msg);
  });

// Validate text that will be used in a url
const urlTextRegex = /^$|^[-a-zA-Z0-9-_ ]*$\b/;
export const urlTextValidator = isValidFormat(urlTextRegex, 'generic.formsValidation.invalidUrlText');

// Validate retailer ids
const retailerIdRegex = /^[-a-z0-9-_.]*$/;
export const retailerIdValidator = isValidFormat(retailerIdRegex, 'generic.formsValidation.invalidRetailerId');

const folderTextRegex = /^[-a-zA-Z0-9-_ ]*$/;
export const folderTextValidator = isValidFormat(folderTextRegex, 'generic.formsValidation.invalidFolderName');

export const tagsTextValidator = isValidFormat(folderTextRegex, 'generic.formsValidation.invalidFolderName');

const numberRegex = /^[-|+]?\d{0,5}(\.\d{1,2})?$/;
export const decimalValidator = isValidFormat(numberRegex, 'generic.formsValidation.invalidDecimal');

const numberWithDecimalsRegex = /^[-|+]?\d{0,5}\.\d{1,2}$/;
export const mandatoryDecimalValidator = isValidFormat(
  numberWithDecimalsRegex,
  'generic.formsValidation.invalidDecimal'
);

const integerRegex = /^-?\d+$/;
export const integerValidator = isValidFormat(integerRegex, 'generic.formsValidation.invalidInteger');

export const notZero = (value) =>
  (value && Number(value) === 0 && i18n.t('generic.formsValidation.notZero')) || undefined;

export const minimumValueAllowed = (
  minVal: number,
  decimalPlaces = 2,
  msg = 'generic.formsValidation.minPossibleValue'
) =>
  handleEmpty((value) => {
    const parsedValue = Number(value);
    return parsedValue < Number(minVal.toFixed(decimalPlaces))
      ? i18n.t(msg, { value: minVal.toFixed(decimalPlaces) })
      : undefined;
  });

export const maxValueAllowed = (maxVal: number, decimalPlaces = 2, msg = 'generic.formsValidation.maxPossibleValue') =>
  handleEmpty((value) => {
    const parsedValue = Number(value);
    return parsedValue > maxVal ? i18n.t(msg, { value: maxVal.toFixed(decimalPlaces) }) : undefined;
  });

/* ********************* */
/* -- Date Validators -- */
/* ********************* */
const TODAY = new Date();

export const dateIsOnFuture = (value: string) => {
  const isValid = moment(value).isSameOrAfter(TODAY, 'day');

  return handleValidation(isValid, 'generic.formsValidation.dateOnFuture');
};

export const dateIsOnPast = (value: string) => {
  const isValid = moment(value).isSameOrBefore(TODAY, 'day');

  return handleValidation(isValid, 'generic.formsValidation.dateOnPast');
};

export const timeIsOnFuture = (currentTime: moment.Moment, inputTime: moment.Moment) => {
  let isTimeInFuture;

  const isToday = moment(inputTime).isSame(TODAY, 'day');

  const isFutureDay = moment(inputTime).isAfter(TODAY, 'day');

  if (isToday) {
    isTimeInFuture = inputTime.isSameOrAfter(currentTime);
  }
  if (isFutureDay) {
    isTimeInFuture = true;
  }

  return handleValidation(isTimeInFuture, 'generic.formsValidation.timeOnFuture');
};

const percentageValidation: Validator[] = [decimalValidator, minimumValueAllowed(0.01, 2), maxValueAllowed(100)];

export const percentageFieldProps: IPercentageFieldProps = {
  validate: percentageValidation,
  inputMode: 'numeric',
  max: 100,
  min: 0.01,
  digits: 2,
  suffix: '%',
};

/* ********************* */
/* -- Misc Validators -- */
/* ********************* */
export const isDuplicate = (list: any[], item: any, customErrorText?: string) =>
  handleValidation(!list.includes(item), customErrorText || 'generic.formsValidation.duplicate');
