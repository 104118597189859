import * as React from 'react';
import { Text, Icon } from 'framework/components/ui';
import { clx } from '../Event';

const localePath = 'components.calendar.events.template.eventBlocker.';

const EventBlocker = React.memo((props: any) => {
  const { data = {} } = props;
  const { shoppingModes = [], note } = data;

  return (
    <ul className={`${clx}__list`}>
      <li>
        <Text caption={`${localePath}title`} />
        {!!note && <Icon name="file" className={`${clx}__template__icon-message`} />}
      </li>

      {shoppingModes.map(sm => (
        <li key={sm.value}>{sm.label}</li>
      ))}
    </ul>
  );
});

export default EventBlocker;
