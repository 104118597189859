import { errorHandler } from 'framework/services/errorHandler';
import { IFetchDataRequest, IFetchDataResponse, MetadataObject } from './types';
import { renewSession } from '../utils/renewSession';

export class FetchError extends Error {
  name = 'fetch-data';

  cause: Error | object | string;

  constructor(e, request: IFetchDataRequest, status, rawResponse) {
    super(`${status} ${rawResponse || 'EMPTY'} - Request: ${JSON.stringify(request || {})}`);
    this.cause = e;
  }
}

/**
 * Handle errors returned from the api Gateway
 */
export function handleError<T>(
  error,
  metadata: MetadataObject,
  request: IFetchDataRequest,
  status,
  rawResponse
): IFetchDataResponse<T> {
  if (status !== 404) {
    errorHandler(new FetchError(error, request, status, rawResponse));
  }

  if (status === 401) {
    renewSession();
  }

  return {
    success: false,
    data: {} as T,
    error,
    appError: request?.mapErrors ? request.mapErrors(rawResponse) : undefined,
    status,
    metadata,
  };
}
