import * as React from 'react';
import { Select } from 'ui';
import { featureFlag } from 'featureFlag';
import { IDropdownInterface } from '../StoreFrontSettings.interfaces';
import ContactSupport from './ContactSupport';

const MultidropdownType = (props: IDropdownInterface) => {
  const {
    component, handleChange, field, value, id,
  } = props;

  const hasValue = value !== undefined && value !== null;

  const handleDropdownChange = (fieldValues) => {
    handleChange(
      field,
      fieldValues.map((content) => content.value),
    );
  };

  const generateValues = () => component.options.map((option) => ({
    value: option,
    label: option,
  }));

  return (
    <>
      {!hasValue && <ContactSupport />}
      <Select
        name={`${id}-${field}`}
        caption={component.title}
        clearable={false}
        searchable={false}
        onChange={handleDropdownChange}
        options={generateValues()}
        multi
        disabled={!hasValue}
        initValue={value}
        explainerText={component.description}
        {
          ...featureFlag({
            support: () => ({...component.helpLink && ({
              explainerLink: {
                caption: 'generic.readMore',
                to: component.helpLink,
                external: true,
              }})}),
            prod: () => {},
          })
        }
        grid
      />
    </>
  );
};

export default MultidropdownType;
