import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import LinkPrimitive from 'framework/components/ui/Primitives/LinkPrimitive';
import CardPrimitive from 'framework/components/ui/Primitives/CardPrimitive';
import { ICardProps } from './Card.interfaces';
import './_style.scss';

const className = prefixClassName('card');

export class Card extends React.Component<ICardProps & { children: React.ReactNode }> {
  render() {
    const { caption, children, icon, to } = this.props;

    const card = (
      <CardPrimitive caption={caption} icon={icon}>
        {children}
      </CardPrimitive>
    );

    return to ? (
      <LinkPrimitive className={className} to={to} onClick={this.props.onClick}>
        {card}
      </LinkPrimitive>
    ) : (
      <button className={className} onClick={this.props.onClick}>
        {card}
      </button>
    );
  }
}

export default Card;
