import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest } from 'framework/api/make-request';
import { config } from 'config';
import { call, delay, put } from 'redux-saga/effects';
import { IFetchDataResponse } from 'framework/api/fetch-data';
import { actionTypes, PollingStoreHierarchyProps, setStoreHierarchyPolling } from '../actions';
import { pollingCount, pollingDelay } from './constant';

function* pollServer({ storeId, parentId }: PollingStoreHierarchyProps) {
  let loop = pollingCount;
  while (loop) {
    try {
      const res: IFetchDataResponse<string[]> = yield call(makeRequest, {
        name: actionTypes.START_POLLING_STOREHIERARCHIES,
        url: `${config.apiEndpoints.orghierarchies}/pathtostore/${storeId}`,
      });
      const { success } = res;
      if (success) {
        const newId = res.data?.[res.data?.length - 2];
        if (newId === parentId) {
          return true;
        }
      }
    } catch {
      loop = 0;
    }

    if (loop) {
      loop -= 1;
      yield delay(pollingDelay);
    }
  }
  return false;
}

function* startPooling({ payload: { storeId, parentId } }) {
  yield put(setStoreHierarchyPolling({ pollingStatus: 'pooling' }));
  const done = yield pollServer({ storeId, parentId });

  yield put(setStoreHierarchyPolling({
    pollingStatus: 'done',
    pollingError: done ? null : 'modules.stores.storeHierarchyPoolingFailed',
  }));
  yield put(setStoreHierarchyPolling({
    pollingStatus: 'idle',
    pollingError: undefined,
  }));
}

export default function* watchPoolingStoreHierarchy() {
  yield takeEveryWithProgressBar(
    [actionTypes.START_POLLING_STOREHIERARCHIES],
    startPooling,
  );
}
