import { APIError, AppError, HttpMethod } from 'types';

export enum ContentTypes {
  Json = 'application/json',
  domainModelSaveJson = 'application/json;domain-model=savediscountorcharge',
  domainModelUpdateJson = 'application/json;domain-model=activatediscountorcharge',
  PatchJson = 'application/json-patch+json',
  PDF = 'application/pdf',
  Apk = 'application/vnd.android.package-archive'
}

export interface IFetchDataRequest<E = any> {
  /**
   * Full url
   */
  url: string;
  /**
   * Default: GET
   */
  method?: HttpMethod;
  body?: any;
  /**
   * query string parameters
   */
  query?: object;
  /**
   * When this value is set, fetchData will return the content in /public/__mocks__/${mock}.json file
   */
  mock?: string;
  /**
   * For requests that require "If-Match" header
   */
  eTag?: string;
  /**
   * @param rawResponse api raw error response to be mapped
   */
  mapErrors?: (rawResponse: any) => AppError<E>;
  /**
   * For setting specific content in the request
   */
  contentType?: ContentTypes;
  headers?: { [k: string]: string };
}

export interface MetadataObject {
  eTag?: string;
}

export interface CustomHeaders {
  responseType?: string;
}

export interface IFetchDataResponse<T, E = any> {
  success: boolean;
  data?: T;
  /**
   * raw error
   */
  error?: APIError;
  /**
   * mapped error
   */
  appError?: AppError<E>;
  status?: number;
  metadata: MetadataObject;
}

export interface GridResponseData<T, O = any> {
  total: number;
  items: T[];
  count?: number;
  options?: O;
}
