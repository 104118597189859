import { history } from 'framework/store';
import { LIST_URL_FULL, CREATE_URL_PARAM } from './constants';
import { Product } from './models/product';

/**
 * Goes to the Products listing page
 */
export const goToList = () => history.push(LIST_URL_FULL);

export const getEditUrl = (product?: Product) =>
  `${LIST_URL_FULL}/${(product && product.id) || CREATE_URL_PARAM}`;

/**
 * Goes to the Product's edition/creation page
 * @param product if null goes to creation page
 */
export const goToEdit = (product?: Product) => history.push(getEditUrl(product));

export const enumToOptions = (obj: Object) => Object.keys(obj).map(u => ({ value: obj[u], label: u }));