import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import translations, { ListingTranslations } from './translations';
import { PATH_MAIN } from './constants';
import {
  recommendationsListRoute,
  recommendationCreateRoute,
  recipesListRoute,
  recipeCreateRoute,
  listingListRoute,
  listingCreateRoute,
  recipesClaims,
  recommendationsListClaims,
  listingsClaims,
} from './routes';

const globalRecipesClaims = {
  [AuthorizationClaims.RECIPES_GLOBAL]: UserAccessRights.ReadWrite,
  ...recipesClaims,
};

const globalRecommendationsAndListingsClaims = {
  [AuthorizationClaims.RECOMMENDATIONS_AND_LISTINGS_GLOBAL]: UserAccessRights.ReadWrite,
  ...recommendationsListClaims,
  ...listingsClaims,
};

const MerchandisingModule: ModuleConfig = {
  name: 'merchandising',
  appMenuTitle: translations.Merchandising,
  appMenuIcon: 'starHalf',
  modulePath: PATH_MAIN,
  additionalClaims: [
    {
      menuTitle: translations.GlobalListingsAndRecommendations,
      authClaim: globalRecommendationsAndListingsClaims,
    },
    {
      menuTitle: translations.GlobalRecipes,
      authClaim: globalRecipesClaims,
    },
  ],
  pageGroups: [
    {
      title: translations.Recommendations,
      routes: [
        recommendationsListRoute,
        recommendationCreateRoute,
      ],
    },
    {
      title: ListingTranslations.Listings,
      routes: [
        listingListRoute,
        listingCreateRoute,
      ],
    },
    {
      title: translations.RecipesTitle,
      routes: [
        recipesListRoute,
        recipeCreateRoute,
      ],
    },
  ],
};

export default MerchandisingModule;
