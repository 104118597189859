import { createSelector } from 'reselect';
import { safeGet } from 'framework/utils';
import { calendarSelectors, apiClientSelectors, formSelectors } from 'framework/selectors';
import {
  API_DATA_SLOTS_CALENDAR_OPTIONS, API_DATA_SLOTS_CALENDAR_EDIT,
} from 'framework/modules/slotManagement/constants';
import { CALENDAR_NAME, FORM_FILTER } from '../constants';

const getCalendarIniData = state => apiClientSelectors.selectApiClientData(
  state, API_DATA_SLOTS_CALENDAR_EDIT,
);

export const getStore = createSelector(
  [getCalendarIniData],
  state => state.store,
);

export const getStoreTimeZone = createSelector(
  [getCalendarIniData],
  state => state.storeTimeZone,
);

export const getSlotsUsage = createSelector(
  [getCalendarIniData],
  data => data.usage || [],
);

export const getFilterOptions = state => {
  // Default values to populate dropdowns and prefill inputs
  const apiData = apiClientSelectors.selectApiClientState(
    state, API_DATA_SLOTS_CALENDAR_OPTIONS,
  );

  return {
    ...apiData,
    shoppingModes: apiData.shoppingModeOptions,
    storeTimeZone: apiData.storeTimeZone,
  };
};

export const getCalendarIsDirty = state => calendarSelectors.isDirty(state, CALENDAR_NAME);
export const getEventSelected = state => calendarSelectors.getSelectedEvent(state, CALENDAR_NAME) || {};

export const getEventSelectedData = createSelector(
  [getEventSelected],
  eventSelectedState => safeGet(eventSelectedState, 'data', {}),
);

// Form Filter
export const getFilterValues = state => formSelectors.getFormFieldValues(state, FORM_FILTER);

export const getStoreId = createSelector(
  [getStore],
  store => (store && store.value.storeId) || undefined,
);
