import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import {
  PATH_CUSTOMERS,
  PATH_CUSTOMER_EDIT,
  PATH_CRM,
  RESEND_EMAIL_CLAIM,
  CUSTOMER_CREDITS_CLAIM,
  CUSTOMER_IMPERSONATE_CLAIM,
  CUSTOMER_RESET_PASSWORD_CLAIM,
  CUSTOMER_CREDITS_READONLY_CLAIM,
  CUSTOMER_COMMENTS_CLAIM,
  CUSTOMER_BUSINESS_ACCOUNTS_CLAIM,
  CUSTOMER_VIEW_SUBSCRIPTIONS_CLAIM,
  CUSTOMER_CANCEL_SUBSCRIPTIONS_CLAIM,
  CUSTOMER_SEGMENTS_CLAIM,
} from './constants';
import { CRMTranslations } from './translations';

const customerClaims = {
  [AuthorizationClaims.CUSTOMER_CUSTOMERS]: UserAccessRights.ReadWrite,
  ...CUSTOMER_CREDITS_READONLY_CLAIM,
  [AuthorizationClaims.ORDERMANAGEMENT_ORDERS]: UserAccessRights.Read,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
};

export const customersListRoute: AppRoute = {
  pageTitle: CRMTranslations.Customers,
  menuTitle: CRMTranslations.AllCustomers,
  path: `${PATH_CRM}${PATH_CUSTOMERS}`,
  authClaim: customerClaims,
  extendedClaims: [
    RESEND_EMAIL_CLAIM,
    CUSTOMER_CREDITS_CLAIM,
    CUSTOMER_IMPERSONATE_CLAIM,
    CUSTOMER_RESET_PASSWORD_CLAIM,
    CUSTOMER_COMMENTS_CLAIM,
    CUSTOMER_BUSINESS_ACCOUNTS_CLAIM,
    CUSTOMER_VIEW_SUBSCRIPTIONS_CLAIM,
    CUSTOMER_CANCEL_SUBSCRIPTIONS_CLAIM,
    CUSTOMER_SEGMENTS_CLAIM
  ],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/Customers'),
};

export const customersEditRoute: AppRoute = {
  pageTitle: CRMTranslations.Customer,
  path: `${PATH_CRM}${PATH_CUSTOMERS}${PATH_CUSTOMER_EDIT}/:customerId`,
  authClaim: customerClaims,
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/CustomerEdit'),
  parent: customersListRoute,
};

export const crmRoutes: AppRoute[] = [customersListRoute, customersEditRoute];
