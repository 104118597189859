/* eslint-disable import/no-cycle */
import * as React from 'react';
import classNames from 'classnames';
import { toCamelCase, toKebabCase } from 'framework/utils/string';
import { TagValue } from 'framework/components/ui/Tag/interfaces';
import { prefixClassName } from '../../../_conf';
import { Select as Slct, Tag } from '../../..';
import { SelectOption } from '../Select/Select';

const clx = prefixClassName('filter-bar__input');

interface SelectProps {
  className?: string;
  placeholder: string;
  name: string;
  options: SelectOption[];
  value?: string;
  onChange: (option?: SelectOption) => void;
  searchable?: boolean;
  readOnly?: boolean;
  useLabel?: boolean;
}

const SelectStatus = ({
  className,
  options,
  onChange,
  placeholder,
  name,
  value,
  readOnly = false,
  searchable = false,
  useLabel = false,
}: SelectProps) => {
  const opts = options.map((option: SelectOption) => ({
    label: <Tag 
      caption={`generic.status.${toCamelCase(useLabel ? option.label : option.value)}`} 
      value={TagValue[toKebabCase(useLabel ? option.label : option.value)]} 
    />,
    value: option.value,
  }));

  return <Slct
    labelInline
    className={classNames(clx, className)}
    onChange={onChange}
    placeholder={placeholder}
    name={name}
    value={value}
    options={opts}
    searchable={searchable}
    readOnly={readOnly}
  />;
};

export { SelectStatus };
