import * as React from 'react';
import { SearchBar } from 'ui';
import { DataGridDouble } from 'framework/modules/searchadmin/components';
import {
  DATA_GRID_RULES_PREVIEW,
  DEFAULT_STORE_ID,
  FORM_RULES_CREATE_RULES,
} from 'framework/modules/searchadmin/constants';
import { ProductScore } from 'framework/modules/searchadmin/models/product';
import { ProductModal, openProductModal } from 'framework/modules/searchadmin/components/ProductModal/ProductModal';
import { SearchConfiguration } from 'framework/modules/searchadmin/models/searchConfiguration';
import { fetchScore } from 'framework/modules/searchadmin/api/score';
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import { SkipTake } from 'types';
import { ProductResults } from 'framework/modules/searchadmin/components/DataGridDouble/DataGridDouble.interface';
import { processRulesDataForPreview } from '../../sagas/helpers';
// eslint-disable-next-line import/no-cycle
import { PreviewViews } from '../../RuleCreateEditContent';

interface PreviewContentProps {
  previewData: ProductResults;
  showGrids: boolean;
  previewStoreId: string;
  enablePreview: boolean;
  isLoading: boolean;
  searchTerm: string;
  previewCurrentData: ProductResults;
  view: PreviewViews;
  fetchPreviewCurrentData: (skipTake: SkipTake & { currentPage: number }) => void;
}

export default (props: PreviewContentProps) => {
  const {
    previewCurrentData,
    previewData,
    showGrids,
    fetchPreviewCurrentData,
    previewStoreId,
    enablePreview,
    isLoading = true,
    searchTerm,
    view = PreviewViews.CurrentAndPreview,
  } = props;

  const { getValues } = useForm(FORM_RULES_CREATE_RULES);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [sku, setSku] = React.useState(undefined);

  const getGridData = (skipTake?: SkipTake) => {
    fetchPreviewCurrentData({ currentPage, ...(skipTake && { ...skipTake }) });
  };

  const paginationReset = () => {
    setCurrentPage(1);
  };

  const onSearchHandler = () => {
    paginationReset();
    getGridData();
  };

  const dataGridShowNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const dataGridShowPrev = () => {
    setCurrentPage(currentPage + -1);
  };

  const selectProduct = (data: ProductScore) => {
    setSku(data.sku);
    openProductModal();
  };

  React.useEffect(() => {
    getGridData();
  }, [currentPage, previewStoreId]);

  const fetchProductScore = async ({ productId }: ProductScore, configurationOverride?: SearchConfiguration) => {
    const formData: { previewStoreId: { value: string }; searchForProducts: string } = getValues();
    const storeId = (formData.previewStoreId && formData.previewStoreId.value) || DEFAULT_STORE_ID;

    const result = await fetchScore({
      relevance: false,
      productId,
      configurationOverride,
      storeId,
      searchTerm: formData.searchForProducts,
    });

    return result.data;
  };

  const showPreviewScoreExplanation = (product: ProductScore) => {
    const formData = getValues();
    const configurationOverride: SearchConfiguration = processRulesDataForPreview(formData);

    return fetchProductScore(product, configurationOverride);
  };

  return (
    <div>
      <SearchBar
        className="mrg-btm-20"
        name="searchForProducts"
        placeholder="modules.merchandising.searchResults.filterBarPlaceholder"
        searchFunction={onSearchHandler}
        debounceTime={400}
      />
      <DataGridDouble
        showGrids={showGrids}
        pageSize={20}
        getGridData={getGridData}
        name={DATA_GRID_RULES_PREVIEW}
        selectProduct={selectProduct}
        showCurrentScoreExplanation={fetchProductScore}
        showPreviewScoreExplanation={showPreviewScoreExplanation}
        current={previewCurrentData}
        preview={enablePreview ? previewData : { total: 0 }}
        defaultText={[
          'modules.merchandising.rules.createAndEdit.textPreview_01',
          'modules.merchandising.rules.createAndEdit.textPreview_02',
        ]}
        currentPage={currentPage}
        onNext={dataGridShowNext}
        onPrevious={dataGridShowPrev}
        isLoading={isLoading}
        searchTerm={searchTerm}
        view={view}
      />
      {sku && <ProductModal sku={sku} storeId={previewStoreId} />}
    </div>
  );
};
