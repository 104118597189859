import { connect } from 'react-redux';
import ProgressBar from './index';
import { IProgressBarProps } from './ProgressBar.interface';

const mapStateToProps = (state: IProgressBarProps) => ({
  inProgress: state.inProgress
});

const ProgressBarContainer = connect(mapStateToProps)(ProgressBar);

export default ProgressBarContainer;
