import { SaveLayoutWidthArgs } from './Interfaces';

const cssRoot = 'mwg-cms-layout-width';
const transRoot = 'modules.contentmanagement.layouts';

export const SAVE_LAYOUT_WIDTH = 'SAVE_LAYOUT_WIDTH';

export const cssClasses = {
  root: cssRoot,
  label: `${cssRoot}__label`,
  text: `${cssRoot}__text`,
  wrap: `${cssRoot}-wrap`,
  title: `${cssRoot}-title`,
  explainer: 'mwg-form-field-wrapper__explainer',
};

export const translations = {
  title: `${transRoot}.width_title`,
  contentWidth: `${transRoot}.content_width`,
  browserWidth: `${transRoot}.browser_width`,
  description: `${transRoot}.width_description`,
};

export const actions = {
  saveLayoutWidth: ({ type, rowIndex }: SaveLayoutWidthArgs) => ({
    type: SAVE_LAYOUT_WIDTH,
    payload: { type, rowIndex },
  }),
};
