import * as React from 'react';
import { SVG } from 'framework/components/ui/SVG';
import { Link } from 'framework/components/ui/Link';
import i18n from 'i18n';
import logo from '../../../../assets/images/logo.svg';
import { prefixClassName } from '../_conf';

const className = prefixClassName('logo');

export default () => (
  <Link className={className} to="/" caption="">
    <SVG src={logo} alt={i18n.t('generic.logoAlt')} />
  </Link>
);
