import { ResolveType, InferableFunction } from 'types';

type cacheAction = 'get' | 'delete';

const actions = {
  get: async (cache, key, callFn) => {
    const data = cache[key];

    // returns data from cache if it exists
    if (data) {
      return data;
    }

    // calls the function
    const result = await callFn();

    // saves in cache
    if (result) {
      cache[key] = result;
    }

    return result;
  },
  delete: (cache, key) => {
    cache[key] = undefined;
    delete cache[key];
  },
};

/**
 * Caches a function call
 * @param cachedFn the function to be cached
 * @param getCacheKey a function that returns the cache key based on the parameters passes
 */
export const cacheable = <T extends InferableFunction>(
  cachedFn: T,
  getCacheKey: (...args: Parameters<T>) => string,
) => {
  const cache = {};

  return async (action: cacheAction, ...args: Parameters<T>): Promise<ResolveType<T>> => {
    const key = getCacheKey(...args);

    const callFn = async () => {
      const result = await cachedFn(...args);
      return result;
    };

    return actions[action](cache, key, callFn);
  };
};
