import * as React from 'react';
import { CalendarTemplate } from './Calendar.interfaces';

// Initialize a context
const CalendarContext = React.createContext({
  template: '',
  data: {},
} as {
  template: CalendarTemplate;
  data: any
});

const useCalendar = () => {
  const context = React.useContext(CalendarContext);
  if (!context) {
    throw new Error(
      'Calendar Components cannot be rendered outside the <Calendar />',
    );
  }

  return context;
};

export { CalendarContext, useCalendar };
