import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text } from 'ui';
import { ExplorerProps, BaseNode } from './types';
import { useExplorer } from './useExplorer';
import { ExplorerColumn } from './ExplorerColumn';

import './Explorer.scss';

const cssClass = prefixClassName('explorer');
const baseClass = prefixClassName('');

export function Explorer<T extends BaseNode<T>>({
  data, idProp, onRowClick, renderColumns: renderRow,
  columnMetadata, preview: Preview, delayedAnim, onSelection, requiredFilesFormat,
  onRowClass, externalSelection, onExplorerSelection, showPreview = true,
}: ExplorerProps<T>) {
  const {
    isSelected, handleRowClick, levelPanes, displayNoData,
  } = useExplorer({
    idProp, onRowClick, data, onSelection, externalSelection, onExplorerSelection,
  });

  const previewRef: React.MutableRefObject<any> = React.useRef(null);

  React.useEffect(() => {
    if (previewRef && displayNoData) {
      previewRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [previewRef?.current?.offsetLeft, externalSelection]);

  return (
        <div className={cssClass}>
          {levelPanes.map((level) => (
              <ExplorerColumn
                key={level.level}
                level={level.level}
                data={level.data}
                idProp={idProp}
                delayedAnim={delayedAnim}
                columnMetadata={columnMetadata}
                isSelected={isSelected}
                renderColumns={renderRow}
                onRowClick={(row, l): Promise<void> => {
                  previewRef.current.scrollIntoView({ behavior: 'smooth' });

                  return handleRowClick(row, l);
                }}
                onRowClass={onRowClass}
                requiredFilesFormat={requiredFilesFormat}
              />
          ))}
         <div ref={previewRef} className={`${cssClass}__preview ${!showPreview && `${baseClass}hidden`}`}>
            {(!Preview && displayNoData && showPreview) && <Text className={`${cssClass}__no-data`}
                                                    tag="span" caption="generic.dataFetchError" />}
            {(Preview && typeof Preview === 'function' && showPreview) ? <Preview /> : Preview}
          </div>
        </div>
  );
}
