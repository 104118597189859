import * as React from 'react';
import * as get from 'lodash.get';
import { useProgressBar as withProgress } from 'framework/components/ui/ProgressBar/useProgressBar';
import { useModal } from 'framework/components/ui/Modal/useModal';
import { CONFIRMATION_MODAL_NAME } from 'framework/components/ui/ConfirmationModal/constants';
import * as services from './services';
import { CmsDamFolder, DamMode, DAM_MODAL } from './Types';
import { translations } from './translations';

interface Props {
  mode: DamMode
  onDismiss?: () => void;
  onFileUploaded?: (file: any) => void;
}

export const useDamInternals = (props: Props) => {
  const { mode, onDismiss, onFileUploaded } = props;
  const [internalMode, setInternalMode] = React.useState(mode);

  const { openModal: openModalDAM, closeModal: closeModalDAM } = useModal(DAM_MODAL);
  const { openModal: openModalConf, closeModal: closeModalConf } = useModal(CONFIRMATION_MODAL_NAME);

  // local data: folders and files
  const [folders, storeData] = React.useState<CmsDamFolder[]>();

  const [loading, setLoading] = React.useState();

  // stores expanded folders reference
  const [expandedFolders, setExpandedFolders] = React.useState([]);

  // stores highlighted folder
  const [highlightedFolder, setHighlightedFoder] = React.useState(null);

  // indicates whether upload will overwrite existing file
  const [overWriteFile, setOverWriteFile] = React.useState(false);

  // stores server error when upload fails
  const [uploadError, setUploadError] = React.useState(null);

  // temporary stores file info to be uploaded when user clicks 'upload button'
  const [uploadableFile, setUploadableFile] = React.useState();

  const [deletableFile, setDeletableFile] = React.useState();

  const withLoading = callback => {
    setLoading(true);
    return callback().finally(() => {
      setLoading(false);
    });
  };

  // retrieve files from server payload
  const getFiles = () => (internalMode === DamMode.file && !!folders
    ? folders.filter(d => d.type === DamMode.file && d.parent === highlightedFolder) : []);

  // retrieve folders from server payload
  const getFolders = () => {
    const data = folders || [];
    return data.filter(d => d.type === DamMode.directory);
  };

  // fetch folders
  const fetchFolders = withProgress(async () => withLoading(() => services.fetchWithProgress({
    data: folders || [], storeData, includeFiles: internalMode === DamMode.file,
  })));

  // fetch subfolders + files
  const fetchSubFolders = () => withLoading(() => services.fetchSubFolders({
    data: folders || [],
    includeFiles: true,
    reference: highlightedFolder,
    storeData,
  }));

  // expanse/collapse folder
  const toggleExpandFolder = reference => {
    const isExpanded = expandedFolders.find(r => r === reference);
    if (isExpanded) {
      // collapse
      setExpandedFolders(expandedFolders.filter(r => r !== reference));
    } else {
      // fetch and expand
      const fetchWithProgress = withProgress(async () => {
        services.fetchSubFolders({
          data: folders,
          reference,
          storeData,
          includeFiles: internalMode === DamMode.file,
        });
      });
      fetchWithProgress().then(() => {
        setExpandedFolders([...expandedFolders, reference]);
      });
    }
  };

  // User can select (highlight) or deselect ('un-hilghlight') a folder
  const toggleHighlightFolder = reference => {
    const busy = folders.filter(f => f.busy).length > 0;
    if (busy) {
      return;
    }
    setHighlightedFoder(reference === highlightedFolder ? null : reference);
  };

  // display/hide modal
  const toggleVisible = () => {
    if (mode) {
      setInternalMode(mode);
      openModalDAM();
    } else {
      closeModalDAM();
    }
  };

  const clearDam = () => {
    setExpandedFolders([]);
    setHighlightedFoder(null);
    setOverWriteFile(null);
  };

  // on modal dismiss
  const handleOnDismiss = () => {
    clearDam();
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleSaveFile = () => {
    let error = null;
    if (!highlightedFolder) {
      error = translations.shouldSelectLocation;
    }

    if (!uploadableFile) {
      error = translations.fileNotFound;
    }
    if (error) {
      // you should select a location
      setUploadError(error);
    } else {
      services.uploadFile({
        file: uploadableFile,
        folders: getFolders(),
        overwrite: overWriteFile,
        path: highlightedFolder,
        storeFolders: storeData,
      }).then(res => {
        if (res.error) {
          const fileNameError = get(res, 'error.errors.fileName', res.error);
          setUploadError(fileNameError);
        } else if (onFileUploaded) {
          clearDam();
          onFileUploaded(res.data);
        }
      });
    }
  };

  const handleFileSelected = file => {
    if (internalMode === DamMode.directory) { // upload
      setUploadableFile(file);
    } else if (onFileUploaded) { // download
      clearDam();
      onFileUploaded(file);
    }
  };

  const toggleConfirmDeleteFileModal = open => {
    if (open) {
      openModalConf();
    } else {
      closeModalConf();
    }
  };

  const handleConfirmDeleteFile = file => {
    setDeletableFile(file);
    toggleConfirmDeleteFileModal({ open: true });
  };

  const deleteFile = () => {
    if (!deletableFile) {
      return;
    }
    toggleConfirmDeleteFileModal(false);

    const deleteWithProgress = withProgress(async () => services.deleteFile({
      reference: deletableFile.reference,
      setUploadError,
    }));

    deleteWithProgress().then((res: any) => {
      if (!res.errors) {
        storeData(folders.filter(d => d.reference !== deletableFile.reference));
        setDeletableFile(null);
      }
    });
  };

  const closeDam = () => {
    closeModalDAM();
    handleOnDismiss();
  };

  React.useEffect(toggleVisible, [mode]);

  const fetchFoldersEffecth = () => {
    fetchFolders();
  };
  React.useEffect(fetchFoldersEffecth, []);

  const fetchSubFoldersEffect = () => {
    if (internalMode === DamMode.file && !!highlightedFolder) {
      fetchSubFolders();
    }
  };
  React.useEffect(fetchSubFoldersEffect, [highlightedFolder]);

  return {
    folders: getFolders(),
    files: getFiles(),
    loading,

    // expand/collapse folders
    expandedFolders,
    toggleExpandFolder,

    // highlight folder
    highlightedFolder,
    toggleHighlightFolder,

    overWriteFile,
    setOverWriteFile,

    uploadError,

    closeDam,
    toggleVisible,

    handleOnDismiss,
    handleSaveFile,

    handleFileSelected,
    handleConfirmDeleteFile,
    toggleConfirmDeleteFileModal,

    deleteFile,

    internalMode,
    setInternalMode,
  };
};
