import { connect } from 'react-redux';
import { tabsActions } from 'framework/actions';
import { CommonPage as CommonPageComponent } from './CommonPage';
import { CommonPageActions, CommonPageProps } from './CommonPage.Interfaces';
import { loadUserAction, clearUser, storePageFlow } from './CommonPage.Actions';
import { getUser } from './CommonPage.Selectors';
import { SITEADMIN_USER_TABS, SITEADMIN_USER_TABS_DETAILS } from './CommonPage.Constants';

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch): CommonPageActions => ({
  resetTabs: () => dispatch(tabsActions.setOpenTab(SITEADMIN_USER_TABS, SITEADMIN_USER_TABS_DETAILS)),
  loadUser: ({ userid }) => dispatch(loadUserAction({ userid })),
  clearUser: () => dispatch(clearUser()),
  storePageFlow: ({ pageFlow }) => dispatch(storePageFlow({ pageFlow })),
});

export const CommonPage: React.StatelessComponent<CommonPageProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonPageComponent) as any;
