import './_style.scss';

import * as React from 'react';
import Navigation from 'framework/components/navigation';
import { prefixClassName } from 'framework/components/ui/_conf';
import {
  LayoutFlexBox,
  LayoutFlexBoxItem,
  ProgressBar,
} from 'ui';
import PageRouting from 'framework/pageRenderer/PageRouting';
import { ToastStore } from '../ToastStore/ToastStore';

const componentClassName = prefixClassName('main');

const Main = () => (
  <LayoutFlexBox className={`${componentClassName}`}>
    <ProgressBar />
    <LayoutFlexBoxItem
      className={`${componentClassName}__nav-container`}
      flexGrow={1}
    >
      <Navigation />
    </LayoutFlexBoxItem>
    <LayoutFlexBoxItem flexGrow={1}>
      <PageRouting />
      <ToastStore />
    </LayoutFlexBoxItem>
  </LayoutFlexBox>
);

export { Main as default };
