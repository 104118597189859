import { takeEvery, put } from 'redux-saga/effects';
import { apiClientActions, modalActions } from 'framework/actions';
import CONST from '../constants';

export default function* watchModalProductSearchClose() {
  yield takeEvery(CONST.ACTION_MODAL_CLOSE, process);
}

function* process() {
  // 1. Hidde Modal
  yield put(modalActions.createCloseModalAction(CONST.MODAL_NAME));

  // 2. Clear API data
  yield put(
    apiClientActions.clearApiClientStateAction({
      name: CONST.API_DATA_NAME
    })
  );
}
