import classnames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import Icon from 'framework/components/ui/Icon';
import Text from 'framework/components/ui/Text';
import { ITab } from './Tab.interfaces';

const componentClass = `${prefixClassName('tabs')}__tab`;

export class Tab extends React.PureComponent<ITab> {
  static defaultProps: Partial<ITab> = {
    isOpen: false,
    completed: false,
    disabled: false,
  };


  onClickHandler = (e) => {
    const { onClick, disabled } = this.props;

    if (!disabled && onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      className,
      disabled,
      isOpen,
      completed,
      label,
    } = this.props;

    return (
      <button
        type="button"
        className={classnames(
          componentClass,
          className,
          isOpen && `${componentClass}--checked`,
          completed && `${componentClass}--completed`,
          disabled && `${componentClass}--disabled`,
          isOpen && className && `${className}--checked`,
          completed && className && `${className}--completed`,
          disabled && className && `${className}--disabled`,
        )}
        id={`${label}-id`}
        disabled={disabled}
        onClick={this.onClickHandler}
      >
        <Text caption={label} />
        {completed && <Icon name="checkmark" className={`${componentClass}__icon`} />}
      </button>
    );
  }
}

export default Tab;
