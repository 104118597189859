import * as React from 'react';
import { Loader } from 'ui';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import Template from './templates/default/default';
import { Campaign, Moved, Saved, Score as ScoreTag } from './labels';
import { ProductScore } from '../../models/product';
import { ScoreExplanation } from '../../models/score';
import './_style.scss';

const clx = prefixClassName('grid-score');
const defaultShowlabels: IDataGridScoreLabels[] = ['score'];

export type IDataGridScoreLabels = 'score' | 'campaign' | 'customPlacement' | 'moved';
export type IDataGirdScoreTooltipTemplate = 'default';
export interface IDataGridScore {
  product: ProductScore;
  // eslint-disable-next-line react/no-unused-prop-types
  showLabels?: IDataGridScoreLabels[];
  saved?: boolean;
  loadExplanation: (product: ProductScore) => Promise<ScoreExplanation>;
}

interface LabelProps extends IDataGridScore {
  labelName: IDataGridScoreLabels;
}

const Label = ({ labelName, product, loadExplanation, saved }: LabelProps) => {
  const [explainData, setExplainData] = React.useState<ScoreExplanation>();
  const [isFetching, setIsFetching] = React.useState<boolean>();
  const { score, customPlacement, isInCampaign, positionDifference } = product;

  React.useEffect(() => {
    setExplainData(undefined);
    setIsFetching(false);
  }, [product]);

  const fetchExplain = () => {
    if (!explainData) {
      setIsFetching(true);
      loadExplanation(product)
        .then((result) => {
          setExplainData(result);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  switch (labelName) {
    case 'campaign':
      return (isInCampaign && <Campaign />) || <></>;
    case 'customPlacement':
      return (customPlacement && <Saved saved={saved} />) || <></>;
    case 'moved':
      return <Moved value={positionDifference} /> || <></>;
    case 'score':
    default:
      return (
        <ScoreTag
          value={score}
          tooltipBody={isFetching ? <Loader size="sm" /> : <Template tooltipData={explainData || {}} />}
          onClick={fetchExplain}
          position="up-left"
          styles={{ right: 'calc(100% - 72px)', left: 'auto', bottom: '24px' }}
        />
      );
  }
};

export const Score = (props: IDataGridScore) => {
  const { showLabels = defaultShowlabels } = props;
  const labelsList = showLabels;
  const allowMultibleLabels = labelsList.length > 1;

  return (
    <span className={classNames(clx, allowMultibleLabels && `${clx}--hasMultipleLabels`)}>
      <div className={`${clx}__lablesList`}>
        {labelsList.map((label) => (
          <Label key={label} labelName={label} {...props} />
        ))}
      </div>
    </span>
  );
};
