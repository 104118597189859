import * as React from 'react';
import classNames from 'classnames';
import { Panel, PanelList, TabsAnchoredContent } from 'ui';
import ContactSupport from './ContactSupport';
import { IObjectTypeInterface } from '../StoreFrontSettings.interfaces';
// eslint-disable-next-line import/no-cycle
import JsonUiGenerator from './JsonUiGenerator';

const ObjectTypeComponent = (props: IObjectTypeInterface) => {
  const {
    valuesObject,
    bannerSettingsMap,
    component,
    handleChange,
    level,
    id,
    Footer,
    formName,
    showAnchors = false,
  } = props;

  const hasValue = !!valuesObject[id] && valuesObject[id] !== undefined && valuesObject[id] !== null;

  const [settings, setSettings] = React.useState<any>(valuesObject[id]);

  const handleFieldChange = (key: string, value: any) => {
    if (Object.prototype.hasOwnProperty.call(settings, key)) {
      const changedSettings = settings;
      changedSettings[key] = value;
      setSettings(changedSettings);
      handleChange(id, settings);
    }
  };

  React.useEffect(() => {
    setSettings(valuesObject[id]);
  }, [valuesObject]);

  const PanelContent = (
    <Panel
      title={component.title}
      {...(component.description && component.description.length > 1
        ? {
          messages: [{ caption: component.description, type: 'info', showLabel: false }],
        }
        : {
          ...(component.message &&
              component.message.caption?.length >= 1 && {
            messages: [
              {
                caption: component?.message.caption,
                showLabel: component?.message.showLabel ?? false,
              },
            ],
          }),
        })}
      className={classNames(level > 1 && 'stretchToPanelWidth remove-border')}
    >
      {!hasValue && <ContactSupport />}
      {JsonUiGenerator(
        component.value,
        settings,
        bannerSettingsMap,
        handleFieldChange,
        level + 1,
        id,
        showAnchors,
        null,
        null,
        null,
        formName,
      )}
    </Panel>
  );

  const generateAnchors = () => {
    return <TabsAnchoredContent caption={component.title}>{PanelContent}</TabsAnchoredContent>;
  };
  
  return (
    <PanelList className={classNames(level > 1 && 'remove-border')}>
      {/* If showAnchors is true, then TabsAnchoredContent need to be generated */}
      {showAnchors ? generateAnchors() : PanelContent}
      {Footer}
    </PanelList>
  );
};
export default ObjectTypeComponent;
