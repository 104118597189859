import * as React from 'react';
import { IformField } from 'framework/components/hoc/formField';
import { Select, ISelectProps, ISelectOptions } from '../Select';
import { fetchTimezones } from './api';

interface TimezoneSelectorProps {
  onLoad?: () => void;
}

type Props = TimezoneSelectorProps & ISelectProps & IformField;

const TimezoneSelector = (props: Props) => {
  const { onLoad } = props;
  const [timezoneOptions, setTimezoneOptions] = React.useState<ISelectOptions[]>([]);

  React.useEffect(() => {
    fetchTimezones().then(timezones => {
      setTimezoneOptions(timezones);
      if (onLoad) {
        onLoad();
      }
    });
  }, []);

  return (
    <Select
      placeholder="components.timezoneSelector.placeholder"
      {...props}
      options={timezoneOptions}
    />
  );
};

export default TimezoneSelector;
