import { Uuid } from 'types';
import { goToRoute } from 'framework/components/navigation/service';
import { TABS_URL_PARAM } from 'framework/components/ui/Tabs';
import { templatesRoute, templatesEditRoute } from './constants';
import { SlotTemplateEditTabs } from './pages/SlotsTemplatesEdit/pageParts/constants';

export const goToSlotTemplateListRoute = () => goToRoute(templatesRoute);

export const goToSlotTemplateEditRoute = (templateId: Uuid, selectedTab?: SlotTemplateEditTabs) => goToRoute(
  templatesEditRoute,
  {
    templateId,
  },
  {
    [TABS_URL_PARAM]: selectedTab,
  },
);
