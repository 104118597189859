import {
  SET_FORM_IS_STENCILLED_PROPERTY,
  DELETE_FORM_DATA,
} from './Form.actions';

interface State {
  [formName: string]: {
    isStencilled?: boolean;
  };
}

interface Action {
  type: string;
  payload: {
    name: string;
    isStencilled?: boolean;
  }
}

const initialState: State = {};
export default (state: State = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FORM_IS_STENCILLED_PROPERTY: {
      return {
        ...state,
        [payload.name]: {
          isStencilled: payload.isStencilled,
        },
      };
    }

    case DELETE_FORM_DATA: {
      const newState = { ...state };
      delete newState[payload.name];

      return newState;
    }

    default:
      return state;
  }
};
