import * as React from 'react';
import * as classnames from 'classnames';
import { Text } from 'ui';
import { isString } from 'framework/utils/helpers';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ITextExplainer } from './TextExplainer.interfaces';
import './_style.scss';

const clx = prefixClassName('explainer');

export const TextExplainer = (props: ITextExplainer) => {
  const { caption = null, className, display = true } = props;
  const captionArray: string[] = isString(caption) ? [caption] : caption;

  return display && captionArray ? (
    <span className={classnames(clx, className)}>
      {captionArray.map((txt, i) => (
        <Text key={i} caption={txt} className={`${clx}__item`} tag="span" />
      ))}
    </span>
  ) : null;
};

export default TextExplainer;
