import * as React from 'react';
import { Icon } from 'framework/components/ui';
import Wrapper from './label.wrapper';

interface ScoreProps {
  className?: string;
  value: string | number;
}

const formatScore = (val: string | number) => (val ? Number(val).toFixed(2) : '--');

export const Score = ({ className, value }: ScoreProps) => (
  <Wrapper type="score">
    <span>{formatScore(value)}</span>
    <Icon name="info" className={`${className}__icon`} />
  </Wrapper>
);

export default Score;
