export enum TimeslotTranslations {
  FilterByKeyword = 'generic.filterByKeyword',
  FilterByStatus = 'generic.filterByStatus',
  Actions = 'generic.actions',
  ApplyAndSchedule = 'modules.slotManagement.templates.edit.applyAndSchedule',
  Delete = 'generic.options.delete',
  Cancel = 'generic.options.cancel',
  FilterByShoppingMode = 'modules.slotManagement.filterByShoppingMode',
  SlotTemplates = 'modules.slotManagement.slotTemplates',
  TabScheduleCaption = 'modules.slotManagement.templates.edit.tabScheduleCaption',
  Status = 'modules.slotManagement.status',
  TemplateDetails = 'modules.slotManagement.templates.edit.templateDetails',
  ShoppingMode = 'modules.slotManagement.shoppingMode',
  Stores = 'modules.slotManagement.stores',
  StoresAvailableToTitle = 'modules.slotManagement.templates.edit.stores.titleAvailableTo',
  StoresAppliedTo = 'modules.slotManagement.storesAppliedTo',
  StoresAvailableTo = 'modules.slotManagement.storesAvailableTo',
  XStores = 'modules.slotManagement.XStores',
  TemplateDeleteSuccess = 'modules.slotManagement.templateDeleteSuccess',
  TemplateDeleteError = 'modules.slotManagement.templateDeleteError',
  FilterByStore = 'modules.slotManagement.filterByStore',
  ContinueToFilters = 'modules.slotManagement.continueToFilters',
  SelectAStore = 'modules.slotManagement.selectAStore',
  SlotTemplateCreateSuccess = 'modules.slotManagement.slotTemplateCreateSuccess',
  SlotTemplateUpdateSuccess = 'modules.slotManagement.slotTemplateUpdateSuccess',
  SlotTemplateSaveError = 'modules.slotManagement.slotTemplateSaveError',
  ClearStores = 'generic.clearStores',
  ModalTitle = 'modules.slotManagement.templates.list.deleteConfirmationModal.message',
  WarningMessage = 'modules.slotManagement.templates.list.deleteConfirmationModal.warning',
  ConfirmButton = 'modules.slotManagement.templates.list.deleteConfirmationModal.confirmButton',
  CancelButton = 'modules.slotManagement.templates.list.deleteConfirmationModal.cancelButton',
}
