export const toggleDisplayPromotionsCarousel = (state, action) => {
  const rows = [...state.loadedPage.cmsContent.rows];

  rows[action.payload.rowIndex] = {
    ...rows[action.payload.rowIndex],
    columns: [{
      ...rows[action.payload.rowIndex].columns[0],
      displayPromotionsCarousel: !rows[action.payload.rowIndex].columns[0].displayPromotionsCarousel,
    }],
  };

  return {
    ...state,
    loadedPage: {
      ...state.loadedPage,
      cmsContent: {
        ...state.loadedPage.cmsContent,
        rows,
      },
    },
  };
};
