import { createSelector } from 'reselect';
import * as get from 'lodash.get';
import { UserProfile } from './Interfaces';

export const TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND_IN_STORE';

const getOidc: any = (state) => state.oidc;

export const selectAccessToken = createSelector(
  getOidc,
  (oidc: any = {}) => get(oidc, 'user.access_token', TOKEN_NOT_FOUND),
);

export const selectIsLoadingUser = createSelector(
  getOidc,
  (oidc: any = {}) => oidc.isLoadingUser as boolean,
);

export const selectProfile = createSelector<unknown, unknown, UserProfile>(
  getOidc,
  (oidc: any = {}) => get(oidc, 'user.profile', {}),
);
