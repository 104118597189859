import { CmsLayoutInspector, CmsLayoutTemplates, CmsLayoutType } from './CmsComponents.interfaces';
import { ContentManagementState } from './contentmanagement.interfaces';

export const internalGetLayoutInspector = (
  templates: CmsLayoutTemplates,
  name: CmsLayoutType,
  componentName: CmsLayoutType,
) => {
  const loweredKeys = Object.keys(templates).reduce<Record<string, CmsLayoutInspector>>((acc, k) => ({
    ...acc,
    [k.toLowerCase()]: templates[k],
  }), {});

  const template = name === 'Template'
    ? loweredKeys[componentName?.toLocaleLowerCase()]
    : loweredKeys[name?.toLocaleLowerCase()];

  return template;
};

export const getLayoutInspector = (
  state: ContentManagementState,
  name: CmsLayoutType,
  reference: CmsLayoutType,
) => {
  const { contentmanagement: { templates } } = state;
  return internalGetLayoutInspector(templates, name, reference);
};
