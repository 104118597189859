import { fork } from 'redux-saga/effects';

import { watchFetchProducts } from './fetchPreviewCurrentData';
import { watchGetCurrentScore, watchGetPreviewScore } from './fetchProductScore';
import fetchRuleDataSaga from './fetchRuleData';
import saveRuleSaga from './saveRuleSaga';
import { watchClearData } from './clearPageSaga';

export default [
  fork(watchFetchProducts),
  fork(watchClearData),
  watchGetCurrentScore(),
  watchGetPreviewScore(),
  fork(fetchRuleDataSaga),
  fork(saveRuleSaga),
];
