import { take, fork, select, put } from 'redux-saga/effects';
import * as constants from '../contentemanagement.constants';
import { ContentManagementState, PageDocument } from '../contentmanagement.interfaces';
import { selectors as formSelectors } from 'framework/components/ui/FormComponents/Form';
import { getLoadedPage, displayMessageErrors } from './_SagaUtils';
import { apiClientActions, toastListActions } from 'framework/actions';

export default function* sagaCmsSeo() {
  while (true) {
    const action = yield take([
      constants.ACTION_CMS_FORM_SEO_TAB_SAVEDATA,
      constants.ACTION_CMS_CREATE_SEO_FOR_VARIANT,
      apiClientActions.API_CLIENT_UPDATE_STATE
    ]);
    yield fork(process, action);
  }
}

function* process(action: any) {
  switch (action.type) {
    case constants.ACTION_CMS_FORM_SEO_TAB_SAVEDATA:
    case constants.ACTION_CMS_CREATE_SEO_FOR_VARIANT:
      {
        yield saveSeoData(action);
      }
      break;
    case apiClientActions.API_CLIENT_UPDATE_STATE:
      {
        if (!action.payload.isFetching && !action.payload.hasError) {
          switch (action.payload.name) {
            case constants.ACTION_CMS_FORM_SEO_TAB_SAVEDATA:
              {
                yield put(toastListActions.addMessage('generic.data.savedSuccess'));
              }
              break;
          }
        } else if (action.payload.hasError) {
          yield displayMessageErrors({
            action,
            error: action.payload.message,
            names: [
              constants.ACTION_CMS_FORM_SEO_TAB_SAVEDATA,
              constants.ACTION_CMS_CREATE_SEO_FOR_VARIANT
            ]
          });
        }
      }
      break;
  }
}

function* saveSeoData(action) {
  const ids: any = yield select((state: ContentManagementState) => {
    return {
      pageId: state.contentmanagement.pageId,
      variantId: state.contentmanagement.variantId
    };
  });
  const values = yield select(formSelectors.getFormValues, constants.CMS_FORM_SEO_TAB);
  const loadedPage: PageDocument = yield select(getLoadedPage);
  const payload = {
    body: {
      seo: {
        ...loadedPage.seo,
        ...values
      }
    },
    method: 'put',
    endpoint: constants.PATH_CMS_ENDPOINT,
    endpointPath: `/documents/${ids.pageId}/variants/${ids.variantId}/seo`,
    name: action.type
  };
  yield put(apiClientActions.createApiClientMakeRequestAction(payload));
}
