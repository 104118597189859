import { config } from 'config';
import { fetchData, IFetchDataResponse } from 'framework/api/fetch-data';
import { SkipTake } from 'types';
import {
  ProductAttribute,
  ProductCustomizations,
  ProductDetails,
  ProductPricing,
  ProductPricingResponse,
  UnitOfPrice,
} from '../models/product';

interface GetProductReturn {
  product: ProductDetails;
  eTag: string;
}

export const getProducts = async (params?: any, skipTake?: SkipTake) => {
  const result  = await fetchData<{ total: number, items: any[], options: any }>({
    url: config.apiEndpoints.products.list,
    query: {...skipTake, ...params},
    method:'GET',
  });

  return result;
};

export const getProductAttributes = async (params: { skip: number, limit: number }) => {
  const result = await fetchData<{ items: ProductAttribute[] }>({ 
    url: config.apiEndpoints.products.attributes,
    query: params,
    method: 'GET',
  });

  return result;
};

export const getProduct = async (id: string): Promise<GetProductReturn> => {
  const result = await fetchData<ProductDetails>({
    url: `${config.apiEndpoints.products.base}/${id}`
  });

  return { product: result.data, eTag: result.metadata.eTag };
};

export const createProduct = async (product: ProductDetails) => {
  const result = await fetchData({
    url: config.apiEndpoints.products.base,
    method: 'POST',
    body: product
  });
  return result;
};

export const patchProduct = async (
  productId, data, eTag
) => {
  const result = await fetchData({
    url: `${config.apiEndpoints.products.base}${productId}`,
    method: 'PATCH',
    body: data,
    eTag
  });
  return result;
};

export const resendProducts = async (sku) => {
  const result = await fetchData({
    url: config.apiEndpoints.products.resendProducts,
    method: 'POST',
    body: [sku]
  });

  return result;
};

export const getProductRulesEvaluation = async (sku: string) => {
  const result = await fetchData({
    url: `${config.apiEndpoints.products.brandbankRulesEvaluator}/${sku}`,
  });

  return result;
};

export const getProductPricing = async (productSku: string) => {
  const result = await fetchData<ProductPricingResponse>({
    url: `${config.apiEndpoints.products.pricing}/${productSku}`,
  });

  // This endpoint returns a 404 if there are no prices set for the product
  const finalResult: IFetchDataResponse<ProductPricingResponse, any> =
    result.success && result.status !== 404
      ? result
      : ({
        ...result,
        data: {
          storeProductPriceResponses: [],
        },
        success: true,
        error: null,
      });

  return finalResult;
};

export const setProductPricing = async (
  sku: string,
  pricing: ProductPricing,
  unitOfPrice: UnitOfPrice,
  method: 'POST' | 'PUT',
) => {
  const { retailerStoreId } = pricing;

  // Update pricing endpoint needs tprPrices property
  const finalPricing: ProductPricing = {
    ...pricing,
    tprPrices: pricing.tprPrices,
  };

  const result = await fetchData({
    url: `${config.apiEndpoints.products.pricing}/${sku}/${retailerStoreId}`,
    method,
    body: {
      ...finalPricing,
      unitOfPrice,
    }
  });

  return result;
};

export const setProductPricingBulk = async (
  sku: string,
  storeProductPrices: ProductPricing[],
  unitOfPrice: UnitOfPrice,
) => {
  // Update pricing endpoint needs tprPrices property
  const finalPricing: ProductPricing[] = storeProductPrices.map((productPrice: ProductPricing) => ({
    ...productPrice,
    tprPrices: productPrice.tprPrices,
  }));

  const result = await fetchData({
    url: `${config.apiEndpoints.products.pricing}/${sku}/bulk`,
    method: 'POST',
    body: {
      unitOfPrice,
      storeProductPrices: finalPricing,
    }
  });

  return result;
};

export const deleteProductPriceBulk = async (sku: string, retailerStoreId: string[]) => {
  const result = await fetchData({
    url: `${config.apiEndpoints.products.pricing}/bulk-delete-stores`,
    method: 'DELETE',
    body: [
      {
        sku,
        retailerStoreId,
      },
    ],
  });

  return result;
};

export const getProductCustomizations = async (sku: string): Promise<IFetchDataResponse<ProductCustomizations>> => {
  const result: IFetchDataResponse<ProductCustomizations> = await fetchData({
    url: `${config.apiEndpoints.products.customizations}/GetProductCustomizationsBySku`,
    method: 'GET',
    query: {
      sku,
    },
  });

  return result;
};

export const saveProductCustomizations = async (
  customizations: ProductCustomizations
): Promise<IFetchDataResponse<ProductCustomizations>> => {
  const result: IFetchDataResponse<ProductCustomizations> = await fetchData({
    url: `${config.apiEndpoints.products.customizations}`,
    // Delete customizations by id if options length is 0, else update the customizations
    ...(customizations.options.length === 0
      ? ({
        method: 'DELETE',
        query: {
          customizationId: customizations.id,
        },
      })
      : ({
        method: 'PUT',
        body: customizations,
      })
    ),
  });

  return result;
};
