import * as React from 'react';
import config from 'config';
import { fetchData } from 'framework/api/fetch-data';

export enum TimeFormats {
  Hours24 = 'Hours24',
  Hours12 = 'Hours12'
}

export interface User {
  id: string;
  email: string;
  familyName: string;
  firstName: string;
  fullName: string;
  orgHierarchyNodeIds?: string[];
  principalRoleName: string;
  roleCount: number;
  roles: any[];
  status: string;
  timeZone: string;
  userName: string;
  timeFormat: TimeFormats;
}

export interface OidcProfile {
  email: string;
  email_verified: boolean;
  family_name: string;
  fullaccess: boolean;
  given_name: string;
  idp: string;
  name: string;
  sid: string;
  sub: string;
}

interface Email {
  value: string;
}
interface Id {
  value: string;
}

const useUserInfo = (props: Email | Id) => {
  const [user, setUser] = React.useState({} as User);

  React.useEffect(() => {
    if (props.value) {
      fetchData<User>({
        url: `${config.apiEndpoints.users}/${props.value}`,
      }).then(({ data }) => setUser(data));
    }
  }, [props.value]);

  return user;
};

export { useUserInfo };
