import * as React from 'react';
import { SelectPrimitive } from '../Primitives/SelectPrimitive';
import { IMultiSelect } from './Multiselect.interfaces';

export const Multiselect = (props: IMultiSelect) => {
  const { value, searchable = false, onBlur } = props;

  // We need to do this: https://github.com/erikras/redux-form/issues/1185#issuecomment-227121795
  const onBlurHandler = () => {
    onBlur(value);
  };

  return <SelectPrimitive multi {...props} onBlur={onBlurHandler} searchable={searchable} />;
};

export default Multiselect;
