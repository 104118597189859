import { useDispatch } from 'framework/utils/useDispatch';
import { createCloseModalAction, createOpenModalAction } from './Modal.actions';

export const useModal = (modalName?: string) => {
  const dispatch = useDispatch();

  return {
    openModal: (name = modalName) => dispatch(createOpenModalAction(name)),
    closeModal: (name = modalName) => dispatch(createCloseModalAction(name)),
  };
};
