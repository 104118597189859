import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Table } from 'ui';
import { ExplorerColumnProps } from './types';
import { checkFileInDam } from '../FormComponents/ImageField/ImageField';

import './Explorer.scss';

const cssClass = prefixClassName('explorer');

export function ExplorerColumn<T>({
  data,
  renderColumns,
  level,
  delayedAnim,
  isSelected,
  onRowClick,
  columnMetadata,
  onRowClass,
  requiredFilesFormat = [],
}: ExplorerColumnProps<T> & { level: number }) {
  const [slided, setSlided] = useState(false);
  const handleRowClick = (row: T) => () => onRowClick(row, level);

  useEffect(() => {
    setTimeout(() => setSlided(true), delayedAnim ? 250 : 0);
  }, []);

  return (
     // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={classnames(`${cssClass}__box-column`)} tabIndex={0}>
      <Table
        data={data}
        noBorder
        columnMetadata={columnMetadata}
        className={classnames(`${cssClass}__box-column__table`, slided && `${cssClass}__box-column__table--slided`)}
        cellRenderer={(_, row, _m, rowIndex) => {
          if (row.type === 'file' && requiredFilesFormat.length > 0 && !checkFileInDam(row, requiredFilesFormat)) {
            return null;
          }
          return (
            <Table.Row
              onClick={handleRowClick(row)}
              className={classnames(isSelected(row) && `${cssClass}--selected`, onRowClass?.(row, level))}
            >
              {renderColumns({
                row,
                level,
                rowIndex,
                isSelected: isSelected(row),
              })}
            </Table.Row>
          );
        }}
      />
    </div>
  );
}
