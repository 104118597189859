import type { AppError, FormFieldErrorsNested } from 'types';
import i18n from 'i18n';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toastError, toastSuccess } from 'stores/toast';
import { errorHandler } from 'framework/services/errorHandler';
import type { FormAdditionalProps, IForm } from './Form.interface';
import { addProgressBar } from '../../ProgressBar/useProgressBar';
import { Form } from './Form';

const mapStateToProps = <T extends {}>(state, ownProps: FormAdditionalProps<T>) => ({
  form: ownProps.name,
  initialValues: ownProps.initialValues,
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
    destroyOnUnmount: true,
    onSubmit: async <T extends {}>(formData: T, _dispatch, props: IForm<T>) => {
      const { onSubmitHandler, mapErrorsToFields } = props;
      let promise: Promise<AppError<T>>;
      let formErrors: AppError<T>;

      if (onSubmitHandler) {
        const submitHandlerWithProgress = addProgressBar(onSubmitHandler);
        promise = new Promise(async (resolve, reject) => {
          let errorMessages: string[] = [];

          try {
            const result = await submitHandlerWithProgress(formData, props);

            // handle success
            if (result.success) {
              if (result.successMessage?.length > 0) {
                toastSuccess(result.successMessage);
              }

              return resolve(result);
            }

            // handle errors
            const errors = result.error.errors ?? result.errors ?? result.error;

            // when APIs don't return the correct form field names we can map them with the following function
            formErrors = errors;
            if (mapErrorsToFields) {
              formErrors = mapErrorsToFields(errors);
            }

            const { globalError, ...fieldErrors } = formErrors;

            // handles global form submission error
            if (globalError) {
              if (Array.isArray(globalError)) {
                errorMessages = errorMessages.concat(globalError);
              } else {
                errorMessages.push(globalError);
              }
            }

            const count = Object.keys(fieldErrors).length;

            if (count > 0) {
              // has field errors, prompts the user to fix them
              errorMessages.push(i18n.t('generic.formsValidation.fixFields', { count }));
            }
          } catch (e) {
            errorMessages.push('generic.formsValidation.formSubmissionFailed');
            errorHandler(e);
          }

          if (errorMessages.length > 0) {
            toastError(errorMessages.length === 1 ? errorMessages[0] : errorMessages);
          }

          return reject(
            new SubmissionError<T, FormFieldErrorsNested<T>>({
              ...formErrors,
              _error: formErrors.globalError,
            })
          );
        });
      }

      return promise;
    },
  })
)(Form) as <T extends {}>(props: IForm<T>) => JSX.Element;
