import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import * as classnames from 'classnames';
import { Icon } from 'ui';

const hierarchyClassName = conf.prefixClassName('hierarchy');

interface ToggleRowButtonProps {
  toggle: any;
  expanded: boolean;
  noChildren: boolean;
}

export const ToggleRowButton = ({ toggle, expanded, noChildren = false }: ToggleRowButtonProps) => {
  return (
    <span className={`${hierarchyClassName}__icon-handle`}>
      {!noChildren && (
        <Icon
          onClick={toggle}
          name="dropdownArrowRight"
          rotate={expanded ? 90 : null}
          className={classnames(
            `${hierarchyClassName}__row__toggle-icon`,
            expanded && `${hierarchyClassName}__row__toggle-icon--expanded`
          )}
        />
      )}
    </span>
  );
};
