import { call, put } from 'redux-saga/effects';
import { IMakeRequest, makeRequest } from 'framework/api/make-request';

import { modalActions } from 'framework/actions';
import { toastError } from 'stores/toast';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';

import { config } from 'config';
import { createApiClientUpdateStateAction } from 'framework/api/apiClient/actions';
import { actionTypes } from '../actions';

const { API_PROVIDER_SAVE_GLOBAL_STOREATTRIBUTE } = actionTypes;

const baseUri = config.apiEndpoints.storeattributes;

function* process({ payload }) {
  const { data, modalName } = payload;

  const request: IMakeRequest = {
    name: API_PROVIDER_SAVE_GLOBAL_STOREATTRIBUTE,
    url: `${baseUri}/${data.id}`,
    method: 'PUT',
    body: {
      ...data,
      id: undefined,
    },
  };

  const { error } = yield call(makeRequest, request);
  if (error) {
    const message = error?.title
      ? error.title
      : 'modules.stores.storeSetup.attributes.createAttribute.errors.saveError';
    const errors = Object.values(error.errors);
    if (errors.length === 0) {
      toastError(message);
    } else {
      errors.forEach((value: string) => {
        toastError(value);
      });
    }
  } else {
    yield put(modalActions.createCloseModalAction(modalName));
    yield put(
      createApiClientUpdateStateAction({
        name: API_PROVIDER_SAVE_GLOBAL_STOREATTRIBUTE,
        data,
      }),
    );
  }
}

export default function* watchSaveGlobalStoreAttributeAction() {
  yield takeEveryWithProgressBar(actionTypes.STORES_CREATE_GLOBAL_STOREATTRIBUTE, process);
}
