import React from 'react';
import { HierarchyTreeView } from 'framework/components/ui/Hierarchy/hierarchy.tree';
import { Table } from 'ui';
import { i18n } from 'i18n';
import { prefixClassName } from 'framework/components/ui/_conf';
import withStencils from 'framework/components/hoc/withStencils';
import { getCategoriesByIds } from './service';
import { CategoryHierarchyNode } from './types';
import './_styles.scss';

const clx = prefixClassName('categoriesHierarchy');

interface CategoriesHierarchyProps {
  nodes: CategoryHierarchyNode[];
  onCategoriesChange: (selectedCategories: CategoryHierarchyNode[]) => void;
  isLoading: boolean;
  selectedIds: string[];
}

const Hierarchy = withStencils('panel')(HierarchyTreeView);

export const CategoriesHierarchy = (props: CategoriesHierarchyProps) => {
  const {
    onCategoriesChange, nodes, isLoading, selectedIds,
  } = props;

  const onSelectionChanged = (ids: string[]) => {
    const selectedData: CategoryHierarchyNode[] = getCategoriesByIds(ids, nodes);

    onCategoriesChange(selectedData);
  };

  return (
    <Hierarchy
      stencilled={isLoading}
      selectable
      nodeIcon="folder"
      leafIcon={null}
      showHeader={false}
      columns={[
        {
          title: '',
          content: (category) => {
            const { node } = category;
            return (
              <Table.Row className={`${clx}__categoryColumn`}>
                <Table.ColumnText caption={node.caption} />
                { node.children.length > 0 && (
                  <Table.ColumnText
                    caption={
                      i18n.t(
                        node.children.length > 1
                          ? 'modules.merchandising.components.categoryModalSearch.subcategoriesCountPlural'
                          : 'modules.merchandising.components.categoryModalSearch.subcategoriesCount',
                        { count: node.children.length },
                      )
                    }
                    className={`${clx}__summarizer`}
                  />
                )}
              </Table.Row>
            );
          },
        },
      ]}
      getNodeIsLeaf={(cat) => !cat.children || cat.children.length === 0}
      onSelectionChanged={onSelectionChanged}
      selectedIds={selectedIds}
      data={nodes}
    />
  );
};
