import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { Features } from 'stores/features';
import * as SynonymsRoutes from './Synonyms/routes';
import * as CONST from './constants';

export const rankingSettingsRoute: AppRoute = {
  pageTitle: 'modules.merchandising.rankingSettings.title',
  menuTitle: 'modules.merchandising.rankingSettings.menuTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_RANKING_SETTINGS}`,
  authClaim: AuthorizationClaims.SEARCH_RELEVANCE,
  Component: () => import('./pages/RankingSettings'),
};

export const searchResultsPreviewRoute: AppRoute = {
  pageTitle: 'modules.merchandising.searchResults.pageTitleSearchResultPreviewAndPositioning',
  menuTitle: 'modules.merchandising.searchResults.menuTitleSearchResultPreviewAndPositioning',
  path: `${CONST.PATH_MAIN}${CONST.PATH_SEARCH_RESULTS_PREVIEW}`,
  authClaim: AuthorizationClaims.SEARCH_PREVIEW,
  requiredFeatures: [{ feature: Features.SearchRulesMigrated }],
  Component: () => import('./SearchResultsPreview/index'),
};

export const synonymsListRoute: AppRoute = {
  ...SynonymsRoutes.synonymsListRoute,
  Component: () => import('./Synonyms/SynonymsList/index'),
};

export const synonymsCreateRoute: AppRoute = {
  ...SynonymsRoutes.synonymsCreateRoute,
  Component: () => import('./Synonyms/SynonymsEdit/index'),
};

export const synonymsEditRoute: AppRoute = {
  ...SynonymsRoutes.synonymsEditRoute,
  Component: () => import('./Synonyms/SynonymsEdit/index'),
};

export const rulesListRoute: AppRoute = {
  pageTitle: 'modules.merchandising.rules.list.title',
  menuTitle: 'modules.merchandising.rules.list.titleMenu',
  path: `${CONST.PATH_MAIN}${CONST.PATH_RULES}`,
  authClaim: AuthorizationClaims.SEARCH_RULES,
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./Rules/RulesList/index'),
};

export const rulesCreateRuleRoute: AppRoute = {
  pageTitle: 'modules.merchandising.rules.create.title',
  menuTitle: 'modules.merchandising.rules.create.menuTitle',
  path: CONST.PATH_RULES_CREATE_FULLPATH,
  authClaim: AuthorizationClaims.SEARCH_RULES,
  Component: () => import('./pages/RulesCreateRule'),
  parent: rulesListRoute,
};

export const rulesEditRuleRoute: AppRoute = {
  pageTitle: 'modules.merchandising.rules.edit.title',
  path: `${CONST.PATH_RULES_EDIT_FULLPATH}/:ruleId`,
  authClaim: AuthorizationClaims.SEARCH_RULES,
  Component: () => import('./pages/RulesEditRule'),
  parent: rulesListRoute,
};

export const defaultSettingsRoute: AppRoute = {
  pageTitle: 'modules.merchandising.searchSettings.title',
  menuTitle: 'modules.merchandising.searchSettings.menuTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_SEARCH_SETTINGS}`,
  authClaim: AuthorizationClaims.SEARCH_SETTINGS,
  Component: () => import('./pages/DefaultSettings'),
};

export const searchRoutes: AppRoute[] = [
  rankingSettingsRoute,
  searchResultsPreviewRoute,
  synonymsListRoute,
  synonymsCreateRoute,
  synonymsEditRoute,
  rulesListRoute,
  rulesCreateRuleRoute,
  rulesEditRuleRoute,
  defaultSettingsRoute,
];
