import * as React from 'react';
import { Icon, LayoutFlexBox, LayoutFlexBoxItem, NumericField, Select } from 'ui';
// eslint-disable-next-line import/no-cycle
import { positionsStore } from './ActionsPosition';
import { ProductPosition } from '../../../../../models/product';

interface Props {
  field: string;
  productPosition: ProductPosition;
  onDelete: () => void;
  fetchCurrentData: () => void;
}

export default ({ field, onDelete, productPosition, fetchCurrentData }: Props) => {
  const onChangeHandler = () => {
    fetchCurrentData();

    // Keep track of changed positions, avoids duplicates
    positionsStore.newPositions = Array.from(
      new Set([...(positionsStore.newPositions ?? []), productPosition?.product?.value])
    );
  };

  return (
    <LayoutFlexBox flexWrap="nowrap">
      <LayoutFlexBoxItem className="mrg-btm-10" flexBasis="100%">
        <LayoutFlexBox justifyContent="space-between">
          <LayoutFlexBoxItem flexBasis="65%">
            <Select name={`${field}.product`} disabled />
          </LayoutFlexBoxItem>

          <LayoutFlexBoxItem flexBasis="20%">
            <NumericField name={`${field}.position`} min={0} max={20} onChange={onChangeHandler} />
          </LayoutFlexBoxItem>
          <LayoutFlexBoxItem flexBasis="10%">
            <Icon
              onClick={onDelete}
              className="mrg-auto mrg-top-10 block cursor-pointer"
              name="del"
              size="sm"
              color="#96a0ab"
            />
          </LayoutFlexBoxItem>
        </LayoutFlexBox>
      </LayoutFlexBoxItem>
    </LayoutFlexBox>
  );
};
