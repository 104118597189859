import * as React from 'react';
import { CheckboxPrimitive } from '../../FormComponents/Primitives';
import { noPaddingRight } from '../constants';
import { Column, Head } from '../TableColumn';

interface CheckboxCellHeadProps<T> {
  selectedItems: T[];
  disabledItems: T[];
  toggleAllSelection: (selectAll: boolean) => void;
  selectionLogic?: (item: T) => boolean;
  data: T[];
  hidePadding?: boolean;
}

export const CheckboxCellHead = React.memo(<T extends {}>({
  selectedItems = [],
  disabledItems = [],
  toggleAllSelection,
  selectionLogic,
  data = [],
  hidePadding = true,
}: CheckboxCellHeadProps<T>) => {
  let isSelected = false;
  if (selectionLogic) {
    const selection = data.filter((item) => !disabledItems.includes(item)).map((item) => selectionLogic(item));
    isSelected = selection.length > 0 && !selection.includes(false);
  } else {
    isSelected = selectedItems.length > 0
    && selectedItems.length === data.length - disabledItems.length;
  }
  return (
  <Head className={hidePadding && noPaddingRight} width="40px">
    <CheckboxPrimitive
      name="listPageSelectAll"
      value={isSelected}
      onChange={(e) => toggleAllSelection(e.target.checked)}
    />
  </Head>
  );
});

interface CheckboxCellBodyProps<T> {
  selectedItems: T[];
  toggleItemSelection: (item: T) => void;
  isSelected?: (item: T) => boolean;
  item: T;
  index: number;
  disabledItems?: T[];
  hidePadding?: boolean;
}

export const CheckboxCellBody = React.memo(<T extends {}>({
  selectedItems = [],
  disabledItems = [],
  toggleItemSelection,
  isSelected,
  item,
  index,
  hidePadding = true,
}: CheckboxCellBodyProps<T>) => (
  <Column className={hidePadding && noPaddingRight} width="40px">
    <CheckboxPrimitive
      name={`listPageSelectAll__${index}`}
      value={isSelected ? isSelected(item) : selectedItems.includes(item)}
      disabled={disabledItems.includes(item)}
      onChange={() => toggleItemSelection(item)}
    />
  </Column>
  ));
