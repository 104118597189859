import { AppRoute, AppRouteClaims } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { i18n } from 'i18n';
import { goToRoute } from 'framework/components/navigation/service';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { appState } from 'stores/app';
import { TABS_URL_PARAM } from 'framework/components/ui/Tabs';
import * as CONST from './constants';
import { FCID_PATH_PARAM, WAVES_TAB } from './pages/OrdersList/constants';
import { TabUrls } from './pages/PersonalShopperEdit/constants';

const getFCName = () => {
  const {
    fulfillmentCenter: { name: FCName },
  } = appState;
  return {
    FCName,
  };
};

const ordersClaims: AppRouteClaims = {
  [AuthorizationClaims.ORDER_FULFILLMENT]: UserAccessRights.ReadWrite,
};

export const orderListRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.ordersList.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.ordersList.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.ordersList.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_ORDERS}/${FCID_PATH_PARAM}?`,
  authClaim: { [AuthorizationClaims.ORDER_FULFILLMENT_ORDERS]: UserAccessRights.ReadWrite },
  extendedClaims: [
    {
      label: 'modules.siteadmin.claims.manageOrders',
      claims: ordersClaims,
    },
    {
      label: 'modules.siteadmin.claims.customerComments',
      claims: {
        [AuthorizationClaims.CUSTOMER_COMMENTS]: UserAccessRights.Read,
      },
    },
  ],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/OrdersList'),
};

export const printOrderSummaryListRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.printOrderSummaryList.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.printOrderSummaryList.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.printOrderSummaryList.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_PRINT_ORDER_SUMMARY_LIST}`,
  authClaim: { [AuthorizationClaims.ORDER_FULFILLMENT_ORDERS]: UserAccessRights.ReadWrite },
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/PrintOrderSummaryListing'),
};

export const printRemovedOrderSummaryListRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.removedPrintOrderSummaryList.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.removedPrintOrderSummaryList.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.removedPrintOrderSummaryList.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_REMOVED_PRINT_ORDER_SUMMARY_LIST}`,
  authClaim: { [AuthorizationClaims.ORDER_FULFILLMENT_ORDERS]: UserAccessRights.ReadWrite },
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/RemovedPrintOrderSummaryListing'),
};

export const noStoresRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.noStoresAssigned.pageBreadcrumb',
  pageTitle: () => 'modules.orderFulfillment.noStoresAssigned.pageTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_NO_STORES}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT],
  Component: () => import('./pages/NoStores'),
};

export const orderDetailsRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.orderDetails.pageBreadcrumb',
  pageTitle: 'modules.orderFulfillment.orderDetails.pageTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_ORDERS}${CONST.PATH_ORDER_DETAILS}`,
  authClaim: ordersClaims,
  Component: () => import('./pages/OrderDetails'),
  parent: orderListRoute,
};

export const personalShoppersRoutes: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.personalShoppers.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.personalShoppers.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.personalShoppers.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_PERSONAL_SHOPPERS}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_PERSONAL_SHOPPERS],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/PersonalShoppers'),
};

export const personalShoppersEditRoutes: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.personalShoppers.personalShopperDetails.pageBreadcrumb',
  pageTitle: 'modules.orderFulfillment.personalShoppers.personalShopperDetails.pageTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_PERSONAL_SHOPPERS}${CONST.PATH_PERSONAL_SHOPPER_DETAILS}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_PERSONAL_SHOPPERS],
  Component: () => import('./pages/PersonalShopperEdit'),
  parent: personalShoppersRoutes,
};

export const goToPersonalShopperWavesTab = (personalShopperId: string, fullName: string) =>
  goToRoute(
    personalShoppersEditRoutes,
    {
      personalShopperId,
      fullName,
    },
    { [TABS_URL_PARAM]: TabUrls.Waves }
  );

export const personalShoppersCreateRoutes: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.personalShoppers.personalShopperDetails.pageBreadcrumb',
  pageTitle: 'modules.orderFulfillment.personalShoppers.personalShopperDetails.pageTitle',
  menuTitle: 'modules.orderFulfillment.personalShoppers.createNew',
  path: `${CONST.PATH_MAIN}${CONST.PATH_PERSONAL_SHOPPERS}${CONST.PATH_PERSONAL_SHOPPER_CREATE}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_PERSONAL_SHOPPERS],
  Component: () => import('./pages/PersonalShopperCreate'),
  parent: personalShoppersRoutes,
};

export const locationServicesRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.locationServices.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.locationServices.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.locationServices.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_LOCATION_SERVICES}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_LOCATIONS],
  Component: () => import('./pages/LocationServices'),
};

export const locationServicesCreateRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.locationServices.locationDetails.create.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.locationServices.locationDetails.create.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.locationServices.locationDetails.create.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_LOCATION_SERVICES}/details`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_LOCATIONS],
  Component: () => import('./pages/LocationServicesDetails'),
  parent: locationServicesRoute,
};

export const locationServicesEditRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.locationServices.locationDetails.edit.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.locationServices.locationDetails.edit.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.locationServices.locationDetails.edit.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_LOCATION_SERVICES}/details/:locationId`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_LOCATIONS],
  Component: () => import('./pages/LocationServicesDetails'),
  parent: locationServicesRoute,
};

export const wavesListRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.waves.menuTitle',
  menuTitle: 'modules.orderFulfillment.waves.menuTitle',
  pageTitle: 'modules.orderFulfillment.waves.pageTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_ORDERS}/${FCID_PATH_PARAM}?tab=${WAVES_TAB}`,
  authClaim: { [AuthorizationClaims.ORDER_FULFILLMENT_ORDERS]: UserAccessRights.ReadWrite },
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/OrdersList'),
};

export const waveDetailsRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.waveInformation.pageBreadcrumb',
  pageTitle: 'modules.orderFulfillment.waveInformation.pageTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_WAVES}${CONST.PATH_WAVE_DETAILS}`,
  authClaim: ordersClaims,
  Component: () => import('./pages/WaveDetails'),
  parent: wavesListRoute,
};

export const settingsRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.settings.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.settings.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.settings.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_SETTINGS}`,
  authClaim: [
    AuthorizationClaims.ORDER_FULFILLMENT,
    { [AuthorizationClaims.ORDER_FULFILLMENT_SETUP]: UserAccessRights.Read },
  ],
  Component: () => import('./pages/Setup/SetupWrapper'),
};

export const printingSettingsRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.printingSettings.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.printingSettings.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.printingSettings.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_PRINTING_SETTINGS}`,
  authClaim: [
    AuthorizationClaims.ORDER_FULFILLMENT,
    { [AuthorizationClaims.ORDER_FULFILLMENT_SETUP]: UserAccessRights.Read },
  ],
  Component: () => import('./pages/Setup/SetupWrapper'),
};

export const mpsSettingsRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.mpsSettings.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.mpsSettings.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.mpsSettings.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_MPS_SETTINGS}`,
  authClaim: [
    AuthorizationClaims.ORDER_FULFILLMENT,
    { [AuthorizationClaims.ORDER_FULFILLMENT_SETUP]: UserAccessRights.Read },
  ],
  extendedClaims: [
    {
      label: 'modules.siteadmin.claims.ofSettingsMPSread',
      claims: {
        [AuthorizationClaims.ORDER_FULFILLMENT_SETUP]: UserAccessRights.Read,
      },
    },
    {
      label: 'modules.siteadmin.claims.ofSettingsMPSwrite',
      claims: {
        [AuthorizationClaims.ORDER_FULFILLMENT_SETUP]: UserAccessRights.ReadWrite,
      },
    },
  ],
  Component: () => import('./pages/Setup/SetupWrapper'),
};

export const productionCentersRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.productionCenters.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.productionCenters.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.productionCenters.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_PRODUCTION_CENTERS_LIST}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_PRODUCTION_CENTERS],
  extendedClaims: [
    {
      label: 'modules.siteadmin.claims.productionCentersPages',
      claims: {
        [AuthorizationClaims.ORDER_FULFILLMENT_PRODUCTION_CENTERS]: UserAccessRights.ReadWrite,
      },
    },
    {
      label: 'modules.siteadmin.claims.productionReport',
      claims: {
        [AuthorizationClaims.ORDER_FULFILLMENT_PRODUCTION_REPORTS]: UserAccessRights.ReadWrite,
      },
    },
  ],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/ProductionCenters'),
};
export const productionCentersCreateRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.productionCentersCreate.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.productionCentersCreate.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.productionCentersCreate.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_PRODUCTION_CENTERS_CREATE}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_PRODUCTION_CENTERS],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/ProductionCentersCreate'),
};
export const productionCentersEditRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.productionCentersEdit.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.productionCentersEdit.menuTitle',
  pageTitle: () => i18n.t('modules.orderFulfillment.productionCentersEdit.pageTitle', getFCName()),
  path: `${CONST.PATH_MAIN}${CONST.PATH_PRODUCTION_CENTERS_EDIT}`,
  authClaim: [AuthorizationClaims.ORDER_FULFILLMENT, AuthorizationClaims.ORDER_FULFILLMENT_PRODUCTION_CENTERS],
  Component: () => import('./pages/ProductionCentersEdit'),
  parent: productionCentersRoute,
};

export const fcListRoute: AppRoute = {
  breadcrumbTitle: 'modules.orderFulfillment.fulfillmentCenters.pageBreadcrumb',
  menuTitle: 'modules.orderFulfillment.fulfillmentCenters.menuTitle',
  pageTitle: 'modules.orderFulfillment.fulfillmentCenters.groupTitle',
  path: `${CONST.PATH_MAIN}${CONST.PATH_FULFILLMENT_CENTERS_LIST}`,
  authClaim: [
    AuthorizationClaims.ORDER_FULFILLMENT,
    AuthorizationClaims.ORDER_FULFILLMENT_FULFILLMENT_CENTERS,
    AuthorizationClaims.ORDER_FULFILLMENT_SETUP,
  ],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/FulfillmentCenters'),
};

export const goToFCListRoute = () => goToRoute(fcListRoute);

export const fcEditRoute: AppRoute = {
  pageTitle: 'modules.orderFulfillment.fulfillmentCenters.editPage.fulfillmentCenter',
  path: `${CONST.PATH_MAIN}${CONST.PATH_FULFILLMENT_CENTERS_LIST}/edit/:fcId`,
  authClaim: [
    AuthorizationClaims.ORDER_FULFILLMENT,
    AuthorizationClaims.ORDER_FULFILLMENT_FULFILLMENT_CENTERS,
    AuthorizationClaims.ORDER_FULFILLMENT_SETUP,
  ],
  parent: fcListRoute,
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/FulfillmentCenters/FCDetails'),
};

export const goToFCEditRoute = (fcId: string) => goToRoute(fcEditRoute, { fcId });

export const orderFulfillmentRoutes: AppRoute[] = [
  orderListRoute,
  orderDetailsRoute,
  printOrderSummaryListRoute,
  printRemovedOrderSummaryListRoute,
  noStoresRoute,
  personalShoppersRoutes,
  personalShoppersEditRoutes,
  personalShoppersCreateRoutes,
  locationServicesRoute,
  locationServicesCreateRoute,
  locationServicesEditRoute,
  waveDetailsRoute,
  wavesListRoute,
  settingsRoute,
  printingSettingsRoute,
  mpsSettingsRoute,
  productionCentersRoute,
  productionCentersCreateRoute,
  productionCentersEditRoute,
  fcListRoute,
  fcEditRoute,
];
