import { call, put, select } from 'redux-saga/effects';
import { createApiClientUpdateStateAction } from 'framework/api/apiClient/actions';
import { makeRequest, makeRequestAndSaveState } from 'framework/api/make-request';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { formSelectors } from 'framework/selectors';
import { history } from 'framework/store';
import { Uuid } from 'framework/utils/generateId';
import { toastError } from 'stores/toast';
import { formActions } from 'framework/actions';

import { config } from 'config';
import { actionTypes, startStoreHierarchyPolling } from '../actions';
import { CREATE_URL_PARAM, STORES_PATH, TAB_ATTRIBUTES } from '../constants';
import { Store } from '../Store';

const { API_PROVIDER_GET_STORE } = actionTypes;
const { API_PROVIDER_SAVE_STORE } = actionTypes;
const baseUri = config.apiEndpoints.stores;

function* process({ payload, type }) {
  const { data, orgHierarchyNode, formName } = payload;
  const isNew = !data.id;
  const storeId = data.id || Uuid();
  const formValues = yield select(formSelectors.getFormFieldValues, formName);
  let store: Store;

  switch (type) {
    case actionTypes.STORES_SAVE_STORE_URLS:
      store = {
        ...data,
        urls: formValues.urls,
      };
      break;
    default:
      store = { ...formValues };
      // TODO: Remove after supporting editable languages
      if (!store.languages || !store.languages.length) {
        store.languages = [
          {
            isoCode: 'en-US',
            isDefault: true,
          },
        ];
      } else if (store.languages[0].isoCode === 'en') {
        store.languages[0].isoCode = 'en-US';
      }
  }

  const { shoppingModePayload } = payload.data as Store;
  const { shoppingMode, ...rest } = store;
  store = rest;

  const response = yield call(makeRequestAndSaveState, {
    name: API_PROVIDER_SAVE_STORE,
    method: 'PUT',
    url: `${baseUri}/${storeId}`,
    body: { ...store, shoppingModeIds: payload.data.shoppingModeIds },
  });

  if (!response.success) {
    const message =
      response.error && response.error.title ? response.error.title : 'modules.stores.storeSetup.errors.saveError';
    const errors = Object.values(response.error.errors);
    if (errors.length === 0) {
      toastError(message);
    } else {
      errors.forEach((value: string) => {
        toastError(value);
      });
    }
  } else {
    yield put(
      createApiClientUpdateStateAction({
        data: store,
        name: API_PROVIDER_GET_STORE,
        hasError: false,
        isFetching: false,
      })
    );

    if (type === actionTypes.STORES_SAVE_STORE) {
      let res;
      if (isNew || !orgHierarchyNode.nodeId) {
        res = yield call(makeRequest, {
          body: { storeId },
          url: `${config.apiEndpoints.orghierarchies}/node/${orgHierarchyNode.parentId}/store`,
          method: 'POST',
          name: actionTypes.STORES_SAVE_STORE_HIERARCHY,
        });
      } else {
        res = yield call(makeRequest, {
          url: `${config.apiEndpoints.orghierarchies}/node/${orgHierarchyNode.nodeId}/move/${orgHierarchyNode.parentId}`,
          method: 'PUT',
          name: actionTypes.STORES_SAVE_STORE_HIERARCHY,
        });
      }
      if (!res.error && shoppingModePayload && !store?.featureFlags?.isShoppingModesOnStoresEnabled) {
        res = yield call(makeRequest, {
          url: `${config.apiEndpoints.StoreShoppingModes}`,
          method: 'PUT',
          name: actionTypes.STORE_SHOPPING_METHOD,
          body: {
            assignations: [
              {
                ...shoppingModePayload,
                storeId,
              },
            ],
          },
        });
      }
      if (res.error) {
        toastError('modules.stores.storeSetup.errors.saveError');
      } else {
        yield put(startStoreHierarchyPolling({ storeId, parentId: orgHierarchyNode.parentId }));
        yield put(formActions.setValue(formName, 'urls', store.urls));
      }

      const path = history.location.pathname.replace(
        `${STORES_PATH}/${CREATE_URL_PARAM}`,
        `${STORES_PATH}/${storeId}?tab=${TAB_ATTRIBUTES}`
      );
      history.replace(path);
    }
  }
}

export default function* watchSaveStoreAction() {
  yield takeEveryWithProgressBar([actionTypes.STORES_SAVE_STORE, actionTypes.STORES_SAVE_STORE_URLS], process);
}
