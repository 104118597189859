import * as React from 'react';
import { view } from '@risingstack/react-easy-state';
import { LayoutFlexBox, Modal, Form, NumericField, TextArea } from 'ui';
import * as CONST from '../../constants';
import { useModal } from '../../../../../../components/ui/Modal';
import { liveCalendarBulkActionStore } from '../content';
import { SlotBulkActions } from '../constants';
import { i18n } from 'i18n';

const ModalBulkEditSlot = view((props: { saveSlot: () => any }) => {
  const { closeModal } = useModal(CONST.MODAL_BULK_EDIT_SLOT_CAPACITY);
  const { saveSlot } = props;

  function getModalOptions(action) {
    const options = {
      closeModalCaption: '',
      modalMessageCaption: '',
      saveButtonOptionKey: '',
      modalCaption: '',
    };

    switch (action) {
      case SlotBulkActions.EditTotalCapacity:
        options.saveButtonOptionKey = 'modules.slotManagement.storeCalendar.edit.modalEditSlot.optionSave';
        options.closeModalCaption = 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.btnCancel';
        options.modalCaption = 'modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityLabel';
        options.modalMessageCaption = 'modules.slotManagement.storeCalendar.edit.modalEditSlot.modalMessages_01';
        break;
      case SlotBulkActions.OpenSlot:
        options.saveButtonOptionKey = 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.btnDelete';
        options.closeModalCaption = 'modules.slotManagement.storeCalendar.edit.modalOpenSlot.btnAdd';
        options.modalCaption = 'modules.slotManagement.storeCalendar.edit.modalOpenSlot.title';
        options.modalMessageCaption = 'modules.slotManagement.storeCalendar.edit.modalEditSlot.modalMessages_02';
        break;
      case SlotBulkActions.CloseSlot:
        options.saveButtonOptionKey = 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.btnAdd';
        options.closeModalCaption = 'modules.slotManagement.storeCalendar.edit.modalOpenSlot.btnDelete';
        options.modalCaption = 'modules.slotManagement.storeCalendar.edit.modalCloseSlot.title';
        options.modalMessageCaption = 'modules.slotManagement.storeCalendar.edit.modalEditSlot.modalMessages_03';
    }

    return options;
  }
  const { closeModalCaption, saveButtonOptionKey, modalCaption, modalMessageCaption } = getModalOptions(
    liveCalendarBulkActionStore.action
  );

  const modalCaptionTranslation = i18n.t(modalCaption);
  const footerOptions = [
    {
      ...(liveCalendarBulkActionStore.action === SlotBulkActions.EditTotalCapacity
        ? { formToSubmit: CONST.FORM_EDIT_SLOT }
        : { onClick: saveSlot }),
      caption: saveButtonOptionKey,
    },
    {
      caption: closeModalCaption,
      onClick: closeModal,
      outline: true,
    },
  ];

  return (
    <Modal
      name={CONST.MODAL_BULK_EDIT_SLOT_CAPACITY}
      caption="Bulk weekly calendar update"
      captionDetails={modalCaptionTranslation}
      footerOptions={footerOptions}
      messages={[
        {
          caption: modalMessageCaption,
          type: 'warning',
        },
      ]}
    >
      <LayoutFlexBox flexWrap="wrap" className="mrg-btm-20">
        <Form name={CONST.FORM_EDIT_SLOT} onSubmit={saveSlot}>
          {liveCalendarBulkActionStore.action === SlotBulkActions.EditTotalCapacity && (
            <NumericField
              grid
              name={CONST.FIELD_EDIT_SLOT_CAPACITY}
              caption="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityLabel"
              explainerText="modules.slotManagement.storeCalendar.edit.modalEditSlot.fieldCapacityExplainer"
            />
          )}
          {liveCalendarBulkActionStore.action === SlotBulkActions.OpenSlot && (
            <TextArea
              optional
              name={CONST.FIELD_CLOSE_SLOT_NOTE}
              caption="modules.slotManagement.storeCalendar.edit.modalOpenSlot.fieldNoteLabel"
              placeholder="modules.slotManagement.storeCalendar.edit.modalOpenSlot.fieldNotePlaceholder"
              explainerText="modules.slotManagement.storeCalendar.edit.modalOpenSlot.fieldNoteExplainer"
            />
          )}
          {liveCalendarBulkActionStore.action === SlotBulkActions.CloseSlot && (
            <TextArea
              optional
              name={CONST.FIELD_CLOSE_SLOT_NOTE}
              caption="modules.slotManagement.storeCalendar.edit.modalCloseSlot.fieldNoteLabel"
              placeholder="modules.slotManagement.storeCalendar.edit.modalCloseSlot.fieldNotePlaceholder"
              explainerText="modules.slotManagement.storeCalendar.edit.modalCloseSlot.fieldNoteExplainer"
            />
          )}
        </Form>
      </LayoutFlexBox>
    </Modal>
  );
});

export default ModalBulkEditSlot;
