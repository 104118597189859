/* eslint-disable jsx-a11y/label-has-associated-control */

import './_styles.scss';

import * as React from 'react';

import Icon from 'framework/components/ui/Icon';
import classnames from 'classnames';
import { IPasswordFieldPrimitiveProps } from './PasswordFieldPrimitive.interface';
import * as conf from '../../../_conf';

const rootClassName = conf.prefixClassName('password-field-primitive');

type PasswordFielType = 'password' | 'text';

export const PasswordFieldPrimitive = (props: IPasswordFieldPrimitiveProps) => {
  const {
    name,
    onBlur,
    onFocus,
    className,
    disabled,
    placeholder,
    onChange,
    onKeyPress,
    value,
    allowToggle = true,
  } = props;
  const [type, setType] = React.useState<PasswordFielType>('password');
  const toggleType = () => setType((prev) => (prev === 'password' ? 'text' : 'password'));

  return (
    <div className={classnames(rootClassName)}>
      <input
        id={name}
        autoComplete="off"
        type={type}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
      />
      {allowToggle && <Icon name={type === 'password' ? 'eye' : 'eyeOff'} onClick={toggleType} />}
    </div>
  );
};

export default PasswordFieldPrimitive;
