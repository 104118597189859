export const NotificationsTranslations = {
  Notifications: 'modules.myNotifications.notifications',
  AllNotifications: 'modules.myNotifications.allNotifications',
  EmptyNotifications: 'modules.myNotifications.emptyNotifications',
  MarkAllSeen: 'modules.myNotifications.markAllSeen',
  New: 'modules.myNotifications.new',
  Seen: 'modules.myNotifications.seen',
  ViewFullDetails: 'modules.myNotifications.viewFullDetails',
  StartTime: 'modules.myNotifications.startTime',
  EstimatedDuration: 'modules.myNotifications.estimatedDuration',
  Impact: 'modules.myNotifications.impact',
  Status: 'modules.myNotifications.status',
  ComponentsAffected: 'modules.myNotifications.componentsAffected',
  Hours: 'modules.myNotifications.hours'
};