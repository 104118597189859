import * as React from 'react';
import classNames from 'classnames';
import { FiltersField } from 'framework/components/ui/FilterBar/components/MoreFiltersTable/interfaces';
import { store } from '@risingstack/react-easy-state';
import { prefixClassName } from '../_conf';
import Form from '../FormComponents/Form';
import LayoutFlexBox from '../LayoutFlexBox';
import { Lazy } from '../Lazy/Lazy';
// eslint-disable-next-line import/no-cycle
import { Input } from './components/Input/Input';
import { SelectStatus } from './components/SelectStatus/SelectStatus';
import { MoreOptionsLabel } from './components/MoreOptionsLabel/MoreOptionsLabel';
import { FilteredBy } from './components/FilteredBy/FilteredBy';
import { SaveSearchModal } from './components/SaveSearchModal/SaveSearchModal';
import { ColumnsModal, ColumnsModalProps } from './components/ColumnsModal/ColumnsModal';
import { HideColumnsPanel } from './components/HideColumns/HideColumns';
import { FilterBarContext, FilterState, FilterTabs } from './context';
import { SearchDataInfo } from './models/savedSearch';
import './_styles.scss';
import { COLUMNS_MODAL, SAVE_SEARCH_FORM } from './constants';
import { Select } from './components/Select/Select';
import { useModal } from '../Modal';
import { Button } from '../FormComponents/Button';

const clx = prefixClassName('filter-bar');
const clxTabContent = prefixClassName('filter-bar__more-options');

const tabStore = store<{selectedTab?: FilterTabs, tabContent?: React.ReactNode}>();

type StretchType = 'page' | 'panel' | 'modal';

interface FilterBarBase {
  className?: string;
  children?: any;
  /**
   * @defaultvalue 'page'
   */
  // eslint-disable-next-line react/no-unused-prop-types
  stretch?: StretchType;
  // eslint-disable-next-line react/no-unused-prop-types
  applyFiltersClicked?: () => void;
}

/**
 * @deprecated Use <FilterBar /> instead
 */
const FilterBarOld = ({ className = '', children }: FilterBarBase) => (
  <LayoutFlexBox className={classNames(`${clx}--old`, className)}>{children}</LayoutFlexBox>
);

interface FilterBarProps extends FilterBarBase {
  name: string;
  initialValues?: object;
  savedSearchValues?: { name: string, searchData: FiltersField[]};
  canSaveSearch?: boolean;
  selectableTable?: boolean;
  // Use localStorageName and overrideKey props to change the default local storage name
  localStorageName?: string;
  overrideKey?: boolean;
  onSubmit?: (formData) => void;
  onSaveSearch?: () => void;
  selectSearch?: (filters: SearchDataInfo) => void;
  columns?: React.ComponentProps<typeof HideColumnsPanel>;
  CustomColumnsPanel?: React.ReactNode;
  columnsModal?: ColumnsModalProps;
  // keepTabStore allows keeping selectedTab and tabContent state from page to page
  keepTabStore?: boolean;
}

const FilterBar = ({
  className,
  stretch = 'page',
  children,
  onSubmit = () => undefined,
  applyFiltersClicked = () => undefined,
  savedSearchValues,
  canSaveSearch,
  onSaveSearch,
  selectSearch,
  columns,
  selectableTable,
  localStorageName = SAVE_SEARCH_FORM,
  overrideKey = false,
  CustomColumnsPanel,
  columnsModal,
  keepTabStore = false,
  ...formProps
}: FilterBarProps) => {
  const [state, setState] = React.useState<FilterState>(FilterState.Collapsed);
  const [selectedTab, setSelectedTab] = React.useState<FilterTabs>(tabStore.selectedTab);
  const [tabContent, setTabContent] = React.useState<React.ReactNode>(tabStore.tabContent);
  const [timeoutId, setTimeoutId] = React.useState<ReturnType<typeof setTimeout>>();
  const [searchData, setSearchData] = React.useState<FiltersField[]>([]);
  const { openModal, closeModal } = useModal(COLUMNS_MODAL);

  React.useEffect(() => {
    if (state === FilterState.Expanded || state === FilterState.Collapsed) {
      return;
    }
    const collapsing = state === FilterState.Collapsing;

    setTimeoutId(setTimeout(() => {
      setState(collapsing ? FilterState.Collapsed : FilterState.Expanded);
    }, 300));
  }, [state]);

  const toggle = React.useCallback((tab: FilterTabs, content: React.ReactNode) => {
    const isClosed = state.startsWith('col');
    const keepOpen = !isClosed && selectedTab !== tab;

    setSelectedTab(tab);
    setTabContent(content);

    if (!keepOpen) {
      clearTimeout(timeoutId);
      setState(isClosed ? FilterState.Expanding : FilterState.Collapsing);
    }
  }, [state, selectedTab]);

  const handleOnChange = (e) => {
    setSearchData(e.morefilters);
  };

  React.useEffect(() => {
    if (keepTabStore && tabStore.selectedTab && tabStore.tabContent) {
      setSelectedTab(tabStore.selectedTab);
      setTabContent(tabStore.tabContent);
      setState(FilterState.Expanded);
    }
  }, []);

  React.useEffect(() => {
    if (keepTabStore && state === FilterState.Expanded) {
      tabStore.selectedTab = selectedTab;
      tabStore.tabContent = tabContent;
      return;
    }

    if (keepTabStore && state === FilterState.Collapsed) {
      tabStore.selectedTab = null;
      tabStore.tabContent = null;
    }
  }, [state]);

  return (
    <FilterBarContext.Provider value={{
      state,
      toggle,
      canSaveSearch,
      onSaveSearch,
      localStorageName,
      overrideKey,
      applyFilters: applyFiltersClicked,
      openTab: selectedTab,
    }}>
      <Form
        className={classNames(clx, `${clx}--${stretch}`, `${clx}--${state}`, className)}
        onSubmit={onSubmit}
        onChange={handleOnChange}
        {...formProps}
      >
       <div className={`${clx}__controls`}>
          <div className={`${clx}__controls__inputs`}>{children}</div>
          {
            columnsModal && <Button
              className={`${clx}__button`}
              caption="Columns"
              onClick={openModal}
              outline
            />
          }
        </div>
        <div className={classNames(
          clxTabContent,
          `${clxTabContent}--${state}`,
          (tabStore.selectedTab && state === FilterState.Expanded) && `${clxTabContent}--remove-transition`,
        )}>
          {selectedTab === FilterTabs.MoreOptions && <>{tabContent}</>}
          {(selectedTab === FilterTabs.Columns && !CustomColumnsPanel)
            && <HideColumnsPanel {...columns} selectableTable={selectableTable} />}
          {(selectedTab === FilterTabs.Columns && CustomColumnsPanel)
             && CustomColumnsPanel}
        </div>
        {
          columnsModal && <ColumnsModal
            {...columnsModal}
            closeModal={closeModal}
          />
        }
      </Form>

      {canSaveSearch
        && <SaveSearchModal
          data={searchData}
          searchName={savedSearchValues.name}
          selectSearch={selectSearch}
          localStorageName={localStorageName}
          overrideKey={overrideKey}
        />}
    </FilterBarContext.Provider>
  );
};

const LazyPanel = React.lazy(() => import('./components/MoreOptionsPanel/MoreOptionsPanel'));
const LazyMoreFiltersTable = React.lazy(() => import('./components/MoreFiltersTable/MoreFiltersTable'));

/**
 * @deprecated Use <FilterBar /> instead
 */
FilterBar.Old = FilterBarOld;
FilterBar.Input = Input;
FilterBar.Select = Select;
FilterBar.SelectStatus = SelectStatus;
FilterBar.Button = Button;
FilterBar.MoreOptionsLabel = MoreOptionsLabel;
FilterBar.MoreOptionsPanel = (props: React.ComponentProps<typeof LazyPanel>) => (
  <Lazy Component={LazyPanel} {...props} />
);
FilterBar.MoreFiltersTable = (props: React.ComponentProps<typeof LazyMoreFiltersTable>) => (
  <Lazy Component={LazyMoreFiltersTable} {...props} />
);
FilterBar.FilteredBy = FilteredBy;
FilterBar.Divisor = () => <div className={`${clx}__divisor`} />;

export default FilterBar;
export { FilterBar };
