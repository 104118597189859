import { useState, useEffect } from 'react';
import { 
  ExplorerProps, BaseNode, ExplorerSelection, ExplorerLevel,
} from './types';

export const toString = (s: unknown) => s as string;
export function useExplorer<T extends BaseNode<T>>({
  idProp, onRowClick, data, onSelection, externalSelection, onExplorerSelection,
}: Pick<ExplorerProps<T>, 'onRowClick' | 'idProp' | 'data' | 'onSelection'
| 'externalSelection' | 'onExplorerSelection'
>) {
  const [internalSelection, setInternalSelection] = useState<ExplorerSelection[]>([]);
  const [levelPanes, setLevelPanes] = useState<ExplorerLevel<T>[]>([]);

  const selection = externalSelection ?? internalSelection;

  // dynamically build explorer panes levels except for leaf elements
  const buildLevelPanes = (level: number, children: T[], root?: string): ExplorerLevel<T>[] => {
    let levels: ExplorerLevel<T>[] = [{ level, root, data: children }];

    const nexLevel = children.find((n) => selection
      .some((s) => !s.isLeaf && s.id === toString(n[idProp]) && s.level === level));

    if (nexLevel && nexLevel.children?.length) {
      levels = [
        ...levels,
        ...buildLevelPanes(level + 1, nexLevel.children ?? [], toString(nexLevel[idProp])),
      ];
    }
    return levels;
  };

  const handleRowClick = async (row: T, level: number) => {
    if (externalSelection) {
      // clear selections of levels greater than currently highlighted row level
      let newSelection = selection.filter((s) => s.level < level);

      if (!newSelection.find((s) => s.id === (row[idProp] as unknown))) {
        newSelection = [...newSelection, { id: toString(row[idProp]), level, isLeaf: !row.children }];
      }

      onExplorerSelection({
        row,
        selection: newSelection,
      });
    } else {
      // first clear selections of levels greater than currently highlighted row level
      setInternalSelection(selection.filter((s) => s.level < level));

      if (onRowClick) {
        // if parent component must dynamically fetch subfolders then callback parent
        await onRowClick(row, level);
      }

      // if selected element not yet added into selection list then add it
      if (!selection.find((id) => id === (row[idProp] as unknown))) {
        setInternalSelection((prev) => [...prev, { id: toString(row[idProp]), level, isLeaf: !row.children }]);
      }

      onSelection?.(row, level, selection);
    }
  };

  const isSelected = (row: T) => {
    const selected = !!selection.find((s) => s.id === toString(row[idProp]));
    return selected;
  };

  const shouldDisplayNoData = () => {
    if (!selection.length) {
      return false;
    }
    const selectionLevel = selection.reduce((acc, s) => (acc >= s.level ? acc : s.level), 0);
    const paneLevel = levelPanes.reduce((acc, p) => (acc >= p.level ? acc : p.level), 0);
    return selectionLevel >= paneLevel;
  };

  const displayNoData = shouldDisplayNoData();

  useEffect(() => {
    setLevelPanes(buildLevelPanes(0, data));
  }, [selection, data]);

  return {
    selection,
    levelPanes,
    isSelected,
    displayNoData,
    handleRowClick,
  };
}
