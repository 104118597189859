import * as React from 'react';
import {
  LayoutFlexBox, Modal, Form, TextField, TagsField, LayoutFlexBoxItem,
} from 'ui';
import { formatDateTime } from 'framework/utils/datetimes';
import * as moment from 'moment';
import { toSentenceCase } from 'framework/utils/string';
import { NotificationsTranslations } from '../Menu/translations';
import { INotificationLine } from '../Menu/Menu.interfaces';
import { DETAILS_MODAL } from '../constants';
import './_styles.scss';
import { i18n } from 'i18n';
import { prefixClassName } from '../../_conf';

const clx = prefixClassName('');

interface DetailsModalProps {
  notification: INotificationLine;
  closeModal: () => void;
}

const ESTIMATED_DURATION = 'estimatedDuration';
const COMPONENTS_AFFECTED = 'componentsAffected';

export const DetailsModal = (props: DetailsModalProps) => {
  const {
    notification,
    closeModal,
  } = props;

  const estimatedHours: number = moment(notification.scheduledUntil).diff(notification.scheduledFor, 'hours');

  return (
    <Modal
      name={DETAILS_MODAL}
      size="large"
      caption={notification.name}
      className={`${clx}details-modal`}
      messages={[
        {
          type: 'notification',
          caption: notification.description ?? notification.impactOverride ?? notification.impact,
        },
      ]}
      footerOptions={[
        {
          onClick: closeModal,
          outline: true,
          caption: 'generic.button.close',
        },
      ]}
    >
        <Form
          name="detailsForm"
          initialValues={{
            ...notification,
            impact: toSentenceCase(notification.impactOverride ?? notification.impact),
            status: toSentenceCase(notification.status),
            // Calculated estimated duration hours using the schedule for time and scheduled until time
            [ESTIMATED_DURATION]: i18n.t(
              NotificationsTranslations.Hours,
              { count: estimatedHours },
            ),
            [COMPONENTS_AFFECTED]: notification.components?.map((component) => component.name)
          }}
        >
          <LayoutFlexBox flexWrap="wrap" flexDirection="column">
            <LayoutFlexBoxItem width="50%">
              <TextField
                readOnly
                className="mrg-btm-40"
                caption={NotificationsTranslations.StartTime}
                name="scheduledFor"
                format={(value) => formatDateTime(value)}
              />
              <TextField
                readOnly
                className="mrg-btm-40"
                caption={NotificationsTranslations.Impact}
                name="impact"
              />
              <TextField
                readOnly
                className="mrg-btm-40"
                caption={NotificationsTranslations.Status}
                name="status"
              />
              {/* Only show the estimated duration field if the estimated hours is more than 0
                  This could help avoiding confusion when an estimation shows as 0 hours */}
              {estimatedHours > 0 && (
                <TextField
                  readOnly
                  className="mrg-btm-40"
                  caption={NotificationsTranslations.EstimatedDuration}
                  name={ESTIMATED_DURATION}
                />
              )}
              <TagsField
                name={COMPONENTS_AFFECTED}
                className="mrg-btm-40"
                caption={NotificationsTranslations.ComponentsAffected}
                readOnly
              />
            </LayoutFlexBoxItem>
          </LayoutFlexBox>
        </Form>
    </Modal>
  );
};