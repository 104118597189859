import { call, put } from 'redux-saga/effects';
import { IMakeRequest, makeRequest } from 'framework/api/make-request';

import { toastListActions } from 'framework/actions';

import { actionTypes } from '../actions';
import { config } from 'config';
import { takeEveryWithConfirmation } from 'framework/sagas/extensions/confirmation';
import { createApiClientUpdateStateAction } from 'framework/api/apiClient/actions';

const {STORES_SAVE_STORE_STATUS} = actionTypes;

const baseUri = config.apiEndpoints.stores;

function* process({ payload }) {
  const { id, status } = payload;

  const request: IMakeRequest = {
    name: STORES_SAVE_STORE_STATUS,
    url: `${baseUri}/${id}/status`,
    method: 'PUT',
    body: {
      status
    }
  };

  const { error } = yield call(makeRequest, request);
  if (error) {
    yield put(
      toastListActions.addMessageError('modules.stores.storeSetup.errors.saveError')
    );
  } else {
    yield put(createApiClientUpdateStateAction({
      data: { id, status },
      name: actionTypes.API_PROVIDER_GET_STORE_STATUS,
      hasError: false,
      isFetching: false
    }));
  }
}

export default function* watchSaveStoreStatusAction() {
  yield takeEveryWithConfirmation(STORES_SAVE_STORE_STATUS, process);
}
