import * as React from 'react';
import Main from 'framework/components/main';
import { useLocation } from 'react-router-dom';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { isValidGrowthBookUrl } from '../../../growthbook/initGrowthBook';
import { userIsAdmin } from '../../authorization/useAuthUser';
import { appState } from '../../../stores/app';
import { handleGrowthBookOverrides } from '../../../growthbook/handleGrowthBookOverrides';
import { Features, isFeatureEnabled } from '../../../stores/features';
import { getGrowthBookOverrideFlags } from '../../../growthbook/getGrowthBookOverrideFlags';

const App = () => {
  const location = useLocation();
  const gb = useGrowthBook();

  const isOverrideFeatureFlagsEnabled = React.useMemo(() => {
    return isFeatureEnabled(Features.AllowFeatureFlagsHeaderOverride);
  }, []);

  React.useEffect(() => {
    if (isValidGrowthBookUrl) {
      gb.setAttributes({
        user_id: appState.user?.profile?.sub,
        user_email: appState.user?.profile?.email,
        user_is_administrator: userIsAdmin(),
      });

      if (isOverrideFeatureFlagsEnabled) {
        handleGrowthBookOverrides(gb);
      }
    }
  }, []);

  React.useEffect(() => {
    if (isValidGrowthBookUrl) {
      /** ignore feature refresh if feature flags are overridden by User-Agent */
      if (isOverrideFeatureFlagsEnabled) {
        const flagOverrides = getGrowthBookOverrideFlags(window?.navigator?.userAgent);
        if (flagOverrides) return;
      }
      gb.refreshFeatures();
    }
  }, [location]);

  return <Main />;
};

export default App;
