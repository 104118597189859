import * as React from 'react';
import classnames from 'classnames';
import { Button } from '../../..';
import { prefixClassName } from '../../../_conf';
import { FilterState, FilterTabs, useFilterBarContext } from '../../context';

interface TabButtonProps {
  caption: string;
  className?: string;
  tab: FilterTabs;
  children?: any;
}

const clx = prefixClassName('filter-bar__more-options');

export const TabButton = ({ caption, className, tab, children }: TabButtonProps) => {
  const { state, toggle, openTab } = useFilterBarContext();

  const onClick = () => {
    toggle(tab, children);
  };

  const isSelected = openTab === tab;
  const isMoving = state === FilterState.Expanding || state === FilterState.Collapsing;
  let localState: FilterState = state;

  if (!isSelected) {
    localState = isMoving ? FilterState.Collapsing : FilterState.Collapsed;
  }

  return (
    <Button
      caption={caption}
      className={classnames(className, `${clx}__button`, `${clx}__button--${localState}`)}
      icon="dropdownArrowDown"
      onClick={onClick}
    />
  );
};
