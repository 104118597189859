import * as React from 'react';
import { connect } from 'react-redux';
import { RadioGroupPrimitive as RadioGroup, SVG, Text } from 'framework/components/ui';
import { ContentManagementState } from 'framework/modules/contentmanagement/contentmanagement.interfaces';
import { cssClasses, translations } from './constants';
import './LayoutType.scss';
import { LabelProps, LayoutTypeProps } from './Interfaces';
import { useLayoutType } from './useLayoutType';
import { getRowLayout } from './selectors';

const Label = ({ img, text }: LabelProps) => (
  <span className={cssClasses.label}>
    <SVG src={img} />
    <Text tag="span" caption={text} className={cssClasses.text} />
  </span>
);

export const LayoutTypeFn = ({ rowIndex, layoutType }: LayoutTypeProps) => {
  const { value, setValue, layoutItems } = useLayoutType({ rowIndex, layoutType });

  const items = layoutItems.map(({ value: val, svg, text }) => ({
    value: val,
    label: <Label img={svg} text={text} />,
  }));

  return (
    <div className={cssClasses.wrap}>
      <Text tag="span" caption={translations.title} className={cssClasses.title} />
      <RadioGroup
        name="mobile-layout"
        className={cssClasses.root}
        data={items}
        displayInline
        value={value}
        onChange={setValue as any}
      />
      <Text tag="span" caption={translations.description} className={cssClasses.explainer} />
    </div>
  );
};

const mapState = (state: ContentManagementState) => {
  const {
    contentmanagement: { activeRowIndex },
  } = state;

  return {
    rowIndex: activeRowIndex,
    layoutType: getRowLayout(state),
  };
};

export const LayoutType = connect(mapState)(LayoutTypeFn);
