import * as React from 'react';
import { AppRouteBase } from 'framework/components/navigation/Navigation.interfaces';
import { UsePageState } from './usePageState';
import { SkipTake } from '../ConnectedTable/usePagination';
import { PageContentProps } from './types';

interface PageState {
  internalFilters?: any;
  pagination?: SkipTake;
  [k: string]: any;
}

interface PageContextProps<T> {
  usePageState: (initialState: T) => UsePageState<T>;
  updatePageTitle: (titles: { pageTitle?: string; pageTitleDetails?: string }) => void;
  updatePageGap?: (component: React.ComponentType | React.ComponentType<PageContentProps<T>>) => void;
  appRoute?: AppRouteBase;
}

const PageContext = React.createContext<PageContextProps<PageState>>(undefined);

const usePageContext = () => React.useContext(PageContext);

export { PageContext, usePageContext };