import * as React from 'react';
import { Link } from 'ui';
import { IText } from './Text.interfaces';
import { prefixClassName } from '../_conf';
import './style.scss';

const labelClx = prefixClassName('text--label');

const Text = React.memo((props: IText) => {
  const {
    caption,
    className = '',
    linebreak,
    tag,
    style,
    label,
    title,
    url,
  } = props;
  const Tag = tag || (React.Fragment as any);

  return (
    <>
      {tag ? (
        <Tag className={`${className} ${label ? labelClx : ''}`} style={style} title={title} >
          {caption} {url && <Link caption={url.caption} to={url.to} external={url.external} />}
        </Tag>
      ) : (
        caption
      )}
      {linebreak && <br />}
    </>
  );
});

export default Text;
