import { GridAction } from 'types';

export type HiddenColumns = string | number;

type HeaderRenderer = (
  data: any[],
  tableName: string,
  metadata: ObjectMetadata[],
  hiddenColumns?: HiddenColumns[]
) => JSX.Element | JSX.Element[];
type CustomRenderer<T> = (data: T, col: number, row: number) => JSX.Element | JSX.Element[];

export interface ObjectMetadataBase<T = {}> {
  caption?:
  | string
  | {
    customRenderer?: (columnIndex: number) => JSX.Element | Element;
    component?: string;
    componentOptions?: any
  };
  id?: string;
  className?: string;
  customRenderer?: CustomRenderer<T>;
  align?: 'left' | 'right' | 'center';
  /**
   * adds the word "(optional)" in front oh the title
   */
  optional?: boolean;
  canHide?: boolean;
  uniqueId?: string;
  locked?: boolean;
  leftLockable?: boolean;
  rightLockable?: boolean;
  hidden?: boolean;
}
export interface ObjectMetadata<T = {}> extends ObjectMetadataBase<T> {
  component?: string;
  componentOptions?: any;
}

export type StringObjectMetadata = (string | ObjectMetadata)[];

export type FunctionMetadata = (data: any[]) => StringObjectMetadata;

export type ColumnMetadata = StringObjectMetadata | FunctionMetadata;

export type ObjectListPageMetadata<T> = ObjectMetadataBase<T> & {
  customRenderer: CustomRenderer<T>;
};

export type ListPageMetadata<T> = (string | ObjectListPageMetadata<T>)[];

export type CellRenderer = (
  tableName: string,
  rowData: any,
  columnMetadata: ObjectMetadata[],
  rowIndex: number,
  hiddenColumns: HiddenColumns[]
) => React.ReactNode;

export interface ITableBase {
  className?: string;
  tableName?: string;
  /**
   * Helper property for automation purposes
   */
  autoid?: string;
  columnMetadata: ColumnMetadata;
  headerRenderer?: HeaderRenderer;
  onTableRendered?: () => void;
  ifEmpty?: (...args: any[]) => JSX.Element;
  emptyText?: string;
  hiddenColumns?: HiddenColumns[];
  noBorder?: boolean;
}

export interface FilterBarSettings {
  filterPlaceholder: string;
  filterKeywords: string[];
  stretchToModalWidth?: boolean;
  className?: string;
}

export type OnDragEndCb = <T>(rowId: string, newIndex: number, data?: T, items?: T[]) => void;

export interface ITable<T> extends ITableBase {
  data: T[];
  cellRenderer?: CellRenderer;
  draggable?: boolean;
  // Use editSequence, onEditSequence, editSequenceCaption along with
  // draggable to show a update sequence button
  // This will change to Save/Cancel when editSequence is true
  editSequence?: boolean;
  onEditSequence?: () => void;
  editSequenceCaption?: string;
  // Use onAddItemClick and addItemCaption to show a button for adding item to Table data array
  // (external inline edit or modal logic required)
  onAddItemClick?: () => void;
  addItemCaption?: string;
  saveSequenceCaption?: string;
  onDragEnd?: OnDragEndCb;
  actions?: GridAction<T>[];
  onActionInvoked?: <T>(action: ReturnType<GridAction<T>['config']>, item: T) => void;
  canSelectItems?: boolean;
  selectedItems?: T[];
  disabledItems?: T[];
  loadingItems?: T[];
  selectionLogic?: (item: T) => boolean;
  toggleAllSelection?: (selectAll: boolean) => void;
  toggleItemSelection?: (item: T) => void;
  // use for draggable table 
  draggableId?: string;
  setExternalData?: (data:T[]) => void;
  disableAddOption?: boolean;
  // use for replacing original data words in default render
  originalWord?: string,
  replacementWord?: string
  // use this to add filtering to Table component
  // only use for string properties
  // do not use with draggable Table
  filterBarSettings?: FilterBarSettings;
  responsive?: boolean;
  hideCheckboxPadding?: boolean;
  showOnlyFirstRow?: boolean;
}

export interface ITableHeader {
  data: any[];
  metadata: ObjectMetadata[];
  cellRenderer?: HeaderRenderer;
  tableName: string;
  hiddenColumns?: HiddenColumns[];
}

export interface ITableRow {
  tableName?: string;
  data: object[];
  columnMetadata: ObjectMetadata[];
  cellRenderer?: CellRenderer;
  hiddenColumns?: HiddenColumns[];
}

export enum ColumnTextInlineEditTypes {
  TextInput = 'Text',
  SelectInput = 'Select'
}
