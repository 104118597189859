/* eslint-disable react/prefer-stateless-function */
import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import * as classnames from 'classnames';
import './_style.scss';

const className = conf.prefixClassName('dashlet');

interface IDashletProps {
  background?: 'main-color-darkest' | 'grey-darker' | 'main-color-darker';
  backgroundImage?: string;
  backgroundImagePosition?: string;
  children: any;
}

export default class Dashlet extends React.Component<IDashletProps> {
  static defaultProps = {
    background: 'main-color-darkest',
  };

  render() {
    const { background, backgroundImage, backgroundImagePosition, children } = this.props;
    let styles = {};

    if (backgroundImage) {
      styles = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: backgroundImagePosition || 'left center',
      };
    }

    return (
      <div className={classnames([className], [`${className}--${background}`])} style={{ ...styles }}>
        {children}
      </div>
    );
  }
}
