// Tabs
export const TABS_ID = 'slotTemplatesEdit';

export enum SlotTemplateEditTabs {
  edit = 'edit-slots',
  schedule = 'schedule',
  details = 'template-details',
}

export enum FulfillmentTypes {
  Delivery = 'Delivery',
  Pickup = 'Pickup',
  Instacart = 'Instacart',
}

export const FORM_EDIT_CALENDAR = 'calendarSlotManagement';

// Create New Slot (Modal)
export const TABS_SLOTEDIT_ID = 'slotTemplatesModalEdit';
export const TAB_SLOTEDIT_DETAILS = 'slotTemplatesModalEditDetails';
export const TAB_SLOTEDIT_AREAS = 'slotTemplatesModalEditAreas';
export const FORM_SLOTEDIT_TIME_INI = 'slotEditTimeIni';
export const FORM_SLOTEDIT_TIME_END = 'slotEditTimeEnd';
export const FORM_SLOTEDIT_TIME_END_DEFAULT = '02:00';

export const FORM_SLOTEDIT_DAY_ALL = 'slotEditDayAll';
export const FORM_SLOTEDIT_DAY_01 = 'slotEditDay01';
export const FORM_SLOTEDIT_DAY_02 = 'slotEditDay02';
export const FORM_SLOTEDIT_DAY_03 = 'slotEditDay03';
export const FORM_SLOTEDIT_DAY_04 = 'slotEditDay04';
export const FORM_SLOTEDIT_DAY_05 = 'slotEditDay05';
export const FORM_SLOTEDIT_DAY_06 = 'slotEditDay06';
export const FORM_SLOTEDIT_DAY_00 = 'slotEditDay00';

export const FORM_SLOTEDIT_CAPACITY = 'slotEditCapacity';
export const FORM_SLOTEDIT_CUTOFF_HOURS = 'slotEditCutOffHours';
export const FORM_SLOTEDIT_CUTOFF_MINUTES = 'slotEditCutOffMinutes';
export const FORM_SLOTEDIT_AMEND_TOGGLE = 'slotToggleAmend';
export const FORM_SLOTEDIT_AMEND_CUTOFF_HOURS = 'slotEditAmendCutOffHours';
export const FORM_SLOTEDIT_AMEND_CUTOFF_MINUTES = 'slotEditAmendCutOffMinutes';
export const FORM_SLOTEDIT_PICKING_STARTING_TOGGLE = 'slotEditTogglePickingStart';
export const FORM_SLOTEDIT_PICKING_STARTING_HOURS = 'pickingStartTimeHours';
export const FORM_SLOTEDIT_PICKING_STARTING_MINUTES = 'pickingStartTimeMinutes';
export const FORM_SLOTEDIT_PICK_BY_HOURS = 'slotEditPickByHours';
export const FORM_SLOTEDIT_PICK_BY_MINUTES = 'slotEditPickByMinutes';
export const FORM_SLOTEDIT_USE_DEFAULT_PICK_BY = 'slotEditDefaultPickBy';
export const FORM_SLOTEDIT_PRICE = 'slotEditPrice';
export const FORM_SLOTEDIT_FULFILLMENT = 'slotEditFulfillment';
export const FORM_SLOTEDIT_TAGS = 'slotEditTags';
export const FORM_SLOTEDIT_SHOPPINGID = 'shoppingModeId';

// Stores & Schedule
export const FORM_STORES = 'templateStores';
export const FORM_SCHEDULE = 'templateSchedule';
export const STORES_AND_SCHEDULE_MODAL = 'STORES_AND_SCHEDULE_MODAL';

// Details
export const FORM_DETAILS_ID = 'templateEditId';
export const FORM_DETAILS_NAME = 'templateEditName';
export const FORM_DETAILS_SHOPPINGMODE = 'templateEditShoppingMode';
export const FORM_DETAILS_AVAILABLE_STORES = 'templateEditAvailableSTores';
