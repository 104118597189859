import * as React from 'react';
import { Text } from 'framework/components/ui';
import { ILabelPrimitive } from './LabelPrimitive.interface';

export const LabelPrimitive = React.memo((props: ILabelPrimitive) => {
  const { children, caption, className, name } = props;
  const content = children || (caption && <Text caption={caption} />) || null;

  return (
    <label aria-label={name} className={className} htmlFor={name}>
      {content}
    </label>
  );
});

export default LabelPrimitive;
