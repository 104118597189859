import * as React from 'react';
import classNames from 'classnames';
import { Button, Panel, Text } from 'ui';
import { get, save } from 'framework/services/localStorage/localStorage';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useLocation } from 'react-router-dom';
import { SummaryProps, SummarySectionProps } from './Summary.interfaces';
import { Item } from './Summary.Item';
import { DateItem } from './Summary.DateItem';
import { clx } from './constants';
import './style.scss';
import Icon from '../Icon/Icon';

const Section = ({ title, children }: SummarySectionProps) => (
  <section className={`${clx}__section`}>
    <Text tag="span" caption={title} className={`${clx}__section__title`} />
    {children}
  </section>
);

const Summary = ({ title = 'generic.summary', children, className, trackEventProps }: SummaryProps) => {
  const [showSummary, setShowSummary] = React.useState<boolean>(get('show_summary', true) ?? true);
  const { pathname } = useLocation();

  const handleShowSummary = () => {
    save('show_summary', !showSummary, null, true);
    setShowSummary(!showSummary);
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || title,
      trackEventProps?.label || 'summaryViewButton'
    );
  };

  return (
    <>
      <Button
        className={`${clx}__icon_group`}
        onClick={handleShowSummary}
        testId="summmaryViewButton"
        name="summaryViewButton"
      >
        <Icon name="formatListBullet" className={`${clx}__icon`} color="white" />
        <Icon
          name={showSummary ? 'dropdownArrowRight' : 'dropdownArrowLeft'}
          color="white"
          className={`${clx}__icon`}
        />
      </Button>
      <Panel title={title} className={classNames(clx, className, !showSummary && `${clx}__hidden`)} tabIndex={0}>
        {children}
      </Panel>
    </>
  );
};

Summary.Section = Section;
Summary.Item = Item;
Summary.DateItem = DateItem;

export default Summary;
