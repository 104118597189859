import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { goToRoute } from 'framework/components/navigation/service';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import translations, { ListingTranslations } from './translations';
import * as CONST from './constants';

export const recommendationsListClaims = {
  [AuthorizationClaims.MERCHANDISING_RECOMMENDATIONS]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_SITE]: UserAccessRights.Read,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
};

export const recipesClaims = {
  [AuthorizationClaims.MERCHANDISING_RECIPES]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.DAM]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_SITE]: UserAccessRights.Read,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
};

export const listingsClaims = {
  [AuthorizationClaims.RECOMMENDATION_LISTING]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_SITE]: UserAccessRights.Read,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
};

export const recommendationsListRoute: AppRoute = {
  pageTitle: translations.RecommendationList,
  menuTitle: translations.RecommendationMenu,
  path: CONST.PATH_RECOMMENDATIONS_FULLPATH,
  authClaim: recommendationsListClaims,
  Component: () => import('./pages/RecommendationsList'),
};

export const goToRecommendationsList = () => goToRoute(recommendationsListRoute);

export const recommendationCreateRoute: AppRoute = {
  pageTitle: translations.RecommendationCreate,
  path: CONST.PATH_RECOMMENDATIONS_CREATE_FULLPATH,
  authClaim: recommendationsListClaims,
  Component: () => import('./pages/RecommendationCreate'),
  parent: recommendationsListRoute,
};

export const recommendationEditRoute: AppRoute = {
  pageTitle: translations.RecommendationEdit,
  path: `${CONST.PATH_RECOMMENDATIONS_EDIT_FULLPATH}/:recommendationId`,
  authClaim: recommendationsListClaims,
  Component: () => import('./pages/RecommendationEdit'),
  parent: recommendationsListRoute,
};

export const goToRecommendationsEdit = (recommendationId: string) => (
  goToRoute(recommendationEditRoute, { recommendationId })
);

export const recipesListRoute: AppRoute = {
  pageTitle: translations.RecipesList,
  menuTitle: translations.RecipesMenu,
  path: CONST.PATH_RECIPES_FULLPATH,
  authClaim: recipesClaims,
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/RecipesList'),
};

export const recipeCreateRoute: AppRoute = {
  pageTitle: translations.RecipesCreate,
  path: CONST.PATH_RECIPES_CREATE_FULLPATH,
  authClaim: recipesClaims,
  Component: () => import('./pages/RecipeCreate'),
  parent: recipesListRoute,
};

export const recipeEditRoute: AppRoute = {
  pageTitle: translations.RecipesEdit,
  path: `${CONST.PATH_RECIPES_EDIT_FULLPATH}/:recipeId`,
  authClaim: recipesClaims,
  Component: () => import('./pages/RecipeEdit'),
  parent: recipesListRoute,
};

export const listingListRoute: AppRoute = {
  pageTitle: ListingTranslations.Listings,
  menuTitle: ListingTranslations.AllListings,
  path: '/merchandising/listings',
  authClaim: listingsClaims,
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/ListingsList'),
};

export const listingCreateRoute: AppRoute = {
  pageTitle: ListingTranslations.CreateListing,
  path: '/merchandising/listings/create',
  authClaim: listingsClaims,
  Component: () => import('./pages/ListingCreate'),
  parent: listingListRoute,
};

export const listingEditRoute: AppRoute = {
  pageTitle: ListingTranslations.EditListing,
  path: '/merchandising/listings/:listingId',
  authClaim: listingsClaims,
  Component: () => import('./pages/ListingEdit'),
  parent: listingListRoute,
};

export const merchandisingRoutes: AppRoute[] = [
  recommendationsListRoute,
  recommendationCreateRoute,
  recommendationEditRoute,
  recipesListRoute,
  recipeCreateRoute,
  recipeEditRoute,
  listingListRoute,
  listingCreateRoute,
  listingEditRoute,
];
