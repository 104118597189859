export enum StoresTranslations {
  FilterByName = 'modules.stores.filterByName',
  FilterByStatus = 'modules.stores.filterByStatus',
  FilterByStoreType = 'modules.stores.filterByStoreType',
  FilterByNameOrId = 'modules.stores.filterByNameOrId',
  SelectAll = 'generic.selectAll',
  EditPageTitle = 'modules.stores.storeSetup.editPageTitle',
  DeleteStoreTitle = 'modules.stores.storeSetup.confirmationModal.deleteStoreTitle',
  DeleteStoreMessage = 'modules.stores.storeSetup.confirmationModal.deleteStoreMessage',
  DeleteStoreConfirmBtn = 'modules.stores.storeSetup.confirmationModal.deleteConfirmButton',
  DeleteStoreCancelBtn = 'modules.stores.storeSetup.confirmationModal.deleteCancelButton',
  ChangeStatusTitle = 'modules.stores.storeSetup.details.status.changeStatusTitle',
  ChangeStatusConfirmation = 'modules.stores.storeSetup.details.status.confirmation',
  ChangeStatusConfirmBtn = 'modules.stores.storeSetup.details.status.changeStatusBtn',
  ChangeStatusCancelBtn = 'modules.stores.storeSetup.details.status.dontChangeStatusBtn',
  ChangeStatusCaption = 'modules.stores.storeSetup.details.status.caption',
  SaveChanges = 'modules.stores.storeSetup.details.actions.submit',
  Reset = 'generic.button.reset',
  Delete = 'modules.stores.storeSetup.details.actions.delete'
}
export enum DeliveryTranslations {
  DeliveryZonesPanelTitle = 'modules.stores.deliveryZones.title',
  DeliveryZones = 'modules.stores.deliveryZones.header',
  StoreZonesName = 'modules.stores.deliveryZones.storeAreas.name',
  StoreZonesAddNew = 'modules.stores.deliveryZones.storeAreas.addNew',
  StoreZonesDeleteAll = 'modules.stores.deliveryZones.storeAreas.deleteAll',
  StoreZonesCount = 'modules.stores.deliveryZones.table.zonesCount',
  Contacts = 'modules.stores.deliveryZones.storeAreas.contacts',
  IsEditing = 'modules.stores.deliveryZones.table.isEditing',
}
export enum StoreHierarchyTranslations {
  EditPageTitle = 'modules.stores.storeHierarchy.editPageTitle',
  ManageHierarchy = 'modules.stores.storeHierarchy.manageHierarchy',
  AddRootFolder = 'modules.stores.storeHierarchy.addRootFolder',
  FilterByName = 'modules.stores.storeHierarchy.filterByName',
  StoresColumn = 'modules.stores.storeHierarchy.storesColumn',
  AddSubFolder = 'modules.stores.storeHierarchy.addSubFolder',
  RenameFolder = 'modules.stores.storeHierarchy.renameFolder',
  StoresCount = 'modules.stores.storeHierarchy.storesCount',
  FolderName = 'modules.stores.storeHierarchy.folderName',
  ToStoreHierarchy = 'modules.stores.storeHierarchy.toStoreHierarchy',
  NewFolderSaveSuccess = 'modules.stores.storeHierarchy.newFolderSaveSuccess',
  NewFolderSaveError = 'modules.stores.storeHierarchy.newFolderSaveError',
  RenameFolderSuccess = 'modules.stores.storeHierarchy.renameFolderSuccess',
  RenameFolderError = 'modules.stores.storeHierarchy.renameFolderError',
  MoveFolder = 'modules.stores.storeHierarchy.moveFolder',
  MoveStore = 'modules.stores.storeHierarchy.moveStore',
  MoveToFolder = 'modules.stores.storeHierarchy.moveToFolder',
  DoNotMoveToFolder = 'modules.stores.storeHierarchy.doNotMoveToFolder',
  MoveNodeSuccess = 'modules.stores.storeHierarchy.moveNodeSuccess',
  MoveNodeError = 'modules.stores.storeHierarchy.moveNodeError',
  DeleteFolderSuccess = 'modules.stores.storeHierarchy.deleteFolderSuccess',
  DeleteFolderError = 'modules.stores.storeHierarchy.deleteFolderError',
  DeleteFolderAreYouSure = 'modules.stores.storeHierarchy.deleteFolderAreYouSure',
  DeleteFolderWarning = 'modules.stores.storeHierarchy.deleteFolderWarning',
  DeleteFolder = 'modules.stores.storeHierarchy.deleteFolder',
  DoNotDeleteFolder = 'modules.stores.storeHierarchy.doNotDeleteFolder',
}