import i18n from 'i18n';
import { AppError } from 'types';
import { SubmitResult } from './Form.interface';

export const handleFormErrors = <T extends {}>(result: SubmitResult<T>,
  mapErrorsToFields?: (receivedErrors: any) => AppError<T>) => {
  const { errors } = result.error ?? result;
  let formErrors: AppError<T>;
  let errorMessages: string[] = [];

  formErrors = errors;

  if (mapErrorsToFields) {
    formErrors = mapErrorsToFields(errors);
  }

  const { globalError, ...fieldErrors } = formErrors;

  // handles global form submission error
  if (globalError) {
    if (Array.isArray(globalError)) {
      errorMessages = errorMessages.concat(globalError);
    } else {
      errorMessages.push(globalError);
    }
  }

  const count = Object.keys(fieldErrors).length;

  if (count > 0) {
    // has field errors, prompts the user to fix them
    errorMessages.push(i18n.t('generic.formsValidation.fixFields', { count }));
  }

  return {
    formErrors,
    errorMessages,
  };
};
