import * as React from 'react';
import { Button } from 'ui';
import ActionsBoostAndBuryFilterTemplate from './ActionsBoostAndBuryFilterTemplate';

interface ActionsBoostAndBuryFilterProps {
  fields: any;
  actionFieldOptions: any;
  actionOperationOptions: any;
  fetchCurrentData: () => void;
}
export default (props: ActionsBoostAndBuryFilterProps) => {
  const { fields, actionFieldOptions, actionOperationOptions, fetchCurrentData } = props;

  const addDynamicField = () => {
    fields.push({});
  };

  return (
    <div>
      {fields.map((field, index) => (
        <ActionsBoostAndBuryFilterTemplate
          key={field}
          field={field}
          remove={fields.remove}
          fieldIndex={index}
          actionFieldOptions={actionFieldOptions}
          actionOperationOptions={actionOperationOptions}
          fetchCurrentData={fetchCurrentData}
        />
      ))}
      <Button onClick={addDynamicField} caption="Add Filter" icon="plus" outline />
    </div>
  );
};
