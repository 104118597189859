import * as actions from './Modal.actions';

// TODO: props for state
export default (state = {} as any, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.OPEN_MODAL: {
      return {
        ...state,
        [payload]: true,
      };
    }
    case actions.CLOSE_MODAL: {
      const newState = { ...state };
      delete newState[payload];

      return {
        ...newState,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
