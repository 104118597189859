import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { translations } from './translations';
import { reportBasePath } from './constants';
import { fetchReportMenus } from './services';

export const ReportsModule: ModuleConfig = {
  name: 'reports',
  appMenuTitle: translations.reports,
  appMenuIcon: 'report',
  modulePath: reportBasePath,
  pageGroups: fetchReportMenus,
};
