import * as React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';
import userManager from 'framework/utils/userManager';

class CallbackPage extends React.Component<any> {
  errorCallback = () => {
    this.props.dispatch(push('/'));
    // TODO: Log error somewhere?
  };

  successCallback = (user) => {
    this.props.dispatch(user.state ? push(user.state) : push('/'));
  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div />
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
