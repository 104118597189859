import { useState, useEffect } from 'react';
import { useDispatch } from 'framework/utils/useDispatch';
import { LayoutwidthProps, LayoutWidthType, LayoutwidthItem } from './Interfaces';
import { actions, translations } from './constants';
import contentSvg from './content-width.svg';
import browserSvg from './browser-width.svg';

export const useLayoutWidth = ({ rowIndex, width }: LayoutwidthProps) => {
  const dispatch = useDispatch();
  const [initialValue] = useState<LayoutWidthType>(width);
  const [value, setValue] = useState<LayoutWidthType>(initialValue);

  const layoutItems: LayoutwidthItem[] = [
    {
      value: LayoutWidthType.contentWidth,
      svg: contentSvg,
      text: translations.contentWidth,
    },
    {
      value: LayoutWidthType.browserWidth,
      svg: browserSvg,
      text: translations.browserWidth,
    },
  ];

  const handleSetValue = (val: LayoutWidthType) => {
    setValue(val);
    dispatch(actions.saveLayoutWidth({ type: val, rowIndex }));
  };

  const onWidthChange = () => {
    if (width !== initialValue) {
      setValue(width);
    }
  };

  useEffect(onWidthChange, [width]);

  return {
    value,
    setValue: handleSetValue,
    layoutItems,
  };
};
