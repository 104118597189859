import * as React from 'react';
import { IAdminControlType, AdminTemplateInputTypes } from '@thryveai/theme-interfaces/dist';
import uuidv4 from 'uuid/v4';
import { toSentenceCase } from 'framework/utils/string';
import { Button, Img, Table } from 'ui';
import { IComponent } from '../../StoreFrontSettings.interfaces';

export const optionsMetaData = (
  component: Omit<IComponent, 'value'> & {
    title: string;
    value: {
      [k: string]: IComponent & { type: IAdminControlType };
    };
  }
) => {
  return Object.values(component.value).map((v) => {
    return toSentenceCase(v.title);
  });
};

interface OptionRowProps {
  data: any;
  component: Omit<IComponent, 'value'> & {
    title: string;
    value: {
      [k: string]: IComponent & { type: IAdminControlType };
    };
  };
  index: number;
  editSequence: boolean;
  handleClickOption: (id: string) => void;
  onDelete: (id: string) => void;
}

export const OptionRow = (props: OptionRowProps) => {
  const { component, data, editSequence, index, handleClickOption, onDelete } = props;
  const RowComp = editSequence ? Table.DraggableRow : Table.Row;

  return (
    <RowComp index={index} draggableId={data.id}>
      {editSequence && <Table.DraggableCell />}
      {Object.entries(component.value).map((v, i) => {
        const fieldName: string = v[0];
        const fieldValue: any = v[1];
        const matchedLabel = Array.isArray(component.value[fieldName]?.options)
          ? component.value[fieldName]?.options?.find((o) => o.value === data[fieldName])?.label ?? data[fieldName]
          : data[fieldName];

        switch (fieldValue.type) {
          case AdminTemplateInputTypes.inputChar: {
            return (
              <Table.Column key={uuidv4()}>
                {i === 0 ? (
                  <Button
                    caption={data[fieldName]}
                    onClick={() => {
                      handleClickOption(data.id);
                    }}
                    className="remove-spacing"
                    link
                    disabled={editSequence}
                  />
                ) : (
                  data[fieldName]
                )}
              </Table.Column>
            );
          }
          case AdminTemplateInputTypes.images: {
            return (
              <Table.Column key={uuidv4()}>
                <Img src={data[fieldName]} alt={toSentenceCase(fieldName)} width="20" height="20" />
              </Table.Column>
            );
          }

          default: {
            return <Table.ColumnText key={uuidv4()} caption={matchedLabel} />;
          }
        }
      })}
      <Table.ColumnDelete onDelete={() => onDelete(data.id)} disabled={editSequence} />
    </RowComp>
  );
};
