import classNames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import Icon from 'framework/components/ui/Icon';
import { CheckboxPrimitiveTypes } from './CheckboxPrimitive.interfaces';

import './_style.scss';
import { LabelPrimitive } from '../LabelPrimitive';

const clx = prefixClassName('checkbox');
const isTrue = (val: boolean | string): boolean => {
  if (val === 'false') {
    return false;
  }
  return !!val;
};
const CheckboxPrimitive = (props: CheckboxPrimitiveTypes) => {
  const {
    active,
    disabled = false,
    intermediate = false,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    value = false,
    className,
  } = props;
  const isChecked: boolean = typeof value === 'function' ? isTrue(value(name)) : isTrue(value);
  return (
    <div
      className={classNames(
        clx,
        active && `${clx}--focused`,
        disabled && `${clx}--disabled`,
        intermediate && `${clx}--intermediate`,
        isChecked && `${clx}--checked`,
        className,
      )}
    >
      {intermediate && <div className={`${clx}__intermediate`} />}
      <Icon name="checkmark" className={`${clx}__icon`} />
      <input
        checked={isChecked}
        className={`${clx}__cb`}
        disabled={disabled}
        id={name}
        name={name}
        onBlur={onBlur}
        aria-label={name || 'checkbox'}
        onChange={onChange}
        onFocus={onFocus}
        type="checkbox"
      />
      <LabelPrimitive name={name} className={`${clx}__box`} />
      {label && <LabelPrimitive name={name} className={`${clx}__label`} caption={label} />}
    </div>
  );
};

export default CheckboxPrimitive;
