import {
  SITEADMIN_LOAD_USER,
  SITEADMIN_STORE_USER,
  SITEADMIN_SAVE_USER,
  SITEADMIN_NEXT_TABSTEP,
  SITEADMIN_CLEAR_USER,
  SITEADMIN_STORE_PAGEFLOW,
  SITEADMIN_CHANGE_USER_STATUS,
} from './CommonPage.Constants';

export const loadUserAction = ({ userid }) => ({
  payload: { userid },
  type: SITEADMIN_LOAD_USER,
});

export const storeUserAction = ({ user }) => ({
  payload: { user },
  type: SITEADMIN_STORE_USER,
});

export const saveUserDataAction = ({
  createFlow,
  formValues = null,
  storeIds = null,
  roles = null,
}) => ({
  payload: {
    createFlow, formValues, storeIds, roles,
  },
  type: SITEADMIN_SAVE_USER,
});

export const saveUserStatusAction = ({
  status = null,
}) => ({
  payload: {
    status,
  },
  type: SITEADMIN_CHANGE_USER_STATUS,
});

export const nextTabSetAction = () => ({
  type: SITEADMIN_NEXT_TABSTEP,
});

export const clearUser = () => ({
  type: SITEADMIN_CLEAR_USER,
});

export const storePageFlow = ({ pageFlow }) => ({
  payload: { pageFlow },
  type: SITEADMIN_STORE_PAGEFLOW,
});
