import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { call, put, select } from 'redux-saga/effects';
import { makeRequest } from 'framework/api/make-request';
import uuidv4 from 'uuid/v4';
import { config } from 'config';
import * as get from 'lodash.get';
import { completeCurrentStep } from 'framework/components/ui/TabSteps/TabSteps.actions';
import { formActions, tabsActions } from 'framework/actions';
import { history } from 'framework/store';
import { takeEveryWithConfirmation } from 'framework/sagas/extensions/confirmation';
import { toastError, toastSuccess } from 'stores/toast';
import {
  USER_DETAILS_FORM,
  SITEADMIN_USER_TABS,
  SITEADMIN_USER_TABS_ROLES,
  SITEADMIN_USER_TABS_STORES,
  SITEADMIN_CHANGE_USER_STATUS,
} from '../CommonPage/CommonPage.Constants';
import { nextTabSetAction, storeUserAction } from '../CommonPage/CommonPage.Actions';
import { SITEADMIN_SAVE_USER } from '../CommonPage';
import { PATH_USER_EDIT_FULLPATH } from '../constants';

const mapFormErrors = ({ errors, formValues }) => Object.keys(errors).reduce((acc, key) => {
  const error = Array.isArray(errors[key]) ? errors[key].join('\r\n') : errors[key];
  if (!key) {
    return {
      ...acc,
      form: error,
    };
  }
  const formKey = Object.keys(formValues).find((k) => k.toLowerCase() === key.toLowerCase());
  const errorProp = formKey || key;
  return {
    ...acc,
    [errorProp]: error,
  };
}, {});

function* process({ payload }) {
  const user = yield select((state) => state.siteadmin.user);
  const tabStep = yield select((state) => state.siteadmin.tabStep);

  const {
    formValues, roles, storeIds, createFlow,
  } = payload;
  const newUser = !user || !user.id;

  const timeZone = formValues ? formValues.timeZone : undefined;
  // eslint-disable-next-line no-nested-ternary
  let timeZoneValue = timeZone
    ? typeof timeZone === 'object'
      ? {
        timeZone: timeZone.value,
      }
      : { timeZone }
    : undefined;

  if (!timeZone && user) {
    timeZoneValue = { timeZone: user.timeZone };
  }

  let rolesValue = roles ? { roles } : undefined;
  if (!rolesValue && user && user.roles) {
    rolesValue = {
      roles: user.roles.map((r) => r.id),
    };
  }

  const orgNodes = !storeIds ? {} : { orgHierarchyNodeIds: storeIds };

  const userData = {
    ...user,
    ...formValues,
    ...timeZoneValue,
    ...rolesValue,
    ...orgNodes,
    id: newUser ? uuidv4() : user.id,
  };

  const res = yield call(makeRequest, {
    url: `${config.apiEndpoints.users}/${newUser ? '' : `${user.id}`}`,
    body: userData,
    method: newUser ? 'POST' : 'PUT',
    name: SITEADMIN_SAVE_USER,
  });
  if (res.error) {
    const formErrors = get(res, 'error.errors');
    if (formErrors) {
      const errors: any = mapFormErrors({ errors: formErrors, formValues });
      if (errors.form) {
        yield put(toastError(errors.form));
      }
      yield put(
        formActions.createSetFormErrorsAction({
          name: USER_DETAILS_FORM,
          errors,
        }),
      );
    } else {
      yield put(toastError(res.error, 5000));
    }
  } else {
    yield put(toastSuccess('generic.data.savedSuccess'));

    // update redux store
    yield put(storeUserAction({ user: res.data }));

    if (createFlow) {
      if (tabStep === 0) {
        yield put(tabsActions.setOpenTab(SITEADMIN_USER_TABS, SITEADMIN_USER_TABS_STORES));
        yield put(nextTabSetAction());
      }
      if (tabStep === 1) {
        yield put(tabsActions.setOpenTab(SITEADMIN_USER_TABS, SITEADMIN_USER_TABS_ROLES));
        yield put(nextTabSetAction());
      }

      if (tabStep > 1) {
        history.push(`${PATH_USER_EDIT_FULLPATH}/${user.id}?tab=assign-roles`);
      } else {
        yield put(completeCurrentStep(SITEADMIN_USER_TABS));
      }
    }
  }
}

function* processStatus({ payload }) {
  const user = yield select((state) => state.siteadmin.user);

  const { status } = payload;

  const newUser = !user || !user.id;

  user.status = status;

  let rolesValue;
  if (user?.roles) {
    rolesValue = {
      roles: user.roles.map((r) => r.id),
    };
  }

  user.status = status;

  const userData = {
    ...user,
    ...rolesValue,
    id: newUser ? uuidv4() : user.id,
  };

  const res = yield call(makeRequest, {
    url: `${config.apiEndpoints.users}/${newUser ? '' : `${user.id}`}`,
    body: userData,
    method: newUser ? 'POST' : 'PUT',
    name: SITEADMIN_CHANGE_USER_STATUS,
  });
  if (res.error) {
    yield put(toastError('modules.siteadmin.userStatusError'));
  } else {
    yield put(toastSuccess('generic.data.savedSuccess'));

    // update redux store
    yield put(storeUserAction({ user: res.data }));
  }
}

export function* watchSaveUserStatusAction() {
  yield takeEveryWithConfirmation(SITEADMIN_CHANGE_USER_STATUS, processStatus);
}

export function* watchSaveUserData() {
  yield takeEveryWithProgressBar(SITEADMIN_SAVE_USER, process);
}
