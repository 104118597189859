import { fork } from 'redux-saga/effects';

import modalProductSearchGet from './modalProductSearchGet';
import watchModalProductSearchOpen from './modalProductSearchOpenSaga';
import watchModalProductSearchClose from './modalProductSearchCloseSaga';

export default [
  fork(watchModalProductSearchOpen),
  fork(watchModalProductSearchClose),
  fork(modalProductSearchGet)
];
