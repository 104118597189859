import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { templatesRoute, templatesEditRoute, liveTimeslotRoute } from './constants';

export const slotManagementeRoutes: AppRoute[] = [
  {
    ...templatesRoute,
    Component: () => import('./SlotTemplatesList'),
  },
  {
    ...templatesEditRoute,
    Component: () => import('./pages/SlotsTemplatesEdit'),
  },
  {
    ...liveTimeslotRoute,
    Component: () => import('./pages/LiveTimeSlotView'),
  },
];
