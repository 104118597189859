import { isString } from 'framework/utils/helpers';
import { ISelectPrimitiveOptionsProps } from './SelectPrimitive.interface';

/**
 * we used to use 'disabled' to disable and option, react-selected changed the prop to 'isDisabled' in a newer version
 * of react-select, this function maps the new prop
 * @param options
 */
export const transformOptions = (options: ISelectPrimitiveOptionsProps[]) => (
  (options || []).map(o => ({ ...o, isDisabled: o.disabled }))
);

const singleSelect = {
  /**
   * transform the initial value string property to the object used by react-select
   * @param value
   */
  transformValues: (value?: string | ISelectPrimitiveOptionsProps) => {
    if (value) {
      if (isString(value)) {
        return { value, label: value };
      }
      return { ...value as any as ISelectPrimitiveOptionsProps };
    }
    return value;
  },
  getAvailableOptions: (selected?: ISelectPrimitiveOptionsProps, options: ISelectPrimitiveOptionsProps[] = []) => {
    // check if value exists in options
    let selectedValues: ISelectPrimitiveOptionsProps;
    const availableOptions = [...options];

    if (selected) {
      selectedValues = options.find(opt => opt.value === selected.value);
      if (!selectedValues) {
        selectedValues = selected;
        availableOptions.unshift(selectedValues);
      }
    }

    return { selectedValues, availableOptions };
  },
  printLabels: (values?: ISelectPrimitiveOptionsProps) => values && values.label,
};

const multiSelect = {
  /**
   * transform the initial value string property to the object used by react-select
   * @param value
   */
  transformValues: (value?: (string | ISelectPrimitiveOptionsProps)[]) => {
    let transformedValues: ISelectPrimitiveOptionsProps[];

    if (value && value.length > 0) {
      transformedValues = value.map(singleSelect.transformValues).filter(x => x) as ISelectPrimitiveOptionsProps[];
    }

    return transformedValues;
  },

  // multi values
  getAvailableOptions: (selected?: ISelectPrimitiveOptionsProps[], options: ISelectPrimitiveOptionsProps[] = []) => {
    // check if value exists in options
    let selectedValues: ISelectPrimitiveOptionsProps[];
    const availableOptions = [...options];

    if (selected && selected.length > 0) {
      selectedValues = selected.map(s => {
        const found = options.find(opt => opt.value === s.value);
        if (!found) {
          // adds the selected option to the available options
          availableOptions.unshift(s);
        }
        return found || s;
      });
    }

    return { selectedValues, availableOptions };
  },

  printLabels: (values: ISelectPrimitiveOptionsProps[] = []) => (values && values.map(v => v.label).join(', ')),
};

export { multiSelect, singleSelect };
