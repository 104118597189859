import * as React from 'react';
import {
  Icon, LayoutFlexBox, LayoutFlexBoxItem, Select,
} from 'ui';
import { ActionFieldType } from './constants';

interface Props {
  field: string;
  actionField: string;
  onDelete: () => void;
}

export default ({ field, actionField, onDelete }: Props) => (
  <LayoutFlexBox flexWrap="nowrap">
    <LayoutFlexBoxItem className="mrg-btm-10" flexBasis="100%">
      <LayoutFlexBox justifyContent="space-between">
        <LayoutFlexBoxItem flexBasis="90%">
          <Select
            name={
              actionField === ActionFieldType.Products
                ? `${field}.product`
                : `${field}.categoryBreadCrumb`
            }
            disabled
          />
        </LayoutFlexBoxItem>
        <LayoutFlexBoxItem flexBasis="8%">
          <Icon
            onClick={onDelete}
            className="mrg-auto mrg-top-10 block cursor-pointer"
            name="del"
            size="sm"
            color="#96a0ab"
          />
        </LayoutFlexBoxItem>
      </LayoutFlexBox>
    </LayoutFlexBoxItem>
  </LayoutFlexBox>
);
