const getDayOfWeek = (date: Date, weekDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7) => {
  // 0 = Monday
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

  return new Date(date.setDate(diff + weekDay));
};

export const getMonday = (date: Date) => getDayOfWeek(date, 0);
export const getSunday = (date: Date) => getDayOfWeek(date, 6);

export const getDayPrevious = (dateReference: Date) => {
  const dt = new Date(dateReference);
  return new Date(dt.setDate(dt.getDate() - 1));
};
export const getDayNext = (dateReference: Date) => {
  const dt = new Date(dateReference);
  return new Date(dt.setDate(dt.getDate() + 1));
};
