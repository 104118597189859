import * as React from 'react';
import { ITabsAnchoredContentProps } from '../../Tabs.interface';

export default class TabsAnchoredContent extends React.Component<ITabsAnchoredContentProps> {
  myRef: any;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    if (props.tabsName === '') {
      throw new Error('TabsAnchoredContent can only be used inside Tabs component');
    }
  }

  componentWillUnmount() {
    this.props.unRegisterAnchor(this.props.caption);
  }

  componentDidMount() {
    this.props.registerAnchor(this.props.caption, this.myRef);
  }

  render() {
    return <div ref={this.myRef}>{this.props.children}</div>;
  }
}
