import * as React from 'react';
import { HierarchyNode } from './hierarchy.interfaces';
// eslint-disable-next-line import/no-cycle
import { HierarchyRow } from './hierarchy.row';

export const HierarchyChildrenRows = (props: {
  level?: number;
  childrenRows?: HierarchyNode[];
  singleSelect?: boolean;
  selectLeavesOnly?: boolean;
  hideIntermediary?: boolean;
  allowSingleLeaf?: boolean;
}) => {
  const {
    childrenRows, selectLeavesOnly, hideIntermediary, allowSingleLeaf, level, singleSelect,
  } = props;

  return (
    <>
      {childrenRows.map((node, i) => (
        <HierarchyRow
          key={i}
          currentNode={node}
          level={level}
          singleSelect={singleSelect}
          selectLeavesOnly={selectLeavesOnly}
          hideIntermediary={hideIntermediary}
          allowSingleLeaf={allowSingleLeaf}
        />
      ))}
    </>
  );
};
