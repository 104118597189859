import React from 'react';
import { Text } from '../Text';
import './style.scss';
import { prefixClassName } from '../_conf';

const clx = prefixClassName('local-clock');

type TimeStyle = 'short' | 'medium' | 'long' | 'full';

/**
 * Reference
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString
 * @param ln en-US, pt-BR
 * @param timeZone Timezone string from IANA database. I.e: 'Europe/Dublin', 'America/Sao_Paulo'
 * @param timeStyle timeStyle
 */
const getTime = (ln, timeZone, timeStyle: TimeStyle) => {
  try {
    return new Date().toLocaleTimeString(ln, { timeZone, timeStyle });
  } catch (e) {
    return undefined;
  }
};

interface LocalClockProps {
  /**
   * Label used on top of the clock
   */
  caption: string;
  /**
   * Language in which time should be formatted. I.e.: 'en-US', 'en-IE'
   * Default: window.navigator.language
   */
  ln?: string;
  /**
   * Timezone string. I.e: 'Europe/Dublin', 'America/Sao_Paulo'
   */
  timeZone: string;
  /**
   * Default: short
   */
  timeStyle?: 'short' | 'medium' | 'long' | 'full';
  className?: string;
}

const LocalClock = ({
  caption,
  ln = window.navigator.language,
  timeZone,
  timeStyle = 'short',
  className = '',
}: LocalClockProps) => {
  const [time, setTime] = React.useState(getTime(ln, timeZone, timeStyle));

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(getTime(ln, timeZone, timeStyle));
    }, 1000);

    return clearInterval.bind(null, interval);
  }, [ln, timeZone, timeStyle]);

  return (
    <>
      {time && (
        <div className={`${clx} ${className}`}>
          <Text tag="div" className={`${clx}__caption`} caption={caption} />
          <div className={`${clx}__time`}>{time}</div>
        </div>
      )
      }
    </>
  );
};

export { LocalClock };
