import * as React from 'react';
import { Table } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import type { ProductScore, ProductSearchResult } from '../../models/product';
import { ScoreExplanation } from '../../models/score';
import { Score, IDataGridScoreLabels } from '../Score/Score';
import '../../SearchResultsPreview/Grid/_styles.scss';

const gridRankClx = prefixClassName('grid-rank');

interface ProductRowProps {
  selectable?: boolean;
  onChange?: (product: ProductScore) => void;
  selected?: boolean;
  product: ProductSearchResult;
  selectProduct: (e: React.MouseEvent, product: ProductScore) => void;
  loadScoreExplanation?: (product: ProductScore) => Promise<ScoreExplanation>;
  scoreLabels?: IDataGridScoreLabels[];
  draggable?: boolean;
  showDraggable?: boolean;
  index: number;
  saved?: boolean;
}

export const ProductRow = ({
  selectable,
  onChange,
  selected,
  product,
  selectProduct,
  scoreLabels,
  loadScoreExplanation,
  draggable = false,
  showDraggable = false,
  index,
  saved = true,
}: ProductRowProps) => {
  const RowComp = showDraggable ? Table.DraggableRow : Table.Row;

  return (
    <RowComp index={index} draggableId={product?.sku} moved={!saved}>
      {showDraggable && (
        <>
          <Table.DraggableCell />
          <Table.Column width="90px">
            <span className={gridRankClx}>{index + 1}</span>
          </Table.Column>
        </>
      )}
      {selectable && <Table.ColumnCheckbox checked={selected} onChange={() => onChange(product)} />}
      <Table.ColumnImage
        src={product.image || product.imageUrl}
        alt={product.description || product.shortDescription || product.name}
        width="80px"
      />
      <Table.ColumnLink
        to={`/product/${product.sku}`}
        caption={`${product.brand} ${product.name}`}
        subcaption={[`SKU: ${product.sku}`, product.categoryBreadcrumb]}
        onClick={(e) => selectProduct(e, product)}
      />
      {scoreLabels && (
        <Table.Column width="80px">
          <Score
            product={product}
            showLabels={draggable ? ['score'] : scoreLabels}
            loadExplanation={loadScoreExplanation}
            saved={saved}
          />
        </Table.Column>
      )}
      {draggable && (
        <>
          <Table.ColumnText caption={String(product.offlineUnitSales ?? 0)} width="80px" align="right" />
          <Table.ColumnText caption={String(product.offlineRevenue ?? 0)} width="80px" align="right" />
        </>
      )}
      {draggable && (
        <Table.Column width="80px" align="right">
          <Score
            product={product}
            showLabels={['customPlacement']}
            loadExplanation={loadScoreExplanation}
            saved={saved}
          />
        </Table.Column>
      )}
    </RowComp>
  );
};
