import * as React from 'react';
import { prefixClassName } from '../../../_conf';
import { Text } from '../../..';

const clx = prefixClassName('filter-bar__more');

const MoreOptionsLabel = () => (
  <Text tag="span" caption="generic.moreFilterOptions" className={clx} />
);

export { MoreOptionsLabel };
