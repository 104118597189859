import { ImageSize } from '../common/CmsBlockElements/CmsBlockImageElement/CmsBlockImageSizes.Components';
import { ServerFile } from '../FileManager/types';

export const DAM_MODAL = 'DAM_MODAL';

export enum DamMode {
  file = 'file',
  directory = 'directory'
}

export interface DamsProps {
  modalName?: string;
  mode?: DamMode;
  disableDelete?: boolean;
  onDismiss?: () => void;
  onFileUploaded?: (file: File) => void;
}

export interface CmsDamProps {
  mode: DamMode;
}

export interface CmsDamContext {
  editorVisible?: boolean;
  uploadedFile?: ServerFile;
  openDam: (mode: DamMode, size: ImageSize, propertyName: string, propertyValue: any) => void;
  closeDam: () => void;
  toggleEditor: (show: boolean) => void;
}

export interface CmsDamFolder {
  parent?: string;
  empty?: boolean;
  processed?: boolean;
  busy?: boolean;
  reference: string;
  name: string;
  type: DamMode;
  url?: string;
}
