/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { store, view } from '@risingstack/react-easy-state';
import { formatDateTime } from 'framework/utils/datetimes';
import { Button, Form, TextField, Select, Summarizer, Text, Kebab, Link } from 'framework/components/ui';
import { FormErrors } from 'redux-form';
import classnames from 'classnames';
import { CheckboxPrimitive as CheckBox } from 'framework/components/ui/FormComponents/Primitives/CheckboxPrimitive';
import { SwitchPrimitive as Switch } from 'framework/components/ui/FormComponents/Primitives/SwitchPrimitive';
import { RouteLink } from 'framework/components/navigation';
import { AppRouteBase } from 'framework/components/navigation/Navigation.interfaces';
import Feedback, { IFeedbackMessage } from 'framework/components/hoc/formField/Feedback';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { TrackEventProps } from 'framework/applicationMonitoring/interface';
import { useLocation } from 'react-router-dom';
import { useForm } from '../FormComponents/Form/useForm';
import TableCells from './Cells/TableCells';
import { prefixClassName } from '../_conf';
import DataGridStatus from '../DataGrid/DataGridComponents/DataGridStatusIndicator';
import { SummarizerProps } from '../Summarizer/Summarizer.interfaces';
import { ColumnTextInlineEditTypes } from './Table.interfaces';
import {
  tableClassName,
  tableDataCell,
  tableActionCell,
  draggableCell,
  draggableCellRect,
  draggableCellDisabled,
} from './constants';
import { IconNames } from '../Icon';
import { Loader } from '../Loader';
import { ISelectPrimitiveOptionsProps } from '../FormComponents/Primitives/SelectPrimitive/SelectPrimitive.interface';
import RadioItem from '../FormComponents/Primitives/RadioGroupPrimitive/RadioGroup.Item';

interface Base {
  children?: React.ReactNode;
  className?: string;
}

interface ColumnBase extends Base {
  align?: 'center' | 'right';
  valign?: 'top' | 'middle' | 'bottom';
  colSpan?: number;
  width?: string;
  dataCell?: boolean;
  isLoading?: boolean;
}

interface ColumnBodyBase extends ColumnBase {
  subcaption?: string | string[];
  i18nSubcaption?: string;
  onClick?: () => void;
  index?: number;
}

const makeArray = <T extends {}>(obj: T): T[] => {
  if (Array.isArray(obj)) {
    return obj;
  }
  return [obj];
};

const textClass = prefixClassName('grid-caption-with-subcaption');
const imgClass = prefixClassName('grid-column-img');
const linkClass = `${prefixClassName('link')} ${tableClassName}__link`;
const xOutOfYClx = prefixClassName('x-out-of-y');
const deleteColumnClx = prefixClassName('button-delete-cell');
const deleteColumnButtonClx = prefixClassName('button-delete');
const rowClx = prefixClassName('Table__data-cell');
const radioClx = prefixClassName('radio');
const columnStatusSubLabel = prefixClassName('status-indicator-subLabel');

export const Head = ({ children, ...th }: ColumnBase) => <th {...th}>{children}</th>;

export const Column = ({
  children,
  subcaption,
  i18nSubcaption,
  dataCell = true,
  onClick,
  index,
  isLoading,
  ...td
}: ColumnBodyBase) => (
  <td
    {...td}
    {...(onClick && {
      onClick,
      role: 'button',
      tabIndex: index,
      'aria-label': 'text',
    })}
    className={dataCell ? classnames(tableDataCell, td.className) : classnames(td.className)}
  >
    {isLoading && <Loader size="sm" className={`${tableDataCell}__loader`} />}
    {!isLoading && (
      <>
        {children}
        {subcaption &&
          makeArray(subcaption).map((s, i) => (
            <span className={`${textClass}__sub`} key={i}>
              {s}
            </span>
          ))
      }
        {i18nSubcaption && <Text tag="span" className={`${textClass}__sub`} caption={i18nSubcaption} />}
      </>
    )}
  </td>
);
interface ColumnImgProps extends ColumnBodyBase {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
}
export const ColumnImage = ({ className = '', src, alt, width, height }: ColumnImgProps) => (
  <Column className={`${imgClass} ${className}`}>
    <TableCells.TableImage src={src} alt={alt} width={width} height={height} />
  </Column>
);
interface ColumnTextProps extends ColumnBodyBase {
  caption: string;
}
interface ColumnTextEditableProps extends ColumnTextProps {
  name: string;
  validator?: (val) => IFeedbackMessage;
  onChange: (e) => void;
}
export const ColumnText = ({ caption, ...rest }: ColumnTextProps) => (
  <Column {...rest}>
    <Text caption={caption} />
  </Column>
);

interface ColumnContrastTextProps extends ColumnBodyBase {
  caption: string;
  subCaption: string;
  boldCaption?: boolean;
  lineBreak?: boolean;
}
export const ColumnContrastText = (
  { caption, subCaption, boldCaption = false, lineBreak = false, ...rest }: ColumnContrastTextProps
) => (
  <Column {...rest}>
    <Text
      caption={caption}
      tag="td"
      style={{ fontWeight: boldCaption ? 'bold' : 'normal', ...lineBreak && ({ display: 'block' }) }}
    />
    {subCaption && <Text caption={subCaption} tag="td" style={{ color: 'grey' }} />}
  </Column>
);

export const ColumnTextEditable = ({
  caption,
  name,
  onChange,
  validator = () => null,
  ...rest
}: ColumnTextEditableProps) => {
  const { setValue } = useForm(name);
  const error = validator(caption);

  React.useEffect(() => {
    setValue(name, caption);
  }, [caption]);

  return (
    <Column {...rest}>
      <Form name={name} initialValues={{ [name]: caption }}>
        <TextField className="mwg-editable-text-field" value={caption} name={name} onChange={onChange} />
        <Feedback hasError={Boolean(error)} messageError={error} />
      </Form>
    </Column>
  );
};

interface ColumnTextInlineSaveProps extends ColumnTextProps {
  name: string;
  row: any;
  editingId: string;
  rowId: string;
  validator?: (values) => FormErrors<any, string>;
  onSaveValidator?: (values) => Promise<FormErrors<any, string>>;
  onSave?: (e: any) => void;
  onCancel?: () => void;
  onChange?: (row: any) => void;
  showButtons?: boolean;
  formClassName?: string;
  className?: string;
  dataInputType?: ColumnTextInlineEditTypes;
  selectOptions?: ISelectPrimitiveOptionsProps[];
  customDataDisplay?: JSX.Element;
  mandatory?: boolean;
  readOnly?: boolean;
  // useful to avoid having text input remounting the component on every change
  disableOnChange?: boolean;
  size?: 'small' | 'large';
}

const localEditingRow = store<{ row: any; errorFields: string[]; cleanRow: any; editingId: string }>({
  row: {},
  errorFields: [],
  cleanRow: {},
  editingId: '',
});

export const ColumnTextInlineEdit = view(
  ({
    caption,
    row,
    editingId,
    rowId,
    name,
    onSave,
    onCancel,
    onChange,
    formClassName,
    className,
    customDataDisplay,
    dataInputType = ColumnTextInlineEditTypes.TextInput,
    selectOptions,
    disableOnChange = false,
    size = 'small',
    validator = () => null,
    onSaveValidator,
    showButtons = false,
    mandatory = false,
    readOnly,
    ...rest
  }: ColumnTextInlineSaveProps) => {
    const { setErrors, getValues } = useForm(`save_text_inline-${name}-form`);

    const editingThisRow: boolean = rowId === editingId;
    const rowHasError: boolean = localEditingRow.errorFields.length > 0;

    const handleOnChange = (e) => {
      localEditingRow.row = { ...localEditingRow.row, [name]: e?.target?.value ?? e?.value };

      if (onChange && !disableOnChange) {
        onChange(localEditingRow.row);
      }
    };

    const handleOnSave = () => {
      if (onSaveValidator) {
        let errorFound: boolean = false;

        onSaveValidator(getValues()).then((result) => {
          errorFound = result && Object.keys(result).length > 0;

          if (errorFound) {
            setErrors(result);
            localEditingRow.errorFields = localEditingRow.errorFields.concat([name]);
          } else {
            onSave(localEditingRow.row);
            localEditingRow.row = {};
            localEditingRow.errorFields = [];
            setErrors(null);
          }
        });
      }
    };

    const handleOnCancel = () => {
      localEditingRow.row = {};
      localEditingRow.errorFields = [];
      onCancel();
    };

    React.useEffect(() => {
      if (localEditingRow.editingId !== editingId) {
        localEditingRow.row = { ...row };
        localEditingRow.cleanRow = { ...row };
        localEditingRow.editingId = editingId;
      }
    }, [editingId]);

    return (
      <Column
        {...rest}
        className={classnames([
          className,
          [`${rowClx}__${size}-width`],
          ...(rowHasError && editingThisRow ? [`${rowClx}--has-error`] : []),
        ])}
      >
        {editingThisRow ? (
          <Form
            name={`save_text_inline-${name}-form`}
            className={formClassName}
            validate={(values) => {
              const validation: FormErrors<any, string> = validator(values);

              const mandatoryValidation: boolean = mandatory && (values[name] === '' || !values[name]);

              if (validation !== null || mandatoryValidation) {
                localEditingRow.errorFields = localEditingRow.errorFields.concat([name]);
                if (Object.keys(values).length && mandatoryValidation) {
                  setErrors({ [name]: 'generic.formsValidation.mandatory' });
                }
              } else {
                localEditingRow.errorFields = localEditingRow.errorFields.filter((field) => field !== name);
              }

              return validation;
            }}
            initialValues={{ [name]: row[name] }}
            destroyOnUnmount={false}
          >
            {dataInputType === ColumnTextInlineEditTypes.SelectInput && selectOptions && (
              <Select
                name={name}
                readOnly={readOnly}
                options={selectOptions}
                onChange={handleOnChange}
                mandatory={mandatory}
                clearable={false}
              />
            )}

            {dataInputType === ColumnTextInlineEditTypes.TextInput && (
              <TextField name={name} readOnly={readOnly} onChange={handleOnChange} mandatory={mandatory} />
            )}

            {showButtons && (onSave || onCancel) && (
              <>
                {onSave && (
                  <Button
                    caption="generic.button.save"
                    onClick={handleOnSave}
                    testId="edit-comment-save"
                    form={`save_text_inline-${name}-form`}
                    disabled={
                      rowHasError || JSON.stringify(localEditingRow.cleanRow) === JSON.stringify(localEditingRow.row)
                    }
                    link
                  />
                )}
                {onCancel && (
                  <Button caption="generic.button.cancel" onClick={handleOnCancel} testId="edit-comment-cancel" link />
                )}
              </>
            )}
          </Form>
        ) : (
          customDataDisplay || <Text caption={caption} />
        )}
      </Column>
    );
  }
);

interface ColumnDateProps extends ColumnBodyBase {
  value: string | Date;
  ignoreLocalTimeZone?: boolean;
  removeDate?: boolean;
  format?: string;
}
export const ColumnDate = ({ value, format, ignoreLocalTimeZone, removeDate, ...rest }: ColumnDateProps) => {
  if (!value) return <Column {...rest} />;

  return <Column {...rest}>{formatDateTime(value, format, null, null, ignoreLocalTimeZone, removeDate)}</Column>;
};

export const ColumnSummarizer = ({
  items,
  caption,
  render,
  onViewAll,
  alwaysWrap,
  maxItems,
  tooltipClassName,
  ...rest
}: ColumnBodyBase & SummarizerProps<any>) => (
  <Column {...rest}>
    <Summarizer
      caption={caption}
      items={items}
      render={render}
      onViewAll={onViewAll}
      alwaysWrap={alwaysWrap}
      maxItems={maxItems}
      tooltipClassName={tooltipClassName}
    />
  </Column>
);
interface ColumnLinkProps extends ColumnTextProps {
  to: string;
  external?: boolean;
  showCopyIcon?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const ColumnLink = ({ caption, to, onClick, external, showCopyIcon = false, ...rest }: ColumnLinkProps) => {
  return (
    <Column {...rest} className={`${prefixClassName('link')}`}>
      <Link
        className={`${linkClass} ${tableClassName}__column-link`}
        caption={caption}
        to={to}
        showCopyIcon={showCopyIcon}
        onClick={onClick}
        external={external}
      />
    </Column>
  );
};

interface ColumnRouteLinkProps extends ColumnTextProps {
  caption: string;
  appRoute: AppRouteBase;
  onClick?: () => void;
  params?: {
    [key: string]: string;
  };
  trackEventProps?: TrackEventProps;
  activeClassName?: string;
}

export const ColumnRouteLink = ({
  caption,
  appRoute,
  params,
  trackEventProps,
  onClick,
  activeClassName,
  ...rest
}: ColumnRouteLinkProps) => {
  const { pathname } = useLocation();
  return (
    <Column {...rest}>
      <RouteLink
        caption={caption}
        appRoute={appRoute}
        params={params}
        className={linkClass}
        onClick={() => {
          ApplicationMonitoring.trackEvent(
            trackEventProps?.name || pathname,
            trackEventProps?.value || 'Column Route Link',
            trackEventProps?.label || caption
          );
          if (onClick) {
            onClick();
          }
        }}
        activeClassName={activeClassName}
      />
    </Column>
  );
};

interface ColumnStatusProps extends ColumnBase {
  value?: string;
  subLabel?: {
    text: string;
    type: string;
  };
}

export const ColumnStatus = ({ value, subLabel, ...props }: ColumnStatusProps) => (
  <Column {...props}>
    <DataGridStatus rowData={{}} cellProperties={{}} value={value} />
    {subLabel && (
      <div className={`${columnStatusSubLabel} ${columnStatusSubLabel}--${subLabel.type}`}>
        <Text caption={subLabel.text} />
      </div>
    )}
  </Column>
);
type ColumnKebabProps = Pick<React.ComponentProps<typeof Kebab>, 'options' | 'keepDropdown' | 'onToggle' | 'disabled'> &
ColumnBodyBase;
export const ColumnKebab = ({ options, keepDropdown, onToggle, ...rest }: ColumnKebabProps) => (
  <Column {...rest}>
    <Kebab options={options} keepDropdown={keepDropdown} onToggle={onToggle} />
  </Column>
);

interface ColumnEnumProps extends ColumnBodyBase {
  /**
   * The prefix in the translations file for the current enum value.
   * Example: for
   * "orderManagement": {
        "historyChangeType": {
          "status": "Status",
          "paymentStatus": "Payment status",
          "fulfilmentDetailsEdited": "Fulfilment details edited",
          "orderItemsEdited": "Order items edited"
        }
      }
   * the prefix will be 'modules.orderManagement.historyChangeType.'
   */
  i18nPrefix: string;
  /**
   * The enum value to be translated
   */
  value: string;
}
export const ColumnEnum = ({ i18nPrefix, value, ...rest }: ColumnEnumProps) => (
  <ColumnText caption={i18nPrefix + value} {...rest} />
);

interface ColumnCheckboxProps {
  checked: boolean;
  className?: string;
  name?: string;
  disabled?: boolean;
  onChange: (e?: any) => void;
  type?: 'checkbox' | 'switch' | 'radio';
}
export const ColumnCheckbox = ({
  className,
  checked,
  onChange,
  name,
  disabled = false,
  type = 'checkbox',
}: ColumnCheckboxProps) => {
  let typeElem: any;
  switch (type) {
    case 'switch':
      typeElem = <Switch value={checked} onChange={onChange} name={name} disabled={disabled} />;
      break;
    case 'radio':
      typeElem = (
        <RadioItem
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={disabled}
          tag="span"
          className={radioClx}
        />
      );
      break;
    case 'checkbox':
    default:
      typeElem = <CheckBox value={checked} onChange={onChange} name={name} disabled={disabled} />;
  }

  return (
    <Column className={className} width="50px">
      {typeElem}
    </Column>
  );
};

interface ColumnXOutOfYProps extends ColumnBodyBase {
  x: number | string;
  y: number | string;
}

/**
 * Displays X/y items with formatted css
 */
export const ColumnXOutOfY = ({ x, y, ...rest }: ColumnXOutOfYProps) => (
  <Column {...rest}>
    <span>{x}</span>
    <span className={xOutOfYClx}>{`/${y}`}</span>
  </Column>
);

type ColumnDeleteProps = ColumnBodyBase & {
  onDelete: () => void;
  disabled?: boolean;
};
export const ColumnDelete = ({ onDelete, className = '', disabled, ...rest }: ColumnDeleteProps) => (
  <Column dataCell={false} className={`${deleteColumnClx} ${tableActionCell} ${className}`} {...rest}>
    <Button
      onClick={onDelete}
      icon="trash"
      className={deleteColumnButtonClx}
      styleless
      iconSize="sm"
      title="generic.options.delete"
      disabled={disabled}
    />
  </Column>
);

type ColumnIconProps = ColumnBodyBase & {
  icon: IconNames;
  iconColor?: string;
  title?: string;
  onClick: () => void;
  useTableDefaultStyle?: boolean;
};
export const ColumnIcon = ({
  icon,
  iconColor,
  title,
  onClick,
  useTableDefaultStyle = false,
  className = '',
  ...rest
}: ColumnIconProps) => (
  <Column
    dataCell={false}
    className={!useTableDefaultStyle ? `${deleteColumnClx} ${tableActionCell} ${className}` : `${className}`}
    {...rest}
  >
    <Button
      onClick={onClick}
      icon={icon}
      iconColor={iconColor}
      className={deleteColumnButtonClx}
      styleless
      iconSize="sm"
      title={title}
    />
  </Column>
);

interface DraggableCellProps {
  disabled?: boolean;
}
export const DraggableCell = ({ disabled }: DraggableCellProps) => (
  <Column width="60px">
    <span className={`${draggableCell} ${disabled ? draggableCellDisabled : ''}`}>
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
      <span className={draggableCellRect} />
    </span>
  </Column>
);
