import { useState } from 'react';
import { useModal } from 'framework/components/ui/Modal';
import { DamMode, DAM_MODAL } from './Types';

export const useDamModal = () => {
  const [mode, setMode] = useState<DamMode>();
  const { closeModal } = useModal(DAM_MODAL);

  const openDam = (m: DamMode) => setMode(m);
  const closeDam = () => {
    setMode(null);
    closeModal();
  };

  return {
    mode,
    openDam,
    openDamAdd: () => openDam(DamMode.directory),
    openDamUpload: () => openDam(DamMode.file),
    closeDam,
  };
};
