export enum CRMTranslations {
  CRM = 'modules.crm.crm',
  Customers = 'modules.crm.customers',
  Customer = 'modules.crm.customer',
  AccountContactNumber = 'modules.crm.accountContactNumber',
  ThreeDS = 'modules.crm.threeDS',
  UsedCredits = 'modules.crm.usedCredits',
  CreditBalance = 'modules.crm.creditBalance.title',
  StoreForEmail = 'modules.crm.creditBalance.storeForEmail',
  StoreForEmailHint1 = 'modules.crm.creditBalance.storeForEmailHint1',
  StoreForEmailHint2 = 'modules.crm.creditBalance.storeForEmailHint2',
  Actions = 'generic.actions',
  Comments = 'modules.crm.commentsTab.comments',
  Comment = 'modules.crm.commentsTab.comment',
  CommentType = 'modules.crm.commentsTab.commentType',
  Reason = 'modules.crm.commentsTab.reason',
  Post = 'modules.crm.commentsTab.post',
  CommentsMessage = 'modules.crm.commentsTab.commentsMessage',
  ExactOrderRefRequired = 'modules.crm.commentsTab.exactOrderRefRequired',
  CustomerOrderRefRequired = 'modules.crm.commentsTab.customerOrderRefRequired',
  EditComment = 'modules.crm.commentsTab.editComment',
  DeleteComment = 'modules.crm.commentsTab.deleteComment',
  Edited = 'modules.crm.commentsTab.edited',
  DeleteCommentAreYouSure = 'modules.crm.commentsTab.deleteCommentAreYouSure',
  DeleteCommentWarning = 'modules.crm.commentsTab.deleteCommentWarning',
  DeleteCommentConfirm = 'modules.crm.commentsTab.deleteCommentConfirm',
  DeleteCommentCancel = 'modules.crm.commentsTab.deleteCommentCancel',
  CommentSaveSuccess = 'modules.crm.commentsTab.commentSaveSuccess',
  CommentSaveError = 'modules.crm.commentsTab.commentSaveError',
  CommentDeleteSuccess = 'modules.crm.commentsTab.commentDeleteSuccess',
  CommentDeleteError = 'modules.crm.commentsTab.commentDeleteError',
  PostedBy = 'modules.crm.commentsTab.postedBy',
  ModifyCreditBalance = 'modules.crm.creditBalance.modify',
  CreditsHistory = 'modules.crm.creditsHistory',
  ModifyBalance = 'modules.crm.creditBalance.modifyBalanceButton',
  Reset = 'generic.options.reset',
  CurrentBalance = 'modules.crm.creditBalance.currentBalance',
  CurrentBalanceHint = 'modules.crm.creditBalance.currentBalanceHint',
  ModifyBalanceBy = 'modules.crm.creditBalance.modifyBalanceBy',
  ModifyBalanceByHint = 'modules.crm.creditBalance.modifyBalanceByHint',
  MaxDeductionAmount = 'modules.crm.creditBalance.maxDeductionAmount',
  NewBalance = 'modules.crm.creditBalance.newBalance',
  NewBalanceHint = 'modules.crm.creditBalance.newBalanceHint',
  Note = 'modules.crm.creditBalance.note',
  NoteHint = 'modules.crm.creditBalance.noteHint',
  RelatedOrderReference = 'modules.crm.creditBalance.relatedOrderReference',
  RelatedOrderReferenceHint = 'modules.crm.creditBalance.relatedOrderReferenceHint',
  CreditUpdateSuccess = 'modules.crm.creditBalance.creditUpdateSuccess',
  CreditUpdateError = 'modules.crm.creditBalance.creditUpdateError',
  AddedBy = 'modules.crm.addedBy',
  TransactionDate = 'modules.crm.transactionDate',
  OrderReference = 'modules.crm.orderReference',
  CreditAmount = 'modules.crm.creditAmount',
  TotalBalance = 'modules.crm.totalBalance',
  TransactionType = 'modules.crm.transactionType',
  allocated = 'modules.crm.allocated',
  redeemed = 'modules.crm.creditsRedeemed',
  Cancel = 'generic.options.cancel',
  FilterByKeyword = 'generic.filterByKeyword',
  AllCustomers = 'modules.crm.allCustomers',
  ShopAsCustomer = 'modules.crm.shopAsCustomer',
  resetPassword = 'modules.siteadmin.resetPassword',
  reset = 'generic.options.reset',
  newPassword = 'modules.siteadmin.newPassword',
  newPasswordExplanatory = 'modules.siteadmin.newPasswordExplanatory',
  site = 'modules.crm.site',
  loyaltyNo = 'modules.crm.loyaltyNo',
  loyaltyNoCount = 'modules.crm.loyaltyNoCount',
  language = 'modules.crm.language',
  languagesCount = 'modules.crm.languageCount',
  FetchSitesError = 'modules.crm.fetchSitesError',
  SiteUrlNotFound = 'modules.crm.SiteUrlNotFound',
  SelectABanner = 'modules.crm.selectABanner',
  CustomerInformation = 'modules.crm.customerInformation',
  CustomerDetails = 'modules.crm.customerInformationTab.customerDetails',
  PerBannerDetails = 'modules.crm.customerInformationTab.perBannerDetails',
  Continue = 'generic.button.continue',
  PasswordUpdateError = 'modules.crm.passwordUpdateError',
  OrderCount = 'modules.crm.orderCount',
  EmailValidated = 'modules.crm.emailValidated',
  ResendRegistrationEmail = 'modules.crm.resendRegistrationEmail',
  SendEmailSuccess = 'modules.crm.sendEmailSuccess',
  SendEmailError = 'modules.crm.sendEmailError',
  Orders = 'modules.crm.orders',
  FilterByStatus = 'generic.filterByStatus',
  FilterOptions = 'generic.filterOptions',
  ResendOrderConfirmationEmail = 'modules.crm.resendOrderConfirmationEmail',
  ResendOrderCancellationEmail = 'modules.crm.resendOrderCancellationEmail',
  ResendOrderModifiedEmail = 'modules.crm.resendOrderModifiedEmail',
  ResendOrderReadyEmail = 'modules.crm.resendOrderReadyEmail',
  ResendWelcomeEmail = 'modules.crm.resendWelcomeEmail',
  DeleteCustomer = 'modules.crm.deleteCustomer',
  DeleteThisCustomer = 'modules.crm.deleteThisCustomer',
  DeleteCustomerAreYouSure = 'modules.crm.deleteCustomerAreYouSure',
  DeleteCustomerSucess = 'modules.crm.deleteCustomerSucess',
  DeleteCustomerError = 'modules.crm.deleteCustomerError',
  DoNotDeleteCustomer = 'modules.crm.doNotDeleteCustomer',
  DeleteCustomerWarning = 'modules.crm.deleteCustomerWarning',
  CustomerName = 'modules.crm.customerName',
  AccountCreated = 'modules.crm.accountCreated',
  Email = 'modules.crm.email',
  BusinessAccounts = 'modules.crm.businessAccounts.businessAccounts',
  BusinessAccountsEmpty = 'modules.crm.businessAccounts.businessAccountsEmpty',
  BusinessAccountNumber = 'modules.crm.businessAccounts.businessAccountNumber',
  BusinessAccountNumberExplainer = 'modules.crm.businessAccounts.businessAccountNumberExplainer',
  Segments = 'modules.crm.segments',
  Subscriptions = 'modules.crm.subscriptions.subscriptions',
  SubscriptionName = 'modules.crm.subscriptions.subscriptionName',
  SubscriptionMessage = 'modules.crm.subscriptions.subscriptionMessage',
  OrderPurchasedIn = 'modules.crm.subscriptions.orderPurchasedIn',
  ExpiryDate = 'modules.crm.subscriptions.expiryDate',
  AutomaticallyRenew = 'modules.crm.subscriptions.automaticallyRenew',
  cancelSubscription = 'modules.crm.subscriptions.cancelSubscription',
  donotCancelSubscription = 'modules.crm.subscriptions.dontCancelSubscription',
  cancelSubscriptionError = 'modules.crm.subscriptions.cancelSubscriptionError',
  cancelSubscriptionSuccess = 'modules.crm.subscriptions.cancelSubscriptionSuccess',
  cancelSubscriptionMessage = 'modules.crm.subscriptions.cancelSubscriptionMessage',
  subscriptionEmpty = 'modules.crm.subscriptions.subscriptionsEmpty',
  Store = 'generic.dataGrid.colStore',
  Status = 'generic.dataGrid.colStatus',
  AddBusinessAccount = 'modules.crm.businessAccounts.addBusinessAccount',
  EditBusinessAccount = 'modules.crm.businessAccounts.editBusinessAccount',
  UpdateBusinessAccount = 'modules.crm.businessAccounts.updateBusinessAccount',
  SelectStore = 'modules.crm.businessAccounts.selectStore',
  ChangeStatusToActive = 'modules.crm.businessAccounts.changeStatusToActive',
  ChangeStatusToInactive = 'modules.crm.businessAccounts.changeStatusToInactive',
  ChangeToThreeDSDisabled = 'modules.crm.customerInformationTab.actions.changeToThreeDSDisabled',
  ChangeToThreeDSEnabled = 'modules.crm.customerInformationTab.actions.changeToThreeDSEnabled',
  ChangeToThreeDSConfirmationEnabled = 'modules.crm.customerInformationTab.actions.changeToThreeDSConfirmationEnabled',
  ChangeToThreeDSConfirmationDisabled = 'modules.crm.customerInformationTab.actions.changeToThreeDSConfirmationDisabled',
  ChangeToThreeDSEnabledWarning =  'modules.crm.customerInformationTab.actions.changeToThreeDSEnabledWarning',
  ChangeToThreeDSDisabledWarning =  'modules.crm.customerInformationTab.actions.changeToThreeDSDisabledWarning',
  ChangeToThreeDSDisabledModalButton = 'modules.crm.customerInformationTab.actions.changeToThreeDSDisabledModalButton',
  ChangeToThreeDSEnabledModalButton = 'modules.crm.customerInformationTab.actions.changeToThreeDSEnabledModalButton',
  ChangeToThreeDSEnabledSuccess = 'modules.crm.customerInformationTab.actions.threeDSChangeEnabledSuccess',
  ChangeToThreeDSDisabledSuccess = 'modules.crm.customerInformationTab.actions.threeDSChangeDisabledSuccess',
  ChangeToThreeDSError = 'modules.crm.customerInformationTab.actions.threeDSChangeError',
  EnableThreeDS = 'modules.crm.customerInformationTab.actions.enableThreeDS',
  DontEnableThreeDS = 'modules.crm.customerInformationTab.actions.dontEnableThreeDS',
  DisableThreeDS = 'modules.crm.customerInformationTab.actions.disableThreeDS',
  DontDisableThreeDS = 'modules.crm.customerInformationTab.actions.dontDisableThreeDS',
  DeleteBusinessAccount = 'modules.crm.businessAccounts.deleteBusinessAccount',
  DeleteBusinessAccountMessage = 'modules.crm.businessAccounts.deleteBusinessAccountMessage',
  DeleteBusinessAccountSuccess = 'modules.crm.businessAccounts.deleteBusinessAccountSuccess',
  DeleteBusinessAccountError = 'modules.crm.businessAccounts.deleteBusinessAccountError',
  CreateBusinessAccountSuccess = 'modules.crm.businessAccounts.createBusinessAccountSuccess',
  CreateBusinessAccountError = 'modules.crm.businessAccounts.createBusinessAccountError',
  UpdateBusinessAccountSuccess = 'modules.crm.businessAccounts.updateBusinessAccountSuccess',
  UpdateBusinessAccountError = 'modules.crm.businessAccounts.updateBusinessAccountError',
  UpdateBusinessAccountStatusSuccess = 'modules.crm.businessAccounts.updateBusinessAccountStatusSuccess',
  UpdateBusinessAccountStatusError = 'modules.crm.businessAccounts.updateBusinessAccountStatusError',
  BusinessAccountsInfo1 = 'modules.crm.businessAccounts.businessAccountsInfo1',
  BusinessAccountsInfo2 = 'modules.crm.businessAccounts.businessAccountsInfo2',
  BusinessAccountsUniqueError = 'modules.crm.businessAccounts.businessAccountsUniqueError',
  BusinessAccountsStoresCount = 'modules.crm.businessAccounts.storesCount',
  BusinessAccountName = 'modules.crm.businessAccounts.businessName',
  BusinessAccountNameExplainer = 'modules.crm.businessAccounts.businessNameExplainer',
}
