import { call, put } from 'redux-saga/effects';
import { config } from 'config';
import { makeRequest } from 'framework/api/make-request';
import { toastListActions } from 'framework/actions';
import { takeEveryWithConfirmation } from 'framework/sagas/extensions/confirmation';
import { withProgressBar } from 'framework/sagas/extensions/progress';
import {
  ACTION_LISTING_DELETE,
} from 'framework/modules/merchandising/constants';
import { ListingTranslations } from 'framework/modules/merchandising/translations';
import { goToRoute } from 'framework/components/navigation/service';
import { listingListRoute } from 'framework/modules/merchandising/routes';


function* process({ payload }) {
  const { id } = payload;

  // 1. Make Delete Request
  const { success } = yield call(makeRequest, {
    name: 'ListingDelete',
    url: config.apiEndpoints.listings,
    method: 'DELETE',
    body: [id],
  });

  if (success) {
    // 2.1 GoTo LISTINGs Grid
    goToRoute(listingListRoute);

    yield put(toastListActions.addMessage(ListingTranslations.DeleteSuccessfully));
  } else {
    // 2.2 handle error situation
    yield put(toastListActions.addMessageError(ListingTranslations.DeleteError));
  }
}

export default function watchDeleteListing() {
  return takeEveryWithConfirmation(ACTION_LISTING_DELETE, withProgressBar(process));
}
