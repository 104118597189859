import * as classnames from 'classnames';
import * as React from 'react';
import i18n from 'i18n';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Icon } from 'ui';
import { IPill } from './Pill.interfaces';
import './_style.scss';

const clx = prefixClassName('pill');

export class Pill extends React.PureComponent<IPill> {
  static defaultProps: Partial<IPill> = {
    readOnly: false,
    disabled: false,
    toggle: false,
    onClose: () => null,
  };

  onCloseHandler = () => {
    if (this.isClosable && this.props.onClose) {
      this.props.onClose();
    }
  };

  isClosable = () => !this.props.disabled && !this.props.readOnly && this.props.onClose;

  render() {
    const {
      caption, className, readOnly, disabled, toggle, onToggle, onClose,
    } = this.props;

    return caption ? (
      <div
        className={classnames(
          clx,
          readOnly && `${clx}--readOnly`,
          disabled && `${clx}--disabled`,
          toggle && `${clx}--toggle`,
          !onClose && `${clx}--noClose`,
          className,
        )}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span
          role="switch"
          aria-checked={!disabled}
          tabIndex={0}
          title={toggle ? i18n.t('components.pill.toggle') : null}
          onClick={toggle && onToggle ? onToggle : null}
        >
          {caption}
        </span>
        {this.isClosable() && (
          <Icon
            name="close"
            title="components.pill.close"
            className={`${clx}__icon`}
            onClick={this.onCloseHandler}
          />
        )}
      </div>
    ) : null;
  }
}

export default Pill;
