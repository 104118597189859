import * as React from 'react';
import {
  ApiRequestProps,
  ApiRequestContextProps,
  ApiRequestStatus
} from './useApiRequest.interfaces';
import { ApiRequestContext } from './useApiRequest.Provider';

export const useApiRemoteRequest
 = (props: ApiRequestProps) => {
   // get context provider data
   const { makeApiCall, apiClientData } = React.useContext<ApiRequestContextProps>(
     ApiRequestContext as any
   );
   const clientData = apiClientData[props.name] || {};

   // capture call features
   const [fetchingState, setFetchingState] = React.useState<ApiRequestStatus>(ApiRequestStatus.idle);
   const [isBusy, setIsBusy] = React.useState(!!clientData.isFetching);
   const [hasDataError, setHasError] = React.useState(clientData.hasError);
   const [response, setResponse] = React.useState(undefined);

   const immediateRequest = props.immediateRequest === undefined || props.immediateRequest;

   // on ComponentDidMount, make the call
   React.useEffect(() => {
     if (immediateRequest) {
       makeApiCall({ ...props });
     }
   }, []);

   // on ComponentDidUpdate: set isFetching, hasError
   React.useEffect(() => {
     const { isFetching, hasError } = clientData;
     setIsBusy(isFetching);
     setHasError(hasError);
   }, [clientData]);

   // on ComponentDidUpdate: if call has ended then update response
   React.useEffect(() => {
     if (isBusy && fetchingState === ApiRequestStatus.idle) {
       // call has started
       setFetchingState(ApiRequestStatus.busy);
     }

     if (!isBusy && fetchingState === ApiRequestStatus.busy) {
       // if call has ended, then update response
       const { data, ...rest } = clientData;
       setFetchingState(ApiRequestStatus.idle);
       setResponse({
         data,
         ...rest
       });
     }
   }, [isBusy]);

   return {
     makeApiCall,
     isBusy,
     hasError: hasDataError,
     response
   };
 };
