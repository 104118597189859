import * as React from 'react';
import i18n from 'i18n';
import {
  DynamicFields,
  Info,
  LayoutFlexBoxItem,
  Text,
} from 'ui';
import ActionsComponent from './ActionsComponent';
import '../_styles.scss';

export default (props) => (
  <LayoutFlexBoxItem flexGrow={1}>
      <Text caption="modules.merchandising.rules.create.actions" tag="strong" />
      <Info
        tooltipBody={i18n.t('modules.merchandising.rules.create.actionsTooltip')}
        toolTipPosition="up-right"
      />
    <LayoutFlexBoxItem flexGrow={1}>
      <DynamicFields name="actions" component={ActionsComponent} {...props} />
    </LayoutFlexBoxItem>
  </LayoutFlexBoxItem>
);
