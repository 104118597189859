import * as React from 'react';
import { Panel, Header, SwitchPrimitive as Switch, Text } from 'framework/components/ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { translations } from './translations';

const componentClass = prefixClassName('dam');

/**
 ** This component allows user to select the file to upload by clicking
 * on input['file'] element
 *
 * @param onUploadFile: callback which returns to parent the uploaded file
 * @param overWriteFile: boolean indicates whether upcoming file should overwrite existing file in server
 * @param toggleOverWriteFile: toggle flag "overWriteFile" true/false
 */
interface Props {
  overWriteFile: boolean;
  toggleOverWriteFile: () => void;
  onFileSelected: (file: any) => void;
}

export const DamUploadFile = (props: Props) => {

  const { onFileSelected, overWriteFile, toggleOverWriteFile } = props;

  const cssClass = `${componentClass}__browse`;

  const handelOnChange = (e: any) => {
    onFileSelected(e.target.files[0]);
  };

  return (
    <>
      <Header
        className={`${cssClass}__title`}
        caption={translations.fileToUpload}
        headerLevel={4}
      />
      <div className={`${componentClass}__browse-container`} >
        <Panel className={`${componentClass}__browse`}>
          <input type="file" onChange={handelOnChange} />
        </Panel>
        <div className={`${componentClass}__file-overwrite`} onClick={toggleOverWriteFile}>
          <Text
            tag="span"
            className={`${componentClass}__file-overwrite__label`}
            caption={translations.fileOverWrite}
          />
          <Switch value={overWriteFile} onChange={toggleOverWriteFile} />
        </div>
      </div>
    </>
  );

};