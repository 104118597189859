import { useState } from 'react';
import { fetchData, IFetchDataRequest } from 'framework/api/fetch-data';
import { useEffectSkipFirstRun } from 'helpers/hooks';
import { SkipTake } from './usePagination';

interface TableData<T> {
  total: number;
  items: T[];
}

export type onDataFetchedType = <T extends {}>(data: T, skipTake?: SkipTake) => TableData<T> | Promise<TableData<T>>;

const getSkipTake = ({ query: { skip, take } }: IFetchDataRequest): SkipTake => ({ skip, take });

export const useApiTableData = <T extends {}>(
  requestDetails: IFetchDataRequest,
  updatePaginationTotal,
  onDataFetched?: onDataFetchedType,
) => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);

  /**
   * Loads the data
   */
  useEffectSkipFirstRun(() => {
    let isAborted = false;
    setIsFetching(true);
    fetchData<TableData<T>>(requestDetails)
      .then(async ({ data: fetchedData }) => {
        const formattedData = onDataFetched
          ? await onDataFetched(fetchedData, getSkipTake(requestDetails))
          : fetchedData;
        if (!isAborted) {
          updatePaginationTotal(formattedData.total || 0);
          setData(formattedData.items || []);
          // setIsFetching(false) can't be added to .finally() block because onDataFetched can be an async operation
          setIsFetching(false);
        }
      })
      .catch(() => {
        updatePaginationTotal(0);
        setData([]);
        setIsFetching(false);
      });
    return () => {
      isAborted = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDetails]);

  return {
    data,
    isFetching,
  };
};
