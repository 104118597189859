import { baseApiUrl, getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { AuthorizationClaims, ensureClaims } from 'framework/authorization';
import { FeatureState } from './types';

export const isBrandbankEnabled = async (): Promise<FeatureState> => {

  const userHasAccess = ensureClaims(AuthorizationClaims.PRODUCT_REQUEST_PRODUCT_RESEND);

  if (!userHasAccess) {
    return {
      isEnabled: false,
      data: {}
    };
  }

  if (!baseApiUrl) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData({
    url: getApiUrl('/brandbank/configurationsettings'),
  });

  return {
    isEnabled: result.success && Object.keys(result.data).length > 0 || false,
    data: result.data
  };
};
