import { call, put } from 'redux-saga/effects';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { apiClientActions } from 'framework/actions';
import { actions } from '../index';
import { config } from 'config';
import { makeRequest } from 'framework/api/make-request';

import * as CONST from 'framework/modules/searchadmin/constants';

export default function* watchMerchandisingRelevanceSaveChanges() {
  yield takeEveryWithProgressBar(
    CONST.ACTION_RELEVANCE_ATTRIBUTES_SAVE_CHANGES,
    merchandisingRelevanceSaveChanges
  );
}

function* merchandisingRelevanceSaveChanges({ payload }) {
  // TODO: get payload via select, it will make code cleaner
  const { attributes, locales, storeId } = payload;

  // 1. Save Request
  yield call(makeRequest, {
    name: 'relevanceChangesPut',
    method: 'PUT',
    body: attributes,
    url: `${config.apiEndpoints.searchRelevance}/locale/${locales}/store/${storeId}`
  });

  // 2. Get Up-to-date data for a Attributes and load Current DataGrid
  yield put(
    actions.getRelevancesAtts({
      storeId,
      locales
    })
  );

  // 3. Hidde Preview DataGrid
  yield put(
    apiClientActions.clearApiClientStateAction({
      name: CONST.API_DATA_PROVIDER_RELEVANCE_PREVIEW
    })
  );
}
