export const rolesAction = 'roles_action';
export const userAction = 'user_action';
export const create = 'create';
export const edit = 'edit';
export const assignRoles = 'assign_roles';
export const assignStores = 'assign_stores';

// ROUTES PATHS
export const PATH_MAIN = '/siteadmin';

/* Relative paths, to use in menu */
export const PATH_USER = '/users';
export const PATH_USER_EDIT = `${PATH_USER}/edit`; // path needs /:userId

/* Absolute paths, to use in buttons and redirections */
export const PATH_USER_EDIT_FULLPATH = `${PATH_MAIN}${PATH_USER_EDIT}`;
