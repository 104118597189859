import { ProductDetails } from '../../../models/product';

export const SAVE_PRODUCT_ACTION = 'SAVE_PRODUCT_ACTION';

export interface SaveProductActionReturn {
  type: string;
  previousProduct: ProductDetails;
  eTag?: string;
}

export const saveProductAction = (previousProduct: ProductDetails, eTag?: string) =>
  ({
    type: SAVE_PRODUCT_ACTION,
    previousProduct,
    eTag
  } as SaveProductActionReturn);
