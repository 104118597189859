import { select, call, put } from 'redux-saga/effects';
import { toastListActions } from 'framework/actions';
import { makeRequest } from 'framework/api/make-request';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { SAVE_CMS_CONTENT } from '../contentemanagement.constants';
import { ContentManagementState } from '../contentmanagement.interfaces';
import { getLoadedPage } from './_SagaUtils';
import { config } from '../../../../config';

function* processSaveCmsContent() {
  const { pageId, variantId }: any = yield select((state: ContentManagementState) => (
    {
      pageId: state.contentmanagement.pageId,
      variantId: state.contentmanagement.variantId,
    }
  ));
  const loadedPage = yield select(getLoadedPage);
  const response = yield call(makeRequest, {
    body: {
      cmsContent: loadedPage.cmsContent,
    },
    method: 'PUT',
    url: `${config.apiEndpoints.cms}/documents/${pageId}/variants/${variantId}/content`,
    name: SAVE_CMS_CONTENT,
  });

  if (response.error) {
    const { cmsContent } = response.error.errors;
    yield put(toastListActions.addMessageError(cmsContent || 'generic.serverError', 5000));
  } else {
    yield put(toastListActions.addMessage('generic.data.savedSuccess'));
  }
}

export function* watchSaveCmsContent() {
  yield takeEveryWithProgressBar(SAVE_CMS_CONTENT, processSaveCmsContent);
}
