import { call } from 'redux-saga/effects';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { API_MAKE_REQUEST } from '../actions';

export function watchApiMakeRequest() {
  return takeEveryWithProgressBar(API_MAKE_REQUEST, process);
}

export function* process({ payload }) {
  yield call(makeRequestAndSaveState, {
    ...payload,
    url: payload.endpoint + (payload.endpointPath || '')
  });
}
