import * as React from 'react';
import { Modal, Form, TimePicker, NumericField } from 'ui';
import { MODAL_ORPHAN } from '../../constants';

export const ModalInfo = (props: any) => {
  const { slotDetails } = props;

  return (
    <Modal
      size="small"
      name={MODAL_ORPHAN}
      caption="modules.slotManagement.storeCalendar.edit.modalOrphanSlot.title"
      messages={[
        {
          caption: 'modules.slotManagement.storeCalendar.edit.modalOrphanSlot.message_01'
        }
      ]}
    >
      <Form
        name="orphanSlotDetails"
        initialValues={{
          start: slotDetails.startDate,
          end: slotDetails.endDate,
          orders: slotDetails.orders
        }}
      >
        <TimePicker
          readOnly
          name="start"
          className="mrg-btm-15"
          caption="modules.slotManagement.storeCalendar.edit.modalOrphanSlot.fieldTimeIniLabel"
        />

        <TimePicker
          readOnly
          name="end"
          className="mrg-btm-15"
          caption="modules.slotManagement.storeCalendar.edit.modalOrphanSlot.fieldTimeEndLabel"
        />

        <NumericField
          disabled
          placeholder="0"
          name="orders"
          caption="modules.slotManagement.storeCalendar.edit.modalOrphanSlot.fieldCapacityLabel"
       />
      </Form>
    </Modal>
  );
};
