import * as React from 'react';
import i18n from 'i18n';
import { Field, WrappedFieldProps } from 'redux-form';
import { Icon, IconNames } from '../Icon';
import { prefixClassName } from '../_conf';
import './style.scss';

const selectBoxClx = prefixClassName('select-box');

type OnChangeCallback = (ev: React.ChangeEvent<HTMLInputElement>) => void;

interface InputProps {
  name: string;
  inputValue: string;
  selectedValue?: string;
  onChange?: OnChangeCallback;
}

interface SelectBoxProps extends InputProps {
  icon: IconNames;
  label: string;
  description: string;
  className?: string;
}

/**
 * Wrapper for the boxes
 */
const SelectBox = ({ children }: { children: any }) => <div className={`${selectBoxClx}-container`}>{children}</div>;

/**
 * Each box option
 */
SelectBox.Item = (props: SelectBoxProps) => {
  const { name, inputValue, selectedValue, onChange = () => {}, className, icon, label, description } = props;
  return (
    <label className={`${selectBoxClx} ${className}`} htmlFor={`${name}__${inputValue}`}>
      <input
        id={`${name}__${inputValue}`}
        name={name}
        type="radio"
        value={inputValue}
        aria-describedby={`${name}__${inputValue}__desc`}
        checked={inputValue === selectedValue}
        className={`${selectBoxClx}__input`}
        onChange={onChange}
      />
      <div className={`${selectBoxClx}__box`}>
        <div className={`${selectBoxClx}__box__icon`}>
          <Icon className={`${selectBoxClx}__box__icon__svg`} name={icon} />
        </div>
        <span className={`${selectBoxClx}__box__label`}>{i18n.t(label)}</span>
      </div>
      {description && (
        <div id={`${name}__${inputValue}__desc`} className={`${selectBoxClx}__desc`}>
          {i18n.t(description)}
        </div>
      )}
    </label>
  );
};

interface ReduxFormInputProps extends SelectBoxProps, WrappedFieldProps {}

const InputField = (props: ReduxFormInputProps) => {
  const {
    input: { name, onChange: reduxFormOnChange, value: selectedValue },
    onChange = () => {},
  } = props;

  const changeCallback = (ev) => {
    reduxFormOnChange(ev);
    onChange(ev);
  };

  return <SelectBox.Item {...props} name={name} selectedValue={selectedValue} onChange={changeCallback} />;
};

/**
 * Box option for use inside redux-form
 */
SelectBox.FormItem = (props: SelectBoxProps) => {
  const { name } = props;

  return <Field name={name} component={InputField} {...props} />;
};

export { SelectBox };
