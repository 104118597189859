/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import * as React from 'react';
import classNames from 'classnames';
import { IKebabButton } from '../Kebab.interfaces';
import './_style.scss';

export class KebabButton extends React.PureComponent<IKebabButton> {
  static defaultProps: Partial<IKebabButton> = {
    helperText: 'show options',
    active: false,
    testId: 'actions',
  };

  onClickHandler = (e?: unknown) => {
    const { disabled, onClick } = this.props;

    if (!disabled && onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      active, className, helperText, disabled, testId,
    } = this.props;

    return (
      <span
        role="button"
        onClick={this.onClickHandler}
        className={classNames(
          className,
          active && `${className}--active`,
          disabled && `${className}--disabled`,
        )}
        title={helperText}
        aria-label={helperText}
        data-testid={testId}
      >
        <div />
        <div />
        <div />
      </span>
    );
  }
}

export default KebabButton;
