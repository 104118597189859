import * as ACTIONS from './actions.constants';

// Sagas
export const fetchCalendarDataAction = (payload?: any) => ({
  type: ACTIONS.VIEWCALENDAR_FETCH_DATA,
  payload: payload || {}
});

export const saveCalendarSlots = () => ({
  type: ACTIONS.VIEWCALENDAR_SAVE_DATA
});

export const saveCalendarSlotsCloseToggle = () => ({
  type: ACTIONS.VIEWCALENDAR_SLOT_CLOSE_TOGGLE
});

export const updateCalendarSlots = () => ({
  type: ACTIONS.VIEWCALENDAR_SLOT_ADD
});
export const createBlocker = () => ({
  type: ACTIONS.VIEWCALENDAR_SLOT_BLOCKER_ADD
});
