import store from 'framework/store';
import { ResolveType, InferableFunction } from 'types';
import { inProgress } from './ProgressBar.actions';

export const addProgressBar = <T extends InferableFunction>(fn: T) => {
  const { dispatch } = store;

  return async (...fnArgs: Parameters<T>): Promise<ResolveType<T>> => {
    dispatch(inProgress(true));
    const result = await fn(...fnArgs);
    dispatch(inProgress(false));
    return result;
  };
};

/**
 * @deprecated NOT A HOOK, use addProgressBar instead
 */
export const useProgressBar = addProgressBar;
