/* eslint-disable no-underscore-dangle */
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../Button';
import { IButtonReset, IButtonResetPrivate } from './ButtonReset.interfaces';

export const ButtonReset = (props: IButtonReset & IButtonResetPrivate) => {
  const {
    formToReset,
    caption = 'generic.button.reset',
    outline = true,
    onClick = () => null,
    onReset = () => null,
    resetForm,
    testId = 'btnReset',
    propagate,
    trackEventProps
  } = props;

  const { pathname } = useLocation();

  const handleClick = (e?: any) => {
    const _e: any = propagate ? e : undefined;
    ApplicationMonitoring.trackEvent(trackEventProps?.name || pathname, trackEventProps?.value || 'Button reset', trackEventProps?.label || caption || formToReset);
    onClick(_e);
    resetForm();
    onReset(_e);
  };

  return (
    <Button
      form={formToReset}
      caption={caption}
      outline={outline}
      {...props}
      type="reset"
      onClick={handleClick}
      testId={testId}
    />
  );
};

export default ButtonReset;
