export enum OrderTranslations {
  Orders = 'modules.orderManagement.orders',
  OrderId = 'modules.orderManagement.orderID',
  Customer = 'modules.orderManagement.customer',
  ContactNo = 'modules.orderManagement.contactNo',
  Store = 'modules.orderManagement.store',
  FulfilmentType = 'modules.orderManagement.fulfilmentType',
  LoyaltyId = 'modules.orderManagement.loyaltyId',
  FulfilmentTime = 'modules.orderManagement.fulfilmentTime',
  FulfilmentTimeTooltip = 'modules.orderManagement.fulfilmentTimeTooltip',
  Status = 'modules.orderManagement.status',
  EstTotal = 'modules.orderManagement.estTotal',
  PlacedAt = 'modules.orderManagement.placedAt',
  PlacedAtTooltip = 'modules.orderManagement.placedAtTooltip',
  LastModified = 'modules.orderManagement.lastModified',
  LastModifiedTooltip = 'modules.orderManagement.lastModifiedTooltip',
  Actions = 'modules.orderManagement.actions',
  FilterByStores = 'modules.orderManagement.FilterByStores',
  ContinueToFilters = 'modules.orderManagement.ContinueToFilters',
  StoresFilterMessage = 'modules.orderManagement.StoresFilterMessage',
  Cancel = 'generic.options.cancel',
  Close = 'generic.close',
  FulfillAreYouSure = 'modules.orderManagement.bulkActionConfirmation.fulfillAreYouSure',
  FulfillDelayMessage = 'modules.orderManagement.bulkActionConfirmation.fulfillDelayMessage',
  CompleteAreYouSure = 'modules.orderManagement.bulkActionConfirmation.completeAreYouSure',
  CompleteDelayMessage = 'modules.orderManagement.bulkActionConfirmation.completeDelayMessage',
  RetryPaymentAreYouSure = 'modules.orderManagement.bulkActionConfirmation.retryPaymentAreYouSure',
  RetryPaymentDelayMessage = 'modules.orderManagement.bulkActionConfirmation.retryPaymentDelayMessage',
  ExportAreYouSure = 'modules.orderManagement.bulkActionConfirmation.exportAreYouSure',
  ExportDelayMessage = 'modules.orderManagement.bulkActionConfirmation.exportDelayMessage',
  CancelAreYouSure = 'modules.orderManagement.bulkActionConfirmation.cancelAreYouSure',
  CancelDelayMessage = 'modules.orderManagement.bulkActionConfirmation.cancelDelayMessage',
  CancelOrderSuccess = 'modules.orderManagement.cancelOrderSuccess',
  CancelOrderSuccessPlural = 'modules.orderManagement.cancelOrderSuccess_plural',
  CancellationErrorPlural = 'modules.orderManagement.cancellationError_plural',
  View = 'generic.options.view',
  CancelOrderTitle = 'modules.orderManagement.cancelOrderTitle',
  CancelOrder = 'modules.orderManagement.cancelOrder',
  CancelOrderPlural = 'modules.orderManagement.cancelOrder_plural',
  CancelOrderMessage = 'modules.orderManagement.cancelOrderMessage',
  EnterOrderID = 'modules.orderManagement.enterOrderID',
  OrderManagement = 'navigation.orderManagement',
  OrderDetails = 'modules.orderManagement.orderDetails',
  History = 'modules.orderManagement.history',
  OrderInformation = 'modules.orderManagement.orderInformation',
  OrderDetailsMessage = 'modules.orderManagement.panelOrderDetailsMessage',
  CustomerDetails = 'modules.orderManagement.customerDetails',
  DeliveryDetails = 'modules.orderManagement.deliveryDetails',
  CustomerName = 'modules.orderManagement.customerName',
  Email = 'modules.orderManagement.email',
  AccContactNo = 'modules.orderManagement.accContactNo',
  LoyaltyCard = 'modules.orderManagement.loyaltyCard',
  ViewAllComments = 'modules.orderManagement.viewAllComments',
  ViewAllSubscriptions = 'modules.orderManagement.viewAllSubscriptions',
  CustomerComments = 'modules.siteadmin.claims.customerComments',
  CustomerViewSubscriptions = 'modules.siteadmin.claims.customerViewSubscriptions',
  NumberOfComments = 'modules.orderManagement.numberOfComments',
  CommentsCount = 'modules.crm.commentsTab.commentsCount',
  FulfilmentDetails = 'modules.orderManagement.fulfilmentDetails',
  PaymentMethods = 'modules.orderManagement.paymentMethods',
  PromoCodes = 'modules.orderManagement.PromoCodes',
  PromotionCode = 'modules.orderManagement.promotionCode',
  Name = 'modules.orderManagement.Name',
  Description = 'modules.orderManagement.Description',
  Savings = 'modules.orderManagement.Savings',
  OffersAndPromotions = 'modules.orderManagement.OffersAndPromotions',
  VouchersAndCoupons = 'modules.orderManagement.VouchersAndCoupons',
  CouponSavingsEstimate = 'modules.orderManagement.CouponSavingsEstimate',
  ClippedVoucherAndCoupons = 'modules.orderManagement.clippedVoucherAndCoupons',
  VoucherCode = 'modules.orderManagement.voucherCode',
  ClippedVoucherAndCouponsInfo = 'modules.orderManagement.clippedVoucherAndCouponsInfo',
  ValidFrom = 'modules.orderManagement.validFrom',
  ValidTo = 'modules.orderManagement.validTo',
  Tip = 'modules.orderManagement.Tip',
  Products = 'modules.orderManagement.Products',
  OrderItemsAndTotalSummary = 'modules.orderManagement.OrderItemsAndTotalSummary',
  OrderedAndPicked = 'modules.orderManagement.OrderedAndPicked',
  ChangedAndRefunded = 'modules.orderManagement.ChangedAndRefunded',
  Charged = 'modules.orderManagement.charged',
  ChargedAndRefundedMessage = 'modules.orderManagement.ChargedAndRefundedMessage',
  PreviouslyRefunded = 'modules.orderManagement.PreviouslyRefunded',
  ReasonForRefund = 'modules.orderManagement.reasonForRefund',
  ReasonForRefundExplainer = 'modules.orderManagement.reasonForRefundExplainer',
  ReasonForRefundPlaceholder = 'modules.orderManagement.reasonForRefundPlaceholder',
  TotalRefunds = 'modules.orderManagement.TotalRefunds',
  Price = 'modules.orderManagement.Price',
  TotalPrice = 'modules.orderManagement.TotalPrice',
  FinalTotal = 'modules.orderManagement.finalTotal',
  FinalTotalTooltip = 'modules.orderManagement.finalTotalTooltip',
  FinalTotalPrice = 'modules.orderManagement.finalTotalPrice',
  FinalTotalAfterRefunds = 'modules.orderManagement.finalTotalAfterRefunds',
  RefundedAmount = 'modules.orderManagement.refundedAmount',
  ItemRefunds = 'modules.orderManagement.itemRefunds',
  FinalQuantityCharged = 'modules.orderManagement.finalQuantityCharged',
  OrderComments = 'modules.orderManagement.OrderComments',
  OrderCommentsMessage = 'modules.orderManagement.OrderCommentsMessage',
  PostedAt = 'modules.orderManagement.PostedAt',
  PostedBy = 'modules.orderManagement.PostedBy',
  Comment = 'modules.orderManagement.Comment',
  DeliveryAddress = 'modules.orderManagement.deliveryAddress',
  FulfilmentContactNumber = 'modules.orderManagement.fulfilmentContactNumber',
  DeliveryInstructions = 'modules.orderManagement.deliveryInstructions',
  Fee = 'modules.orderManagement.fee',
  FufilmentProvider = 'modules.orderManagement.fulfilmentProvider',
  PaymentType = 'modules.orderManagement.paymentType',
  BillingAddress = 'modules.orderManagement.billingAddress',
  CardNumber = 'modules.orderManagement.cardNumber',
  CardExpiry = 'modules.orderManagement.cardExpiry',
  Amount = 'modules.orderManagement.amount',
  ClearStores = 'generic.clearStores',
  NoPromoCodes = 'modules.orderManagement.NoPromoCodes',
  NoOffersAndPromotions = 'modules.orderManagement.NoOffersAndPromotions',
  NoComments = 'modules.orderManagement.NoComments',
  ExactOrderIdRequired = 'modules.orderManagement.exactOrderIdRequired',
  ProductName = 'modules.orderManagement.productName',
  Quantity = 'modules.orderManagement.quantity',
  AllowSubs = 'modules.orderManagement.allowSubs',
  CustomerNotes = 'modules.orderManagement.customerNotes',
  EstPrice = 'modules.orderManagement.estPrice',
  EstTotalPrice = 'modules.orderManagement.estTotalPrice',
  SubstitutedWith = 'modules.orderManagement.substitutedWith',
  And = 'modules.orderManagement.and',
  PreferredSubstitute = 'generic.status.preferredSubstitute',
  ConsignmentX = 'modules.orderManagement.consignmentX',
  OrderItemTypes = 'modules.orderManagement.orderItemTypes',
  WasPrice = 'modules.orderManagement.wasPrice',
  ConsignmentTotalItems = 'modules.orderManagement.consignmentTotalItems',
  ConsignmentItemsTotal = 'modules.orderManagement.consignmentItemsTotal',
  OtherInformation = 'modules.orderManagement.otherInformation',
  Total = 'modules.orderManagement.total',
  TotalNumberOfItems = 'modules.orderManagement.totalNumberOfItems',
  Subtotal = 'modules.orderManagement.subtotal',
  Tax = 'modules.orderManagement.tax',
  AdditionalDiscountsAndChargesAmount = 'modules.orderManagement.additionalDiscountsAndChargesAmount',
  Fulfilment = 'modules.orderManagement.fulfilment',
  EstCoupons = 'modules.orderManagement.estCoupons',
  OffersAndPromotionsAtCheckout = 'modules.orderManagement.offersAndPromotionsAtCheckout',
  OffersAndPromotionsAfterPOS = 'modules.orderManagement.offersAndPromotionsAfterPOS',
  SKUs = 'modules.orderManagement.SKUs',
  User = 'modules.orderManagement.user',
  ChangedBy = 'modules.orderManagement.changedBy',
  ChangeType = 'modules.orderManagement.changeType',
  TimeOfChange = 'modules.orderManagement.timeOfChange',
  HistoryHint = 'modules.orderManagement.historyHint',
  AllChangeTypes = 'modules.orderManagement.allChangeTypes',
  HistoryChangePrefix = 'modules.orderManagement.historyChangeType.',
  NoteForPersonalShopper = 'modules.orderManagement.noteForPersonalShopper',
  ContactNoForNotifications = 'modules.orderManagement.contactNoForNotifications',
  Cancelling = 'modules.orderManagement.cancellingOrder',
  AreYouSure = 'generic.areYouSure',
  Continue = 'generic.button.continue',
  CancellationSuccess = 'modules.orderManagement.cancellationSuccess',
  CancellationError = 'modules.orderManagement.cancellationError',
  CancellationCheckLater = 'modules.orderManagement.cancellationCheckLater',
  DoNotCancelOrder = 'modules.orderManagement.doNotCancelOrder',
  DoNotCancelOrderPlural = 'modules.orderManagement.doNotCancelOrder_plural',
  CheckOrderLater = 'modules.orderManagement.checkOrderLater',
  ShowLess = 'generic.showLess',
  ShowMore = 'generic.showMore',
  ConsignmentXAddress = 'modules.orderManagement.consignmentXAddress',
  CustomerCredits = 'modules.orderManagement.customerCredits',
  FilterByEmail = 'modules.orderManagement.filterByEmail',
  AllOrders = 'modules.orderManagement.allOrders',
  SaveChanges = 'generic.button.saveChanges',
  Reset = 'generic.button.reset',
  Select = 'generic.button.select',
  EditFulfilmentInformation = 'modules.orderManagement.editFulfilmentInformation',
  SelectFulfilmentTime = 'modules.orderManagement.selectFulfilmentTime',
  Reserved = 'modules.orderManagement.reserved',
  SoldOut = 'modules.orderManagement.soldOut',
  SlotsLeft = 'modules.orderManagement.slotsLeft',
  PickupAddress = 'modules.orderManagement.pickupAddress',
  Comments = 'modules.orderManagement.comments',
  TimeslotCommentsExplainer = 'modules.orderManagement.timeslotCommentsExplainer',
  UpdateFulfilment = 'modules.orderManagement.updateFulfilment',
  UpdatingFulfilment = 'modules.orderManagement.updatingFulfilment',
  TimeslotSuccess = 'modules.orderManagement.timeslotSuccess',
  TimeslotError = 'modules.orderManagement.timeslotError',
  TimeslotCheckLater = 'modules.orderManagement.timeslotCheckLater',
  NoTimeslotsAvailable = 'modules.orderManagement.noTimeslotsAvailable',
  FulfilmentChargeNotice = 'modules.orderManagement.fulfilmentChargeNotice',
  Attributes = 'modules.orderManagement.attributes',
  ReferenceName = 'modules.orderManagement.referenceName',
  Value = 'modules.orderManagement.value',
  FulfilmentUpdateSuccess = 'modules.orderManagement.fulfilmentUpdateSuccess',
  UpdateChargeAmount = 'modules.orderManagement.updateChargeAmount',
  UpdateChargeAmountSnap = 'modules.orderManagement.updateChargeAmountSnap',
  UpdateChargeAmountNonSnap = 'modules.orderManagement.updateChargeAmountNonSnap',
  UpdateChargeAmountTotal = 'modules.orderManagement.updateChargeAmountTotal',
  UpdateChargeAmountMessage01 = 'modules.orderManagement.updateChargeAmountModalMessage_01',
  UpdateChargeAmountExplainer = 'modules.orderManagement.updateChargeAmountExplainer',
  UpdateChargeAmountWarning = 'modules.orderManagement.updateChargeAmountWarning',
  UpdatingChargeAMount = 'modules.orderManagement.updatingChargeAmount',
  UpdateChargeAmountSuccess = 'modules.orderManagement.updateChargeAmountSuccess',
  UpdateChargeAmountError = 'modules.orderManagement.updateChargeAmountError',
  UpdateChargeAmountCheckLater = 'modules.orderManagement.updateChargeAmountCheckLater',
  RetryPaymentTitle = 'modules.orderManagement.retryPaymentTitle',
  RetryPayment = 'modules.orderManagement.retryPayment',
  RetryPaymentPlural = 'modules.orderManagement.retryPayment_plural',
  DoNotRetryPayment = 'modules.orderManagement.doNotRetryPayment',
  DoNotRetryPaymentPlural = 'modules.orderManagement.doNotRetryPayment_plural',
  RetryPaymentSuccess = 'modules.orderManagement.retryPaymentSuccess',
  RetryPaymentError = 'modules.orderManagement.retryPaymentError',
  RetryPaymentCheckLater = 'modules.orderManagement.retryPaymentCheckLater',
  RetryPaymentNotice = 'modules.orderManagement.retryPaymentNotice',
  Refund = 'modules.orderManagement.refund',
  RefundPayment = 'modules.orderManagement.refundPayment',
  SendToCollections = 'modules.orderManagement.sendToCollections',
  SentToCollections = 'modules.orderManagement.sentToCollections',
  SentToCollectionsYes = 'modules.orderManagement.sentToCollectionsYes',
  SentToCollectionsNo = 'modules.orderManagement.sentToCollectionsNo',
  SendToCollectionsSuccess = 'modules.orderManagement.sendToCollectionsSuccess',
  SendToCollectionsSuccessPlural = 'modules.orderManagement.sendToCollectionsSuccess-plural',
  SendToCollectionsCheckLater = 'modules.orderManagement.sendToCollectionsCheckLater',
  SendToCollectionsError = 'modules.orderManagement.sendToCollectionsError',
  SendToCollectionsErrorPlural = 'modules.orderManagement.sendToCollectionsError-plural',
  SendToCollectionsTitle = 'modules.orderManagement.sendToCollectionsTitle',
  SendToCollectionsCancel = 'modules.orderManagement.sendToCollectionsCancel',
  SendToCollectionsWarning = 'modules.orderManagement.sendToCollectionsWarning',
  SendToCollectionsInProgress = 'modules.orderManagement.sendToCollectionsInProgress',
  SendToCollectionsAreYouSure = 'modules.orderManagement.bulkActionConfirmation.sendToCollectionsAreYouSure',
  SendToCollectionsDelayMessage = 'modules.orderManagement.bulkActionConfirmation.sendToCollectionsDelayMessage',
  RetrieveFromCollections = 'modules.orderManagement.retrieveFromCollections',
  RetrieveFromCollectionsSuccess = 'modules.orderManagement.retrieveFromCollectionsSuccess',
  RetrieveFromCollectionsSuccessPlural = 'modules.orderManagement.retrieveFromCollectionsSuccess-plural',
  RetrieveFromCollectionsCheckLater = 'modules.orderManagement.retrieveFromCollectionsCheckLater',
  RetrieveFromCollectionsError = 'modules.orderManagement.retrieveFromCollectionsError',
  RetrieveFromCollectionsErrorPlural = 'modules.orderManagement.retrieveFromCollectionsError-plural',
  RetrieveFromCollectionsTitle = 'modules.orderManagement.retrieveFromCollectionsTitle',
  RetrieveFromCollectionsCancel = 'modules.orderManagement.retrieveFromCollectionsCancel',
  RetrieveFromCollectionsWarning = 'modules.orderManagement.retrieveFromCollectionsWarning',
  RetrieveFromCollectionsInProgress = 'modules.orderManagement.retrieveFromCollectionsInProgress',
  RetrieveFromCollectionsAreYouSure = 'modules.orderManagement.bulkActionConfirmation.retrieveFromCollectionsAreYouSure',
  RetrieveFromCollectionsDelayMessage = 'modules.orderManagement.bulkActionConfirmation.retrieveFromCollectionsDelayMessage',
  refundUpdate = 'modules.orderManagement.refundUpdate',
  refundUpdateMessage = 'modules.orderManagement.refundUpdateMessage',
  RefundInProgressCaption = 'modules.orderManagement.refundInProgressCaption',
  RefundInProgressMessage = 'modules.orderManagement.refundInProgressMessage',
  RefundEbtCaption = 'modules.orderManagement.refundEbtCaption',
  RefundWarning = 'modules.orderManagement.refundWarning',
  SnapEligible = 'modules.orderManagement.snapEligible',
  NonSnapEligible = 'modules.orderManagement.nonSnapEligible',
  RefundedX = 'modules.orderManagement.refundedX',
  RefundAmount = 'modules.orderManagement.refundAmount',
  MaxRefundAmount = 'modules.orderManagement.maxRefundAmount',
  RequestRefund = 'modules.orderManagement.requestRefund',
  PreviousRefunds = 'modules.orderManagement.previousRefunds',
  RefundRequestSuccess = 'modules.orderManagement.refundRequestSuccess',
  RefundRequestError = 'modules.orderManagement.refundRequestError',
  Notes = 'modules.orderManagement.notes',
  RefundNotesHint = 'modules.orderManagement.refundNotesHint',
  RequestRefundConfirmation = 'modules.orderManagement.requestRefundConfirmation',
  TransactionSummary = 'modules.orderManagement.transactionSummary',
  Date = 'modules.orderManagement.date',
  Action = 'modules.orderManagement.action',
  Message = 'modules.orderManagement.message',
  OrderSummary = 'modules.orderManagement.orderSummary',
  PaymentDetails = 'modules.orderManagement.paymentDetails',
  TransactionID = 'modules.orderManagement.transactionID',
  ChargeAmount = 'modules.orderManagement.chargeAmount',
  CurrentStatus = 'modules.orderManagement.currentStatus',
  NotifyCustomer = 'modules.orderManagement.notifyCustomer',
  NotifyCustomerTooltip = 'modules.orderManagement.notifyCustomerTooltip',
  SendEmailSuccess = 'modules.orderManagement.sendEmailSuccess',
  SendEmailError = 'modules.orderManagement.sendEmailError',
  Time = 'modules.orderManagement.time',
  RefundEntireChargeAmount = 'modules.orderManagement.refundEntireChargeAmount',
  TotalRefund = 'modules.orderManagement.totalRefund',
  OrderItems = 'modules.orderManagement.orderItems',
  FilterByOrderItemsPlaceholder = 'modules.orderManagement.filterOrderItemsPlaceholder',
  EmptyOrderItems = 'modules.orderManagement.emptyOrderItems',
  NumberOfRefundItems = 'modules.orderManagement.numberOfRefundItems',
  FulfillmentFee = 'modules.orderManagement.fulfillmentFee',
  FulfillmentFeeAmount = 'modules.orderManagement.fulfillmentFeeAmount',
  RefundFulfillmentFee = 'modules.orderManagement.refundFulfillmentFee',
  GeneralRefund = 'modules.orderManagement.generalRefund',
  GeneralRefundExplainer = 'modules.orderManagement.generalRefundExplainer',
  FeeRefundExplainer = 'modules.orderManagement.feeRefundExplainer',
  QuantityToRefund = 'modules.orderManagement.quantityToRefund',
  AmountToRefund = 'modules.orderManagement.amountToRefund',
  PointsRedeemed = 'modules.orderManagement.pointsRedeemed',
  PointsRedeemedContent = 'modules.orderManagement.pointsRedeemedContent',
  PickedInQtdsOf = 'modules.orderManagement.pickedInQtdsOf',
  PointsEarned = 'modules.orderManagement.pointsEarned',
  FilterByKeyword = 'generic.filterByKeyword',
  ApplyFilters = 'generic.button.applyFilters',
  SaveSearch = 'generic.button.saveSearch',
  SelectStores = 'generic.selectStores',
  XStoresSelected = 'generic.XStoresSelected',
  EditSelection = 'generic.editSelection',
  ShowOrdersWhen = 'modules.orderManagement.showOrdersWhen',
  ShowWavesWhen = 'modules.orderManagement.showWavesWhen',
  OrderFiltersHint = 'modules.orderManagement.orderFiltersHint',
  WavesFiltersHint = 'modules.orderManagement.wavesFiltersHint',
  ChangeStatusToCompleteAction = 'modules.orderManagement.changeStatusToCompleteAction',
  CompleteOrderTitle = 'modules.orderManagement.completeOrderTitle',
  CompleteOrder = 'modules.orderManagement.completeOrder',
  CompleteOrderPlural = 'modules.orderManagement.completeOrder_plural',
  CompleteOrderMessage = 'modules.orderManagement.completeOrderMessage',
  CompletingOrder = 'modules.orderManagement.completingOrder',
  CompletionSuccess = 'modules.orderManagement.completionSuccess',
  CompletionSuccessPlural = 'modules.orderManagement.completionSuccess_plural',
  CompletionError = 'modules.orderManagement.completionError',
  CompletionErrorPlural = 'modules.orderManagement.completionError_plural',
  CompletionCheckLater = 'modules.orderManagement.completionCheckLater',
  DoNotCompleteOrder = 'modules.orderManagement.doNotCompleteOrder',
  DoNotCompleteOrderPlural = 'modules.orderManagement.doNotCompleteOrder_plural',
  ExportOrderToPOSAction = 'modules.orderManagement.exportOrderToPOSAction',
  ExportOrderTitle = 'modules.orderManagement.exportOrderTitle',
  ExportOrder = 'modules.orderManagement.exportOrder',
  ExportOrderPlural = 'modules.orderManagement.exportOrder_plural',
  DoNotExportOrder = 'modules.orderManagement.doNotExportOrder',
  DoNotExportOrderPlural = 'modules.orderManagement.doNotExportOrder_plural',
  ExportPOSFile = 'modules.orderManagement.exportPOSFile',
  ExportPOSFileRequested = 'modules.orderManagement.exportPOSFileRequested',
  ExportPOSFileError = 'modules.orderManagement.exportPOSFileError',
  ExportPOSFileModalTitle = 'modules.orderManagement.exportPOSFileModalTitle',
  ExportPOSFileModalTableTitle = 'modules.orderManagement.exportPOSFileModalTableTitle',
  ExportPOSFileModalTableColumnStatus = 'modules.orderManagement.exportPOSFileModalTableColumnStatus',
  ExportPOSFileModalTableColumnMessage = 'modules.orderManagement.exportPOSFileModalTableColumnMessage',
  ExportPOSFileModalTableColumnDate = 'modules.orderManagement.exportPOSFileModalTableColumnDate',
  ExportPOSFileModalButtonExport = 'modules.orderManagement.exportPOSFileModalButtonExport',
  IgnoreAtPOS = 'modules.orderManagement.ignoreAtPOS',
  ItemUpdateSuccess = 'modules.orderManagement.itemUpdateSuccess',
  ItemUpdateError = 'modules.orderManagement.itemUpdateError',
  ItemUpdateCheckLater = 'modules.orderManagement.itemUpdateCheckLater',
  OrderHasPaperPickedItems = 'modules.orderManagement.orderHasPaperPickedItems',
  PayAtStore = 'modules.orderManagement.payAtStore',
  GiftCard = 'modules.orderManagement.giftCard',
  ChangeStatusToPickedAction = 'modules.orderManagement.changeStatusToPickedAction',
  DoNotFulfillConsignment = 'modules.orderManagement.doNotFulfillConsignment',
  DoNotFulfillConsignmentPlural = 'modules.orderManagement.doNotFulfillConsignment_plural',
  FulfillConsignmentTitle = 'modules.orderManagement.fulfillConsignmentTitle',
  FulfillConsignment = 'modules.orderManagement.fulfillConsignment',
  FulfillConsignmentPlural = 'modules.orderManagement.fulfillConsignment_plural',
  FulfillConsignmentMessage = 'modules.orderManagement.fulfillConsignmentMessage',
  FulfillingConsignment = 'modules.orderManagement.fulfillingConsignment',
  FulfillConsignmentSuccess = 'modules.orderManagement.fulfillConsignmentSuccess',
  FulfillConsignmentSuccessPlural = 'modules.orderManagement.fulfillConsignmentSuccess_plural',
  FulfillConsignmentCheckLater = 'modules.orderManagement.fulfillConsignmentCheckLater',
  FulfillConsignmentError = 'modules.orderManagement.fulfillConsignmentError',
  OrderNumber = 'modules.orderManagement.orderNumber',
  RetryOriginalTimeslot = 'modules.orderManagement.retryOriginalTimeslot',
  SelectNewTimeslot = 'modules.orderManagement.selectNewTimeslot',
  RetryTimeslotReservation = 'modules.orderManagement.retryTimeslotReservation',
  RetryingTimeslotReservation = 'modules.orderManagement.retryingTimeslotReservation',
  SubmissionAction = 'modules.orderManagement.submissionAction',
  SubmissionDate = 'modules.orderManagement.submissionDate',
  OrderDeliveryStatus = 'modules.orderManagement.orderDeliveryStatus',
  ErrorDescription = 'modules.orderManagement.errorDescription',
  OrderStatus = 'modules.orderManagement.orderStatus',
  FailedSubmissions = 'modules.orderManagement.failedSubmissions',
  DeliveryProviders = 'modules.orderManagement.deliveryProviders',
  DeliverySolutions = 'modules.orderManagement.deliverySolutions',
  DeliveryOrderStatus = 'modules.orderManagement.solutionStatusColumn.Delivery',
  Zetes = 'modules.orderManagement.zetes',
  Fulfillment = 'modules.orderManagement.fulfillment',
  SubmissionStatus = 'modules.orderManagement.submissionStatus',
  ConsignmentStatus = 'modules.orderManagement.consignmentStatus',
  ChangeStatusToPicked = 'modules.orderManagement.changeStatusToPickedButton',
  UpdateConsignment = 'modules.orderManagement.updateConsignment',
  UpdateConsignmentMessage = 'modules.orderManagement.updateConsignmentMessage',
  DoNotUpdateConsignment = 'modules.orderManagement.doNotUpdateConsignment',
  UpdatingConsignment = 'modules.orderManagement.updatingConsignment',
  UpdateConsignmentSuccess = 'modules.orderManagement.updateConsignmentSuccess',
  UpdateConsignmentCheckLater = 'modules.orderManagement.updateConsignmentCheckLater',
  UpdateConsignmentError = 'modules.orderManagement.updateConsignmentError',
  EnterOrderTotalFromPOS = 'modules.orderManagement.enterOrderTotalFromPOS',
  EnterOrderTotalFromPOSNonSnap = 'modules.orderManagement.orderTotalFromPOSNonSnap',
  EnterOrderTotalFromPOSSnap = 'modules.orderManagement.orderTotalFromPOSSnap',
  EnterOrderTotalFromPOSHint = 'modules.orderManagement.enterOrderTotalFromPOSHint',
  EnterOrderTotalFromPOSHintEbt = 'modules.orderManagement.enterOrderTotalFromPOSHintEbt',
  EnterAmount = 'modules.orderManagement.enterAmount',
  EstimatedTotal = 'modules.orderManagement.estimatedTotal',
  OrderTotalFromPOS = 'modules.orderManagement.orderTotalFromPOS',
  OrderTotalFromPOSHint = 'modules.orderManagement.orderTotalFromPOSHint',
  POSConfirmationWarning = 'modules.orderManagement.POSConfirmationWarning',
  ConfirmAmount = 'modules.orderManagement.confirmAmount',
  DoNotEnterAmount = 'modules.orderManagement.doNotEnterAmount',
  SettingPOSTotalAmount = 'modules.orderManagement.settingPOSTotalAmount',
  POSConfirmationThresholdWarning = 'modules.orderManagement.POSConfirmationThresholdWarning',
  SetPOSTotalCheckLater = 'modules.orderManagement.setPOSTotalCheckLater',
  SetPOSTotalError = 'modules.orderManagement.setPOSTotalError',
  FinalTotalAfterPOS = 'modules.orderManagement.finalTotalAfterPOS',
  SetPOSTotalSuccess = 'modules.orderManagement.setPOSTotalSuccess',
  ManageOrders = 'modules.orderManagement.manageOrders',
  EnterPOSTotalAmountManually = 'modules.orderManagement.enterPOSTotalAmountManually',
  RefundPayments = 'modules.orderManagement.refundPayments',
  OrderTotalEstimateMessage = 'modules.orderManagement.orderTotalEstimateMessage',
  OrderPreferences = 'modules.orderManagement.OrderPreferences',
  OrderPreferenceName = 'modules.orderManagement.OrderPreferenceName',
  Option = 'modules.orderManagement.Option',
  BarcodeOrPlu = 'modules.orderManagement.BarcodeOrPlu',
  lastPlaceAt = 'modules.orderManagement.lastPlaceAt',
  nextFufilmentTime = 'modules.orderManagement.nextFufilmentTime',
  Event = 'modules.orderManagement.event',
  fulfilmentProviderDetails = 'modules.orderManagement.fulfilmentProviderDetails',
  PrintOrderSummary = 'modules.orderFulfillment.orderDetails.orderActions.printOrderSummary',
}

export enum DeliveryTranslations {
  DeliveryRetrySuccess = 'modules.orderManagement.deliveryRetrySuccess',
  DeliveryRetryError = 'modules.orderManagement.deliveryRetryError',
  DeliveryCloseSuccess = 'modules.orderManagement.deliveryCloseSuccess',
  DeliveryCloseError = 'modules.orderManagement.deliveryCloseError',
  DeliveryRetrySuccessPlural = 'modules.orderManagement.deliveryRetrySuccess_plural',
  DeliveryRetryErrorPlural = 'modules.orderManagement.deliveryRetryError_plural',
  DeliveryCloseSuccessPlural = 'modules.orderManagement.deliveryCloseSuccess_plural',
  DeliveryCloseErrorPlural = 'modules.orderManagement.deliveryCloseError_plural',
  ResendOrder = 'modules.orderManagement.resendOrder',
  RemoveFailedOrderFromList = 'modules.orderManagement.removeFailedOrderFromList',
  OpenDeliverySolutionsApplication = 'modules.orderManagement.openDeliverySolutionsApplication',
  RequestReceived = 'modules.orderManagement.deliveryStatus.REQUEST_RECEIVED',
  PendingDispatch = 'modules.orderManagement.deliveryStatus.PENDING_DISPATCH',
  PendingRelease = 'modules.orderManagement.deliveryStatus.PENDING_RELEASE',
  PendingRetry = 'modules.orderManagement.deliveryStatus.PENDING_RETRY',
  EstimatesReceived = 'modules.orderManagement.deliveryStatus.ESTIMATES_RECEIVED',
  EstimatesFailed = 'modules.orderManagement.deliveryStatus.ESTIMATES_FAILED',
  OrderDispatched = 'modules.orderManagement.deliveryStatus.ORDER_DISPATCHED',
  OrderFailed = 'modules.orderManagement.deliveryStatus.ORDER_FAILED',
  OrderAssigned = 'modules.orderManagement.deliveryStatus.ORDER_ASSIGNED',
  PickupStarted = 'modules.orderManagement.deliveryStatus.PICKUP_STARTED',
  PickupCompleted = 'modules.orderManagement.deliveryStatus.PICKUP_COMPLETED',
  PickupException = 'modules.orderManagement.deliveryStatus.PICKUP_EXCEPTION',
  OutForDelivery = 'modules.orderManagement.deliveryStatus.OUT_FOR_DELIVERY',
  OrderDelivered = 'modules.orderManagement.deliveryStatus.ORDER_DELIVERED',
  OrderCancelled = 'modules.orderManagement.deliveryStatus.ORDER_CANCELLED',
  OrderDelayed = 'modules.orderManagement.deliveryStatus.ORDER_DELAYED',
  OrderUnassigned = 'modules.orderManagement.deliveryStatus.ORDER_UNASSIGNED',
  OrderReturned = 'modules.orderManagement.deliveryStatus.ORDER_RETURNED',
  OrderUndeliverable = 'modules.orderManagement.deliveryStatus.ORDER_UNDELIVERABLE',
  ErrorException = 'modules.orderManagement.deliveryStatus.ERROR_EXCEPTION',
  OrderRedelivery = 'modules.orderManagement.deliveryStatus.ORDER_REDELIVERY',
  InTransit = 'modules.orderManagement.deliveryStatus.IN_TRANSIT',
  PartiallyInTransit = 'modules.orderManagement.deliveryStatus.PARTIAL_IN_TRANSIT',
  PartialException = 'modules.orderManagement.deliveryStatus.PARTIAL_EXCEPTION',
  PartiallyOutForDelivery = 'modules.orderManagement.deliveryStatus.PARTIAL_OUT_FOR_DELIVERY',
  PartiallyDelayed = 'modules.orderManagement.deliveryStatus.PARTIAL_DELAYED',
  PartiallyCancelled = 'modules.orderManagement.deliveryStatus.PARTIAL_CANCELLED',
  PartiallyDelivered = 'modules.orderManagement.deliveryStatus.PARTIAL_DELIVERED',
  PartiallyUndeliverable = 'modules.orderManagement.deliveryStatus.PARTIAL_UNDELIVERABLE',
  PartiallyReturned = 'modules.orderManagement.deliveryStatus.PARTIAL_RETURNED',
  DeliveryAtLocation = 'modules.orderManagement.deliveryStatus.DELIVERY_AT_LOCATION',
  PickupAtLocation = 'modules.orderManagement.deliveryStatus.PICKUP_AT_LOCATION',
  OrderAtLocation = 'modules.orderManagement.deliveryStatus.ORDER_AT_LOCATION',
  ResendOrderConfirm = 'modules.orderManagement.resendOrderConfirm',
  ResendOrderConfirmWarning = 'modules.orderManagement.resendOrderConfirmWarning',
  ResendOrderConfirmPlural = 'modules.orderManagement.resendOrderConfirm_plural',
  ResendConfirmButton = 'modules.orderManagement.resendConfirmButton',
  ResendConfirmButtonPlural = 'modules.orderManagement.resendConfirmButtonPlural',
  ResendCancelButton = 'modules.orderManagement.resendCancelButton',
  ResendCancelButtonPlural = 'modules.orderManagement.resendCancelButtonPlural',
  RemoveOrderConfirm = 'modules.orderManagement.removeOrderConfirm',
  RemoveOrderConfirmWarning = 'modules.orderManagement.removeOrderConfirmWarning',
  RemoveOrderConfirmPlural = 'modules.orderManagement.removeOrderConfirm_plural',
  RemoveOrderConfirmPluralWarning = 'modules.orderManagement.removeOrderConfirm_plural_warning',
  Remove = 'modules.orderManagement.remove',
  Confirmation = 'generic.confirmation',
  ConfirmButton = 'modules.orderManagement.confirmButton',
  ConfirmButtonPlural = 'modules.orderManagement.confirmButtonPlural',
  CancelButton = 'modules.orderManagement.cancelButton',
  CancelButtonPlural = 'modules.orderManagement.cancelButtonPlural',
}

export enum DeliverySolutionsTranslations {
  DSTitle = 'modules.orderManagement.deliverySolutions',
  DSOrderId = 'modules.orderManagement.DSSection.DSOrderId',
  DSProvider = 'modules.orderManagement.DSSection.DSProvider',
  DSOrderLink = 'modules.orderManagement.DSSection.DSOrderLink',
  DSLabelLink = 'modules.orderManagement.DSSection.DSLabelLink',
  DSTrackingUrl = 'modules.orderManagement.DSSection.DSTrackingUrl',
}
