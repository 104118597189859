import { config } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { AuthorizationClaims, ensureClaims } from 'framework/authorization';
import { FeatureState } from './types';

export const isDeliveryProviderEnabled = async (): Promise<FeatureState> => {
  const userHasAccess = ensureClaims(AuthorizationClaims.ORDERMANAGEMENT_FAILEDSUBMISSIONS);

  if (!userHasAccess) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData<boolean>({
    url: config.apiEndpoints.deliverySolutions.checkEnabled,
  });

  return {
    isEnabled: result.success && !!result.data,
  };
};
