import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  children?: any;
  activeClassName?: string;
  className?: string;
  onClick?: (e?: React.MouseEvent) => void;
  handleMouseEnter?: (e?: React.MouseEvent) => void;
  handleMouseLeave?: (e?: React.MouseEvent) => void;
  title?: string;
  to: string;
  external?: boolean;
  exact?: boolean;
  disabled?: boolean;
  headerPrimary?: boolean;
}

const LinkPrimitive = ({
  activeClassName,
  children,
  className,
  onClick,
  to,
  title,
  exact,
  disabled,
  external = false,
  handleMouseEnter,
  handleMouseLeave,
  headerPrimary,
}: Props) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (onClick) {
      onClick(e);
    }
  };

  const testId = 'headerPrimary';
  return (
    <NavLink
      target={external ? '_blank' : ''}
      title={title}
      aria-label={title || 'link'}
      className={className}
      to={external ? { pathname: to } : to}
      activeClassName={activeClassName}
      exact={exact}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={headerPrimary && testId}
    >
      {children}
    </NavLink>
  );
};

export default LinkPrimitive;
