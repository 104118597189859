import * as React from 'react';
import { ImageField, Panel } from 'ui';
import classNames from 'classnames';
import { StorefrontUiTranslations } from 'framework/modules/siteSettings/translations';
import { IRetailerImageComponentProps } from './interfaces';
import '../_styles.scss';
import { IImage } from '../../FormComponents/ImageField/ImageField.interfaces';

const RetailerImageComponent = (props: IRetailerImageComponentProps) => {
  const { component, value, field, handleChange, level, id, required } = props;
  const [inputValue, setInputValue] = React.useState<string>(value);

  const handleInputChange = (newImageUrl: string) => {
    setInputValue(value);
    handleChange(field, newImageUrl);
  };

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  const imageField = (
    <ImageField
      grid
      name={`${id}-${field}`}
      value={{
        alt: inputValue,
        title: inputValue?.replace(/^.*[\\/]/, ''),
        url: inputValue,
      }}
      initValue={{
        alt: inputValue,
        title: inputValue?.replace(/^.*[\\/]/, ''),
        url: inputValue,
      }}
      onChange={(newImage: IImage) => {
        handleInputChange(newImage?.url);
      }}
      caption={component.title}
      description={component.description}
      imageNameCaption={StorefrontUiTranslations.ImageNameCaption}
      mandatory={required}
      requiredFilesFormat={component.options?.requiredFormat}
    />
  );

  if (level === 1) {
    // Wrap image in a Panel if there is only one
    return (
      <Panel
        title={component.title}
        {...(component.description &&
          component.description.length > 1 && {
          messages: [{ caption: component.description, type: 'info', showLabel: false }],
        })}
        className={classNames(level > 1 && 'stretchToPanelWidth remove-border')}
      >
        {imageField}
      </Panel>
    );
  }

  return imageField;
};

export default RetailerImageComponent;
