import * as React from 'react';
import { Link } from 'framework/components/ui/Link';
import classNames from 'classnames';
import * as conf from 'framework/components/ui/_conf';
import { withStencils } from 'framework/components/hoc/withStencils';
import { GaActions } from 'framework/applicationMonitoring/interface';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useLocation } from 'react-router-dom';
import { ISubNavMenu } from './Navigation.interfaces';
import { resolveTitle } from './service';

const navigationClass = conf.prefixClassName('navigation');
const subnavClassname = `${navigationClass}__SubNav`;

const StencilledMenu = withStencils('form-field')(() => (
  <div/>
));

export const NavigationSubNavMenu = ({ route, onSelectNav, trackEventProps }: ISubNavMenu) => {
  const liClsNames = classNames({
    [`${subnavClassname}__navmenu`]: true,
  });

  const { pathname } = useLocation();

  return (
    <li className={liClsNames}>
      <Link
        to={route.path}
        onClick={() => {
          ApplicationMonitoring.trackEvent(trackEventProps?.name || pathname, trackEventProps?.value || GaActions.NavigationMenu, trackEventProps?.label || resolveTitle(route.menuTitle || route.pageTitle));
          onSelectNav();
        }}
        caption={resolveTitle(route.menuTitle || route.pageTitle)}
        exact
      />
    </li>
  );
};

export const LoadingSubMenus = () => (
  <>
  {[1, 2].map((i) => (
    <li key={i} className={classNames({
      [`${subnavClassname}__navmenu`]: true,
    })}>
      <StencilledMenu stencilled />
    </li>
  ))}
  </>
);
