import * as React from 'react';
import classnames from 'classnames';
import { Icon } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import withTranslation from 'framework/components/hoc/withTranslation';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IPaginationButtonsButton } from './Buttons.types';

const clx = prefixClassName('paginationButtons__btn');

export class PaginationButton extends React.Component<IPaginationButtonsButton & RouteComponentProps<any>> {
  static defaultProps: Partial<IPaginationButtonsButton> = {
    disabled: false,
    icon: 'dropdownArrowRight',
  };

  onClickHandler = () => {
    const { disabled, onClick, trackEventProps, location: {pathname}, icon } = this.props;
    if (!disabled) {
      ApplicationMonitoring.trackEvent(
        trackEventProps?.name || pathname,
        trackEventProps?.value || 'Pagination button',
        trackEventProps?.label || icon
      );
      onClick();
    }
  };

  render() {
    const { caption, disabled, icon: iconName } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <span
        onClick={this.onClickHandler}
        title={caption}
        className={classnames(clx, 'mwg-btn', disabled && `${clx}--disabled`)}
      >
        <Icon name={iconName} className={`${clx}__icon`} size="sm" />
      </span>
    );
  }
}

export default compose(withTranslation, withRouter)(PaginationButton);
