import { CmsLayoutInspector } from '../CmsComponents.interfaces';

export const fixDefaultValues = (inspector: CmsLayoutInspector, data: any) => {
  let formData = {
    ...data,
  };

  if (!inspector.props) {
    return formData;
  }

  inspector.props.forEach((prop) => {
    if (prop.blockElementType === 'RadioGroup') {
      const defaultKey = Object.keys(prop.radioItems).find((r) => {
        const item = prop.radioItems[r];
        return item.default;
      });

      if (defaultKey) {
        const item = prop.radioItems[defaultKey];
        let val = item.value ? item.value : defaultKey;

        if (typeof val === 'number') {
          val = `'${val}'`;
        }

        formData = {
          ...formData,
          [prop.name]: val,
        };
      }
    }
  });

  return formData;
};
