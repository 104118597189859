import * as React from 'react';
import { AuthAccessClaimRights } from './Interfaces';
import { ensureClaims } from './useAuthUser';

export type WithAuthorizeProps = {
  authorize?: AuthAccessClaimRights;
  AuthorizeFallback?: React.ComponentType;
};

export function withAuthorize<T>(Wrapped: React.ComponentType<T>) {
  function WithAuthorize(props: T & WithAuthorizeProps) {
    const {
      authorize,
      AuthorizeFallback = React.Fragment,
    } = props;
    const isAuthorized = React.useMemo(() => (authorize ? ensureClaims(authorize) : true), [authorize]);

    return (
      <>
        {isAuthorized && <Wrapped {...props} />}
        {!isAuthorized && <AuthorizeFallback />}
      </>
    );
  }

  return WithAuthorize;
}
