import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Lazy } from 'framework/components/ui/Lazy';
import { Page } from 'framework/components/ui/Page';
import { NoPermissionPage, AuthorizedRoute } from 'framework/authorization';
import { useAppRoutes } from 'framework/modules/routes';
import { view } from '@risingstack/react-easy-state';
import { LoadingSpinner } from 'framework/modules/contentmanagement/SharedContent/Helpers-Ui';

export const PageRoutingRenderer = view(() => {
  const { localRoutes, isLoading } = useAppRoutes();

  return isLoading ? <LoadingSpinner visible={isLoading} /> : (
    <Switch>
      <Route path="/nopermission" exact component={() => <NoPermissionPage isFeaturesLoading={isLoading} />} />
      {/* page routes */}
      {localRoutes?.map((appRoute) => {
        return (
          <Route
            key={appRoute.path}
            path={appRoute.path}
            exact
            render={(routeProps) => {
              return (
                <AuthorizedRoute routeClaim={appRoute.authClaim}>
                  <Lazy Component={React.lazy(appRoute.Component)} pageInfo={appRoute} appRoute={appRoute} {...routeProps} />
                </AuthorizedRoute>
              );
            }}
          />
        );
      })}
      {/* TODO: create proper Not found page */}
      <Route
        render={(routeProps) => (
          <Page
            hideBreadCrumbs
            pageInfo={{ pageTitle: 'Not found' }}
            pageParts={{ content: () => <div className="mrg-top-20">{routeProps.location.pathname}</div> }}
          />
        )}
      />
    </Switch>
  );
});
