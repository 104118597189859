import { store } from '@risingstack/react-easy-state';
import * as isEqual from 'lodash.isequal';
import { IMessageTypes } from 'framework/components/ui/Primitives/MessagePrimitive/MessagePrimitve.interface';
import { ActionResult } from 'types';

let id = 0;

export interface Toast {
  readonly id?: string;
  readonly caption: string | string[];
  readonly type: IMessageTypes;
}

interface ToastStore {
  toasts: Toast[];
}
const localStore = store<ToastStore>({ toasts: [] });

export const toastStore: Readonly<ToastStore> = localStore;

export const dismissToast = (toast: Toast) => {
  const index = localStore.toasts.findIndex((data) => data.id === toast.id && data.caption === toast.caption);
  if (index > -1) {
    const newToasts = [...localStore.toasts];
    newToasts.splice(index, 1);
    localStore.toasts = newToasts;
  }
};

export const dismissAllToasts = () => {
  localStore.toasts = [];
};

const toastExists = (newToast: Toast) => {
  const result: Toast[] = localStore.toasts.filter((toast: Toast) => {
    return Array.isArray(toast.caption) && Array.isArray(newToast.caption)
      ? isEqual(toast.caption, newToast.caption)
      : toast.caption === newToast.caption;
  });
  return result.length;
};

export const addToast = (message: string | string[], type: IMessageTypes, delay: number = 3000) => {
  id += 1;

  const newToast: Readonly<Toast> = {
    id: `${id}`,
    caption: message,
    type,
  };

  // adds the toast to the store, if it does not already exist
  if (!toastExists(newToast)) {
    localStore.toasts = [...localStore.toasts, newToast];
  }

  // sets the dimiss process
  setTimeout(() => {
    dismissToast(newToast);
  }, delay);

  return newToast;
};

export const toastSuccess = (message: string | string[], delay: number = 3000) => addToast(message, 'success', delay);

export const toastError = (message: string | string[], delay: number = 5000) => addToast(message, 'error', delay);

export const toastWarning = (message: string | string[], delay: number = 5000) => addToast(message, 'warning', delay);

export const toastInfo = (message: string | string[], delay: number = 5000) => addToast(message, 'info', delay);

export const toastNotification = (message: string | string[], delay: number = 5000) =>
  addToast(message, 'notification', delay);

// This is useful when implementing unit tests that require use of toastStore
export const resetToastId = () => {
  id = 0;
};

export const toastActionResult = (result: ActionResult, successDelay?: number, errorDelay?: number) => {
  if (result.success) {
    toastSuccess(result.successMessage || 'generic.data.savedSuccess', successDelay);
  } else {
    toastError(result.errorMessage || 'generic.data.saveError', errorDelay);
  }
};
