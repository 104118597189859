import * as React from 'react';
import { i18n } from 'i18n';
import ImagePrimitive from 'react-image';
import { store } from '@risingstack/react-easy-state';
import { Uuid } from 'framework/utils/generateId';
import ImgLoader from './Img.loader';
import { IImgProps } from './Img.interfaces';

export const localImgId = store<{id: string}>({ id: Uuid() });

const imageDefault = '/images/imgDefault.svg';
export const Img = (props: IImgProps) => {
  const {
    src, alt, height = '80px', width = '80px', className, isPresentational = false,
  } = props;
  const localizedAlt = i18n.t(alt);
  if (process.env.NODE_ENV !== 'production' && !alt && !isPresentational) {
    console.warn(`Missing alt text for image: ${src}`);
  }

  let imageUrls = [imageDefault];
  if (src) {
    if (Array.isArray(src)) {
      const editedSrc: string[] = src.map((s) => {
        const includesParams: boolean = /[?&]/.test(s);
        return `${s}${!includesParams ? '?' : '&'}id=${localImgId.id}`;
      });

      imageUrls = [...editedSrc, ...imageUrls];
    } else {
      const includesParams: boolean = /[?&]/.test(src);
      imageUrls = [`${src}${!includesParams ? '?' : '&'}id=${localImgId.id}`, ...imageUrls];
    }
  }
  
  return (
    <ImagePrimitive
      src={imageUrls}
      width={width}
      height={height}
      alt={localizedAlt}
      
      title={localizedAlt}
      className={`${className} `}
      role={isPresentational ? 'presentation' : null}
      loader={<ImgLoader width={width} height={height} caption="Loading image" isLoading />}
      unloader={<ImgLoader width={width} height={height} caption={localizedAlt} />}
    />
  );
};

export default Img;
