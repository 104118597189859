import * as get from 'lodash.get';
import { resolveDefaultWithdType } from 'framework/modules/contentmanagement/common/Layoutwidth/utils';
import {
  CmsLayoutInspector, CmsPageContent, CmsPageRow, LayoutWidthType,
} from '../CmsComponents.interfaces';
import { excludeOnRow } from '../common/PageBuilderEngine/CmsLayoutSelect/CmsLayoutSelect.Constants';
import { initializeLayout } from './utils.initializeLayout';

/*
   Add compoent to current page being edited
   This action is initiated from an Component Editor
*/

const insertRow = ({ index, rows, payload }) => {
  if (index >= rows.length) {
    return [
      ...rows,
      {
        ...payload,
        width: LayoutWidthType.contentWidth,
      },
    ];
  }
  rows.splice(index, 0, payload);
  return [...rows];
};

export const addSelectedLayoutToPage = (
  inspector: CmsLayoutInspector,
  page: CmsPageContent,
  insertIndex?: number,
): CmsPageRow[] => {
  let rows: CmsPageRow[] = get({ Rows: page?.rows }, 'Rows', []);

  if (inspector.name === 'Row') {
    // User is explicitely adding an empty row
    return insertRow({
      index: insertIndex,
      rows,
      payload: {
        columns: [],
        implicit: false,
        type: 'Row',
      },
    });
  }

  const selection = inspector.selection || [];
  const explicite = !selection.some((key) => excludeOnRow.includes(key));

  rows = insertRow({
    index: insertIndex,
    rows,
    payload: {
      columns: [],
      type: 'Row',
      implicit: !explicite,
    },
  });

  return rows.map((row: any, i) => {
    if (insertIndex === i) {
      const payload: any = initializeLayout(inspector);
      return {
        ...row,
        width: resolveDefaultWithdType(payload.type),
        columns: [...row.columns, payload],
      };
    }
    return row;
  });
};
