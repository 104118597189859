export enum AuthorizationClaims {
  POWER_USER = 'poweruser',
  FULLACCESS = 'fullaccess',

  // content management claims
  CONTENTMANAGEMENT_PAGES = 'contentmanagement.pages',
  CONTENTMANAGEMENT_EMAILS = 'contentmanagement.emails',
  CONTENTMANAGEMENT_TERMS = 'contentmanagement.terms',
  CMS_TERMS = 'cms.terms',
  CONTENTMANAGEMENT_NOTIFICATIONS = 'contentmanagement.notifications',
  DAM = 'dam',
  CMS_PREVIEW_SESSIONS = 'cmspreview.sessions',
  CONTENTMANAGEMENT_PRINTED_DOCUMENTS = 'contentmanagement.printeddocument',

  // Slot Management claims
  SLOTMANAGEMENT_CALENDAR = 'timeslotadmin.calendar',
  SLOTMANAGEMENT_SLOTS_TEMPLATES = 'timeslotadmin.template',

  // Stores claims
  STORES_SITE = 'stores.site',
  STORES_STORE = 'stores.store',
  STORES_ORGHIERARCHY = 'stores.orghierarchy',
  STORES_ATTRIBUTE = 'stores.attribute',
  STORES_SITE_ADDRESSFINDER = 'addressfinder.addressfinder',
  DELIVERYAREASADMIN_DELIVERYAREAS = 'deliveryareasadmin.deliveryareas',
  STORES_PROVIDER_CONFIGURATION = 'providerconfiguration.user',

  // order management claims
  ORDERMANAGEMENT_ORDERS = 'orders.order',
  ORDERMANAGEMENT_FAILEDSUBMISSIONS = 'orders.failedSubmissions',
  ORDERMANAGEMENT_DELIVERYINTEGRATION = 'deliveryintegration',
  PAYMENTS_PAYMENT = 'payments.payment',
  ORDERMANAGEMENT_POSTOTAL = 'orders.POSTotal',
  ORDERMANAGEMENT_UPDATE_CHARGE_AMOUNT = 'orders.CanReplacePosResult',

  // CRM claims
  CUSTOMER_CUSTOMERS = 'crm.customers',
  CUSTOMER_RESEND_EMAILS = 'customeremails.resend',
  CUSTOMER_CREDITS_ALLOCATE = 'customerCredits.allocate',
  CUSTOMER_IMPERSONATE = 'user.impersonate',
  CUSTOMER_USER_PASSWORD = 'user.password',
  CUSTOMER_COMMENTS = 'crm.comments',
  CUSTOMER_BUSINESS_ACCOUNTS = 'crm.businessAccounts',
  CUSTOMER_VIEW_SUBSCRIPTIONS_CLAIM = 'subscriptions.customersubscriptions',
  CUSTOMER_CANCEL_SUBSCRIPTIONS_CLAIM = 'subscriptions.customersubscriptionscancel',

  // order fulfilment claims
  ORDER_FULFILLMENT = 'orderFulfillment.fulfillOrders',
  ORDER_FULFILLMENT_ORDERS = 'orderFulfillment.orders',
  ORDER_FULFILLMENT_PERSONAL_SHOPPERS = 'orderFulfillment.personalShopper',
  ORDER_FULFILLMENT_LOCATIONS = 'orderFulfillment.productLocations',
  ORDER_FULFILLMENT_PRODUCTION_CENTERS = 'orderFulfillment.orderFulfilment.productionCenters',
  ORDER_FULFILLMENT_PRODUCTION_REPORTS = 'orderFulfillment.orderFulfilment.reports.productionCenters',
  ORDER_FULFILLMENT_REPORTS = 'orderFulfillment.Reports.personalShopper',
  ORDER_FULFILLMENT_SETUP = 'orderFulfillment.setup',
  ORDER_FULFILLMENT_FULFILLMENT_CENTERS = 'orderFulfillment.fulfillmentCenters',
  // site admin claims
  SITEADMIN_USER = 'siteadmin.user',
  SITEADMIN_ROLE = 'siteadmin.role',

  // search claims
  SEARCH_RELEVANCE = 'searchadmin.relevance',
  SEARCH_PREVIEW = 'searchadmin.preview',
  SEARCH_SYNONYMS = 'searchadmin.synonyms',
  SEARCH_RULES = 'searchadmin.rules',
  SEARCH_SETTINGS = 'searchadmin.settings',

  PRODUCTS_PRODUCT = 'products.product',
  PRODUCTS_CATEGORY = 'products.category',
  PRODUCTS_ATTRIBUTE = 'products.attribute',
  PRODUCT_MANAGE_OUT_OF_STOCK = 'products.manageoutofstock',
  PRODUCT_MANAGE_OUT_OF_STOCK_UNTIL = 'products.manageoutofstockuntil',
  PRODUCT_REQUEST_PRODUCT_RESEND = 'products.requestProductResend',
  PRODUCTS_MANGE_PRICING = 'products.managepricing',
  PRODUCTS_MANAGE_UNIT_PRICE = 'products.manageunitofprice',
  PRODUCTS_MANAGE_CUSTOMIZATIONS = 'products.productConfigurations',

  // Merchandising
  MERCHANDISING_RECOMMENDATIONS = 'recommendations.recommendation',
  MERCHANDISING_RECIPES = 'recipes.recipe',
  RECIPES_GLOBAL = 'recipes.global',
  RECOMMENDATION_LISTING = 'recommendations.listing',
  RECOMMENDATIONS_AND_LISTINGS_GLOBAL = 'recommendations.global',

  // Promotions
  PROMOTIONS_PROMOCODES = 'promocodes.promocodes',
  PROMOTIONS_PROMOTIONS = 'promotions.promotions',
  PROMOTIONS_GLOBAL = 'promotions.global',

  // Single Sign On claim
  SITESETTINGS_SINGLE_SIGN_ON = 'disable.native.admin.login',

  // Site settings claims
  SITESETTINGS_IDENTITYPROVIDER = 'identityprovider',
  SITESETTINGS_DELIVERYSOLUTIONS = 'deliverysolutions',
  SITESETTINGS_DELIVERYPROVIDERS = 'siteSettings.deliveryProviders',
  SITESETTINGS_SEGMENTATION = 'segmentation.rw',

  // Webhook claims
  SITESETTINGS_WEBHOOKS = 'webhooks.customer',

  // Order Preferences claims
  SITESETTINGS_ORDERPREFRENCES = 'discountsandcharges.discountsandcharges',

  // Shopping modes
  SHOPPINGMODE_ADMIN = 'shoppingmodesadmin.shoppingmode',
  SHOPPINGMODE_ADMIN_STORES = 'shoppingmodesadmin.storeshoppingmode',

  // Reports
  REPORTING_REPORTS = 'reporting.reports',
  REPORTING_REPORTS_CANCELLED_ORDERS = 'reporting.reports.cancelled_orders',
  REPORTING_REPORTS_ORDER_DELIVERY = 'reporting.reports.order_delivery',
  REPORTING_REPORTS_SALES = 'reporting.reports.sales',
  REPORTING_REPORTS_ORDERED_PRODUCTS = 'reporting.reports.ordered_products',
  REPORTING_REPORTS_PAYMENT_RECONCILIATION = 'reporting.reports.payment_reconciliation',
  REPORTING_REPORTS_TODAYS_PROJECTED = 'reporting.reports.todays_projected_sales_and_orders',
  REPORTING_REPORTS_PICKED_ON_TIME = 'reporting.reports.picked_on_time',
  REPORTING_REPORTS_BLOCKED_AND_CLOSED_SLOTS = 'reporting.reports.blocked_and_closed_slots',
  REPORTING_REPORTS_FUTURE_ORDERS = 'reporting.reports.future_orders',
  REPORTING_REPORTS_SLOT_CAPACITY = 'reporting.reports.slot_capacity',
  REPORTING_REPORTS_SLOT_UTILIZATION = 'reporting.reports.slot_utilization',
  REPORTING_REPORTS_MPS_AUDIT = 'reporting.reports.mps_audit',
  REPORTING_REPORTS_MPS_DAILY_OOS = 'reporting.reports.mps_daily_out_of_stock',
  REPORTING_REPORTS_MPS_PICKER_PERFORMANCE = 'reporting.reports.mps_picker_performance',
  REPORTING_REPORTS_MPS_PRODUCT_AVAILABILITY = 'reporting.reports.mps_product_availability',
  REPORTING_REPORTS_MPS_TOP_OOS = 'reporting.reports.mps_top_out_of_stock',
}

export enum UserAccessRights { // future access rights should be added here
  ReadWrite = 'rw',
  Read = 'r',
}

// in reality this should be a union type because there are claims
// which are not acces-right. e.g "email", "fullname"...etc
export type UserProfile = {
  [p in AuthorizationClaims]+?: UserAccessRights;
} & { sub: string };

export type ClaimDescriptions = {
  [p in AuthorizationClaims]: string;
};

export type AuthAccessClaimRights = {
  [p in AuthorizationClaims]+?: UserAccessRights;
};
export type AuthAccessClaim = AuthorizationClaims | AuthAccessClaimRights;
