import {
  API_CLIENT_CLEAR_STATE,
  API_CLIENT_DELETE_DATA,
  API_CLIENT_STORE_REQUEST,
  API_CLIENT_UPDATE_STATE,
  API_CLIENT_IS_FETCHING_SET,
  API_CLIENT_FORCE_STATE_UPDATE,
} from './actions';

const initialState = {};
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case API_CLIENT_STORE_REQUEST: {
      return {
        ...state,
        [payload.name]: {
          ...payload,
        },
      };
    }
    case API_CLIENT_DELETE_DATA: {
      const newState = { ...state };
      delete newState[payload.name];

      return newState;
    }
    case API_CLIENT_UPDATE_STATE: {
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          ...payload,
        },
      };
    }
    case API_CLIENT_IS_FETCHING_SET: {
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          isFetching: payload.isFetching,
        },
      };
    }
    case API_CLIENT_CLEAR_STATE: {
      return {
        ...state,
        [payload.name]: {},
      };
    }
    // this is a temporary implementation awaiting the switch to SignalR or any other real-time notification system
    case API_CLIENT_FORCE_STATE_UPDATE: {
      const newState = {
        ...state,
      };
      let targetObject = newState;
      while (payload.path.length) {
        const path = payload.path.shift();
        if (targetObject[path]) {
          targetObject = targetObject[path];
        }
      }

      payload.process(targetObject);
      return newState;
    }
    default: {
      return { ...state };
    }
  }
};
