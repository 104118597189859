import * as ACTIONS from './actions.constants';

export const setInitialValues = (calendarName: string, events: any, action?:any) => ({
  type: ACTIONS.CALENDAR_SET_INITIAL_EVENTS,
  payload: {
    calendarName,
    events,
    action
  }
});
export const setLoader = (calendarName: string, isLoading: boolean) => ({
  type: ACTIONS.CALENDAR_SET_LOADER,
  payload: {
    calendarName,
    isLoading
  }
});

export const addEvent = (calendarName: string, event: any) => ({
  type: ACTIONS.CALENDAR_ADD_EVENT,
  payload: {
    calendarName,
    event
  }
});
export const addEvents = (calendarName: string, events: any = []) => ({
  type: ACTIONS.CALENDAR_ADD_EVENTS,
  payload: {
    calendarName,
    events
  }
});
export const removeEvent = (calendarName: string, eventId: string) => ({
  type: ACTIONS.CALENDAR_REMOVE_EVENT,
  payload: {
    calendarName,
    eventId
  }
});

export const reset = (calendarName: string) => ({
  type: ACTIONS.CALENDAR_RESET_VALUES,
  payload: {
    calendarName
  }
});

export const clearSelectedEvent = (calendarName: string) => ({
  type: ACTIONS.CALENDAR_CLEAR_SELECTOR_EVENT,
  payload: {
    calendarName
  }
});

export const clear = (calendarName: string) => ({
  type: ACTIONS.CALENDAR_CLEAR_VALUES,
  payload: {
    calendarName
  }
});
export const clearEvents = (calendarName: string) => ({
  type: ACTIONS.CALENDAR_CLEAR_EVENTS,
  payload: {
    calendarName
  }
});

// Actions used for internal porpouse, to update selectors
export const updateSelectorDate = (calendarName: string, newDate: Date) => ({
  // It will show a new date on reducer, but not change the calendar day
  type: ACTIONS.CALENDAR_UPDATE_SELECTOR_DATE,
  payload: {
    calendarName,
    newDate
  }
});

export const updateSelectorView = (calendarName: string, view: string) => ({
  type: ACTIONS.CALENDAR_UPDATE_SELECTOR_VIEW,
  payload: {
    calendarName,
    view
  }
});

export const updateSelectorEvent = (calendarName: string, eventId: string) => ({
  // It will show last selected Event on reducer, but not change the Event
  type: ACTIONS.CALENDAR_UPDATE_SELECTOR_EVENT,
  payload: {
    calendarName,
    eventId
  }
});
