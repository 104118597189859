export enum ProductTranslations {
  Products = 'navigation.products',
  View = 'generic.options.view',
  ProductList = 'modules.products.productList',
  ProductName = 'modules.products.productName',
  Categories = 'modules.products.categories',
  CountCategories = 'modules.products.countCategories',
  Status = 'modules.products.status',
  Actions = 'generic.actions',
  FilterBySku = 'modules.products.filterBySku',
  FilterByBarcode = 'modules.products.filterByBarcode',
  FilterByCategories = 'modules.products.filterByCategories',
  FilteredBy = 'generic.filteredBy',
  ClearFilters = 'generic.clearFilters',
  MoreFilterOptions = 'generic.moreFilterOptions',
  Cancel = 'generic.options.cancel',
  SelectAll = 'generic.selectAll',
  FilterByStatus = 'generic.filterByStatus',
  Active = 'generic.status.active',
  Inactive = 'generic.status.inactive',
  ProductInformation = 'modules.products.productInformation',
  ProductDetails = 'modules.products.productDetails',
  ProductNameHint = 'modules.products.productNameHint',
  SKU = 'modules.products.sku',
  SkuHint = 'modules.products.skuHint',
  Barcode = 'modules.products.barcode',
  BarcodeHint = 'modules.products.barcodeHint',
  AddBarcode = 'modules.products.addBarcode',
  Description = 'modules.products.description',
  DescriptionHint = 'modules.products.descriptionHint',
  Brand = 'modules.products.brand',
  BrandHint = 'modules.products.brandHint',
  CountryOfOrigin = 'modules.products.countryOfOrigin',
  CountryOfOriginHint = 'modules.products.countryOfOriginHint',
  Content = 'modules.products.content',
  ProductImage = 'modules.products.productImage',
  ProductImageHint = 'modules.products.productImageHint',
  FirstImage = 'modules.products.firstImage',
  NoImageSelected = 'modules.products.noImageSelected',
  UploadNewImage = 'modules.products.uploadNewImage',
  UseExisting = 'modules.products.useExisting',
  Units = 'modules.products.units',
  SaleType = 'modules.products.saleType',
  SaleTypeHint = 'modules.products.saleTypeHint',
  Size = 'modules.products.size',
  Unit = 'modules.products.unit',
  UnitHint = 'modules.products.unitHint',
  CategoryHierarchies = 'modules.products.categoryHierarchies',
  CategoryHierarchiesHint = 'modules.products.categoryHierarchiesHint',
  AddToHierarchy = 'modules.products.addToHierarchy',
  NoCategories = 'modules.products.noCategories',
  CategoryHierarchy = 'modules.products.categoryHierarchy',
  CategoryLocations = 'modules.products.categoryLocations',
  DefaultCategory = 'modules.products.defaultCategory',
  MakeDefaultCategory = 'modules.products.makeDefaultCategory',
  Continue = 'generic.button.continue',
  Ingredients = 'modules.products.ingredients',
  ListOfIngredients = 'modules.products.listOfIngredients',
  IngredientsHint = 'modules.products.ingredientsHint',
  IngredientsHint2 = 'modules.products.ingredientsHint2',
  Nutrition = 'modules.products.nutrition',
  SuggestedServingSize = 'modules.products.suggestedServingSize',
  SuggestedServingSizeHint = 'modules.products.suggestedServingSizeHint',
  NumberOfServings = 'modules.products.numberOfServings',
  NumberOfServingsHint = 'modules.products.numberOfServingsHint',
  AddNutritionalValues = 'modules.products.addNutritionalValues',
  ValuesPerWeightSize = 'modules.products.valuesPerWeightSize',
  NutrientName = 'modules.products.nutrientName',
  Value = 'modules.products.value',
  DailyValues = 'modules.products.dailyValues',
  NutritionTableDisclaimer = 'modules.products.nutritionTableLayoutDisclaimer',
  NoNutritionalValues = 'modules.products.noNutritionalValues',
  SaveInformationContinueToPricing = 'modules.products.saveInformationContinueToPricing',
  SaveError = 'modules.products.saveError',
  SaveSuccess = 'generic.data.savedSuccess',
  ErrorConflictingSku = 'modules.products.errorConflictingSku',
  NotFound = 'modules.products.notFound',
  CreateNewProduct = 'modules.products.createNewProduct',
  BackToList = 'modules.products.backToList',
  AllProducts = 'modules.products.allProducts',
  ProductManagement = 'modules.products.productManagement',
  EditProduct = 'modules.products.editProduct',
  Product = 'modules.products.product',
  ManageAttributeOption = 'modules.products.manageAttributeOption',
  ManageOutOfStock = 'modules.products.manageOutOfStock',
  ManageOutOfStockUntil = 'modules.products.manageOutOfStockUntil',
  ManageAttributePerStore = 'modules.products.manageAttributePerStore',
  ManagePricing = 'modules.products.managePricing',
  ManageUnitPrice = 'modules.products.manageUnitPrice',
  ManageCustomizations = 'modules.products.manageCustomizations',
  ViewProducts = 'modules.products.viewProducts',
  IndividualAttributesModalHeader = 'modules.products.manageIndividualAttributesModal.header',
  Message01 = 'modules.products.manageIndividualAttributesModal.message_01',
  Message02 = 'modules.products.manageIndividualAttributesModal.message_02',
  Message03 = 'modules.products.manageIndividualAttributesModal.message_03',
  Message04 = 'modules.products.manageIndividualAttributesModal.message_04',
  ReferenceName = 'modules.products.manageIndividualAttributesModal.referenceName',
  AssignedStore = 'modules.products.manageIndividualAttributesModal.assignedStore',
  SelectAttribute = 'modules.products.manageIndividualAttributesModal.selectAttribute',
  UseTimePicker = 'modules.products.manageIndividualAttributesModal.useTimePicker',
  UseTextInput = 'modules.products.manageIndividualAttributesModal.useTextInput',
  Save = 'modules.products.manageIndividualAttributesModal.save',
  CurrentValues = 'modules.products.productHierarchy.currentValues',
  HeaderButtonIndividual = 'modules.products.manageIndividualAttributesModal.headerButtonIndividual',
  HeaderButtonStore = 'modules.products.manageIndividualAttributesModal.headerButtonStore',
  Message05 = 'modules.products.manageIndividualAttributesModal.message_05',
  Message06 = 'modules.products.manageIndividualAttributesModal.message_06',
  StoreSelectExplainer = 'modules.products.manageIndividualAttributesModal.storeSelectExplainer',
  AttributesByStore = 'modules.products.manageIndividualAttributesModal.attributesByStore',
  Store = 'modules.products.manageIndividualAttributesModal.store',
  PerStoreValue = 'modules.products.manageIndividualAttributesModal.perStoreValue',
  AddAttribute = 'modules.products.manageIndividualAttributesModal.addAttribute',
  DefaultValue = 'modules.products.manageIndividualAttributesModal.defaultValue',
  StoreOverrideNotification = 'modules.products.manageIndividualAttributesModal.storeOverrideNotification',
  IndividualOverrideNotification = 'modules.products.manageIndividualAttributesModal.individualOverrideNotification',
  AttributesConfirmationModalCaption = 'modules.products.manageIndividualAttributesModal.attributesConfirmationModal.caption',
  AttributesConfirmationModalWarning = 'modules.products.manageIndividualAttributesModal.attributesConfirmationModal.warning',
  AttributesConfirmationModalStoreColumn = 'modules.products.manageIndividualAttributesModal.attributesConfirmationModal.storeColumn',
  AttributesConfirmationModalCurrentValueColumn = 'modules.products.manageIndividualAttributesModal.attributesConfirmationModal.currentValueColumn',
  AttributesConfirmationModalSave = 'modules.products.manageIndividualAttributesModal.attributesConfirmationModal.save',
  AttributesConfirmationModalCancel = 'modules.products.manageIndividualAttributesModal.attributesConfirmationModal.cancel',
  RequestProductResend = 'modules.products.requestProductResend',
  BrandbankControls = 'modules.products.brandbankControls',
  RunRulesEvaluator = 'modules.products.runRulesEvaluator',
  ExpectedResult = 'modules.products.expectedResult',
  Customization = 'modules.products.customizationsTab.customization',
  Customizations = 'modules.products.customizationsTab.customizations',
  Option = 'modules.products.customizationsTab.option',
  Options = 'modules.products.customizationsTab.options',
  Choices = 'modules.products.customizationsTab.choices',
  EditOptionSequence = 'modules.products.customizationsTab.editOptionSequence',
  UpdateSequence = 'modules.products.customizationsTab.updateSequence',
  EditOptionSequenceMessage = 'modules.products.customizationsTab.editOptionSequenceMessage',
  OptionsMessage = 'modules.products.customizationsTab.optionsMessage',
  ChoicesMessage = 'modules.products.customizationsTab.choicesMessage',
  Instruction = 'modules.products.customizationsTab.instruction',
  InstructionExplainer = 'modules.products.customizationsTab.instructionExplainer',
  ChoiceSelection = 'modules.products.customizationsTab.choiceSelection',
  ChoiceSelectionExplainer = 'modules.products.customizationsTab.choiceSelectionExplainer',
  RequiredOption = 'modules.products.customizationsTab.requiredOption',
  RequiredOptionExplainer = 'modules.products.customizationsTab.requiredOptionExplainer',
  ChoiceName = 'modules.products.customizationsTab.choiceName',
  ChoiceNameExplainer = 'modules.products.customizationsTab.choiceNameExplainer',
  EmptyChoicesMessage = 'modules.products.customizationsTab.emptyChoicesMessage',
  ChoiceDescription = 'modules.products.customizationsTab.description',
  DescriptionExplainer = 'modules.products.customizationsTab.descriptionExplainer',
  AdditionalCost = 'modules.products.customizationsTab.additionalCost',
  AdditionalCostExplainer = 'modules.products.customizationsTab.additionalCostExplainer',
  Image = 'modules.products.customizationsTab.image',
  ImageExplainer = 'modules.products.customizationsTab.imageExplainer',
  MinChoicesMessage = 'modules.products.customizationsTab.minChoicesMessage',
  ImageNameCaption = 'modules.products.customizationsTab.imageNameCaption',
  AddChoice = 'modules.products.customizationsTab.addChoice',
  UpdateChoice = 'modules.products.customizationsTab.updateChoice',
  SingleSelect = 'modules.products.customizationsTab.selection',
  MultiSelect = 'modules.products.customizationsTab.selectionMulti',
  TextField = 'modules.products.customizationsTab.text',
  MinChoices = 'modules.products.customizationsTab.minChoices',
  MinChoicesExplainer = 'modules.products.customizationsTab.minChoicesExplainer',
  MaxChoices = 'modules.products.customizationsTab.maxChoices',
  MaxChoicesExplainer = 'modules.products.customizationsTab.maxChoicesExplainer',
  MaxErrorMessage = 'modules.products.customizationsTab.maxErrorMessage',
  MaxCharacterCount = 'modules.products.customizationsTab.maxCharacterCount',
  MaxCharacterCountExplainer = 'modules.products.customizationsTab.maxCharacterCountExplainer',
  Rule = 'modules.products.rule',
  Result = 'modules.products.result',
  Pricing = 'modules.products.pricingTab.pricing',
  Price = 'modules.products.pricingTab.price',
  UnitOfPrice = 'modules.products.pricingTab.unitOfPrice',
  UnitOfPriceMessage = 'modules.products.pricingTab.unitOfPriceMessage',
  UnitOfPriceTypeExplainer = 'modules.products.pricingTab.unitOfPriceTypeExplainer',
  UnitOfPriceSizeExplainer = 'modules.products.pricingTab.unitOfPriceSizeExplainer',
  Type = 'modules.products.pricingTab.type',
  PricePerStore = 'modules.products.pricingTab.pricePerStore',
  Message01Price = 'modules.products.pricingTab.message01',
  TemporaryPrice = 'modules.products.pricingTab.temporaryPrice',
  Label = 'modules.products.pricingTab.label',
  StartTime = 'modules.products.pricingTab.startTime',
  EndTime = 'modules.products.pricingTab.endTime',
  AddPrice = 'modules.products.pricingTab.addPrice',
  EditPrice = 'modules.products.pricingTab.editPrice',
  DeletePrice = 'modules.products.pricingTab.deletePrice',
  DoNotDeletePrice = 'modules.products.pricingTab.doNotDeletePrice',
  DeletePriceAreYouSure = 'modules.products.pricingTab.deletePriceAreYouSure',
  ActionCannotBeUndone = 'generic.actionCannotBeUndone',
  PricingUpdate = 'modules.products.pricingTab.pricingUpdate',
  PricingUpdateMessage = 'modules.products.pricingTab.pricingUpdateMessage',
  ProductPricingUpdateSuccess = 'modules.products.pricingTab.productPricingUpdateSuccess',
  ProductPricingUpdateError = 'modules.products.pricingTab.productPricingUpdateError',
  PriceExplainer = 'modules.products.pricingTab.priceModal.priceExplainer',
  TempPrices = 'modules.products.pricingTab.priceModal.tempPrices',
  AddTempPrice = 'modules.products.pricingTab.priceModal.addTempPrice',
  EditTempPrice = 'modules.products.pricingTab.priceModal.editTempPrice',
  DeleteTempPrice = 'modules.products.pricingTab.priceModal.deleteTempPrice',
  TempPricesMessage01 = 'modules.products.pricingTab.priceModal.tempPricesMessage01',
  TempPricesEmpty = 'modules.products.pricingTab.priceModal.tempPricesEmpty',
  TPRDateError = 'modules.products.pricingTab.priceModal.tprDateError',
  TPRDateInvalidEndTime = 'modules.products.pricingTab.priceModal.tprDateInvalidEndTime',
  BulkUpdate = 'modules.products.pricingTab.priceModal.bulkUpdate',
  BulkUpdatePriceMessage = 'modules.products.pricingTab.priceModal.bulkUpdatePriceMessage',
  ChangeProductStatus = 'modules.products.productStatusModal.id',
  ProductStatusModalTitle = 'modules.products.productStatusModal.title',
  ProductStatusModalWarning = 'modules.products.productStatusModal.warning',
  Activate = 'generic.options.StatusChangeToActive',
  Deactivate = 'generic.options.StatusChangeToInactive',
}
