import { useState, useEffect } from 'react';
import { IFetchDataRequest } from 'framework/api/fetch-data';
import { useEffectSkipFirstRun } from 'helpers/hooks';

export interface SkipTake {
  skip: number;
  take: number;
}

interface IFetchDataRequestWithSkipTake extends IFetchDataRequest {
  query: {
    [n:string]: any;
    skip: number;
    take: number;
  }
}

export type OnPageChangedType = (pagination: SkipTake) => void;

export const defaultSkipTake = { skip: 0, take: 10 } as SkipTake;

export const usePagination = (
  requestDetails: IFetchDataRequest,
  skipTake: SkipTake = defaultSkipTake,
  onPageChanged?: OnPageChangedType,
) => {
  const [recordCount, setRecordCount] = useState<number>(0);
  const [requestWithPagination, setRequestWithPagination] = useState({
    ...requestDetails,
    query: {
      ...requestDetails.query,
      ...skipTake,
    },
  });

  const setRequestWithPaginationProxy = (req: IFetchDataRequestWithSkipTake) => {
    setRequestWithPagination(req);
    if (onPageChanged) {
      onPageChanged({ skip: req.query.skip, take: req.query.take });
    }
  };

  const getNewPagination = (newSkip: number, newTake: number) => ({
    ...requestWithPagination,
    query: {
      ...requestWithPagination.query,
      skip: newSkip,
      take: newTake ?? requestWithPagination.query.take,
    },
  });

  /**
   * Resets pagination to the first page when the request changes (i.e.: filters were applied)
   */
  useEffectSkipFirstRun(() => {
    setRequestWithPaginationProxy({
      ...requestDetails,
      query: {
        ...requestDetails.query,
        take: requestWithPagination.query.take,
        skip: 0,
      },
    });
  }, [requestDetails, skipTake]);

  const onNext = () => {
    const { skip, take } = requestWithPagination.query;
    setRequestWithPaginationProxy(getNewPagination(skip + take, undefined));
  };

  const onPrevious = () => {
    const { skip, take } = requestWithPagination.query;
    setRequestWithPaginationProxy(getNewPagination(skip - take, undefined));
  };

  const onGetPage = (page: number) => {
    if (!Number.isNaN(page) && page > 0) {
      setRequestWithPaginationProxy(
        getNewPagination((page - 1) * requestWithPagination.query.take, undefined),
      );
    }
  };

  useEffect(() => {
    setRequestWithPaginationProxy(
      getNewPagination(skipTake.skip, skipTake.take),
    );
  }, [skipTake]);


  return {
    setRecordCount,
    requestWithPagination,
    paginationProps: {
      recordCount,
      onNext,
      onPrevious,
      onGetPage,
      pageSize: requestWithPagination.query.take,
      currentPage:
        (requestWithPagination.query.skip + requestWithPagination.query.take)
        / requestWithPagination.query.take,
    },
  };
};
