import * as React from 'react';
import { Select } from 'ui';
import { featureFlag } from 'featureFlag';
import ContactSupport from './ContactSupport';
import { IDropdownInterface } from '../StoreFrontSettings.interfaces';
// eslint-disable-next-line import/no-cycle
import { selectedOptions } from './JsonUiGenerator';

const DropdownType = (props: IDropdownInterface) => {
  const { component, handleChange, field, value, id, bypassValueCheck, required } = props;

  const hasValue = (value !== undefined && value !== null) || bypassValueCheck;

  const [shouldSaveNumber] = React.useState(typeof value === 'number');

  const handleDropdownChange = (e) => {
    const valueToSave = shouldSaveNumber ? Number(e.value) : e.value;
    handleChange(field, valueToSave);
  };

  const options = component.options ?? component.uniqueOptions;

  const generateValues = () =>
    options
      .map((option) => ({
        value: option.value ?? (option as any).toString(),
        label: option.label ?? (option as any).toString(),
      }))
      .filter((option) => !selectedOptions?.options?.map((item) => item.provider).includes(option.value));

  return (
    <>
      {!hasValue && <ContactSupport />}
      <Select
        name={`${id}-${field}`}
        caption={component.title}
        clearable={false}
        searchable={false}
        onChange={handleDropdownChange}
        options={generateValues()}
        initValue={value}
        multi={false}
        menuPlacement="top"
        explainerText={component.description}
        {...featureFlag({
          support: () => ({
            ...(component.helpLink && {
              explainerLink: {
                caption: 'generic.readMore',
                to: component.helpLink,
                external: true,
              },
            }),
          }),
          prod: () => {},
        })}
        disabled={!hasValue}
        mandatory={required}
        grid
      />
    </>
  );
};

export default DropdownType;
