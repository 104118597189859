import * as React from 'react';
import { debounce } from 'lodash';
import i18n from 'i18n';
// eslint-disable-next-line import/no-cycle
import { Text, Pill, Button } from '../../..';
import { prefixClassName } from '../../../_conf';
import { useFilterBarContext, FilterState } from '../../context';

const FILTERED_BY = 'generic.filteredBy';
const CLEAR_FILTERS = 'generic.clearFilters';

interface FilteredByProps<T> {
  items?: T[];
  className?: string;
  toggle?: boolean;
  onToggle?: (index: number, item?: T) => void;
  getIsActive?: (item: T) => boolean;
  filteredByCaption?: string;
  clearAllCaption?: string;
  showClearAll?: boolean;
  showClose?: boolean;
  getDisplayName: (item: T) => string;
  onItemsChanged: (newItems: T[]) => void;
  getIsSelected?: (item: T) => boolean;
  setExternalState?: (item: T[]) => void;
}

const clx = prefixClassName('filter-bar__filtered-by');
const cssMaxHeight = 65;
const checkOverflow = (el: HTMLElement) => (el.clientHeight > cssMaxHeight ? true : el.clientHeight < el.scrollHeight);

const FilteredBy = <T extends {}>({
  className = '',
  filteredByCaption = FILTERED_BY,
  clearAllCaption = CLEAR_FILTERS,
  showClearAll = true,
  showClose = true,
  getDisplayName,
  items = [],
  toggle = false,
  onToggle,
  getIsActive = () => true,
  onItemsChanged,
  getIsSelected = () => false,
  setExternalState = null,
}: FilteredByProps<T>) => {
  const { state: filterBarState } = useFilterBarContext();
  const showFilteredBy = filterBarState === FilterState.Collapsed;
  const [state, setState] = React.useState({
    hasOverflow: false,
    showingAll: false,
  });
  const containerRef = React.useRef(undefined);
  const clearItem = (item) => {
    const filteredItem = items.filter((i) => i !== item);
    onItemsChanged(filteredItem);
    if (setExternalState) setExternalState(filteredItem);
  };
  const clearAll = () => {
    onItemsChanged([]);
    if (setExternalState) setExternalState([]);
  };

  const doSizeChecks = debounce(() => {
    if (containerRef.current) {
      let newHasOverflow = state.hasOverflow;
      let resetShowAll = false;
      newHasOverflow = checkOverflow(containerRef.current);

      if (state.hasOverflow !== newHasOverflow && !newHasOverflow) {
        resetShowAll = true;
      }

      setState((p) => ({
        hasOverflow: newHasOverflow,
        showingAll: resetShowAll ? false : p.showingAll,
      }));
    }
  }, 300);

  React.useEffect(() => {
    if (items.length) {
      doSizeChecks();
      window.addEventListener('resize', doSizeChecks);
      // hook clean up function
      return () => {
        window.removeEventListener('resize', doSizeChecks);
      };
    }
    // if all items were removed, resets toogle state
    setState({
      hasOverflow: false,
      showingAll: false,
    });
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, items]);

  const toogle = () => {
    setState((p) => ({ ...p, showingAll: !p.showingAll }));
    containerRef.current.scroll(0, 0);
  };

  return (
    <>
      {showFilteredBy && items.length > 0 && (
        <div ref={containerRef} className={`${clx} ${className} ${state.showingAll ? `${clx}--show-all` : ''}`}>
          <Text tag="span" caption={filteredByCaption} className={`${clx}__label`} />
          {items.map((item, i) => (
            <Pill
              disabled={!getIsActive(item)}
              caption={getDisplayName(item)}
              key={i}
              onClose={showClose && (() => clearItem(item))}
              toggle={toggle}
              onToggle={onToggle ? () => onToggle(i, item) : null}
              className={getIsSelected(item) && `${clx}--isSelected`}
            />
          ))}
          <span className={`${clx}__links ${state.hasOverflow ? `${clx}__links--overflow` : ''}`}>
            {state.hasOverflow && (
              <Button
                className={`${clx}__links__link`}
                caption={state.showingAll ? 'generic.showLess' : i18n.t('generic.showAllX', { x: items.length })}
                onClick={toogle}
                styleless
              />
            )}
            {showClearAll && (
              <Button className={`${clx}__links__link`} caption={clearAllCaption} onClick={clearAll} styleless />
            )}
          </span>
        </div>
      )}
    </>
  );
};

export { FilteredBy };
