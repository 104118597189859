import { CmsPageHierarchyFolderInfo } from '../pages/CmsAllPages/CmsAllPages.interfaces';
import uuid from 'uuid/v4';

export const formatPagesHierarchy = (data: CmsPageHierarchyFolderInfo[]): any[] => {
  const items = data.reduce(
    (acc: CmsPageHierarchyFolderInfo[], item: CmsPageHierarchyFolderInfo) => {
      const currentItem = { ...item };
      // tslint:disable-next-line
      acc = [...acc, currentItem];
      if (currentItem.children && currentItem.children.length) {
        currentItem.children = [...formatPagesHierarchy(currentItem.children)];
      }

      if (currentItem.value.variants && currentItem.value.variants.length) {
        const defaultVariants = currentItem.value.variants.filter(v => v.isDefault);

        defaultVariants.forEach(defaultVariant => {
          const defaultNode = addVariantNode({
            id: uuid(),
            isGroup: true,
            variant: defaultVariant
          });
          defaultNode.children = [];
          currentItem.children = [...currentItem.children, defaultNode];
          const variants = currentItem.value.variants.filter(v => {
            return v.documentId === defaultVariant.documentId && !v.isDefault;
          });
          defaultNode.variantCount = variants.length;
          variants.forEach(v => {
            const variantNode = addVariantNode({
              id: uuid(),
              isGroup: false,
              variant: v
            });
            defaultNode.children = [...defaultNode.children, variantNode];
          });
        });
      }
      return acc;
    },
    []
  );
  return [...items];
};

const addVariantNode = ({ id, isGroup, variant }) => {
  const { documentId, variantId, displayName, ...rest } = variant;

  return {
    id,
    documentId,
    variantId,
    value: {
      description: displayName,
      ...rest
    },
    isVariant: !isGroup,
    isGroup
  } as any;
};
