import * as classnames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IGoToTopProps, IGoToTopState } from './GoToTop.interface';
import { Icon } from 'ui';
import './_style.scss';

const clx = prefixClassName('goToTop');

class GoToTop extends React.PureComponent<IGoToTopProps, IGoToTopState> {
  static defaultProps: IGoToTopProps = {
    isVisible: false
  };

  constructor(props: IGoToTopState) {
    super(props);
    this.state = {
      isVisible: props.isVisible
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.setVisibility);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setVisibility);
  }

  setVisibility = () => {
    const _body = document.body; // IE 'quirks'
    let D = document.documentElement; // IE with doctype
    D = D.clientHeight ? D : _body;

    if (D.scrollTop === 0) {
      this.setState({
        isVisible: false
      });
    } else if (!this.state.isVisible) {
      this.setState({
        isVisible: true
      });
    }
  };

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    const { className } = this.props;
    const { isVisible } = this.state;

    return (
      <div
        onClick={this.scrollToTop}
        className={classnames(clx, isVisible && `${clx}--isVisible`, className)}
      >
        <Icon name="dropdownArrowUp" className={`${clx}__icon`} />
      </div>
    );
  }
}

export default GoToTop;
