import * as React from 'react';
import { Button } from 'ui';
import { Uuid } from 'framework/utils/generateId';
import { WrappedFieldArrayProps } from 'redux-form';
import { useModal } from 'framework/components/ui/Modal/useModal';
import { ModalProductSearch } from 'framework/modules/searchadmin/components/ModalProductSearch';
import {
  openProductSearchModal,
  closeProductSearchModal,
} from 'framework/modules/searchadmin/components/ModalProductSearch/constants';
import { ProductScore } from 'framework/modules/searchadmin/models/product';
import { CategoryHierarchyNode } from 'framework/modules/searchadmin/components/ModalCategorySearch/types';
import ActionsHideComponent from './ActionsHideComponent.template';
import { CATEGORIES_MODAL, MODAL_PRODUCTSEARCH_HIDE } from '../../../../../constants';
import { CategorySearchModal } from '../../../../../components/ModalCategorySearch/CategorySearchModal';
import { ActionFieldType } from './constants';

interface Props extends WrappedFieldArrayProps<{
  product?: {
    value: string;
    label: string;
  };
  categoryBreadCrumb?: {
    value: string;
    label: string;
    category?: CategoryHierarchyNode;
  };
}> {
  fetchCurrentData: () => void;
  actionField: string;
}

export default (props: Props) => {
  const {
    fields,
    fetchCurrentData,
    actionField,
  } = props;

  const [actionsMounted, setActionsMounted] = React.useState<boolean>(false);
  const [productSearchModalId] = React.useState<string>(`${MODAL_PRODUCTSEARCH_HIDE}${Uuid()}`);

  const [categoriesSearchModalId] = React.useState<string>(`${CATEGORIES_MODAL}${Uuid()}`);
  const { openModal: openCategoriesSelect, closeModal: closeCategoriesSelect } = useModal(categoriesSearchModalId);

  React.useEffect(() => {
    if (actionsMounted && fields.length !== 0) {
      fields.removeAll();
    } else {
      setActionsMounted(true);
    }
  }, [actionField]);

  const addDynamicField = (itemsList: ProductScore[] | CategoryHierarchyNode[]) => {
    fields.removeAll();
    itemsList
      .forEach((p) => {
        if ((actionField === ActionFieldType.Products) && p.productId) {
          fields.push({ product: { value: p.productId, label: `${p.name ? `${p.name} - ` : ''}${p.productId}` } });
        }

        if (actionField === ActionFieldType.Category) {
          fields.push({ categoryBreadCrumb: { value: p.breadCrumb, label: p.breadCrumb, category: p } });
        }
      });
    fetchCurrentData();
  };

  const onDeleteClickHandler = (index: number) => {
    fields.remove(index);
    fetchCurrentData();
  };

  return (
    <>
      { actionField === ActionFieldType.Products
        && (<ModalProductSearch
              name={productSearchModalId}
              onSelectProducts={addDynamicField}
              selected={(fields.getAll() || []).map((p) => ({ productId: p?.product?.value }))}
              closeModal={() => closeProductSearchModal(productSearchModalId)}
            />)
      }
      { actionField === ActionFieldType.Category
        && (<CategorySearchModal
              onSelectCategories={addDynamicField}
              handleClose={closeCategoriesSelect}
              breadCrumbs={(fields.getAll() || []).map((crumb) => crumb?.categoryBreadCrumb?.label)}
              name={categoriesSearchModalId}
            />)
      }
      <div className="mrg-top-10">
        {fields.map((field, index) => (
          <ActionsHideComponent
            key={field}
            field={field}
            actionField={actionField}
            onDelete={() => onDeleteClickHandler(index)}
          />
        ))}
        { actionField && (
          <Button
            onClick={actionField === ActionFieldType.Products
              ? () => openProductSearchModal(productSearchModalId)
              : openCategoriesSelect
            }
            caption={
              actionField === ActionFieldType.Products
                ? 'modules.merchandising.rules.create.btnAddProduct'
                : 'modules.merchandising.rules.create.btnAddCategory'
              }
            outline
          />
        )}
      </div>
    </>
  );
};
