import { formatDateTime } from 'framework/utils/datetimes';
import * as React from 'react';
import { Text } from 'ui';
import { clx } from './constants';
import { SummaryDateItemProps } from './Summary.interfaces';

const DateItem = ({
  label,
  value,
  format,
  ignoreLocalTimeZone,
  className,
  i18nOptions,
}: SummaryDateItemProps) => (
  <div className={`${clx}__item ${className}`}>
    {label && <Text caption={label} i18nOptions={i18nOptions} tag="span" className={`${clx}__item__label`} />}
    <span className={`${clx}__item__value`}>
      {formatDateTime(value, format, null, null, ignoreLocalTimeZone)}
    </span>
  </div>
);

export { DateItem };