import {
  arrayInsert,
  arrayRemove,
  arrayRemoveAll,
  change,
  initialize,
  reset as reduxFormReset,
  startSubmit as reduxFormStartSubmit,
  submit as reduxFormSubmit,
  touch as reduxFormTouch,
} from 'redux-form';
import {
  ICreateSetFormStencilledPropertyActionPayloadProps,
  ICreateSetFormErrorsActionPayloadProps,
  ICreateDeleteFormDataActionPayloadProps,
} from './Form.interface';

export const SET_FORM_IS_STENCILLED_PROPERTY = 'SET_FORM_IS_STENCILLED_PROPERTY';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';
export const DELETE_FORM_DATA = 'DELETE_FORM_DATA';

export function createSetFormIsStencilledPropertyAction(
  payload: ICreateSetFormStencilledPropertyActionPayloadProps,
) {
  return {
    payload,
    type: SET_FORM_IS_STENCILLED_PROPERTY,
  };
}

export function createSetFormErrorsAction(payload: ICreateSetFormErrorsActionPayloadProps) {
  // Example: createSetFormErrorsAction({
  //   name: 'MyFormName',
  //   errrors: { firstName: 'First Name is required', email: ['Email format wrong', 'Email duplicated'] }
  // });
  return {
    payload,
    type: SET_FORM_ERRORS,
  };
}

export function createDeleteFormDataAction(payload: ICreateDeleteFormDataActionPayloadProps) {
  return {
    payload,
    type: DELETE_FORM_DATA,
  };
}

export const startSubmitting = (formName: string) => reduxFormStartSubmit(formName);
export const submit = (formName: string) => reduxFormSubmit(formName);
export const touch = (formName: string) => reduxFormTouch(formName);
export const reset = (formName: string) => reduxFormReset(formName);
export const setValue = (formName: string, fieldName: string, value: any) => change(formName, fieldName, value);
export const initializeValues = (formName: string, values: any) => initialize(formName, values);
export const removeArrayField = (formName: string, fieldName: string, index: number) => (
  arrayRemove(formName, fieldName, index)
);
export const removeAllArrayFields = (formName: string, fieldName: string) => arrayRemoveAll(formName, fieldName);
export const addArrayField = (formName: string, fieldName: string, index: number, value: any) => (
  arrayInsert(formName, fieldName, index, value)
);
