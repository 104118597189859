import * as React from 'react';
import { store, autoEffect, view } from '@risingstack/react-easy-state';
import { RequiredFeatures } from 'framework/components/navigation/Navigation.interfaces';
import config, { baseApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { asyncTimeout } from 'framework/utils/asyncTimeout';
import { appState } from '../app';
import { isDeliveryProviderEnabled } from './deliverySolutions';
import { AppFeatures, DeliveryIntegrationProviders, Features } from './types';
import { isImmediatePicksEnabled } from './quickPicks';
import { isShoppingRulesEnabled } from './shoppingRulesOption';
import { isBrandbankEnabled } from './brandBankIntegration';
import { isZetesIntegrationEnabled } from './zetesIntegration';
import { isWebhooksEnabled } from './webhookCustomers';
import { isSearchRulesMigrated } from './migratedSearchRules';
import { isMultiLingualEnabled } from './multiLingual';
import { isBusinessAccountMultipleStoresEnabled } from './businessAccountMultiipleStores';
import { isCustomersV2Enabled } from './customersV2';
import { isRecommendationsActionFlagsEnabled } from './recommendationsActionFlags';
import { segmentationIsEnabled } from './segmentationIsEnabled';

export const featuresState = store<AppFeatures>({
  [Features.DeliverySolutions]: {
    isEnabled: false,
  },
  [Features.CMSFreeFormAllowScript]: {
    isEnabled: window.appSettings?.cmsFreeFormAllowScript,
  },
  [Features.FlippEnabled]: {
    isEnabled: window.appSettings?.flyerCmsComponentEnabled,
  },
  [Features.ImmediatePicks]: {
    isEnabled: false,
  },
  [Features.ShoppingRulesOption]: {
    isEnabled: false,
  },
  [Features.Brandbank]: {
    isEnabled: false,
    data: {},
  },
  [Features.ZetesIntegration]: {
    isEnabled: false,
  },
  [Features.Webhooks]: {
    isEnabled: false,
  },
  [Features.SearchRulesMigrated]: {
    isEnabled: false,
  },
  [Features.MultiLingual]: {
    isEnabled: false,
  },
  [Features.BusinessAccountMultipleStores]: {
    isEnabled: false,
  },
  [Features.CustomersV2]: {
    isEnabled: false,
  },
  [Features.Segmentation]: {
    isEnabled: false,
  },
  [Features.RecommendationsActionFlags]: {
    isEnabled: false,
  },
  [Features.AllowFeatureFlagsHeaderOverride]: {
    isEnabled: window.allowFeatureFlagsHeaderOverride,
  },
  loadingFeatures: {},
  isLoading: true,
});

export const ensureFeatures = (requiredFeatures: RequiredFeatures[]) => {
  return requiredFeatures.some((feature) => featuresState[feature.feature]?.isEnabled);
};

export const ensureFeature = (feature: Features): boolean => {
  return featuresState[feature]?.isEnabled;
};

export { Features };

export const getLoadingFeature = (feature: Features) =>
  featuresState.loadingFeatures[feature] || Promise.resolve({ isEnabled: false });

export const getFeature = (feature: Features) => featuresState[feature];

export const isFeatureEnabled = (feature: Features) => getFeature(feature)?.isEnabled;

export const getAllFeatures = async () => {
  return { allFeatures: featuresState };
};

const featuresInSessionStorage = Object.values(Features).some((feature) => sessionStorage.getItem(feature) !== null);

export const resetSessionStorage = () => {
  sessionStorage.removeItem(Features.DeliverySolutions);
  sessionStorage.removeItem(Features.ImmediatePicks);
  sessionStorage.removeItem(Features.ShoppingRulesOption);
  sessionStorage.removeItem(Features.Brandbank);
  sessionStorage.removeItem(Features.ZetesIntegration);
  sessionStorage.removeItem(Features.Webhooks);
  sessionStorage.removeItem(Features.SearchRulesMigrated);
  sessionStorage.removeItem(Features.MultiLingual);
  sessionStorage.removeItem(Features.BusinessAccountMultipleStores);
  sessionStorage.removeItem(Features.CustomersV2);
  sessionStorage.removeItem(Features.Segmentation);
  sessionStorage.removeItem(Features.RecommendationsActionFlags);
};

const resetStore = () => {
  // resets to initial state
  featuresState.loadingFeatures = {};
  featuresState.DeliverySolutions.isEnabled = false;
  featuresState.ZetesIntegration.isEnabled = false;
  featuresState.CMSFreeFormAllowScript.isEnabled = window.appSettings?.cmsFreeFormAllowScript;
  featuresState.FlippEnabled.isEnabled = window.appSettings?.flyerCmsComponentEnabled;
  featuresState.immediatePicks.isEnabled = false;
  featuresState.ShoppingRulesOption.isEnabled = false;
  featuresState.Brandbank = {
    isEnabled: false,
    data: {},
  };
  featuresState.Webhooks.isEnabled = false;
  featuresState.SearchRulesMigrated.isEnabled = false;
  featuresState.MultiLingual.isEnabled = false;
  featuresState.BusinessAccountMultipleStores.isEnabled = false;
  featuresState.CustomersV2.isEnabled = false;
  featuresState.Segmentation.isEnabled = false;
  featuresState.RecommendationsActionFlags.isEnabled = false;
};

// inits features
autoEffect(async () => {
  try {
    if (appState.user && !appState.user.expired) {
      // user logged in
      if (!featuresInSessionStorage) {
        // awaits a bit - the user token might not have taken effect into all the servers and causes a 401
        await asyncTimeout(() => {}, 200);
        let providers;

        if (baseApiUrl) {
          providers = await fetchData<DeliveryIntegrationProviders>({
            url: config.apiEndpoints.deliveryIntegration.checkProviders,
            method: 'GET',
          });
        }
        // checks if Delivery Solutions is Enabled
        featuresState.loadingFeatures[Features.DeliverySolutions] = isDeliveryProviderEnabled();

        // check if ImmediatePicks is Enabled
        featuresState.loadingFeatures[Features.ImmediatePicks] = isImmediatePicksEnabled();

        // check if zetes is Enabled
        featuresState.loadingFeatures[Features.ZetesIntegration] = isZetesIntegrationEnabled(providers);

        // check if shopping rules is enabled
        featuresState.loadingFeatures[Features.ShoppingRulesOption] = isShoppingRulesEnabled();

        // check if brandbank is enabled
        featuresState.loadingFeatures[Features.Brandbank] = isBrandbankEnabled();

        // check if webhooks is enabled
        featuresState.loadingFeatures[Features.Webhooks] = isWebhooksEnabled();

        // check if SearchRulesMigrated is enabled
        featuresState.loadingFeatures[Features.SearchRulesMigrated] = isSearchRulesMigrated();

        // check if MultiLingual is enabled
        featuresState.loadingFeatures[Features.MultiLingual] = isMultiLingualEnabled();

        // check if BusinessAccountMultipleStores is enabled
        featuresState.loadingFeatures[Features.BusinessAccountMultipleStores] =
          isBusinessAccountMultipleStoresEnabled();

        // check if segmentation is enabled
        featuresState.loadingFeatures[Features.Segmentation] = segmentationIsEnabled();

        // check if CustomersV2 is enabled
        featuresState.loadingFeatures[Features.CustomersV2] = isCustomersV2Enabled();

        // check if Recommendations Action Flags is enabled
        featuresState.loadingFeatures[Features.RecommendationsActionFlags] = isRecommendationsActionFlagsEnabled();

        const [
          resultDeliverySolutions,
          resultImmediatePicks,
          resultShoppingRulesOption,
          resultBrandbank,
          resultZetesIntegration,
          resultWebhooks,
          resultSearchRulesMigrated,
          resultMultiLingual,
          resultBusinessAccountMultipleStores,
          resultCustomersV2,
          resultSegmentation,
          resultRecommendationsActionFlags,
        ] = await Promise.all([
          featuresState.loadingFeatures[Features.DeliverySolutions],
          featuresState.loadingFeatures[Features.ImmediatePicks],
          featuresState.loadingFeatures[Features.ShoppingRulesOption],
          featuresState.loadingFeatures[Features.Brandbank],
          featuresState.loadingFeatures[Features.ZetesIntegration],
          featuresState.loadingFeatures[Features.Webhooks],
          featuresState.loadingFeatures[Features.SearchRulesMigrated],
          featuresState.loadingFeatures[Features.MultiLingual],
          featuresState.loadingFeatures[Features.BusinessAccountMultipleStores],
          featuresState.loadingFeatures[Features.CustomersV2],
          featuresState.loadingFeatures[Features.Segmentation],
          featuresState.loadingFeatures[Features.RecommendationsActionFlags],
        ]);

        sessionStorage.setItem(Features.DeliverySolutions, JSON.stringify(resultDeliverySolutions));
        sessionStorage.setItem(Features.ImmediatePicks, JSON.stringify(resultImmediatePicks));
        sessionStorage.setItem(Features.ShoppingRulesOption, JSON.stringify(resultShoppingRulesOption));
        sessionStorage.setItem(Features.Brandbank, JSON.stringify(resultBrandbank));
        sessionStorage.setItem(Features.ZetesIntegration, JSON.stringify(resultZetesIntegration));
        sessionStorage.setItem(Features.Webhooks, JSON.stringify(resultWebhooks));
        sessionStorage.setItem(Features.SearchRulesMigrated, JSON.stringify(resultSearchRulesMigrated));
        sessionStorage.setItem(Features.MultiLingual, JSON.stringify(resultMultiLingual));
        sessionStorage.setItem(
          Features.BusinessAccountMultipleStores,
          JSON.stringify(resultBusinessAccountMultipleStores)
        );
        sessionStorage.setItem(Features.CustomersV2, JSON.stringify(resultCustomersV2));
        sessionStorage.setItem(Features.Segmentation, JSON.stringify(resultSegmentation));
        sessionStorage.setItem(Features.RecommendationsActionFlags, JSON.stringify(resultRecommendationsActionFlags));
      }

      if (appState.user) {
        // user is still logged in
        featuresState[Features.DeliverySolutions] = JSON.parse(sessionStorage.getItem(Features.DeliverySolutions));
        featuresState[Features.ImmediatePicks] = JSON.parse(sessionStorage.getItem(Features.ImmediatePicks));
        featuresState[Features.ShoppingRulesOption] = JSON.parse(sessionStorage.getItem(Features.ShoppingRulesOption));
        featuresState[Features.Brandbank] = JSON.parse(sessionStorage.getItem(Features.Brandbank));
        featuresState[Features.ZetesIntegration] = JSON.parse(sessionStorage.getItem(Features.ZetesIntegration));
        featuresState[Features.Webhooks] = JSON.parse(sessionStorage.getItem(Features.Webhooks));
        featuresState[Features.SearchRulesMigrated] = JSON.parse(sessionStorage.getItem(Features.SearchRulesMigrated));
        featuresState[Features.MultiLingual] = JSON.parse(sessionStorage.getItem(Features.MultiLingual));
        featuresState[Features.BusinessAccountMultipleStores] = JSON.parse(
          sessionStorage.getItem(Features.BusinessAccountMultipleStores)
        );
        featuresState[Features.CustomersV2] = JSON.parse(sessionStorage.getItem(Features.CustomersV2));
        featuresState[Features.Segmentation] = JSON.parse(sessionStorage.getItem(Features.Segmentation));
        featuresState[Features.RecommendationsActionFlags] = JSON.parse(
          sessionStorage.getItem(Features.RecommendationsActionFlags)
        );
        featuresState.isLoading = false;
      }
    } else {
      resetStore();
    }
  } catch {
    resetStore();
  }
}, [appState.user]);

interface AppFeatureProps {
  feature: Features;
  children: React.ReactNode;
}

export const AppFeature = view(({ feature, children }: AppFeatureProps) => (
  <>{featuresState[feature]?.isEnabled && children}</>
));
