import { call, put, select } from 'redux-saga/effects';
import { config } from 'config';
import { apiClientActions } from 'framework/actions';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { takeLatestWithProgressBar } from 'framework/sagas/extensions/progress';
import * as CONST from 'framework/modules/searchadmin/constants';
import { selectors } from '..';

function* process({ payload }) {
  const { data, apiDataProviderName } = payload;
  const attAreDirty = yield select(selectors.attributesAreDirty);
  const isPreview = apiDataProviderName === CONST.API_DATA_PROVIDER_RELEVANCE_PREVIEW;

  const {
    storeId = CONST.DEFAULT_STORE_ID,
    locales = CONST.DEFAULT_LOCALE_ID,
    query = '',
    take: t = 10,
    skip = 0,
    attributes
  } = data;
  if (
    query === '' ||
    // Only render items on preview when there are updates
    (isPreview && !attAreDirty)
  ) {
    yield put(
      apiClientActions.clearApiClientStateAction({
        name: apiDataProviderName
      })
    );
  } else {
    // 1. Request Search Data
    yield call(makeRequestAndSaveState, {
      name: apiDataProviderName,
      method: 'POST',
      url: `${config.apiEndpoints.searchRelevance}/locale/${locales}/store/${storeId}/products`,
      body: {
        q: query,
        take: t,
        skip,
        configurationOverride: {
          overrideType: 'Merge',
          relevancyConfiguration: attributes
        }
      }
    });
  }
}

export function* watchMerchandisingRelevanceGetSearchCurrent() {
  yield takeLatestWithProgressBar(CONST.ACTION_RELEVANCE_SEARCH_RESULTS_CURRENT_GET, process);
}
export function* watchMerchandisingRelevanceGetSearchPreview() {
  yield takeLatestWithProgressBar(CONST.ACTION_RELEVANCE_SEARCH_RESULTS_PREVIEW_GET, process);
}
