import * as ACTIONS from '../actions.constants';
import setInitialEvents from './reducer.calendarSetInitialEvents';
import addEvent from './reducer.calendarAddEvent';
import addEvents from './reducer.calendarAddEvents';
import removeEvent from './reducer.calendarRemoveEvent';
import clearCalendar from './reducer.calendarClear';
import clearCalendarEvents from './reducer.calendarClearEvents';
import calendarReset from './reducer.calendarReset';
import calendarUpdateSelectorView from './reducer.calendarUpdateSelectorView';
import calendarUpdateSelectorDate from './reducer.calendarUpdateSelectorDate';
import calendarUpdateSelectorEvent from './reducer.calendarUpdateSelectorEvent';
import calendarClearSelectedEvent from './reducer.calendarClearSelectedEvent';
import calendarSetLoader from './reducer.calendarSetLoader';

export default (state = {} as any, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.CALENDAR_SET_INITIAL_EVENTS: {
      return setInitialEvents(state, payload);
    }
    case ACTIONS.CALENDAR_ADD_EVENT: {
      return addEvent(state, payload);
    }
    case ACTIONS.CALENDAR_ADD_EVENTS: {
      return addEvents(state, payload);
    }
    case ACTIONS.CALENDAR_REMOVE_EVENT: {
      return removeEvent(state, payload);
    }
    case ACTIONS.CALENDAR_RESET_VALUES: {
      return calendarReset(state, payload);
    }
    case ACTIONS.CALENDAR_CLEAR_VALUES: {
      return clearCalendar(state, payload);
    }
    case ACTIONS.CALENDAR_CLEAR_EVENTS: {
      return clearCalendarEvents(state, payload);
    }
    case ACTIONS.CALENDAR_UPDATE_SELECTOR_VIEW: {
      return calendarUpdateSelectorView(state, payload);
    }
    case ACTIONS.CALENDAR_UPDATE_SELECTOR_DATE: {
      return calendarUpdateSelectorDate(state, payload);
    }
    case ACTIONS.CALENDAR_UPDATE_SELECTOR_EVENT: {
      return calendarUpdateSelectorEvent(state, payload);
    }
    case ACTIONS.CALENDAR_CLEAR_SELECTOR_EVENT: {
      return calendarClearSelectedEvent(state, payload);
    }
    case ACTIONS.CALENDAR_SET_LOADER: {
      return calendarSetLoader(state, payload);
    }
  }

  return state;
};
