import { call, select, put } from 'redux-saga/effects';
import { toastListActions, tabsActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest, makeRequestAndSaveState } from 'framework/api/make-request';
import { DEFAULT_LOCALE_ID, getApiUrl } from 'config';
import { ListingDetails } from 'framework/modules/merchandising/models';
import { API_DATA_LISTING_DATA, ACTION_LISTING_SAVE_DETAILS } from 'framework/modules/merchandising/constants';
import { ListingTranslations } from 'framework/modules/merchandising/translations';
import { goToRoute } from 'framework/components/navigation/service';
import { listingEditRoute } from 'framework/modules/merchandising/routes';
import { listingDefault } from './helpers';
import { TABS_NAME, TAB_RULES } from '../../constants';
import { getListingData, getFormValuesDetails } from '../selectors';

function* process({ payload: { id } }) {
  const dataOriginal = yield select(getListingData);
  const formValues = yield select(getFormValuesDetails);

  // 1. Make Save Request
  const listing: ListingDetails = {
    ...listingDefault,
    ...dataOriginal,
    ...formValues,
    id,
  };

  const { success } = yield call(makeRequest, {
    name: 'saveListingDetails',
    method: 'PUT',
    url: getApiUrl(`/v2/listings/${listing.id}`),
    body: { ...listing },
  });

  // 2. Navigate to Rules List Page
  if (success) {
    // 2.1 Update data
    yield call(makeRequestAndSaveState, {
      name: API_DATA_LISTING_DATA,
      method: 'GET',
      url: getApiUrl(`/v2/locales/${DEFAULT_LOCALE_ID}/listings/${listing.id}`),
    });

    // 2.2 change tab
    yield put(toastListActions.addMessage(ListingTranslations.SaveSuccess));
    yield call(goToRoute, listingEditRoute, { listingId: listing.id });
    yield put(tabsActions.setOpenTab(TABS_NAME, TAB_RULES));
  } else {
    yield put(toastListActions.addMessageError(ListingTranslations.SaveError));
  }
}

export function watchSaveDetails() {
  return takeEveryWithProgressBar(ACTION_LISTING_SAVE_DETAILS, process);
}
