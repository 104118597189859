import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';

/*
  This component 'might' undergo refactoring when there is a proper
  images repository in place.
*/

const componentClass = prefixClassName('img-upload ');

export const ImgUpload = (props: {
  actionTitle: string;
  onFileChanged: (filename: string) => void;
}) => {
  const handleFileChanged = e => {
    const file = e.target.files?.length ? e.target.files[0] : null;
    if (file) {
      props.onFileChanged(file.name);
    }
  };

  return (
    <div className={componentClass}>
      <label className={`${componentClass}__content`}>
        <input type="file" accept="image/*" onChange={handleFileChanged} />
        <span className={`${componentClass}__link`}>{props.actionTitle}</span>
      </label>
    </div>
  );
};
