import * as utils from '../utils';
import { internalGetLayoutInspector } from '../contentmanagement.selectors';

export const storeLayoutEditorFormData = (state, action) => {
  return {
    ...state,
    loadedPage: {
      ...state.loadedPage,
      cmsContent: {
        ...state.loadedPage.cmsContent,
        rows: utils.storeLayoutEditorFormData({
          rows: state.loadedPage.cmsContent.rows,
          rowIndex: action.payload.rowIndex,
          columnIndex: action.payload.columnIndex,
          inspector: internalGetLayoutInspector(
            state.templates,
            action.payload.type,
            action.payload.type
          ),
          data: action.payload.data
        })
      }
    }
  };
};
