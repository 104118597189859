import * as React from 'react';
// import clx from 'classnames';

import { ILoader } from 'framework/components/ui/Loader';
import { Loader } from 'ui';

import { SHARED_CSS } from './constants';

type LoadingSpinnerProps = {
  size?: ILoader['size'];
  visible: boolean;
  className?: string;
};
export const LoadingSpinner = ({ size = 'lg', visible, className }: LoadingSpinnerProps) => (
      <>
        {visible && (
            <div className={`${SHARED_CSS}__loader ${className}`}>
                <Loader size={size} />
            </div>
        )}
      </>
);
