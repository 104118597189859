import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../Button';
import { IButtonSubmit, IButtonSubmitPrivate } from './ButtonSubmit.interfaces';

export const ButtonSubmit = (props: IButtonSubmit & IButtonSubmitPrivate) => {
  const {
    formToSubmit,
    caption = 'generic.button.save',
    onClick,
    submitForm,
    testId = 'btnSubmit',
    propagate,
    trackEventProps
  } = props;

  const { pathname } = useLocation();

  const handleClick = (e?: any) => {
    ApplicationMonitoring.trackEvent(trackEventProps?.name || pathname, trackEventProps?.value ||'Button submit', trackEventProps?.label || caption || formToSubmit);
    if (onClick) {
      onClick(propagate ? e : undefined);
    }
    submitForm();
  };

  return (
    <Button form={formToSubmit} type="submit" caption={caption} onClick={handleClick} testId={testId} {...props} />
  );
};

export default ButtonSubmit;
