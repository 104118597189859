import {
  CmsPageRow,
  CmsPageColumn,
  DefaultBuilderProps,
} from 'framework/modules/contentmanagement/CmsComponents.interfaces';

export interface RowBuilderProps extends DefaultBuilderProps {
  rowOrColumn: CmsPageRow;
  rowIndex: number;
  maxColumns?: number;
  activeColumnIndex: number;
  activeCarouselIndex: number;
  carouselIsEditing: boolean;
  sharedComponentOpen?: CmsPageRow;
}

export interface RowBuilderReduxActions {
  setActiveColumnIndex: ({ activeColumnIndex }) => void;
  setCarouselColumnIndex: ({ carouselColumnIndex }) => void;
  shouldReopenLayoutEditorModal: ({ shouldReopen }) => void;
  initRowEditorData: ({ data }: { data: CmsPageRow }) => void;
  openModal: (modal: string) => void;
  closeModal: (modal: string) => void;
  addEmptyPageRowColumn: () => void;
  removeRowEditorColumn: ({ columnIndex }) => void;
  storeRowEditorData: ({ index, data }) => void;
  updateCarouselData: ({
    rowIndex, colIndex, rowData, largeTimeout, mediumTimeout,
  }) => void;
  updateCarouselTimeout: ({ timeout, screenType }) => void;
}

export interface CmsLayoutEditorRowBuilderState {
  columnCount: number;
  row: CmsPageRow;
  activeContent?: CmsPageColumn;
}

export enum CarouselTimeoutScreenTypes {
  Desktop = 'Desktop',
  Mobile = 'Mobile'
}

export interface TimeoutActionProps {
  timeout: string;
  screenType: CarouselTimeoutScreenTypes;
}
