import { DEFAULT_LOCALE_ID, getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { ScoreExplanation } from '../models/score';
import { SearchConfiguration } from '../models/searchConfiguration';

export interface FetchScoreArgs {
  relevance: boolean;
  localeId?: string,
  storeId: string,
  searchTerm: string,
  productId: string;
  skip?: number;
  take?: number;
  configurationOverride?: SearchConfiguration;
}

export const fetchScore = async ({
  relevance,
  skip = 0,
  take = 10,
  localeId = DEFAULT_LOCALE_ID,
  storeId,
  searchTerm,
  productId,
  configurationOverride,
}: FetchScoreArgs) => {
  const url = relevance ? '/search/relevance/locale/{localeId}/store/{storeId}/products/{productId}/explain'
    : '/search/locale/{localeId}/store/{storeId}/products/{productId}/explain';

  const result = await fetchData<ScoreExplanation>({
    url: getApiUrl(url, {
      localeId,
      storeId,
      productId,
    }),
    method: 'POST',
    body: {
      q: searchTerm,
      skip,
      take,
      configurationOverride,
    },
  });

  return result;
};
