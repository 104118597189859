import { call, put } from 'redux-saga/effects';
import { config } from 'config';
import { makeRequest } from 'framework/api/make-request';
import { toastListActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import {
  ACTION_LISTING_UPDATE_STATE,
} from 'framework/modules/merchandising/constants';
import { ListingTranslations } from 'framework/modules/merchandising/translations';
import { listingListRoute } from 'framework/modules/merchandising/routes';
import { goToRoute } from 'framework/components/navigation/service';

function* process({ payload }) {
  const { id, status } = payload;

  // 1. Make Update Request
  const { success } = yield call(makeRequest, {
    name: 'LISTINGStateUpdate',
    url: `${config.apiEndpoints.listings}/status/${status}`,
    method: 'PUT',
    body: [id],
  });

  if (success) {
    // 2.1 GoTo LISTINGs Grid
    goToRoute(listingListRoute);
    yield put(toastListActions.addMessage(ListingTranslations.StatusUpdateSuccessfully));
  } else {
    yield put(toastListActions.addMessageError(ListingTranslations.StatusUpdateError));
  }
}

export default function watchUpdateLISTINGState() {
  return takeEveryWithProgressBar(ACTION_LISTING_UPDATE_STATE, process);
}
