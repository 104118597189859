import { apiClientSelectors, formSelectors } from 'framework/selectors';
import { connect } from 'react-redux';
import * as actions from './actions';
import CONST from './constants';
import Modal from './modalProductSearch';

const mapStateToProps = (state: any) => ({
  formDataValues: formSelectors.getFormValues(state, CONST.FORM_NAME),
  isFetching: apiClientSelectors.getIsFetching(state, CONST.API_DATA_NAME),
  data: apiClientSelectors.selectApiClientData(state, CONST.API_DATA_NAME),
  previewStoreId: apiClientSelectors.selectApiClientData(state, CONST.API_DATA_RULE_DATA)?.previewStoreId,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(actions.hideModalAction()),
  searchProducts: (query) => dispatch(actions.searchProductsAction(query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal) as React.ComponentType<React.ComponentProps<typeof Modal>>;
