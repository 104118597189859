import * as React from 'react';
import * as classnames from 'classnames';
import { Icon, Button, Text } from 'ui';
import { IMenuButton } from '../Menu.interfaces';
import './_style.scss';

export const MenuButton = (props: IMenuButton) => {
  const {
    onClick = () => {},
    active,
    className,
    text,
    icon,
  } = props;

  return (
    <Button
      onClick={onClick}
      aria-expanded={active}

      className={classnames(
        className,
        active && `${className}--active`,
      )}
      testId="myAccount"
    >
      <Icon
        name={icon}
        size="sm"
        className={classnames(`${className}--button-icon`, active && `${className}--button-icon--active`)}
      />
      <Text
        caption={text}
        className={`${className}--button-text`}
      />
      <Icon
        name={ active ? 'dropdownArrowUp' : 'dropdownArrowDown' }
        size="sm"
        className={classnames(`${className}--button-arrow`, active && `${className}--button-arrow--active`)}
      />
    </Button>
  );
};

export default MenuButton;
