import { AppRoute, AppRouteClaims } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { goToRoute } from 'framework/components/navigation/service';
import { PromotionsTranslations } from '../../translations';

const promoClaims: AppRouteClaims = [
  AuthorizationClaims.PROMOTIONS_PROMOCODES,
];

export const promotionCodeListRoute: AppRoute = {
  path: '/promotions/promotionCodes/',
  authClaim: promoClaims,
  pageTitle: PromotionsTranslations.PromotionCodes,
  menuTitle: PromotionsTranslations.AllPromotionCodes,
  Component: () => import('./index'),
};

export const goToPromoCodeListRoute = () => goToRoute(promotionCodeListRoute);
