import * as constants from '../contentemanagement.constants';
import {
  CmsLayoutType, CmsPageRow, CarouselBasedContainer, CmsPageContent, CmsPageColumn,
} from '../CmsComponents.interfaces';
import { TimeoutActionProps } from '../common/LayoutBuilder/InspectorBuilders/RowBuilder/Interfaces';
import { SharedFolderOrDocumentRow } from '../SharedContent/types';
import { DocumentFolder, PageStatus, ScheduleStatus } from '../Core/DocumentFoldersPage/DocumentHierarchy/interfaces';

export const createCmsPageAction = () => ({
  type: constants.ACTION_CMS_CREATE_PAGE,
});

export const saveCmsContent = () => ({
  type: constants.SAVE_CMS_CONTENT,
});

export const storePageDocumentAction = (values: any) => ({
  payload: values,
  type: constants.ACTION_CMS_STORE_PAGE,
});

export const addLayoutToPageAction = (props: {
  componentName: CmsLayoutType;
  rowIndex: number;
}) => ({
  payload: props,
  type: constants.ACTION_CMS_ADD_LAYOUT_TO_PAGE,
});

export const setInsertLayoutIndex = (props: {
  insertIndex: number
}) => ({
  payload: props,
  type: constants.ACTION_CMS_SET_INSERT_LAYOUT_INDEX,
});

export const setSelectedDocumentInManager = (props: {
  selectedDocumentedInManager: SharedFolderOrDocumentRow,
}) => ({
  payload: props,
  type: constants.ACTION_SET_SELECTED_COMPONENT_IN_MANAGER,
});

export const setAllPagesState = (props: {
  lastSelectedId?: string,
  filterByName?: string,
  filterByStatus?: PageStatus,
  filterByScheduleStatus?: ScheduleStatus,
  documentsHierarchy?: DocumentFolder[];
}) => ({
  payload: props,
  type: constants.ACTION_SET_ALL_PAGES_STATE,
});

export const removeCmsRowAction = (payload: { rowIndex: number }) => ({
  payload,
  type: constants.ACTION_CMS_REMOVE_ROW,
});

export const toggleDisplayPromotionsCarousel = (payload: { rowIndex: number }) => ({
  payload,
  type: constants.ACTION_CMS_TOGGLE_DISPLAY_PROMOTIONS_CAROUSEL,
});

export const reorderRowsAction = (payload: CmsPageRow[]) => ({
  payload,
  type: constants.ACTION_CMS_REORDER_ROWS,
});

export const setCmsEditRowIndexAction = (payload: { rowIndex: number }) => ({
  payload,
  type: constants.ACTION_CMS_SET_EDITROWINDEX,
});

export const loadDocumentVariant = ({ pageId, variantId }) => ({
  payload: {
    pageId,
    variantId,
  },
  type: constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT,
});

export const loadPagesHierarchyAction = () => ({
  type: constants.ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY,
});

export const storePagesHierarchy = (payload: { cmsPagesHierarchy: any[] }) => ({
  payload,
  type: constants.ACTION_CMS_STORE_PAGES_HIERARCHY,
});

export const storeCmsPageInfo = (props: {
  pageId: string;
  variantId: string;
  pageCreated?: boolean;
  folderId?: string;
}) => ({
  payload: {
    ...props,
  },
  type: constants.ACTION_CMS_STORE_PAGEINFO,
});

export const clearCurrentPageAction = () => ({
  type: constants.ACTION_CMS_CLEAR_CURRENTPAGE,
});

export const storeCmsTemplates = ({ templates }) => ({
  payload: {
    templates,
  },
  type: constants.ACTION_CMS_STORE_TEMPLATES,
});

// initialize layout editor form saving
export const saveLayoutEditorAction = ({ rowIndex, columnIndex, type }) => ({
  payload: {
    rowIndex,
    columnIndex,
    type,
  },
  type: constants.ACTION_CMS_SAVE_LAYOUT_EDITOR,
});

// store layout editor form values into redux store
export const storeLayoutEditorFormDataAction = ({
  rowIndex, columnIndex, type, data,
}) => ({
  payload: {
    rowIndex,
    columnIndex,
    type,
    data,
  },
  type: constants.ACTION_CMS_STORE_LAYOUT_EDITOR_FORMDATA,
});

export const setActiveColumnIndexAction = ({ activeColumnIndex }) => ({
  payload: {
    activeColumnIndex,
  },
  type: constants.ACTION_CMS_SET_ACTIVE_COLUMNINDEX,
});

export const shouldReopenLayoutEditorModalAction = ({ shouldReopen }) => ({
  payload: {
    shouldReopen,
  },
  type: constants.ACTION_CMS_SHOULDREOPEN_LAYOUTEDITOR_MODAL,
});

export const initRowEditorDataAction = ({ data }: { data: CmsPageRow }) => ({
  payload: { data },
  type: constants.ACTION_CMS_INIT_ROWEDITOR_DATA,
});

export const clearRowEditorDataAction = () => ({
  type: constants.ACTION_CMS_CLEAR_ROWEDITOR_DATA,
});

export const addEmptyPageRowColumnAction = () => ({
  type: constants.ACTION_CMS_ADD_EMPTY_COLUMN,
});

export const removeRowEditorColumnAction = ({ columnIndex }) => ({
  payload: {
    columnIndex,
  },
  type: constants.ACTION_CMS_REMOVE_COLUMN,
});

export const removeDeletedTypeAction = () => ({
  type: constants.ACTION_CMS_REMOVE_DELETED_TYPE,
});

export const removeRowEditorColumnFromPageAction = (
  { rowIndex, columnIndex, carouselBased }: { rowIndex: number, columnIndex: number, carouselBased: boolean }
) => ({
  payload: {
    rowIndex,
    columnIndex,
    carouselBased,
  },
  type: constants.ACTION_CMS_REMOVE_COLUMN_PAGE_VIEW,
});

export const insertColumnContentInRowEditorData = (props: {
  columnIndex: number;
  layoutType: CmsLayoutType;
}) => ({
  payload: props,
  type: constants.ACTION_CMS_ADD_COLUMN_CONTENT,
});

export const storeRowEditorDataAction = ({ index, data }) => ({
  payload: {
    index,
    data,
  },
  type: constants.ACTION_CMS_STORE_ROWEDITOR_DATA,
});

export const updateCarouselDataAction = ({
  rowIndex, colIndex, rowData, largeTimeout, mediumTimeout,
}) => ({
  payload: {
    rowIndex,
    colIndex,
    rowData,
    largeTimeout,
    mediumTimeout,
  },
  type: constants.ACTION_CMS_UPDATE_CAROUSEL_DATA,
});

export const updateCarouselTimeout = ({ timeout, screenType }: TimeoutActionProps) => ({
  payload: {
    timeout,
    screenType,
  },
  type: constants.ACTION_CMS_SET_CAROUSEL_TIMEOUT,
});

export const setCarouselColumnIndexAction = ({ carouselColumnIndex }) => ({
  payload: {
    carouselColumnIndex,
  },
  type: constants.ACTION_CMS_SET_CAROUSEL_COLUMNINDEX,
});

export const setCarouselIsEditingAction = ({ isEditing }) => ({
  payload: {
    isEditing,
  },
  type: constants.ACTION_CMS_SET_CAROUSEL_IS_EDITING,
});

export const setEditSharedComponentAction = ({ component }) => ({
  payload: {
    component,
  },
  type: constants.ACTION_CMS_SET_EDIT_SHARED_COMPONENT,
});

export const saveCmsFormSeoTabAction = () => ({
  type: constants.ACTION_CMS_FORM_SEO_TAB_SAVEDATA,
});

export const createCmsSeoForVariant = () => ({
  type: constants.ACTION_CMS_CREATE_SEO_FOR_VARIANT,
});

export const createPageVariant = ({ selectedIds }) => ({
  payload: { selectedIds },
  type: constants.ACTION_CMS_CREATE_PAGE_VARIANT,
});

export const loadDocumentVariantSeo = ({ documentId, variantId }) => ({
  payload: {
    documentId,
    variantId,
  },
  type: constants.ACTION_CMS_LOAD_DOCUMENT_VARIANT_SEO,
});

export const saveCmsStoresTab = ({ selectedIds }) => ({
  type: constants.ACTION_CMS_SAVE_STORES_TAB,
  payload: {
    selectedIds,
  },
});

export const submitSchedulingForm = () => ({
  type: constants.ACTION_CMS_SUBMIT_SCHEDULING_FORM,
});

export const saveSchedulingFormData = () => ({
  type: constants.ACTION_CMS_SAVE_SCHEDULING_FORM_DATA,
});

export const submitPageDetailFormValues = () => ({
  type: constants.ACTION_CMS_SUBMIT_TAB_EDITDETAIL_FORM,
});

export const createCmsPageDocument = () => ({
  type: constants.ACTION_CMS_CREATE_PAGE_DOCUMENT,
});

export const savePageDetailsData = () => ({
  type: constants.ACTION_CMS_SAVE_PAGEDETAILS,
});

export const clearPageCreatedFlag = () => ({
  type: constants.ACTION_CMS_CLEAR_PAGECREATED_FLAG,
});

export const updateCmsPageStatus = (props: {
  documentId: string;
  variantId: string;
  status: string;
  onComplete: () => void;
  onError: () => void;
}) => ({
  type: constants.ACTION_CMS_PAGE_UPDATE_STATUS,
  payload: {
    ...props,
  },
});

export const storeHighlightedFolderId = ({ folderId }) => ({
  type: constants.ACTION_CMS_STORE_HIGHLIGHTED_FOLDERID,
  payload: {
    folderId,
  },
});

export const deletePage = ({ documentId, variantId }) => ({
  type: constants.ACTION_CMS_DELETE_PAGE,
  payload: {
    documentId,
    variantId,
  },
});

export const setSubContainerEditing = (
  payload: { editingSubContainerType?: CmsLayoutType | CarouselBasedContainer },
) => ({
  type: constants.ACTION_SET_SUBCONTAINER_EDITING,
  payload,
});

export type SharedComponentMetadata = {
  rowIndex: number;
  colIndex?: number;
  shared: (CmsPageRow | CmsPageContent | CmsPageColumn) & {
    metadata: {
      documentId?: string;
      variantId?: string;
      variantName?: string;
      directReferencersCount?: number,
      totalReferencersCount?: number,
    },
  }
};

export const createSharedComponentPlaceholder = ({
  rowIndex, colIndex, shared,
}: SharedComponentMetadata) => ({
  type: constants.ACTION_CREATE_SHARED_COMPONENT_PLACEHOLDER,
  payload: {
    rowIndex,
    colIndex,
    shared,
  },
});

export const insertSharedComponentColumn = (props: {
  colIndex: number;
  data: unknown;
}) => ({
  type: constants.ACTION_INSERT_SHARED_COMPONENT_COLUMN,
  payload: props,
});

export const detachSharedComponent = (props: {
  rowIndex: number;
  colIndex?: number;
}) => ({
  type: constants.DETACH_SHARED_COMPONENT,
  payload: props,
});
