import {
  createContext, useContext, useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { formActions } from 'framework/actions';
import { modalSelectors } from 'framework/selectors';

import { useModal } from 'framework/components/ui/Modal';
import { FILEMANAGER_MODAL } from './constants';
import {
  ExplorerMode, UploadableFile, FileFolder, ServerFile, FileFormValues, BaseFileManagerProps,
} from './types';
import { FORM_LAYOUT_INSPECTOR } from '../contentemanagement.constants';

const useBaseFileManager = ({ explorerModalName }: BaseFileManagerProps = { explorerModalName: FILEMANAGER_MODAL }) => {
  const { openModal, closeModal } = useModal(explorerModalName);
  const [mode, setMode] = useState<ExplorerMode>(ExplorerMode.EXPLORE);
  const modalOpen = useSelector((state) => modalSelectors.isModalOpen(state, explorerModalName));
  const dispatch = useDispatch();

  const openFileManager = (_mode: ExplorerMode = ExplorerMode.EXPLORE) => {
    setMode(_mode);
    dispatch(openModal());
  };

  const closeFileManager = () => {
    setMode(ExplorerMode.EXPLORE);
    dispatch(closeModal());
  };

  const onDismissModal = () => setMode(ExplorerMode.EXPLORE);

  return {
    mode,
    modalOpen,
    onDismissModal,
    openFileManager,
    closeFileManager,
  };
};

export const useFileManagerWithProvider = () => {
  const { ...rest } = useBaseFileManager();
  const [uploadable, setUploadable] = useState<UploadableFile>();
  const [requiredFilesFormat, setRequiredFilesFormat] = useState<string[]>([]);
  const formValues = useSelector((state) => getFormValues(FORM_LAYOUT_INSPECTOR)(state));
  const dispatch = useDispatch();

  const saveFile = (file: ServerFile) => {
    const { type, ...restFile } = file;
    const formValue: FileFormValues = formValues[uploadable.propertyName] || {};
    const newValue: FileFormValues | string =  uploadable?.size ?  {
      ...formValue,
      [uploadable.size]: {
        ...restFile,
        filetype: type,
      },
    } : restFile?.url;

    dispatch(formActions.setValue(FORM_LAYOUT_INSPECTOR, uploadable.propertyName, newValue));
    rest.closeFileManager();
  };

  const handleFilesUploaded = (files: ServerFile[]) => saveFile(files[0]);
  const handleFileSelected = (file: FileFolder) => saveFile({
    name: file.name,
    reference: file.reference,
    type: file.type,
    url: file.url,
  });

  useEffect(() => setUploadable(undefined), []);

  return {
    ...rest,
    uploadable,
    setUploadable,
    requiredFilesFormat,
    setRequiredFilesFormat,
    onFilesUploaded: handleFilesUploaded,
    onFileSelected: handleFileSelected,
  };
};

export const context = createContext<ReturnType<typeof useFileManagerWithProvider>>({} as any);

// use file manager with context-api (see cms image component)
export const useFileManagerProvider = () => useContext(context);

export const useFileManager = ({ explorerModalName }: BaseFileManagerProps = {
  explorerModalName: FILEMANAGER_MODAL,
}) => useBaseFileManager({ explorerModalName });
