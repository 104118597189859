export default (
  state,
  payload: {
    calendarName: string;
  }
) => {
  const { calendarName } = payload;
  const newState = { ...state };
  delete newState[calendarName];

  return newState;
};
