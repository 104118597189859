export const constants = {
  newSiteUrl: '/storeManagement/sites/create',
  attributesUrl: '/storeManagement/attributes',
  attributeEditUrl: '/storeManagement/attributes/edit/:id',
  attributeCreateUrl: '/storeManagement/attributes/new',
  manageHierarchyUrl: '/storeManagement/hierarchy',
};

export const TILES_PROVIDER = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';

export const STORE_SEARCH = 'STORE_SEARCH';
export const STORE_ATTRIBUTE_SEARCH = 'STORE_ATTRIBUTE_SEARCH';
