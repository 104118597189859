import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { AppRouteBase } from 'framework/components/navigation/Navigation.interfaces';
import { goToRoute } from 'framework/components/navigation/service';
import { SynonymTranslations } from './translations';

export const synonymsListRoute: AppRouteBase = {
  pageTitle: SynonymTranslations.Synonyms,
  menuTitle: SynonymTranslations.AllSynonyms,
  path: '/searchadmin/synonyms',
  authClaim: AuthorizationClaims.SEARCH_SYNONYMS,
};

export const goToSynonymsListRoute = () => goToRoute(synonymsListRoute);

export const synonymsCreateRoute: AppRouteBase = {
  pageTitle: 'modules.merchandising.synonyms.create.title',
  menuTitle: SynonymTranslations.CreateSynonym,
  path: '/searchadmin/synonyms/create',
  authClaim: AuthorizationClaims.SEARCH_SYNONYMS,
  parent: synonymsListRoute,
};

export const goToSynonymsCreateRoute = () => goToRoute(synonymsCreateRoute);

export const synonymsEditRoute: AppRouteBase = {
  pageTitle: 'modules.merchandising.synonyms.edit.title',
  path: '/searchadmin/synonyms/edit/:synonymId',
  authClaim: AuthorizationClaims.SEARCH_SYNONYMS,
  parent: synonymsListRoute,
};

export const goToSynonymsEditRoute = (synonymId: string) => goToRoute(synonymsCreateRoute, { synonymId });
