import { eventTemplates } from 'framework/modules/slotManagement/pages/LiveTimeSlotView/constants';
import uuidv4 from 'uuid/v4';

export default (
  state,
  payload: {
    calendarName: string;
    event: any;
  }
) => {
  const { calendarName, event = {} } = payload;
  const calendar = state[calendarName] || {};
  const id = event.id || uuidv4();

  return {
    ...state,
    [calendarName]: {
      ...calendar,
      events: {
        ...calendar.events,
        [id]: { ...event, id, modified: true },
      },
      newBlockedSlots: [
        ...(calendar?.newBlockedSlots ? [...calendar.newBlockedSlots] : []),
        ...(event.template === eventTemplates.blocker ? [{ ...event, id, modified: true }] : []),
      ],
    },
  };
};
