import * as React from 'react';
import PaginationButtonsDefault from './Buttons';
import PaginationDropDown from './Dropdown';
import { IPagination } from './Pagination.types';

export const toDisplayProps = (page: number, size: number, count: number, itemCount?: number) => {
  const from = (size * (page - 1)) + 1;
  return {
    from,
    to: (count !== -1 && (page * size) > count)
      ? count
      : from + ((itemCount || size) - 1),
  };
};

export class Pagination extends React.Component<IPagination> {
  render() {
    const {
      currentPage,
      pageSize,
      paginationType,
      recordCount,
      itemCount,
    } = this.props;

    switch (paginationType) {
      case 'Dropdown':
        return <PaginationDropDown {...this.props} />;
      case 'Buttons':
      default:
        return <PaginationButtonsDefault
          {...this.props}
          {...toDisplayProps(currentPage, pageSize, recordCount, itemCount)}
        />;
    }
  }
}

export default Pagination;
