import {
  SITEADMIN_STORE_USER,
  SITEADMIN_NEXT_TABSTEP,
  SITEADMIN_CLEAR_USER,
  SITEADMIN_STORE_PAGEFLOW
} from './CommonPage.Constants';

export const siteadmin = (
  state = {
    tabStep: 0
  },
  action
) => {
  switch (action.type) {
    case SITEADMIN_STORE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }

    case SITEADMIN_CLEAR_USER: {
      return {
        tabStep: 0
      };
    }

    case SITEADMIN_NEXT_TABSTEP: {
      return {
        ...state,
        tabStep: state.tabStep + 1
      };
    }

    case SITEADMIN_STORE_PAGEFLOW: {
      const { pageFlow } = action.payload;
      return {
        ...state,
        pageFlow
      };
    }

    default:
      return state;
  }
};
