import * as React from 'react';
import { Link, Text } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { useLocation } from 'react-router-dom';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { IFeedback, IFeedbackMessageObject } from './Feedback.interface';
import './_styles.scss';

const clx = prefixClassName('form-field-wrapper__feedback');

export const Feedback = React.memo((props: IFeedback) => {
  const {
    hasError = false, messageError, messageInfo, className = '', trackEventProps
  } = props;

  const { pathname } = useLocation();

  const renderMessage = (message: IFeedbackMessageObject) => {
    if (typeof message === 'string') {
      return <Text caption={message} linebreak key={message} />;
    }

    const linkText = message.linkText || 'components.form.feedbackViewMore';
    return (
      <React.Fragment key={message.caption}>
        <Text caption={message.caption} />
        {message.linkTo && (
          <Link
            onClick={() =>
              ApplicationMonitoring.trackEvent(
                trackEventProps?.name || pathname,
                trackEventProps?.value || 'Feedback link',
                trackEventProps?.label || linkText
              )
            }
            caption={linkText}
            to={message.linkTo}
          />
        )}
        <br />
      </React.Fragment>
    );
  };

  const renderMessagesList = (messages: IFeedbackMessageObject | IFeedbackMessageObject[]) => {
    if (!messages) return null;

    return Array.isArray(messages) && messages.length > 0
      ? messages.map((m) => renderMessage(m))
      : renderMessage(messages as IFeedbackMessageObject);
  };

  const messageErrorList = renderMessagesList(messageError);
  const messageInfoList = renderMessagesList(messageInfo);

  return (hasError && messageErrorList) || messageInfoList ? (
    <div className={`${clx} ${className}`}>
      {hasError && (
        <span className={`${clx}__messages ${clx}__messages--error`}>{messageErrorList}</span>
      )}
      <span className={`${clx}__messages ${clx}__messages--info`}>{messageInfoList}</span>
    </div>
  ) : null;
});

export default Feedback;
