import uuidv4 from 'uuid/v4';

export default (
  state,
  payload: {
    calendarName: string;
    events: any;
  }
) => {
  const { calendarName, events = [] } = payload;
  const calendar = state[calendarName] || {};
  const newEvents = {};
  events.map((event: any = {}) => {
    const id = event.id || uuidv4();

    newEvents[id] = {
      ...event,
      id,
      modified: true
    };
  });

  return {
    ...state,
    [calendarName]: {
      ...calendar,
      events: {
        ...calendar.events,
        ...newEvents
      }
    }
  };
};
