import * as classnames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Message } from 'ui';
import { IMessageList } from './MessageList.interfaces';
import './_style.scss';

const clx = prefixClassName('messageList');

const MessageList = (props: IMessageList) => {
  const { messages = [], className } = props;

  return messages.length ? (
    <div className={classnames(clx, className)}>
      {messages.map((m, index) => (
        <Message key={`item-${index}`} {...m} />
      ))}
    </div>
  ) : null;
};

export default MessageList;
