import { KebabMenuDirection } from 'framework/components/ui/Kebab/Kebab.interfaces';
import { CmsLayoutType, CmsPageRow, CarouselBasedContainer, CmsPageColumn } from '../../../CmsComponents.interfaces';
import { carouselBasedContainers } from '../CmsLayoutSelect/constants';

export const containerLayouts: CmsLayoutType[] = ['Row', 'Carousel', ...carouselBasedContainers];

export const isContainerLayout = (layout: CmsLayoutType): boolean =>
  containerLayouts.some((c) => c.toLocaleLowerCase() === layout.toLocaleLowerCase());

export const isSubContainer = (container: CmsLayoutType | CarouselBasedContainer) =>
  carouselBasedContainers.some((c) => c === container);

export const rowIsEmpty = (row: CmsPageRow): boolean => {
  const isEmpty = !row?.columns?.length;
  return isEmpty;
};

export const rowIsPlaceHolder = (row: CmsPageRow): boolean =>
  !rowIsEmpty(row) &&
  row?.columns?.length === 1 &&
  row?.columns?.[0]?.type.toLocaleLowerCase() === 'Placeholder'.toLowerCase();
export const rowIsExplicit = (row: CmsPageRow) => !row?.implicit;
export const rowIsSubContainer = (row: CmsPageRow) => {
  const isSub = !rowIsEmpty(row) && row?.columns.length === 1 && isSubContainer(row?.columns?.[0].type);
  return isSub;
};
export const rowIsCarouselBased = (row: CmsPageRow) => {
  const isCarousel =
    !rowIsEmpty(row) && (row?.columns?.[0]?.type === 'Carousel' || isSubContainer(row?.columns?.[0]?.type));
  return isCarousel;
};

export const rowIsEmptyCarousel = (row: CmsPageRow) => {
  const emptyCarousel = rowIsCarouselBased(row) && row?.columns?.[0].items && !row?.columns?.[0]?.items.length;
  return emptyCarousel;
};

export const rowIsDeleted = (row: CmsPageRow) =>
  (row?.columns && row?.columns.length === 1 && row?.columns[0]?.type === 'DocumentPlaceholder') || row?.type === 'DocumentPlaceholder';

export const resolveKebabDirection = (row: CmsPageRow, colIndex: number): KebabMenuDirection => {
  const columns = rowIsCarouselBased(row) ? row?.columns?.[0].items ?? [] : row?.columns ?? [];

  return colIndex === 0 && columns.length > 1 ? 'downRight' : 'downLeft';
};

export const retrieveRowColumns = (row: CmsPageRow) =>
  rowIsCarouselBased(row) ? row?.columns?.[0].items ?? [] : row?.columns ?? [];

export const retrieveSharedMetaData = (row: CmsPageRow, colIndex?: number) => {
  if (colIndex === undefined) {
    return row?.metadata;
  }
  const columns = retrieveRowColumns(row);
  return columns[colIndex]?.metadata;
};

export const retrieveParentSharedMetadata = (row: CmsPageRow, col?: CmsPageColumn) => {
  if (rowIsCarouselBased(row) || rowIsExplicit(row)) {
    return row.columns?.[0].metadata;
  }
  return col.metadata;
};

export const resolveTypeOfComponentFromRow = (row?: CmsPageRow): CmsLayoutType => {
  const type: CmsLayoutType = row?.columns?.[0]?.type || 'Row';
  if (row && rowIsExplicit(row) && !isContainerLayout(type)) {
    return 'Row';
  }
  return type;
};
