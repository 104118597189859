import { SaveLayoutArgs } from './Interfaces';

const cssRoot = 'mwg-cms-mobile-layout';
const transRoot = 'modules.contentmanagement.layouts';

export const SAVE_LAYOUT = 'SAVE_LAYOUT';

export const cssClasses = {
  root: cssRoot,
  label: `${cssRoot}__label`,
  text: `${cssRoot}__text`,
  wrap: `${cssRoot}-wrap`,
  title: `${cssRoot}-title`,
  explainer: 'mwg-form-field-wrapper__explainer',
};

export const translations = {
  title: `${transRoot}.mobile_layout_title`,
  stacked: `${transRoot}.layout_stacked`,
  slider: `${transRoot}.layout_slider`,
  twoColumns: `${transRoot}.layout_two_columns`,
  description: `${transRoot}.layout_description`,
};

export const actions = {
  saveLayout: ({ type, rowIndex }: SaveLayoutArgs) => ({
    type: SAVE_LAYOUT,
    payload: { type, rowIndex },
  }),
};
