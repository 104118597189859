import { IAdminControlType } from '@thryveai/theme-interfaces/dist';
import { SelectOption } from 'types';
import { IMessageTypes } from '../Primitives/MessagePrimitive/MessagePrimitve.interface';

export interface IApplicationSettingsListProps {
  children: any;
}

export interface IApplicationSettingsDocumentProps {
  status: string;
  displayName: string;
  url: string;
  lastUpdated: string;
  scheduleStatus: string;
}

export interface IComponent {
  title?: string;
  description?: string;
  options?: string[] | SelectOption[];
  helpLink?: string;
  value?: any;
  message: {
    caption: string[];
    showLabel: boolean;
  };
  uniqueOptions?: string[];
}

export interface IApplicationSettingsBanner {
  banner: string;
  imageUrl: string;
  imageAlt: string;
  bannerName: string;
  activeBannerId: string;
  handleClick: (id: string) => void;
}

export interface ICheckboxInterface {
  field: string;
  value: any;
  id: string;
  component: IComponent;
  handleChange: (key: string, value: any) => void;
}

export interface IDropdownInterface {
  field: string;
  value: string | boolean;
  id: string;
  component: IComponent;
  bypassValueCheck?: boolean;
  required?: boolean;
  handleChange: (key: string, value: any) => void;
}

export interface IObjectTypeInterface {
  valuesObject: any;
  bannerSettingsMap: any;
  id: string;
  component: IComponent;
  handleChange: (key: string, value: any) => void;
  canBeNull?: boolean;
  isCollapsable?: boolean;
  level: number;
  Footer?: React.ReactNode;
  showAnchors?: boolean;
  formName?: string;
}

export interface IArrayTypeInterface {
  valuesObject: any;
  bannerSettingsMap: any;
  id: string;
  level: number;
  value: any[];
  message: {
    caption: string[];
    type: IMessageTypes;
    showLabel: boolean;
  };
  component: Omit<IComponent, 'value'> & {
    title: string;
    value: {
      [k: string]: IComponent & { type: IAdminControlType };
    };
  };
  Footer?: React.ReactNode;
  formName: string;
  handleChange: (key: string, value: any) => void;
}

export enum InputTypes {
  Text = 'text',
  Number = 'number',
}

export interface IInputTypeInterface {
  field: string;
  value: string | boolean;
  id: string;
  component: {
    title: string;
    description: string;
    options: { max: number; min: number } | string[];
    helpLink: string;
  };
  bypassValueCheck?: boolean;
  required?: boolean;
  type: InputTypes;
  handleChange: (key: string, value: any) => void;
}

export interface IStoreTypeInterface {
  field: string;
  bypassValueCheck?: boolean;
  id: string;
  value: string[];
  formName: string;
  component: {
    title: string;
  };
  handleChange: (key: string, value: any) => void;
}
