import * as React from 'react';
import classnames from 'classnames';
import { Range, NumericField } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IRangeWithNumerical } from './RangeWithNumerical.interfaces';
import './_style.scss';

const clx = prefixClassName('rangeWithNumerical');

const RangeWithNumerical = (props: IRangeWithNumerical) => {
  const {
    className,
    label,
    name,
    onChange,
    displayExtraInfo,

    min = 0,
    max = 10,
    step = 0.01,
    minCaption,
    maxCaption,

    disabled = false
  } = props;

  return (
    <div className={classnames(clx, label && `${clx}--hasLabel`, className)}>
      <Range
        name={name}
        caption={label}
        className={`${clx}__range`}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        onMouseUp={onChange}
        displayExtraInfo={displayExtraInfo}
        minCaption={minCaption}
        maxCaption={maxCaption}
      />
      <NumericField
        name={name}
        className={`${clx}__numericField`}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        showIcons={false}
        onChange={onChange}
        decimals={2}
      />
    </div>
  );
};

export default RangeWithNumerical;
