import * as React from 'react';
import { ButtonList } from 'framework/components/ui';
import { IModalFooter } from './Modal.interface';
import { modalClass } from './constants';

const ModalFooter = (props: IModalFooter) => {
  const { options = [], Component } = props;
  const validOptions = options.filter((o) => o);
  if (!Component && validOptions.length === 0) {
    return null;
  }
  return (
    <div className={`${modalClass}__footer`}>
      {Component ? <Component /> : null}
      <ButtonList buttons={validOptions} />
    </div>
  );
};

export default ModalFooter;
