import { connect } from 'react-redux';
import Modal from './ModalCloseSlot';
import { Props, Actions } from './ModalCloseSlot.interfaces';
import { actions, selectors } from '../../store';
import { modalActions } from 'framework/actions';
import * as CONST from '../../constants';

const mapStateToProps = (state): Props => {
  const selectedEvent: any = selectors.getEventSelectedData(state);
  const initialValues = !selectedEvent
    ? {}
    : {
      [CONST.FIELD_CLOSE_SLOT_NOTE]: selectedEvent.isClosedNotes
    };

  return {
    initialValues,
    isOpen: !selectedEvent.isClosed
  };
};

const mapDispatchToProps = (dispatch): Actions => ({
  addCloser: () => dispatch(actions.saveCalendarSlotsCloseToggle()),
  deleteCloser: () => dispatch(actions.saveCalendarSlotsCloseToggle()),
  closeModal: () => dispatch(modalActions.createCloseModalAction(CONST.MODAL_CLOSE_SLOT))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
