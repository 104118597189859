import * as CONST from 'framework/modules/searchadmin/constants';
import store from 'framework/store';
import { createOpenModalAction, createCloseModalAction } from 'framework/components/ui/Modal/Modal.actions';

export default {
  FORM_NAME: CONST.FORM_MODAL_PRODUCTSEARCH,
  DATAGRID_NAME: CONST.DATA_GRID_MODAL_PRODUCTSEARCH,
  API_DATA_NAME: CONST.API_DATA_MODAL_PRODUCTSEARCH,
  API_DATA_RULE_DATA: CONST.API_DATA_RULE_DATA,

  // Actions
  ACTION_MODAL_OPEN: CONST.ACTION_MODAL_PRODUCTSEARCH_OPEN,
  ACTION_MODAL_CLOSE: CONST.ACTION_MODAL_PRODUCTSEARCH_CLOSE,
  ACTION_MODAL_SEARCH: CONST.ACTION_MODAL_PRODUCTSEARCH_SEARCH,
};

export const openProductSearchModal = (modal: string) => store.dispatch(
  createOpenModalAction(modal),
);

export const closeProductSearchModal = (modal: string) => store.dispatch(
  createCloseModalAction(modal),
);
