/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Icon } from 'framework/components/ui';
import { formField } from 'framework/components/hoc/formField';
import * as conf from 'framework/components/ui/_conf';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import StatusDropdownItem from './StatusDropdownItem';
import { defaultStatuses } from './StatusDropdown.constants';
import * as interfaces from './StatusDropdown.interfaces';
import './_styles.scss';

const className = conf.prefixClassName('sts-drop');

export const StatusDropdown = (props: interfaces.Props) => {
  const {
    readOnly, value, options, layout = 'default', onChange, onBlur, onFocus, disableClick,
  } = props;

  const { pathname } = useLocation();

  // internal state initialization
  const [state, setState] = React.useState<Partial<interfaces.State>>({
    dropdownHidden: true,
    direction: 'down',
  });

  // initialize options list: either from props if provided or take the default ones
  const [stateOptions, setStateOptions] = React.useState(options || defaultStatuses);

  const myRef = React.useRef();

  React.useEffect(() => {
    // if options list changed, then update internal options list
    const propsOptions = options || [];
    let hasChanged = propsOptions.length && options.length !== stateOptions.length;
    if (!hasChanged) {
      hasChanged = !!propsOptions.find((s) => !stateOptions.find((ss) => ss === s));
    }
    if (hasChanged) {
      setStateOptions(propsOptions);
    }
  }, [options, stateOptions]);

  const hiddeDropdown = () => {
    setState({
      ...state,
      dropdownHidden: true,
    });
  };

  const setActiveItemClickHandler = (val: string) => {
    ApplicationMonitoring.trackEvent(pathname, 'Status dropdown item', val);
    if (onChange) {
      onChange(val);
    }
    hiddeDropdown();
    if (onBlur) {
      onBlur(val);
    }
  };

  const getActiveOptionValue = disableClick ? stateOptions[0].value : value || stateOptions[0].value;

  const renderNonActiveItems = () => {
    const activeOptionValue = getActiveOptionValue;

    return stateOptions.map((op) => (
      op.value !== activeOptionValue ? (
      <StatusDropdownItem
        label={op.label}
        caption={op.caption}
        value={op.value}
        key={op.value}
        onClick={setActiveItemClickHandler}
        mappingColor={op.colorMapping}
        layout={layout}
      />
      ) : (
        undefined
      )));
  };

  const toggleClickHandler = () => {
    let direction = 'down';
    ApplicationMonitoring.trackEvent(pathname, 'Status dropdown item', 'toggle');
    if (
      (myRef as any).current.getBoundingClientRect().top + 160
      >= document.documentElement.clientHeight
    ) {
      direction = 'up';
    }
    if (state.dropdownHidden && onFocus) {
      onFocus(value);
    } else if (onBlur) {
      onBlur(value);
    }

    setState({ ...state, dropdownHidden: !state.dropdownHidden, direction });
  };

  let activeOption;
  if ( !disableClick ) {
    activeOption = stateOptions.find((v) => v.value === value);
  }
  if (!activeOption) {
    // we should prevent this case scenario
    // prop.value should always have a value
    [activeOption] = stateOptions;
  }

  return (
    <div
      className={classnames(
        `${className}`,
        readOnly && `${className}--readOnly`,
        !state.dropdownHidden && `${className}--open`,
        state.direction === 'up' && `${className}--direction-up`,
      )}
      ref={myRef}
    >
      {readOnly ? (
        <StatusDropdownItem
          label={activeOption.label}
          caption={activeOption.caption}
          value={activeOption.value}
          onClick={setActiveItemClickHandler}
          readOnly={readOnly}
          mappingColor={activeOption.colorMapping}
          layout={layout}
        />
      ) : (
        <OutsideClickHandler onOutsideClick={hiddeDropdown} disabled={state.dropdownHidden}>
          <StatusDropdownItem
            label={activeOption.label}
            caption={activeOption.caption}
            value={activeOption.value}
            onClick={toggleClickHandler}
            readOnly={readOnly}
            mappingColor={activeOption.colorMapping}
            layout={layout}
          />
          <span className={`${className}__toggle`} onClick={toggleClickHandler}>
            <Icon
              size="sm"
              className={`${className}__toggle__icon`}
              name={state.dropdownHidden ? 'dropdownArrowDown' : 'dropdownArrowUp'}
            />
          </span>

          <div
            className={classnames(`${className}__items`, `${className}__items--${layout}`, {
              [`${className}__items--hidden`]: state.dropdownHidden,
            })}
          >
            {renderNonActiveItems()}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default formField(StatusDropdown);