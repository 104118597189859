import type { ObjectMetadata, ColumnMetadata, HiddenColumns } from '../Table.interfaces';

export const getMetadataByID = (data: ColumnMetadata, id: string, getAll: boolean = false): any => {
  const results = [];
  // eslint-disable-next-line no-shadow
  const loopData = (data: any, id: string) => {
    let i;
    if (!data || typeof data !== 'object') {
      return;
    }
    const iterable = data.constructor === Object ? Object.keys(data) : data;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < iterable.length; i++) {
      const key = iterable[i];
      if (data.constructor === Object) {
        if (key === 'id' && data[key] === id) {
          results.push(data);
          if (!getAll) {
            break;
          }
        }
        loopData(data[key], id);
      } else {
        loopData(key, id);
      }
    }
  };
  loopData(data, id);
  return getAll ? results : results[0];
};
export const queryTableData = (data: object[], queryKey: string, queryValue: any, getAll: boolean = false): any => {
  const results = [];
  // eslint-disable-next-line no-shadow
  const loopData = (data: any, id: string) => {
    let i;
    if (!data || typeof data !== 'object') {
      return;
    }
    const iterable = data.constructor === Object ? Object.keys(data) : data;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < iterable.length; i++) {
      const key = iterable[i];
      if (data.constructor === Object) {
        if (key === queryKey && data[key] === queryValue) {
          results.push(data);
          if (!getAll) {
            break;
          }
        }
        loopData(data[key], id);
      } else {
        loopData(key, id);
      }
    }
  };
  loopData(data, queryKey);
  return getAll ? results : results[0];
};
export const replaceMetadataByID = (metadata: any, id: string, data: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in metadata) {
    if (key === id) {
      // eslint-disable-next-line no-param-reassign
      metadata[key] = data;
    }
  }
};
export const interpolateData = (data: any, metadata: object[]) => {
  // eslint-disable-next-line no-useless-escape
  const dataPlaceholderRegEx = /{[^\{\}]*}/g;
  const getPropertyByStringPath = (key, object) => {
    const path = key.split('.');
    const { length } = path;
    let property = object;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      try {
        property = property[path[i]];
      } catch (error) {
        property = '';
        // property = `looking up ${key}`;
      }
    }
    if (typeof property === 'undefined' || property === null) {
      return '';
    }
    return property;
  };
  const replaceRecursively = (obj: any, d: any) => {
    // replace the occurrences of {key} (data placeholders) in the columnMetadata
    // with the actual value coming from the API
    let newObj = obj;
    // enforce immutability of arrays & objects
    if (typeof obj === 'object') {
      newObj = { ...obj };
    }
    if (Array.isArray(obj)) {
      newObj = [...obj];
    }
    Object.keys(newObj).forEach((key) => {
      if (typeof newObj[key] === 'object') {
        // if object, recursively loop
        newObj[key] = replaceRecursively(newObj[key], d);
      } else if (typeof newObj[key] === 'string' && newObj[key].match(dataPlaceholderRegEx)) {
        // if string, look for data placeholders {key} and replace it with data.key
        const dataPlaceholders = newObj[key].match(dataPlaceholderRegEx);
        // eslint-disable-next-line no-restricted-syntax
        for (const dataPlaceholder of dataPlaceholders) {
          const dataPoint = dataPlaceholder.substring(1, dataPlaceholder.length - 1);
          if (dataPoint === '*') {
            newObj[key] = { ...data };
          } else {
            const dataToInterpolate = getPropertyByStringPath(dataPoint, data);
            switch (dataToInterpolate.constructor) {
              case Array:
                newObj[key] = [...dataToInterpolate];
                break;
              case Object:
                newObj[key] = { ...dataToInterpolate };
                break;
              default:
                newObj[key] = newObj[key].replace(`{${dataPoint}}`, dataToInterpolate);
                break;
            }
          }
        }
      }
    });
    return newObj;
  };
  const interpolated = metadata.map((item: any) => replaceRecursively(item, data));
  return interpolated;
};

export const transformMetadata = (metadata: ColumnMetadata, data: any[]): ObjectMetadata[] => {
  const resolved = typeof metadata === 'function' ? metadata(data) : metadata;

  return resolved.map((m) => (typeof m === 'string' ? { caption: m } : m));
};

export const hideColumns = (metadata: ObjectMetadata[], hidden: HiddenColumns[]) => {
  const stringHidden = hidden.map((m) => String(m));
  return metadata.filter((_m, i) => stringHidden.indexOf(i.toString()) === -1);
};

export const updatePosition = <T>(attributeId, newIndex: number, draggableId: string, data: T[]) => {
  if (!attributeId || newIndex < 0 || !draggableId ) return data;
  const items = [...data];
  const attributeIndex = items.findIndex((p) => p[draggableId] === attributeId);
  const [product] = items.splice(attributeIndex, 1);
  items.splice(newIndex, 0, product);
  return items;
};