import uuidv4 from 'uuid/v4';
import { eventTemplates } from '../../../../../modules/slotManagement/pages/LiveTimeSlotView/constants';

export default (
  state,
  payload: {
    calendarName: string;
    events: any;
    action:any;
  }
) => {
  const { calendarName, events = [] } = payload;
  let hasInstacartSlots = false;
  let hasOrphanedSlots = false;

  const eventsObject = {};

  events.forEach((event) => {
    const id = event.id || uuidv4();

    if (event.template === eventTemplates.orphanSlot) {
      hasOrphanedSlots = true;
    }
    
    if (event.data?.fulfilmentType === 'Instacart' || event.data?.fulfillmentType?.value === 'Instacart') {
      hasInstacartSlots = true;
    }
    eventsObject[id] = { ...event, id };
  });

  const newBlockedSlotsObject = {};
  
  if (state[calendarName]?.newBlockedSlots?.length > 0) {
    state[calendarName]?.newBlockedSlots?.forEach((event: any) => {
      const id = event.id || uuidv4();
  
      newBlockedSlotsObject[id] = { ...event, id };
    });
  }

  return {
    ...state,
    [calendarName]: {
      ...state[calendarName],
      isLoading: false,
      events: { ...eventsObject, ...newBlockedSlotsObject },
      eventsOriginal: { ...eventsObject },
      eventsDeleted: state[calendarName]?.eventsDeleted?.length >= 1 ? state[calendarName].eventsDeleted : [],
      hasInstacartSlots,
      hasOrphanedSlots
    },
  };
};
