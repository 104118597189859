import * as React from 'react';
import { Icon, LayoutFlexBox, LayoutFlexBoxItem, Select, TagsField } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ISelectPrimitiveOptionsProps } from 'framework/components/ui/FormComponents/Primitives/SelectPrimitive/SelectPrimitive.interface';

const className = prefixClassName('boost-and-bury-filter');

interface IActionsBoostAndBuryFilter {
  field: string;
  fieldIndex: number;
  remove: (number) => {};
  actionFieldOptions: ISelectPrimitiveOptionsProps<string>[];
  actionOperationOptions: ISelectPrimitiveOptionsProps<string>[];
  fetchCurrentData: () => void;
}

export default (props: IActionsBoostAndBuryFilter) => {
  const { field, fieldIndex, remove, actionFieldOptions, actionOperationOptions, fetchCurrentData } = props;

  const onDeleteClickHandler = () => {
    remove(fieldIndex);
    fetchCurrentData();
  };

  return (
    <LayoutFlexBox flexWrap="nowrap">
      <LayoutFlexBoxItem className="mrg-btm-10 relative" flexBasis="100%">
        <LayoutFlexBox justifyContent="space-between" alignItems="flex-end">
          <LayoutFlexBoxItem flexBasis="47%">
            <Select
              caption="modules.merchandising.rules.create.andFilterByLabel"
              name={`${field}.field`}
              options={actionFieldOptions}
              placeholder="components.select.placeholder"
              onChange={fetchCurrentData}
              mandatory
            />
          </LayoutFlexBoxItem>
          <LayoutFlexBoxItem flexBasis="47%">
            <Select
              name={`${field}.operation`}
              options={actionOperationOptions}
              placeholder="components.select.placeholder"
              onChange={fetchCurrentData}
              mandatory
            />
          </LayoutFlexBoxItem>
        </LayoutFlexBox>
        <LayoutFlexBoxItem className="mrg-btm-20">
          <TagsField
            name={`${field}.values`}
            className="mrg-top-20"
            divider=","
            onChange={fetchCurrentData}
            mandatory
          />
        </LayoutFlexBoxItem>
        <Icon
          onClick={onDeleteClickHandler}
          className={`${className} ${className}__delete mrg-auto mrg-top-10 block cursor-pointer`}
          name="trash"
          size="sm"
          color="#96a0ab"
        />
      </LayoutFlexBoxItem>
    </LayoutFlexBox>
  );
};
