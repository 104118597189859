import { IEventComponent } from "../../../../../components/ui/Calendar/components/Event/Event.interfaces";
import { i18n } from '../../../../../../i18n';
import { LIST_VIEW_TRANSLATION_BASE } from '../../common/ListView/constants';
import { SlotBulkActions } from './constants';


export const getBulkOption = (selectedSlots: IEventComponent[]) => {
    if (selectedSlots.length === 0) return [];

    const { someExpired, allClosed, allOpen } = selectedSlots.reduce(
      (acc, slot) => ({
        someExpired: acc.someExpired || slot.data?.isExpired,
        allClosed: acc.allClosed && slot.data?.isClosed,
        allOpen: acc.allOpen && !slot.data?.isClosed,
      }),
      { someExpired: false, allClosed: true, allOpen: true }
    );
    
    if (someExpired) {
      return [];
    }

    const options = [
      {
        label: i18n.t(`${LIST_VIEW_TRANSLATION_BASE}.columns.totalCapacity`),
        value: SlotBulkActions.EditTotalCapacity,
      }
    ];

    if (allClosed) {
      options.push({
        label: i18n.t(`${LIST_VIEW_TRANSLATION_BASE}.columns.reopenSlots`),
        value: SlotBulkActions.OpenSlot,
      });
    }

    if (allOpen) {
      options.push({
        label: i18n.t(`${LIST_VIEW_TRANSLATION_BASE}.columns.closeSlots`),
        value: SlotBulkActions.CloseSlot,
      });
    }

    return options;
  };