const root = 'modules.contentmanagement.dam';

export const translations = {
  uploadNew: `${root}.upload_new`,
  upload: `${root}.upload`,
  selectLocation: `${root}.select_location`,
  noContent: `${root}.no_content`,
  fileToUpload: `${root}.file_to_upload`,
  noFileSelected: `${root}.no_file_selected`,
  fileOverWrite: `${root}.file_overwrite`,
  contentAssets: `${root}.content_assets`,
  confirmDeleteFile: `${root}.confirm_delete_file`,
  shouldSelectLocation: `${root}.should_select_location`,
  fileNotFound: `${root}.file_not_found`,
  useExisting: 'modules.contentmanagement.use_existing_file',
};
