import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import { CheckboxPrimitive as CheckBox } from 'framework/components/ui/FormComponents/Primitives/CheckboxPrimitive';
import classNames from 'classnames';
import { Button, Icon, Text } from 'ui';
import * as _get from 'lodash.get';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useLocation } from 'react-router-dom';
import { ToggleRowButton } from './hierarchy.togglebtn';
import { HierarchyContext } from './hierarchy.provider';
import { HierarchyRowProps } from './hierarchy.interfaces';
// eslint-disable-next-line import/no-cycle
import { HierarchyChildrenRows } from './hierarchy.children';
import RadioItem from '../FormComponents/Primitives/RadioGroupPrimitive/RadioGroup.Item';

const hierarchyClassName = conf.prefixClassName('hierarchy');

const DEFAULT_MAX_DISPLAYED_CHILDREN = 10;
const MAX_DISPLAYED_INCREMENT = 10;

export const HierarchyRow = (props: HierarchyRowProps) => {
  const context = React.useContext(HierarchyContext);
  const {
    toggleRow, highlightable, highlightedId, getIconClassName, leafIcon, nodeIcon, columns,
    rowIsExpanded, getNodeIsLeaf, selectable, rowIsSelected, singleSelect, rowIsIntermediary,
    rowHasAllChildrenSelected, disabled, allowShowMore,  isFiltered, useIsFiltered = false
  } = context;

  const {
    currentNode, level, selectLeavesOnly, hideIntermediary, allowSingleLeaf, trackEventProps, filteredData,
  } = props;
  const { pathname } = useLocation();
  const [maxDisplayed, setMaxDisplayed] = React.useState<number>(DEFAULT_MAX_DISPLAYED_CHILDREN);

  const getColumnBaseContent = (node, column, boldDescriptionText) => {
    const Content: any = column.content;
    const boldClassName: string = `${hierarchyClassName}__bold-description`;

    return typeof column.content === 'function' ? (
      // allow pageObject "toggle row" action for custom column component
      <Content node={node} toggleRow={toggleRow} className={boldDescriptionText ? boldClassName : ''} />
    ) : (
      <Text tag="span" className={boldDescriptionText ? boldClassName : ''} caption={_get(node, column.content)} />
    );
  };

  const shouldDisplayIcon = (
    node, isLeaf, displayIconCallBack,
  ) => (displayIconCallBack ? displayIconCallBack(node, isLeaf) : true);

  const getClickHandler = (columnProps) => ({
    onClick: (e) => {
      e.stopPropagation();
      if (context.highlightable) {
        context.highlightRow(columnProps.currentNode);
      }
    },
  });

  const handleOnClickHighlight = (e, columnProps): void => {
    if (!context.highlightable) {
      e.stopPropagation();
      columnProps.onToggleRow();
    }
  };

  const getFirstColumnContent = (columnProps) => {
    const {
      currentNode: columnNode, displayPrefix, longWidth, displayCheckBox,
      rowSelected, onToggleCheck, allChildrenSelected, intermediate, displayIcon,
      getNodeIcon, isLeaf, ColumnContent,
    } = columnProps;
    return (
      <span
        className={classNames(
          `${hierarchyClassName}__column__inner`,
          highlightable && `${hierarchyClassName}--highlightable`,
          highlightedId === columnNode.id && `${hierarchyClassName}--highlighted`,
        )}
      {...getClickHandler(columnProps)}
      >
        {displayPrefix && (
          <span
            className={classNames(
              `${hierarchyClassName}__column-prefix`,
              longWidth && `${hierarchyClassName}__column-prefix--long-width`,
            )}
          >
            {displayCheckBox && (
              <span className={`${hierarchyClassName}__checkbox`}>
                {
                  singleSelect
                    ? (<RadioItem
                      value={rowSelected}
                      checked={rowSelected}
                      onChange={onToggleCheck}
                      disabled={disabled}
                      name={currentNode.id}
                      id={`${currentNode.id}-select`}
                      tag="span"
                      className={`${hierarchyClassName}__checkbox__radio`}
                    />)
                    : (<CheckBox
                      value={rowSelected || allChildrenSelected}
                      intermediate={!hideIntermediary && intermediate && !allChildrenSelected}
                      onChange={onToggleCheck}
                      disabled={disabled}
                      name={currentNode.id}
                    />)
                }
              </span>
            )}
            {displayIcon && (
              <span className={classNames(`${hierarchyClassName}__node-icon`,
                `${hierarchyClassName}--highlightable`)}
              {...{
                onClick: (e) => handleOnClickHighlight(e, columnProps),
              } as any}
              >
                {getNodeIcon ? (
                  <Icon
                    className={classNames(
                      getIconClassName
                      && getIconClassName(currentNode, isLeaf),
                    )}
                    name={getNodeIcon(currentNode, isLeaf)}
                  />
                ) : (
                    <Icon
                      className={classNames(
                        getIconClassName
                        && getIconClassName(currentNode, isLeaf),
                      )}
                      name={isLeaf ? leafIcon : nodeIcon}
                    />
                )}
              </span>
            )}
          </span>
        )}
        <span
          {...{
            onClick: (e) => handleOnClickHighlight(e, columnProps),
          } as any}
        >
          {ColumnContent}
        </span>
      </span>
    );
  };

  React.useEffect(() => {
    if (useIsFiltered) {
      if (isFiltered) context.expandRow(currentNode.id);
      if (!isFiltered) context.collapseRow(currentNode.id);
    }
  }, [filteredData?.length]);

  const handleToggleRow = () => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Page hierarchy',
      trackEventProps?.label || currentNode?.value?.description || currentNode.id
    );
    context.toggleRow(currentNode.id);
  };

  const handleToggleCheck = () => {
    if (singleSelect) {
      context.resetSelectedIds();
    }
    context.toggleCheck(currentNode, singleSelect);
  };

  const rowExpanded = rowIsExpanded(currentNode.id);
  const rowIsLeaf = getNodeIsLeaf
    ? getNodeIsLeaf(currentNode, !currentNode.children)
    : !currentNode.children;
  const showChildren = !rowIsLeaf && rowExpanded;
  const displayIcon = shouldDisplayIcon(currentNode, rowIsLeaf, shouldDisplayIcon);
  const longWidth = displayIcon && selectable && !(rowIsLeaf && !leafIcon);
  const displayPrefix = displayIcon || selectable;
  const rowSelected = rowIsSelected(currentNode.id, currentNode?.parent?.id, allowSingleLeaf);
  const allChildrenSelected = rowHasAllChildrenSelected(currentNode);
  // eslint-disable-next-line no-underscore-dangle
  const __rowIsIntermediary = !rowSelected && !singleSelect && rowIsIntermediary(currentNode);
  const boldDescriptionText = singleSelect && !rowSelected && rowIsIntermediary(currentNode);
  const noChildren = !currentNode.children || !currentNode.children.length;
  const { sortBy } = React.useContext(HierarchyContext);
  const sortedChildren = sortBy ? [...currentNode.children].sort(sortBy) : currentNode.children;

  const indentation = level > 0;
  const columnMargin = indentation ? { marginLeft: level * (level ? 42 : 35) } : {};

  return (
    <>
      <tr className={classNames(`${hierarchyClassName}__row`)} id={`${currentNode?.id}-row`}>
        {columns.map((column, i) => {
          const isFirstColumn = i === 0;

          let ColumnContent: any = getColumnBaseContent(currentNode, column, boldDescriptionText);
          const displayCheckBox = context.selectable && (!selectLeavesOnly || rowIsLeaf);

          if (isFirstColumn) {
            ColumnContent = getFirstColumnContent({
              displayPrefix,
              displayCheckBox,
              displayIcon,
              getNodeIcon: context.getNodeIcon,
              intermediate: __rowIsIntermediary,
              isLeaf: rowIsLeaf,
              longWidth,
              currentNode,
              onToggleRow: handleToggleRow,
              onToggleCheck: handleToggleCheck,
              rowSelected,
              allChildrenSelected,
              ColumnContent,
            });
          }

          const ToggleBtn: any = !isFirstColumn || rowIsLeaf ? (
              <></>
          ) : (
                <ToggleRowButton
                  toggle={handleToggleRow}
                  expanded={rowExpanded}
                  noChildren={noChildren}
                />
          );

          return (
            <td key={i} className={classNames(`${hierarchyClassName}__column`, column.className)}>
              <span style={isFirstColumn ? columnMargin : {}} className={`${hierarchyClassName}__column__wrapper`}>
                {isFirstColumn && (
                  <span
                    className={classNames(
                      `${hierarchyClassName}__icons`,
                      indentation && rowIsLeaf && `${hierarchyClassName}__icons--inner-most`,
                    )}
                  >
                    {ToggleBtn}
                  </span>
                )}

                <span
                  className={classNames(
                    isFirstColumn
                    && !level
                    && rowIsLeaf
                    && `${hierarchyClassName}__push-inner`,
                  )}
                >
                  {ColumnContent}
                </span>
              </span>
            </td>
          );
        })}
      </tr>
      {showChildren && (
        <>
          <HierarchyChildrenRows
            childrenRows={
              (allowShowMore && sortedChildren.length > maxDisplayed)
                ? sortedChildren.slice(0, maxDisplayed)
                : sortedChildren
            }
            level={level + 1}
            singleSelect={singleSelect}
            selectLeavesOnly={selectLeavesOnly}
            hideIntermediary={hideIntermediary}
            allowSingleLeaf={allowSingleLeaf}
          />
          {
            (allowShowMore && sortedChildren.length > maxDisplayed && showChildren) && (
              <Button
                caption="generic.showMore"
                onClick={() => setMaxDisplayed(maxDisplayed + MAX_DISPLAYED_INCREMENT)}
                style={{
                  marginLeft: columnMargin.marginLeft ?? 0
                }}
                className={`${hierarchyClassName}__show-more`}
                testId={`${currentNode?.id}-show-more`}
                link
              />
            )
          }
        </>
      )}
    </>
  );
};
