import './styles.scss';
import * as React from 'react';
import { useState, useEffect } from 'react';
import * as conf from 'framework/components/ui/_conf';
import classNames from 'classnames';
import { useAuthUser } from 'framework/authorization/useAuthUser';
import { ModuleConfig, RouteGroup, GetRouteGroupFunc } from './Navigation.interfaces';
import { NavigationSubNavMenu, LoadingSubMenus } from './NavigationSubNavMenu';
import { Icon } from '../ui/Icon';
import { Text } from '../ui/Text';
import { Header } from '../ui/Header';
import { resolveTitle } from './service';

const navigationClass = conf.prefixClassName('navigation');
const subnavClassname = `${navigationClass}__SubNav`;
const pageGroupsClassName = conf.prefixClassName('pagegroups');

interface Props {
  appModule: ModuleConfig;
  isOpened?: boolean;
  onSelectNav: (e: any) => void;
}
type RouteGroupwithLoading = RouteGroup & { loading?: boolean };
type Group = RouteGroupwithLoading[] | GetRouteGroupFunc;

export const NavigationSubNav = ({ appModule, onSelectNav, isOpened }: Props) => {
  const groupIsFunc = () => {
    const pageGroups = appModule?.pageGroups || [];
    return typeof pageGroups === 'function';
  };
  const getGroups = () => (groupIsFunc() ? [] : appModule?.pageGroups || []);

  const [groups, setGroups] = useState<Group>(getGroups());

  const handleCloseSubNav = (e: any) => {
    onSelectNav(e);
  };

  useEffect(() => {
    if (groupIsFunc()) {
      setGroups([
        {
          title: '',
          loading: true,
          routes: [],
        },
      ]);
      (appModule.pageGroups as GetRouteGroupFunc)().then((res) => setGroups(res));
    } else {
      setGroups(getGroups());
    }
  }, [appModule]);

  return (
    <div
      className={classNames({
        [subnavClassname]: true,
        [`${subnavClassname}--opened`]: isOpened,
      })}
    >
      <div className={`${subnavClassname}__container`}>
        {appModule && <Header headerLevel={2} caption={appModule.appMenuTitle} />}
        {(groups as RouteGroup[]).map((group, i) => (
          <AuthorizedSubMenus key={i} group={group} onSelectNav={onSelectNav} />
        ))}
      </div>
      {isOpened && <Icon name="close" className={`${subnavClassname}__closebtn`} onClick={handleCloseSubNav} />}
    </div>
  );
};
type AuthorizedMenusProps = {
  group: RouteGroupwithLoading;
  onSelectNav: (e: any) => void;
};
export const AuthorizedSubMenus = ({ group, onSelectNav }: AuthorizedMenusProps) => {
  const { ensureClaims } = useAuthUser();
  // routes without claims are public routes
  const authorizedRoutes = group.routes.filter((r) => !r.authClaim || ensureClaims(r.authClaim));
  if (!group.loading && !authorizedRoutes.length) {
    return <></>;
  }
  return (
    <div
      className={classNames({
        [pageGroupsClassName]: true,
        [`${pageGroupsClassName}__bottom-divider`]: group.bottomDivider,
      })}
    >
      <Text caption={resolveTitle(group.title)} className={`${pageGroupsClassName}__header`} tag="div" />
      <ul className={`${subnavClassname}__links`}>
        {group.loading && <LoadingSubMenus />}
        {!group.loading &&
          authorizedRoutes.map((route) => (
            <NavigationSubNavMenu key={route.path} route={route} onSelectNav={onSelectNav} />
          ))}
      </ul>
    </div>
  );
};
