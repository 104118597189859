import { call, select, put } from 'redux-saga/effects';
import { tabsActions } from 'framework/actions';
import { toastError, toastSuccess } from 'stores/toast';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest, makeRequestAndSaveState } from 'framework/api/make-request';
import { config, DEFAULT_LOCALE_ID } from 'config';
import { RecommendationDetails } from 'framework/modules/merchandising/models';
import {
  API_DATA_RECOMMENDATION_DATA,
  ACTION_RECOMMENDATION_SAVE_DETAILS,
} from 'framework/modules/merchandising/constants';
import { Recommendation as translation } from 'framework/modules/merchandising/translations';
import { goToRoute } from 'framework/components/navigation/service';
import { recommendationEditRoute } from 'framework/modules/merchandising/routes';
import { recommendationDefault } from './helpers';
import { TABS_NAME, TAB_RULES } from '../../constants';
import { getRecommendationData, getFormValuesDetails } from '../selectors';

function* process({ payload: { id } }) {
  const dataOriginal = yield select(getRecommendationData);
  const formValues = yield select(getFormValuesDetails);

  // 1. Make Save Request
  const recommendation: RecommendationDetails = {
    ...recommendationDefault,
    ...dataOriginal,
    ...formValues,
    id,
  };

  const { success } = yield call(makeRequest, {
    name: 'saveRecommendationDetails',
    method: 'PUT',
    url: `${config.apiEndpoints.recommendations}/${recommendation.id}`,
    body: {
      ...recommendation,
    },
  });

  // 2. Navigate to Rules List Page
  if (success) {
    // 2.1 Update data
    yield call(makeRequestAndSaveState, {
      name: API_DATA_RECOMMENDATION_DATA,
      method: 'GET',
      url: `${config.apiEndpoints.api}/v2/locales/${DEFAULT_LOCALE_ID}/recommendations/${
        recommendation.id
      }`,
    });

    // // 2.2 change tab
    yield put(toastSuccess(translation.SaveSuccess));
    yield call(goToRoute, recommendationEditRoute, { recommendationId: recommendation.id });
    yield put(tabsActions.setOpenTab(TABS_NAME, TAB_RULES));
  } else {
    yield put(toastError(translation.SaveError));
  }
}

export function watchSaveDetails() {
  return takeEveryWithProgressBar(ACTION_RECOMMENDATION_SAVE_DETAILS, process);
}
