import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import * as classnames from 'classnames';
import i18n from 'i18n';
import { toKebabCase } from 'framework/utils/string';
import { Layout } from './StatusDropdown.interfaces';

const className = conf.prefixClassName('sts-drop');

interface StatuDropdownItemProps {
  label: string;
  caption: string;
  value: any;
  readOnly?: boolean;
  onClick: (e: any) => void;
  mappingColor?: string;
  layout: Layout;
}

const StatuDropdownItem = (props: StatuDropdownItemProps) => {
  // This is nasty, ugly, brutal, horrawful but agreed with Paul that we do it like this
  // until they figure out way of sending enums via api
  // TODO: when enums are designed, adjust this component and test it then
  const {
    caption,
    mappingColor,
    onClick,
    readOnly,
    value,
    label,
    layout,
  } = props;
  const captionClassName = mappingColor || caption || value;
  const itemClassName = toKebabCase(captionClassName);
  return (
    <span
      className={classnames(`${className}-item`,
        `${className}-item--${itemClassName}`,
        `${className}-item--${layout}`, {
          [`${className}-item--readOnly`]: readOnly,
        })}
      onClick={() => onClick(value)}
    >
      {!!caption && caption}
      {label && i18n.t(label)}
    </span>
  );
};

export default StatuDropdownItem;
