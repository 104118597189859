import * as React from 'react';
import config from 'config';
import { fetchData } from 'framework/api/fetch-data';

export interface Currency {
  country: string;
  name: string;
  code: string;
}

const useCurrencies = () => {
  const [currencies, setCurrencies] = React.useState([]);

  const fetchCurrencies = async () => {
    const { data, success } = await fetchData<Currency[]>({
      url: `${config.apiEndpoints.referencedata.currencies}`,
    });
    setCurrencies(success ? data : []);
  };

  React.useEffect(() => {
    fetchCurrencies();
  }, []);

  return currencies;
};

export { useCurrencies };
