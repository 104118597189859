import * as React from 'react';
import { AnimatedBG, DataGrid, FilterBarStencil, FormField, Panel, Text } from './stencilsTemplates';

const stencilsMapping = (component: string, hasTop?: boolean, hasBottom?: boolean, rows?: number) => {
  const stencilsComponent = {
    'data-grid': <DataGrid hasTop={hasTop} hasBottom={hasBottom} rows={rows} />,
    default: <AnimatedBG />,
    'form-field': <FormField />,
    panel: <Panel rows={rows} />,
    text: <Text />,
    'filter-bar': <FilterBarStencil />
  };
  return stencilsComponent[component];
};

export default stencilsMapping;
