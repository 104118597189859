import * as React from 'react';
import classNames from 'classnames';
import { Icon, Button, Text } from 'ui';
import { IMenuButton } from '../Menu.interfaces';
import './_style.scss';

export const MenuButton = (props: IMenuButton) => {
  const {
    onClick = () => {},
    active,
    className,
    text,
    icon,
    notificationsNotSeenTotal,
  } = props;

  return (
    <Button
      onClick={onClick}
      aria-expanded={active}
      className={classNames(
        className,
        active && `${className}--active`,
      )}
      testId="myNotifications"
    >
      { notificationsNotSeenTotal > 0 &&
        <div className={`${className}--totalCounter`}>{notificationsNotSeenTotal}</div>
      }
      <Icon
        name={icon}
        size="sm"
        className={classNames(`${className}--button-icon`, active && `${className}--button-icon--active`)}
      />
      <Text
        caption={text}
        className={`${className}--button-text`}
      />
    </Button>
  );
};

export default MenuButton;
