import React from 'react';
import { view } from '@risingstack/react-easy-state';
import { ConfirmationModalFn } from 'framework/components/ui/ConfirmationModal/ConfirmationModal';
import { FileManagerAddFolder } from './FileManagerComponents';
import { FileManagerMoveFileModal } from './FileManagerMoveFileModal';
import { useFileManagerContextInternal, FileManagerContext } from './FileManagerContext';
import { FILEMANAGER_DELETE_CONFIRM_MODAL } from './constants';
import { FileFolderType } from './types';

type FileManagerContextProvider = {
  confirmationModalName?: string;
  children: React.ReactNode;
};

export const FileManagerContextProvider = view(({
  confirmationModalName,
  children,
}: FileManagerContextProvider) => {
  const {
    forRoot,
    activeFolder,
    rootFolderModalOpen,
    moveFileModalOpen,
    previewableFile,
    store,
    deleteMode,
    onDeleteFunc,
    onFileMoved,
    onSubFolderCreated,
    onFolderCreated,
    ...rest
  } = useFileManagerContextInternal(confirmationModalName);

  return (
        <FileManagerContext.Provider value={{
          previewableFile,
          activeFolder,
          store,
          ...rest,
        }}>
          <ConfirmationModalFn
            name={confirmationModalName || FILEMANAGER_DELETE_CONFIRM_MODAL}
            warning={deleteMode === FileFolderType.DIRECTORY
              ? 'modules.contentmanagement.confirmDeleteFolder'
              : 'modules.contentmanagement.dam.confirm_delete_file' }
            onConfirmDelete={onDeleteFunc}
          />
          {rootFolderModalOpen && (
          <FileManagerAddFolder
            parentFolder={forRoot ? activeFolder?.reference : undefined}
            onFolderCreated={forRoot ? onSubFolderCreated : onFolderCreated}
          />
          )}
          {moveFileModalOpen && (
            <FileManagerMoveFileModal
              movableFile={previewableFile}
              onFileMoved={onFileMoved}
            />
          )}
            {children}
        </FileManagerContext.Provider>
  );
});
