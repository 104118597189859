import { put, takeEvery, select } from 'redux-saga/effects';
import { safeGet } from 'framework/utils';
import { calendarActions, modalActions } from 'framework/actions';
import { formSelectors } from 'framework/selectors';
import { VIEWCALENDAR_SLOT_BLOCKER_ADD } from '../actions.constants';
import * as CONST_PAGE from '../../constants';
import { selectors } from '..';
import { dateSetTime } from './_helpers';

interface ITime {
  hours: number;
  minutes: number;
}

export function* process() {
  const formValues = yield select(formSelectors.getFormValues, CONST_PAGE.MODAL_BLOCKER_SLOT);
  const selectedSlot: any = yield select(selectors.getEventSelected);
  const templates = CONST_PAGE.eventTemplates;

  const day: Date = formValues[CONST_PAGE.FIELD_BLOCKER_SLOT_DAY];
  const timeStart: ITime = formValues[CONST_PAGE.FIELD_BLOCKER_SLOT_TIME_INI];
  const timeEnd: ITime = formValues[CONST_PAGE.FIELD_BLOCKER_SLOT_TIME_END];
  const start = dateSetTime(day, timeStart);
  const end = dateSetTime(day, timeEnd);
  const blockerSlot: any = {
    start,
    end,
    id: safeGet(selectedSlot, 'id', null),
    template: templates.blocker,
    data: {
      start,
      end,
      timeStart,
      timeEnd,
      shoppingModes: formValues[CONST_PAGE.FIELD_BLOCKER_SLOT_SHOPPINGMODE],
      note: formValues[CONST_PAGE.FIELD_BLOCKER_SLOT_NOTE] || '',
    },
  };

  yield put(modalActions.createCloseModalAction(CONST_PAGE.MODAL_BLOCKER_SLOT));
  yield put(calendarActions.addEvent(CONST_PAGE.CALENDAR_NAME, blockerSlot));
}

export default function* watchEditCalendarBlockerAction() {
  yield takeEvery(VIEWCALENDAR_SLOT_BLOCKER_ADD, process);
}