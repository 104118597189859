/* eslint-disable import/no-cycle */
import * as React from 'react';
import withStencils from 'framework/components/hoc/withStencils';
import { IFetchDataRequest } from 'framework/api/fetch-data';
import { save } from 'framework/services/localStorage/localStorage';
import { Table, ITableBase } from '../Table';
import { CellRenderer, ObjectMetadata } from '../Table/Table.interfaces';
import PaginationDefault, { IPagination, Pagination } from '../Pagination';
import { useApiTableData, onDataFetchedType } from './useApiTableData';
import { usePagination, OnPageChangedType, SkipTake } from './usePagination';
import { prefixClassName } from '../_conf';
import { SelectMenuPlacements } from '../FormComponents/Select/interfaces';

const PAGE_SIZE_SELECTION = 'PAGE_SIZE_SELECTION';

const className = prefixClassName('data-grid');

export interface PaginationType {
  pagination?: SkipTake;
  onPageChanged?: OnPageChangedType;
}

export type RenderRow = (data: any, info: RenderRowInfo) => React.ReactNode;

interface RenderRowInfo {
  rowIndex: number,
  tableName: string,
  metadata: ObjectMetadata[],
  hiddenColumns?: string[],
  itemsNo?: number
}

interface ConnectedTableProps extends ITableBase, PaginationType {
  requestDetails: IFetchDataRequest;
  renderRow?: RenderRow;
  /**
   * Intercept the api return before the data is passed on to the Table component, use this
   * callback to fetch additional data if required.
   * Case the API doesn't return the data in the correct format, use this function temporarily
   * to format the received data AND open a ticket for the responsible team to fix it.
   * If you use this callback to update some state in your component, wrap your function with a React.useCallback,
   * it will prevent 2 api calls from being made
   */
  onDataFetched?: onDataFetchedType;
  actions?: React.ComponentProps<typeof Table>['actions'],
  onActionInvoked?: React.ComponentProps<typeof Table>['onActionInvoked'],
  loadingItems?: React.ComponentProps<typeof Table>['loadingItems'],
}

const StencilledTable = withStencils('data-grid')(Table);

interface ConnectedPaginationProps {
  isFetching: boolean;
  paginationProps: IPagination;
  menuPlacement?: SelectMenuPlacements;
  onSetPageSizeHandler?: (option: { value: string }) => void;
}
const ConnectedPagination = (
  {
    isFetching,
    paginationProps,
    onSetPageSizeHandler = undefined,
    menuPlacement = SelectMenuPlacements.Bottom,
  }: ConnectedPaginationProps) => {
  return <>
    {!isFetching && onSetPageSizeHandler
      ? <Pagination
        className={`${className}-paging`}
        pageSize={paginationProps.pageSize}
        recordCount={paginationProps.recordCount}
        currentPage={paginationProps.currentPage}
        onNext={paginationProps.onNext}
        onPrevious={paginationProps.onPrevious}
        onGetPage={paginationProps.onGetPage}
        onSetPageSizeHandler={onSetPageSizeHandler}
        menuPlacement={menuPlacement}
      />
      : <PaginationDefault className={`${className}-paging`} menuPlacement={menuPlacement} {...paginationProps} />
    }
    </>;
};

export const ConnectedTable = React.memo((props: ConnectedTableProps) => {
  const {
    requestDetails,
    pagination,
    renderRow,
    onDataFetched,
    onPageChanged,
    ...tableProps
  } = props;

  const [skipTake, setSkipTake] = React.useState<SkipTake>(pagination);

  const paginationHook = usePagination(
    requestDetails,
    skipTake,
    onPageChanged,
  );

  const { data, isFetching } = useApiTableData(
    paginationHook.requestWithPagination,
    paginationHook.setRecordCount,
    onDataFetched,
  );

  const cellRenderer: CellRenderer = renderRow ? (
    (tableName, rowData, metadata, rowIndex) => renderRow(rowData, {
      tableName, metadata, rowIndex, itemsNo: data.length,
    })
  ) : undefined;

  return (
    <div className={className}>
      <ConnectedPagination
        isFetching={isFetching}
        paginationProps={paginationHook.paginationProps}
        onSetPageSizeHandler={(item: {value: any}) => {
          save(PAGE_SIZE_SELECTION, item.value);
          setSkipTake({skip: 0, take: parseInt(item.value, 10)});
        }}
      />
      <StencilledTable
        {...tableProps}
        stencilled={isFetching}
        data={data}
        cellRenderer={cellRenderer}
      />
      <ConnectedPagination
        isFetching={isFetching}
        paginationProps={paginationHook.paginationProps}
        onSetPageSizeHandler={(item: {value: any}) => {
          save(PAGE_SIZE_SELECTION, item.value);
          setSkipTake({skip: 0, take: parseInt(item.value, 10)});
        }}
        menuPlacement={SelectMenuPlacements.Top}
      />
    </div>
  );
});
