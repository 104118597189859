import {
  SET_OPEN_TAB,
  SET_OPEN_SUBTAB,
  REMOVE_TABS,
  TABS_REGISTER_ANCHOR,
  TABS_UNREGISTER_ANCHOR,
} from './Tabs.types';
import { IPayloadRegisterAnchor } from './Tabs.interface';

export const setOpenTab = (tabGroupId: string, tabId: string, keepAnchors: boolean = false) => ({
  type: SET_OPEN_TAB,
  payload: {
    tabGroupId,
    tabId,
    keepAnchors,
  },
});

export const setOpenSubTab = (tabGroupId: string, subTabId: string) => ({
  type: SET_OPEN_SUBTAB,
  payload: {
    tabGroupId,
    subTabId,
  },
});

export const removeTabs = (tabGroupId: string) => ({
  type: REMOVE_TABS,
  payload: {
    tabGroupId,
  },
});

export const registerAnchor = ({ tabGroupId, anchorName }: IPayloadRegisterAnchor) => ({
  type: TABS_REGISTER_ANCHOR,
  payload: {
    anchorName,
    tabGroupId,
  },
});

export const unRegisterAnchor = ({ tabGroupId, anchorName }: IPayloadRegisterAnchor) => ({
  type: TABS_UNREGISTER_ANCHOR,
  payload: {
    anchorName,
    tabGroupId,
  },
});
