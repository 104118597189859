import * as React from 'react';
import { get, save } from 'framework/services/localStorage/localStorage';
import * as isEqual from 'lodash.isequal';
import { usePooling } from 'framework/utils/usePooling';
import { INotificationLine, NotificationType } from './Menu/Menu.interfaces';
import { NONE, NOTIFICATIONS_LOCAL_STORAGE_NAME } from './constants';
import { fetchFinalNotifications } from './api/notifications';
import { StoredNotification } from './interfaces';

const useNotifications = (
  openDetailsModal: (name?: string) => {
    type: string;
    payload: string;
  }
) => {
  const storedNotifications: StoredNotification[] = get(NOTIFICATIONS_LOCAL_STORAGE_NAME, true) ?? [];

  const [allNotifications, setAllNotifications] = React.useState<INotificationLine[]>([]);
  const [selectedNotification, setSelectedNotification] = React.useState<INotificationLine>();
  const [notificationType, setNotificationType] = React.useState<NotificationType>(NONE);

  // Poll every 10 minutes
  const { startPooling } = usePooling({ interval: 600000, count: 100 });

  const fetchNotifications = React.useCallback(async () => {
    const { finalNotifications, incidentType } = await fetchFinalNotifications(storedNotifications);

    setAllNotifications(finalNotifications);
    setNotificationType(incidentType);

    // Filter out notifications that are in local storage but no longer in api
    const editedStoredNotifications: StoredNotification[] = [...storedNotifications].filter((n) => {
      return !!finalNotifications.find((fn) => fn.id === n.id);
    });

    if (!isEqual(storedNotifications, editedStoredNotifications)) {
      save(NOTIFICATIONS_LOCAL_STORAGE_NAME, editedStoredNotifications, null, true);
    }

    // Returning false for polling
    return false;
  }, [storedNotifications]);

  const notificationsSeen: INotificationLine[] = React.useMemo(
    () => allNotifications?.filter((n) => n.seen),
    [allNotifications]
  );
  const notificationsNotSeen: INotificationLine[] = React.useMemo(
    () => allNotifications?.filter((n) => !n.seen),
    [allNotifications]
  );

  const openNotificationDetailsClick = (notification: INotificationLine) => {
    setSelectedNotification(notification);
    openDetailsModal();
  };

  const clearSelectedNotifications = React.useCallback(() => {
    setSelectedNotification(null);
  }, []);

  const handleNotificationClick = React.useCallback(
    (notification: INotificationLine) => {
      setAllNotifications(
        allNotifications?.map((n) => {
          // Update selected notification seen property to true
          if (n.id === notification.id) {
            return { ...n, seen: true };
          }

          return n;
        })
      );

      const notificationToStore: StoredNotification = {
        createdAt: notification.createdAt,
        dateSeen: new Date(),
        id: notification.id,
        seen: true,
      };

      const editedStoredNotifications: StoredNotification[] = [...storedNotifications];

      // If notification is not stored in local storage, then add it
      if (!editedStoredNotifications.find((n) => n.id === notification.id)) {
        editedStoredNotifications.push(notificationToStore);
      }

      // If the editedStoredNotifications is different from storedNotifications, then update local storage
      if (!isEqual(storedNotifications, editedStoredNotifications)) {
        save(NOTIFICATIONS_LOCAL_STORAGE_NAME, editedStoredNotifications, null, true);
      }

      openNotificationDetailsClick(notification);
    },
    [allNotifications, storedNotifications, openNotificationDetailsClick]
  );

  const handleMarkAllAsSeen = React.useCallback(() => {
    const updatedNotifications: INotificationLine[] = [...allNotifications].map((n) => {
      // Only update notification seen property to true if it is false
      if (!n.seen) {
        return { ...n, seen: true };
      }

      return n;
    });

    setAllNotifications(updatedNotifications);

    // Set all notifications as seen in local storage
    const editedStoredNotifications: StoredNotification[] = updatedNotifications.map((n) => {
      return {
        createdAt: n.createdAt,
        dateSeen: new Date(),
        id: n.id,
        seen: true,
      };
    });

    save(NOTIFICATIONS_LOCAL_STORAGE_NAME, editedStoredNotifications, null, true);
  }, [allNotifications]);

  React.useEffect(() => {
    fetchNotifications();

    startPooling(async () => fetchNotifications());
  }, []);

  return {
    allNotifications,
    selectedNotification,
    notificationType,
    notificationsSeen,
    notificationsNotSeen,
    handleNotificationClick,
    handleMarkAllAsSeen,
    clearSelectedNotifications,
  };
};

export default useNotifications;
