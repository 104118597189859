import * as React from 'react';
import { Text } from 'ui';
import Wrapper from './label.wrapper';

interface IMoved {
  value: string | number;
}

export class Moved extends React.PureComponent<IMoved> {
  renderUp = (val: number) => (
    <Wrapper type="moved-up">
      <Text caption="generic.dataGrid.score.moveUp" />
      {` ${val}`}
    </Wrapper>
  );

  renderDown = (val: number) => (
    <Wrapper type="moved-down">
      <Text caption="generic.dataGrid.score.moveDown" />
      {` ${Math.abs(val)}`}
    </Wrapper>
  );

  render() {
    const num = Number(this.props.value);
    let Label = null;

    if (num > 0) {
      Label = this.renderUp(num);
    } else if (num < 0) {
      Label = this.renderDown(num);
    }

    return Label;
  }
}

export default Moved;
