import { createSelector } from 'reselect';
import { isDirty, isValid, getFormSyncErrors, isPristine } from 'redux-form';
import { safeGet } from 'framework/utils';

export function getForm(state: any, formName: string) {
  // whole redux store has been passed ie. from Saga select
  // or state.form has been passed
  const form = state?.form ? state.form : state;

  return form[formName] || {};
}
export function getFormExist(state: any, formName: string) {
  const form = state?.form;

  return !!form[formName];
}

const getFormName = (props: any) => safeGet(props, 'meta.form');
const getFormState = (state: any, props: object) => {
  const formName = getFormName(props);
  return state?.formMWG && state.formMWG[formName];
};

export const getFormValues = createSelector([getForm], (form) => form.values || {});
export const getFormFieldValues = createSelector([getFormValues], (formValues) => {
  const fieldValues = {};

  Object.getOwnPropertyNames(formValues).forEach((fieldName) => {
    // If field is a dropdown, send value inside the object
    fieldValues[fieldName] = safeGet(formValues[fieldName], 'value', formValues[fieldName]);
  });

  return fieldValues;
});

export const getFieldValue = (state: any, formName: string, fieldName: string) => {
  const values = getFormFieldValues(state, formName);

  return safeGet(values, fieldName, null);
};

export const getFormInitialValues = createSelector([getForm], (form) => form.initial || {});
export const getFormFields = createSelector([getForm], (form) => form.registeredFields || {});
export const getFormFieldNames = createSelector([getFormFields], (fields) => Object.keys(fields).map((key) => key));
export const getFilledFields = createSelector([getFormValues, getFormFieldNames], (fValues, fields) =>
  fields.filter((f) => fValues[f])
);
export const getUnfilledFields = createSelector([getFormValues, getFormFieldNames], (fValues, fields) =>
  fields.filter((f) => !fValues[f])
);

export const getIsFormStencilled = createSelector(
  [getFormState],
  (formState) => !!(formState?.isStencilled)
);

export const getIsFormValid = (state: any, formName: string) => isValid(formName)(state);
export const getIsFormDirty = (state: any, formName: string) => isDirty(formName)(state);
export const getIsFormFieldPristine = (state: any, formName: string, fieldName: string) =>
  isPristine(formName)(state, fieldName);
export const getIsFormFieldDirty = (state: any, formName: string, fieldName: string) =>
  isDirty(formName)(state, fieldName);
export const getSyncErrors = (state: any, formName: string) => getFormSyncErrors(formName)(state);

export const getAreAllFieldsValid = (state: any, formName: string) => {
  const registeredFields = getFormFieldNames(state, formName);
  const syncErrors = getSyncErrors(state, formName);

  // Return false as soon as 1 field has an error, otherwise, return true
  return !registeredFields.some((fieldName) => !!syncErrors[fieldName]);
};

// Stablish if the form is available for submit/reset actions
export const getIsSubmitReady = createSelector(
  [getFormExist, getIsFormStencilled, getIsFormDirty, getIsFormValid],
  (exist, isStencilled, dirty, valid) => (exist ? !(!!isStencilled || !dirty || !valid) : false)
);

export const getIsResetReady = createSelector(
  [getFormExist, getIsFormStencilled, getIsFormDirty],
  (exist, isStencilled, dirty) => (exist ? !(!!isStencilled || !dirty) : false)
);
