export const parseQueryString = (qs: string = '') => {
  const params = qs.indexOf('?') === 0 ? qs.slice(1).split('&') : qs.split('&');
  const qsObj = params.reduce((acc, item) => {
    const keyValue = item.split('=');
    const [key, value] = keyValue;
    acc[key] = value;
    return acc;
  }, {});
  return qsObj;
};

export const buildQueryString = (queryString: object, includeQuestionMark = true): string => {
  const params = Object.keys(queryString).map((key: string) => `${key}=${queryString[key]}`);
  if (!params.length) {
    return '';
  }
  return `${includeQuestionMark ? '?' : ''}${params.join('&')}`;
};
