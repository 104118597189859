import { ContentManagementReduxState } from 'framework/modules/contentmanagement/contentmanagement.interfaces';
import { SaveLayoutTitleArgs, AttributeType } from './interfaces';

export const layoutReducer = (
  state: ContentManagementReduxState,
  action: {
    type: string;
    payload: SaveLayoutTitleArgs;
    attributeType: AttributeType;
  }
) => {
  const { rowEditorData } = state;

  return {
    ...state,
    rowEditorData: {
      ...rowEditorData,
      settings: {
        ...rowEditorData.settings,
        [action.payload.attributeType]: action.payload.attributeValue,
      },
    },
  };
};
