import * as React from 'react';
import * as classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IInputGroup } from './InputGroup.interfaces';
import './_style.scss';

const clx = prefixClassName('input-group');

export const InputGroup = (props: IInputGroup) => {
  const { children, className } = props;

  return <div className={classNames(clx, className)}>{children}</div>;
};

export default InputGroup;
