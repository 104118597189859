import React, { useEffect, useRef } from 'react';
import Timeout from 'smart-timeout';
import { useSafeState } from 'helpers/hooks';
import { ConfirmationModal } from 'ui';
import { useModal } from 'framework/components/ui/Modal';

type PoolingProps = {
  count?: number;
  interval?: number;
  onFailed?: () => void;
};

const POLLING_ERROR_MODAL = 'pollingErrorModal';

interface PoolingFailedModalProps {
  title?: string;
  messageType?: 'warning' | 'note' | 'error' | 'info';
  message?: string;
}

const PoolingFailedModal = ({
  title = 'generic.pollingError',
  messageType = 'warning',
  message = 'generic.pollingErrorMessage',
}: PoolingFailedModalProps) => {
  // Use this modal when using usePooling hook to show a warning message to the user
  return (
    <ConfirmationModal
      name={POLLING_ERROR_MODAL}
      hideConfirmationButton
      cancelButton="generic.button.continue"
      title={title}
      warning={messageType === 'warning' && message}
      note={messageType === 'note' && message}
      info={messageType === 'info' && message}
    />
  );
};

export type Callback = (...args: any) => Promise<boolean>;

export const usePooling = ({ interval = 1000, count = 5, onFailed }: PoolingProps) => {
  const [pooling, setPooling] = useSafeState(false);
  const [poolingCount, setPoolingCount] = useSafeState(count);

  const callback = useRef<Callback>();
  const { openModal } = useModal();

  const stopPooling = () => {
    setPooling(false);
  };

  const execute = async () => {
    try {
      const done = await callback.current();
      if (done) {
        stopPooling();
      } else {
        setPoolingCount((val) => val - 1);
      }
    } catch {
      stopPooling();
      if (onFailed) onFailed();
    }
  };

  const startPooling = (cb: Callback) => {
    setPooling(true);
    setPoolingCount(count);
    callback.current = cb;
  };

  useEffect(() => {
    if (pooling && poolingCount > 0) {
      Timeout.set(execute, interval);
    }
 
    if (poolingCount === 0) {
      setPooling(false);
      if (onFailed) onFailed();
      openModal(POLLING_ERROR_MODAL);
    }
  }, [pooling, poolingCount]);

  return {
    pooling,
    startPooling,
    PoolingFailedModal,
  };
};
