import { ModuleConfig, RouteGroup } from 'framework/components/navigation/Navigation.interfaces';
import {
  CMS_MODULENAME,
  PATH_CMS_BASEURL,
} from '../contentemanagement.constants';
import {
  cmsAllPagesRoute,
  cmsAllEmailsRoute,
  cmsCreatePageRoute,
  allTermsPageRoute,
  allNotificationsRoute,
  termsCreatePageRoute,
  allPrintedDocumentsRoute,
  createNotificationRoute,
} from '../routes';

const pageGroups: RouteGroup[] = [
  {
    title: 'modules.contentmanagement.pages.title',
    routes: [
      cmsAllPagesRoute,
      cmsCreatePageRoute,
    ],
  }, {
    title: 'modules.contentmanagement.emails.emails',
    routes: [
      cmsAllEmailsRoute,
    ],
  },
  {
    title: 'modules.contentmanagement.term_dictionaries',
    routes: [
      allTermsPageRoute,
      termsCreatePageRoute,
    ],
  },
  {
    title: 'modules.contentmanagement.notifications',
    routes: [
      allNotificationsRoute,
      createNotificationRoute
    ],
  },
  {
    title: 'modules.contentmanagement.printedDocuments.printedDocuments',
    routes: [
      allPrintedDocumentsRoute,
    ],
  }
];

const ContentManagementModule: ModuleConfig = {
  name: CMS_MODULENAME,
  appMenuTitle: 'navigation.contentManagement',
  appMenuIcon: 'image', // 'report',
  modulePath: PATH_CMS_BASEURL,
  pageGroups,
};

export default ContentManagementModule;
