import { config } from 'config';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import * as CONST from 'framework/modules/searchadmin/constants';
import { makeRequestAndSaveState } from 'framework/api/make-request';

export default function* watchGetDefaultSettingPageDataAction() {
  yield takeEveryWithProgressBar(CONST.ACTION_DEFAULT_SETTINGS_GET_PAGE_DATA, process);
}

export function* process() {
  const localeId = CONST.DEFAULT_LOCALE_ID; // In future, this will come from somewhere else

  // 1. Get Settings Data
  yield makeRequestAndSaveState({
    name: CONST.API_DATA_DEFAULT_SETTINGS_DATA,
    url: `${config.apiEndpoints.search}/locale/${localeId}/settings`,
    method: 'GET'
  });
}
