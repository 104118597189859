import { fetchData } from 'framework/api/fetch-data';
import { getApiUrl } from 'config';
import moment from 'moment';
import { INotificationLine, ITrustPortal, StatusComponent } from '../Menu/Menu.interfaces';
import { StoredNotification } from '../interfaces';

export const getTrustPortalNotifications = async (): Promise<ITrustPortal> => {
  const result = await fetchData<ITrustPortal>({
    url: getApiUrl('trustportalbridge/incidents'),
  });

  return result.data;
};

export const fetchFinalNotifications = async (
  storedNotifications: StoredNotification[]
) => {
  const notificationData: ITrustPortal = await getTrustPortalNotifications();

  const trustPortalNotifications: INotificationLine[] = notificationData?.incidents;
  const incidentType = notificationData?.incidentType;

  // Map and set notification type based on api called
  const finalNotifications: INotificationLine[] = trustPortalNotifications?.map((notification: INotificationLine) => {
    return {
      ...notification,
      createdAt: moment(notification.createdAt).toDate(),
      resolvedAt: moment(notification.resolvedAt).toDate(),
      // If scheduledFor is an empty date (all 0's) then use createdAt
      scheduledFor:  moment(notification.scheduledFor).get('hours')
        ? moment(notification.scheduledFor).toDate()
        : moment(notification.createdAt).toDate(),
      scheduledUntil: moment(notification.scheduledUntil).toDate(),
      // Create description from components if all components have a description
      ...(notification.components.every(
        (comp: StatusComponent) => comp.description && comp.description.length !== 0) && ({
        description: notification.components.map((comp: StatusComponent) => comp.description).toString(),
      })),
      type: { name: 'notification', icon: 'bell' },
      seen: (storedNotifications && !!storedNotifications?.find((n) => n.id === notification.id)) ?? false,
    };
  });

  return { finalNotifications, incidentType };
};