import { take, fork, select, put } from 'redux-saga/effects';
import * as constants from '../contentemanagement.constants';
import { ContentManagementState } from '../contentmanagement.interfaces';
import { actions as apiClientActions } from 'framework/api/apiClient';
import { apiClientSelectors } from 'framework/selectors';
import { makeRequest } from 'framework/api/make-request';
import * as cmsActions from '../actions';
import { history } from 'framework/store';
import { actions as toastListActions } from 'framework/components/ui/ToastList';
import { getLoadedPage, displayMessageErrors } from './_SagaUtils';
import { config } from '../../../../config';

export default function* sagaCmsPageVariant() {
  while (true) {
    const action = yield take([constants.ACTION_CMS_SAVE_STORES_TAB, apiClientActions.API_CLIENT_UPDATE_STATE]);
    yield fork(process, action);
  }
}

function* process(action) {
  switch (action.type) {
    case constants.ACTION_CMS_SAVE_STORES_TAB:
      {
        yield saveVariantStores(action);
      }
      break;

    case apiClientActions.API_CLIENT_UPDATE_STATE:
      {
        if (!action.payload.isFetching && !action.payload.hasError) {
          if (action.payload.name === constants.ACTION_CMS_PAGE_SAVE_CONTENT) {
            // after cmsContent created, load variant
            yield copyAndSaveSeo(action);
          }

          if (action.payload.name === constants.ACTION_CMS_CREATE_SEO_FOR_VARIANT) {
            // after seo created, navigate to variant page
            yield navigateToVariantPage(action);
          }
        } else if (action.payload && action.payload.hasError) {
          yield displayMessageErrors({
            action,
            error: action.payload.message,
            names: [
              constants.ACTION_CMS_CREATE_PAGE_VARIANT,
              constants.ACTION_CMS_SAVE_STORES_TAB,
              constants.ACTION_CMS_PAGE_SAVE_CONTENT,
              constants.ACTION_CMS_CREATE_SEO_FOR_VARIANT,
            ],
          });
        }
      }
      break;
  }
}

function* copyAndSaveSeo(action) {
  const loadedPage = yield select(getLoadedPage);
  if (loadedPage.seo) {
    yield put(cmsActions.createCmsSeoForVariant());
  } else {
    yield navigateToVariantPage(action);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* navigateToVariantPage(action) {
  const values: any = yield select((state: ContentManagementState) => {
    return {
      pageId: state.contentmanagement.pageId,
      variantId: state.contentmanagement.variantId,
    };
  });

  yield put(cmsActions.clearCurrentPageAction());
  history.push(`/contentmanagement/page/${values.pageId}/variants/${values.variantId}`);
}

function* saveVariantStores(action) {
  const values: any = yield select((cmsState: ContentManagementState) => {
    return {
      pageId: cmsState.contentmanagement.pageId,
      variantId: cmsState.contentmanagement.variantId,
    };
  });
  const loadedPage = yield select(getLoadedPage);
  const { displayName, language, varyBy } = loadedPage;
  const storeHierarchyNodes = action.payload.selectedIds;

  yield makeRequest({
    body: {
      variant: {
        displayName,
        language,
        storeHierarchyNodes,
        varyBy,
      }
    },
    method: 'PUT',
    url: `${config.apiEndpoints.cmsDocuments}/documents/${values.pageId}/variants/${values.variantId}`,
    name: constants.ACTION_CMS_SAVE_STORES_TAB,
  });

  const state = yield select(apiClientSelectors.selectApiClientState, constants.ACTION_CMS_SAVE_STORES_TAB);

  if (!state.hasError) {
    yield put(
      cmsActions.storePageDocumentAction({
        storeHierarchyNodes,
      })
    );
    yield put(toastListActions.addMessage('generic.data.savedSuccess'));
  }
}
