import * as React from 'react';
import { StatusIndicator } from 'ui';

interface Props {
  className?: string;
  value: string;
  icon?: boolean;
  cellProperties: any;
}

export class DataGridStatusIndicator extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    icon: false,
  };

  getShowIcon = () => {
    const { cellProperties = {} } = this.props;
    const { componentOptions } = cellProperties;

    return typeof componentOptions === 'object' && componentOptions.showIcon
      ? componentOptions.showIcon
      : this.props.icon;
  };

  render() {
    const { className, value = '' } = this.props;
    const hasIcon = this.getShowIcon();
    // This is nasty, ugly, brutal, horrawful but agreed with Paul that we do it like this
    // until they figure out way of sending enums via api
    // TODO: when enums are designed, adjust this component and test it then

    return <StatusIndicator caption={value} icon={hasIcon && 'info'} className={className} />;
  }
}
export default DataGridStatusIndicator as any as React.StatelessComponent<Props>;
