export const getSelectedTerms = state => (state && state.termsCondition_terms) || [];

export const getSelectedStores = state => {
  const storesList = new Set();
  const formValues = state || {};

  if (Array.isArray(formValues.filterConditions)) {
    formValues.filterConditions.forEach(condition => {
      const condition_term = (condition.field && condition.field.value) || condition.field;
      const selected_term = condition.condition_search_terms_select;

      if (condition_term === 'store.storeId' && Array.isArray(selected_term)) {
        selected_term.forEach(st => {
          storesList.add(st);
        });
      }
    });
  }

  return Array.from(storesList);
};
