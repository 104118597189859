import * as React from 'react';
import { Text, Tag } from 'ui';
import { Table } from 'framework/components/ui/Table';
import { formatDateTime } from 'framework/utils/datetimes';
// eslint-disable-next-line import/no-cycle
import { appState } from 'stores/app';
// eslint-disable-next-line import/no-cycle
import { rulesEditRuleRoute } from '../../../../routes';
import { SearchRule } from '../../../../Rules/models/searchRule';
import { RuleConflictTranslations } from './translations';

export const ruleConflictMetaData = [
  {
    caption: RuleConflictTranslations.RuleTableHeader,
    customRenderer: ({ name, id, type }: SearchRule) => (
      <Table.ColumnRouteLink caption={name} appRoute={rulesEditRuleRoute} params={{ ruleId: id }} subcaption={type} />
    ),
  },
  {
    caption: RuleConflictTranslations.StoresTableHeader,
    customRenderer: ({ stores }: SearchRule) => (
        <Table.Column>
          <Tag caption={stores} value={Tag.Value.info} />
        </Table.Column>),
  },
  {
    caption: RuleConflictTranslations.ScheduledTableHeader,
    customRenderer: (rowData: SearchRule) => {
      const { startDate, finishDate } = rowData;
      const { dateTimeFormatOf } = appState.userSettings;

      return (
        <Table.Column>
          <Text tag="span" caption={RuleConflictTranslations.StartTime} />
          : {formatDateTime(startDate, dateTimeFormatOf, '--')}
          <br />
          <Text tag="span" caption={RuleConflictTranslations.EndTime} />
          : {formatDateTime(finishDate, dateTimeFormatOf, '--')}
        </Table.Column>
      );
    },
  },
  {
    caption: RuleConflictTranslations.RelatedRulesTableHeader,
    customRenderer: ({ terms }: SearchRule) => {
      const relatedRules = terms.split('|');
      return (
        <Table.ColumnSummarizer
          items={relatedRules}
          caption={RuleConflictTranslations.RulesCount}
        />
      );
    },
  },
];
