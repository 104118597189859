import * as React from 'react';
import { prefixClassName } from '../../_conf';
import { ColumnKebab } from '../TableColumn';

const clx = prefixClassName('');

type ActionsCellProps = Pick<React.ComponentProps<typeof ColumnKebab>, 'options'> & {
  isLoading?: boolean;
};

export const ActionsCell = React.memo(({ options, isLoading }: ActionsCellProps) => (
  <ColumnKebab
    isLoading={isLoading}
    width="80px"
    options={options}
    className={`${clx}Table-cell--no-ellipsis`}
    keepDropdown
  />
));
