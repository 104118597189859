/* eslint-disable max-len */
import { useState } from 'react';
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import { OrgHierarchy } from 'framework/shared';
import { hasSameItems } from 'framework/utils/helpers';
import { useEffectSkipFirstRun } from 'helpers/hooks';
import { HierarchyFilterNode, HierarchyFiltersProps } from './Interfaces';
import { DefaultFilterName } from './constants';

export function useHierarchyFilters<TValues = {}>({
  customFilterFunction,
  formFilterName,
}: Pick<HierarchyFiltersProps<TValues>, 'formFilterName' | 'customFilterFunction'>) {
  const [data, setData] = useState<HierarchyFilterNode[]>([]);
  const [root, setRoot] = useState<OrgHierarchy>(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filtered, setFiltered] = useState<HierarchyFilterNode[]>(null);
  const [expandedIds, setExpandedIds] = useState<string[]>(null);
  const { getValues } = useForm(formFilterName);

  const initializeFilterData = (initialData: HierarchyFilterNode[]) => {
    setData(initialData);
  };

  // default filtering: filter by name or by store id
  const defaultFilterFunction = (value: string = '', node: HierarchyFilterNode) => {
    const byName = node.value.description?.toLowerCase().includes(value.toLowerCase());
    const byRetailerStoreId = node.value.retailerStoreId && node.value.retailerStoreId === value;

    return byName || byRetailerStoreId;
  };

  const filterHierarchy = (node: HierarchyFilterNode, filterFn: any, includeTotal?: boolean) => {
    let filteredData: HierarchyFilterNode[] = [];
    const { children = [], ...rest } = node;

    const nodeChildren = children.reduce((acc, n) => {
      const items = filterHierarchy(n, filterFn);
      return [...acc, ...items];
    }, []);

    const found = filterFn(node);
    const foundChildren = nodeChildren.length;

    if (found || foundChildren) {
      filteredData = [
        {
          ...rest,
          ...(includeTotal && ({ totalChildren: children?.length })),
          children: found ? children.map(child => ({...child})) : nodeChildren,
        },
      ];
    }
    return filteredData;
  };
  
  const getExpandedIds = (nodes: HierarchyFilterNode[]) => nodes.reduce((acc, n) => {
    let currentAcc = acc;
    const node = n;
    const { children } = node;
    currentAcc = [...currentAcc, node.id];
    if (children) {
      currentAcc = [...currentAcc, ...getExpandedIds(children)];
    }
    return currentAcc;
  }, []);

  useEffectSkipFirstRun(() => {
    setIsFiltered(!hasSameItems(data, filtered));
  }, [filtered?.length]);

  const onFilter = () => {
    const currentValues: TValues = getValues();

    const filterFunction = customFilterFunction
      ? (node) => customFilterFunction(currentValues, node)
      : (node) => defaultFilterFunction(currentValues[`${DefaultFilterName}`], node);

    const filteredData = data.reduce((acc, n) => [
      ...acc,
      ...filterHierarchy(n, filterFunction),
    ], []);
   
    setFiltered(filteredData);
    setExpandedIds(getExpandedIds(filteredData.filter((node) => {
      return node.children.length === 0;
    })));

  };

  // when data is filtered then remove parent from selection if not all children are selected (parent should be in "intermidate" state)
  const fixSelection = (node: OrgHierarchy, selection: OrgHierarchy[]): OrgHierarchy[] => {
    const children = (node.children || []).reduce<OrgHierarchy[]>((acc, n) => [...acc, ...fixSelection(n, selection)], []);
    const selected = !!selection.find((s) => s.id === node.id);
    if (selected) {
      if (node?.children.length) {
        const selectedChildren = children.filter((t) => !!(node.children || []).find((n) => n.id === t.id));
        if (hasSameItems(node.children, selectedChildren)) {
          return [{ ...node }, ...children];
        }
      } else {
        return [{ ...node }];
      }
    }
    return children;
  };

  const handleOnChange = (onChange: (n: OrgHierarchy[]) => void) => (nodes: OrgHierarchy[]) => {
    if (onChange) {
      const selection = data.reduce((acc, n) => [...acc, ...fixSelection(n, nodes)], []);
      onChange(selection);
    }
  };
  const handleOnFetchStores = (onFetchStores?: (data: OrgHierarchy[]) => void) => (
    nodes: OrgHierarchy[], orgRoot?: OrgHierarchy,
  ) => {
    initializeFilterData(nodes);

    if (onFetchStores) {
      onFetchStores(nodes);
    }

    if (orgRoot) {
      setRoot(orgRoot);
    }
  };

  return {
    onFilter,
    filtered,
    expandedIds,
    isFiltered,
    handleOnFetchStores,
    handleOnChange,
    root,
    data,
  };
}
