import * as React from 'react';
import { Info, Text } from 'framework/components/ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { datesAreEqual, getWeekDay } from 'framework/utils/datetimes';
import { FulfillmentTypes } from 'framework/modules/slotManagement/pages/SlotsTemplatesEdit/pageParts/constants';
import { useCalendar } from '../../Calendar.context';
import { usageData, usageDetails, capacityDetailsProps, usagePerShoppingModeProps } from './HeaderWeek.interfaces';

const clx = prefixClassName('cal__headerWeek__addContent');

export const HeaderWeekAdditional = (props: any) => {
  const { date } = props;
  const { template, data = {} } = useCalendar();
  const renderCapacity = () => {
    const { headerData = [] as capacityDetailsProps[] } = data;
    const dayInfo = headerData.find((d) => datesAreEqual(d.day, date)) || ({} as capacityDetailsProps);
    const { capacity = '-', fulfillmentType } = dayInfo;

    return (
      <>
        <Text caption="components.calendar.headerInfo.capacityCount.title" i18nOptions={{ capacity }} />
        {fulfillmentType === FulfillmentTypes.Instacart && (
          <div>
            <Text
              caption="components.calendar.headerInfo.capacityCount.excludingInstacart"
              tag="div"
              className="secondary-color"
            />
          </div>
        )}
      </>
    );
  };

  const getTooltipBody = (smData: usagePerShoppingModeProps[]) =>
    smData.length === 0
      ? null
      : smData.map((d, i) => {
        const path = 'components.calendar.headerInfo.usageCount.tooltip.';
        const { value } = d;
        const {
          slotCapacity = 0,
          slotTaken = 0,
          slotRemaining = 0, // closedSlots = 0,
        } = value;

        return (
            <div className={`${clx}__tooltip`} key={i}>
              <span>{d.key}</span>
              <ul>
                <Text tag="li" i18nOptions={{ count: slotCapacity }} caption={`${path}capacity`} />
                <Text tag="li" i18nOptions={{ count: slotTaken }} caption={`${path}taken`} />
                <Text tag="li" i18nOptions={{ count: slotRemaining }} caption={`${path}remaining`} />
                {/* <Text tag="li" i18nOptions={{ count: closedSlots }} caption={`${path}closed`} /> */}
              </ul>
            </div>
        );
      });

  const renderUsage = () => {
    const { headerData = [] as usageData[] } = data;
    const weekDay = getWeekDay(date);
    const dayInfo = headerData.find((d) => d.day === weekDay) || ({} as usageData);
    const { total = {} as usageDetails, usagePerShoppingMode = [] } = dayInfo;
    const { slotTaken = '-', slotCapacity = '-' } = total;

    return (
      <>
        <Text
          caption="components.calendar.headerInfo.usageCount.title"
          i18nOptions={{
            taken: slotTaken,
            capacity: slotCapacity,
          }}
        />
        <Info tooltipBody={getTooltipBody(usagePerShoppingMode)} showOnHover />
      </>
    );
  };

  const contentAdditional = () => {
    switch (template) {
      case 'slotTemplates':
        return renderCapacity();
      case 'liveCalendar':
        return renderUsage();
      case '':
      default:
        return null;
    }
  };

  const content = contentAdditional();

  return content ? <span className={clx}>{content}</span> : null;
};
