// ROUTES PATHS
export const PATH_MAIN = '/merchandising';

/* Relative paths, to use in menu */
export const PATH_RECOMMENDATIONS = '/recommendations';
export const PATH_RECOMMENDATIONS_CREATE = `${PATH_RECOMMENDATIONS}/create`;
export const PATH_RECOMMENDATIONS_EDIT = `${PATH_RECOMMENDATIONS}/edit`; // path needs /:recommendationId

export const PATH_RECIPES = '/recipes';
export const PATH_RECIPES_CREATE = `${PATH_RECIPES}/create`;
export const PATH_RECIPES_EDIT = `${PATH_RECIPES}/edit`; // path needs /:recipeId

/* Absolute paths, to use in buttons and redicrections */
export const PATH_RECOMMENDATIONS_FULLPATH = `${PATH_MAIN}${PATH_RECOMMENDATIONS}`;
export const PATH_RECOMMENDATIONS_CREATE_FULLPATH = `${PATH_MAIN}${PATH_RECOMMENDATIONS_CREATE}`;
export const PATH_RECOMMENDATIONS_EDIT_FULLPATH = `${PATH_MAIN}${PATH_RECOMMENDATIONS_EDIT}`;

export const PATH_RECIPES_FULLPATH = `${PATH_MAIN}${PATH_RECIPES}`;
export const PATH_RECIPES_CREATE_FULLPATH = `${PATH_MAIN}${PATH_RECIPES_CREATE}`;
export const PATH_RECIPES_EDIT_FULLPATH = `${PATH_MAIN}${PATH_RECIPES_EDIT}`;

export const PATH_LISTINGS = '/listings';
export const PATH_LISTINGS_FULLPATH = `${PATH_MAIN}${PATH_LISTINGS}`;

// ACTIONS SPECIFIC TO THIS MODULE
export const ACTION_RECOMMENDATION_SAVE_DETAILS = 'ACTION_RECOMMENDATION_SAVE_DETAILS';
export const ACTION_RECOMMENDATION_SAVE_RULES = 'ACTION_RECOMMENDATION_SAVE_RULES';

// ACTIONS SPECIFIC TO THIS MODULE
export const ACTION_LISTING_DELETE = 'ACTION_LISTING_DELETE';
export const ACTION_LISTING_UPDATE_STATE = 'ACTION_LISTING_UPDATE_STATE';
export const ACTION_LISTING_SAVE_DETAILS = 'ACTION_LISTING_SAVE_DETAILS';
export const ACTION_LISTING_SAVE_RULES = 'ACTION_LISTING_SAVE_RULES';

// API DATA PROVIDERS
export const API_RECOMMENDATION_SITES = 'ApiDataProviderRecommendationSites';
export const API_DATA_RECOMMENDATION_DATA = 'ApiDataProviderRecommendationData';
export const API_RECIPE_EDIT = 'ApiDataProviderRecipe';

export const API_LISTING_SITES = 'ApiDataProviderListingSites';
export const API_DATA_LISTING_DATA = 'ApiDataProviderListingData';
