import * as React from 'react';
import * as classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text } from 'ui';
import { IInfo } from './Info.interfaces';
import InfoIcon from './InfoIcon';
import './_style.scss';

const clx = prefixClassName('info');

const Info = (props:IInfo) => {
  const {
    caption,
    tooltipBody,
    className,
    onClick,
    showOnHover = false,
    toolTipPosition = 'up-right',
  } = props;

  const content = tooltipBody || <Text caption={caption} />;

  return (
    <div className={classnames(clx, className)} onClick={onClick}>
      <InfoIcon
        tooltipBody={content}
        className={`${clx}__icon`}
        position={toolTipPosition}
        showOnHover={showOnHover}
      />
    </div>
  );
};

export default Info;
