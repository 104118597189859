import { select, put, call } from 'redux-saga/effects';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { toastListActions } from 'framework/actions';
import { formSelectors } from 'framework/selectors';
import { makeRequest } from 'framework/api/make-request';
import { ACTION_CMS_SAVE_SCHEDULING_FORM_DATA, CMSPAGE_TAB_CMS_SCHEDULING_FORM } from '../contentemanagement.constants';
import { config } from '../../../../config';
import { ContentManagementState } from '../contentmanagement.interfaces';
import { getLoadedPage, processFormErrors } from './_SagaUtils';

export function* watchSaveScheduleData() {
  yield takeEveryWithProgressBar(ACTION_CMS_SAVE_SCHEDULING_FORM_DATA, processSaveScheduleData);
}

function* processSaveScheduleData() {
  const { documentId, variantId }: any = yield select((state: ContentManagementState) => ({
    documentId: state.contentmanagement.pageId,
    variantId: state.contentmanagement.variantId,
  }));

  const formFieldValues = yield select(formSelectors.getFormFieldValues, CMSPAGE_TAB_CMS_SCHEDULING_FORM);

  const loadedPage = yield select(getLoadedPage);

  const response = yield call(makeRequest, {
    body: {
      ...loadedPage.scheduling,
      ...formFieldValues,
    },
    method: 'PUT',
    url: `${config.apiEndpoints.cms}/documents/${documentId}/variants/${variantId}/schedule`,
    name: ACTION_CMS_SAVE_SCHEDULING_FORM_DATA,
  });

  if (response.error) {
    yield processFormErrors(response.error, CMSPAGE_TAB_CMS_SCHEDULING_FORM, ['timeZone', 'start', 'end']);
  } else {
    yield put(toastListActions.addMessage('generic.data.savedSuccess'));
  }
}
