import { connect } from 'react-redux';
import { actions as formActions, selectors as formSelectors } from '../Form';
import { ButtonSubmit } from './ButtonSubmit';
import { IButtonSubmit, IButtonSubmitPrivate } from './ButtonSubmit.interfaces';

const mapStateToProps = (state, { disabled, formToSubmit }: IButtonSubmit) => ({
  disabled: (disabled !== undefined) ? disabled : !formSelectors.getIsSubmitReady(state, formToSubmit as never),
});

const mapDispatchToProps = (dispatch, ownProps): IButtonSubmitPrivate => ({
  submitForm: () => dispatch(formActions.submit(ownProps.formToSubmit)),
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(ButtonSubmit) as any) as React.StatelessComponent<IButtonSubmit>;
