import * as React from 'react';
import classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text } from 'ui';
import { i18n } from 'i18n';
import config from 'config';
import PageSelectionFieldDefault from './ButtonsField';
import PaginationButtonDefault from './ButtonsButton';
import { IPaginationButtons } from './Buttons.types';
import { ShowingNoOfNo } from './ShowingNoOfNo';
import './_styles.scss';
import { SelectPrimitive } from '../../FormComponents/Primitives';
import { SelectMenuPlacements } from '../../FormComponents/Select/interfaces';

const clx = prefixClassName('paginationButtons');

export default class PaginationButtons extends React.Component<IPaginationButtons> {
  static defaultProps: Partial<IPaginationButtons> = {
    currentPage: 1,
  };

  getNumberOfPages = (pageSize: number, total: number) => total && pageSize && Math.ceil(total / pageSize);

  render() {
    const {
      className,
      currentPage,
      pageSize,
      onSetPageSizeHandler,
      recordCount,
      from,
      to,
      onViewAll,
      onGetPage,
      onPrevious,
      onNext,
      itemCount,
      disablePageInput,
      menuPlacement = SelectMenuPlacements.Bottom,
    } = this.props;

    const numberOfPages = this.getNumberOfPages(pageSize, recordCount);
    const disablePagination = !recordCount;
    const pageSizeClass = onSetPageSizeHandler ? `${clx}__pageSize` : '';
    const hideDropdownAndPagination = recordCount <= pageSize && recordCount !== -1;


    return (recordCount === -1 || recordCount > 0) ? (
      <div className={classnames(clx, className, pageSizeClass)}>
        {(onSetPageSizeHandler) && (
          <>
            <Text
              tag="span"
              className={`${clx}__pagesize-select__label`}
              caption="components.pagination.buttons.pageSizeLabel"
            />
            <SelectPrimitive
              name="page_size"
              clearable={false}
              className={`${clx}__pagesize-select`}
              value={pageSize.toString()}
              options={config.dataGridPageSizeOptions.map((item) => ({
                label: i18n.t('components.pagination.buttons.pageSize', { size: item }),
                value: item,
              }))}
              onChange={onSetPageSizeHandler}
              menuPlacement={menuPlacement}
            />
          </>
        )}
        <ShowingNoOfNo
          className={`${clx}__overview`}
          caption={`components.pagination.buttons.${recordCount !== -1 ? 'showingNoOfNo' : 'showingNoOfNoHideOf'}`}
          i18nOptions={{
            from,
            to,
            recordCount,
          }}
        />
        {!hideDropdownAndPagination && (
        <>
          <span className={`${clx}__page-selection`}>
            <span className={`${clx}__before-input`}>
              <Text caption="components.pagination.buttons.page" />
            </span>
            <PageSelectionFieldDefault
              disabled={disablePagination || recordCount === -1}
              className={clx}
              onChange={onGetPage}
              readOnly={disablePageInput}
              currentPage={currentPage}
              numberOfPages={numberOfPages} />
          </span>
          <div className={`${clx}__buttons-area`}>
            <span className={`${clx}__after-input`}>
              {recordCount !== -1 && i18n.t('components.pagination.buttons.of', { numberOfPages })}
            </span>
            <span className={`${clx}__arrows`}>
              <PaginationButtonDefault
                onClick={onPrevious}
                icon="dropdownArrowLeft"
                disabled={disablePagination || currentPage === 1}
                caption="components.pagination.buttons.previous" />
              <PaginationButtonDefault
                onClick={onNext}
                icon="dropdownArrowRight"
                disabled={disablePagination || (numberOfPages && currentPage === numberOfPages) || itemCount < pageSize}
                caption="components.pagination.buttons.next" />
            </span>
          </div>
        </>)}
        {!!onViewAll && (
          <span aria-hidden="true" className={`${clx}__view-all`} onClick={onViewAll}>
            <Text caption="components.pagination.buttons.viewAll" />
          </span>
        )}
      </div>
    ) : null;
  }
}
