import * as React from 'react';
import {
  AdminTemplateInputTypes,
  IAdminImagesTemplateSFUI,
  IAdminImagesTemplateSTS,
  IAdminSettingsTemplate,
  IAdminSettingsTemplateSTS,
  IAdminThemeTemplateSFUI,
  AdminSettingsTemplateMobile,
} from '@thryveai/theme-interfaces';
import { toCamelCase, toSentenceCase } from 'framework/utils/string';
import { store } from '@risingstack/react-easy-state';
import CheckboxType from './CheckboxType';
import InputType from './InputType';
// eslint-disable-next-line import/no-cycle
import ObjectTypeComponent from './ObjectTypeComponent';
// eslint-disable-next-line import/no-cycle
import DropdownType from './DropdownType';
import MultidropdownType from './MultipleDropdownType';
import Image from './Images';
import ThemeColorPicker from './ThemeColorPicker';
import { InputTypes } from '../StoreFrontSettings.interfaces';
// eslint-disable-next-line import/no-cycle
import ArrayType from './ArrayType/ArrayType';
import StoreType from './StoreType/StoreType';

type ISettingsTemplate =
  | IAdminSettingsTemplate
  | IAdminSettingsTemplateSTS
  | IAdminImagesTemplateSFUI
  | IAdminImagesTemplateSTS
  | IAdminThemeTemplateSFUI
  | typeof AdminSettingsTemplateMobile;

export const selectedOptions = store({ options: null });

const JsonUiGenerator = (
  templateToBeMapped: ISettingsTemplate,
  valuesObject,
  bannerSettingsMap,
  handleFieldChange: (key: string, value: any) => void,
  level = 1,
  id = null,
  showAnchors = false,
  // setting bypassValueCheck as true will avoid checking if the field value exists in the terms dictionary
  // this is useful when creating new fields, like for ArrayTypes
  bypassValueCheck = false,
  required = false,
  // use valuesOverride when creating new fields that are not in the terms dictionary already
  // useful for ArrayTypes
  valueOverrides = null,
  formName = null
) => {
  if (!templateToBeMapped || !valuesObject || !bannerSettingsMap) {
    return null;
  }

  return Object.keys(templateToBeMapped).map((key: string, index: number) => {
    const Item = templateToBeMapped[key];

    if (Item.title) Item.title = toSentenceCase(Item.title);

    if (Item.type === AdminTemplateInputTypes.dropdown) {
      return (
        <DropdownType
          component={Item}
          value={valueOverrides ? valueOverrides[key] : valuesObject[key]}
          handleChange={handleFieldChange}
          field={key}
          id={id}
          key={`${Item.title}-${index}`}
          bypassValueCheck={bypassValueCheck}
          required={required}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.multiDropdown) {
      return (
        <MultidropdownType
          component={Item}
          value={valueOverrides ? valueOverrides[key] : valuesObject[key]}
          handleChange={handleFieldChange}
          field={key}
          id={id}
          key={`${Item.title}-${index}`}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.checkbox) {
      return (
        <CheckboxType
          component={Item}
          value={valueOverrides ? valueOverrides[key] : valuesObject[key]}
          handleChange={handleFieldChange}
          field={key}
          id={id}
          key={`${Item.title}-${index}`}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.inputChar || Item.type === AdminTemplateInputTypes.inputNumber) {
      return (
        <InputType
          component={Item}
          value={valueOverrides ? valueOverrides[key] : valuesObject[key]}
          handleChange={handleFieldChange}
          field={key}
          id={id}
          key={`${Item.title}-${index}`}
          type={Item.type === AdminTemplateInputTypes.inputChar ? InputTypes.Text : InputTypes.Number}
          bypassValueCheck={bypassValueCheck}
          required={required}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.storeSelection) {
      return (
        <StoreType
          component={Item}
          value={valueOverrides ? valueOverrides[key] : valuesObject[key]}
          handleChange={handleFieldChange}
          field={key}
          id={id}
          formName={formName}
          key={`${Item.title}-${index}`}
          bypassValueCheck={bypassValueCheck}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.object) {
      return (
        <ObjectTypeComponent
          component={Item}
          id={key}
          valuesObject={valuesObject}
          bannerSettingsMap={bannerSettingsMap}
          handleChange={handleFieldChange}
          level={level}
          key={`${Item.title}-${index}`}
          showAnchors={showAnchors}
          formName={formName}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.collapsableObject) {
      return (
        <ObjectTypeComponent
          component={Item}
          id={key}
          valuesObject={valuesObject}
          bannerSettingsMap={bannerSettingsMap}
          handleChange={handleFieldChange}
          isCollapsable
          level={level}
          key={`${Item.title}-${index}`}
          showAnchors={showAnchors}
          formName={formName}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.nullObject) {
      return (
        <ObjectTypeComponent
          component={Item}
          id={key}
          valuesObject={valuesObject}
          bannerSettingsMap={bannerSettingsMap}
          handleChange={handleFieldChange}
          canBeNull
          level={level}
          key={`${Item.title}-${index}`}
          showAnchors={showAnchors}
          formName={formName}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.images) {
      return (
        <Image
          component={Item}
          name={`${Item.title}-${index}`}
          value={valueOverrides ? valueOverrides[key] : valuesObject[key]}
          field={key}
          id={id}
          handleChange={handleFieldChange}
          key={`${Item.title}-${index}`}
          level={level}
          required={required}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.color) {
      return (
        <ThemeColorPicker
          name={`${Item.title}-${index}`}
          component={Item}
          value={valueOverrides ? valueOverrides[key] : valuesObject[key]}
          field={key}
          handleChange={handleFieldChange}
          id={id}
          key={`${Item.title}-${index}`}
        />
      );
    }
    if (Item.type === AdminTemplateInputTypes.array) {
      return (
        <ArrayType
          bannerSettingsMap={bannerSettingsMap}
          component={Item}
          id={key}
          key={`${Item.title}-${index}`}
          handleChange={handleFieldChange}
          level={level}
          message={Item.message}
          valuesObject={valuesObject}
          value={valuesObject[key]}
          formName={formName}
        />
      );
    }

    return null;
  });
};

export const GenerateSettingsTab = (
  Item: any,
  index: number,
  key: string,
  valuesObject,
  bannerSettingsMap,
  handleFieldChange: (key: string, value: any) => void,
  Footer: React.ReactNode,
  showAnchors = false,
  level = 1,
  formName: string,
) => {
  const TabData = {
    id: `${toCamelCase(Item.title)}`,
    label: toSentenceCase(Item.title),
    content: null,
  };

  // Removing array item type for now because it will be implemented in a future ticket
  if (!valuesObject || !bannerSettingsMap) {
    return {
      ...TabData,
      hidden: true,
    };
  }
  const contentChildrenType = Object.entries(Item.value).map((item: any) => item[1]?.type);

  const showTitle = contentChildrenType.every(
    (item: any) => item === AdminTemplateInputTypes.collapsableObject || item === AdminTemplateInputTypes.array
  );

  const updatedItem: any = { ...Item, title: showTitle ? '' : toSentenceCase(Item.title) };

  if (Item.type === AdminTemplateInputTypes.array) {
    TabData.content = (
      <ArrayType
        bannerSettingsMap={bannerSettingsMap}
        component={updatedItem}
        id={key}
        key={`${Item.title}-${index}`}
        Footer={Footer}
        handleChange={handleFieldChange}
        level={level}
        message={Item.message}
        valuesObject={valuesObject}
        value={valuesObject[key]}
        formName={formName}
      />
    );
  }
  if (Item.type === AdminTemplateInputTypes.object) {
    TabData.content = (
      <ObjectTypeComponent
        component={updatedItem}
        id={key}
        valuesObject={valuesObject}
        bannerSettingsMap={bannerSettingsMap}
        handleChange={handleFieldChange}
        level={level}
        key={`${Item.title}-${index}`}
        Footer={Footer}
        showAnchors={showAnchors}
        formName={formName}
      />
    );
  }
  if (Item.type === AdminTemplateInputTypes.collapsableObject) {
    TabData.content = (
      <ObjectTypeComponent
        component={updatedItem}
        id={key}
        valuesObject={valuesObject}
        bannerSettingsMap={bannerSettingsMap}
        handleChange={handleFieldChange}
        isCollapsable
        level={level}
        key={`${Item.title}-${index}`}
        Footer={Footer}
        showAnchors={showAnchors}
        formName={formName}
      />
    );
  }
  if (Item.type === AdminTemplateInputTypes.nullObject) {
    TabData.content = (
      <ObjectTypeComponent
        component={updatedItem}
        id={key}
        valuesObject={valuesObject}
        bannerSettingsMap={bannerSettingsMap}
        handleChange={handleFieldChange}
        canBeNull
        level={level}
        key={`${Item.title}-${index}`}
        formName={formName}
      />
    );
  }

  return TabData;
};

export default JsonUiGenerator;
