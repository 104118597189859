import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import {
  ordersListRoute, orderDetailsRoute, deliverySolutionsFailedSubmissionsRoute,
  deliverySolutionsFailedSubmissionsDetailsRoute, fulfillmentFailedSubmissionsRoute,
  fulfillmentFailedSubmissionsDetailsRoute,
  zetesFailedSubmissionsRoute,
} from './constants';

const ordersListRouteComponent: AppRoute = {
  ...ordersListRoute,
  Component: () => import('./pages/OrdersList'),
};

const orderDetailsRouteComponent: AppRoute = {
  ...orderDetailsRoute,
  Component: () => import('./pages/OrderDetails'),
};

const ordersDeliveriesRouteComponent: AppRoute = {
  ...deliverySolutionsFailedSubmissionsRoute,
  Component: () => import('./pages/FailedSubmissions/DeliverySolutions'),
};

const zetesFailedSubmissionsRouteComponent: AppRoute = {
  ...zetesFailedSubmissionsRoute,
  Component: () => import('./pages/FailedSubmissions/Zetes'),
};

const fulfillmentFailedSubmissionsRouteComponent: AppRoute = {
  ...fulfillmentFailedSubmissionsRoute,
  Component: () => import('./pages/FailedSubmissions/Fulfillment'),
};

const deliverySolutionsDetailsRouteComponent: AppRoute = {
  ...deliverySolutionsFailedSubmissionsDetailsRoute,
  Component: () => import('./pages/OrderDetails'),
};


const fulfillmentDetailsRouteComponent: AppRoute = {
  ...fulfillmentFailedSubmissionsDetailsRoute,
  Component: () => import('./pages/OrderDetails'),
};

export const orderManagementRoutes: AppRoute[] = [
  ordersListRouteComponent,
  orderDetailsRouteComponent,
  ordersDeliveriesRouteComponent,
  deliverySolutionsDetailsRouteComponent,
  fulfillmentFailedSubmissionsRouteComponent,
  fulfillmentDetailsRouteComponent,
  zetesFailedSubmissionsRouteComponent,
];
