import { createSelector } from 'reselect';
import { safeGet } from 'framework/utils';

export const getTabs = state => safeGet(state, 'tabGroups', {});

export const getOpenTab = (state, tabGroupId: string) =>
  safeGet(getTabs(state), `${tabGroupId}.openTab`, false);

export const getRegisteredAnchors = (state, tabGroupId: string) =>
  safeGet(getTabs(state), `${tabGroupId}.registeredAnchors`, false);

export const getRegisteredAnchorsMemoized = createSelector(
  [getRegisteredAnchors],
  anchors => anchors
);
