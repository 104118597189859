export default (
  state,
  payload: {
    calendarName: string;
    eventId: string;
  }
) => {
  const { calendarName, eventId } = payload;
  const newState = { ...state };
  const eventsDeleted = newState[calendarName].eventsDeleted || [];

  delete newState[calendarName].events[eventId];

  return {
    ...newState,
    [calendarName]: {
      eventsDeleted: Object.keys(state[calendarName]?.eventsOriginal).includes(eventId)
        ? eventsDeleted.push(eventId)
        : state[calendarName]?.eventsDeleted,
      ...newState[calendarName],
    },
  };
};
