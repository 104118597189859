import React from 'react';
import { hasSameItems } from 'framework/utils/helpers';
import { HierarchyTreeView } from 'framework/components/ui/Hierarchy/hierarchy.tree';
import withStencils from 'framework/components/hoc/withStencils';
import { Kebab, Table, Text } from 'ui';
import { i18n } from 'i18n';
import { HierarchyColumnProps, HierarchyProps } from 'framework/components/ui/Hierarchy/hierarchy.interfaces';
import { GridAction, GridActionResult } from 'types';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ensureClaims } from 'framework/authorization';
import { fetchStores } from '../../api/orghierarchy';
import { StoresTranslations } from '../../translations';
import { OrgHierarchy } from '../../models/orghierarchy';
import {
  getStoresById,
  sortStores,
  hasStoreId,
  getTopStoreNodes,
  getStoresFromIds,
  fixInvalidSelectionSync,
} from './service';
import { leafIcon, nodeIcon } from './constants';
import { BulkActions } from './BulkActions';

import './_styles.scss';

const clx = prefixClassName('stores-filter');

export interface StoresFilterProps extends Partial<HierarchyProps> {
  orgEndpoint?: string;
  caption?: string;
  stencilled?: boolean;
  singleSelect?: boolean;
  selectLeavesOnly?: boolean;
  hasSelectAll?: boolean;
  disabled?: boolean;
  selectedStores?: string[];
  selectTopNodesOnly?: boolean;
  onChange?: (ids: OrgHierarchy[]) => void;
  onFetchStores?: (stores: OrgHierarchy[], root?: OrgHierarchy) => void;
  /**
   * The hierarchy uses Store.id by default for selectedStores prop.
   * Set this value to true to pass Store.value.storeId as the values in selectedStores.
   */
  useStoreId?: boolean;
  isFiltered?: boolean;
  useRetailerStoreId?: boolean;
  filteredData?: OrgHierarchy[];
  stretch?: 'panel' | 'modal';
  additionalColumns?: HierarchyColumnProps[];
  className?: string;
  showHeader?: boolean;
  actions?: GridAction<OrgHierarchy, GridActionResult>[];
  showBulkActions?: boolean;
  showLink?: boolean;
}

const Hierarchy = withStencils('panel')(HierarchyTreeView);

export const StoresFilter = ({ 
  orgEndpoint,
  isFiltered,
  caption = StoresTranslations.SelectAll,
  selectedStores = [],
  onChange,
  stencilled,
  selectTopNodesOnly,
  useStoreId = false,
  useRetailerStoreId = false,
  singleSelect = false,
  hasSelectAll = true,
  onFetchStores,
  filteredData,
  selectLeavesOnly,
  disabled,
  stretch = 'panel',
  additionalColumns,
  className,
  showHeader = true,
  actions,
  showBulkActions = false,
  selectable = true,
  height = 'max',
  showLink = false,
}: StoresFilterProps) => {
  const [apiData, setApiData] = React.useState([]);
  const [rootStore, setRootStore] = React.useState<OrgHierarchy>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [selectedStoresData, setSelectedStoresData] = React.useState<OrgHierarchy[]>();

  const selectedIds = React.useMemo(() => {
    let ids = selectedStores;

    if (rootStore && (useStoreId  || useRetailerStoreId)) {
      ids = getStoresFromIds(rootStore, selectedStores, useStoreId).map((s) => s.id);
    }

    if (rootStore) {
      const fixed = fixInvalidSelectionSync(
        ids,
        rootStore,
      );

      if (hasSameItems(ids || [], selectedIds || [])) {
        return selectedIds || [];
      }

      return fixed;
    }

    return selectedStores;
  }, [rootStore, selectedStores]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchStores(orgEndpoint).then(async (stores) => {
      setRootStore(stores.root ?? stores?.orgHierarchy?.root);
      const data = sortStores(stores.root ?? stores?.orgHierarchy?.root).children;
      setApiData(data);

      if (onFetchStores) {
        onFetchStores(data, stores.root ?? stores?.orgHierarchy?.root);
      }
      setIsLoading(false);
    });
  }, []);

  const onSelectionChanged = (ids: string[]) => {
    if (rootStore) {
      let selectedData = getStoresById(rootStore, ids);
      if (selectTopNodesOnly) {
        selectedData = getTopStoreNodes(rootStore, selectedData);
      }

      if (onChange) onChange(selectedData);
      setSelectedStoresData(selectedData);
    }
  };

  return (
    <>
      {
        (showBulkActions && actions) && (
          <BulkActions actions={actions} selectedStores={selectedStoresData} />
        )
      }
      { isFiltered && filteredData?.length === 0
        && <div className={`${clx}__no-data`}>{i18n.t('generic.dataFetchError').toString()}</div>
      }
      <Hierarchy
        stencilled={isLoading || stencilled}
        selectable={selectable}
        disabled={disabled}
        singleSelect={singleSelect}
        allSelected={hasSelectAll}
        nodeIcon={nodeIcon}
        leafIcon={leafIcon}
        hidden={isFiltered && filteredData?.length === 0}
        columns={
          [
            {
              title: caption,
              content: (store) => {
                const childrenCount: number =
                  store.node.children?.filter((child) => child.value.retailerStoreId)?.length;

                return  (<span>
                  {
                    !showLink || !store.node.value.retailerStoreId
                      ? `${store.node?.value?.description}`
                      : (<Table.ColumnLink
                        to={`/storeManagement/stores/${store.node?.value?.storeId}`}
                        caption={store.node?.value?.description}
                      />)
                  }
                  {childrenCount > 0 &&
                    <Text
                      tag='span' 
                      className='mrg-left-10 mwg-with-tooltip mwg-summarizer' 
                      caption={i18n.t('modules.stores.storesNumber', {count: childrenCount})}
                    />
                  }
                  </span>
                );
              }
            },
            ...(additionalColumns ? [...additionalColumns] : []),
            ...(actions
              ? [{
                title: 'generic.actions',
                className: `${clx}__actions`,
                content: (store) => {
                  const authorizedActions = actions.filter((a) => ensureClaims(a.authorize) && !a.hideRowAction);

                  return (
                    <Kebab
                      options={authorizedActions.map((action) => {
                        const config = action.config(store.node);
                        return ({
                          label: config?.label,
                          onClick: config?.onClick,
                          hidden: config?.hidden,
                        });
                      })}
                    />
                  );
                },
              }]
              : [])
          ]
        }
        showHeader={!disabled && showHeader}
        getNodeIsLeaf={hasStoreId}
        onSelectionChanged={onSelectionChanged}
        selectedIds={selectedIds}
        data={filteredData || apiData}
        stretch={stretch}
        height={height}
        selectLeavesOnly={selectLeavesOnly}
        className={className}
        useIsFiltered
        isFiltered={isFiltered}
      />
    </>
  );
};
