import * as React from 'react';
import { NumericField, TextField } from 'ui';
import { featureFlag } from 'featureFlag';
import { IInputTypeInterface, InputTypes } from '../StoreFrontSettings.interfaces';
import ContactSupport from './ContactSupport';

const InputType = (props: IInputTypeInterface) => {
  const { component, type, value, handleChange, field, id, bypassValueCheck, required } = props;

  const hasValue = (value !== undefined && value !== null) || bypassValueCheck;

  const handleInputChange = (e) => {
    const { value: newValue } = e.target;
    handleChange(field, newValue);
  };

  const handleNumberChange = (num: number) => {
    handleChange(field, num);
  };

  return (
    <>
      {!hasValue && <ContactSupport />}
      {type === InputTypes.Text ? (
        <TextField
          caption={component.title}
          name={`${id}-${field}`}
          onChange={handleInputChange}
          explainerText={component.description}
          initValue={value}
          {...featureFlag({
            support: () => ({
              ...(component.helpLink && {
                explainerLink: {
                  caption: 'generic.readMore',
                  to: component.helpLink,
                  external: true,
                },
              }),
            }),
            prod: () => {},
          })}
          disabled={!hasValue}
          mandatory={required}
          grid
        />
      ) : (
        <NumericField
          caption={component.title}
          name={`${id}-${field}`}
          onChange={handleNumberChange}
          initValue={value}
          explainerText={component.description}
          min={component.options?.min}
          max={component.options?.max}
          {...featureFlag({
            support: () => ({
              ...(component.helpLink && {
                explainerLink: {
                  caption: 'generic.readMore',
                  to: component.helpLink,
                  external: true,
                },
              }),
            }),
            prod: () => {},
          })}
          disabled={!hasValue}
          grid
        />
      )}
    </>
  );
};

export default InputType;
