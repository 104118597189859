import * as React from 'react';
import * as classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IImageLoader } from './Img.interfaces';
import './_style.scss';

const clx = prefixClassName('img-loader');

const ImgLoader = React.memo((props: IImageLoader) => {
  const { isLoading = false, height, width, caption } = props;
  const style = {
    width,
    height,
  };

  return (
    <div
      style={style}
      title={caption}
      aria-label={caption}
      role="img"
      className={classnames(clx, isLoading && `${clx}--isLoading`)}
    />
  );
});

export default ImgLoader;
