import { baseApiUrl, getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { AuthorizationClaims, ensureClaims } from 'framework/authorization';
import { Features, FeatureState, OFFeatureFlag } from './types';

export const isImmediatePicksEnabled = async (): Promise<FeatureState> => {
  const userHasAccess = ensureClaims([
    AuthorizationClaims.ORDER_FULFILLMENT,
    AuthorizationClaims.ORDER_FULFILLMENT_SETUP,
  ]);

  if (!userHasAccess || !baseApiUrl) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData<OFFeatureFlag>({
    url: getApiUrl('/order-fulfillment/feature-flags/feature?featureName={featureName}', {
      featureName: Features.ImmediatePicks,
    }),
  });

  return {
    isEnabled: result.success && result?.data?.enabled || false,
  };
};
