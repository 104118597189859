import * as React from 'react';
import { TextField } from 'ui';
import { IRetailerImageComponentProps } from './interfaces';
import '../_styles.scss';

const ThemeColorPicker = (props: IRetailerImageComponentProps) => {
  const { component, field, value: initValue, handleChange, id } = props;
  const [inputValue, setInputValue] = React.useState<any>(initValue);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    handleChange(field, value);
  };

  React.useEffect(() => {
    setInputValue(initValue);
  }, [initValue]);

  return (
    <div className="retailer-image-component">
      <div className="retailer-image-component_text-box">
        <TextField
          caption={component.title}
          name={`${id}-${field}`}
          onChange={handleInputChange}
          initValue={initValue}
          grid
        />
      </div>
      <span style={{ backgroundColor: inputValue }} className='retailer-image-component_image-preview-link'>
        <div className='retailer-image-component_image-preview' style={{ backgroundColor: inputValue }} />
      </span>
    </div>
  );
};

export default ThemeColorPicker;
