import * as React from 'react';
import * as classnames from 'classnames';
import * as conf from 'framework/components/ui/_conf';
import { ICollapsiblePanel } from './CollapsiblePanel.interfaces';
import { CollapsibleButton } from './CollapsibleButton';
import './_style.scss';

const clx = conf.prefixClassName('collapsible');

const CollapsiblePanel = (props: ICollapsiblePanel) => {
  const [opened, setOpened] = React.useState<boolean>(true);
  
  React.useEffect(() => {
    const { panelId } = props;
    if (panelId) {
      const localStorageKey = `collapsiblePanelstate-${panelId}`;
      const initialState = JSON.parse(localStorage.getItem(localStorageKey)) ?? true;
      setOpened(initialState);
    }
  }, []);

  React.useEffect(() => {
    const { panelId } = props;
    if (panelId) {
      const localStorageKey = `collapsiblePanelstate-${panelId}`;
      localStorage.setItem(localStorageKey, JSON.stringify(opened));
    }
  }, [opened]);

  const toggle = () => {
    setOpened(!opened);
  };

  const { className, panelHeader: Header, children } = props;

  return (
    <div className={classnames(clx, !opened && `${clx}--collapsed`, className)} id={props.panelId}>
      <div className={`${clx}__header`}>
        <CollapsibleButton
          opened={opened}
          onClick={toggle}
          className={`${clx}__header__btn`}
        />
        {typeof Header === 'function' ? <Header /> : Header}
      </div>

      <div className={`${clx}__body`}>
        <div className={`${clx}_body_content`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export { CollapsiblePanel };