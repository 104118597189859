import { baseApiUrl, getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { FeatureState } from './types';

export const isShoppingRulesEnabled = async (): Promise<FeatureState> => {

  if (!baseApiUrl) {
    return {
      isEnabled: false,
    };
  }

  const result = await fetchData<{version: string}>({
    url: getApiUrl('/shoppingrules/options'),
  });

  return {
    isEnabled: result.success && result?.data?.version === 'V2' || false,
  };
};
