import * as React from 'react';
import classNames from 'classnames';
import { Panel, PanelList, Switch, Table } from 'ui';
import { useModal } from 'framework/components/ui/Modal';
import { StorefrontUiTranslations } from 'framework/modules/siteSettings/translations';
import { Uuid } from 'framework/utils/generateId';
import { i18n } from 'i18n';
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import * as isEqual from 'lodash.isequal';
import { useEffectSkipFirstRun } from 'helpers/hooks';
import ContactSupport from '../ContactSupport';
import { IArrayTypeInterface } from '../../StoreFrontSettings.interfaces';
import { OptionRow, optionsMetaData } from './TableConfig';
// eslint-disable-next-line import/no-cycle
import { ADD_OPTION_MODAL, AddOptionModal } from './AddOptionModal';
import { selectedOptions } from '../JsonUiGenerator';

const ArrayType = (props: IArrayTypeInterface) => {
  const {
    valuesObject,
    message,
    level,
    value: initValue,
    bannerSettingsMap,
    component,
    handleChange,
    id,
    Footer,
    formName,
  } = props;

  const hiddenFieldName: string = `${id}-${level}`;

  const value: any[] = initValue.map((v) => {
    // Add or use id for sequencing and deleting
    return { id: v.id ?? Uuid(), ...v };
  });

  const { changeValue, reset } = useForm(formName);
  const { openModal, closeModal } = useModal(ADD_OPTION_MODAL);

  const [originalValue] = React.useState<any[]>([...value]);
  const [localValues, setLocalValues] = React.useState<any[]>(value);
  const [editSequence, setEditSequence] = React.useState<boolean>(false);
  const [editOption, setEditOption] = React.useState<{ id: string; index: number }>();

  const tmpValues = React.useRef<any[]>(localValues);

  const hasValue = !!valuesObject[id] && valuesObject[id] !== undefined && valuesObject[id] !== null;

  const [settings, setSettings] = React.useState<any>(valuesObject[id]);

  const handleFieldChange = (_key: string, newValue: any) => {
    if (Array.isArray(settings)) {
      const changedValues = [...localValues, { id: Uuid(), ...newValue }];
      setSettings(changedValues);
      handleChange(id, changedValues);
      setLocalValues(changedValues);

      tmpValues.current = changedValues;
    }
  };

  const handleFieldUpdate = (newValue: any) => {
    if (Array.isArray(settings)) {
      const changedValues = [...settings];
      changedValues[editOption.index] = newValue;

      setSettings(changedValues);
      handleChange(id, changedValues);
      setLocalValues(changedValues);

      tmpValues.current = changedValues;
    }
  };

  const handleDeleteValue = (_id: string) => {
    const newValues: any[] = localValues.filter((v) => v.id !== _id);

    setLocalValues(newValues);
    tmpValues.current = newValues;
    handleChange(id, newValues);
  };

  React.useEffect(() => {
    setSettings(valuesObject[id]);
  }, [valuesObject]);

  React.useEffect(() => {
    // Handle reset click
    if (!isEqual(value, localValues)) {
      setLocalValues(value);
    }

    if (value.length <= 1) setEditSequence(false);
  }, [initValue]);

  React.useEffect(() => {
    selectedOptions.options = localValues;
  }, [localValues]);

  useEffectSkipFirstRun(() => {
    // Change value of hidden Switch to handle activating save/reset button
    if (!isEqual(originalValue, value)) {
      changeValue(hiddenFieldName, true, true);
    } else {
      reset();
    }
  }, [localValues]);

  const PanelContent = (
    <Panel
      title={component.title}
      {...(message &&
        message.caption?.length >= 1 && {
        messages: [
          {
            caption: message.caption,
            type: message.type,
            showLabel: message.showLabel,
          },
        ],
      })}
      className={classNames(level > 1 && 'stretchToPanelWidth remove-border')}
    >
      {!hasValue && <ContactSupport />}
      {/* Hidden Switch to handle save/reset button in footer when options change */}
      <Switch
        name={hiddenFieldName}
        initValue={false}
        className="mwg-hidden"
      />
      <Table
        draggable={editSequence}
        draggableId="id"
        onAddItemClick={openModal}
        disableAddOption={component.value.provider?.uniqueOptions.length === localValues.length}
        addItemCaption={StorefrontUiTranslations.AddOption}
        editSequence={editSequence}
        onEditSequence={() => {
          setEditSequence(!editSequence);
        }}
        editSequenceCaption={StorefrontUiTranslations.EditSequence}
        columnMetadata={optionsMetaData(component)}
        setExternalData={(items: any[]) => {
          setLocalValues(items);
          handleChange(id, items);
        }}
        emptyText={i18n.t(StorefrontUiTranslations.EmptyTableMessage, { dataType: component.title?.toLowerCase() })}
        cellRenderer={(_b, rowData, _m, i) => {
          return (
            <OptionRow
              component={component}
              data={rowData}
              editSequence={editSequence}
              handleClickOption={(_id: string) => {
                setEditOption({ id: _id, index: i });
                openModal();
              }}
              onDelete={handleDeleteValue}
              index={i}
              key={i}
            />
          );
        }}
        data={localValues}
      />
    </Panel>
  );

  return (
    <PanelList className={classNames(level > 1 && 'remove-border')}>
      {PanelContent}
      {Footer}
      <AddOptionModal
        bannerSettingsMap={bannerSettingsMap}
        component={component}
        id={id}
        level={level + 1}
        settings={settings}
        handleAddOption={handleFieldChange}
        handleUpdateOption={handleFieldUpdate}
        closeModal={() => {
          closeModal();
          if (editOption) setEditOption(null);
        }}
        editOption={localValues.find((v) => v.id === editOption?.id)}
      />
    </PanelList>
  );
};

export default ArrayType;