import { types, tooltipInterfaces } from '.';

export const storeTooltipData = (payload: tooltipInterfaces.IStoreTooltipDataActionPayload) => ({
  type: types.STORE_TOOLTIP_DATA,
  payload,
});

export const deleteTooltipData = (payload: tooltipInterfaces.IRemoveTooltipDataActionPayload) => ({
  type: types.DELETE_TOOLTIP_DATA,
  payload,
});
