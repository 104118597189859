export enum IdentityProviderTranslations {
  ProviderName = 'modules.siteSettings.singleSignOn.providerName',
  ProviderNameHint = 'modules.siteSettings.singleSignOn.providerNameHint',
  ProviderProtocol = 'modules.siteSettings.singleSignOn.providerProtocol',
  IdentityServerUrl = 'modules.siteSettings.singleSignOn.identityServerUrl',
  IdentityServerUrlHint = 'modules.siteSettings.singleSignOn.identityServerUrlHint',
  IdentityServerUrlHintFirstLine = 'modules.siteSettings.singleSignOn.identityServerUrlHintFirstLine',
  IdentityServerUrlHintSecondLine = 'modules.siteSettings.singleSignOn.identityServerUrlHintSecondLine',
  EntityId = 'modules.siteSettings.singleSignOn.entityId',
  EntityIdHintFirstLine = 'modules.siteSettings.singleSignOn.entityIdHintFirstLine',
  EntityIdHintSecondLine = 'modules.siteSettings.singleSignOn.entityIdHintSecondLine',
  SingleSignOnServiceUrl = 'modules.siteSettings.singleSignOn.singleSignOnServiceUrl',
  SingleSignOnServiceUrlHintFirstLine = 'modules.siteSettings.singleSignOn.singleSignOnServiceUrlHintFirstLine',
  SingleSignOnServiceUrlHintSecondLine = 'modules.siteSettings.singleSignOn.singleSignOnServiceUrlHintSecondLine',
  Value = 'modules.siteSettings.singleSignOn.value',
  Password = 'components.form.password',
  IdentityProviders = 'modules.siteSettings.singleSignOn.identityProviders',
  CreateIdentityProvider = 'modules.siteSettings.singleSignOn.createIdentityProvider',
  EditIdentityProvider = 'modules.siteSettings.singleSignOn.editIdentityProvider',
  AllIdentityProviders = 'modules.siteSettings.singleSignOn.allIdentityProviders',
  SingleSignOnIdentityProviders = 'modules.siteSettings.singleSignOn.singleSignOnIdentityProviders',
  Applications = 'modules.siteSettings.singleSignOn.applications',
  ApplicationsHint = 'modules.siteSettings.singleSignOn.applicationsHint',
  Status = 'generic.dataGrid.colStatus',
  Actions = 'generic.actions',
  Reset = 'generic.options.reset',
  Cancel = 'generic.options.cancel',
  StoreFrontUi = 'generic.applications.storefrontui',
  AdminUi = 'generic.applications.adminui',
  AppMenuTitle = 'navigation.siteSettings',
  SingleSignOn = 'modules.siteSettings.singleSignOn.singleSignOn',
  DeleteIdentityProvider = 'modules.siteSettings.singleSignOn.deleteIdentityProvider',
  StatusChangeToActive = 'generic.options.StatusChangeToActive',
  StatusChangeToInactive = 'generic.options.StatusChangeToInactive',
  XIds = 'modules.siteSettings.singleSignOn.xIds',
  IdentityProviderSaveSuccess = 'modules.siteSettings.singleSignOn.identityProviderSaveSuccess',
  IdentityProviderSaveError = 'modules.siteSettings.singleSignOn.identityProviderSaveError',
  IdnetityProviderUpdatedSuccess = 'modules.siteSettings.singleSignOn.identityProviderUpdateSuccess',
  IdnetityProviderUpdatedError = 'modules.siteSettings.singleSignOn.identityProviderUpdateError',
  IdentityProviderDeleteSuccess = 'modules.siteSettings.singleSignOn.identityProviderDeleteSuccess',
  IdentityProviderDeleteError = 'modules.siteSettings.singleSignOn.identityProviderDeleteError',
  IdentityProviderEnableConfirm = 'modules.siteSettings.singleSignOn.identityProviderEnableConfirm',
  IdentityProviderEnableConfirmButton = 'modules.siteSettings.singleSignOn.identityProviderEnableConfirmButton',
  IdentityProviderEnableCancelButton = 'modules.siteSettings.singleSignOn.identityProviderEnableCancelButton',
  IdentityProviderDisableConfirm = 'modules.siteSettings.singleSignOn.identityProviderDisableConfirm',
  IdentityProviderDisableConfirmButton = 'modules.siteSettings.singleSignOn.identityProviderDisableConfirmButton',
  IdentityProviderDisableCancelButton = 'modules.siteSettings.singleSignOn.identityProviderDisableCancelButton',
  IdentityProviderDeleteConfirm = 'modules.siteSettings.singleSignOn.identityProviderDeleteConfirm',
  IdentityProviderDeleteConfirmButton = 'modules.siteSettings.singleSignOn.identityProviderDeleteConfirmButton',
  IdentityProviderDeleteCancelButton = 'modules.siteSettings.singleSignOn.identityProviderDeleteCancelButton',
  IdentityProviderWarningMessage = 'modules.siteSettings.singleSignOn.warningMessage',
  RoleMapping = 'modules.siteSettings.singleSignOn.roleMapping',
  IdentityProviderDetails = 'modules.siteSettings.singleSignOn.identityProviderDetails',
  LocalCertificateValue = 'modules.siteSettings.singleSignOn.localCertificateValue',
  LocalCertificateValueHint = 'modules.siteSettings.singleSignOn.localCertificateValueHint',
  LocalCertificatePassword = 'modules.siteSettings.singleSignOn.localCertificatePassword',
  LocalCertificatePasswordHint = 'modules.siteSettings.singleSignOn.localCertificatePasswordHint',
  PartnerCertificateValue = 'modules.siteSettings.singleSignOn.partnerCertificateValue',
  PartnerCertificateValueHint = 'modules.siteSettings.singleSignOn.partnerCertificateValueHint',
  PartnerCertificatePassword = 'modules.siteSettings.singleSignOn.partnerCertificatePassword',
  PartnerCertificatePasswordHint = 'modules.siteSettings.singleSignOn.partnerCertificatePasswordHint',
  SaveAndContinueToMapping = 'modules.siteSettings.singleSignOn.saveAndContinueToMapping',
  LoadCertificate = 'modules.siteSettings.singleSignOn.loadCertificate',
  LoadCertificateHint = 'modules.siteSettings.singleSignOn.loadCertificateHint',
  LoadedCertificateSuccess = 'modules.siteSettings.singleSignOn.loadedCertificateSuccess',
  LoadedCertificateError = 'modules.siteSettings.singleSignOn.loadedCertificateError',
  IdentityProviderSaml = 'modules.siteSettings.singleSignOn.identityProviderSaml',
  RetailerRole = 'modules.siteSettings.singleSignOn.retailerRole',
  IdentityProviderRoles = 'modules.siteSettings.singleSignOn.identityProviderRoles',
  IdpRolesTagsFieldPlaceholder = 'modules.siteSettings.singleSignOn.idpRolesTagsFieldPlaceholder',
  SaveChanges = 'generic.button.saveChanges',
  Delete = 'generic.delete',
  IdpRolesMappingSaveSuccess = 'modules.siteSettings.singleSignOn.idpRolesMappingSaveSuccess',
  IdpRolesMappingSaveError = 'modules.siteSettings.singleSignOn.idpRolesMappingSaveError',
  Saml = 'modules.siteSettings.singleSignOn.saml',
  IdentityProviderLoadingError = 'modules.siteSettings.singleSignOn.identityProviderLoadingError',
  RoleMappingWarningMessage = 'modules.siteSettings.singleSignOn.roleMappingWarningMessage',
}

export enum DeliveryProvidersTranslations {
  DeliveryProviders = 'modules.siteSettings.deliveryProviders.deliveryProviders',
  DeliverySolutions = 'modules.siteSettings.deliveryProviders.deliverySolutions',
  TestDeliverySolutionsConnection = 'modules.siteSettings.deliveryProviders.testDeliverySolutionsConnection',
  TenantID = 'modules.siteSettings.deliveryProviders.tenantID',
  TenantIDHint = 'modules.siteSettings.deliveryProviders.tenantIDHint',
  AuthenticationKey = 'modules.siteSettings.deliveryProviders.authenticationKey',
  AuthenticationKeyHint = 'modules.siteSettings.deliveryProviders.authenticationKeyHint',
  BaseUrlApi = 'modules.siteSettings.deliveryProviders.baseUrlApi',
  BaseUrlApiHint = 'modules.siteSettings.deliveryProviders.baseUrlApiHint',
  BaseUrlDeliverySolutionsPortal = 'modules.siteSettings.deliveryProviders.baseUrlDeliverySolutionsPortal',
  BaseUrlDeliverySolutionsPortalHint = 'modules.siteSettings.deliveryProviders.baseUrlDeliverySolutionsPortalHint',
  BaseUrlDeliverySolutionsOrderDetails = 'modules.siteSettings.deliveryProviders.baseUrlDeliverySolutionOrderDetailsPage',
  BaseUrlDeliverySolutionsOrderDetailsHint = 'modules.siteSettings.deliveryProviders.baseUrlDeliverySolutionOrderDetailsPageHint',
  OrderSubmissionPolicy = 'modules.siteSettings.deliveryProviders.orderSubmissionPolicy',
  OrderSubmissionPolicyHint = 'modules.siteSettings.deliveryProviders.orderSubmissionPolicyHint',
  OrderPickupPolicy = 'modules.siteSettings.deliveryProviders.orderPickupPolicy',
  OrderPickupPolicyHint = 'modules.siteSettings.deliveryProviders.orderPickupPolicyHint',
  DaysOfApiStatusHistoryToKeep = 'modules.siteSettings.deliveryProviders.daysOfApiStatusHistoryToKeep',
  DaysOfApiStatusHistoryToKeepHint = 'modules.siteSettings.deliveryProviders.daysOfApiStatusHistoryToKeepHint',
  DefaultIsoCountryCode = 'modules.siteSettings.deliveryProviders.defaultIsoCountryCode',
  DefaultIsoCountryCodeHint = 'modules.siteSettings.deliveryProviders.defaultIsoCountryCodeHint',
  HoursPlural = 'generic.units.hoursPlural',
  MinutesPlural = 'generic.units.minutesPlural',
  DaysPlural = 'generic.units.daysPlural',
  Status = 'generic.dataGrid.colStatus',
  SaveChanges = 'generic.button.saveChanges',
  SaveSettings = 'modules.siteSettings.deliveryProviders.saveSettings',
  Reset = 'generic.options.reset',
  ConfirmationMsgToEnable = 'modules.siteSettings.deliveryProviders.confirmationMsgToEnable',
  ConfirmationMsgToDisable = 'modules.siteSettings.deliveryProviders.confirmationMsgToDisable',
  WarningNotHavingDsInstalled = 'modules.siteSettings.deliveryProviders.warningNotHavingDsInstalled',
  WarningMustCompleteInProgressOrders = 'modules.siteSettings.deliveryProviders.warningMustCompleteInProgressOrders',
  WhenOrderIsPlaced = 'modules.siteSettings.deliveryProviders.whenOrderIsPlaced',
  AfterOrderHasBeenPickedOrPacked = 'modules.siteSettings.deliveryProviders.afterOrderHasBeenPickedOrPacked',
  DeliverySolutionsSaveSuccess = 'modules.siteSettings.deliveryProviders.deliverySolutionsSaveSuccess',
  DeliverySolutionsSaveError = 'modules.siteSettings.deliveryProviders.deliverySolutionsSaveError',
  DeliveryProvidersLoadingError = 'modules.siteSettings.deliveryProviders.deliveryProvidersLoadingError',
  EnableIntegration = 'modules.siteSettings.deliveryProviders.enableIntegration',
  DisableIntegration = 'modules.siteSettings.deliveryProviders.disableIntegration',
  TestDeliverySolutionsConnectionSucceed = 'modules.siteSettings.deliveryProviders.testDeliverySolutionsConnectionSucceed',
  TestDeliverySolutionsConnectionFailed = 'modules.siteSettings.deliveryProviders.testDeliverySolutionsConnectionFailed',
  SendPaymentToggle = 'modules.siteSettings.deliveryProviders.sendPaymentToggle',
  SendPaymentToggleHint = 'modules.siteSettings.deliveryProviders.sendPaymentToggleHint',
}

export enum SharedSettingsTranslations {
  GroupTitle = 'modules.siteSettings.sharedSettings.groupTitle',
  UpdateError = 'modules.siteSettings.sharedSettings.updateError',
  UpdateSuccess = 'modules.siteSettings.sharedSettings.updateSuccess',
  Settings = 'modules.siteSettings.sharedSettings.settings',
}

export enum MobileAppUiTranslations {
  GroupTitle = 'modules.siteSettings.mobileApp.groupTitle',
  Settings = 'modules.siteSettings.mobileApp.settings',
  Banner = 'modules.siteSettings.mobileApp.banner',
  EditBanner = 'modules.siteSettings.mobileApp.editBanner',
  UpdateError = 'modules.siteSettings.mobileApp.updateError',
  UpdateSuccess = 'modules.siteSettings.mobileApp.updateSuccess',
}

export enum StorefrontUiTranslations {
  GroupTitle = 'modules.siteSettings.storefront.groupTitle',
  Settings = 'modules.siteSettings.storefront.settings',
  Themes = 'modules.siteSettings.storefront.themes',
  Images = 'modules.siteSettings.storefront.images',
  ImageNameCaption = 'modules.siteSettings.storefront.imageNameCaption',
  IntegrationMessage = 'modules.siteSettings.storefront.integrationMessage',
  Banner = 'modules.siteSettings.storefront.banner',
  EditBanner = 'modules.siteSettings.storefront.editBanner',
  CreateBanner = 'modules.siteSettings.storefront.createBanner',
  BannerName = 'modules.siteSettings.storefront.bannerName',
  RetailerCode = 'modules.siteSettings.storefront.retailerCode',
  BannerLogo = 'modules.siteSettings.storefront.bannerLogo',
  BannerDetails = 'modules.siteSettings.storefront.bannerDetails',
  BannerDetailsMessage = 'modules.siteSettings.storefront.bannerDetailsMessage',
  BannerNameExplainer = 'modules.siteSettings.storefront.bannerNameExplainer',
  RetailerCodeExplainer = 'modules.siteSettings.storefront.retailerCodeExplainer',
  BannerNameError = 'modules.siteSettings.storefront.bannerNameError',
  StorefrontSettingsUpdateError = 'modules.siteSettings.storefront.storefrontSettingsUpdateError',
  StorefrontSettingsUpdateSuccess = 'modules.siteSettings.storefront.storefrontSettingsUpdateSuccess',
  AddOption = 'modules.siteSettings.storefront.addOption',
  UpdateOption = 'modules.siteSettings.storefront.updateOption',
  EditSequence = 'generic.options.editSequence',
  EmptyTableMessage = 'modules.siteSettings.storefront.emptyTableMessage',
  BulkRemoveStores = 'modules.siteSettings.storefront.bulkRemoveStores',
  BulkRemoveStoresExplainer = 'modules.siteSettings.storefront.bulkRemoveStoresExplainer',
  StoreIds = 'modules.siteSettings.storefront.storeIds',
}

export enum SegmentsTranslations {
  GroupTitle = 'modules.segments.groupTitle',
  AllSegmentsBreadCrumb = 'modules.segments.allSegmentsBreadcrumb',
  AllSegmentsPageTitle = 'modules.segments.allSegmentsPageTitle',
  AllSegmentsMenuTitle = 'modules.segments.allSegmentsMenuTitle',
  CreateSegmentBreadCrumb = 'modules.segments.createSegmentBreadcrumb',
  CreateSegmentPageTitle = 'modules.segments.createSegmentPageTitle',
  CreateSegmentMenuTitle = 'modules.segments.createSegmentMenuTitle',
  EditSegmentBreadCrumb = 'modules.segments.editSegmentBreadcrumb',
  EditSegmentPageTitle = 'modules.segments.editSegmentPageTitle',
  EditSegmentMenuTitle = 'modules.segments.editSegmentMenuTitle',
  FilterByName = 'modules.segments.filterByName',
  FilterByStatus = 'generic.filterByStatus',
  FilterByType = 'modules.segments.filterByType',
  SegmentName = 'modules.segments.segmentName',
  SegmentNameExplainer = 'modules.segments.segmentNameExplainer',
  SegmentType = 'modules.segments.segmentType',
  SegmentTypeExplainer = 'modules.segments.segmentTypeExplainer',
  SegmentId = 'modules.segments.segmentId',
  SegmentIdExplainer = 'modules.segments.segmentIdExplainer',
  DeleteSegment = 'modules.segments.deleteSegment',
  DeleteSegmentName = 'modules.segments.deleteSegmentName',
  DetachSegment = 'modules.segments.detachSegment',
  CancelDeleteSegment = 'modules.segments.cancelDeleteSegment',
  Status = 'modules.segments.status',
  Type = 'modules.segments.type',
  DeleteWarningMessage = 'modules.segments.warningMessage',
  SegmentDetails = 'modules.segments.segmentDetails',
  SaveSegment = 'modules.segments.saveSegment',
  Emails = 'modules.segments.emails',
  NumberOfEmails = 'modules.segments.numberOfEmails',
  NumberOfEmailsExplainer = 'modules.segments.numberOfEmailsExplainer',
  NumberOfCustomers = 'modules.segments.numberOfCustomers',
  EmailsExplainer = 'modules.segments.emailsExplainer',
  EmailsPlaceholder = 'modules.segments.emailsPlaceholder',
  ThreeOrMoreTermsRequired = 'modules.segments.threeOrMoreTermsRequired',
  PoolingTitle = 'modules.segments.poolingTitle',
  PoolingDescription = 'modules.segments.poolingDescription',
  DeactivateSegment = 'generic.options.StatusChangeToInactive',
  ActivateSegment = 'generic.options.StatusChangeToActive',
  Customers = 'modules.segments.customers'
}

export enum StsTranslations {
  GroupTitle = 'modules.siteSettings.sts.groupTitle',
  Settings = 'modules.siteSettings.sts.settings',
  Themes = 'modules.siteSettings.sts.themes',
  Images = 'modules.siteSettings.sts.images',
}

export enum WebhookTranslations {
  GroupTitle = 'modules.siteSettings.webhooks.webhooks',
  MenuTitle = 'modules.siteSettings.webhooks.menuTitle',
  WebhookListing = 'modules.siteSettings.webhooks.webhooks',

  // Listing Filter
  FilterByKeyword = 'modules.siteSettings.webhooks.filters.filterByKeyword',
  FilterByStatus = 'modules.siteSettings.webhooks.filters.filterByStatus',
  FilterByAuth = 'modules.siteSettings.webhooks.filters.filterByAuth',

  // Enable confirmation modal
  EnableWebhook = 'generic.options.StatusChangeToActive',
  EnableWebhookTitle = 'modules.siteSettings.webhooks.enableWebhookTitle',
  EnableWebhookConfirm = 'modules.siteSettings.webhooks.enableWebhookConfirmation',
  EnableWebhookWarningMessage = 'modules.siteSettings.webhooks.enableWebhookWarningMessage',
  EnableWebhookCancel = 'modules.siteSettings.webhooks.enableWebhookCancel',
  EnableWebhookSuccess = 'modules.siteSettings.webhooks.enableWebhookSuccess',
  EnableWebhookError = 'modules.siteSettings.webhooks.enableWebhookError',

  // Disable confirmation modal
  DisableWebhook = 'generic.options.StatusChangeToInactive',
  DisableWebhookTitle = 'modules.siteSettings.webhooks.disableWebhookTitle',
  DisableWebhookConfirm = 'modules.siteSettings.webhooks.disableWebhookConfirmation',
  DisableWebhookWarningMessage = 'modules.siteSettings.webhooks.disableWebhookWarningMessage',
  DisableWebhookCancel = 'modules.siteSettings.webhooks.disableWebhookCancel',
  DisableWebhookSuccess = 'modules.siteSettings.webhooks.disableWebhookSuccess',
  DisableWebhookError = 'modules.siteSettings.webhooks.disableWebhookError',

  // Delete confirmation modal
  DeleteWebhook = 'modules.siteSettings.webhooks.deleteWebhook',
  DeleteWebhookTitle = 'modules.siteSettings.webhooks.deleteWebhookTitle',
  DeleteWebhookConfirm = 'modules.siteSettings.webhooks.deleteWebhookConfirmation',
  DeleteWebhookWarningMessage = 'modules.siteSettings.webhooks.deleteWebhookWarningMessage',
  DeleteWebhookCancel = 'modules.siteSettings.webhooks.deleteWebhookCancel',
  DeleteWebhookSuccess = 'modules.siteSettings.webhooks.deleteWebhookSuccess',
  DeleteWebhookError = 'modules.siteSettings.webhooks.deleteWebhookError',

  Webhook = 'modules.siteSettings.webhooks.webhook',
  Webhooks = 'modules.siteSettings.webhooks.webhooks',
  WebhookDetails = 'modules.siteSettings.webhooks.webhookDetails',
  WebhookInformation = 'modules.siteSettings.webhooks.webhookInformation',
  WebhookName = 'modules.siteSettings.webhooks.fields.webhookName',
  WebhookNameExplainer = 'modules.siteSettings.webhooks.fields.webhookNameExplainer',
  WebhookType = 'modules.siteSettings.webhooks.fields.webhookType',
  WebhookTypeExplainer = 'modules.siteSettings.webhooks.fields.webhookTypeExplainer',
  URI = 'modules.siteSettings.webhooks.fields.URI',
  URIExplainer = 'modules.siteSettings.webhooks.fields.URIExplainer',
  Method = 'modules.siteSettings.webhooks.fields.method',
  Timeout = 'modules.siteSettings.webhooks.fields.timeout',
  TimeoutExplainer = 'modules.siteSettings.webhooks.fields.timeoutExplainer',
  MethodExplainer = 'modules.siteSettings.webhooks.fields.methodExplainer',
  AuthenticationType = 'modules.siteSettings.webhooks.fields.authenticationType',
  AuthenticationTypeExplainer = 'modules.siteSettings.webhooks.fields.authenticationTypeExplainer',
  AuthenticationValue = 'modules.siteSettings.webhooks.fields.authenticationValue',
  AuthenticationValueExplainer = 'modules.siteSettings.webhooks.fields.authenticationValueExplainer',
  Username = 'modules.siteSettings.webhooks.fields.username',
  Password = 'modules.siteSettings.webhooks.fields.password',
  RetryIntervals = 'modules.siteSettings.webhooks.fields.retryIntervals',
  Seconds = 'modules.siteSettings.webhooks.fields.seconds',
  HeaderName = 'modules.siteSettings.webhooks.webhookColumns.headerName',
  HeaderValue = 'modules.siteSettings.webhooks.webhookColumns.headerValue',
  AddHeader = 'modules.siteSettings.webhooks.addHeader',
  DeleteHeader = 'modules.siteSettings.webhooks.deleteHeader',
  AddInterval = 'modules.siteSettings.webhooks.addInterval',
  CreateWebhook = 'modules.siteSettings.webhooks.createWebhook',
  IntervalMessage = 'modules.siteSettings.webhooks.intervalMessage',
  WebhookHeaders = 'modules.siteSettings.webhooks.webhookHeaders',
  EmptyHeaders = 'modules.siteSettings.webhooks.emptyHeaders',
  HeadersMessage = 'modules.siteSettings.webhooks.headersMessage',
  AssignStores = 'modules.siteSettings.webhooks.assignStores',
  StoresMessage1 = 'modules.siteSettings.webhooks.storesMessage1',
  StoresMessage2 = 'modules.siteSettings.webhooks.storesMessage2',
  FailedToLoad = 'modules.siteSettings.webhooks.failedToLoad',
  SaveChanges = 'modules.siteSettings.webhooks.saveChanges',
  WebhookSaveSuccess = 'modules.siteSettings.webhooks.webhookSaveSuccess',
  WebhookSaveFailed = 'modules.siteSettings.webhooks.webhookSaveFailed',
  Active = 'generic.status.active',
  Inactive = 'generic.status.inactive',
  Status = 'modules.siteSettings.webhooks.status',
}

export enum ShoppingRuleTranslations {
  ShoppingRule = 'modules.siteSettings.shoppingRules.shoppingRule',
  ShoppingRules = 'modules.siteSettings.shoppingRules.shoppingRules',
  AllShoppingRules = 'modules.siteSettings.shoppingRules.allShoppingRules',
  CreateShoppingRule = 'modules.siteSettings.shoppingRules.createShoppingRule',
  ShoppingRuleDetails = 'modules.siteSettings.shoppingRules.shoppingRuleDetails',
  Name = 'modules.siteSettings.shoppingRules.name',
  Description = 'modules.siteSettings.shoppingRules.description',
  ShoppingRuleInformation = 'modules.siteSettings.shoppingRules.shoppingRuleInformation',
  NameExplainer = 'modules.siteSettings.shoppingRules.nameExplainer',
  DescriptionExplainer = 'modules.siteSettings.shoppingRules.descriptionExplainer',
  AssignStores = 'modules.siteSettings.shoppingRules.assignStores',
  AssignedStores = 'modules.siteSettings.shoppingRules.assignedStores',
  StoresMessage1 = 'modules.siteSettings.shoppingRules.storesMessage1',
  StoresMessage2 = 'modules.siteSettings.shoppingRules.storesMessage2',
  SaveAndContinue = 'modules.siteSettings.shoppingRules.saveAndContinue',
  FailedToLoad = 'modules.siteSettings.shoppingRules.failedToLoad',
  SaveSuccess = 'modules.siteSettings.shoppingRules.saveSuccess',
  SaveFailed = 'modules.siteSettings.shoppingRules.saveFailed',
  Status = 'modules.siteSettings.shoppingRules.status',
  Active = 'generic.status.active',
  Inactive = 'generic.status.inactive',
  ShoppingRuleTabMessage = 'modules.siteSettings.shoppingRules.shoppingRuleTab.message',
  IfStoreIs = 'modules.siteSettings.shoppingRules.shoppingRuleTab.ifStoreIs',
  Then = 'modules.siteSettings.shoppingRules.shoppingRuleTab.then',
  ErrorLoadingSchema = 'modules.siteSettings.shoppingRules.shoppingRuleTab.errorLoadingSchema',
  ErrorLoadingOptions = 'modules.siteSettings.shoppingRules.shoppingRuleTab.errorLoadingOptions',
  SaveShoppingRule = 'modules.siteSettings.shoppingRules.shoppingRuleTab.saveShoppingRule',
}

export enum OrderPreferencesTranslation {
  OrderPreferences = 'modules.siteSettings.orderPreferences.orderPreferences',
  OrderPreference = 'modules.siteSettings.orderPreferences.orderPreference',
  MenuTitle = 'modules.siteSettings.orderPreferences.menuTitle',
  createOrderPreferences = 'modules.siteSettings.orderPreferences.createOrderPreferences',
  saveOrderPreferences = 'modules.siteSettings.orderPreferences.saveOrderPreferences',
  OrderPreferenceName = 'modules.siteSettings.orderPreferences.orderPreferenceName',
  OrderPreferenceInformation = 'modules.siteSettings.orderPreferences.orderPreferenceInfo',
  Shoppingmode = 'modules.siteSettings.orderPreferences.shoppingMode',
  Status = 'modules.siteSettings.orderPreferences.status',
  Active = 'generic.status.active',
  Inactive = 'generic.status.inactive',
  Stores = 'modules.siteSettings.orderPreferences.stores',
  Tags = 'modules.siteSettings.orderPreferences.Tags',
  OrderPreferenceSaveSuccess = 'modules.siteSettings.orderPreferences.orderPreferenceSaveSuccess',
  OrderPreferenceSaveFailed = 'modules.siteSettings.orderPreferences.orderPreferenceSaveFailed',
  FailedToLoad = 'modules.siteSettings.orderPreferences.failedToLoad',
  OrderPreferenceReadOnly = 'modules.siteSettings.orderPreferences.orderPreferenceReadOnlyCaption',

  // Activate Order Preference
  Activate = 'generic.options.StatusChangeToActive',
  ActivateMessage = 'modules.siteSettings.orderPreferences.activateConfirmMessage',
  CancelActivate = 'modules.siteSettings.orderPreferences.dontActivateBtn',
  ActivateSuccess = 'modules.siteSettings.orderPreferences.activateSuccess',
  ActivateError = 'modules.siteSettings.orderPreferences.activateError',

  // Deactivate Order Preference
  Deactivate = 'generic.options.StatusChangeToInactive',
  DeactivateMessage = 'modules.siteSettings.orderPreferences.deactivateConfirmMessage',
  CancelDeactivate = 'modules.siteSettings.orderPreferences.dontDeactivateBtn',
  DeactivateSuccess = 'modules.siteSettings.orderPreferences.deactivateSuccess',
  DeactivateError = 'modules.siteSettings.orderPreferences.deactivateError',

  // Details module
  OrderPreferenceDetails = 'modules.siteSettings.orderPreferences.orderPreferenceDetails',
  OrderPreferenceOptions = 'modules.siteSettings.orderPreferences.orderPreferenceOptions',
  OrderPreferenceTags = 'modules.siteSettings.orderPreferences.tags.headline',
  OrderPreferenceAssignStore = 'modules.siteSettings.orderPreferences.assignStores.headline',

  // Order preference details
  DetailsCaption = 'modules.siteSettings.orderPreferences.details.caption',
  ChargeAmountsKnown = 'modules.siteSettings.orderPreferences.details.chargeAmountsKnown',
  ChargeAmountsUnknown = 'modules.siteSettings.orderPreferences.details.chargeAmountsUnknown',
  NameExplainer = 'modules.siteSettings.orderPreferences.details.nameExplainer',
  HeadlineTitle = 'modules.siteSettings.orderPreferences.details.headLine',
  HeadlineExplainer = 'modules.siteSettings.orderPreferences.details.headLineExplainer',
  ShoppingMode = 'modules.siteSettings.orderPreferences.details.shoppingMode',
  ShoppingModeExplainer = 'modules.siteSettings.orderPreferences.details.shoppingModeExplainer',
  GroupTitle = 'modules.siteSettings.orderPreferences.details.group',
  GroupExplainer = 'modules.siteSettings.orderPreferences.details.groupExplainer',
  BarcodePlu = 'modules.siteSettings.orderPreferences.details.barcodePlu',
  BarcodePluExplainer = 'modules.siteSettings.orderPreferences.details.barcodePluExplainer',
  HideOptions = 'modules.siteSettings.orderPreferences.details.hideOptionsInCheckout',
  HideOptionsExplainer = 'modules.siteSettings.orderPreferences.details.hideOptionsExplainer',
  optionSelection = 'modules.siteSettings.orderPreferences.optionSelection',
  optionSelectionExplainerText = 'modules.siteSettings.orderPreferences.optionSelectionExplainerText',
  title = 'modules.siteSettings.orderPreferences.optionSelectionConfirmationModal.title',
  warningMessage = 'modules.siteSettings.orderPreferences.optionSelectionConfirmationModal.warning',
  confirmButton = 'modules.siteSettings.orderPreferences.optionSelectionConfirmationModal.confirm',
  cancelButton = 'modules.siteSettings.orderPreferences.optionSelectionConfirmationModal.cancel',

  // Order preference options
  OptionLabel = 'modules.siteSettings.orderPreferences.options.optionLabel',
  OptionsCaption = 'modules.siteSettings.orderPreferences.options.caption',
  AddOption = 'modules.siteSettings.orderPreferences.options.addOption',
  EditOption = 'modules.siteSettings.orderPreferences.options.editOption',
  EditSequence = 'modules.siteSettings.orderPreferences.options.editSequence',
  EmptyOptions = 'modules.siteSettings.orderPreferences.options.emptyOptions',
  Default = 'modules.siteSettings.orderPreferences.options.default',

  // create  Order preference options modal
  OptionLabelExplainerHint = 'modules.siteSettings.orderPreferences.options.createOptionsModal.optionLabelExplainerHint',
  AmountType = 'modules.siteSettings.orderPreferences.options.createOptionsModal.amountType',
  AmountTypeExplainerHint = 'modules.siteSettings.orderPreferences.options.createOptionsModal.amountTypeExplainerHint',
  Predefined = 'modules.siteSettings.orderPreferences.options.createOptionsModal.predefined',
  Custom = 'modules.siteSettings.orderPreferences.options.createOptionsModal.custom',
  AmountCharged = 'modules.siteSettings.orderPreferences.options.createOptionsModal.amountCharged',
  AmountChargedExplainerHint = 'modules.siteSettings.orderPreferences.options.createOptionsModal.amountChargedExplainerHint',
  MinimumAmount = 'modules.siteSettings.orderPreferences.options.createOptionsModal.minimumAmount',
  MinimumAmountExplainerHint = 'modules.siteSettings.orderPreferences.options.createOptionsModal.minimumAmountExplainerHint',
  MaximumAmount = 'modules.siteSettings.orderPreferences.options.createOptionsModal.maximumAmount',
  MaximumAmountExplainerHint = 'modules.siteSettings.orderPreferences.options.createOptionsModal.maximumAmountExplainerHint',
  DisplayOrderListing = 'modules.siteSettings.orderPreferences.options.createOptionsModal.displayOrderListing',
  DisplayOrderListingExplainerHint = 'modules.siteSettings.orderPreferences.options.createOptionsModal.displayOrderListingExplainerHint',
  CustomOptionExists = 'modules.siteSettings.orderPreferences.options.createOptionsModal.customOptionExist',
  BarcodePluOptionExplainer = 'modules.siteSettings.orderPreferences.options.barcodePluExplainer',

  // Order preference tags
  TagsCaption = 'modules.siteSettings.orderPreferences.tags.caption',
  TagName = 'modules.siteSettings.orderPreferences.tags.tagName',
  EmptyTags = 'modules.siteSettings.orderPreferences.tags.emptyTags',
  EmptyTagsOptions = 'modules.siteSettings.orderPreferences.tags.emptyTagsOptions',
  AddTag = 'modules.siteSettings.orderPreferences.tags.addTag',

  // Order preference stores
  StoresHeadline = 'modules.siteSettings.orderPreferences.assignStores.headline',
  Message1 = 'modules.siteSettings.orderPreferences.assignStores.message1',
  Message2 = 'modules.siteSettings.orderPreferences.assignStores.message2',

  // Listing Filter
  FilterByStatus = 'modules.siteSettings.orderPreferences.filters.filterByStatus',
  FilterByKeyword = 'modules.siteSettings.orderPreferences.filters.filterByKeyword',
  FilterByTags = 'modules.siteSettings.orderPreferences.filters.filterByTags',
  FilterByShoppingMode = 'modules.siteSettings.orderPreferences.filters.filterByShoppingMode',
}
