import * as CONST from 'framework/modules/merchandising/constants';

export const saveRecommendationDetails = (id) => ({
  type: CONST.ACTION_RECOMMENDATION_SAVE_DETAILS,
  payload: { id },
});

export const saveRecommendationRules = (id) => ({
  type: CONST.ACTION_RECOMMENDATION_SAVE_RULES,
  payload: { id },
});
