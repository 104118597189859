import { SaveLayoutTitleArgs } from './interfaces';

const cssRoot = 'mwg-cms-layout-text';
const transRoot = 'modules.contentmanagement.layouts.textLayout';

export const SAVE_LAYOUT_TEXT = 'SAVE_LAYOUT_TEXT';

export const cssClasses = {
  root: cssRoot,
  main: `${cssRoot}-main-wrapper`,
  wrap: `${cssRoot}-wrap`,
  title: `${cssRoot}-title`,
  explainer: 'mwg-form-field-wrapper__explainer',
  description: `${cssRoot}-description`,
};

export const translations = {
  title: `${transRoot}.title`,
  description: `${transRoot}.description`,
  inputTitle: `${transRoot}.inputTitle`,
  dropdownTitle: `${transRoot}.dropdownTitle`,
  explainer: `${transRoot}.explainer`,
  heading1: `${transRoot}.heading1`,
  heading2: `${transRoot}.heading2`,
  heading3: `${transRoot}.heading3`,
  alignmentTitle: `${transRoot}.alignmentTitle`,
  alignmentExplainer: `${transRoot}.alignmentExplainer`,
  left: `${transRoot}.left`,
  center: `${transRoot}.center`,
  right: `${transRoot}.right`,
};

export const actions = {
  saveTitleLayout: ({ attributeValue, attributeType, rowIndex }: SaveLayoutTitleArgs) => ({
    type: SAVE_LAYOUT_TEXT,
    payload: { attributeValue, attributeType, rowIndex },
  }),
};
