import { fetchData, IFetchDataResponse, IFetchDataRequest } from './fetch-data';
import { put, call, SimpleEffect } from 'redux-saga/effects';
import { createApiClientUpdateStateAction } from './apiClient/actions';
import { withFetching } from 'framework/sagas/extensions/fetching';

export interface IMakeRequest extends IFetchDataRequest {
  /**
   * Name in the state to be stored
   */
  name: string;
}

/**
 * Makes an api call with the provided details. Sets isFetching to true and false.
 * @param {IMakeRequest} requestDetails
 * @returns {IFetchDataResponse} IFetchDataResponse
 */
export function* makeRequest<T>(
  requestDetails: IMakeRequest
): IterableIterator<SimpleEffect<any, any>> | IteratorResult<IFetchDataResponse<T>> {
  // Make request
  const response: IFetchDataResponse<T> = yield withFetching(
    requestDetails.name,
    fetchData,
    requestDetails
  );

  return response;
}

/**
 * Makes an api call with the provided details and saves the returned data in state.
 * Sets isFetching to true and false.
 * @param {IMakeRequest} requestDetails
 * @returns {IFetchDataResponse} IFetchDataResponse
 */
export function* makeRequestAndSaveState<T>(
  requestDetails: IMakeRequest
): IFetchDataResponse<T> | any {
  // Makes the request
  const response: IFetchDataResponse<T> = yield call(makeRequest, requestDetails);

  // Saves the response in state
  yield saveState(requestDetails.name, response);

  return response;
}

/**
 * Calls the function passed and saves the response in state
 * @param state
 * @param func
 * @param args
 */
export function* callAndSaveState(state, func, ...args) {
  const response = yield withFetching(state, func, ...args);

  yield saveState(state, response);
}

// Saves the response in state
export function* saveState<T>(state: string, response: IFetchDataResponse<T>) {
  yield put(
    createApiClientUpdateStateAction({
      name: state, // name in the state
      success: response.success,
      error: response.error,
      data: response.data
    })
  );
}
