import { ProductTranslations } from '../../../translations';

const Conflict = 'Conflict';

interface ProductError {
  globalErrors?: string[];
  fieldErrors?: any;
}

export const mapError = (error): ProductError => {
  if (error === Conflict) {
    return { globalErrors: [ProductTranslations.ErrorConflictingSku] };
  }

  return { globalErrors: [ProductTranslations.SaveError] };
};
