import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { formField } from 'framework/components/hoc/formField';
import {
  TimePickerPrimitive,
  ITimePickerPrimitive,
} from 'framework/components/ui/FormComponents/Primitives';
import { Icon } from 'ui';
import classNames from 'classnames';
import './_style.scss';

const clx = prefixClassName('form-field-datepicker');
const cnField = prefixClassName('form-field');

export const TimePicker = (props: ITimePickerPrimitive) => {
  const { readOnly, disabled } = props;
  const editable = !disabled && !readOnly;

  return (
    <div className={classNames(`${clx}__wrapper`, editable && `${clx}--editable`)}>
      <TimePickerPrimitive {...props as any} className={classNames(cnField, clx)} />
      <Icon name="clock" className={classNames(`${clx}__icon`, `${clx}__icon--clock`)} />
    </div>
  );
};

export default formField(TimePicker);
