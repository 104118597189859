/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { getUser, workInProgress } from '../CommonPage/CommonPage.Selectors';
import { CommonPageRoles as CommonPageRolesComponent } from './CommonPageRoles';
import { CommonPageRolesProps, CommonPageRolesActions } from './CommonPageRoles.Interfaces';
import { saveUserDataAction, saveUserStatusAction } from '../CommonPage/CommonPage.Actions';

const mapStateToProps = (state): CommonPageRolesProps => ({
  user: getUser(state),
  workInProgress: workInProgress(state),
});

const mapDispatchToProps = (dispatch, ownProps: CommonPageRolesProps): CommonPageRolesActions => ({
  saveRoles: ({ roles }) => dispatch(saveUserDataAction({ roles, createFlow: !ownProps.editFlow })),
  saveUserStatus: ({ status }) => dispatch(saveUserStatusAction({ status })),
});

export const CommonPageRoles = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonPageRolesComponent);
