import { useEffect, useRef, useState } from 'react';
import { store as _store } from '@risingstack/react-easy-state';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'framework/selectors';
import { addProgressBar } from 'framework/components/ui/ProgressBar/useProgressBar';
import { IconNames } from 'framework/components/ui/Icon';
import { useModal } from 'framework/components/ui/Modal';
import { fetchData, fileUpload } from 'framework/api/fetch-data';
import { SubmitResult } from 'framework/components/ui/FormComponents/Form/Form.interface';
import { AppError } from 'types';
import config from 'config';
import { toastError, toastSuccess } from 'stores/toast';
import { removeSpaceAndSpecialCharacter, renameFile } from 'framework/utils/helpers';
import { useLocation } from 'react-router-dom';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { localImgId } from 'framework/components/ui/Img/Img';
import { Uuid } from 'framework/utils/generateId';
import {
  fetchFolders,
  formatChildren,
  attachChildren,
  deleteFileFolder,
} from './services';
import {
  FileFolder,
  FileFolderType,
  MissingData,
  ExplorerMode,
  UploaderFileManagerProps,
  ServerFile,
  RootFileManagerProps,
  ExplorerFileManagerProps,
} from './types';
import { fileExtensionMapping, FILEMANAGER_MODAL, FILEMANAGER_MODAL_MOVE_TO_FOLDER } from './constants';
import { useFileManagerContext } from './FileManagerContext';

const getStore = () => {
  const store = _store({
    isOpen: false,
    initializing: true,
    loadingReference: undefined as string,
    data: [] as FileFolder[],

    initializeStore: addProgressBar(async (open: boolean) => {
      store.isOpen = open;
      if (store.isOpen) {
        store.initializing = true;
        await fetchFolders('', true)
          .then((res) => {
            store.data = formatChildren(res.data ?? []);
          })
          .finally(() => {
            store.initializing = false;
          });
      }
    }),

    isLoading: (ref: string) => store.loadingReference === ref,

    resolveIcon: (row: FileFolder): IconNames => {
      if (row.type === FileFolderType.DIRECTORY) {
        return 'folder';
      }
      return fileExtensionMapping[row.name.split('.').pop()];
    },

    fetchSubFolders: addProgressBar(async (row: FileFolder, includeFiles: boolean = true) => {
      store.loadingReference = row.type === FileFolderType.DIRECTORY ? row.reference : undefined;
      return fetchFolders(row.reference, includeFiles)
        .then((res) => {
          store.data = attachChildren(row.reference, store.data, formatChildren(res.data));
          return res.data;
        })
        .finally(() => {
          store.loadingReference = undefined;
        });
    }),

    rowClick: async (
      row: FileFolder,
      includeFiles: boolean = true,
    ) => store.fetchSubFolders(row, includeFiles).then((res) => res),
  });
  return store;
};

type BaseFileManagerProps = {
  modalName?: string;
};

export const useBaseFileManager = ({ modalName }: BaseFileManagerProps) => {
  const store = useRef(getStore()).current;
  const [missingData, setMissingData] = useState<MissingData>();
  const modalIsOpen = useSelector((state) => modalSelectors.isModalOpen(state, modalName));

  // fetch folders when modal is mounted
  useEffect(() => {
    store.initializeStore(!modalName || modalIsOpen);
  }, [modalIsOpen]);

  return {
    store,
    missingData,
    setMissingData,
  };
};

export const useExplorerFileManager = ({ explorerModalName = FILEMANAGER_MODAL }: ExplorerFileManagerProps) => {
  const base = useBaseFileManager({ modalName: explorerModalName });
  const { openModal } = useModal(FILEMANAGER_MODAL_MOVE_TO_FOLDER);

  const { pathname } = useLocation();

  const {
    setActiveFolder,
    setPreviewableFile,
    previewableFile,
    activeFolder,
  } = useFileManagerContext();

  const explorerIsOpen = useSelector((state) => modalSelectors.isModalOpen(state, explorerModalName));

  const { store } = base;

  const openMoveModal = () => openModal();

  const handleExplorerRowClick = async (row: FileFolder) => {
    ApplicationMonitoring.trackEvent(
      row?.trackEventProps?.name || pathname,
      row?.trackEventProps?.value || 'Explorer row',
      row?.trackEventProps?.label || row?.name
    );
    if (row.type === FileFolderType.DIRECTORY) {
      const res = await store.rowClick(row);
      setPreviewableFile(undefined);
      setActiveFolder(row);
      return res;
    }
    setPreviewableFile(row);
    return store.data;
  };

  const refreshActiveFolder = () => {
    setPreviewableFile(undefined);
    store.fetchSubFolders(activeFolder, true);
  };

  const handleFileDelete = (file: FileFolder) => deleteFileFolder(file.reference).then(() => {
    refreshActiveFolder();
  });

  const handleActiveFolderDelete = () => {
    const { parent } = activeFolder;
    deleteFileFolder(activeFolder.reference).then(() => {
      if (parent) {
        handleExplorerRowClick(parent);
      } else {
        store.initializeStore(true);
      }
    });
  };

  useEffect(() => {
    if (!explorerIsOpen) {
      setPreviewableFile(undefined);
      setActiveFolder(undefined);
    }
  }, [explorerIsOpen]);

  return {
    ...base,
    activeFolder,
    previewableFile,
    openMoveModal,
    handleFileDelete,
    setPreviewableFile,
    refreshActiveFolder,
    handleExplorerRowClick,
    handleActiveFolderDelete,
  };
};

export type MoveFolderFileManagerProps = {
  movableFile: FileFolder;
  onFileMoved?: () => void;
};
export const useMoveFolderFileManager = ({ movableFile, onFileMoved }: MoveFolderFileManagerProps) => {
  const base = useBaseFileManager({ modalName: FILEMANAGER_MODAL_MOVE_TO_FOLDER });
  const [destinationFolder, setDestinationFolder] = useState<FileFolder>();
  const { closeModal } = useModal(FILEMANAGER_MODAL_MOVE_TO_FOLDER);

  const { store, setMissingData } = base;

  const handleRowExpanding = (row: FileFolder) => store.rowClick(row, false);
  const handleHighlightFolder = (row: FileFolder) => {
    setDestinationFolder(row);
    if (row) {
      setMissingData(undefined);
    }
  };

  const handleMoveFile = addProgressBar(async () => {
    if (!destinationFolder) {
      setMissingData(MissingData.TARGET_DIRECTORY);
    } else {
      const res: SubmitResult<{ existingFileFromPath: string }> = await fetchData({
        url: `${config.apiEndpoints.cms}/dam/${destinationFolder.reference}`,
        method: 'POST',
        body: {
          ExistingFileFromPath: movableFile.reference,
        },
      });
      if (res.success) {
        closeModal();
        onFileMoved?.();
      } else {
        toastError(res.error.errors.existingFileFromPath[0]);
      }
    }
  });

  return {
    ...base,
    destinationFolder,
    handleMoveFile,
    handleRowExpanding,
    handleHighlightFolder,
  };
};

export const useUploaderFileManager = ({
  onDropFiles,
  onDestinationSelected,
  onClearMissingData,
}: Omit<UploaderFileManagerProps, 'onDeleteFile'>) => {
  const { store } = useBaseFileManager({});

  const handleRowExpanding = (row: FileFolder) => store.rowClick(row, false);
  const { setActiveFolder } = useFileManagerContext();

  const handleHighlightFolder = (row: FileFolder) => {
    onDestinationSelected(row);
    setActiveFolder(row);
    if (row) {
      onClearMissingData(undefined);
    }
  };

  const handleDropFiles = (files: File[]) => {
    // Show error toast if user selects or drops in more than one file
    if (files.length > 1) {
      toastError('modules.contentmanagement.multipleFilesError');

      return;
    }

    onDropFiles(files.map((file) => renameFile(file, removeSpaceAndSpecialCharacter(file.name))));

    if (files.length) {
      onClearMissingData(undefined);
    }
  };

  return {
    store,
    handleDropFiles,
    handleRowExpanding,
    handleHighlightFolder,
  };
};

export const useRootFileManager = ({ mode, explorerModalName = FILEMANAGER_MODAL }: RootFileManagerProps) => {
  const [displayMode, setDisplayMode] = useState(mode);
  const [missingData, setMissingData] = useState<MissingData>();
  const [destinationFolder, setDestinationFolder] = useState<FileFolder>();
  const [uploadableFiles, setUploadableFiles] = useState<File[]>([]);

  const rootModalIsOpen = useSelector((state) => modalSelectors.isModalOpen(state, explorerModalName));

  const { closeModal } = useModal(explorerModalName);

  const handleUploadFile = addProgressBar(
    async (overwrite: boolean = false, newFileName?: string): Promise<ServerFile | any | undefined> => {
      if (!uploadableFiles.length) {
        setMissingData(MissingData.UPLOAD_FILE);
        return Promise.resolve(undefined);
      }

      if (!destinationFolder) {
        setMissingData(MissingData.TARGET_DIRECTORY);
        return Promise.resolve(undefined);
      }

      const formData: any = new FormData();
      formData.append('file', uploadableFiles[0]);

      return fileUpload<ServerFile>({
        url: `${config.apiEndpoints.cms}/dam/${destinationFolder.reference}`,
        method: 'POST',
        query: {
          overwrite,
          ...(newFileName && { fileName: newFileName }),
        },
        body: formData,
      }).then((res: SubmitResult<{}>) => {
        if (res.success) {
          toastSuccess('modules.contentmanagement.fileUploaded');

          // change id used on image urls to force a reload of all images
          // if the image uploaded is replacing one with the same name
          if (overwrite) localImgId.id = Uuid();
        } else {
          const errors: string | string[] | AppError<{}> = Object.values(res.error.errors).filter(
            (e) => e.toString().length !== 0,
          );

          if (Array.isArray(errors)) {
            errors.forEach((e: string) => toastError(e));
          } else if (typeof errors === 'string') {
            toastError(errors);
          }
        }

        return res?.error?.errors && Object.values(res?.error?.errors).length > 0 ? res?.error?.errors : res.data;
      });
    },
  );

  const handleDeleteFile = (file: File) => {
    setUploadableFiles((prev) => prev.filter((f) => f.name !== file.name));
  };

  const handleClearMissingData = () => {
    if (missingData === MissingData.TARGET_DIRECTORY && !!destinationFolder) {
      setMissingData(undefined);
    }
    if (missingData === MissingData.UPLOAD_FILE && !!uploadableFiles.length) {
      setMissingData(undefined);
    }
  };

  useEffect(() => {
    if (displayMode === ExplorerMode.UPLOAD) {
      setMissingData(undefined);
    }
  }, [displayMode]);

  useEffect(() => {
    setDisplayMode(mode);
    if (!rootModalIsOpen) {
      setMissingData(undefined);
      setDestinationFolder(undefined);
      setUploadableFiles([]);
    }
  }, [rootModalIsOpen]);

  return {
    displayMode,
    missingData,
    rootModalIsOpen,
    uploadableFiles,
    setDisplayMode,
    handleUploadFile,
    handleDeleteFile,
    closeRootModal: closeModal,
    setDestinationFolder,
    setUploadableFiles,
    handleClearMissingData,
  };
};
