import { IMessage } from 'framework/components/ui/Message';
import { IForm, SubmitResult } from 'framework/components/ui/FormComponents/Form/Form.interface';
import { SelectMenuPlacements } from 'framework/components/ui/FormComponents/Select/interfaces';
import { TagsDivider } from '../../components/ui/FormComponents/TagsField/TagsField.interfaces';
import { Validator } from 'redux-form';
import { LayoutType } from './common/LayoutBuilder/LayoutType/Interfaces';
import { LayoutTextAlignment, LayoutTextType } from './common/LayoutText/interfaces';

export type CmsLayoutType =
  | 'Page'
  | 'Row'
  | 'Image'
  | 'HeadingsAndText'
  | 'HeadingsandTextLimit'
  | 'Carousel'
  | 'CallToAction'
  | 'RetailerTemplate'
  | 'Template'
  | 'LayoutExtraLargeBanner1'
  | 'LayoutExtraLargeBanner2'
  | 'LargePromotion'
  | 'SmallPromotion'
  | 'Recommendation'
  | 'StackedContentBlock'
  | 'StandardContentBlock1'
  | 'StandardContentBlock2'
  | 'StandardContentBlock3'
  | 'ImageTextButton'
  | 'TextButton'
  | 'Placeholder'
  | 'Video'
  | 'HeroBanner'
  | 'Freeform'
  | 'VideoWithContent'
  | 'Recipe'
  | 'ProductCardStory'
  | 'ProductListing'
  | 'TreeBanner'
  | 'TwoProductCard'
  | 'CarouselSmall'
  | 'DocumentPlaceholder'
  | 'Circular'
  | 'NextAvailableTimeSlot'
  | 'WelcomeTimeSlotItem'
  | 'NextTimeSlotItem'
  | 'ReservedTimeSlotItem'
  | 'SoldOutTimeSlotItem';

export type SubSet<T, U extends T> = U;
export type CarouselBasedContainer = SubSet<
  CmsLayoutType,
  'TreeBanner' | 'TwoProductCard' | 'CarouselSmall' | 'NextAvailableTimeSlot'
>;
export type CmsBlockElementType =
  | 'Text'
  | 'Number'
  | 'Image'
  | 'CarouselContent'
  | 'RadioGroup'
  | 'TextArea'
  | 'Link'
  | 'Promotion'
  | 'Recommendation'
  | 'VideoUrl'
  | 'Freeform'
  | 'Recipe'
  | 'ProductListing'
  | 'Select'
  | 'DocumentPlaceholder'
  | 'Tags'
  | 'Switch';

export type CmsTemplateType = 'TextBlockImage';

export interface CmsLayout {
  type: CmsLayoutType;
}

export type CmsLayoutInpectorDefinition = {
  [K in CmsLayoutType]: {
    name: string;
    title?: any;
    description?: string;
    inspector: CmsLayoutInspectorConfig;
    editor?: React.StatelessComponent<Partial<DefaultBuilderProps>>;
    wellKnown?: boolean;
  };
};

export interface CmsLayoutInspectorItem {
  element: CmsBlockElementType;
  displayName: string;
  description?: string;
}

export interface CmsLayoutInspectorConfig {
  [name: string]: CmsLayoutInspectorItem;
}

export interface CmsBlockElementProps {
  property: CmsInspectorProp;
  inspector?: CmsLayoutInspector;
}

export type CmsElementMappings = {
  [k in CmsBlockElementType]: React.FunctionComponent<Partial<CmsBlockElementProps>>;
};

export interface LayoutBuilderProps {
  pageContent: CmsPageContent;
  activeRowIndex: number;
  columnIndex: number;
  visible?: boolean;
  onSave?: (saving: boolean, success?: boolean) => void;
  getLayoutInspector: (name: CmsLayoutType, reference: CmsLayoutType) => CmsLayoutInspector;
}

export interface CmsLayoutEditorsActions {
  addCmsLayout: (type: CmsLayoutType, rowIndex: number) => void;
  closeLayoutSelect: (modal: string) => void;
  saveCmsLayout: (props: { rowIndex: number; columnIndex: number; type: CmsLayoutType }) => void;
}

export interface DefaultBuilderProps {
  sharedMetadata?: SharedMetadata;
  rowOrColumn?: CmsPageRow | CmsPageColumn;
  children?: any;
  formInitialData: any;
  inspector: CmsLayoutInspector;
  fullwidth?: boolean;
  hideFooter?: boolean;
  embedded?: boolean;
  maxColumns?: number;
  isSubContainer?: boolean;
  subContainerType?: CmsLayoutType;
  largeTimeout?: string | number;
  mediumTimeout?: string | number;
  singleComponent?: boolean;
  addLayout?: (type: CmsLayoutType) => void;
  onSubmit?: (type: CmsLayoutType) => void;
  onSubmitHandler?: (values: unknown, props: IForm<unknown>) => SubmitResult<unknown> | Promise<SubmitResult<unknown>>;
  getLayoutInspector: (name: CmsLayoutType, reference: CmsLayoutType) => CmsLayoutInspector;
}

export enum FixedColumId {
  Cart = 'fixed_cart',
}

export enum OpenInType {
  SameTab = '_self',
  NewTab = '_blank',
}

export interface CmsPageColumn extends CmsLayout {
  data: any;
  description?: string;
  items?: CmsPageColumn[]; // if column is carousel
  componentName?: CmsLayoutType;
  id?: FixedColumId;
  title?: string;
  displayPromotionsCarousel?: false;
  componentKey?: string;
  metadata?: SharedMetadata;
  largeTimeout?: string | number;
  mediumTimeout?: string | number;
}

export enum LayoutWidthType {
  contentWidth = 'content-width',
  browserWidth = 'browser-width',
}

export type referrer = {
  documentId: string;
  documentReference: string;
  variantId: string;
  variantName: string;
  path?: string;
};

export type SharedMetadata = {
  documentReference?: string;
  documentId?: string;
  variantId?: string;
  variantName?: string;
  variantStatus?: string;
  directReferencersCount?: number;
  totalReferencersCount?: number;
  indirectReferencersCount?: number;
  referrers?: referrer[];
};

export interface CmsPageRow {
  implicit?: boolean;
  columns: CmsPageColumn[];
  type: 'Row' | 'DocumentPlaceholder' | CmsLayoutType;
  width: LayoutWidthType;
  metadata?: SharedMetadata;
  largeTimeout?: string | number;
  mediumTimeout?: string | number;
  componentKey?: string;
  items?: CmsPageColumn[];
  singleComponent?: boolean;
  settings?: {
    mobileLayout?: LayoutType;
    titleType: LayoutTextType;
    titleText: string;
    titleTextAlignment: LayoutTextAlignment;
  };
}
export interface CmsPageContent extends CmsLayout {
  type: 'Page';
  title?: string;
  settings?: CmsPageSettings;
  rows: CmsPageRow[];
}

export interface CmsPageSettings {
  secondaryTitle?: string;
  allowDoNotShow?: boolean;
  doNotShowText?: string;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  secondaryButtonOpenMode?: string;
}

export interface CmsDeletedSharedComponent {
  type: 'DocumentPlaceholder';
}

export interface CmsLayoutTemplates {
  [name: string]: CmsLayoutInspector;
}

export type CmsLayoutInspectorCategory = 'standard' | 'base' | 'advanced';
export type LayoutSelectionType = 'row' | 'carousel' | 'fullwidth' | 'nonrow-selectable' | 'normal' | 'carouselSelect';
export type LayoutSelectGrouping = 'containers' | 'largeComponents' | 'smallComponents' | 'basicElements';

export interface CmsLayoutInspector {
  /**
   * name of layiout: this string is validated in backend and must be known by storefront team
   */
  name: CmsLayoutType;

  /**
   * string title of layout
   */
  title?: string;
  description: string;
  message?: IMessage;
  custom?: boolean;
  wellknown?: boolean;
  /**
   * @deprecated Not used anymore, should be tracked and removed from code base
   */
  category?: CmsLayoutInspectorCategory;
  selection?: LayoutSelectionType[];
  group?: LayoutSelectGrouping;
  props?: CmsInspectorProperties;
  requiredSlides?: number;
  disableSharedComponentOption?: boolean;
}

export type CmsInspectorProperties = CmsInspectorProp[];

export interface CmsInspectorProp {
  name: string;
  isDivider?: boolean;
  blockElementType?: CmsBlockElementType;
  layoutRef?: CmsLayoutType;
  title?: string;
  subtitle?: string;
  displayName?: string;
  description?: string;
  embedded?: boolean;
  optional?: boolean;
  validate?: Validator[];
  radioItems?: {
    [name: string]: {
      caption?: string;
      value?: any;
      default?: boolean;
    };
  };
  selectOptions?: {
    items: {
      value: any;
      label: string;
    }[];
    menuPlacement?: SelectMenuPlacements;
  };
  min?: number;
  max?: number;
  defaultNumber?: number;
  divider?: TagsDivider | TagsDivider[];
  defaultValue?: string;
  explainer?: string;
  content?: CmsInspectorProp[];
}
