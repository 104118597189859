import { connect } from 'react-redux';
import { ApiRequestProvider as ApiRequestProviderComponent } from './useApiRequest.Provider';
import { ApiRequestContextProps, ApiRequestProps } from './useApiRequest.interfaces';
import { actions } from 'framework/api/apiClient';

const mapState = state => {
  return {
    apiClientData: state.apiClientData || {}
  };
};

const mapDispatch = (dispatch): Partial<ApiRequestContextProps> => {
  return {
    makeApiCall: (props: ApiRequestProps) =>
      dispatch(actions.createApiClientMakeRequestAction(props as any))
  };
};

export const ApiRequestProvider = connect(
  mapState,
  mapDispatch
)(ApiRequestProviderComponent);
