import * as React from 'react';
import { Loader } from '../Loader';

type LazyPropsType = React.LazyExoticComponent<any>;
// Propeties specific to <Lazy />
type LazyProps<T extends LazyPropsType> = {
  Component: T;
  Fallback?: React.ReactNode;
};
// Join properties of <Lazy /> and the passed <Component />
type LazyAllWithComponentProps<T extends LazyPropsType> = LazyProps<T> & React.ComponentPropsWithRef<T>;

const Lazy = <T extends LazyPropsType>({ Component, Fallback, ...props }: LazyAllWithComponentProps<T>) => (
  <React.Suspense fallback={Fallback || <Loader />}>
    <Component {...props} />
  </React.Suspense>
);

export { Lazy };
