import { IconNames } from 'framework/components/ui/Icon';

export type TagProps = {
  caption: string;
  i18nOptions?: {
    [propToReplace: string]: string | number;
  },
  value: TagValue;
  icon?: IconNames;
  control?: React.FC<any>;
  className?: string;
};

// see Status indicators (zeplin: https://app.zeplin.io/project/5abba2082ad5c9a98d9f7f1a/screen/5abce7767e1fb5dea2157af2)
// enum values match with "$status-indicator-background-colors" variable (see _variables.scss)
// this is a work-in-progress: new statuses will be added here as long as they exist as "status indicator" in the styleguide
export enum TagValue {
  active = 'active',
  inactive = 'inactive',
  partially = 'partially-picked',
  warning = 'warning',
  picking = 'picking',
  draft = 'draft',
  processing = 'processing',
  placed = 'placed',
  substituted = 'substituted',
  error = 'rejected',
  info = 'info',
  Cancelled = 'cancelled',
  unknown = 'unknown',
  expired = 'expired',
  scheduled = 'scheduled',
  ordered = 'ordered',
  'ready-for-picking' = 'ready-for-picking',
  'picking-some-waves-complete' = 'partially-picked',
  completed = 'completed',
  published = 'published',
  unpublished = 'unpublished',
  immediate = 'immediate',
}
