import { call, select, debounce, put, all } from 'redux-saga/effects';
import * as CONST from 'framework/modules/searchadmin/constants';
import { apiClientActions } from 'framework/actions';
import { withProgressBar } from 'framework/sagas/extensions/progress';
import { config } from 'config';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { formSelectors } from 'framework/selectors';
import { objectHelper } from 'framework/utils';
import { toastError } from 'stores/toast';
import { rulePaginationStore } from 'framework/modules/searchadmin/components/DataGridDouble/DataGridDouble';
import { processRulesDataForPreview } from './helpers';

function* isValidSearch() {
  const formData = yield select(formSelectors.getFormFieldValues, CONST.FORM_RULES_CREATE_RULES);
  const isValid = yield select(formSelectors.getIsFormValid, CONST.FORM_RULES_CREATE_RULES);

  return isValid && !!formData.searchForProducts;
}
function* getCommomRequestParts(payload) {
  const { data = {} } = payload;

  const { take = 20, currentPage = 1 } = data;
  const formData = yield select(formSelectors.getFormFieldValues, CONST.FORM_RULES_CREATE_RULES);
  const localeId = CONST.DEFAULT_LOCALE_ID;
  const storeId = formData.previewStoreId || CONST.DEFAULT_STORE_ID;
  const skip = take * (currentPage - 1);

  return {
    url: `${config.apiEndpoints.search}/rules/locale/${localeId}/store/${storeId}/products`,
    body: {
      skip: rulePaginationStore?.pagination?.skip ?? skip,
      take: rulePaginationStore?.pagination?.take ?? take,
      q: formData.searchForProducts,
    },
  };
}

function* getCommomRequestPartsWithPreview(payload) {
  const formData = yield select(formSelectors.getFormValues, CONST.FORM_RULES_CREATE_RULES);
  const configurationOverride = processRulesDataForPreview(formData);
  const commonRequest = yield call(getCommomRequestParts, payload);

  return {
    url: commonRequest.url,
    body: {
      ...commonRequest.body,
      configurationOverride,
    },
  };
}

export function* fetchCurrentProducts(payload) {
  if (yield call(isValidSearch)) {
    const request = yield call(getCommomRequestParts, payload);

    // Make Save Request
    yield call(makeRequestAndSaveState, {
      name: CONST.API_DATA_RULE_CURRENT_DATA,
      method: 'POST',
      url: request.url,
      body: request.body,
    });
  } else {
    yield put(
      apiClientActions.clearApiClientStateAction({
        name: CONST.API_DATA_RULE_CURRENT_DATA,
      })
    );
  }
}

export function* fetchPreviewProducts(payload) {
  if (yield call(isValidSearch)) {
    const request = yield call(getCommomRequestPartsWithPreview, payload);
    // Make Save Request
    const { success, error = {} } = yield call(makeRequestAndSaveState, {
      name: CONST.API_DATA_RULE_PREVIEW_DATA,
      method: 'POST',
      url: request.url,
      body: request.body,
    });

    if (!success) {
      const { errors }: { errors: { value: string | string[] } } = error;

      if (!objectHelper.isEmpty(errors ?? {})) {
        Object.values(errors).forEach((value) => {
          toastError(value);
        });
      }
    }
  } else {
    yield put(
      apiClientActions.clearApiClientStateAction({
        name: CONST.API_DATA_RULE_PREVIEW_DATA,
      })
    );
  }
}

/**
 * Fetches both Current and Preview products based on a new rule
 */
export function* fetchProducts({ payload }) {
  yield all([call(fetchCurrentProducts, payload), call(fetchPreviewProducts, payload)]);
}

/**
 * Fetches both Current and Preview products based on a new rule.
 * With a debounce of 500ms to wait until the user finishes typing the search term
 */
export function* watchFetchProducts() {
  yield debounce(500, CONST.ACTION_RULES_PREVIEW_FETCH_CURRENT_DATA, withProgressBar(fetchProducts));
}
