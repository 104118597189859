import classnames from 'classnames';
import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { prefixClassName } from '../../_conf';
import { IMenu } from './Menu.interfaces';
import { MenuButton } from './MenuButton';
import { MenuOption } from './MenuOption';
import '../_styles.scss';

const clx = prefixClassName('account-menu');

export const Menu = (props: IMenu) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    className,
    optionsData,
    options = [],
    onToggle,
    buttonText,
    icon,
  } = props;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    onToggle?.(!isOpen);
  };
  const onOutsideClickHandler = () => {
    setIsOpen(false);
  };

  const handleClick = (fn: (e?: any) => void) => {
    if (optionsData) {
      fn(optionsData);
    } else {
      fn();
    }

    toggleOpen();
  };

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClickHandler} disabled={!isOpen}>
      <div
        className={classnames(
          clx,
          isOpen && `${clx}--open`,
          className,
        )}
      >
        <MenuButton
          className={`${clx}__button`}
          onClick={toggleOpen}
          active={isOpen}
          text={buttonText}
          icon={icon}
        />
        {isOpen && (
          <ul className={`${clx}__optionsList`}>
            {options.map((option, key) => (
              <MenuOption
                key={key}
                label={option.label}
                linkTo={option.linkTo}
                index={key}
                onClick={() => !option.linkTo && handleClick(option.onClick)}
                className={classnames(`${clx}__option`, option.className)}
              />
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Menu;
