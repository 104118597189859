import * as classnames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text } from 'framework/components/ui/Text';
import * as icons from './Icon.icons';
import { IIconProps } from './Icon.interfaces';
import './_style.scss';

const clx = prefixClassName('icon');

class Icon extends React.PureComponent<IIconProps> {
  static defaultProps: IIconProps = {
    name: 'cart',
  };

  render() {
    const {
      name, className, color, onClick, rotate, size, title, viewBox,
    } = this.props;

    const icon = name && icons[name];
    const iconPaths: any = (icon?.path) || '';
    const iconViewBox = viewBox ?? ((icon?.viewBox) || '');
    const iconTransform = (icon && (icon as any).transform) || '';

    const styles: any = {
      fill: color || null,
      transform: rotate ? `rotate(${rotate}deg)` : null,
    };

    return icon ? (
      <svg
        onClick={onClick}
        viewBox={iconViewBox}
        style={styles}
        className={classnames(clx, name && `${clx}--${name}`, size && `${clx}--${size}`, className)}
      >
        <g transform={iconTransform}>
          {title && <Text caption={title} tag="title" />}
          {iconPaths.map((pathProps, i) => (
            <path {...pathProps} key={i} />
          ))}
        </g>
      </svg>
    ) : null;
  }
}

export default Icon;
