import * as React from 'react';
import classNames from 'classnames';
// eslint-disable-next-line import/no-cycle
import { TABLE_DEFAULT_NAME } from './TableDefaults';
import { CellRenderer, HiddenColumns, ITable, ITableHeader, ObjectMetadata } from './Table.interfaces';
import { TBodyProps } from './Table';

export interface SeparateTableInterface<T> extends ITable<T> {
  metadata: ObjectMetadata<T>[];
  updateRowPosition: (attributeId: string, newIndex: number) => void;
  filteredData: T[];
  TBody: ({ onDragEnd, children }: TBodyProps) => JSX.Element;
  TableRow: ({ children }: { children: any; }) => JSX.Element;
  isEmpty: boolean;
  TableHeader?: (props: ITableHeader) => JSX.Element;
  cellRenderer?: CellRenderer | (<T extends {}>(tableName: string,
    data: T,
    metadata: ObjectMetadata<{}>[],
    rowIndex: number,
    hiddenColumns?: HiddenColumns[],
    originalWord?: string,
    replacementWord?: string,
  ) => JSX.Element);
  tableClassName: string;
}

const SingleTable = <T extends {}>(props: SeparateTableInterface<T>) => {
  const { noBorder, tableName, autoid, data, headerRenderer, draggableId, onDragEnd,
    emptyText, ifEmpty, cellRenderer, originalWord, replacementWord, metadata, tableClassName,
    updateRowPosition, filteredData, TBody, TableRow, hiddenColumns, className, isEmpty, TableHeader } = props;

  return (<table
    className={classNames(tableClassName, className, noBorder && `${tableClassName}--no-border`)}
    id={tableName}
    data-autoid={autoid}
  >
    <TableHeader
      data={data}
      tableName={tableName || TABLE_DEFAULT_NAME}
      metadata={metadata}
      hiddenColumns={hiddenColumns}
      cellRenderer={headerRenderer}
    />
    <TBody onDragEnd={draggableId ? updateRowPosition : onDragEnd}>
      {isEmpty && ifEmpty(metadata, emptyText)}
      {!isEmpty &&
        [...(filteredData || data)].map((singleRow: any, i: number) => (
          <TableRow key={i}>
            {cellRenderer(tableName || TABLE_DEFAULT_NAME, singleRow, metadata, i,
              hiddenColumns, originalWord, replacementWord)}
          </TableRow>
        ))}
    </TBody>
  </table>);
};

export default SingleTable;
export { SingleTable };
