import { take, fork, put } from 'redux-saga/effects';
import * as constants from '../contentemanagement.constants';
import * as cmsActions from '../actions';
import { actions as apiActions } from 'framework/api/apiClient';

import * as _get from 'lodash.get';

const forever = true;
export default function* sagaCmsPageHierarchy() {
  while (forever) {
    const action = yield take([
      constants.ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY,
      apiActions.API_CLIENT_UPDATE_STATE
    ]);
    yield fork(process, action);
  }
}

function* process(action) {
  switch (action.type) {
    case constants.ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY:
      {
        yield loadPagesHierarchy();
      }
      break;
    case apiActions.API_CLIENT_UPDATE_STATE:
      {
        if (action.payload.name === constants.ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY) {
          yield storePageHierarchy(action);
        }

        if (action.payload.name === constants.CMS_ORG_STORE_HIERARCHY) {
          if (!action.payload.isFetching && !action.payload.hasError) {
            yield put(
              apiActions.clearApiClientStateAction({
                name: constants.CMS_ORG_STORE_HIERARCHY
              })
            );
          }
        }
      }
      break;
  }
}

function* loadPagesHierarchy() {
  yield put(
    apiActions.createApiClientMakeRequestAction({
      name: constants.ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY,
      endpoint: constants.PATH_CMS_ENDPOINT,
      endpointPath: '/folders/pages',
      includeResponseDetails: true
    })
  );
}

function* storePageHierarchy(action) {
  if (!action.payload.isFetching && !action.payload.hasError) {
    const cmsPagesHierarchy = _get(action.payload.data, 'children', []);
    yield put(
      cmsActions.storePagesHierarchy({
        cmsPagesHierarchy
      })
    );
    yield put(
      apiActions.clearApiClientStateAction({
        name: constants.ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY
      })
    );
  }
}
