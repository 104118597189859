import { TrackEventProps } from 'framework/applicationMonitoring/interface';
import { IKebabOption } from 'framework/components/ui/Kebab/KebabOption';
import { ImageSize } from '../common/CmsBlockElements/CmsBlockImageElement/CmsBlockImageSizes.Components';

export enum FileFolderType {
  DIRECTORY = 'directory',
  FILE = 'file'
}

export enum ExplorerMode {
  EXPLORE = 'explore',
  UPLOAD = 'upload'
}

export type FileExtension = 'jpg' | 'png' | 'svg' | 'txt' | 'pdf' | 'gif';

export type ServerFile = {
  url: string;
  name: string;
  type: FileFolderType;
  reference: string;
};

export type FileFolder = Omit<ServerFile, 'url'> & {
  url?: string;
  level?: number;
  parent?: FileFolder;
  displayEmpty?: boolean;
  children?: FileFolder[];
  trackEventProps?: TrackEventProps;
};

export type FileFormValues = {
  [size in ImageSize]: ServerFile;
};

export type UploadableFile = {
  propertyName: string;
  size?: ImageSize,
};

export type FileManagerLauncherProps = {
  link?: boolean;
  mode?: ExplorerMode;
  onOpen?: () => void;
};

type ExplorerModalNameProps = {
  explorerModalName?: string;
};

export type FileManagerProps = ExplorerModalNameProps & {
  mode?: ExplorerMode;
  fileSelectable?: boolean;
  onDismissModal?: () => void;
  onFilesUploaded?: (files: ServerFile[]) => void;
  onFileSelected?: (file: FileFolder) => void;
  trackEventProps?: TrackEventProps;
  requiredFilesFormat?: string[];
  confirmationModalName?: string;
};

export type RootFileManagerProps = ExplorerModalNameProps & {
  mode?: ExplorerMode};

export type ExplorerFileManagerProps = ExplorerModalNameProps;
export type BaseFileManagerProps = ExplorerModalNameProps;

export type InternalFileManagerProps = {
  mode?: ExplorerMode
} & Pick<FileManagerProps, 'onFileSelected'>;

export enum MissingData {
  UPLOAD_FILE = 'UPLOAD_FILE',
  TARGET_DIRECTORY = 'TARGET_DIRECTORY',
  PARENT_DIRECTORY = 'PARENT_DIRECTORY',
}

export type UploaderFileManagerProps = {
  uploadableFiles: File[];
  onClearMissingData: (missingData: MissingData) => void;
  onDropFiles: (files: File[]) => void;
  onDestinationSelected: (destination: FileFolder) => void;
  onDeleteFile: (file: File) => void;
};

export type KebabAction = Pick<IKebabOption, 'label' | 'onClick'>;
