import { connect } from 'react-redux';
import { formActions, modalActions } from 'framework/actions';
import { shoppingModeType } from 'framework/components/ui/Calendar/components/EventWrapper/EventWrapper';
import { toSentenceCase } from 'framework/utils/string';
import Modal from './ModalEditSlot';
import { Props, Actions } from './ModalEditSlot.interfaces';
import { actions, selectors } from '../../store';
import * as CONST from '../../constants';

const mapStateToProps = (state): Props => {
  const selectedEvent: any = selectors.getEventSelectedData(state);
  const sm = shoppingModeType[selectedEvent?.shoppingModeId];
  const shoppingModeId = sm && toSentenceCase(sm);
  const initialValues = !selectedEvent
    ? {}
    : {
      [CONST.FIELD_EDIT_SLOT_TIME_INI]: selectedEvent.startDate,
      [CONST.FIELD_EDIT_SLOT_TIME_END]: selectedEvent.endDate,
      [CONST.FIELD_EDIT_SLOT_CAPACITY]: selectedEvent.capacity,
      [CONST.FIELD_EDIT_SLOT_CAPACITY_AVAILABLE]: selectedEvent.availableCapacity,
      [CONST.FIELD_EDIT_SLOT_ORDERS]: selectedEvent.orders,
      [CONST.FIELD_EDIT_SLOT_PRICE]: selectedEvent.price,
      [CONST.FIELD_EDIT_SLOT_CUTOFF]: selectedEvent.cutoffTime,
      [CONST.FIELD_EDIT_SLOT_PICK_BY_TIME]: selectedEvent.pickByTime,
      [CONST.FIELD_EDIT_SLOT_TAGS]: selectedEvent.tags,
      [CONST.FIELD_EDIT_SLOT_SHOPPINGID]: shoppingModeId,
      [CONST.FIELD_EDIT_SLOT_FULFILLMENT]: selectedEvent.fulfilmentType,
    };

  return {
    isOpen: !selectedEvent.isClosed,
    isExpired: selectedEvent.isExpired,
    hasOrders: true,
    formIsEditMode: !selectedEvent.isExpired,
    initialValues,
    fulfilmentType: selectedEvent.fulfilmentType,
  };
};

const mapDispatchToProps = (dispatch): Actions => ({
  saveSlot: () => dispatch(actions.updateCalendarSlots()),
  closeSlot: () => {
    // Switch modals
    dispatch(modalActions.createCloseModalAction(CONST.MODAL_EDIT_SLOT));
    dispatch(modalActions.createOpenModalAction(CONST.MODAL_CLOSE_SLOT));
  },
  resetSlot: () => dispatch(formActions.reset(CONST.FORM_EDIT_SLOT)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
