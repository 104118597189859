import * as moment from 'moment';
import { INotificationLine } from './Menu/Menu.interfaces';

export const DETAILS_MODAL = 'detailsModal';

export const ALL_NOTIFICATIONS_MODAL = 'allNotificationsModal';

export const NOTIFICATIONS_LOCAL_STORAGE_NAME = 'myNotifications';

export const testNotification: INotificationLine = {
  id: '123',
  type: { name: 'notification', icon: 'bell' },
  createdAt: moment().add(2, 'hours').toDate(),
  impact: 'maintenance',
  name: 'Upcoming scheduled maintenance',
  description: 'We will be executing some infrastructure updates to components of the Wynshop solution in your production environment on 3:00pm, October 25, 2023.',
  scheduledFor: moment().add(3, 'hours').toDate(),
  scheduledUntil: moment().add(4, 'hours').toDate(),
  status: 'in_progress',
  components: [
    { id: '222', description: '', name: 'Admin Panel (Production)', status: 'under_maintenance' },
    { id: '223', description: '', name: 'WPS (Production)', status: 'under_maintenance' }
  ],
  seen: false,
};

export const testNotification2: INotificationLine = {
  id: '1234',
  type: { name: 'notification', icon: 'bell' },
  createdAt: moment().add(4, 'hours').toDate(),
  impact: 'maintenance',
  name: 'Upcoming scheduled maintenance (2)',
  description: 'We will be executing some infrastructure updates to components of the Wynshop solution in your production environment on 3:00pm, October 25, 2023.',
  scheduledFor: moment().add(5, 'hours').toDate(),
  scheduledUntil: moment().add(7, 'hours').toDate(),
  status: 'in_progress',
  components: [{ id: '333', description: '', name: 'MPS (Production)', status: 'under_maintenance' }],
  seen: false,
};

// Notification incident types
export const ALL = 'All';

export const UNRESOLVED = 'Unresolved';

export const SCHEDULED = 'Scheduled';

export const NONE = 'None';
