import { prefixClassName } from '../../../../components/ui/_conf';
import * as React from 'react';
import { Button, Form, Select } from 'ui';
import { liveCalendarBulkActionStore } from '../../../slotManagement/pages/LiveTimeSlotView/pageParts/content';

const clx = prefixClassName('');
const BULK_ACTIONS = 'BULK_ACTIONS';
interface IProps {
  options: BulkAction[]
  selected: any[]
  onClick: (action: string) => void
  disabled?: boolean
}
interface BulkAction {
  label: string
  value: string
  disabled?: boolean
}

export const BulkActions = (props: IProps) => {
  const {
    options, selected, onClick, disabled = false,
  } = props;
  const [selectedBulkAction, setSelectedBulkAction] = React.useState<BulkAction>();

  React.useEffect(() => {
    if(!liveCalendarBulkActionStore.action){
      setSelectedBulkAction(null);
    }
  },[liveCalendarBulkActionStore.action])

  return (<div className={`${clx}actions-bar`}>
    <Form
      className={
        Object.keys(selected)?.length < 1  
          ? `${clx}bulk-actions-form--disabled` 
          : `${clx}bulk-actions-form--enabled`}
      name={BULK_ACTIONS}
      initialValues={Object.keys(selected)?.length < 1 ? null : {bulk_action: selectedBulkAction} }
    >
      <Select
        disabled={selected.length < 1 || disabled}
        className={`${clx}location-services__bulk-actions-select`}
        placeholder="generic.selectActionDefault"
        name="bulk_action"
        onChange={(opt) => {
          setSelectedBulkAction(opt);
        }}
        options={options}
      />
      <Button
        disabled={
          (selected.length < 1)
          || !selectedBulkAction
          || disabled
          || selectedBulkAction?.disabled === true
        }
        className={` ${clx}bulk-actions-button`}
        outline
        caption="generic.button.confirm"
        onClick={() => { onClick(selectedBulkAction?.value); }}
      />
    </Form>
  </div>);  
}