import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims, ensureClaims } from 'framework/authorization';
import { OrderTranslations } from './translations';
import {
  deliverySolutionsFailedSubmissionsRoute, ordersListRoute, PATH_MAIN,
  fulfillmentFailedSubmissionsRoute, zetesFailedSubmissionsRoute,
} from './constants';

const OrderManagementModule: ModuleConfig = {
  name: 'orderManagement',
  appMenuTitle: OrderTranslations.OrderManagement,
  appMenuIcon: 'clipboardCheck',
  modulePath: PATH_MAIN,

  pageGroups: async () => {
    const hasFailedSubmissionClaims = ensureClaims(AuthorizationClaims.ORDERMANAGEMENT_FAILEDSUBMISSIONS);

    return [
      {
        title: OrderTranslations.Orders,
        routes: [
          ordersListRoute,
        ],
      },
      ...hasFailedSubmissionClaims ? [{
        title: OrderTranslations.FailedSubmissions,
        routes: [
          deliverySolutionsFailedSubmissionsRoute,
          fulfillmentFailedSubmissionsRoute,
          zetesFailedSubmissionsRoute,
        ],
      }]: []
    ];
  },
};

export default OrderManagementModule;
