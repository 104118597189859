import * as React from 'react';
import classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IHeader } from './Header.interfaces';
import './_style.scss';
import Text from '../Text';

const clx = prefixClassName('header');

export const Header = (props: IHeader) => {
  const {
    caption,
    className,
    headerLevel = 6,
    id,
    captionDetails,
    orientation = 'horizontal',
    optional = false,
  } = props;
  const Tag: any = `h${headerLevel}`;

  return (
    <Tag id={id} className={classnames(clx, className, `${clx}__${orientation}`)}>
      {caption}
      {captionDetails && (
        <>
          {': '}
          <span className={classnames(`${clx}__details`, className && `${className}__details`)}>{captionDetails}</span>
        </>
      )}
      {optional && <Text className={`${clx}__optional`} caption="components.form.optionalValue" tag="span" />}
    </Tag>
  );
};

export default Header;
