export const SITEADMIN_STORE_USER = 'SITEADMIN_STORE_USER';
export const SITEADMIN_SAVE_USER = 'SITEADMIN_SAVE_USER';
export const USER_DETAILS_FORM = 'USER_DETAILS_FORM';
export const USER_ROLES_FORM = 'USER_ROLES_FORM';
export const USER_STORES_FORM = 'USER_STORES_FORM';
export const SITEADMIN_LOAD_USER = 'SITEADMIN_LOAD_USER';
export const SITEADMIN_ASSIGN_ROLES_TO_USER = 'SITEADMIN_ASSIGN_ROLES_TO_USER';
export const STITEADMIN_FETCH_ROLES = 'STITEADMIN_FETCH_ROLES';
export const SITEADMIN_NEXT_TABSTEP = 'SITEADMIN_NEXT_TABSTEP';
export const SITEADMIN_CLEAR_USER = 'SITEADMIN_CLEAR_USER';
export const SITEADMIN_STORE_PAGEFLOW = 'SITEADMIN_STORE_PAGEFLOW';
export const SITEADMIN_USER_TABS = 'SITEADMIN_USER_TABS';
export const SITEADMIN_USER_TABS_DETAILS = 'details';
export const SITEADMIN_USER_TABS_STORES = 'assign-stores';
export const SITEADMIN_USER_TABS_ROLES = 'assign-roles';
export const SITEADMIN_STATUS = 'Status';
export const SITEADMIN_CHANGE_USER_STATUS = 'SITEADMIN_CHANGE_USER_STATUS';
