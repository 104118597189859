import * as React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import { ButtonList } from 'framework/components/ui';
import { IPanelFooter } from './Panel.interfaces';
// eslint-disable-next-line import/no-cycle
import { clx as parentClx } from './index';

const PanelFooter = (props: IPanelFooter) => {
  const {
    children, options, className, sticky = true,
  } = props;
  const [isOnView, setIsOnView] = React.useState(true);
  const clx = `${parentClx}__footer`;

  const inVisibilityChangeHandler = isVisible => {
    setIsOnView(isVisible);
  };

  return children || options ? (
    <>
      <div
        className={classNames(
          clx,
          sticky && `${clx}--sticky`,
          isOnView ? `${clx}--onView` : `${clx}--outOfView`,
          className,
        )}
      >
        <div className={`${clx}__content`}>{children}</div>
        {options && <ButtonList className={`${clx}__butons`} buttons={options} />}
      </div>
      <VisibilitySensor onChange={inVisibilityChangeHandler} scrollDelay={0} intervalDelay={0}>
        <div className={`${clx}__visibility-sensor`} />
      </VisibilitySensor>
    </>
  ) : null;
};

export { PanelFooter };
