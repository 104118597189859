import * as React from 'react';
import userManager from 'framework/utils/userManager';
import { Message } from 'ui';
import { config } from 'config';
import App from 'framework/components/app/';
import { withErrorCatcher } from 'framework/components/hoc/withErrorCatcher';
import { resetSessionStorage } from '../../stores/features';

interface RedirectParams {
  state: string;
  redirect_uri: string
}
interface State {
  authenticated: boolean;
  signinError: boolean;
  signinRedirect: boolean;
}

class Authorization extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      signinError: false,
      signinRedirect: false,
    };
  }

  async componentDidMount() {
    const user = await this.isAuthenticated();

    if (user) {
      const { FS } = window;
      if (user.expired) {
        resetSessionStorage();
      }
      if (FS) {
        FS.identify(user.profile.sub, {
          displayName: `${user.profile.name}`,
          email: user.profile.email,
        });
      }
      this.setState({
        authenticated: true,
      });
    } else {
      this.setState({
        signinRedirect: true,
      });

      const redirectParams = {
        state: `${window.location.pathname}${window.location.search}`,
      } as RedirectParams;

      // If the search string contains internal_login=true, it means that the identity service
      // login screen should be used over an identity provider's login page.
      // The value is respected on the server side.
      if (window.location.search.indexOf('internal_login=true') !== -1) {
        redirectParams.redirect_uri = `${config.identityserver.redirect_uri}?internal_login=true`;
      }

      userManager.signinRedirect(redirectParams).catch(() => {
        this.setState({
          signinRedirect: false,
          signinError: true,
        });
      });
    }
  }

  isAuthenticated = async () => {
    const user = await userManager.getUser();
    return user;
  };

  render() {
    const {
      authenticated,
      signinRedirect,
      signinError,
    } = this.state;
    if (authenticated) {
      return <App />;
    }
    if (signinRedirect) {
      resetSessionStorage();
      return <Message type="info" caption="authorizationRedirect" key="info" />;
    }
    if (signinError) {
      return <Message type="error" caption="authorizationError" key="error" />;
    }
    return null;
  }
}

export default withErrorCatcher(Authorization);
