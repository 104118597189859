import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { TrackEventProps } from 'framework/applicationMonitoring/interface';
import { useWindow } from 'framework/utils/useWindow';
import * as React from 'react';
import { NumericFieldPrimitive } from 'ui';
import { i18n } from 'i18n';
import { ISelectionField } from './Buttons.types';

interface Props extends ISelectionField {
  numberOfPages: number;
  trackEventProps?: TrackEventProps;
  readOnly?: boolean;
}

export const PageSelectionField = (props: Props) => {
  const {
    className,
    currentPage,
    disabled = false,
    numberOfPages,
    trackEventProps,
    readOnly = false,
    onChange,
  } = props;

  const [page, setPage] = React.useState(currentPage || 1);

  const {
    location: { pathname },
  } = useWindow();

  React.useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const getTargetValue = (event: any) => parseInt(event.target.value, 10);

  const inRange = (value: number) => value > 0 && value <= numberOfPages;

  const goToPage = (value: number) => {
    if (Number.isNaN(value) || !inRange(value)) {
      setPage(currentPage);
    } else if (value !== currentPage) {
      ApplicationMonitoring.trackEvent(
        trackEventProps?.name || pathname,
        trackEventProps?.value || 'Pagination page',
        trackEventProps?.label || (value as unknown as string)
      );
      onChange(value);
    }
  };

  const handleBlur = (event: FocusEvent) => goToPage(getTargetValue(event));

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;
    const value = getTargetValue(event);
    if (key === 'Enter') {
      goToPage(value);
    }
  };

  return (
    <NumericFieldPrimitive
      className={readOnly ? 'mrg-5' : `${className}__page-selection-input`}
      disabled={disabled}
      min={1}
      max={numberOfPages}
      onBlur={handleBlur}
      onChange={(e) => {
        setPage(getTargetValue(e));
      }}
      onKeyDown={handleKeyDown}
      value={page}
      readOnly={readOnly}
      ariaLabel={i18n.t('generic.ariaLabel.pageNumber')}
    />
  );
};

export default PageSelectionField;
