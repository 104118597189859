import * as React from 'react';
import classNames from 'classnames';
import * as moment from 'moment-timezone';
import DatePicker, { registerLocale } from 'react-datepicker';
import { localeStore, onLocaleChange } from 'stores/locale';
import { DATE_FORMAT } from 'config';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Icon } from 'ui';
import { appState } from 'stores/app';
import { ReadOnlyField } from '..';
import { IDatePickerPrimitive, IDatePickerPrimitivePrivate, popperPlacement } from './DatepickerPrimitive.interface';
import './react-datepicker.scss';
import './_styles.scss';
import { isDate, areDatesEqual } from './helpers';

const clx = prefixClassName('datepicker-primitive');
const clxField = prefixClassName('form-field');

// // initializes the locale data and handles when it changes
onLocaleChange(() => {
  registerLocale(localeStore.shortLocaleId, localeStore.dateLocale);
});

// moment and react-datepicker are internally using different formats,
// it is not possible to share between them
const DP_DATE_FORMAT = 'MMM d, yyyy';
const DP_DATE_FORMAT_CALENDAR = 'MMM, yyyy';
const DP_TIME_FORMAT = 'HH:mm';
const PAYLOAD_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const DatePickerPrimitive = (props: IDatePickerPrimitive & IDatePickerPrimitivePrivate) => {
  const {
    clearable = true,
    className,
    id,
    placeholder,
    value,
    overWriteValue,
    showTimepicker = true,
    showTimeSelectOnly = false,
    selectsRange = false,
    inline = false,
    disabled = false,
    readOnly = false,
    calendarDirection = 'bottom',
    formats = {},

    // Properties used on range-selection
    startDate,
    endDate,
    minDate = undefined,
    maxDate = undefined,
    isStartSelector = false,
    isEndSelector = false,

    timeZone,
    ignoreLocalTimeZone = false,
    convertFormatInput = false,

    // Actions
    onChange = () => null,
    onClear = () => null,
    onFocus = () => null,
    // onBlur = () => null,
  } = props;

  const { dateTimeFormat, convertedTimeFormat } = appState.userSettings;

  const datePickerRef = React.useRef(null);

  const isClearable = clearable && !disabled && !!value;
  const icon = showTimeSelectOnly ? 'clock' : 'calendar';

  const formatDate = (val: Date | string) => {
    if (!val) {
      return null;
    }

    const date = isDate(val) ? val : new Date(val);
    const momentDate = timeZone ? moment(date).tz(timeZone).tz(moment.tz.guess(), true) : moment(date);
    if (formats.input) {
      // Custom format provided
      if (convertFormatInput) {
        return momentDate.format(formats.input.toUpperCase());
      }
      return momentDate.format(formats.input);
    }
    if (showTimeSelectOnly) {
      return momentDate.format(convertedTimeFormat);
    }
    if (showTimepicker) {
      return momentDate.format(dateTimeFormat);
    }
    return momentDate.format(DATE_FORMAT);
  };

  const getInputFormat = () => {
    if (formats.input) {
      // Custom format provided
      return formats.input;
    }
    if (showTimeSelectOnly) {
      // HH:mm or h:mm A
      return convertedTimeFormat;
    }
    if (showTimepicker) {
      // HH:mm or h:mm A - MMMM d, yyyy
      // HH:mm as default if convertedTimeFormat is undefined
      const DP_DATETIME_FORMAT = `${convertedTimeFormat ?? DP_TIME_FORMAT} - ${DP_DATE_FORMAT}`;

      return DP_DATETIME_FORMAT;
    }
    // MMMM d, yyyy
    return DP_DATE_FORMAT;
  };

  const ph = placeholder || showTimeSelectOnly ? '_ _ : _ _' : formatDate(new Date());
  const format = getInputFormat();

  const getDateValue = (date = value) => {
    if (!date) {
      return null;
    }

    let d: Date;
    if (timeZone) {
      // will display a timezoned date as local date, without changing the time
      d = moment(date).tz(timeZone).tz(moment.tz.guess(), true).toDate();
    } else {
      d = new Date(!ignoreLocalTimeZone ? date : moment(date).utc().format(PAYLOAD_TIME_FORMAT));
    }

    return d;
  };

  const rangeTime = {
    min: undefined,
    max: undefined,
  };

  // Format possible string values to be dates
  const [selectedValue, setSelectedValue] = React.useState<Date>(getDateValue());
  const startValue = getDateValue(startDate);
  const endValue = getDateValue(endDate);

  const handleChange = (date: Date | Date[]) => {
    if (Array.isArray(date) && selectsRange) {
      // This is used when selectsRange prop is true
      // startDate, endDate, parse, overWriteValue props will also need to be provided
      const [start, end] = date;

      let newStartDate: Date | string = null;
      let newEndDate: Date | string = null;

      if (timeZone) {
        newStartDate = moment(start).tz(timeZone, true).format();
        if (end) newEndDate = moment(end).tz(timeZone, true).format();
      } else {
        newStartDate = new Date(start);
        if (end) newEndDate = new Date(end);
      }

      if (newStartDate instanceof Date) {
        setSelectedValue(newStartDate);
      } else {
        setSelectedValue(new Date(!ignoreLocalTimeZone ? start : moment(newStartDate).format(PAYLOAD_TIME_FORMAT)));
      }

      onChange({ newStartDate, newEndDate });
    }
    if (!Array.isArray(date)) {
      // if picker is cleared, set value to null
      if (!date) {
        setSelectedValue(null);
        onChange(null);
        return;
      }
      let newDate: Date | string;
      if (timeZone) {
        newDate = moment(date).tz(timeZone, true).format();
      } else {
        newDate = new Date(date);
      }

      onChange(!ignoreLocalTimeZone ? newDate : moment(new Date(newDate).toString()).format('YYYY-MM-DDTHH:mm:ss[Z]'));

      if (newDate instanceof Date) {
        setSelectedValue(newDate);
      } else {
        setSelectedValue(new Date(!ignoreLocalTimeZone ? date : moment(newDate).format(PAYLOAD_TIME_FORMAT)));
      }
    }
  };

  const clearDate = () => {
    onClear();
    handleChange(null);
  };

  // Set min and max times for range scenarios
  if (isStartSelector && areDatesEqual(endValue, startValue)) {
    rangeTime.min = new Date().setHours(0, 0, 0, 0);
    rangeTime.max = endValue;
  } else if (isEndSelector && areDatesEqual(endValue, startValue)) {
    rangeTime.min = startValue;
    rangeTime.max = new Date().setHours(23, 59, 0, 0);
  }

  React.useEffect(() => {
    if (selectsRange) setSelectedValue(getDateValue(value));
  }, [value]);

  return readOnly ? (
    <ReadOnlyField value={formatDate(value)} placeholder={ph} icon="calendar" />
  ) : (
    <div
      className={classNames(
        clx,
        !value && `${clx}--isEmpty`,
        showTimepicker && `${clx}--hasTimepicker`,
        disabled && `${clx}--disabled`,
        readOnly && `${clx}--readOnly`,
        className
      )}
    >
      <div className={clxField}>
        <DatePicker
          locale={localeStore.shortLocaleId}
          dateFormat={format}
          // Used on the calendar header
          dateFormatCalendar={formats.header || DP_DATE_FORMAT_CALENDAR}
          timeFormat={convertedTimeFormat}
          selected={selectedValue}
          // onBlur={onBlur}
          onFocus={onFocus}
          value={overWriteValue}
          disabled={disabled}
          onChange={handleChange}
          showTimeSelect={showTimepicker}
          showTimeSelectOnly={showTimeSelectOnly}
          placeholderText={ph}
          popperPlacement={popperPlacement[calendarDirection]}
          selectsStart={isStartSelector}
          selectsEnd={isEndSelector}
          startDate={startValue}
          minDate={minDate ?? (isEndSelector && startValue)}
          endDate={endValue}
          maxDate={maxDate ?? (isStartSelector && endValue)}
          minTime={rangeTime.min}
          maxTime={rangeTime.max}
          inline={inline}
          {...(selectsRange && {
            onClickOutside: () => {
              datePickerRef?.current?.setOpen(false);
            },
            openToDate: getDateValue(value),
            disabledKeyboardNavigation: true,
            selectsRange: true,
          })}
          ref={datePickerRef}
          id={id}
        />
      </div>
      <Icon name={icon} className={`${clx}__btn--dateSelector`} />
      {isClearable && <Icon name="del" className={`${clx}__btn--close`} onClick={clearDate} />}
    </div>
  );
};

export default DatePickerPrimitive;
