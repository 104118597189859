import { safeGet } from 'framework/utils';
import {
  SET_OPEN_TAB,
  SET_OPEN_SUBTAB,
  REMOVE_TABS,
  TABS_REGISTER_ANCHOR,
  TABS_UNREGISTER_ANCHOR,
} from './Tabs.types';

const initialState = {};

const tabGroups = (state: any = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_TAB: {
      return {
        ...state,
        [action.payload.tabGroupId]: {
          ...(action.payload.keepAnchors ? state[action.payload.tabGroupId] : {}),
          openTab: action.payload.tabId,
          openSubTab: null,
        },
      };
    }
    case SET_OPEN_SUBTAB: {
      return {
        ...state,
        [action.payload.tabGroupId]: {
          ...state[action.payload.tabGroupId],
          openTab: state[action.payload.tabGroupId].openTab,
          openSubTab: action.payload.subTabId,
        },
      };
    }
    case REMOVE_TABS: {
      const newState = { ...state };
      delete newState[action.payload.tabGroupId];
      return newState;
    }
    case TABS_REGISTER_ANCHOR: {
      const { anchorName, tabGroupId } = action.payload;
      return {
        ...state,
        [tabGroupId]: {
          ...state[tabGroupId],
          registeredAnchors: {
            ...safeGet(state, `${tabGroupId}.registeredAnchors`, {}),
            [anchorName]: true,
          },
        },
      };
    }
    case TABS_UNREGISTER_ANCHOR: {
      const { anchorName, tabGroupId } = action.payload;
      const newState = { ...state };

      if (safeGet(newState, `${tabGroupId}.registeredAnchors`)) {
        delete newState[tabGroupId].registeredAnchors[anchorName];
      }
      return {
        ...newState,
      };
    }
    default: {
      return state;
    }
  }
};

export default tabGroups;
