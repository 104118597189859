import * as React from 'react';
import Icon from 'framework/components/ui/Icon';

interface Props {
  className: string;
  opened?: boolean;
  onClick: (e?: any) => void;
}

export const CollapsibleButton = (props: Props) => {
  const { className, opened } = props;

  return (
    <span className={className} onClick={props.onClick}>
      <Icon name="dropdownArrowRight" rotate={!opened ? 0 : 90} className={`${className}__icon`} />
    </span>
  );
};
