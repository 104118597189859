import * as React from 'react';
import classnames from 'classnames';
import i18n from 'i18n';
import LinkPrimitive from 'framework/components/ui/Primitives/LinkPrimitive';
import { IKebabOption } from '../Kebab.interfaces';
import './_style.scss';
import { renderLabel } from '../Utils';

export class KebabSingleOption extends React.PureComponent<IKebabOption> {
  static defaultProps: Partial<IKebabOption> = {
    disabled: false,
  };

  onClickHandler = (e?: unknown) => {
    const { disabled, onClick } = this.props;

    if (!disabled && onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      hidden, linkTo, onClick, className, label, helperText, disabled, index,
    } = this.props;
    const infoText = helperText || (typeof label === 'string' && i18n.t(label));
    const isHidden = !!(hidden || !(linkTo || onClick));

    const spanClick = {
      onClick: this.onClickHandler,
    };

    return !isHidden ? (
      <div
        role="button"
        title={infoText}
        tabIndex={index}
        aria-label={infoText}
        {...spanClick}
        className={classnames(className, disabled && `${className}--disabled`)}
      >
        {linkTo ? (
          <LinkPrimitive to={linkTo} className={`${className}__link`}>
            {renderLabel(label)}
          </LinkPrimitive>
        ) : (
          renderLabel(label)
        )}
      </div>
    ) : null;
  }
}

export default KebabSingleOption;
