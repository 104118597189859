import * as React from 'react';
import * as classnames from 'classnames';
import { IconNames } from 'framework/components/ui/Icon';
import { IformField } from 'framework/components/hoc/formField';
import { TextFieldProps } from 'framework/components/ui/FormComponents/TextField';
import { prefixClassName } from '../../../_conf';
import { FilterField } from '../../..';

const clx = prefixClassName('filter-bar__input');

interface InputProps extends TextFieldProps, IformField {
  className?: string;
  onFilter?: (text: string) => void;
  icon?: IconNames;
  iconBg?: 'default' | 'main';
}

const Input = ({
  className,
  onFilter,
  icon,
  iconBg,
  ...props
}: InputProps) => (
  <div className={classnames(clx, className)}>
    <FilterField
      onFilter={onFilter}
      icon={icon}
      iconBg={iconBg}
      {...props}
    />
  </div>
);

export { Input };
