import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { ProductTranslations } from './translations';
import { productsListRoute, ROOT } from './constants';

const ProductsModule: ModuleConfig = {
  name: 'products',
  appMenuTitle: ProductTranslations.ProductManagement,
  appMenuIcon: 'product',
  modulePath: ROOT,
  pageGroups: async () => { 
    return [
      {
        title: ProductTranslations.Products,
        routes:  [productsListRoute],
      }
    ];
  },
};

export default ProductsModule;
