export const FORM_NAME = 'ProductEdit';
export const PRICE_FORM_NAME = 'ProductPrice';
export const RULES_EVALUATION_MODAL = 'RulesEvaluationModal';

export const INFORMATION_TAB = 'information';

export const PRICING_TAB = 'pricing';

export const CUSTOMIZATIONS_TAB = 'customizations';

export const EDIT_OPTIONS_SEQUENCE_MODAL = 'EditOptionsSequenceModal';
