import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IHorizontalScrollButtonsProps } from './HoriztonalScrollButtons.interface';
import './_style.scss';
import { Button } from '../FormComponents/Button';

const clx = prefixClassName('scroll');

const HorizontalScrollButtons = (props: IHorizontalScrollButtonsProps) => {
  const { scrollContainerRef, leftTableWidth, rightTableWidth, emptyTable } = props;

  const [scrollY, setScrollY] = React.useState(0);
  const [scrollX, setScrollX] = React.useState(0);
  const [showButtons, setShowButtons] = React.useState(true);
  const [leftButton, setLeftButton] = React.useState(true);
  const [rightButton, setRightButton] = React.useState(true);
  const [buttonClass, setButtonClass] = React.useState<string>();
  const [horizontalScrollWidth, setHorizontalScrollWidth] = React.useState(0);
  const [isMouseDown, setIsMouseDown] = React.useState<{ down: boolean; scroll: number; side: 'left' | 'right' }>();

  const calculateHorizontalScrollWidth = () => {
    const container = scrollContainerRef.current;
    const totalContentWidth = container.scrollWidth;
    const visibleWidth = container.clientWidth;
    const scrollWidth = totalContentWidth - visibleWidth;
    setHorizontalScrollWidth(scrollWidth);
  };

  const scroll = (value) => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollLeft += value;
    }
  };

  const handleContainerScroll = () => {
    setScrollX(scrollContainerRef.current.scrollLeft);
  };

  const calculateVisibleArea = () => {
    const divElement = scrollContainerRef?.current;
    const divRect = divElement?.getBoundingClientRect();
    const viewportHeight = window?.innerHeight;

    // Calculate the visible height of the div on the screen
    const visibleHeight = Math.min(divRect?.bottom, viewportHeight) - Math.max(divRect?.top, 0);

    const totalHeight = divRect?.height;
    const percentageVisible = (visibleHeight / totalHeight) * 100;

    return { visibleHeight, percentageVisible };
  };

  const handleWindowScroll = () => {
    const { visibleHeight, percentageVisible } = calculateVisibleArea();

    const visible: number = Math.floor(visibleHeight);

    setScrollY(visible / 2);

    if (percentageVisible === 100) {
      setButtonClass(null);
      return;
    }

    if (
      visible === window.innerHeight ||
      visible >= window.innerHeight ||
      (window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight - 100 &&
        Math.floor(window.scrollY) >= (document.body.clientHeight - window.innerHeight) / 2)
    ) {
      setButtonClass('fixed');
    } else {
      setButtonClass(null);
    }
  };

  const { visibleHeight } = calculateVisibleArea();

  React.useEffect(() => {
    const container = scrollContainerRef.current;
    calculateHorizontalScrollWidth();
    window.addEventListener('resize', calculateHorizontalScrollWidth);
    container.addEventListener('scroll', handleContainerScroll);
    window.addEventListener('scroll', handleWindowScroll);
    window.addEventListener('resize', handleContainerScroll);

    return () => {
      container.removeEventListener('scroll', handleContainerScroll);
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleContainerScroll);
      window.removeEventListener('resize', calculateHorizontalScrollWidth);
    };
  }, []);

  React.useEffect(() => {
    const { visibleHeight: vHeight } = calculateVisibleArea();

    // Hide buttons when user scrolls to the top
    const calc = scrollY > 0 ? scrollY > 70 : vHeight > 70;
    setShowButtons(calc);
    setLeftButton(scrollX > 1);
    setRightButton(scrollX === 0 ? true : scrollX < horizontalScrollWidth - 2);
  }, [scrollY, scrollX]);

  React.useEffect(() => {
    let interval: NodeJS.Timer;

    if (isMouseDown?.down) {
      interval = setInterval(() => scroll(isMouseDown.scroll), 300);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isMouseDown]);

  React.useEffect(() => {
    // Handle situation when buttons hide after scrolling all the way right or left
    // isMouseDown needs to reset to null when this happens
    if ((isMouseDown?.side === 'left' && !leftButton) || (isMouseDown?.side === 'right' && !rightButton)) {
      setIsMouseDown(null);
    }
  }, [leftButton, rightButton]);

  return (
    <div className={showButtons && !emptyTable ? `${clx}--isVisible` : clx}>
      {leftButton && (
        <Button
          className={`${clx}-button-left`}
          onMouseDown={() => setIsMouseDown({ down: true, scroll: -225, side: 'left' })}
          onMouseUp={() => setIsMouseDown(null)}
          onMouseOut={() => setIsMouseDown(null)}
          onClick={() => scroll(-225)}
          icon="dropdownArrowLeft"
          iconSize="lg"
          style={{
            paddingRight: '5px',
            left: buttonClass ? 118 + leftTableWidth : leftTableWidth - 24,
            top: `${scrollY > 0 ? scrollY : visibleHeight / 2}px`,
            ...(buttonClass && { position: 'fixed' }),
          }}
        />
      )}
      {rightButton && (
        <Button
          className={`${clx}-button-right`}
          onMouseDown={() => setIsMouseDown({ down: true, scroll: 225, side: 'right' })}
          onMouseUp={() => setIsMouseDown(null)}
          onMouseOut={() => setIsMouseDown(null)}
          onClick={() => scroll(225)}
          icon="dropdownArrowRight"
          iconSize="lg"
          style={{
            right: buttonClass ? rightTableWidth - 4 : rightTableWidth + 40,
            top: `${scrollY > 0 ? scrollY : visibleHeight / 2}px`,
            ...(buttonClass && { position: 'fixed' }),
          }}
        />
      )}
    </div>
  );
};

export default HorizontalScrollButtons;
