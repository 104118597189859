import * as React from 'react';
import { Modal, Table } from 'ui';
import { RULES_CONFLICT_MODAL } from 'framework/modules/searchadmin/constants';
import { SearchRule } from '../../../../Rules/models/searchRule';
import { ruleConflictMetaData } from './ruleConflictTableConfig';

interface Props {
  conflictingRules: SearchRule[];
  saveRule: ({ conflict: boolean }) => void;
  handleCancelSave: () => void;
}

export const RuleConflictModal = (props: Props) => {
  const { conflictingRules, saveRule, handleCancelSave } = props;

  return (<Modal
    name={RULES_CONFLICT_MODAL}
    caption="modules.merchandising.rules.createAndEdit.searchConflictTitle"
    onDismiss={handleCancelSave}
    size="large"
    messages={[{ type: 'warning', caption: 'modules.merchandising.rules.createAndEdit.searchConflictWarning' }]}
    footerOptions={[
      {
        caption: 'modules.merchandising.rules.createAndEdit.activateRule',
        onClick: () => saveRule({ conflict: true }),
      },
      {
        caption: 'modules.merchandising.rules.createAndEdit.doNotActivateRule',
        outline: true,
        onClick: handleCancelSave,
      },
    ]}
  >
    <Table data={conflictingRules} columnMetadata={ruleConflictMetaData} />
  </Modal>);
};

export default RuleConflictModal;
