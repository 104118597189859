import { useDispatch } from 'framework/utils/useDispatch';
import store from 'framework/store';
import { change } from 'redux-form';
import { createSetFormErrorsAction, reset, submit, setValue, initializeValues } from './Form.actions';
import { FormErrorObject } from './Form.interface';
import { getRawValues } from './service';
import { getIsFormDirty, getIsFormValid, getIsFormFieldDirty, getIsFormFieldPristine } from './Form.selectors';

export const useForm = (formName: string) => {
  const dispatch = useDispatch();
  return {
    setErrors: (errors: FormErrorObject) => dispatch(createSetFormErrorsAction({ name: formName, errors })),

    setValue: (field: string, value: any) => dispatch(setValue(formName, field, value)),
    /**
     * Changes a field value programatically
     */
    changeValue: (field: string, newValue: any,
      touch?: boolean, persistentSubmitErrors?: boolean) => dispatch(change(formName, field,
      newValue, touch, persistentSubmitErrors)),
    /**
     * Gets all form fields values
     */
    getValues: <T>(): T => getRawValues(store.getState().form[formName]?.values || {}) as T,

    /**
     * Gets value from one form field
     */
    getFieldValue: <T>(field: string): T => getRawValues(store.getState().form[formName]?.values || {})[field] as T,

    /**
     * Initialize form with passed values
     */
    initializeValues: (values: any) => dispatch(initializeValues(formName, values)),

    /**
     * reset form flags
     */
    reset: () => dispatch(reset(formName)),

    /**
     * validate and submit form values
     */
    submit: () => dispatch(submit(formName)),

    getIsFormValid: () => getIsFormValid(store.getState(), formName),

    getIsFormDirty: () => getIsFormDirty(store.getState(), formName),

    /**
     * check if form field is pristine or dirty
     */
    getIsFormFieldPristine: (fieldName: string) => getIsFormFieldPristine(store.getState(), formName, fieldName),
    getIsFormFieldDirty: (fieldName: string) => getIsFormFieldDirty(store.getState(), formName, fieldName)
  };
};
