export default (
  state,
  payload: {
    calendarName: string;
  }
) => {
  const { calendarName } = payload;

  return {
    ...state,
    [calendarName]: {
      ...state[calendarName],
      isLoading: false,
      events: {},
      eventsOriginal: {},
      eventsDeleted: []
    }
  };
};
