import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { HOMEURL, goToRoute } from 'framework/components/navigation/service';
import * as CONST from './constants';

export const homeRoute: AppRoute = {
  pageTitle: '',
  path: HOMEURL,
  authClaim: '',
  Component: () => import('./pages/Dashboard'),
};

export const accountRoute: AppRoute = {
  pageTitle: 'modules.myAccount.title',
  path: CONST.myAccountPath,
  authClaim: '',
  Component: () => import('./pages/myAccount'),
};
export const goToAccountRoute = () => goToRoute(accountRoute);

export const homeRoutes: AppRoute[] = [
  homeRoute,
  accountRoute,
];
