import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { PATH_CRM } from './constants';
import { CRMTranslations } from './translations';
import { customersListRoute } from './routes';

const CRMModule: ModuleConfig = {
  name: 'crm',
  appMenuTitle: CRMTranslations.CRM,
  appMenuIcon: 'thumbUp',
  modulePath: PATH_CRM,
  pageGroups: [
    {
      title: CRMTranslations.Customers,
      routes: [
        customersListRoute,
      ],
    },
  ],
};

export default CRMModule;
