import * as moment from 'moment-timezone';
import { appState } from 'stores/app';

export const datesAreEqual = (date1: Date | string, date2: Date | string) => moment(date1).isSame(date2);

export const compareDates = (date1, date2, strict = false) => {
  // todo: Better error management here!!
  const isDate1Valid = moment(date1).isValid();
  const isDate2Valid = moment(date2).isValid();

  if (!isDate1Valid || !isDate2Valid) {
    return undefined;
  }

  if (!strict) {
    return moment(date1).isSameOrBefore(date2);
  }
  return moment(date1).isBefore(date2);
};

export const checkOverlappingDateRanges = (
  rangeOne: { start: Date | string, end: Date | string },
  rangeTwo: { start: Date | string, end: Date | string }
): boolean => {
  return moment(rangeOne.start).isSameOrBefore(rangeTwo.end) && moment(rangeOne.end).isSameOrAfter(rangeTwo.start);
};

export const formatTime = (
  time: string,
  defaultValue: string = '--',
): string => {
  const { convertedTimeFormat } = appState.userSettings;

  // return original string if it is not in time format
  if (!time?.includes('.') && !time?.includes(':')) {
    return time;
  }

  return (time ? moment(time, 'HH:mm:ss').format(convertedTimeFormat) : defaultValue);
};

export const formatDate = (
  date: Date | string | moment.MomentInput,
  format?: string,
  defaultValue: string = '--',
  ignoreLocalTimeZone: boolean = false,
  useUTCFormat: boolean = false,
  utcToLocal: boolean = false,
): string => {
  const { dateFormat } = appState.userSettings;
  const userFormat: string = format ?? dateFormat;

  if (!date) {
    return defaultValue;
  }

  if (ignoreLocalTimeZone) {
    return moment.parseZone(date).format(userFormat);
  }

  if (useUTCFormat) {
    return moment.utc(date).format(format ?? dateFormat);
  }

  if (utcToLocal) {
    return moment.utc(date).local().format(format ?? dateFormat);
  }

  return (date ? moment(date).format(format ?? dateFormat) : defaultValue);
};

export const formatDateTime = (
  date: Date | string,
  format?: string,
  defaultValue: string = '--',
  timeZone?: string,
  ignoreLocalTimeZone: boolean = false,
  removeDate: boolean = false,
  utcToLocal: boolean = false,
): string => {
  const { dateTimeFormat, convertedTimeFormat } = appState.userSettings;

  if (!date) {
    return defaultValue;
  }

  let userFormat: string = format ?? dateTimeFormat;

  if (removeDate) {
    userFormat = convertedTimeFormat;
  }

  if (ignoreLocalTimeZone) {
    return moment.parseZone(date).format(userFormat);
  }

  if (utcToLocal) {
    return moment.utc(date).local().format(userFormat);
  }

  if (date) {
    return timeZone ? moment(date).tz(timeZone).format(userFormat) : moment(date).format(userFormat);
  }
  return defaultValue;
};

export const formatDateTimeSlot = (
  startDate: Date | string,
  endDate: Date | string,
  format?: string,
  defaultValue: string = '--',
  timeZone?: string,
): string => {
  const { convertedTimeFormat, timeSlotDateTimeFormat } = appState.userSettings;

  const userFormat: string = format ?? timeSlotDateTimeFormat;

  if (startDate && endDate) {
    return timeZone
      ? `${moment(startDate).tz(timeZone).format(convertedTimeFormat)} - ${moment(endDate).tz(timeZone).format(userFormat)}`
      : `${moment.parseZone(startDate).format(convertedTimeFormat)} - ${moment.parseZone(endDate).format(userFormat)}`;
  }
  return defaultValue;
};

export const getWeekDay = (value: string | Date) => {
  const d = new Date(value);
  return d.getDay();
};

export const checkIfValueIsDate = (value):boolean => {
  if (typeof value === 'number') return false;
  const date = moment(value, true);
  return date.isValid();
};

/**
 * returns the date in the YYYY-MM-DD format
 * @param date
 */
export const formatDateISO = (date: Date | string | moment.MomentInput): string => formatDate(date, 'YYYY-MM-DD');

/**
 * returns the date in the YYYY-MM-DDTHH:mm format
 * @param date
 */
export const formatDateTimeISO = (
  date: Date | string | moment.MomentInput, useUtc?: boolean, utcToLocal?: boolean,
): string => (
  formatDate(date, 'YYYY-MM-DDTHH:mm', null, null, useUtc, utcToLocal)
);

export const addDays = (date: Date | string, daysToAdd: number) => moment(date).add(daysToAdd, 'days');

// Get the days difference between two dates, works across different months
export const dateDiffInDays = (dateOne: Date, dateTwo: Date) => {
  const msPerDay = 1000 * 60 * 60 * 24;

  const dateOneUtc = Date.UTC(dateOne?.getFullYear(), dateOne?.getMonth(), dateOne?.getDate());
  const dateTwoUtc = Date.UTC(dateTwo?.getFullYear(), dateTwo?.getMonth(), dateTwo?.getDate());

  return Math.floor((dateTwoUtc - dateOneUtc) / msPerDay);
};