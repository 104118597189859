const dev = 'dev';
// use testDev flag when a feature requires an external env for testing
const testDev = 'testDev';
const support = 'support';
const prod = 'prod';
const urlParam = 'flag';

const INTERNAL_ENV = 'admin.mi9.';

type FeatureFlagFunction = () => any;

interface EnvFlags {
  [dev]: FeatureFlagFunction;
  [testDev]: FeatureFlagFunction;
  [support]: FeatureFlagFunction;
  [prod]: FeatureFlagFunction;
}

interface CustomFlags {
  [customFlag: string]: FeatureFlagFunction;
}

const envFlag = process.env.NODE_ENV === 'production' ? prod : dev;

/**
 * REACT_APP_LOCAL_ENV_FLAG:
 * This key is set in a .env file and allows a developer to branch out code based on a .env key
 * e.g REACT_APP_LOCAL_ENV_FLAG=local
 * featureFlag({
 *  local: () => {...},
 *  ...
 * })
 */
const localEnvFlag = process.env.REACT_APP_LOCAL_ENV_FLAG;

const getCurrentFlag = () => {
  const urlFlag = URLSearchParams && new URLSearchParams(window.location.search).get(urlParam);
  return urlFlag || localEnvFlag;
};

/**
 * Branches the code execution according to the provided feature flag
 * @param map the branches of code to be choosen (i.e.: dev, prod, customFlag)
 * @param forceFlag forces the excution of a branch, independent of the current flag
 * @param internalOnly only allow using flag in internal environments
 */
const featureFlag = (map: EnvFlags | CustomFlags, internalOnly = false, forceFlag = getCurrentFlag()) => {
  // return undefined if provided flag is dev or internalOnly is true, not in internal environment, and not in dev env
  if (envFlag !== dev && (forceFlag === dev || internalOnly) && !window?.location?.hostname?.includes(INTERNAL_ENV)) {
    return undefined;
  }

  // tries to find the branch for the provided flag
  if (forceFlag && map[forceFlag]) {
    return map[forceFlag]();
  }

  // falls back to the env flags
  if (map[envFlag]) {
    return map[envFlag]();
  }

  // mapping for the current flag wasn't provided
  return undefined;
};

interface FeatureFlagProps {
  /**
   * "dev" or "prod" or any custom feature flag value
   */
  when: string | string[];
  children: any;
}

/**
 * Branches the code in jsx
 */
const FeatureFlag = ({ when, children }: FeatureFlagProps) => {
  const flag = getCurrentFlag();
  const parsedWhen: string[] = Array.isArray(when) ? when : [when];

  // isValid is false when none of the flags in the `when` prop matches the current flag
  // and if any of the flags is dev and not in an internal environment
  const isValid =
    (!(parsedWhen.includes(dev) && !window?.location?.hostname?.includes(INTERNAL_ENV)) &&
      parsedWhen.some((w) => w === flag || w === envFlag)) ||
    // Show when in dev environment and no flag is used or flag is dev
    (envFlag === dev && (!flag || flag === dev));

  return isValid && children;
};

export { featureFlag, FeatureFlag, urlParam };
