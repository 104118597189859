import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import './_styles.scss';

const clx = prefixClassName('stencil__filter-bar');

const Masker = () => <div className={`${clx}__masker`} />;

export const FilterBarStencil = () => (
  <div className={clx}>
    <div className={`${clx}--wrapper`}>
      <Masker />
      <Masker />
      <Masker />
    </div>
  </div>
);

export default FilterBarStencil;
