import * as React from 'react';
import { Button } from 'ui';
import { IApplicationSettingsBanner } from './StoreFrontSettings.interfaces';
import './_styles.scss';

const ApplicationSettingsBanner = (props: IApplicationSettingsBanner) => (
  <div
    className={
      props.banner !== props.activeBannerId ? 'application-settings-banner active' : 'application-settings-banner'
    }
  >
    <span>
      <img src={props.imageUrl} alt={props.imageAlt} />
    </span>
    <span>
      <p>Banner Name</p>
      <h3>{props.bannerName.toUpperCase()}</h3>
    </span>
    <span>
      <p>Retailer Code</p>
      <h3>{props.banner.toUpperCase()}</h3>
    </span>
    <Button onClick={() => props.handleClick(props.banner)}>Edit Settings</Button>
  </div>
);

export default ApplicationSettingsBanner;
