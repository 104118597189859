import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './assets/styles/main.scss';
import './buildTimeVars';
import store, { history } from 'framework/store';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import userManager from 'framework/utils/userManager';
import CallbackPage from 'framework/modules/callback';
import Authorization from 'framework/authorization/Authorization';
import { retryPromise } from 'framework/utils/retryPromise';
import { errorHandler } from 'framework/services/errorHandler';
import { render } from 'react-dom';
import { initTranslations } from './i18n';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { gb } from './growthbook/initGrowthBook';

const renderApp = () => {
  try {
    const container = document.getElementById('root');
    render(
      <GrowthBookProvider growthbook={gb}>
        <Provider store={store}>
          <OidcProvider store={store} userManager={userManager}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route path="/callback" component={CallbackPage} />
                <Route path="/" exact={false} render={() => <Authorization />} />
              </Switch>
            </ConnectedRouter>
          </OidcProvider>
        </Provider>
      </GrowthBookProvider>,
      container
    );
  } catch (e) {
    errorHandler(e);
  }
};

// renders the app after the translations are loaded
retryPromise(initTranslations, 1000, 5)
  .then(renderApp)
  .catch(() => errorHandler('Failed loading translations'));
