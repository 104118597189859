import * as React from 'react';
import { IPanelList } from './PanelList.interface';
import { prefixClassName } from 'framework/components/ui/_conf';
import './_style.scss';
import { MessageList, Panel } from 'ui';

const className = prefixClassName('panel-list');

const PanelList = (props: IPanelList) => (
  <div className={`${className} ${props.className}`}>
    {props.messages && (
      <MessageList className={`${className}__messageList`} messages={props.messages} />
    )}
    {props.children}
  </div>
);

PanelList.Footer = Panel.Footer;

export default PanelList;
