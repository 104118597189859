import * as React from 'react';
import { ReadOnlyField } from '../ReadOnlyField';
import { PrimitiveTextProps } from './TextFieldPrimitive.interfaces';

export const TextFieldPrimitive = (props: PrimitiveTextProps) => {
  const {
    autocomplete = false,
    className,
    disabled = false,
    onKeyPress,
    maxLength,
    minLength,
    name,
    placeholder,
    readOnly = false,
    onBlur,
    onChange,
    onFocus,
    onMouseOut,
    value,
    setRef,
    labelledby,
    inputMode,
    id,
    testId,
  } = props;
  const aria = labelledby ? { 'aria-labelledby': labelledby } : {};
  return readOnly ? (
    <ReadOnlyField value={value} placeholder={placeholder} />
  ) : (
    <input
      type="text"
      disabled={disabled}
      name={name}
      aria-label={name}
      id={id ?? name}
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      onMouseOut={onMouseOut}
      className={className}
      autoComplete={autocomplete ? 'on' : 'off'}
      ref={setRef}
      maxLength={maxLength}
      minLength={minLength}
      inputMode={inputMode}
      data-testid={testId}
      {...aria}
    />
  );
};

export default TextFieldPrimitive;
