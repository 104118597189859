// Tabs
export const TABS_NAME = 'TabsListing';
export const TAB_DETAILS = 'information';
export const TAB_RULES = 'rule';

/* Details Tab */
export const FORM_DETAILS = 'listingDetails';
export const FIELD_NAME = 'name';
export const FIELD_HEADLINE = 'headline';
export const FIELD_STATUS = 'status';
export const FIELD_DATE_INI = 'startDate';
export const FIELD_DATE_END = 'endDate';
export const FIELD_PRIORITY = 'priority';
export const FIELD_LOCATIONS = 'locations';
export const FIELD_STORES = 'stores';

/* Rules Tab */
export const FORM_RULES = 'listingRules';
export const FIELD_PRODUCTS_TO_DISPLAY = 'resultsCount';
export const FIELD_SORTBY = 'sortBy';

export const FIELD_CONDITIONS = 'conditions';
export const FIELD_CONDITIONS_PROPERTY = 'appliesOnProperty'; // sku | brand |  category | ...
export const FIELD_CONDITIONS_RULE = 'operationType'; // match | notMatch
export const FIELD_CONDITIONS_VALUE = 'appliesOnValues';

export const FIELD_ACTIONS = 'actions';
export const FIELD_ACTIONS_DISPLAY = 'resultsCount';
export const FIELD_ACTIONS_PROPERTY = 'appliesOnProperty'; // sku | brand |  category | ...
export const FIELD_ACTIONS_RULE = 'applyActionOperation'; // oneOf | all | ...
export const FIELD_ACTIONS_VALUE = 'appliesOnValues';
export const FIELD_ACTIONS_SORT = 'sortBy';
export const FIELD_ACTIONS_PARAMETERS = 'externalProviderParameters';
export const FIELD_ACTIONS_PROPERTY_ATTRIBUTES = 'Attributes';

export const FIELD_FILTERS = 'filters';
export const FIELD_FILTERS_PROPERTY = 'appliesOnProperty'; // sku | brand |  category | ...
export const FIELD_FILTERS_RULE = 'applyActionOperation'; // oneOf | all | ...
export const FIELD_FILTERS_VALUE = 'appliesOnValues';

export const ATTRIBUTE_INITIALS = 'attributes.';

export const MIN_DISPLAY_PRODUCTS = 1;
export const MAX_DISPLAY_PRODUCTS = 9999;