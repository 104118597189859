import {
  ITabSteps,
  INIT_TABSTEPS_STORE,
  COMPLETE_TABSTEPS_CURRENT,
  GO_TO_TABSTEPS_PREVIOUS,
  REMOVE_TABSTEPS_INFO,
  UPDATE_TABSTEPS_CURRENTINDEX,
} from './TabSteps.interfaces';

export const initStore = (tabsteps: ITabSteps) => ({
  type: INIT_TABSTEPS_STORE,
  payload: {
    ...tabsteps,
  },
});

// This function has to be used after completing every tab in TabSteps in order
// to navigate to the next tab
// For example: call this function in the onClick function of a submit button inside a tab
export const completeCurrentStep = (tabsId: string) => ({
  type: COMPLETE_TABSTEPS_CURRENT,
  payload: {
    tabsId,
  },
});

export const goToPreviousStep = (tabsId: string) => ({
  type: GO_TO_TABSTEPS_PREVIOUS,
  payload: {
    tabsId,
  },
});

export const clearStore = (id: string) => ({
  type: REMOVE_TABSTEPS_INFO,
  payload: {
    id,
  },
});

export const updateCurrentStepIndex = (props: { tabsId: string; stepId: string }) => ({
  type: UPDATE_TABSTEPS_CURRENTINDEX,
  payload: {
    ...props,
  },
});
