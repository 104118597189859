import * as React from 'react';
import classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text } from 'ui';
import { i18nWithParams } from 'i18n';
import SelectPrimitive from 'framework/components/ui/FormComponents/Primitives/SelectPrimitive';
import { IPaginationDropdown } from './Dropdown.types';
import './_styles.scss';

const clx = prefixClassName('paginationDropdown');

const pageSizeOptions = [10, 25, 50];
const makeOption = opt => ({ value: `${opt}`, label: `${opt}` });

export class PaginationDropdown extends React.Component<IPaginationDropdown> {
  static defaultProps: Partial<IPaginationDropdown> = {
    value: 25,
  };

  generateOptions = newOption => {
    // TODO: make this generic somehow
    const options = [...pageSizeOptions];
    if (pageSizeOptions.indexOf(newOption) === -1) {
      options.push(newOption);
    }
    return options.sort((x, y) => (x > y ? 1 : -1)).map(opt => makeOption(opt));
  };

  render() {
    const { className, visibleRecordsCount, recordCount, menuPlacement, onSetPageSizeHandler } = this.props;
    const options = this.generateOptions(visibleRecordsCount);

    return (
      <div className={classnames(clx, className)}>
        <span className={`${clx}__resultsShowed`}>
          {i18nWithParams('components.pagination.dropdown.showingNoOfNo', {
            pageSize: visibleRecordsCount,
            recordCount,
          })}
        </span>
        <span className={`${clx}__pageSelector`}>
          <Text caption="components.pagination.dropdown.view" />
          <SelectPrimitive
            value={`${visibleRecordsCount}`}
            className={`${clx}__pageSelector__dropdown`}
            name="pagination-dropdown"
            onChange={onSetPageSizeHandler}
            options={options}
            clearable={false}
            searchable={false}
            menuPlacement={menuPlacement}
          />
          <Text caption="components.pagination.dropdown.products" />
        </span>
      </div>
    );
  }
}

export default PaginationDropdown;
