import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { get } from 'framework/services/localStorage/localStorage';
import { SavedSearchesState } from '../models/savedSearch';
import { SAVE_SEARCH_FORM } from '../constants';

const mapStateToProps = (): SavedSearchesState => ({ savedSearches: get(SAVE_SEARCH_FORM) ?? [] });

export const connectSavedSearches = <P = {}>(Component: ComponentType<SavedSearchesState & P>) => {
  const connector = connect(mapStateToProps);

  const Connected = connector(Component as any);

  return Connected as ComponentType<P>;
};
