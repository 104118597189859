import * as React from 'react';
import * as classnames from 'classnames';
import { Text } from 'framework/components/ui';
import { IRadioItem } from './RadioGroup.interfaces';
import { LabelPrimitive } from '../LabelPrimitive';

const RadioItem = (props: Partial<IRadioItem>) => {
  const {
    className,
    checked = false,
    disabled = false,
    name,
    id,
    value,
    label,
    childLabel,
    explainerText,
    itemClassName,
    onChange = () => null,
    tag = 'li',
  } = props;
  const Tag = tag as any;

  let radioContent;
  if (label === 'string') {
    radioContent = (
      <Text
        tag="span"
        caption={label}
        className={classnames(`${className}__label__text`, checked && `${className}__label__text--checked`)}
      />
    );
  } else if (childLabel) {
    radioContent = (
      <>
        <Text
          tag="span"
          caption={childLabel}
          className={classnames(`${className}__label__text`, checked && `${className}__label__text--checked`)}
        />
        {explainerText && (
          <Text caption={explainerText} tag="span" className="mwg-form-field-wrapper-grid__explainer" />
        )}
        {checked && label}
      </>
    );
  } else {
    radioContent = (
      <>
        <span className={classnames(`${className}__label__text`, checked && `${className}__label__text--checked`)}>
          {label}
        </span>
        {explainerText && (
          <Text
            caption={explainerText}
            tag="span"
            className="mwg-form-field-wrapper-grid__explainer display-initial mrg-left-10"
          />
        )}
      </>
    );
  }

  return (
    <Tag
      className={classnames(
        className,
        checked && `${className}--checked`,
        itemClassName,
        disabled && `${className}--disabled`
      )}
    >
      <input
        type="radio"
        name={name}
        id={id}
        className={`${className}__cb`}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
      <span className={`${className}__icon`} />

      <LabelPrimitive name={id} className={`${className}__label`}>
        {radioContent}
      </LabelPrimitive>
    </Tag>
  );
};

export default RadioItem;
