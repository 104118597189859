import * as React from 'react';
import { connect } from 'react-redux';
import { tabsSelectors } from 'framework/selectors';
import { useDispatch } from 'framework/utils/useDispatch';
import { revertContentChanges } from './actions';

/**
 * This component manages tabs navigation for cms documents
 * More specifically, it allows stores-hierarchy and cms content to "post" their changes
 */

type Condition = {
  key: string;
  value: boolean
};

type PreventNavContextProps = {
  /**
   * check if there exists any negative condition preventing navigation
   */
  navigationAllowed: () => boolean,

  /**
   * post a condition (usually from stores-hierarchy or cms-content)
   */
  addNavigationCondition: (condition: Condition) => void;

  /**
   * clears the list of conditions
   */
  resetConditions: () => void;
};

type PreventNavProps = {
  tabsId: string
};

const PreventNavContext = React.createContext<PreventNavContextProps>({} as any);

export const useTabsNavigation = () => React.useContext(PreventNavContext);

const TabsNavigationFn = ({ activeTab, children }: any) => {
  // list of conditions
  const [conditions, setConditions] = React.useState<Condition[]>([]);

  // current active tab: if this value changes then clear the list of conditions
  const [internalActiveTab, setInternalActiveTab] = React.useState(activeTab || []);
  const dispatch = useDispatch();

  const navigationAllowed = () => {
    // check if there exists any negative condition
    const navAllowed = !conditions.some((cond) => {
      const { value } = cond;
      return !value;
    });

    return navAllowed;
  };

  // add navigation condition
  const addNavigationCondition = (cond: Condition) => {
    if (!conditions.find((c) => c.key === cond.key)) {
      setConditions([
        ...conditions, cond,
      ]);
    } else {
      const conds = conditions.map((cnd) => {
        if (cnd.key === cond.key) {
          return {
            ...cnd,
            value: cond.value,
          };
        }
        return cnd;
      });
      setConditions([...conds]);
    }
  };

  // clear conditions list
  const resetConditions = () => setConditions([]);

  React.useEffect(() => {
    /**
     * if user accepts to navigate away then:
     *  1. clear conditions list
     *  2. reset "initial cms content" property from reducer
     *     (this property allows to reset cmsContent in case user prevents navigation from cmsContent tab)
     */
    if (activeTab !== internalActiveTab) {
      resetConditions();
      dispatch(revertContentChanges());
    }

    // set new active tab
    setInternalActiveTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, internalActiveTab]);

  const context: any = {
    navigationAllowed,
    addNavigationCondition,
    resetConditions,
  };

  return (
    <PreventNavContext.Provider value={context}>
      {children}
    </PreventNavContext.Provider>
  );
};

const mapState = (state, ownProps: PreventNavProps) => ({
  activeTab: tabsSelectors.getOpenTab(state, ownProps.tabsId),
});

export const TabsNavigation: React.StatelessComponent<PreventNavProps> = connect(mapState)(TabsNavigationFn) as any;
