import { fetchData } from 'framework/api/fetch-data';
import { config } from 'config';
import { addProgressBar } from 'framework/components/ui/ProgressBar/useProgressBar';
import { cacheable } from 'framework/api/cacheable';
import { AuthorizationClaims, UserAccessRights, ensureClaims } from 'framework/authorization';
import { ExtendedClaims, RouteGroup } from 'framework/components/navigation/Navigation.interfaces';
// eslint-disable-next-line import/no-cycle
import { DashboardRoute } from './routes';
import { translations } from './translations';
import * as constants from './constants';
import { ReportInfo } from './common';

const buildMenus = (root: ReportInfo) => {
  const folders = root.children.filter((r) => (r.type === 'Folder' || r.type === 'folder') && r.children?.length);
  const DASHBOARD = 'Dashboard';

  const extendedClaims: ExtendedClaims[] = [];
  const builtFolders: RouteGroup[] = folders
    .filter((f) => f.id !== DASHBOARD)
    .map<RouteGroup>((f) => ({
      title: f.name,
      hideFromRoleAssociation: true,
      routes: [
        ...(f.children || []).map((c) => {
          if (c.accessClaim) {
            extendedClaims.push({
              label: c.name,
              claims: { [c.accessClaim.toString()]: UserAccessRights.ReadWrite },
            });
          }

          return {
            pageTitle: c.name,
            menuTitle: c.name,
            exact: false,
            path: `${constants.reportPrintPath}/${c.id}`,
            authClaim: [AuthorizationClaims.REPORTING_REPORTS, ...(c.accessClaim ? [c.accessClaim] : [])],
            Component: () => import('./dashboard'),
          };
        }),
      ],
    }));

  return [
    {
      title: translations.allReports,
      routes: [{ ...DashboardRoute, extendedClaims }],
    },
    ...builtFolders,
  ];
};

export const fetchReportRoot = cacheable(
  async () => {
    const fetch = addProgressBar(async () =>
      fetchData<ReportInfo>({
        url: `${config.apiEndpoints.reporting}/tree`,
      })
    );
    const { data: root } = await fetch();

    return root;
  },
  () => constants.getReportKey
);

export const fetchReportMenus = async () => {
  const hasClaim = ensureClaims(AuthorizationClaims.REPORTING_REPORTS);
  if (!hasClaim) {
    return [];
  }
  const root = await fetchReportRoot('get');
  return Array.isArray(root?.children) ? buildMenus(root) : [];
};
