import * as React from 'react';
import classNames from 'classnames';
import { config } from 'config';
import { LayoutFlexBox, TextExplainer, Pagination } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { PaginationLoadMore } from 'framework/components/ui/DataGrid/Plugins/TableMode/Pagination/PaginationLoadMore';
import { createPaginationStore, PaginationStoreSkipTake } from 'framework/components/ui/Pagination/paginationStore';
import { store } from '@risingstack/react-easy-state';
import { featureFlag } from 'featureFlag';
// eslint-disable-next-line import/no-cycle
import { IDataGridDouble } from './DataGridDouble.interface';
import ContentGrid from './DataGridDouble.single';
import './_style.scss';
import { PreviewViews } from '../../common/Rules/RuleCreateEditContent';
import { actionsStore } from '../../common/Rules/components/Actions/ActionsComponent';

const clx = prefixClassName('gridDouble');

export const rulePaginationStore: { pagination?: PaginationStoreSkipTake } = store<{
  pagination?: PaginationStoreSkipTake;
}>({});

const DataGridDouble = (props: IDataGridDouble) => {
  const {
    current,
    className,
    defaultText = 'generic.dataGridDouble.defaultText',
    name,
    preview,
    showGrids: SG = false,
    currentPage = 1,
    pageSize = config.dataGridDefaultPageSize,
    onNext,
    onPrevious,
    onGetPage,
    selectProduct,
    showCurrentScoreExplanation,
    showPreviewScoreExplanation,
    getGridData,
    isLoading,
    searchTerm,
    view = PreviewViews.CurrentAndPreview,
  } = props;

  const { skip, take } = rulePaginationStore?.pagination ?? { skip: 0, take: 20 };

  const paginationTotal = preview?.total ? preview.total : current?.total || 0;
  const showGrids = SG || current.total > 0 || preview.total > 0 || current.isFetching || preview.isFetching;

  React.useEffect(() => {
    featureFlag({
      prod: () => {
        rulePaginationStore.pagination = createPaginationStore({ skip: 0, take: 20, onPageChanged: getGridData });
      },
      dev: () => {},
    });
  }, [searchTerm]);

  return (
    <LayoutFlexBox flexDirection="column" className={classNames(clx, className)}>
      {showGrids ? (
        <>
          <LayoutFlexBox className={`${clx}__gridsContainer`}>
            {view === PreviewViews.CurrentAndPreview && (
              <ContentGrid
                products={current}
                type="current"
                name={`${name}__current`}
                className={`${clx}__grid`}
                selectProduct={selectProduct}
                loadScoreExplanation={showCurrentScoreExplanation}
              />
            )}
            <ContentGrid
              products={preview}
              {...(view === PreviewViews.PreviewOnly && { flexBasis: '100%' })}
              type="preview"
              name={`${name}__preview`}
              className={`${clx}__grid`}
              selectProduct={selectProduct}
              loadScoreExplanation={showPreviewScoreExplanation}
              fetchCurrentData={() => {
                getGridData({ skip, take });
              }}
              draggable={view === PreviewViews.PreviewOnly}
            />
          </LayoutFlexBox>
          {featureFlag({
            prod: () =>
              actionsStore?.fields?.length > 0 &&
              paginationTotal > 0 &&
              !isLoading && (
                <div className={`${clx}__pagination mrg-btm-20`}>
                  <PaginationLoadMore
                    {...rulePaginationStore.pagination?.props}
                    className="mrg-btm-20"
                    // eslint-disable-next-line react/prop-types
                    visibleRecordsCount={preview.count}
                    recordCount={paginationTotal}
                  />
                </div>
              ),
            // Old pagination, keep under dev flag just in case
            dev: () =>
              paginationTotal > 0 && (
                <Pagination
                  currentPage={currentPage}
                  onNext={onNext}
                  onGetPage={onGetPage}
                  onPrevious={onPrevious}
                  pageSize={pageSize}
                  recordCount={paginationTotal}
                  className={`${clx}__pagination`}
                />
              ),
          })}
        </>
      ) : (
        <TextExplainer className={`${clx}__defaultText`} display={!showGrids} caption={defaultText} />
      )}
    </LayoutFlexBox>
  );
};

export default DataGridDouble;
