export const storeExpandedIds = (state, action) => ({
  ...state,
  cmsPages: {
    ...state.cmsPages,
    expandedIds: action.payload.expandedIds,
  },
});

export const clearExpandedIds = (state) => ({
  ...state,
  cmsPages: {
    ...state.cmsPages,
    expandedIds: [],
  },
});
