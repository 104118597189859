import * as React from 'react';
import { IPanelHeader } from './Panel.interfaces';
import { Text } from '../index';
import { clx as parentClx } from './index';

const PanelHeader = (props: IPanelHeader) => {
  const { subtitle, title, optional, headerControls } = props;
  const clx = `${parentClx}__header`;

  return title || subtitle ? (
    <div className={clx}>
      {title && <Text caption={title} className={`${clx}__title`} tag="h2" />}
      {optional && (
        <Text className={`${clx}__optional`} caption="components.form.optionalValue" tag="span" />
      )}
      {headerControls && <div className={`${clx}__headerControls`}>{headerControls}</div>}
      {subtitle && <Text caption={subtitle} className={`${clx}__subTitle`} tag="h3" />}
    </div>
  ) : null;
};

export default PanelHeader;
