export enum SearchAdminTranslations {
  ProductInformation = 'modules.searchAdmin.productInformation',
  SKU = 'modules.searchAdmin.sku',
  UnitOfSize = 'modules.searchAdmin.unitOfSize',
  Brand = 'modules.searchAdmin.brand',
  Price = 'modules.searchAdmin.price',
  PricePerUnit = 'modules.searchAdmin.pricePerUnit',
  Promotion = 'modules.searchAdmin.promotion',
  ShortDescription = 'modules.searchAdmin.shortDescription',
  LongDescription = 'modules.searchAdmin.longDescription',
  DefaultCategory = 'modules.searchAdmin.defaultCategory',
  CategoryBreadcrumb = 'modules.searchAdmin.categoryBreadcrumb',
  AllCategories = 'modules.searchAdmin.allCategories',
  CountCategories = 'modules.searchAdmin.countCategories',
  Keywords = 'modules.searchAdmin.keywords',
  Attributes = 'modules.searchAdmin.attributes',
  Metrics = 'modules.searchAdmin.metrics',
  UnitSales = 'modules.searchAdmin.unitSales',
  Revenue = 'modules.searchAdmin.revenue',
  Views = 'modules.searchAdmin.views',
  Clicks = 'modules.searchAdmin.clicks',
  Margin = 'modules.searchAdmin.margin',
  Other = 'modules.searchAdmin.other',
  Active = 'generic.status.active',
  Inactive = 'generic.status.inactive',
  Status = 'generic.dataGrid.colStatus',
  WasPrice = 'modules.searchAdmin.wasPrice',
  TagsField_placeholder = 'components.tagsField.semicolonSeparatorPlaceholder',
}
