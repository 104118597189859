import * as React from 'react';
import { appState } from 'stores/app';
import userManager from 'framework/utils/userManager';
import { Icon, Text } from 'ui';
import classNames from 'classnames';
import { prefixClassName } from '../_conf';
import { Menu } from './Menu/Menu';
import { MenuTranslations } from './translations';
import './_styles.scss';
import { DOCS_URL, isValidExternalKey } from '../../../../config';

export const signOutUser = async () => {
  await userManager.signoutRedirect({ id_token_hint: appState.user?.id_token });
  const { FS } = window;
  if (FS) {
    FS.identify(false);
  }
};

const clx = prefixClassName('user-block');
const clxLink = prefixClassName('link');

const docsUrl = window.docsUrl?.trim();
const isValidDocsUrl = isValidExternalKey({ docsUrl });

export const UserBlock = () => (
  <div className="wrapper">
    <div className={`${clx}__help-link`}>
      <span className={`${clx}__help-link__nowrap`}>
        <Icon name="question" className={`${clx}__help-icons`} />
        <a
          className={classNames(`${clx}__help-link__text`, clxLink)}
          href={isValidDocsUrl ? docsUrl : DOCS_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text caption="generic.help" />
        </a>
      </span>
    </div>
    <Menu
      options={[
        {
          label: MenuTranslations.MyAccount,
          linkTo: '/myaccount',
        },
        {
          label: MenuTranslations.SignOut,
          onClick: signOutUser,
        },
      ]}
      keepDropdown
      buttonText={`${appState.user?.profile?.given_name} ${appState.user?.profile?.family_name}`}
      icon="account"
    />
  </div>
);
