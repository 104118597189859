import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { view } from '@risingstack/react-easy-state';
import {
  Table, Icon, Text, Loader,
} from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { IconNames } from 'framework/components/ui/Icon';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useLocation } from 'react-router-dom';
import { FileFolder, FileFolderType, KebabAction } from './types';
import { useFileManagerContext } from './FileManagerContext';

const cssClass = prefixClassName('file-manager');

export type FileManagerHierarchyProps = {
  data: FileFolder[],
  label?: string;
  hideHeaders?: boolean;
  displayEmptyRow?: boolean;
  skipActive?: boolean;
  wider?: boolean;
  activeFolder?: FileFolder;
  resolveIcon: (row: FileFolder) => IconNames;
  isLoading: (reference: string) => boolean;
  onHierarchyRowExpanding: (row: FileFolder) => Promise<FileFolder[]>;
  onHierarchyFolderHighlight: (row: FileFolder) => void;
  onKebabActions?: (row: FileFolder) => KebabAction[];
};
export const FileManagerHierarchy = view(({
  data,
  hideHeaders,
  wider,
  activeFolder,
  displayEmptyRow = true,
  isLoading,
  skipActive,
  resolveIcon,
  onKebabActions,
  onHierarchyRowExpanding,
  onHierarchyFolderHighlight,
  label = 'modules.contentmanagement.selectLocation',
}: FileManagerHierarchyProps) => {
  const [treeData, setTreeData] = useState<FileFolder[]>([]);
  const [expanded, setExpanded] = useState<FileFolder[]>([]);
  const [highlighted, setHightlighted] = useState<string>();
  const { setActiveFolder, store } = useFileManagerContext();

  const { pathname } = useLocation();

  const isHighlighted = (row: FileFolder) => (!highlighted ? false : (row.reference === highlighted)
                                || (row.children ?? []).some((r) => isHighlighted(r)));
  const isExpanded = (row: FileFolder) => expanded.some((r) => r.reference === row.reference);
  const isParent = (top: FileFolder, node?: FileFolder) => {
    if (!node) {
      return false;
    }
    return top.reference === node.parent?.reference || isParent(top, node?.parent);
  };

  const toggleExpanded = (row: FileFolder) => () => {
    ApplicationMonitoring.trackEvent(
      row?.trackEventProps?.name || pathname,
      row?.trackEventProps?.value || 'Explorer toggle expanded',
      row?.trackEventProps?.label || row?.name
    );
    if (isExpanded(row)) {
      setExpanded((prev) => prev.filter((r) => r.reference !== row.reference && !isParent(row, r)));
    } else {
      onHierarchyRowExpanding(row).then(() => setExpanded((prev) => [...prev, row]));
    }
  };
  const handleHighlight = (row: FileFolder) => {
    ApplicationMonitoring.trackEvent(
      row?.trackEventProps?.name || pathname,
      row?.trackEventProps?.value || 'Explorer highlight row',
      row?.trackEventProps?.label || row?.name
    );
    setHightlighted(row?.reference);
    onHierarchyFolderHighlight(row);
    if (!skipActive) {
      setActiveFolder(row);
    }
  };
  const toggleSelect = (row: FileFolder) => ({
    onClick: () => {
      handleHighlight(!highlighted || highlighted !== row.reference ? row : undefined);
    },
  });

  const calcIndent = (_row?: FileFolder) => {
    if (!_row?.parent) {
      return 0;
    }
    const indent = calcIndent(_row.parent);
    return indent + 60;
  };

  const buildFolders = (_data: FileFolder[],
    level: number,
    parent?: FileFolder) => _data.reduce<FileFolder[]>((acc, n) => {
    if (n.type !== FileFolderType.DIRECTORY) {
      return acc;
    }
    if (isExpanded(n)) {
      const updated = { ...n, level, parent };
      let emptyRow: FileFolder[] = [];
      const shouldDisplayEmpty = !isLoading(n.reference)
        && !updated.children?.some((c) => c.type === FileFolderType.DIRECTORY);

      if (shouldDisplayEmpty && displayEmptyRow) {
        emptyRow = [
          {
            name: 'modules.contentmanagement.noSubfolders',
            reference: `${n.reference}/empty`,
            type: FileFolderType.FILE,
            parent: updated,
            displayEmpty: true,
          },
        ];
      }
      return [
        ...acc,
        updated,
        ...emptyRow,
        ...buildFolders(n.children ?? [], level + 1, updated)];
    }
    return [...acc, { ...n, parent }];
  }, []);

  useEffect(() => {
    setTreeData(() => buildFolders(data, 0));
  }, [data, expanded]);

  const navigateToFolder = (folder: FileFolder) => {
    if (treeData.length === 0) {
      const folders = buildFolders(data, 0);
      setTreeData(folders);

      const folderSuccession = folder.reference.split('/');

      let localTreeData = folders;
      folderSuccession.filter((fold) => fold && fold !== '').forEach((f) => {
        const treeFolder = localTreeData.find((node) => node.name === f);
        setExpanded((prev) => [...prev, treeFolder]);
        localTreeData = treeFolder?.children;
      });
    }
  };

  useEffect(() => {
    if (activeFolder) {
      handleHighlight(activeFolder);
      navigateToFolder(activeFolder);
    }
  }, [activeFolder]);

  return (
    <div className={classnames({
      [`${cssClass}__treeview`]: true,
      [`${cssClass}__treeview--wider`]: wider,
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    })} tabIndex={0}>
      <Table
        data={treeData}
        noBorder
        className={classnames({ [`${cssClass}__treeview--hide-headers`]: hideHeaders })}
        columnMetadata={onKebabActions ? [label, ''] : [label]}
        cellRenderer={(_, row: FileFolder) => (

          <Table.Row
              className={classnames(`${cssClass}__treeview__row`)}>

            <Table.Column className={`${cssClass}__treeview__td-handle`}>
              <div className={classnames(`${cssClass}__treeview-handle`,
                isHighlighted(row) && `${cssClass}__treeview-handle--selected`,
                row.parent && isExpanded(row.parent) && `${cssClass}__treeview-handle--expanded`)}
                style={{ marginLeft: calcIndent(row) }}
                {...toggleSelect(row)}
              >

                {row.type === FileFolderType.FILE ? (
                  <Text tag="span" className={`${cssClass}__treeview--empty`} caption={row.name} />
                ) : (
                    <>
                      { isLoading(row.reference) ? <Loader size="xs" />
                        : <Icon onClick={toggleExpanded(row)}
                                className={classnames(`${cssClass}__treeview__arrow-icon`)}
                                name="dropdownArrowRight"
                                rotate={isExpanded(row) ? 90 : null}
                          />
                      }
                        <div>
                          <Icon name={resolveIcon(row)} className={classnames(`${cssClass}__icon`)} />
                          <Text tag="span" caption={row.name} />
                        </div>
                    </>
                ) }
              </div>
            </Table.Column>

            { (!row.displayEmpty && onKebabActions) ? (
              <Table.ColumnKebab
                key="td-action"
                keepDropdown
                options={onKebabActions(row)}
                onToggle={(isOpen) => {
                  if (isOpen) {
                    handleHighlight(row);
                    store.loadingReference = row.reference;
                    store.rowClick(row).finally(() => {
                      store.loadingReference = undefined;
                    });
                  }
                }}
              />
            ) : (<Table.Column>
              <div {...toggleSelect(row)}/>
            </Table.Column>)}

          </Table.Row>
        )}
      />
    </div>
  );
});
