import * as React from 'react';
// eslint-disable-next-line import/no-cycle
import { ReadOnlyField } from '..';
import { PrimitiveTextAreaProps } from './TextAreaPrimitive.interfaces';

export const TextAreaPrimitive = (props: PrimitiveTextAreaProps) => {
  const {
    className,
    disabled = false,
    name,
    rows = 4,
    placeholder,
    readOnly = false,
    onBlur,
    onChange,
    onFocus,
    value,
  } = props;

  return readOnly ? (
    <ReadOnlyField value={value} placeholder={placeholder} />
  ) : (
    <textarea
      className={className}
      disabled={disabled}
      id={name}
      aria-label={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      rows={rows}
      value={value}
    />
  );
};

export default TextAreaPrimitive;
