/* eslint-disable import/no-cycle */
import * as React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ensureClaims } from 'framework/authorization';
import { Button } from 'ui';
import * as isEqual from 'lodash.isequal';
import TableHeader from './TableHeader';
import './Table.scss';
import { ITable, ObjectMetadata, OnDragEndCb } from './Table.interfaces';
// eslint-disable-next-line import/no-cycle
import { defaultCellRenderer, defaultIfEmpty, defaultHeaderRenderer } from './TableDefaults';
import { transformMetadata, updatePosition } from './helpers';
import { Row, MultiRow, DeletableRow, ExpandableRow, DraggableRow } from './Rows';
import {
  Head,
  Column,
  ColumnText,
  ColumnTextEditable,
  ColumnTextInlineEdit,
  ColumnStatus,
  ColumnKebab,
  ColumnImage,
  ColumnLink,
  ColumnDate,
  ColumnSummarizer,
  ColumnEnum,
  ColumnCheckbox,
  ColumnRouteLink,
  ColumnXOutOfY,
  ColumnDelete,
  ColumnIcon,
  DraggableCell,
  ColumnContrastText,
} from './TableColumn';
import { CheckboxCellHead, CheckboxCellBody } from './Cells/CheckBoxCell';
import { ActionsCell } from './Cells/ActionsCell';
import { emptyCell, noPaddingRight, tableClassName } from './constants';
import { TableFilter } from './TableFilter';
import { ResponsiveTable } from './ResponsiveTable';
import { SingleTable } from './SingleTable';

const TableRow = ({ children }: { children: any }) => <>{children}</>;

export interface TBodyProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onDragEnd?: OnDragEndCb;
  children: any;
}

const TableBody = ({ children }: TBodyProps) => <tbody>{children}</tbody>;

const DragDropBody = ({ onDragEnd, children }: TBodyProps) => (
  <DragDropContext
    onDragEnd={({ draggableId, source, destination }) => {
      if (source?.index !== destination?.index) {
        onDragEnd(draggableId, destination?.index);
      }
    }}
  >
    <Droppable droppableId="droppable" direction="vertical">
      {(droppableProvided) => (
        <tbody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
          {children}
          {droppableProvided.placeholder}
        </tbody>
      )}
    </Droppable>
  </DragDropContext>
);

const Table = <T extends {}>(props: ITable<T>) => {
  const {
    onTableRendered,
    tableName,
    autoid,
    columnMetadata,
    data,
    className,
    hiddenColumns,
    headerRenderer = defaultHeaderRenderer,
    cellRenderer = defaultCellRenderer,
    ifEmpty = defaultIfEmpty,
    emptyText,
    noBorder,
    draggable,
    onDragEnd,
    actions,
    onActionInvoked,
    canSelectItems,
    selectedItems,
    selectionLogic,
    disabledItems,
    toggleAllSelection,
    toggleItemSelection,
    loadingItems = [],
    draggableId,
    setExternalData,
    originalWord = null,
    replacementWord = null,
    filterBarSettings,
    responsive = false,
    onAddItemClick,
    addItemCaption,
    editSequence = false,
    onEditSequence,
    editSequenceCaption,
    saveSequenceCaption,
    disableAddOption = false,
    hideCheckboxPadding = true,
    showOnlyFirstRow = false,
  } = props;
  const [filteredData, setFilteredData] = React.useState<T[]>(null);

  // Local tmpData to handle sequence Save button state
  const tmpData = React.useRef<T[]>(data);

  const handleSequenceSave = () => {
    // Update tmpData when Save is clicked while changing data sequence
    tmpData.current = data;
  };

  const handleSequenceCancel = () => {
    if (tmpData.current) {
      // Update external data to tmpData when Cancel is clicked while changing data sequence
      setExternalData(tmpData.current);
    }
  };

  const updateRowPosition = (attributeId: string, newIndex: number) => {
    if (newIndex === null || newIndex === undefined || newIndex < 0 || Number.isNaN(newIndex)) return;
    const items: T[] = updatePosition(attributeId, newIndex, draggableId, data);

    // Send attributeId, new index for row, and row data if onDragEnd exists
    if (onDragEnd) onDragEnd(attributeId, newIndex, data?.find((v: T) => v[draggableId] === attributeId), items);

    if (setExternalData) setExternalData(items);
  };

  const TBody = draggable ? DragDropBody : TableBody;

  const handleOnFilter = (value: string) => {
    if (value === null || value.length === 0) {
      setFilteredData(data);
      return;
    }
    
    const editedData: T[] = [...data].filter((d) => filterBarSettings.filterKeywords.some((key) => {
      return d[key]?.toLowerCase()?.includes(value);
    }));

    setFilteredData(editedData);
  };

  React.useEffect(() => {
    if (onTableRendered) {
      onTableRendered();
    }
  }, []);

  const isEmpty = !Array.isArray(data) || data.length === 0 || (filterBarSettings && filteredData?.length === 0);

  // adds selectcolumn to the start of metadata
  const metadata: ObjectMetadata<T>[] = React.useMemo(() => {
    let newMetadata = transformMetadata(columnMetadata, data);

    if (editSequence) {
      newMetadata = [
        {
          id: 'dragColumn',
          caption: '',
          customRenderer: () => (
            <Table.DraggableCell />
          ),
        },
        ...newMetadata,
      ];
    }

    if (canSelectItems) {
      // generates select column
      newMetadata = [
        {
          id: 'selectColumn',
          caption: {
            customRenderer: () => (
              <CheckboxCellHead
                toggleAllSelection={toggleAllSelection}
                selectedItems={selectedItems}
                selectionLogic={selectionLogic}
                disabledItems={disabledItems}
                data={data}
                hidePadding={hideCheckboxPadding}
              />
            ),
          },
          customRenderer: (item, _col, row) => (
            <CheckboxCellBody
              toggleItemSelection={toggleItemSelection}
              selectedItems={selectedItems}
              item={item}
              isSelected={selectionLogic}
              disabledItems={disabledItems}
              index={row}
              hidePadding={hideCheckboxPadding}
            />
          ),
        },
        ...newMetadata,
      ];
    }

    if (actions) {
      // adds Actions column at the end of the metadata
      const authorizedActions = actions.filter((a) => ensureClaims(a.authorize) && !a.hideRowAction);

      if (authorizedActions.length > 0) {
        newMetadata = [
          ...newMetadata,
          {
            id: 'actions',
            caption: 'generic.actions',
            customRenderer: (item: T) => (
              <ActionsCell
                isLoading={loadingItems.includes(item)}
                options={authorizedActions.map((a) => {
                  const config = a.config(item);
                  return {
                    ...config,
                    onClick: () => onActionInvoked(config, item),
                  };
                })}
              />
            ),
          },
        ];
      }
    }

    return newMetadata;
  }, [columnMetadata, data, canSelectItems, actions, selectedItems, loadingItems, hiddenColumns]);

  React.useEffect(() => {
    if (filterBarSettings) setFilteredData(null);
    // Keep tmpData up to date if rows are added or deleted
    if (tmpData?.current && tmpData.current?.length !== data.length) tmpData.current = data;
  }, [data]);

  React.useEffect(() => {
    // Update tmpData to the most current data when editSequence is activated
    if (tmpData && editSequence) tmpData.current = data;
  }, [editSequence]);

  return (
    <>
      {
        filterBarSettings && (
          <TableFilter
            tableName={tableName}
            filterBarSettings={filterBarSettings}
            handleOnFilter={handleOnFilter}
          />
        )
      }
      
      {
        !responsive
          ? <SingleTable<T>
            autoid={autoid}
            emptyText={emptyText}
            noBorder={noBorder}
            onDragEnd={onDragEnd}
            draggableId={draggableId}
            draggable={draggable}
            originalWord={originalWord}
            tableName={tableName}
            TBody={TBody}
            replacementWord={replacementWord}
            TableRow={TableRow}
            filteredData={filteredData}
            updateRowPosition={updateRowPosition}
            TableHeader={TableHeader}
            metadata={metadata}
            ifEmpty={ifEmpty}
            hiddenColumns={hiddenColumns}
            headerRenderer={headerRenderer}
            isEmpty={isEmpty}
            className={className}
            data={showOnlyFirstRow ? [data[0]]: data}
            cellRenderer={cellRenderer}
            columnMetadata={columnMetadata}
            tableClassName={tableClassName}
          />
          : <ResponsiveTable<T>
            autoid={autoid}
            emptyText={emptyText}
            noBorder={noBorder}
            onDragEnd={onDragEnd}
            draggableId={draggableId}
            draggable={draggable}
            originalWord={originalWord}
            tableName={tableName}
            TBody={TBody}
            replacementWord={replacementWord}
            TableRow={TableRow}
            filteredData={filteredData}
            updateRowPosition={updateRowPosition}
            TableHeader={TableHeader}
            metadata={metadata}
            ifEmpty={ifEmpty}
            hiddenColumns={hiddenColumns}
            headerRenderer={headerRenderer}
            isEmpty={isEmpty}
            className={className}
            data={data}
            cellRenderer={cellRenderer}
            columnMetadata={columnMetadata}
            tableClassName={tableClassName}
          />
      }
      {
        (onAddItemClick && addItemCaption) && (
          <Button
            caption={addItemCaption}
            onClick={onAddItemClick}
            disabled={disableAddOption}
            icon="plus"
            outline
          />
        )
      }
      {
        (onEditSequence && editSequence !== null && editSequenceCaption) && (
          !editSequence
            ? (<Button
              caption={editSequenceCaption}
              onClick={onEditSequence}
              disabled={data.length < 2}
              outline
            />)
            : (<>
              <Button
                name="saveSequence"
                testId="saveSequenceBtn"
                caption={saveSequenceCaption ?? 'generic.options.saveSequence'}
                disabled={isEqual(data, tmpData.current)}
                onClick={() => {
                  handleSequenceSave();
                  onEditSequence();
                }}
              />
              <Button
                name="cancelSequence"
                testId="cancelSequenceBtn"
                caption="generic.button.cancel"
                outline
                onClick={() => {
                  handleSequenceCancel();
                  onEditSequence();
                }}
              />
            </>)
        )
      }
    </>
  );
};

Table.Row = Row;
Table.DraggableRow = DraggableRow;
Table.MultiRow = MultiRow;
Table.DeletableRow = DeletableRow;
Table.ExpandableRow = ExpandableRow;
Table.Head = Head;
Table.Column = Column;
Table.ColumnText = ColumnText;
Table.ColumnContrastText = ColumnContrastText;
Table.ColumnEditableText = ColumnTextEditable;
Table.ColumnTextInlineSave = ColumnTextInlineEdit;
Table.ColumnLink = ColumnLink;
Table.ColumnSummarizer = ColumnSummarizer;
Table.ColumnImage = ColumnImage;
Table.ColumnStatus = ColumnStatus;
Table.ColumnKebab = ColumnKebab;
Table.ColumnDate = ColumnDate;
Table.ColumnEnum = ColumnEnum;
Table.ColumnCheckbox = ColumnCheckbox;
Table.ColumnRouteLink = ColumnRouteLink;
Table.ColumnXOutOfY = ColumnXOutOfY;
Table.ColumnDelete = ColumnDelete;
Table.ColumnIcon = ColumnIcon;
Table.DraggableCell = DraggableCell;
Table.EmptyHeader = { caption: '', className: emptyCell } as ObjectMetadata;
Table.IconHeader = { caption: '', className: emptyCell, width: '36px' } as ObjectMetadata;
Table.noPaddingRightClass = noPaddingRight;

export default Table;
