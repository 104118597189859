import * as React from 'react';
import { Button, Header, Modal } from 'ui';
import classNames from 'classnames';
import { NotificationsTranslations } from '../Menu/translations';
import { INotificationLine } from '../Menu/Menu.interfaces';
import { ALL_NOTIFICATIONS_MODAL } from '../constants';
import NotificationLine from '../Menu/NotificationLine';
import { prefixClassName } from '../../_conf';

const clx = prefixClassName('notifications-menu');

interface AllNotificationsModalProps {
  notificationsSeen: INotificationLine[];
  notificationsNotSeen: INotificationLine[];
  onMarkAllAsSeen: () => void;
  onNotificationClick: (notification: INotificationLine) => void;
  closeModal: () => void;
}

export const AllNotificationsModal = (props: AllNotificationsModalProps) => {
  const { notificationsSeen, notificationsNotSeen, closeModal, onMarkAllAsSeen, onNotificationClick } = props;

  return (
    <Modal
      name={ALL_NOTIFICATIONS_MODAL}
      size="large"
      caption={NotificationsTranslations.AllNotifications}
      footerOptions={[
        {
          onClick: closeModal,
          outline: true,
          caption: 'generic.button.close',
        },
      ]}
      {...(notificationsNotSeen.length !== 0
        ? {
            headerControls: () => (
              <Button
                className="mrg-left-40"
                outline
                onClick={onMarkAllAsSeen}
                caption={NotificationsTranslations.MarkAllSeen}
                testId="markAllAsSeenModalBtn"
              />
            ),
          }
        : {})}
      className={`${clx}__allNotificationsModal`}
    >
      {notificationsNotSeen.length !== 0 && (
        <>
          <Header caption={NotificationsTranslations.New} headerLevel={3} />
          <ul className={`${clx}__optionsList`}>
            {notificationsNotSeen.map((notification: INotificationLine, key) => (
              <NotificationLine
                key={key}
                {...notification}
                seen={!notificationsNotSeen.find((n) => n.id === notification.id)}
                onClick={() => onNotificationClick(notification)}
                className={classNames(`${clx}__option`, notification.className)}
              />
            ))}
          </ul>
        </>
      )}

      {notificationsSeen.length !== 0 && (
        <>
          <Header caption={NotificationsTranslations.Seen} headerLevel={3} />
          <ul className={`${clx}__optionsList`}>
            {notificationsSeen.map((notification: INotificationLine, key) => (
              <NotificationLine
                key={key}
                {...notification}
                seen={!notificationsNotSeen.find((n) => n.id === notification.id)}
                onClick={() => onNotificationClick(notification)}
                className={classNames(`${clx}__option`, notification.className)}
              />
            ))}
          </ul>
        </>
      )}
    </Modal>
  );
};
