import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import './_styles.scss';

const clx = prefixClassName('stencil-form-field');

export const StencilFormField = () => (
  <div className={clx}>
    <div className={`${clx}__bg-masker ${clx}__top`} />
    <div className={`${clx}__bg-masker ${clx}__bottom`} />
  </div>
);

export default StencilFormField;
