import * as React from 'react';
import * as classnames from 'classnames';
import { IEventWrapper } from './EventWrapper.interfaces';
import './_style.scss';

const clx = 'cal_eventWrapper';
export enum shoppingModeType {
  '11111111-1111-1111-1111-111111111111' = 'pickup',
  '22222222-2222-2222-2222-222222222222' = 'delivery',
  '33333333-3333-3333-3333-333333333333' = 'planning',
}

const EventWrapper = (props: IEventWrapper) => {
  const { className, event, type, children } = props;
  const { disabled, modified = false, template, data = {} } = event as any;
  const { shoppingModeId, fulfillmentType, fulfilmentType, isClosed } = data;
  const sm = shoppingModeType[shoppingModeId];

  const classMap = {
    [`${clx}--${type}`]: type,
    [`${clx}--template-${fulfillmentType?.value ?? fulfillmentType ?? fulfilmentType}`]: fulfillmentType || fulfilmentType,
    [`${clx}--template-${template}`]: template,
    [`${clx}--shoppingMode-${sm}`]: sm,
    [`${clx}--isUpdated`]: modified,
    [`${clx}--disabled`]: disabled,
    [`${clx}--isExpired`]: data.isExpired,
    [`${clx}--isClosed`]: isClosed,
    [`${clx}--expired-delivery`]: data.isExpired && (sm === 'delivery'),
    [`${clx}--expired-pickup`]: data.isExpired && (sm=== 'pickup'),
    [`${clx}--expired-instacart`]: data.isExpired && (fulfillmentType?.value === 'instacart' || fulfilmentType === 'instacart'),
    [`${clx}--expired-blocked`]: data.isExpired && data.isBlocked ,
    [`${clx}--expired-orphan`]: data.isExpired && data.isOrphan,
    [`${clx}--expired-closed`]: data.isExpired && data.isClosed,
  };
  return <div className={classnames(clx, classMap, className)}>{children}</div>;
};

export default EventWrapper;
