export default (
  state,
  payload: {
    calendarName: string;
  }
) => {
  const { calendarName } = payload;

  return {
    ...state,
    [calendarName]: {
      ...state[calendarName],
      events: { ...state[calendarName].eventsOriginal },
      eventsDeleted: [],
      newBlockedSlots: [],
    }
  };
};
