import * as React from 'react';
import { Text, Icon } from 'framework/components/ui';
import { i18n } from 'i18n';
import { FulfillmentTypes } from 'framework/modules/slotManagement/pages/SlotsTemplatesEdit/pageParts/constants';
// eslint-disable-next-line import/no-cycle
import { toSentenceCase } from 'framework/utils/string';
// eslint-disable-next-line import/no-cycle
import { clx } from '../Event';
import { shoppingModeType } from '../../EventWrapper/EventWrapper';

const localePath = 'components.calendar.events.template.liveCalendar.';

const EventLiveCalendar = React.memo((props: any) => {
  const { data = {} } = props;
  const {
    availableCapacity,
    isClosed = false,
    isClosedNotes,
    orders,
    tags = [],
    price = 0,
    fulfilmentType,
    shoppingModeId,
  } = data;

  const sm = shoppingModeType[shoppingModeId];

  const renderProp = (label: string, value: any) =>
    !!value && (
      <li>
        <Text caption={label} />: {value}
      </li>
    );

  return (
    <ul className={`${clx}__list`}>
      {isClosed && (
        <li>
          <Text caption={`${localePath}closed`} />
          {!!isClosedNotes && <Icon name="file" className={`${clx}__template__icon-message`} />}
        </li>
      )}
      {renderProp(`${localePath}orders`, orders)}
      {renderProp(
        `${localePath}capacity`,
        (fulfilmentType?.value?.toLowerCase() || fulfilmentType?.toLowerCase())
          === FulfillmentTypes.Instacart.toLowerCase() ? i18n.t('generic.unlimited') : availableCapacity)}
      {renderProp(`${localePath}price`, price)}
      {renderProp(`${localePath}tags`, tags.join(', '))}
      {sm && renderProp(`${localePath}shoppingMode`, toSentenceCase(sm))}
    </ul>
  );
});

export default EventLiveCalendar;
