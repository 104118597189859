import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { goToRoute } from 'framework/components/navigation/service';
import { Uuid } from 'types';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { Features } from 'stores/features';
import * as CONST from './constants';
import {
  IdentityProviderTranslations,
  DeliveryProvidersTranslations,
  StorefrontUiTranslations,
  StsTranslations,
  WebhookTranslations,
  ShoppingRuleTranslations,
  OrderPreferencesTranslation,
  MobileAppUiTranslations,
  SharedSettingsTranslations,
  SegmentsTranslations,
} from './translations';
import { featureFlag } from '../../../featureFlag';

export const identityProviders: AppRoute = {
  breadcrumbTitle: IdentityProviderTranslations.IdentityProviders,
  menuTitle: IdentityProviderTranslations.AllIdentityProviders,
  pageTitle: IdentityProviderTranslations.SingleSignOnIdentityProviders,
  path: `${CONST.PATH_MAIN}${CONST.PATH_IDENTITY_PROVIDERS}`,
  authClaim: AuthorizationClaims.SITESETTINGS_IDENTITYPROVIDER,
  Component: () => import('./pages/IdentityProviders'),
};

export const createIdentityProvider: AppRoute = {
  breadcrumbTitle: IdentityProviderTranslations.CreateIdentityProvider,
  menuTitle: IdentityProviderTranslations.CreateIdentityProvider,
  pageTitle: IdentityProviderTranslations.CreateIdentityProvider,
  path: CONST.PATH_IDENTITY_PROVIDERS_CREATE_FULLPATH,
  authClaim: AuthorizationClaims.SITESETTINGS_IDENTITYPROVIDER,
  Component: () => import('./pages/IdentityProviderEdit'),
  parent: identityProviders,
};

export const editIdentityProvider: AppRoute = {
  path: `${CONST.PATH_IDENTITY_PROVIDERS_EDIT_FULLPATH}/:id`,
  pageTitle: IdentityProviderTranslations.EditIdentityProvider,
  authClaim: AuthorizationClaims.SITESETTINGS_IDENTITYPROVIDER,
  Component: () => import('./pages/IdentityProviderEdit'),
  parent: identityProviders,
};

export const webhookListing: AppRoute = {
  breadcrumbTitle: WebhookTranslations.WebhookListing,
  menuTitle: WebhookTranslations.MenuTitle,
  pageTitle: WebhookTranslations.WebhookListing,
  path: '/sitesettings/webhooks',
  authClaim: {
    [AuthorizationClaims.SITESETTINGS_WEBHOOKS]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
  },
  requiredFeatures: [{ feature: Features.Webhooks }],
  Component: () => import('./pages/Webhooks/List'),
};

export const createWebhook: AppRoute = {
  breadcrumbTitle: WebhookTranslations.CreateWebhook,
  menuTitle: WebhookTranslations.CreateWebhook,
  pageTitle: WebhookTranslations.CreateWebhook,
  path: '/sitesettings/webhooks/create',
  authClaim: AuthorizationClaims.SITESETTINGS_WEBHOOKS,
  Component: () => import('./pages/Webhooks/Details'),
  requiredFeatures: [{ feature: Features.Webhooks }],
  parent: webhookListing,
};

export const editWebhook: AppRoute = {
  path: '/sitesettings/webhooks/edit/:id',
  pageTitle: WebhookTranslations.Webhook,
  authClaim: AuthorizationClaims.SITESETTINGS_WEBHOOKS,
  Component: () => import('./pages/Webhooks/Details'),
  requiredFeatures: [{ feature: Features.Webhooks }],
  parent: webhookListing,
};

export const goToEditWebhookRoute = (id: Uuid) => goToRoute(editWebhook, { id });
export const goToWebhookListingRoute = () => goToRoute(webhookListing);

export const createShoppingRule: AppRoute = {
  breadcrumbTitle: ShoppingRuleTranslations.CreateShoppingRule,
  menuTitle: ShoppingRuleTranslations.CreateShoppingRule,
  pageTitle: ShoppingRuleTranslations.CreateShoppingRule,
  path: '/sitesettings/shoppingrules/create',
  authClaim: [],
  alwaysShowInDev: true,
  Component: () => import('./pages/ShoppingRules/Details'),
  requiredFeatures: [{ feature: Features.ShoppingRulesOption }],
  parent: webhookListing,
};

export const editShoppingRule: AppRoute = {
  path: '/sitesettings/shoppingrules/edit/:id',
  pageTitle: ShoppingRuleTranslations.ShoppingRule,
  authClaim: [],
  alwaysShowInDev: true,
  Component: () => import('./pages/ShoppingRules/Details'),
  requiredFeatures: [{ feature: Features.ShoppingRulesOption }],
  parent: webhookListing,
};

export const orderPrefrencesListing: AppRoute = {
  breadcrumbTitle: OrderPreferencesTranslation.OrderPreferences,
  menuTitle: OrderPreferencesTranslation.MenuTitle,
  pageTitle: OrderPreferencesTranslation.OrderPreferences,
  path: '/sitesettings/Orderpreferences',
  authClaim: {
    [AuthorizationClaims.SITESETTINGS_ORDERPREFRENCES]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
    [AuthorizationClaims.STORES_STORE]: UserAccessRights.Read,
    [AuthorizationClaims.SHOPPINGMODE_ADMIN]: UserAccessRights.Read,
  },
  Component: () => import('./pages/OrderPreferences/List'),
};

export const createOrderPreferences: AppRoute = {
  breadcrumbTitle: OrderPreferencesTranslation.createOrderPreferences,
  menuTitle: OrderPreferencesTranslation.createOrderPreferences,
  pageTitle: OrderPreferencesTranslation.createOrderPreferences,
  path: '/sitesettings/orderpreferences/create',
  authClaim: {
    [AuthorizationClaims.SITESETTINGS_ORDERPREFRENCES]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
    [AuthorizationClaims.STORES_STORE]: UserAccessRights.Read,
    [AuthorizationClaims.SHOPPINGMODE_ADMIN]: UserAccessRights.Read,
  },
  Component: () => import('./pages/OrderPreferences/Details'),
  parent: orderPrefrencesListing,
};

export const editOrderPreference: AppRoute = {
  pageTitle: OrderPreferencesTranslation.OrderPreference,
  path: '/sitesettings/orderpreferences/edit/:id',
  authClaim: {
    [AuthorizationClaims.SITESETTINGS_ORDERPREFRENCES]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
    [AuthorizationClaims.STORES_STORE]: UserAccessRights.Read,
    [AuthorizationClaims.SHOPPINGMODE_ADMIN]: UserAccessRights.Read,
  },
  Component: () => import('./pages/OrderPreferences/Details'),
  parent: orderPrefrencesListing,
};

export const goToOrderPreferencesListingRoute = () => goToRoute(orderPrefrencesListing);
export const goToEditOrderPreferencesListingRoute = (id: string) => goToRoute(editOrderPreference, { id });

export const deliverySolutions: AppRoute = {
  breadcrumbTitle: DeliveryProvidersTranslations.DeliverySolutions,
  pageTitle: DeliveryProvidersTranslations.DeliveryProviders,
  menuTitle: DeliveryProvidersTranslations.DeliverySolutions,
  path: `/sitesettings${CONST.PATH_DELIVERY_SOLUTIONS}`,
  authClaim: [AuthorizationClaims.SITESETTINGS_DELIVERYSOLUTIONS, AuthorizationClaims.SITESETTINGS_DELIVERYPROVIDERS],
  Component: () => import('./pages/DeliveryProviders'),
};

export const storefrontSettings: AppRoute = {
  breadcrumbTitle: StorefrontUiTranslations.GroupTitle,
  pageTitle: StorefrontUiTranslations.GroupTitle,
  menuTitle: StorefrontUiTranslations.Settings,
  path: `/sitesettings${CONST.PATH_STOREFRONT_SETTINGS}`,
  authClaim: [AuthorizationClaims.FULLACCESS],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/StorefrontSection/BannerSettings/StorefrontSettings'),
};
export const goToStorefrontSettingsRoute = () => goToRoute(storefrontSettings);

export const storefrontSettingsBanner: AppRoute = {
  breadcrumbTitle: StorefrontUiTranslations.Banner,
  pageTitle: StorefrontUiTranslations.EditBanner,
  path: `/sitesettings${CONST.PATH_STOREFRONT_SETTINGS}/:banner`,
  authClaim: [AuthorizationClaims.FULLACCESS],
  parent: storefrontSettings,
  Component: () => import('./pages/StorefrontSection/BannerSettings/StorefrontSettings'),
};

export const goToEditStorefrontSettingsRoute = (banner: string) => goToRoute(storefrontSettingsBanner, { banner });

export const mobileAppSettings: AppRoute = {
  breadcrumbTitle: MobileAppUiTranslations.GroupTitle,
  pageTitle: MobileAppUiTranslations.GroupTitle,
  menuTitle: MobileAppUiTranslations.Settings,
  path: `/sitesettings${CONST.PATH_MOBILE_APP_SETTINGS}`,
  authClaim: [AuthorizationClaims.FULLACCESS],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/StorefrontSection/BannerSettings/MobileAppSettings'),
};

export const goToMobileAppSettings = () => goToRoute(mobileAppSettings);

export const editMobileSettings: AppRoute = {
  breadcrumbTitle: StorefrontUiTranslations.Banner,
  pageTitle: StorefrontUiTranslations.EditBanner,
  path: `/sitesettings${CONST.PATH_MOBILE_APP_SETTINGS}/:banner`,
  authClaim: [AuthorizationClaims.FULLACCESS],
  parent: mobileAppSettings,
  Component: () => import('./pages/StorefrontSection/BannerSettings/MobileAppSettings'),
};

export const goToEditMobileSettings = (banner: string) => goToRoute(editMobileSettings, { banner });

export const storefrontAndMobileAppSettings: AppRoute = {
  breadcrumbTitle: SharedSettingsTranslations.GroupTitle,
  pageTitle: SharedSettingsTranslations.GroupTitle,
  menuTitle: SharedSettingsTranslations.Settings,
  path: `/sitesettings${CONST.PATH_SHARED_APP_SETTINGS}`,
  authClaim: [AuthorizationClaims.FULLACCESS],
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/StorefrontSection/BannerSettings/SharedSettings'),
};

export const goToStorefrontAndMobileAppSettings = () => goToRoute(storefrontAndMobileAppSettings);

export const editStorefrontAndMobileSettings: AppRoute = {
  breadcrumbTitle: StorefrontUiTranslations.Banner,
  pageTitle: StorefrontUiTranslations.EditBanner,
  path: `/sitesettings${CONST.PATH_SHARED_APP_SETTINGS}/:banner`,
  authClaim: [AuthorizationClaims.FULLACCESS],
  parent: storefrontAndMobileAppSettings,
  Component: () => import('./pages/StorefrontSection/BannerSettings/SharedSettings'),
};

export const goToEditStorefrontAndMobileSettings = (banner: string) =>
  goToRoute(editStorefrontAndMobileSettings, { banner });

export const storefrontThemes: AppRoute = {
  breadcrumbTitle: StorefrontUiTranslations.Themes,
  pageTitle: StorefrontUiTranslations.GroupTitle,
  menuTitle: StorefrontUiTranslations.Themes,
  path: `${CONST.PATH_STOREFRONT_THEMES}`,
  authClaim: [],
  Component: () => import('./pages/StorefrontSection/StorefrontThemes'),
};
export const editStorefrontThemes: AppRoute = {
  path: `${CONST.PATH_STOREFRONT_THEMES_EDIT_FULLPATH}/:documentId/variants/:variantId`,
  pageTitle: StorefrontUiTranslations.Themes,
  authClaim: [],
  Component: () => import('./pages/StorefrontSection/StorefrontThemes/SingleThemePage'),
  parent: storefrontThemes,
};
export const storefrontImages: AppRoute = {
  breadcrumbTitle: StorefrontUiTranslations.Images,
  pageTitle: StorefrontUiTranslations.GroupTitle,
  menuTitle: StorefrontUiTranslations.Images,
  path: `${CONST.PATH_STOREFRONT_IMAGES}`,
  authClaim: [],
  Component: () => import('./pages/StorefrontSection/StorefrontImages'),
};
export const editStorefrontImages: AppRoute = {
  path: `${CONST.PATH_STOREFRONT_IMAGES_EDIT_FULLPATH}/:documentId/variants/:variantId`,
  pageTitle: StorefrontUiTranslations.Images,
  authClaim: [],
  Component: () => import('./pages/StorefrontSection/StorefrontImages/SingleThemePage'),
  parent: storefrontImages,
};

export const stsSettings: AppRoute = {
  breadcrumbTitle: StsTranslations.Settings,
  pageTitle: StsTranslations.GroupTitle,
  menuTitle: StsTranslations.Settings,
  path: `${CONST.PATH_STS_SETTINGS}`,
  authClaim: [],
  Component: () => import('./pages/StsSection/StsSettings'),
};
export const stsImages: AppRoute = {
  breadcrumbTitle: StsTranslations.Images,
  pageTitle: StsTranslations.GroupTitle,
  menuTitle: StsTranslations.Images,
  path: `${CONST.PATH_STS_IMAGES}`,
  authClaim: [],
  Component: () => import('./pages/StsSection/StsImages'),
};
export const stsTheme: AppRoute = {
  breadcrumbTitle: StsTranslations.Themes,
  pageTitle: StsTranslations.GroupTitle,
  menuTitle: StsTranslations.Themes,
  path: `${CONST.PATH_STS_THEME}`,
  authClaim: [],
  Component: () => import('./pages/StsSection/StsTheme'),
};

export const allSegments: AppRoute = {
  breadcrumbTitle: SegmentsTranslations.AllSegmentsBreadCrumb,
  pageTitle: SegmentsTranslations.AllSegmentsPageTitle,
  menuTitle: SegmentsTranslations.AllSegmentsMenuTitle,
  path: `${CONST.PATH_ALL_SEGMENTS}`,
  authClaim: [AuthorizationClaims.SITESETTINGS_SEGMENTATION],
  requiredFeatures: [{ feature: Features.Segmentation }],
  Component: () => import('./pages/Segments/AllSegments'),
};

export const createSegment: AppRoute = {
  breadcrumbTitle: SegmentsTranslations.CreateSegmentBreadCrumb,
  pageTitle: SegmentsTranslations.CreateSegmentPageTitle,
  menuTitle: SegmentsTranslations.CreateSegmentMenuTitle,
  path: `${CONST.PATH_CREATE_SEGMENT}`,
  authClaim: [AuthorizationClaims.SITESETTINGS_SEGMENTATION],
  parent: allSegments,
  requiredFeatures: [{ feature: Features.Segmentation }],
  Component: () => import('./pages/Segments/ManageSegment/ManageSegment'),
};

export const editSegment: AppRoute = {
  breadcrumbTitle: SegmentsTranslations.EditSegmentBreadCrumb,
  pageTitle: SegmentsTranslations.EditSegmentPageTitle,
  menuTitle: SegmentsTranslations.EditSegmentMenuTitle,
  path: `${CONST.PATH_EDIT_SEGMENT}`,
  authClaim: [AuthorizationClaims.SITESETTINGS_SEGMENTATION],
  parent: allSegments,
  requiredFeatures: [{ feature: Features.Segmentation }],
  Component: () => import('./pages/Segments/ManageSegment/ManageSegment'),
};

export const goToSegmentsListRoute = () => goToRoute(allSegments);

export const goToEditSegmentRoute = (id: string) => goToRoute(editSegment, { segmentId: id });

export const goToIdentityProvidersListRoute = () => goToRoute(identityProviders);

export const siteSettingsRoutes: AppRoute[] = [
  identityProviders,
  editIdentityProvider,
  createIdentityProvider,
  deliverySolutions,
  createWebhook,
  editWebhook,
  webhookListing,
  createShoppingRule,
  editShoppingRule,
  orderPrefrencesListing,
  createOrderPreferences,
  editOrderPreference,
  storefrontSettings,
  storefrontSettingsBanner,
  mobileAppSettings,
  editMobileSettings,
  storefrontAndMobileAppSettings,
  editStorefrontAndMobileSettings,
  allSegments,
  createSegment,
  editSegment,
];

const storefrontImagesRoute = featureFlag({
  support: () => storefrontImages,
});

if (storefrontImagesRoute !== undefined) {
  siteSettingsRoutes.push(storefrontImagesRoute);
}
const storefrontThemesRoute = featureFlag({
  support: () => storefrontThemes,
});

if (storefrontThemesRoute !== undefined) {
  siteSettingsRoutes.push(storefrontThemesRoute);
}

const storefrontImagesEditRoute = featureFlag({
  support: () => editStorefrontImages,
});

if (storefrontImagesRoute !== undefined) {
  siteSettingsRoutes.push(storefrontImagesEditRoute);
}

const storefrontThemeEditRoute = featureFlag({
  support: () => editStorefrontThemes,
});

if (storefrontThemeEditRoute !== undefined) {
  siteSettingsRoutes.push(storefrontThemeEditRoute);
}

const stsSettingsRoute = featureFlag({
  support: () => stsSettings,
});

if (stsSettingsRoute !== undefined) {
  siteSettingsRoutes.push(stsSettingsRoute);
}

const stsImagesRoute = featureFlag({
  support: () => stsImages,
});

if (stsImagesRoute !== undefined) {
  siteSettingsRoutes.push(stsImagesRoute);
}

const stsThemeRoute = featureFlag({
  support: () => stsTheme,
});

if (stsThemeRoute !== undefined) {
  siteSettingsRoutes.push(stsThemeRoute);
}
