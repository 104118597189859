import classNames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { SwitchPrimitiveTypes } from './SwitchPrimitive.interface';
// eslint-disable-next-line import/no-cycle
import { LabelPrimitive } from '..';
import './_style.scss';

const clx = prefixClassName('switch');

export const SwitchPrimitive = (props: SwitchPrimitiveTypes) => {
  const {
    value = false,
    disabled = false,
    readOnly = false,
    name,
    className,
    onBlur,
    onChange,
    onFocus,
    testid,
    id,
  } = props;
  const isClickable = !disabled && !readOnly;
  let isChecked = value;
  const checkValue = Array.isArray(value) ? value[0] : value;
  if ((checkValue as any) === 'true') {
    isChecked = true;
  } else if ((checkValue as any) === 'false') {
    isChecked = false;
  }

  return (
    <div
      className={classNames(
        clx,
        isChecked && `${clx}--checked`,
        readOnly && `${clx}--readOnly`,
        disabled && `${clx}--disabled`,
        className
      )}
      id={id || `${clx}--${name}`}
    >
      <input
        id={name}
        className={`${clx}__cb`}
        disabled={!isClickable}
        name={name}
        type="checkbox"
        checked={isChecked}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        data-testid={testid}
      />
      <LabelPrimitive name={name} className={`${clx}__rail`} />
    </div>
  );
};

export default SwitchPrimitive;
