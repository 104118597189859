/* eslint-disable  */
const production = !process || process.env.NODE_ENV === 'production';

const win: any = window;
const { __mi9__: buildData } = win;
const baseAuthUrl = production ? win.baseAuthUrl : process.env.REACT_APP_BASE_AUTH_URL;
const baseAppUrl = production ? win.baseAppUrl : process.env.REACT_APP_BASE_APP_URL;
export const baseApiUrl = production ? win.baseApiUrl : process.env.REACT_APP_BASE_API_URL;
const baseUrl = production ? win.baseUrl : process.env.REACT_APP_BASE_URL;
export const config = {
  apiEndpoints: {
    api: `${baseApiUrl}/api`,
    orders: `${baseApiUrl}/api/orders`,
    orderHistory: `${baseApiUrl}/api/orderhistory`,
    products: {
      base: `${baseApiUrl}/api/products/`,
      list: `${baseApiUrl}/api/products/query`,
      attributes: `${baseApiUrl}/api/attributes`,
      shoppingRules: `${baseApiUrl}/api/shoppingrules/options`,
      resendProducts: `${baseApiUrl}/api/brandbank/resendproducts`,
      brandbankRulesEvaluator: `${baseApiUrl}/api/brandbank/evaluaterules/skus`,
      pricing: `${baseApiUrl}/api/pricing`,
      customizations: `${baseApiUrl}/api/product-customizations`,
    },
    productslist: `${baseApiUrl}/api/products/query`,
    promotions: `${baseApiUrl}/api/promotions`,
    categories: `${baseApiUrl}/api/categories`,
    options: `${baseApiUrl}/api/search/relevance/options`,
    searchExplanation: `${baseApiUrl}/api/search/explanation`,
    identityProviders: `${baseApiUrl}/api/identityproviders`,
    roles: `${baseApiUrl}/api/roles`,
    search: `${baseApiUrl}/api/search`,
    searchRelevance: `${baseApiUrl}/api/search/relevance`,
    searchRules: {
      get: `${baseApiUrl}/api/search/locale/{localeId}/store/{storeId}/rules/{ruleId}`,
      update: `${baseApiUrl}/api/search/locale/{localeId}/rules/{ruleId}`,
      conflicts: `${baseApiUrl}/api/search/rules/conflicts?localeId={localeId}`,
    },
    recommendations: `${baseApiUrl}/api/v2/recommendations`,
    listings: `${baseApiUrl}/api/v2/listings`,
    recipes: {
      list: `${baseApiUrl}/api/locale/{localeId}/recipes`,
      get: `${baseApiUrl}/api/locales/{localeId}/recipes/{recipeId}`,
    },
    synonyms: `${baseApiUrl}/api/search/synonyms`,
    users: `${baseApiUrl}/api/users`,
    cms: `${baseApiUrl}/api/cms`,
    cmsDocuments: `${baseApiUrl}/api/v2/cms`,
    sites: `${baseApiUrl}/api/sites`,
    slotCalendar: `${baseApiUrl}/api/TimeSlotsCalendar`,
    slotTemplates: `${baseApiUrl}/api/timeslots`,
    slotTemplatesv2: `${baseApiUrl}/api/v2/timeslots`,
    stores: `${baseApiUrl}/api/stores`,
    storeattributes: `${baseApiUrl}/api/storeattributes`,
    storeDeliveryAreas: `${baseApiUrl}/api/store`,
    orghierarchies: `${baseApiUrl}/api/orghierarchy`,
    orderfulfillment: {
      base: `${baseApiUrl}/api/order-fulfillment`,
      orders: `${baseApiUrl}/api/order-fulfillment/orders`,
      ordersCount: `${baseApiUrl}/api/orders/customerIds/{{customerId}}/count`,
      settings: `${baseApiUrl}/api/order-fulfillment/fulfillment-centers`,
      retailer: `${baseApiUrl}/api/order-fulfillment/retailer`,
      toteLabels: `${baseApiUrl}/api/order-fulfillment/labels/tote/exist`,
      waveLabels: `${baseApiUrl}/api/order-fulfillment/labels/tote/exist-for-wave`,
      downloadToteLabels: `${baseApiUrl}/api/order-fulfillment/labels/tote/pdf`,
      downloadToteLabelsByWave: `${baseApiUrl}/api/order-fulfillment/labels/tote-by-wave/pdf`,
      oversizedLabels: `${baseApiUrl}/api/order-fulfillment/labels/oversized/exist`,
      waveOversizedLabels: `${baseApiUrl}/api/order-fulfillment/labels/oversized/exist-for-wave`,
      downloadOversizedLabels: `${baseApiUrl}/api/order-fulfillment/labels/oversized/pdf`,
      downloadOversizedLabelsByWave: `${baseApiUrl}/api/order-fulfillment/labels/oversized-by-wave/pdf`,
      waves: `${baseApiUrl}/api/order-fulfillment/waves/fulfillment-centers`,
      wavesQuery: `${baseApiUrl}/api/order-fulfillment/waves/waves-dashboard-query`,
      waveDetails: `${baseApiUrl}/api/order-fulfillment/waves/wave-dashboard-details`,
      fulfillmentCenters: `${baseApiUrl}/api/order-fulfillment/fulfillment-centers`,
      locationsSchema: `${baseApiUrl}/api/order-fulfillment/fulfillment-centers/product-locations-schema`,
      locationsSchemaDefaults: `${baseApiUrl}/api/order-fulfillment/fulfillment-centers/product-locations-schema/default`,
      mpsVersion: `${baseApiUrl}/api/order-fulfillment/download/mps/version`,
      hasProductSearch: `${baseApiUrl}/api/order-fulfillment/is-product-search-enabled`,
      fulfillmentCentersQuery: `${baseApiUrl}/api/order-fulfillment/fulfillment-centers/query`,
      fulfillmentExternalSites: `${baseApiUrl}/api/order-fulfillment/external-sites`,
    },
    bulkPrinting: {
      printOrderSummaryList: `${baseApiUrl}/api/document-storage`,
      removeFromOrderList: `${baseApiUrl}/api/document-storage/mark-documents-as-skipped`,
      markAsPrinted: `${baseApiUrl}/api/document-storage/mark-documents-as-printed`,
      printDocument: `${baseApiUrl}/api/document-storage/download-documents.pdf`,
    },
    productLocations: {
      locations: `${baseApiUrl}/api/order-fulfillment/fulfillment-centers/`,
      sectors: `${baseApiUrl}/api/v2/order-fulfillment/fulfillment-centers/`,
    },
    coupons: {
      providers: `${baseApiUrl}/api/coupons/providers`,
      assigned: `${baseApiUrl}/api/stores/{{id}}/attributes`,
      find: `${baseApiUrl}/api/storeattributes/find/`,
    },
    deliverySolutions: {
      integration: `${baseApiUrl}/api/deliverysolutions/fulfillmentCenterSetting/`,
      checkService: `${baseApiUrl}/api/deliverysolutions/test-connection`,
      checkEnabled: `${baseApiUrl}/api/deliverysolutions/enabled`,
      storeIntegration: `${baseApiUrl}/api/deliverysolutions/fulfillmentCenterSetting/stores`,
    },
    zetesIntegration: {
      integration: `${baseApiUrl}/api/deliveryintegration/providers/zetes/fulfillmententers/{fulfillmentCenterId}/settings`,
      checkEnabled: `${baseApiUrl}/api/deliveryintegration/providers/zetes/configuration`,
    },
    deliveryIntegration: {
      checkProviders: `${baseApiUrl}/api/deliveryintegration/providers`,
    },
    providerConfiguration: `${baseApiUrl}/api/store/{storeId}/providersconfigurations`,
    featureFlags: `${baseApiUrl}/api/featureflags`,
    referencedata: {
      countries: `${baseApiUrl}/api/referencedata/countries`,
      currencies: `${baseApiUrl}/api/referencedata/currencies`,
      timezones: `${baseApiUrl}/api/referencedata/timezones`,
      languages: `${baseApiUrl}/api/referencedata/languages`,
    },
    customerCredits: `${baseApiUrl}/api/customercredits`,
    customers: `${baseApiUrl}/api/customers`,
    customerCreditsBalance: `${baseApiUrl}/api/customercredits/{customerId}/getbalance`,
    customerCreditsAllocate: `${baseApiUrl}/api/customercredits/{customerId}/allocate`,
    customerCreditsJournal: `${baseApiUrl}/api/customercredits/{customerId}/getjournal`,
    shoppingModes: `${baseApiUrl}/api/shoppingmodes`,
    StoreShoppingModes: `${baseApiUrl}/api/storeshoppingmodes`,
    reporting: `${baseApiUrl}/api/reporting`,
  },
  dataGridClientSidePagingPageSize: 100,
  dataGridDefaultPageSize: 10,
  dataGridPageSizeOptions: ['10', '20', '50'],
  defaultPageTitle: 'v8 MyWebGrocer Admin',
  MPSApkUrl: `${baseApiUrl}/api/order-fulfillment/download/mps/app-release.apk`,
  identityserver: {
    client_id: 'mwg.ecm.adminui',
    client_secret: 'secret',
    authority: baseAuthUrl,
    post_logout_redirect_uri: baseAppUrl,
    redirect_uri: `${baseAppUrl}/callback`,
    silent_redirect_uri: `${baseAppUrl}/silent_renew/silent_renew.html`,
    response_type: 'token id_token',
    scope: 'mwg.ecm.adminui.apigateway:all openid claims profile email',
  },
  baseUrl,
  externalLinks: {
    homepage: {
      help: 'https://commercedocumentation.mywebgrocer.com/User-Guides.htm',
      feedback: 'mailto:techpubs@mi9retail.com',
    },
  },
  buildNumber: buildData?.buildNumber,
};

export const DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = `${DATE_FORMAT} - ${TIME_FORMAT}`;
export const DATETIME_FORMAT_OF = `${TIME_FORMAT} - ${DATE_FORMAT}`;
export const TIMESLOT_DATETIME_FORMAT = `${TIME_FORMAT} ${DATE_FORMAT}`;
export const DEFAULT_STORE_ID = 'global';
export const DEFAULT_LOCALE_ID = '9';
export const locales = [{ value: DEFAULT_LOCALE_ID, label: 'en' }];
export const LOCAL_USER_SETTINGS = 'localUserSettings';
export const DOCS_URL = "https://docs.wynshop.com";
/**
 * Use this function to validate keys that come from the build process.
 * They ensure that the keys are set with a correct value.
 * @param obj
 */
export const isValidExternalKey = (obj: object) =>
  Object.keys(obj).every((key) => obj[key] && [`%%${key}%%`, 'placeholder'].indexOf(obj[key]) === -1);

type RouteParamType = string | number | boolean;
interface RouteOptions {
  [param: string]: RouteParamType;
}

const replaceRouteParam = (path: string, key: string, value: RouteParamType) =>
  path.replace(new RegExp(`{${key}}`, 'g'), `${value}`);

const getRouteUrl = (url: string, routeParams: RouteOptions = {}) => {
  const replacedUrl = Object.keys(routeParams).reduce(
    (path, param) => replaceRouteParam(path, param, routeParams[param]),
    url
  );

  return replacedUrl;
};

/**
 * Creates the api path adn replaces the url params, {localeId} will be replaced by default if not provided
 * @param path path to api
 * @param params params to be replaced
 */
export const getApiUrl = (path: string, params: RouteOptions = {}, includeApi: boolean = true) => {
  const allParams = {
    localeId: DEFAULT_LOCALE_ID,
    locale: DEFAULT_LOCALE_ID,
    ...params,
  };

  // adds '/' as the first char if it wasnt passed in, replaces all params that match {paramName}
  const replacedPath = `${path[0] !== '/' ? '/' : ''}${getRouteUrl(path, allParams)}`;

  return `${baseApiUrl}${includeApi ? '/api' : ''}${replacedPath}`;
};

// OF local storage key
export const ACTIVE_FULFILLMENT_CENTER = 'ACTIVE_FULFILLMENT_CENTER';
export const ACTIVE_SLOTMANAGEMENT_STORE = 'ACTIVE_SLOTMANAGEMENT_STORE';
export const IGNORE_LOCAL_FC = 'IGNORE_LOCAL_FC';

export default config;
