import withStencils from 'framework/components/hoc/withStencils';
import * as React from 'react';
import { Form, Modal, SearchBar, Table } from 'ui';
import { ProductScore, ProductSearchResult } from '../../models/product';
import { openProductModal, ProductModal } from '../ProductModal/ProductModal';
import { ProductRow } from '../ProductRow/ProductRow';
import CONST from './constants';

const StencilledTable = withStencils('data-grid')(Table);

interface ModalProductSearchProps {
  name: string;
  isFetching?: boolean;
  data?: {
    items: ProductScore[];
  };
  previewStoreId?: string;
  selected: { productId: string }[];
  formDataValues?: { searchForProducts: string };
  searchProducts?: ({ searchTerm: string }) => void;
  onSelectProducts: (products: ProductScore[]) => void;
  closeModal: () => void;
}

// TODO Add Select All checkbox column
export default (props: ModalProductSearchProps) => {
  const {
    name,
    isFetching,
    data,
    formDataValues,

    // Actions
    searchProducts,
    previewStoreId,
    selected = [],
    onSelectProducts = () => null,
    closeModal,
  } = props;

  const [selectedProducts, setSelectedProducts] = React.useState(
    selected.reduce((c, p) => ({ ...c, [p.productId]: p }), {})
  );
  const [viewedProduct, setViewedProduct] = React.useState<ProductSearchResult>(null);

  const toggleProduct = (product: ProductScore) => {
    const newSelection = {
      ...selectedProducts,
    };

    if (newSelection[product.productId]) {
      newSelection[product.productId] = undefined;
      delete newSelection[product.productId];
    } else {
      newSelection[product.productId] = product;
    }

    setSelectedProducts(newSelection);
  };

  const onSearchHandler = () => {
    searchProducts({ searchTerm: formDataValues.searchForProducts });
  };

  const handleCloseModal = () => {
    searchProducts({ searchTerm: '' });
    closeModal();
  };

  const onSelectClickHandler = () => {
    onSelectProducts(Object.keys(selectedProducts).map((k) => selectedProducts[k]));
    handleCloseModal();
  };

  const viewProduct = (e: React.MouseEvent, product: ProductSearchResult) => {
    e.preventDefault();
    setViewedProduct(product);
    openProductModal();
  };

  const hasSelections = Object.keys(selectedProducts).length > 0;

  React.useEffect(() => {
    setSelectedProducts(selected.reduce((c, p) => ({ ...c, [p.productId]: p }), {}));
  }, [selected]);

  return (
    <>
      {viewedProduct && <ProductModal sku={viewedProduct?.sku} storeId={previewStoreId} />}
      <Modal
        caption="modules.merchandising.components.productModalSearch.titleDefault"
        name={name}
        messages={[
          {
            showLabel: false,
            caption: 'modules.merchandising.components.productModalSearch.message_01',
          },
        ]}
        footerOptions={[
          {
            caption: 'generic.button.select',
            onClick: onSelectClickHandler,
            disabled: !hasSelections,
          },
          {
            caption: 'generic.button.cancel',
            onClick: handleCloseModal,
            outline: true,
          },
        ]}
      >
        <Form name={CONST.FORM_NAME}>
          <SearchBar
            className="mrg-btm-20"
            name="searchForProducts"
            placeholder="modules.merchandising.components.productModalSearch.inputPlaceholder"
            searchFunction={onSearchHandler}
          />
        </Form>
        <StencilledTable
          tableName={CONST.DATAGRID_NAME}
          data={data?.items || []}
          stencilled={isFetching}
          columnMetadata={[Table.EmptyHeader, 'generic.dataGrid.colImage', Table.EmptyHeader]}
          cellRenderer={(_t, p: ProductScore) => (
            <ProductRow
              product={p}
              selectable
              onChange={toggleProduct}
              selected={!!selectedProducts[p.productId]}
              selectProduct={viewProduct}
              key={p.productId}
            />
          )}
        />
      </Modal>
    </>
  );
};
