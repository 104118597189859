/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import * as React from 'react';
import * as uniqid from 'uniqid';
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import { Button, FilterField, Form } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { LoadingSpinner } from 'framework/modules/contentmanagement/SharedContent/Helpers-Ui';
import { ListItem } from '..';
import { DualSelectListBoxProps } from './ListBox.interfaces';
import { IDualSelectItem } from '../../DualSelect.interfaces';

const _defaultClass = prefixClassName('dualselect');

export const DualSelectListBox = (props: DualSelectListBoxProps) => {
  const {
    items,
    name,
    position,
    itemsCaption,
    allItemsCaption,
    onItemsMoved,
    isLoading,
    onFilter,
  } = props;

  const { setValue } = useForm(`${name}-filter`);

  const [highlighted, setHighlighted] = React.useState<IDualSelectItem[]>([]);

  const handleHighlight = (selectedItems: IDualSelectItem[]) => setHighlighted(selectedItems);

  // multi select item while user is pressing ctrl
  const doSelectWithCtrlKey = (item: IDualSelectItem) => {
    if (highlighted.findIndex((t) => t.name === item.name) === -1) {
      handleHighlight([...highlighted, item]);
    }
  };

  // select a range of item when user has click shift + click
  const doSelectWithShiftKey = (newHighlight: IDualSelectItem) => {
    let selectedItems = [];
    const lastHighlight = highlighted ? highlighted[0] : null;

    if (!lastHighlight) {
      selectedItems = [newHighlight];
      handleHighlight(selectedItems);
    } else {
      const bound1 = items.findIndex((t) => t.name === lastHighlight.name);
      const bound2 = items.findIndex((t) => t.name === newHighlight.name);

      // // set correct order of boundaries for range
      const min = Math.min(bound1, bound2);
      const max = Math.max(bound1, bound2);

      const highlightedItems = items.filter((t, i) => min <= i && i <= max);

      handleHighlight(highlightedItems);
    }
  };

  // handle user click event
  const onHighlightItems = (e: any = {}, item: IDualSelectItem) => {
    const isMultiple = !!e.ctrlKey || !!e.metaKey;
    const isShiftSelect = !!e.shiftKey;

    if (isMultiple) {
      // hande ctrl + click
      doSelectWithCtrlKey(item);
    } else if (isShiftSelect) {
      // handle shift + click
      doSelectWithShiftKey(item);
    } else {
      // handle normal click
      handleHighlight([item]);
    }
  };

  const handleMoveItems = () => {
    // move only highlighted items
    const itemsToMove = items.filter((t) => highlighted.findIndex((v) => v.name === t.name) > -1);
    onItemsMoved(itemsToMove);
    setHighlighted([]);
  };

  const handleMoveAllItems = () => {
    // check for items with accessible attribute and filter it and move all the others
    const itemsToMove = items.filter(t => t.accessible );
    onItemsMoved(itemsToMove);
    setHighlighted([]);
    if (position === 'right') {
      setValue(`${name}-filter-input`, '');
      onFilter('');
    }
  };

  // check if any item has accesible true value 
  const checkIfAnyItemHasAccesible = (roleItems : IDualSelectItem[]):boolean => roleItems.some(element => element.accessible);
  const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const sortedItems = [...items].sort(sortByName);

  let hasHighlighted = false;

  return (
    <div className={`${_defaultClass}__box`}>
      {onFilter && (<Form
        name={`${name}-filter`}
      >
        <FilterField
          name={`${name}-filter-input`}
          onFilter={onFilter}
          placeholder="modules.siteadmin.createUserPage.filter"
          clearable
          disabled={isLoading}
        />
      </Form>)}
      <div className={`${_defaultClass}__list`} role="menu">
        {isLoading ? (<LoadingSpinner visible size="lg" />)
          : sortedItems.map((item, i) => {
            const isHighlighted = highlighted.findIndex(
              (t) => t.name === item.name,
            ) > -1;
            if (isHighlighted) {
              hasHighlighted = true;
            }
            return (
                <ListItem
                  key={uniqid()}
                  index={i}
                  onClick={(e) => onHighlightItems(e, item)}
                  caption={item.name}
                  className={`${_defaultClass}__list__item`}
                  isHighlighted={isHighlighted}
                  disabled={!item.accessible}
                />
            );
          })}
      </div>
      <div className={`${_defaultClass}__list__actions`}>
        <div className={`${_defaultClass}__list__actions__btn`}>
          <Button
            outline
            caption={itemsCaption}
            icon={position === 'left' ? 'dropdownArrowRight' : 'dropdownArrowLeft'}
            iconPosition={position === 'left' ? 'right' : 'left'}
            onClick={handleMoveItems}
            disabled={!items.length || !hasHighlighted}
          />
        </div>
        <div className={`${_defaultClass}__list__actions_btn`}>
          <Button
            outline
            caption={allItemsCaption}
            icon={position === 'right' ? 'dropdownArrowLeft' : 'dropdownArrowRight'}
            iconPosition={position === 'left' ? 'right' : 'left'}
            onClick={handleMoveAllItems}
            disabled={!items.length || !checkIfAnyItemHasAccesible(items)}
          />
        </div>
      </div>
    </div>
  );
};
