import * as React from 'react';
import { Text } from 'ui';
import * as conf from 'framework/components/ui/_conf';
import clx from 'classnames';
import { DualSelectListBox } from './components/ListBox/ListBox';
import { IDualSelectInternalProps, IDualSelectItem } from './DualSelect.interfaces';
import './_style.scss';

const defaultClass = conf.prefixClassName('dualselect');

export const DualSelect = (props: IDualSelectInternalProps) => {
  const {
    availableItems,
    name,
    onAddItems,
    onRemoveItems,
    className,
    keyId,
    keyCaption,
    addItemsCaption,
    addAllItemsCaption,
    addAllFilteredItemsCaption,
    onFilter,
    assignedItems,
    removeItemsCaption,
    removeAllItemsCaption,
    removeAllFilteredItemsCaption,
    instructions,
    isLoading,
  } = props;

  const [localAssignedItems, setLocalAssignedItems] = React.useState<IDualSelectItem[]>([...assignedItems]);
  const [isFiltered, setIsFiltered] = React.useState<{left: boolean, right: boolean}>({ left: false, right: false });

  const filterItems = (filterBy: string) => {
    onFilter(filterBy);

    setIsFiltered({ ...isFiltered, left: filterBy?.length !== 0 });
  };

  const filterLocalAssignedItems = (filterBy: string) => {
    setLocalAssignedItems(
      filterBy?.length === 0 ? assignedItems : assignedItems.filter(
        (item) => item.name.toLowerCase().includes(filterBy.toLowerCase()),
      ),
    );

    setIsFiltered({ ...isFiltered, right: filterBy?.length !== 0 });
  };

  React.useEffect(() => {
    setLocalAssignedItems([...assignedItems]);
  }, [assignedItems]);

  return (
    <>
      <div className={clx(defaultClass, className)}>
        <DualSelectListBox
          name={`${name}.left`}
          items={availableItems}
          keyId={keyId}
          keyCaption={keyCaption}
          itemsCaption={addItemsCaption}
          allItemsCaption={!isFiltered.left ? addAllItemsCaption : addAllFilteredItemsCaption}
          position="left"
          onItemsMoved={onAddItems}
          onFilter={filterItems}
          isLoading={isLoading}
        />

        <DualSelectListBox
          name={`${name}.right`}
          items={localAssignedItems}
          keyId={keyId}
          keyCaption={keyCaption}
          itemsCaption={removeItemsCaption}
          allItemsCaption={!isFiltered.right ? removeAllItemsCaption : removeAllFilteredItemsCaption}
          position="right"
          onItemsMoved={onRemoveItems}
          onFilter={filterLocalAssignedItems}
          isLoading={isLoading}
        />
      </div>
      <p className={`${defaultClass}__instructions`}>
        <Text caption={instructions} />
      </p>
    </>
  );
};
