import { ContentManagementReduxState } from 'framework/modules/contentmanagement/contentmanagement.interfaces';
import { SaveLayoutArgs } from './Interfaces';

export const layoutReducer = (
  state: ContentManagementReduxState,
  action: {
    type: string;
    payload: SaveLayoutArgs;
  }
) => {
  const {
    payload: { type: layout },
  } = action;
  const { rowEditorData } = state;

  return {
    ...state,
    rowEditorData: {
      ...rowEditorData,
      settings: {
        ...rowEditorData.settings,
        mobileLayout: layout,
      },
    },
  };
};
