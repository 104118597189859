import * as React from 'react';
import {
  Logo, Icon, Link, Text,
} from 'ui';
import config from 'config';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { GaActions } from 'framework/applicationMonitoring/interface';
import { featuresState } from 'stores/features';
import { view } from '@risingstack/react-easy-state';
import { prefixClassName } from '../ui/_conf';
import { NavigationSubNav } from './NavigationSubNav';
import { getRoutePath } from './service';
import './styles.scss';
import { useNavigation } from './UseNavigation';

const version = config.buildNumber;
const navigationClass = prefixClassName('navigation');
const menuClass = prefixClassName('navmenu');

export const Navigation = view((props: RouteComponentProps) => {
  const [activeMenu, setActiveMenu] = React.useState(-1);
  const { authorisedModules } = useNavigation(featuresState);
  const { location: { pathname = '' } } = props;

  const openSubMenu = (e: React.MouseEvent<Element, MouseEvent>, index: number) => {
    if (e) {
      e.preventDefault();
    }
    setActiveMenu(index);
  };
  const openSubMenuClick: any = { onClick: openSubMenu };
  const subMenuOpened = activeMenu > -1;
  const selectedAppModule = authorisedModules[activeMenu];

  return (
    <div className={navigationClass}>
      <div className={`${navigationClass}__Container`}>
        <div className={`${navigationClass}__Main`}>
          <Logo />
          {authorisedModules.map((mod, i) => {
            const menuIsOpened = activeMenu === i;
            return (
              <Link
                key={i}
                className={classNames({
                  [menuClass]: true,
                  [`${menuClass}--opened`]: menuIsOpened,
                })}
                to={getRoutePath(mod.modulePath)}
                caption={mod.appMenuTitle}
                captionAfterChildren
                onClick={(e) => {
                  ApplicationMonitoring.trackEvent(pathname, GaActions.NavigationMenu, mod.appMenuTitle);
                  openSubMenu(e, menuIsOpened ? -1 : i);
                }}
              >
                <Icon name={mod.appMenuIcon as any} className={`${menuClass}__icon`} />
              </Link>
            );
          })}
          <div className={`${menuClass}__versionContainer`}>
            <Text tag="div" className={`${menuClass}__versionContainer--version`} caption="Version" />
            <Text tag="div" className={`${menuClass}__versionContainer--versionNumber`} caption={`${version}`}/>
          </div>
        </div>
      </div>
      <NavigationSubNav
        appModule={selectedAppModule}
        isOpened={subMenuOpened}
        onSelectNav={(e) => openSubMenu(e, -1)}
      />

      <div
        {...openSubMenuClick}
        className={classNames(`${navigationClass}__overlay`, {
          [`${navigationClass}__overlay--opened`]: activeMenu > -1,
        })}
      />
    </div>
  );
});

export default withRouter(Navigation);
