import { autoEffect, clearEffect, store } from '@risingstack/react-easy-state';
import type { Locale } from 'date-fns';
import * as dateLocaleObj from 'date-fns/locale';

export interface LocaleStore {
  localeId: string;
  shortLocaleId: string;
  dateLocale: Locale;
}

const localStore = store<LocaleStore>({} as LocaleStore);

export const setLocale = (localeId: string) => {
  localStore.localeId = localeId;
  localStore.shortLocaleId = localeId.replace('-', ''); // removes the '-' if exists
  localStore.dateLocale = dateLocaleObj[localStore.shortLocaleId] || dateLocaleObj.enUS;
};

setLocale(navigator.languages[0] || 'en-US');// gets the user's preferred locale

export const localeStore: Readonly<LocaleStore> = localStore;

export const onLocaleChange = (fn: () => void) => {
  const effect = autoEffect(fn, [localeStore.localeId]);
  return () => clearEffect(effect);
};
