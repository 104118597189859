import * as React from 'react';
import { Form, Switch, TagsField } from 'ui';
import * as isEqual from 'lodash.isequal';
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import { useEffectSkipFirstRun } from 'helpers/hooks';
import ContactSupport from '../ContactSupport';
import { IStoreTypeInterface } from '../../StoreFrontSettings.interfaces';

const StoreType = (props: IStoreTypeInterface) => {
  const { value, id, handleChange, formName, field, bypassValueCheck, component } = props;

  const STORE_IDS_FORM = 'STORE_IDS_FORM';
  const hiddenFieldName: string = `${id}-${field}-hidden`;

  const { changeValue, reset } = useForm(formName);

  const [originalValue, setOriginalValue] = React.useState<string[]>([...value]);
  const [selectedStoreIds, setSelectedStoreIds] = React.useState<string[]>(value);

  const hasValue = (value !== undefined && value !== null) || bypassValueCheck;

  const handleStoresChange = (stores?: string[], overrideIds?: string[]) => {
    const storeRetailerIds: string[] = overrideIds ?? stores;
    handleChange(field, storeRetailerIds);
  };

  useEffectSkipFirstRun(() => {
    if (!isEqual(originalValue, value)) {
      changeValue(hiddenFieldName, true, true);
      setOriginalValue(value);
    } else {
      reset();
    }

    setSelectedStoreIds(value);
  }, [value]);

  return (
    <>
      {!hasValue && <ContactSupport />}
      {/* Hidden Switch to handle save/reset button in footer when selected stores change */}
      <Switch
        name={hiddenFieldName}
        initValue={false}
        className="mwg-hidden"
      />
      <Form
        name={STORE_IDS_FORM}
        initialValues={{ storeIds: selectedStoreIds }}
        onChange={(values) => {
          handleStoresChange(values.storeIds);
        }}
      >
        <TagsField
          name="storeIds"
          initValue={selectedStoreIds}
          caption={component.title}
          divider={[' ', ',', ';']}
          preventDefaultOnEnter
          placeholder=" "
          optional
        />
      </Form>
    </>
  );
};

export default StoreType;
