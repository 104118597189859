import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'framework/utils/useDispatch';
import { LayoutTypeProps, LayoutItem } from './Interfaces';
import { actions, translations } from './constants';
import stackedSvg from './icons/stacked.svg';
import sliderSvg from './icons/slider.svg';
import twoColumnsSvg from './icons/twoColumns.svg';

export const useLayoutType = ({ rowIndex, layoutType }: LayoutTypeProps) => {
  const dispatch = useDispatch();
  const [initialValue] = useState<string>(layoutType);
  const [value, setValue] = useState<string>(initialValue);

  const layoutItems: LayoutItem[] = useMemo(
    () => [
      {
        value: 'stacked',
        svg: stackedSvg,
        text: translations.stacked,
      },
      {
        value: 'slider',
        svg: sliderSvg,
        text: translations.slider,
      },
      {
        value: 'twoColumns',
        svg: twoColumnsSvg,
        text: translations.twoColumns,
      },
    ],
    [translations, stackedSvg, sliderSvg, twoColumnsSvg]
  );

  const handleSetValue = (val: string) => {
    setValue(val);
    dispatch(actions.saveLayout({ type: val, rowIndex }));
  };

  const onLayoutChange = () => {
    if (layoutType !== initialValue) {
      setValue(layoutType);
    }
  };

  useEffect(onLayoutChange, [layoutType]);

  return {
    value,
    setValue: handleSetValue,
    layoutItems,
  };
};
