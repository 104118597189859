import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { PATH_MAIN, templatesRoute, liveTimeslotRoute } from './constants';

const SlotManagementModule: ModuleConfig = {
  name: 'slotManagement',
  appMenuTitle: 'navigation.slotManagement',
  appMenuIcon: 'calendarRange',
  modulePath: PATH_MAIN,
  pageGroups: [
    {
      title: 'modules.slotManagement.templates.title',
      routes: [templatesRoute],
    },
    {
      title: 'modules.slotManagement.storeCalendar.title',
      routes: [liveTimeslotRoute],
    },
  ],
};

export default SlotManagementModule;
