import { appState } from 'stores/app';
import { UserProfile, AuthAccessClaim } from './Interfaces';
import { claimExists } from './utils';

const claimFound = (profile: UserProfile) => (clmValue) => claimExists(profile, clmValue);

// giving a list of claim, returns true/false if user has these claims
export const ensureClaims = (claimValue: AuthAccessClaim | AuthAccessClaim[], strict = true): boolean => {
  if (!appState.user) { // not logged in
    return false;
  }

  const { profile } = appState.user;
  const arrayValues: AuthAccessClaim[] = !Array.isArray(claimValue) ? [claimValue] : claimValue;

  // public route
  if (!claimValue || !arrayValues.length) {
    return true;
  }

  if (profile.poweruser || profile.fullaccess) {
    return true;
  }
  return strict ? arrayValues.every(claimFound(profile)) : arrayValues.some(claimFound(profile));
};

export const userIsAdmin = () => {
  if (appState?.user?.profile?.poweruser || appState?.user?.profile?.fullaccess) {
    return true;
  }
  return false;
};

/**
 * @deprecated use ensureClaims() instead
 */
export const useAuthUser = () => ({
  ensureClaims,
});
