export const SET_INITIAL_CONTENT = 'SET_INITIAL_CONTENT';
export const REVERT_CONTENT_CHANGES = 'REVERT_CONTENT_CHANGES';

/**
 *
 * set initial cms content in redux. This value is stored when
 * cms content is first fetched from backend or reset
 */
export const setInitialContent = ({ content }) => ({
  type: SET_INITIAL_CONTENT,
  payload: {
    ...content,
  },
});

// reset cmsContent from initial content saved previously
export const revertContentChanges = () => ({
  type: REVERT_CONTENT_CHANGES,
});
