import { types } from '.';

const initialState = {};
export default (state = initialState as any, action) => {
  switch (action.type) {
    case types.STORE_TOOLTIP_DATA: {
      return {
        ...state,
        [action.payload.id]: action.payload.data
      };
    }

    case types.DELETE_TOOLTIP_DATA: {
      const newState = { ...state };
      delete newState[action.payload.id];
      return {
        ...newState
      };
    }
  }
  return state;
};
