import { put, select } from 'redux-saga/effects';
import { isString } from 'framework/utils/helpers';
import { calendarActions, modalActions } from 'framework/actions';
import { formSelectors } from 'framework/selectors';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { MODAL_TEMPLATE_EDIT_SLOT, FORM_SLOTMANAGEMENT_EDIT_SLOT } from 'framework/modules/slotManagement/constants';
import { getSelectValue, getSelectValues } from 'framework/components/ui/FormComponents/Select';
import * as CONST_PAGE from '../../pageParts/constants';
// eslint-disable-next-line import/no-cycle
import { ACTIONS, selectors as slotTemplateSelector } from '..';
import { getDate } from '../selectors';

const formatDate = (time) => {
  let h = time.hours || '00';
  let m = time.minutes || '00';

  if (isString(time)) {
    [h, m] = time.split(':');
  }

  return `${h}:${m}:00`;
};
const getCutOff = (formValues) => {
  const h = formValues[CONST_PAGE.FORM_SLOTEDIT_CUTOFF_HOURS] || '00';
  const m = formValues[CONST_PAGE.FORM_SLOTEDIT_CUTOFF_MINUTES] || '00';

  return `${h}:${m}`;
};
const getAmendCutOff = (formValues) => {
  const h = formValues[CONST_PAGE.FORM_SLOTEDIT_AMEND_CUTOFF_HOURS] || '00';
  const m = formValues[CONST_PAGE.FORM_SLOTEDIT_AMEND_CUTOFF_MINUTES] || '00';

  if (h === '00' && m === '00') return null;
  return `${h}:${m}`;
};
const getPickByTime = (formValues) => {
  const h = formValues[CONST_PAGE.FORM_SLOTEDIT_PICK_BY_HOURS] || '00';
  const m = formValues[CONST_PAGE.FORM_SLOTEDIT_PICK_BY_MINUTES] || '00';

  return `${h}:${m}`;
};

const getPickStartingTime = (formValues) => {
  const h = formValues[CONST_PAGE.FORM_SLOTEDIT_PICKING_STARTING_HOURS] || '00';
  const m = formValues[CONST_PAGE.FORM_SLOTEDIT_PICKING_STARTING_MINUTES] || '00';
  
  if (h === '00' && m === '00') return null;
  return `${h}:${m}`;
};
function* process({ payload }) {
  const { deliveryAreas = [], slotData } = payload;
  const selectedSlot: any = yield select(slotTemplateSelector.getSelectedSlot);
  const formValues = yield select(formSelectors.getFormValues, FORM_SLOTMANAGEMENT_EDIT_SLOT);
  const newSlots: any = [];
  const tags = getSelectValues(formValues[CONST_PAGE.FORM_SLOTEDIT_TAGS] || []);
  const iniTime = !slotData ? formatDate(formValues[CONST_PAGE.FORM_SLOTEDIT_TIME_INI]) : null;
  const endTime = !slotData ? formatDate(formValues[CONST_PAGE.FORM_SLOTEDIT_TIME_END]) : null;

  // 1. Add Slot data to Template
  const slotsData: any = slotData
    ? { ...slotData }
    : {
      newOrdersCutoff: getCutOff(formValues),
      amendOrdersCutoff: getAmendCutOff(formValues),
      pickingStartingTime: getPickStartingTime(formValues),
      capacity: formValues[CONST_PAGE.FORM_SLOTEDIT_CAPACITY],
      fulfillmentType: getSelectValue(formValues[CONST_PAGE.FORM_SLOTEDIT_FULFILLMENT]),
      price: formValues[CONST_PAGE.FORM_SLOTEDIT_PRICE] || 0,
      tags,
      deliveryAreas: deliveryAreas.filter((da) => da.type === 'deliveryArea'),
      start: iniTime,
      end: endTime,
    };
  if (!slotData && !getSelectValue(formValues[CONST_PAGE.FORM_SLOTEDIT_USE_DEFAULT_PICK_BY])) {
    slotsData.pickByTime = getPickByTime(formValues);
  }

  if (selectedSlot.id || slotData) {
    // 1.1 Edit Slot selected
    const { day } = slotData?.data ?? selectedSlot.data;

    newSlots.push({
      id: slotData?.id ?? selectedSlot.id,
      start: getDate(day, iniTime),
      end: getDate(day, endTime),
      template: 'slotTemplate',
      data: {
        day,
        ...slotsData,
      },
      ...slotData,
    });
  } else {
    // 1.2 Create an Slot for each day selected
    for (let day = 0; day < 7; day += 1) {
      if (formValues[`slotEditDay0${day}`]) {
        newSlots.push({
          start: getDate(day, iniTime),
          end: getDate(day, endTime),
          template: 'slotTemplate',
          data: {
            day,
            ...slotsData,
          },
        });
      }
    }
  }

  yield put(calendarActions.addEvents(CONST_PAGE.FORM_EDIT_CALENDAR, newSlots));

  // 2. Hide Modal
  yield put(modalActions.createCloseModalAction(MODAL_TEMPLATE_EDIT_SLOT));
  yield put(calendarActions.updateSelectorEvent(CONST_PAGE.FORM_EDIT_CALENDAR, null));
}

export default function* watchEditSlotTemplatesAction() {
  yield takeEveryWithProgressBar(ACTIONS.TEMPLATE_EDIT_SLOT, process);
}
