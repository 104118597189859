import * as classnames from 'classnames';
import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Icon, Text } from 'framework/components/ui';
import { IMessageProps } from './MessagePrimitve.interface';
import './_style.scss';

const clx = prefixClassName('message-primitive');

enum labels {
  info = 'components.message.labels.info',
  success = 'components.message.labels.success',
  note = 'components.message.labels.note',
  warning = 'components.message.labels.warning',
  error = 'components.message.labels.error',
  notification = 'components.message.labels.notification',
}

const MessagePrimitive = (props: IMessageProps) => {
  const {
    caption,
    visible = true,
    className,
    type = 'info',
    onClose = null,
    showLabel = true,
    customLabel,
  } = props;

  return caption ? (
    <div
      className={classnames(
        clx,
        `${clx}--${type}`,
        onClose && `${clx}--closable`,
        !visible && 'mwg-hidden',
        className,
      )}
    >
      {onClose && <Icon className={`${clx}__closeIcon`} name="close" onClick={onClose} />}
      {showLabel && <Text caption={customLabel || labels[type]} tag="strong" />}
      <span className={`${clx}__content`}>{caption}</span>
    </div>
  ) : null;
};

export default MessagePrimitive;
