import { prefixClassName } from '../_conf';

export const tableClassName = prefixClassName('Table');
export const emptyCell = prefixClassName('empty-cell');
export const noPaddingRight = prefixClassName('no-padding-right');
export const tableDataCell = `${tableClassName}__data-cell`;
export const tableActionCell = `${tableClassName}__action-cell`;

export const tableRow = `${tableClassName}__row`;
export const tableRowDragged = `${tableRow}--dragged`;
export const tableRowMoved = `${tableRow}--moved`;

export const draggableCell = `${tableClassName}__draggable-cell`;
export const draggableCellRect = `${draggableCell}__rect`;
export const draggableCellDisabled = `${draggableCell}--disabled`;
