import { put, takeEvery } from 'redux-saga/effects';
import { createApiClientUpdateStateAction } from 'framework/api/apiClient/actions';

import { actionTypes } from '../actions';


function* process() {
  yield put(createApiClientUpdateStateAction({
    data: {},
    name: actionTypes.API_PROVIDER_GET_STORE
  }));
}

export default function* watchCreateStoreAction() {
  yield takeEvery(actionTypes.STORES_CREATE_STORE, process);
}
