import { call, put } from 'redux-saga/effects';
import { getApiClientData } from 'framework/api/apiClient/service';
import { apiClientActions, toastListActions } from 'framework/actions';
import { config } from 'config';
import * as CONST from 'framework/modules/searchadmin/constants';
import { history } from 'framework/store';
import { takeEveryWithConfirmation } from 'framework/sagas/extensions/confirmation';
import { withProgressBar } from 'framework/sagas/extensions/progress';

function* process({ payload }) {
  const localeId = CONST.DEFAULT_LOCALE_ID; // We need to hardcode locale for now
  const { ruleId } = payload;

  // 1. Set isFetching to true
  yield put(
    apiClientActions.createApiClientUpdateStateAction({
      name: CONST.API_DATA_RULES_LIST,
      isFetching: true,
    }),
  );
  // 2. Make Delete Request
  const searchEndpoint = config.apiEndpoints.search;
  const deleteEndpointPath = `/locale/${localeId}/rules/${ruleId}`;
  const deleteRequestDetails = {
    name: 'DeleteRule',
    url: searchEndpoint + deleteEndpointPath,
    method: 'delete',
  };
  const responseDataAndHeaders = yield call(getApiClientData, deleteRequestDetails, true);

  if (responseDataAndHeaders.success) {
    // 3. Navigate to Rules List Page

    // TODO: create proper module that would expose history object
    history.push(CONST.PATH_RULES_FULLPATH);
  } else {
    yield put(toastListActions.addMessageError('There were problem deleting rules'));
  }
}

export default function* watchDeleteRulesAction() {
  yield takeEveryWithConfirmation(
    CONST.ACTION_RULES_DELETE_RULE_FROM_EDIT_PAGE,
    withProgressBar(process),
  );
}
