import { fetchData } from 'framework/api/fetch-data';
import { DEFAULT_LOCALE_ID, getApiUrl } from 'config';
import { RuleHideCategory } from '../types';

export interface FetchRulesCategoriesArgs {
  localeId: string;
}

export const fetchCategories = async ({
  localeId = DEFAULT_LOCALE_ID,
}: FetchRulesCategoriesArgs): Promise<RuleHideCategory[]> => {
  const url = 'search/locale/{localeId}/categories';

  const categories = await fetchData<{ categories: RuleHideCategory[] }>({
    url: getApiUrl(url, { localeId }),
    method: 'GET',
  });

  return categories.data.categories || [];
};
