import * as React from 'react';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { formField, IformField } from 'framework/components/hoc/formField';
import {
  IPasswordFieldPrimitiveProps,
  PasswordFieldPrimitive,
} from 'framework/components/ui/FormComponents/Primitives/PasswordFieldPrimitive';

interface Props extends IPasswordFieldPrimitiveProps {
  hasError?: boolean;
}

const clx = prefixClassName('form-field');

export const PasswordField = (props: Props) => {
  const { className, hasError } = props;

  return (
    <PasswordFieldPrimitive
      {...props}
      className={classNames(clx, hasError && `${clx}--error`, className)}
    />
  );
};

export default (formField(PasswordField) as any) as React.StatelessComponent<
Partial<Props & IformField>
>;
