import { createUserManager } from 'redux-oidc';
import { ACTIVE_FULFILLMENT_CENTER, config, LOCAL_USER_SETTINGS } from 'config';
import { FulfillmentCenter, setFulfillmentCenter, setUser, setUserSettings, UserSettings } from 'stores/app';
import { fetchData } from 'framework/api/fetch-data';
import { TimeFormats } from 'framework/components/globalhooks';
import { get } from 'framework/services/localStorage/localStorage';
import { AuthorizationClaims, ensureClaims } from 'framework/authorization';

export const DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = `${DATE_FORMAT} - ${TIME_FORMAT}`;
export const DATETIME_FORMAT_OF = `${TIME_FORMAT} - ${DATE_FORMAT}`;
export const TIMESLOT_DATETIME_FORMAT = `${TIME_FORMAT} ${DATE_FORMAT}`;

/* eslint-disable  */
const oidcConfig = {
  authority: config.identityserver.authority,
  client_id: config.identityserver.client_id,
  redirect_uri: config.identityserver.redirect_uri,
  response_type: config.identityserver.response_type,
  client_secret: config.identityserver.client_secret,
  scope: config.identityserver.scope,
  acr_values: 'area:admin',
  silent_redirect_uri: config.identityserver.silent_redirect_uri,
  automaticSilentRenew: true,

  post_logout_redirect_uri: config.identityserver.post_logout_redirect_uri,
};

let idToken;
const userManager = createUserManager(oidcConfig);
userManager.events.addAccessTokenExpired(async () => {
  await userManager.signinSilent().catch(() => userManager.signoutRedirect({ id_token_hint: idToken }));
  const { FS } = window;
  if (FS) {
    FS.identify(false);
  }
});
userManager.events.addSilentRenewError(async (e) => {
  if (e) {
    await userManager.signoutRedirect({ id_token_hint: idToken });
    const { FS } = window;
    if (FS) {
      FS.identify(false);
    }
  }
});

userManager.events.addUserSignedOut(() => {
  userManager
    .signinRedirect({
      redirect_uri: config.identityserver.redirect_uri,
    })
    .then(() => {
      const { FS } = window;
      if (FS) {
        FS.identify(false);
      }
    });
});

const getUserTimeFormat = async (accessToken: string, forceFetch: boolean = false) => {
  // If user settings is already in local storage and forceFetch is false, then it will be used when the user refreshes
  // the application
  const savedUserSettings = get<UserSettings>(LOCAL_USER_SETTINGS, true);

  if (savedUserSettings && !forceFetch) {
    setUserSettings(savedUserSettings, true);

    return;
  }

  // If forceFetch is true, then the user settings should be fetched
  const userSettings = await fetchData<UserSettings>(
    {
      url: `${config.apiEndpoints.users}/settings`,
      method: 'GET',
    },
    accessToken
  );

  const { timeFormat } = userSettings.data;

  const convertedTimeFormat: string = timeFormat === TimeFormats.Hours12 ? 'h:mm a' : 'HH:mm';

  const userTimeSettings: UserSettings = {
    timeFormat,
    convertedTimeFormat,
    dateFormat: DATE_FORMAT,
    dateTimeFormat: `${convertedTimeFormat} - ${DATE_FORMAT}`,
    dateTimeFormatOf: `${convertedTimeFormat} - ${DATE_FORMAT}`,
    timeSlotDateTimeFormat: `${convertedTimeFormat} ${DATE_FORMAT}`,
  };

  setUserSettings(userTimeSettings);
};

const updateLocalFulfillmentCenter = async (accessToken) => {
  const savedFC = get<FulfillmentCenter>(ACTIVE_FULFILLMENT_CENTER, true) || {
    id: null,
    name: null,
    expired: null,
    timeZone: '',
  };
  if (savedFC.id) {
    const updatedFCData = await fetchData<FulfillmentCenter>(
      {
        url: `${config.apiEndpoints.orderfulfillment.fulfillmentCenters}/${savedFC.id}`,
        method: 'GET',
      },
      accessToken
    );
    setFulfillmentCenter(updatedFCData.data);
  }
};
const setUserAndAccessToken = (user) => {
  setUser(user);
  
  if (user.id_token) idToken = user.id_token;

  if (user.access_token) {
    getUserTimeFormat(user.access_token);
  }
};

// sets the user in appState case is already logged in
userManager
  .getUser()
  .then(async (user) => {
    setUserAndAccessToken(user);
  })
  .catch(() => setUser(undefined));

// adds events to set/remove the user from  appState
userManager.events.addUserLoaded(async (user) => {
  setUserAndAccessToken(user);

  if (user.access_token) {
    // Fetch user settings on log in
    getUserTimeFormat(user.access_token, true);
    
    if (ensureClaims(AuthorizationClaims.ORDER_FULFILLMENT)) {
      updateLocalFulfillmentCenter(user.access_token);
    }
  }
});

userManager.events.addUserUnloaded(() => {
  setUser(undefined);
});

export default userManager;
