import * as React from 'react';
import Menu from './Menu/Menu';
import { NotificationsTranslations } from './Menu/translations';
import { useModal } from '../Modal';
import { ALL_NOTIFICATIONS_MODAL, DETAILS_MODAL, NONE } from './constants';
import { DetailsModal } from './Details/DetailsModal';
import { AllNotificationsModal } from './AllNotifications/AllNotificationsModal';
import useNotifications from './useNotifications';

export const Notifications = () => {
  const { openModal: openDetailsModal, closeModal: closeDetailsModal } = useModal(DETAILS_MODAL);
  const { openModal: openAllNotificationsModal, closeModal: closeAllNotificationsModal } =
    useModal(ALL_NOTIFICATIONS_MODAL);

  const {
    allNotifications,
    notificationType,
    selectedNotification,
    notificationsSeen,
    notificationsNotSeen,
    handleMarkAllAsSeen,
    handleNotificationClick,
    clearSelectedNotifications,
  } = useNotifications(openDetailsModal);

  if (notificationType !== NONE) {
    return (
      <>
        <Menu
          buttonText={NotificationsTranslations.Notifications}
          notificationsSeen={notificationsSeen}
          notificationsNotSeen={notificationsNotSeen}
          onMarkAllAsSeen={() => {
            handleMarkAllAsSeen();
          }}
          onNotificationClick={handleNotificationClick}
          onClickAllNotifications={openAllNotificationsModal}
        />
        {selectedNotification && (
          <DetailsModal
            notification={selectedNotification}
            closeModal={() => {
              closeDetailsModal();
              clearSelectedNotifications();
            }}
          />
        )}
        {allNotifications?.length > 0 && (
          <AllNotificationsModal
            notificationsSeen={notificationsSeen}
            notificationsNotSeen={notificationsNotSeen}
            onMarkAllAsSeen={() => {
              handleMarkAllAsSeen();
            }}
            onNotificationClick={handleNotificationClick}
            closeModal={closeAllNotificationsModal}
          />
        )}
      </>
    );
  }

  return null;
};
