import * as React from 'react';
import { Info, Text } from 'framework/components/ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import './_style.scss';

const clx = prefixClassName('gutter-tooltip');
const localePath = 'components.calendar.components.headerGutterTooltip';

export const TooltipContents = ({
  hasInstacartTimeslot,
  hasOrphanedSlots,
}: {
  hasInstacartTimeslot: boolean;
  hasOrphanedSlots: boolean;
}) => {
  return (
    <div className={`${clx}__key-wrapper`}>
      <Text tag="div" caption={`${localePath}.key`} className={`${clx}__header`} />
      <Text tag="div" caption={`${localePath}.pickup`} className={`${clx} ${clx}__Pickup`} />
      <Text tag="div" caption={`${localePath}.delivery`} className={`${clx} ${clx}__Delivery`} />
      {hasInstacartTimeslot && (
        <Text tag="div" caption={`${localePath}.instacart`} className={`${clx} ${clx}__Instacart`} />
      )}
      <Text tag="div" caption={`${localePath}.blocking`} className={`${clx} ${clx}__Blocked`} />
      <Text tag="div" caption={`${localePath}.closed`} className={`${clx} ${clx}__Closed`} />
      {hasOrphanedSlots && <Text tag="div" caption={`${localePath}.orphaned`} className={`${clx} ${clx}__Orphaned`} />}
    </div>
  );
};

const HeaderGutterTooltip = ({
  hasInstacartTimeslot,
  hasOrphanedSlots,
}: {
  hasInstacartTimeslot: boolean;
  hasOrphanedSlots: boolean;
}) => {
  return (
    <div className={`${clx}__wrapper`}>
      <Text caption={`${localePath}.key`} />
      <Info
        showOnHover
        tooltipBody={
          <TooltipContents hasInstacartTimeslot={hasInstacartTimeslot} hasOrphanedSlots={hasOrphanedSlots} />
        }
      />
    </div>
  );
};

export default HeaderGutterTooltip;
