import { call, put, select } from 'redux-saga/effects';
import { config } from 'config';
import {
  calendarActions, apiClientActions,
} from 'framework/actions';
import { calendarSelectors, apiClientSelectors } from 'framework/selectors';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { API_DATA_SLOTS_CALENDAR_EDIT } from 'framework/modules/slotManagement/constants';
import { OrgHierarchy } from 'framework/shared';
import { addToast, toastError } from 'stores/toast';
import * as CONST_PAGE from '../../constants';
import * as selectors from '../selectors';
import { VIEWCALENDAR_FETCH_DATA } from '../actions.constants';
import * as helpers from './_helpers';

export function* process({ payload }) {
  const formValues = yield select(selectors.getFilterValues);
  const prevStore = yield select(selectors.getStore);
  const templates = CONST_PAGE.eventTemplates;
  const calendarSettings = yield select(
    calendarSelectors.getCalendarSettings,
    CONST_PAGE.CALENDAR_NAME,
  );

  const startDate = helpers.formatDate(calendarSettings.dateIni);
  const endDate = helpers.formatDate(calendarSettings.dateEnd);

  const { pageFilters = {} } = payload;
  const filters = {
    [CONST_PAGE.FIELD_FILTER_STORE]: prevStore,
    [CONST_PAGE.FIELD_FILTER_SHOPPINGMODE]: formValues[CONST_PAGE.FIELD_FILTER_SHOPPINGMODE],
    ...pageFilters,
  };
  const store: OrgHierarchy = filters[CONST_PAGE.FIELD_FILTER_STORE];
  const newDate = (d) => new Date(d);
  const getTime = (d) => {
    const date = new Date(d);

    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };
  };

  // 1. Show Loaders - Calendar
  yield put(calendarActions.setLoader(CONST_PAGE.CALENDAR_NAME, true));

  // 2. Make Request
  const searchEndpoint = config.apiEndpoints.slotCalendar;
  const endpointPath = `/storeId/${store.value.storeId}/startDate/${startDate}/endDate/${endDate}`;

  const responseData = yield call(makeRequestAndSaveState, {
    name: API_DATA_SLOTS_CALENDAR_EDIT,
    method: 'GET',
    url: searchEndpoint + endpointPath,
    query: {
      shoppingModeId: filters[CONST_PAGE.FIELD_FILTER_SHOPPINGMODE],
    },
  });

  if (responseData.success) {
    // 3. fetch data into redux -> Calendar
    const slots = responseData?.data?.slots?.map((event) => ({
      id: event.slotId,
      template: templates.slot,
      start: newDate(event.startDate),
      end: newDate(event.endDate),
      data: {
        isClosedNotes: event.note,
        ...event,
      },
    }));
    const orphanSlots = responseData?.data?.orphanSlots?.map((event) => ({
      template: templates.orphanSlot,
      start: newDate(event.startDate),
      end: newDate(event.endDate),
      data: event,
    }));
    const blockers = responseData?.data?.blockingSlots?.map((event) => ({
      id: event.id,
      template: templates.blocker,
      start: newDate(event.start),
      end: newDate(event.end),
      data: {
        ...event,
        timeStart: getTime(event.start),
        timeEnd: getTime(event.end),
      },
    }));

    yield put(
      calendarActions.setInitialValues(CONST_PAGE.CALENDAR_NAME, [
        ...slots,
        ...orphanSlots,
        ...blockers,
      ], 
      payload.action),
    );
  } else {
    yield put(calendarActions.clearEvents(CONST_PAGE.CALENDAR_NAME));

    if (responseData.error.title === CONST_PAGE.TIMELINE_NOT_FOUND) {
      addToast('modules.slotManagement.storeCalendar.noActiveTimeSlot', 'notification');
    } else {
      toastError('modules.slotManagement.storeCalendar.errorLoadingData');
    }
  }
  // keep selected store in the state
  const apiState = yield select(apiClientSelectors.selectApiClientData, API_DATA_SLOTS_CALENDAR_EDIT);
  yield put(
    apiClientActions.createApiClientUpdateStateAction({
      name: API_DATA_SLOTS_CALENDAR_EDIT,
      success: true,
      data: {
        ...apiState,
        store,
        storeTimeZone: responseData?.data?.storeTimeZone || undefined,
      },
    }),
  );
}

export default function* watchFetchSlotTemplatesAction() {
  yield takeEveryWithProgressBar(VIEWCALENDAR_FETCH_DATA, process);
}
