/**
 * Loads stackdriver when the keys are provided, else defaults to console.error
 */
import * as StackdriverErrorReporter from 'stackdriver-errors-js';
import { isValidExternalKey } from 'config';

type ErrorHandlerFn = (error: Error | string) => void;

export const initialize = (stackdriverKey?: string, stackdriverProjectId?: string): ErrorHandlerFn => {
  // eslint-disable-next-line no-console
  let handler: ErrorHandlerFn = console.error;

  if (isValidExternalKey({ stackdriverKey, stackdriverProjectId })) {
    const stackdriver = new StackdriverErrorReporter();

    stackdriver.start({
      key: stackdriverKey,
      projectId: stackdriverProjectId,
      service: 'admin-ui',
      version: window.__mi9__.buildNumber, // eslint-disable-line no-underscore-dangle
    });

    handler = (...args) => {
      stackdriver.report(...args).catch(() => {
      // catches if stackdriver fails reporting
      // eslint-disable-next-line no-console
        console.error(...args);
      });
    };
  }

  return handler;
};
