import { isDate, isString, isValidDate } from 'framework/utils/helpers';

export { isDate, isString, isValidDate };

const formatNum = (num: number = 0) => {
  const s = `00${num}`;
  return s.substr(s.length - 2);
};

export const getTimeValue = (val: any = {}) => {
  let h;
  let m;

  if (!val) {
    return null;
  }
  if (isDate(val)) {
    const date = new Date(val);
    h = date.getHours();
    m = date.getMinutes();
  } else if (isString(val)) {
    // '12:34' format
    [h, m] = val.split(':');
  } else {
    h = val.hours || '00';
    m = val.minutes || '00';
  }

  return `${formatNum(h)}:${formatNum(m)}`;
};

export const areDatesEqual = (date1, date2) => {
  // Compare if two dates are equal
  // ignoring their hours and minutes
  if (!date1 || !date2) {
    return false;
  }
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (!isDate(d1 || !isDate(d2))) {
    return false;
  }

  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);

  return d1.getTime() === d2.getTime();
};
