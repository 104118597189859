import { put, select } from 'redux-saga/effects';
import { apiClientSelectors } from 'framework/selectors';
import { toastListActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import * as constants from '../contentemanagement.constants';
import * as cmsActions from '../actions';
import { apiDataProviderMakeRequest } from 'framework/api/apiClient/saga';

export default function* watchCmsPageUpdateStatus() {
  yield takeEveryWithProgressBar(constants.ACTION_CMS_PAGE_UPDATE_STATUS, process);
}

function* process({ payload }) {
  // publish page
  yield apiDataProviderMakeRequest({
    payload: {
      body: {
        status: payload.status
      },
      method: 'put',
      endpoint: constants.PATH_CMS_ENDPOINT,
      endpointPath: `/documents/${payload.documentId}/variants/${payload.variantId}/status`,
      name: constants.ACTION_CMS_PAGE_UPDATE_STATUS
    }
  } as any);

  const state = yield select(
    apiClientSelectors.selectApiClientState,
    constants.ACTION_CMS_PAGE_UPDATE_STATUS
  );

  if (!state.hasError) {
    yield put(toastListActions.addMessage('generic.data.savedSuccess', 3000));
    // update page status on redux
    yield put(
      cmsActions.storePageDocumentAction({
        status: payload.status
      })
    );
    payload.onComplete();
  } else {
    yield put(toastListActions.addMessageError(state.message, 5000));
    payload.onError();
  }
  payload.onComplete();
}
