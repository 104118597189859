import * as React from 'react';
import classnames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Text } from 'ui';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useWindow } from 'framework/utils/useWindow';
import { IRangePrimitive } from './RangePrimitive.interface';
import './_style.scss';

const clx = prefixClassName('range');

export const RangePrimitive = (props: IRangePrimitive) => {
  const {
    displayExtraInfo = false,
    min = 0,
    max = 100,
    value = 0,
    disabled = false,
    step = 1,
    minCaption,
    maxCaption,
    name,
    className,
    onChange = () => null,
    onMouseUp,
    trackEventProps
  } = props;

  const { location: { pathname = '' } } = useWindow();

  const setPercentage = (val: number) => ((val - min) * 100) / (max - min);
  const getBetweenValue = () => {
    const twoValues = [+min, +max];
    const result = twoValues.reduce((a, b) => a + b, 0) / twoValues.length;

    return (result && result.toFixed(0)) || 0;
  };

  const handleOnChange = (val) => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || `Range ${name}`,
      trackEventProps?.label || val.target.value
    );
    onChange(val);
  };

  const handleOnMouseUp = (val) => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || `Range ${name}`,
      trackEventProps?.label || val.target.value
    );
    onMouseUp(val);
  };

  return (
    <div>
      <input
        name={name}
        id={name}
        className={classnames(clx, disabled && `${clx}--disabled`, className)}
        type="range"
        style={{
          backgroundSize: `${setPercentage(value)}% 100%`
        }}
        disabled={disabled}
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={handleOnChange}
        onMouseUp={handleOnMouseUp}
        aria-label={name}
      />
      {displayExtraInfo && (
        <div className={classnames(`${clx}__extra-info`, className)}>
          <div className={`${clx}__extra-info__top`}>
            <span>{min}</span>
            <span>{getBetweenValue()}</span>
            <span>{max}</span>
          </div>
          <div className={`${clx}__extra-info__bottom`}>
            <span>
              <Text caption={minCaption} />
            </span>
            <span>
              <Text caption={maxCaption} />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default RangePrimitive;
