import * as React from 'react';
import i18n from 'i18n';
import store from 'framework/store';
import { update, get } from 'framework/services/localStorage/localStorage';
import { modalActions } from 'framework/actions';
import { IMessageProps } from 'framework/components/ui/Primitives/MessagePrimitive';
import { SearchDataInfo } from '../../models/savedSearch';
// eslint-disable-next-line import/no-cycle
import { Modal, Form, TextField } from '../../..';
import { SAVE_SEARCHES_LIMIT } from '../../constants';
import { connectSavedSearches } from '../../state/savedSearchesConnector';

interface SaveSearchModalProps {
  caption?: string;
  searchName?: string;
  selectSearch?: (filters: SearchDataInfo) => void;
  data: any; // search data
  localStorageName?: string;
  overrideKey?: boolean;
}

class SaveSearchMaxLimitError extends Error {
  constructor() {
    super('maxSaveLimit');
  }
}

const SAVE_SEARCH_MODAL = 'filterBarSaveSearchModal';

export const openSaveSearchModal = () => store.dispatch(modalActions.createOpenModalAction(SAVE_SEARCH_MODAL));
export const closeSaveSearchModal = () => store.dispatch(modalActions.createCloseModalAction(SAVE_SEARCH_MODAL));

export const saveSearchData = (
  name: string,
  searchData: any,
  select?: any,
  overwriteSearch?: boolean,
  prevName?: string,
  localStorageName?: string,
  overrideKey?: boolean,
) => {
  update<SearchDataInfo[]>(localStorageName, () => {
    const savedData = get(localStorageName, null, overrideKey);

    if (select) select({ name, searchData });

    if (!savedData) {
      return [{
        name,
        searchData,
      }];
    }

    if (overwriteSearch) {
      const prevSearchIndex = savedData.findIndex((s) => s.name.toLowerCase() === prevName?.toLowerCase());

      if (prevSearchIndex > -1) {
        const updatedSearch = [...savedData];
        updatedSearch[prevSearchIndex] = {
          name,
          searchData,
        };

        return updatedSearch;
      }
    }

    if (savedData.length >= SAVE_SEARCHES_LIMIT) {
      throw new SaveSearchMaxLimitError();
    }

    return [
      ...savedData,
      { name, searchData },
    ];
  }, null, null, overrideKey);
};

const SaveSearchModal = connectSavedSearches<SaveSearchModalProps>(({
  caption = 'generic.button.saveSearch',
  searchName,
  selectSearch,
  data,
  localStorageName,
  overrideKey,
}) => {
  const savedSearches: SearchDataInfo[] = get(localStorageName, null, overrideKey) ?? [];

  const [newSearchName, setNewSearchName] = React.useState<string>(searchName ?? '');

  const isDuplicateSearch = () => {
    if (!Array.isArray(savedSearches)) return null;

    return savedSearches?.find(
      (opt) => JSON.stringify(opt.searchData) === JSON.stringify(data),
    )?.name || null;
  };

  const duplicateCheck = isDuplicateSearch();
  const mode = searchName ? 'update' : 'new';
  const blockEditing = duplicateCheck && mode === 'new';

  const noDuplicateNames = () => {
    if (!Array.isArray(savedSearches)) return false;

    const duplicateName = savedSearches.filter(({ name }) => name?.toLowerCase() === newSearchName?.toLowerCase());
    return (mode === 'new' && duplicateName.length)
      || (mode === 'update' && duplicateName.length && searchName !== newSearchName)
      ? { searchName: 'modules.orderFulfillment.advancedFilters.saveFilterModal.errors.duplicateName' }
      : null;
  };

  const hasNewName = () => {
    const duplicateName = savedSearches.filter((opt) => opt.name === newSearchName);
    return duplicateName.length > 0;
  };

  const handleOnChangeName = (e) => {
    setNewSearchName(e.searchName);
  };

  const handleOnSubmit = (updateSearch) => {
    saveSearchData(newSearchName, data, selectSearch, updateSearch, searchName, localStorageName, overrideKey);

    closeSaveSearchModal();
  };

  const getMessage = (): IMessageProps[] => {
    const genericMessage = i18n.t('modules.orderFulfillment.advancedFilters.saveFilterModal.message', {
      count: savedSearches.length,
    });
    if (blockEditing) {
      return [
        {
          type: 'error',
          showLabel: false,
          caption: i18n.t('modules.orderFulfillment.advancedFilters.saveFilterModal.messageDuplicate', {
            name: duplicateCheck,
          }),
        },
      ];
    }
    if (savedSearches.length >= SAVE_SEARCHES_LIMIT && mode === 'new') {
      return [
        {
          type: 'error',
          showLabel: false,
          caption: 'modules.orderFulfillment.advancedFilters.saveFilterModal.messageFull',
        },
      ];
    }
    return [
      {
        type: savedSearches.length > Math.round(SAVE_SEARCHES_LIMIT / 2) ? 'warning' : 'info',
        showLabel: false,
        caption: genericMessage,
      },
    ];
  };

  return (<Modal
    caption={caption}
    name={SAVE_SEARCH_MODAL}
    messages={getMessage()}
    footerOptions={blockEditing ? [{
      caption: 'generic.button.cancel',
      onClick: () => closeSaveSearchModal(),
      link: true,
    },
    ] : [
      !searchName && {
        caption: 'generic.button.save',
        onClick: () => handleOnSubmit(false),
        disabled: !!noDuplicateNames() || !newSearchName,
      },
      searchName && {
        caption: 'generic.button.update',
        onClick: () => handleOnSubmit(true),
        disabled: !!noDuplicateNames() || !newSearchName,
      },
      searchName && {
        caption: 'generic.button.saveNew',
        onClick: () => handleOnSubmit(false),
        outline: true,
        disabled: hasNewName() || savedSearches.length === SAVE_SEARCHES_LIMIT,
      },
      {
        caption: 'generic.button.cancel',
        onClick: () => closeSaveSearchModal(),
        link: true,
      },
    ].filter((b) => b)}
  >
    <Form
      name={localStorageName}
      onChange={handleOnChangeName}
      initialValues={{ searchName }}
    >
      <TextField
        name="searchName"
        caption="generic.searchName"
        explainerText="generic.searchNameHint"
        mandatory
        grid
        readOnly={blockEditing}
      />
    </Form>
  </Modal>
  );
});

export { SaveSearchModal };
