import * as React from 'react';
import * as classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import TextAreaPrimitive, {
  PrimitiveTextAreaProps
} from 'framework/components/ui/FormComponents/Primitives/TextAreaPrimitive';
import { formField, IformField } from 'framework/components/hoc/formField';
import './_style.scss';

const clxField = prefixClassName('form-field');
const clxTextArea = prefixClassName('form-textArea');

export const TextArea = (props: PrimitiveTextAreaProps) => {
  const { hasError, className } = props;

  return (
    <TextAreaPrimitive
      {...props}
      className={classNames(clxField, clxTextArea, hasError && `${clxField}--error`, className)}
    />
  );
};

export default (formField(TextArea) as any) as React.StatelessComponent<
Partial<PrimitiveTextAreaProps & IformField>
>;
