import { GrowthBook, InitOptions, Context } from '@growthbook/growthbook-react';
import { growthBookCustomFlags, IGrowthBookFeatures } from './growthbook.interfaces';
import { isValidExternalKey } from '../config';

const growthBookUrl = window.growthBookUrl?.trim();

export const isValidGrowthBookUrl = isValidExternalKey({ growthBookUrl });

export const initGrowthBook = () => {
  const growthbookOptions: Context = {};

  const initOptions: InitOptions = {
    timeout: 2000,
  };

  if (isValidGrowthBookUrl) {
    const url = new URL(growthBookUrl);
    const apiHost = url.origin;
    const clientKey = url.pathname.slice(url.pathname.lastIndexOf('/') + 1);
    growthbookOptions.apiHost = apiHost;
    growthbookOptions.clientKey = clientKey;
  } else if (process.env.NODE_ENV !== 'production') {
    initOptions.payload = { features: growthBookCustomFlags };
  } else {
    initOptions.payload = {};
  }
  const gb = new GrowthBook<IGrowthBookFeatures>(growthbookOptions);

  gb.init(initOptions);

  return gb;
};

export const gb = initGrowthBook();
