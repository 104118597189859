import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { ExtendedClaims } from 'framework/components/navigation/Navigation.interfaces';

export const PATH_CRM = '/crm';
export const PATH_CUSTOMERS = '/customers';
export const PATH_CUSTOMER_EDIT = '/edit';

export const ADD_CUSTOMER_COMMENT_FORM = 'ADD_CUSTOMER_COMMENT_FORM';
export const TERMS_COMMENTS_REASON = 'terms_comment_reasons';

export const FIELD_EMAIL_STORE_ID = 'storeId';

export const RESEND_EMAIL_CLAIM: ExtendedClaims = {
  label: 'modules.siteadmin.claims.resendEmails',
  claims: {
    [AuthorizationClaims.CUSTOMER_RESEND_EMAILS]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.STORES_SITE]: UserAccessRights.Read,
  },
};

export const CUSTOMER_CREDITS_READONLY_CLAIM = {
  [AuthorizationClaims.CUSTOMER_CREDITS_ALLOCATE]: UserAccessRights.Read,
};

export const CUSTOMER_CREDITS_CLAIM = {
  label: 'modules.siteadmin.claims.customerCredits',
  claims: {
    [AuthorizationClaims.CUSTOMER_CREDITS_ALLOCATE]: UserAccessRights.ReadWrite,
  },
};

export const CUSTOMER_IMPERSONATE_CLAIM = {
  label: 'modules.siteadmin.claims.customerImpersonate',
  claims: {
    [AuthorizationClaims.CUSTOMER_IMPERSONATE]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.STORES_SITE]: UserAccessRights.Read,
  },
};

export const CUSTOMER_RESET_PASSWORD_CLAIM = {
  label: 'modules.siteadmin.claims.customerResetPassword',
  claims: {
    [AuthorizationClaims.CUSTOMER_USER_PASSWORD]: UserAccessRights.ReadWrite,
  },
};

export const CUSTOMER_COMMENTS_CLAIM = {
  label: 'modules.siteadmin.claims.customerComments',
  claims: {
    [AuthorizationClaims.CUSTOMER_COMMENTS]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.CONTENTMANAGEMENT_TERMS]: UserAccessRights.Read,
  },
};

export const CUSTOMER_BUSINESS_ACCOUNTS_CLAIM = {
  label: 'modules.siteadmin.claims.customerBusinessAccounts',
  claims: {
    [AuthorizationClaims.CUSTOMER_BUSINESS_ACCOUNTS]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
  },
};

export const CUSTOMER_VIEW_SUBSCRIPTIONS_CLAIM = {
  label: 'modules.siteadmin.claims.customerViewSubscriptions',
  claims: {
    [AuthorizationClaims.CUSTOMER_VIEW_SUBSCRIPTIONS_CLAIM]: UserAccessRights.Read,
  },
};

export const CUSTOMER_CANCEL_SUBSCRIPTIONS_CLAIM = {
  label: 'modules.siteadmin.claims.customerCancelSubscriptions',
  claims: {
    [AuthorizationClaims.CUSTOMER_CANCEL_SUBSCRIPTIONS_CLAIM]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.CUSTOMER_VIEW_SUBSCRIPTIONS_CLAIM]: UserAccessRights.Read,
  }
};

export const CUSTOMER_SEGMENTS_CLAIM = {
  label: 'modules.siteadmin.claims.customerSegments',
  claims: {
    [AuthorizationClaims.SITESETTINGS_SEGMENTATION]: UserAccessRights.Read,
  },
};