import * as React from 'react';
import { safeGet } from 'framework/utils';
import {
  Calendar,
  LayoutFlexBoxItem,
  StickyBar,
  ConfirmationModal,
  ModalPreventNavigation,
  FilterBar,
  LocalClock,
} from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import ApiDataProvider from 'framework/components/services/ApiDataProvider';
import { API_DATA_SLOTS_CALENDAR_OPTIONS } from 'framework/modules/slotManagement/constants';
import { ConnectedToolbar } from 'framework/components/ui/Calendar/components/Toolbar/ToolbarOptions';
import { ICalendarViews } from 'framework/components/ui/Calendar/Calendar.interfaces';
import { viewStore } from 'framework/components/ui/Calendar/components/Toolbar/viewSelector/Toolbar.viewSelector';
import * as CONST_PAGE from '../constants';
import { ModalCreateBlocker } from './ModalCreateBlocker';
import { ModalCloseSlot } from './ModalCloseSlot';
import { ModalEditSlot } from './ModalEditSlot';
import { ModalOrphanDetails } from './ModalOrphanDetails';
import { Props, PropsActions } from './content.interfaces';
import './_styles.scss';
import { store } from '@risingstack/react-easy-state';
import CalendarListView from '../../common/ListView/CalendarListView';
import { IEventComponent } from '../../../../../components/ui/Calendar/components/Event/Event.interfaces';
import { BulkActions } from '../../../../orderFulfillment/pages/ProductionCenters/BulkActions';
import { SlotBulkActions } from './constants';
import { ModalBulkEditSlot } from './ModalBulkEditSlot';
import { useModal } from '../../../../../components/ui/Modal';
import { ListViewTypes } from '../../common/ListView/constants';
import { getBulkOption } from './_helpers';

export const liveCalendarBulkActionStore = store<{ action: SlotBulkActions; selectedSlots: IEventComponent[] }>();

const storeClockClx = prefixClassName('liveSlotCalendar__store-clock');

const BulkActionsContainer = (listView, bulkOptions, selectedSlots) => {
  const { openModal: openModalBulkCapacity } = useModal(CONST_PAGE.MODAL_BULK_EDIT_SLOT_CAPACITY);

  return (
    listView && (
      <BulkActions
        options={bulkOptions}
        onClick={(action: SlotBulkActions) => {
          liveCalendarBulkActionStore.action = action;
          openModalBulkCapacity();
        }}
        selected={selectedSlots}
      />
    )
  );
};

export const Content = React.memo((props: Props & PropsActions) => {
  const {
    calHasUpdates,
    filterOptions,
    storeSelected,
    storeTimeZone,
    slotsUsage = [],

    // Actions
    saveCalendar,
    resetCalendar,
    fetchCalendarData,
    openModalCreateBlocker,
    openModalEditSlot,
    openModalOrphan,
    calendarEvents,
    calendarSettings,
    updateSelectorEvent,
  } = props;

  const [view, setView] = React.useState<ICalendarViews>('week');
  const [listView, setListView] = React.useState<boolean>(viewStore.view === 'list');
  // only allow bulk editing if expired slots is not selected
  const [selectedSlots, setSelectedSlots] = React.useState<IEventComponent[]>([]);

  const handleFilterUpdate = () => {
    fetchCalendarData();
  };

  const handleOnSelectEvent = (event) => {
    const templates = CONST_PAGE.eventTemplates;

    switch (event.template) {
      case templates.slot:
        openModalEditSlot();
        break;
      case templates.orphanSlot:
        openModalOrphan();
        break;
      case templates.blocker:
        openModalCreateBlocker();
        break;
      default:
        break;
    }
  };

  const handleShoppingModeChange = (e) => {
    if (storeSelected) {
      fetchCalendarData({
        pageFilters: {
          [CONST_PAGE.FIELD_FILTER_SHOPPINGMODE]: safeGet(e, 'value.value', e.value),
        },
        action: 'filter',
      });
    }
  };

  React.useEffect(() => {
    setSelectedSlots(liveCalendarBulkActionStore.selectedSlots ?? []);
  }, [liveCalendarBulkActionStore.selectedSlots]);

  const hiddenClass = `${CONST_PAGE.liveSlotClassName}__hidden`;

  return (
    <>
      <ApiDataProvider endpoint="slotCalendar" endpointPath="/options" name={API_DATA_SLOTS_CALENDAR_OPTIONS} />

      <ModalEditSlot />
      <ModalCreateBlocker />
      <ModalCloseSlot />
      <ModalOrphanDetails />
      <ModalBulkEditSlot />
      <ConfirmationModal
        title="modules.slotManagement.storeCalendar.modalConfirmation.title"
        note="modules.slotManagement.storeCalendar.modalConfirmation.message"
        message="modules.slotManagement.storeCalendar.modalConfirmation.caption"
        confirmButton="modules.slotManagement.storeCalendar.modalConfirmation.btnConfirm"
      />
      <ModalPreventNavigation blockNavigation={calHasUpdates} />

      <StickyBar
        options={[
          {
            onClick: saveCalendar,
            disabled: !calHasUpdates,
            caption: 'generic.button.saveChanges',
          },
          {
            onClick: resetCalendar,
            disabled: !calHasUpdates,
            caption: 'generic.button.undo',
            outline: true,
          },
        ]}
      />
      <FilterBar name={CONST_PAGE.FORM_FILTER}>
        {storeTimeZone && (
          <LocalClock
            className={storeClockClx}
            caption="modules.slotManagement.storeLocalTime"
            timeZone={storeTimeZone}
          />
        )}
        <FilterBar.Select
          onChange={handleShoppingModeChange}
          name={CONST_PAGE.FIELD_FILTER_SHOPPINGMODE}
          placeholder="modules.slotManagement.storeCalendar.edit.fieldShoppingModePlaceholder"
          options={filterOptions.shoppingModes}
        />
        <ConnectedToolbar
          className={`${CONST_PAGE.liveSlotClassName}__toolbar`}
          show
          calendarName={CONST_PAGE.CALENDAR_NAME}
          viewOptions={['day', 'week', 'list']}
          showNavigation
          setListView={(showList: boolean) => setListView(showList)}
          onView={handleFilterUpdate}
          onNavigate={handleFilterUpdate}
        />
      </FilterBar>
      <LayoutFlexBoxItem className={CONST_PAGE.liveSlotClassName}>
        {BulkActionsContainer(listView, getBulkOption(selectedSlots), selectedSlots)}
        {listView && (
          <CalendarListView
            calendarEvents={calendarEvents}
            calendarSettings={calendarSettings}
            onEventClick={(event) => {
              updateSelectorEvent(event.id.toString());
              handleOnSelectEvent(event);
            }}
            headerData={slotsUsage}
            template="liveCalendar"
            listViewType={ListViewTypes.Calendar}
            selectedSlots={selectedSlots}
            setSelectedSlots={(slots: IEventComponent[]) => {
              setSelectedSlots(slots);
              liveCalendarBulkActionStore.selectedSlots = slots;
            }}
          />
        )}

        <Calendar
          name={CONST_PAGE.CALENDAR_NAME}
          headerData={slotsUsage}
          template="liveCalendar"
          toolBarShow={false}
          onView={handleFilterUpdate}
          onNavigate={handleFilterUpdate}
          className={`${CONST_PAGE.liveSlotClassName}__calendar ${
            view === 'day' && `${CONST_PAGE.liveSlotClassName}__dayView`
          } ${listView && hiddenClass}`}
          minHour={6}
          maxHour={23}
          views={['day', 'week']}
          onSelectEvent={handleOnSelectEvent}
          noDataSelected={!storeSelected}
          noDataSelectedMessage="modules.slotManagement.storeCalendar.edit.msgCalendarNoData"
          onViewChange={(e) => setView(e)}
        />
      </LayoutFlexBoxItem>
    </>
  );
});

export default Content;
