// ROUTES PATHS
export const PATH_MAIN = '/searchadmin';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_SEARCH_RESULTS_PREVIEW = '/search-results-preview';
export const PATH_RANKING_SETTINGS = '/rankingsettings';
export const PATH_SEARCH_SETTINGS = '/search-settings';
export const PATH_SYNONYMS_CREATE = 'create'; // path needs /:synonymType
export const PATH_SYNONYMS = '/synonyms';
export const PATH_SYNONYMS_EDIT = 'edit'; // path needs /:synonymId
export const PATH_SYNONYMS_EDIT_FULLPATH = `${PATH_MAIN}${PATH_SYNONYMS}/${PATH_SYNONYMS_EDIT}`; // path needs /:synonymId
export const PATH_SYNONYMS_CREATE_FULLPATH = `${PATH_MAIN}${PATH_SYNONYMS}/${PATH_SYNONYMS_CREATE}`;
export const PATH_SYNONYMS_FULLPATH = `${PATH_MAIN}${PATH_SYNONYMS}`;
export const PATH_RULES = '/rules';
export const PATH_RULES_CREATE = 'create';
export const PATH_RULES_EDIT = 'edit'; // path needs /:ruleId
export const PATH_RULES_FULLPATH = `${PATH_MAIN}${PATH_RULES}`;
export const PATH_RULES_CREATE_FULLPATH = `${PATH_MAIN}${PATH_RULES}/${PATH_RULES_CREATE}`;
export const PATH_RULES_EDIT_FULLPATH = `${PATH_MAIN}${PATH_RULES}/${PATH_RULES_EDIT}`; // path needs /:ruleId

// ACTIONS SPECIFIC TO THIS MODULE
export const ACTION_MERCHANDISING_SAVE_CHANGES = 'ACTION_MERCHANDISING_SAVE_CHANGES';
export const ACTION_MERCHANDISING_SEARCH_PRODUCTS = 'ACTION_MERCHANDISING_SEARCH_PRODUCTS';
export const ACTION_MERCHANDISING_MODAL_VIEW_CHANGES = 'ACTION_MERCHANDISING_MODAL_VIEW_CHANGES';
export const ACTION_MERCHANDISING_MODAL_ADD_PRODUCTS_OPEN = 'ACTION_MERCHANDISING_MODAL_ADD_PRODUCTS_OPEN';
export const ACTION_MERCHANDISING_MODAL_ADD_PRODUCTS_SEARCH_PRODUCTS =
  'ACTION_MERCHANDISING_MODAL_ADD_PRODUCTS_SEARCH_PRODUCTS';
export const ACTION_MERCHANDISING_MODAL_ADD_PRODUCTS_ADD_TO_RESULTS =
  'ACTION_MERCHANDISING_MODAL_ADD_PRODUCTS_ADD_TO_RESULTS';
export const ACTION_MERCHANDISING_MODAL_VIEW_CHANGES_SAVE = 'ACTION_MERCHANDISING_MODAL_VIEW_CHANGES_SAVE';
export const ACTION_MERCHANDISING_GET_PRODUCT_EXPLAIN_INFORMATION =
  'ACTION_MERCHANDISING_GET_PRODUCT_EXPLAIN_INFORMATION';
export const ACTION_RELEVANCE_ATTRIBUTES_GET = 'ACTION_RELEVANCE_ATTRIBUTES_GET';
export const ACTION_RELEVANCE_SEARCH_RESULTS_GET = 'ACTION_RELEVANCE_SEARCH_RESULTS_GET';
export const ACTION_RELEVANCE_SEARCH_RESULTS_CURRENT_GET = 'ACTION_RELEVANCE_SEARCH_RESULTS_CURRENT_GET';
export const ACTION_RELEVANCE_SEARCH_RESULTS_PREVIEW_GET = 'ACTION_RELEVANCE_SEARCH_RESULTS_PREVIEW_GET';
export const ACTION_RELEVANCE_ATTRIBUTES_SAVE_CHANGES = 'ACTION_RELEVANCE_ATTRIBUTES_SAVE_CHANGES';
export const ACTION_RELEVANCE_CURRENT_SCORE = 'ACTION_RELEVANCE_CURRENT_SCORE';
export const ACTION_RELEVANCE_PREVIEW_SCORE = 'ACTION_RELEVANCE_PREVIEW_SCORE';
export const ACTION_RELEVANCE_ATTRIBUTES_RESET = 'ACTION_RELEVANCE_ATTRIBUTES_RESET';
export const ACTION_RELEVANCE_ATTRIBUTES_RESET_GLOBAL = 'ACTION_RELEVANCE_ATTRIBUTES_RESET_GLOBAL';
export const ACTION_RELEVANCE_ATTRIBUTES_CLEAR_DATA = 'ACTION_RELEVANCE_ATTRIBUTES_CLEAR_DATA';
export const ACTION_SYNONYMS_DELETE_SYNONYM = 'ACTION_SYNONYMS_DELETE_SYNONYM';
export const ACTION_SYNONYMS_SAVE_SYNONYM = 'ACTION_SYNONYMS_SAVE_SYNONYM';
export const ACTION_SYNONYMS_SEARCH_SYNONYMS = 'ACTION_SYNONYMS_SEARCH_SYNONYMS';
export const ACTION_SYNONYMS_CHANGE_SYNONYM_STATUS = 'ACTION_SYNONYMS_CHANGE_SYNONYM_STATUS';
export const ACTION_SYNONYMS_GET_SYNONYM_DATA = 'ACTION_SYNONYMS_GET_SYNONYM_DATA';
export const ACTION_SYNONYMS_UPDATE_SYNONYM = 'ACTION_SYNONYMS_UPDATE_SYNONYM';
export const ACTION_SYNONYMS_APPLY_PENDING_CHANGES = 'ACTION_SYNONYMS_APPLY_PENDING_CHANGES';
export const ACTION_SYNONYMS_APPLY_IS_IN_PROGRESS = 'ACTION_SYNONYMS_APPLY_IS_IN_PROGRESS';
export const ACTION_SYNONYMS_SAVED = 'ACTION_SYNONYMS_SAVED';
export const ACTION_DEFAULT_SETTINGS_GET_PAGE_DATA = 'ACTION_DEFAULT_SETTINGS_GET_PAGE_DATA';
export const ACTION_DEFAULT_SETTINGS_RESET_PAGE_DATA = 'ACTION_DEFAULT_SETTINGS_RESET_PAGE_DATA';
export const ACTION_DEFAULT_SETTINGS_SAVE_DATA = 'ACTION_DEFAULT_SETTINGS_SAVE_DATA';
export const RULES_CONFLICT_MODAL = 'RulesConflictModal';
export const CATEGORIES_MODAL = 'CategoriesModal';
export const ACTION_RULES_SAVE_RULE = 'ACTION_RULES_SAVE_RULE';
export const ACTION_RULES_SEARCH_RULES = 'ACTION_RULES_SEARCH_RULES';
export const ACTION_RULES_CHANGE_STATUS = 'ACTION_RULES_CHANGE_STATUS';
export const ACTION_RULES_DELETE_RULE = 'ACTION_RULES_DELETE_RULE';
export const ACTION_RULES_FETCH_RULE_DATA = 'ACTION_RULES_FETCH_RULE_DATA';
export const ACTION_RULES_CURRENT_SCORE_EXPLANATION = 'ACTION_RULES_CURRENT_SCORE_EXPLANATION';
export const ACTION_RULES_PREVIEW_SCORE_EXPLANATION = 'ACTION_RULES_PREVIEW_SCORE_EXPLANATION';
export const ACTION_RULES_PREVIEW_FETCH_CURRENT_DATA = 'ACTION_RULES_PREVIEW_FETCH_CURRENT_DATA';
export const ACTION_RULES_CLEAR_PAGE_DATA = 'ACTION_RULES_CLEAR_PAGE_DATA';
export const ACTION_MODAL_PRODUCT_GET = 'ACTION_MODAL_PRODUCT_GET';
export const ACTION_MODAL_PRODUCT_CLOSE = 'ACTION_MODAL_PRODUCT_CLOSE';
export const ACTION_MODAL_PRODUCTSEARCH_OPEN = 'ACTION_MODAL_PRODUCTSEARCH_OPEN';
export const ACTION_MODAL_PRODUCTSEARCH_CLOSE = 'ACTION_MODAL_PRODUCTSEARCH_CLOSE';
export const ACTION_MODAL_PRODUCTSEARCH_SEARCH = 'ACTION_MODAL_PRODUCTSEARCH_SEARCH';

export const ACTION_RULES_DELETE_RULE_FROM_EDIT_PAGE = 'ACTION_RULES_DELETE_RULE_FROM_EDIT_PAGE';

// API DATA PROVIDERS
export const API_DATA_PROVIDER_SEARCH_RESULTS_PREVIEW = 'ApiDataProviderSearchResults';
export const API_DATA_PROVIDER_MODAL_VIEW_CHANGES = 'ApiDataProviderModalViewChanges';
export const API_DATA_PROVIDER_MODAL_ADD_PRODUCTS_SEARCH = 'ApiDataProviderModalAddProductsSearch';
export const API_DATA_PROVIDER_RELEVANCE_ATTRIBUTES = 'ApiDataProviderRelevanceAttributes';
export const API_DATA_PROVIDER_RELEVANCE_CURRENT = 'ApiDataProviderRelevanceCurrent';
export const API_DATA_PROVIDER_RELEVANCE_PREVIEW = 'ApiDataProviderRelevancePreview';
export const API_DATA_PROVIDER_SYNONYMS_LIST = 'ApiDataProviderSynonymsList';
export const API_DATA_PROVIDER_SYNONYM_DATA = 'ApiDataProviderSynonymData';
export const API_DATA_PROVIDER_CREATE_SYNONYM_DATA = 'ApiDataProviderCreateSynonymData';
export const API_DATA_DEFAULT_SETTINGS_DATA = 'ApiDataProviderDefaultSettingsData';
export const API_DATA_RULES_OPTIONS = 'ApiDataProviderRulesOptions';
export const API_DATA_RULES_LIST = 'ApiDataProviderRulesList';
export const API_DATA_RULE_DATA = 'ApiDataProviderRuleData';
export const API_DATA_RULE_CURRENT_DATA = 'ApiDataProviderRuleCurrentData';
export const API_DATA_RULE_PREVIEW_DATA = 'ApiDataProviderRulePreviewData';
export const API_DATA_MODAL_PRODUCT = 'ApiDataProviderModalProduct';
export const API_DATA_MODAL_PRODUCTSEARCH = 'ApiDataProviderModalProductSearch';
export const API_DATA_PROVIDER_FILTER_STORE_OPTIONS = 'ApiDataProviderFilterStoreOption';

// DATA GRIDS
export const DATA_GRID_SEARCH_RESULTS_PREVIEW = 'DataGridSearchResultsPreview';
export const DATA_GRID_MODAL_ADD_PRODUCT_TO_SEARCH_RESULTS = 'DataGridModalAddProductsToSearchResults';
export const DATA_GRID_MODAL_VIEW_SAVED_CHANGES = 'DataGridModalViewSavedChanges';
export const DATA_GRID_RELEVANCE = 'DataGridRelevance';
export const DATA_GRID_SYNONYMS_LIST = 'DataGridSynonymsList';
export const DATA_GRID_RULES_LIST = 'DataGridRulesList';
export const DATA_GRID_RULES_PREVIEW = 'DataGridRulesPreview';
export const DATA_GRID_MODAL_PRODUCTSEARCH = 'DataGridModalProductSearch';

// FORMS
export const FORM_SEARCH_RESULTS_PREVIEW = 'FormSearchResultsPreview';
export const FORM_MODAL_ADD_PRODUCTS_TO_SEARCH_RESULTS = 'FormModalAddProductsToResults';
export const FORM_MODAL_ADD_PRODUCTS_POSITION_DATA = 'FormModalAddProductsPositionData';
export const FORM_RELEVANCE_RELEVANCE_GENERAL = 'FormRankingRelevanceGeneral';
export const FORM_RELEVANCE_RELEVANCE_ATT = 'FormRankingRelevanceAtt';
export const FORM_RELEVANCE_ATTS_ARRAY = 'attributes';
export const FORM_RELEVANCE_NEWATT_VALUE = 'newAttribute';
export const FORM_SYNONYMS_CREATE_EDIT = 'FormSynonymsCreateEdit';
export const FORM_SYNONYMS_LIST = 'FormSynonymsList';
export const FORM_SYNONYMS_EDIT = 'FormSynonymsEdit';
export const FORM_SEARCH_SETTINGS = 'FormsearchSettings';
export const FORM_SORT_BY_OPTIONS = 'FormSearchByoptions';
export const FORM_RULES_CREATE_RULES = 'FormsRulesCreateRule';
export const FORM_RULES_LIST = 'FormRulesList';
export const FORM_PRODUCT_DETAIL = 'FormProductDetail';
export const FORM_MODAL_PRODUCTSEARCH = 'FormModalProductSearch';

// MODALS
export const MODAL_VIEW_SAVED_CHANGES = 'ModalViewSavedChanges';
export const MODAL_ADD_PRODUCTS_TO_RESULTS = 'ModalAddProductsToResults';
export const MODAL_CREATE_NEW_SYNONYM = 'ModalCreateNewSynonym';
export const MODAL_PRODUCT = 'ModalProduct';
export const MODAL_PRODUCTSEARCH = 'ModalProductSearch';
export const MODAL_PRODUCTSEARCH_HIDE = 'ModalProductSearchHide';
export const MODAL_PRODUCTSEARCH_POSITION = 'ModalProductSearchPosition';

// DEFAULT VALUES
export { DEFAULT_LOCALE_ID, DEFAULT_STORE_ID } from 'config';

export enum SynonymTypes {
  Equivalent = 'Equivalent',
}
