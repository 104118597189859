/* eslint-disable import/no-cycle */
import { useForm } from 'framework/components/ui/FormComponents/Form/useForm';
import { i18n } from 'i18n';
import React from 'react';
import { ConfirmationModal, StatusDropdown } from 'ui';
import { SITEADMIN_STATUS } from './CommonPage';
import { commonPageStatus } from './CommonPageDetails/CommonPageDetails.Interfaces';
import { CommonPageDetailsTranslation } from './CommonPageDetails/CommonPageDetails.Translation';

interface CommonStatusDropdownProps {
  formName : string;
  saveUserStatus: (e) =>void
}
export const CommonStatusDropdown = ({ formName, saveUserStatus } : CommonStatusDropdownProps) => {
  const { changeValue, getValues } = useForm(formName);
  const [message, setMessage] = React.useState('');
  
  const handleChange = (status) => {
    const confirmationnMessage = status === i18n.t(CommonPageDetailsTranslation.Active)
      ? CommonPageDetailsTranslation.reactivateUserConfirm
      : CommonPageDetailsTranslation.deactivateUserConfirm;
    setMessage(confirmationnMessage);
    saveUserStatus({ status });
  };

  const handleCancel = () => {
    const { status } = getValues();
    return status === commonPageStatus.Active
      ? changeValue('status', commonPageStatus.Inactive) : changeValue('status', commonPageStatus.Active);
  };
  return (
    <>
        <ConfirmationModal warning={message} onCancel={handleCancel}/>
        <StatusDropdown
            labelInline
            caption={SITEADMIN_STATUS}
            name="status"
            options={[
              {
                caption: i18n.t(CommonPageDetailsTranslation.Inactive),
                value: commonPageStatus.Inactive,
                colorMapping: commonPageStatus.Inactive,
              },
              {
                caption: i18n.t(CommonPageDetailsTranslation.Active),
                value: commonPageStatus.Active,
                colorMapping: commonPageStatus.Active,
              },
            ]}
            onChange={handleChange}
        />
    </>
  );
};
