import { call, takeEvery } from 'redux-saga/effects';
import { withProgressBar } from 'framework/sagas/extensions/progress';
import { makeRequest, makeRequestAndSaveState } from 'framework/api/make-request';
import { get } from 'framework/services/localStorage/localStorage';
import { useDispatch } from 'framework/utils/useDispatch';
import { toastError } from 'stores/toast';
import { ACTIVE_FULFILLMENT_CENTER, IGNORE_LOCAL_FC } from 'config';
import * as CONST from '../constants';
import { createCrudResourceAction, CRUDPayload } from './crudActionsCreator';

const applyPathParams = (url: string, payload: any) => {
  const pathParamsRegex = new RegExp(/\{\{(.*?)\}\}/g);
  const pathParams = [];
  let interpolatedUrl = url;
  let results = pathParamsRegex.exec(interpolatedUrl);
  while (results !== null) {
    pathParams.push({ str: results[0], val: results[1] });
    results = pathParamsRegex.exec(interpolatedUrl);
  }
  pathParams.forEach((item) => {
    interpolatedUrl = interpolatedUrl.split(item.str).join(payload[item.val]);
  });
  return interpolatedUrl;
};
const buildQueryString = (payload) => Object.keys(payload)
  .map((key) => `${key}=${payload[key]}`)
  .join('&');

function* processCrudResource(crudAction: any) {
  // build CRUD request based on config obj
  const buildRequest = (resource: string, opType: string, payload: any) => {
    const requestOptions = { ...crudAction.config[resource][opType] };
    requestOptions.url = crudAction.config[resource][opType].url
      ? crudAction.config[resource][opType].url
      : crudAction.config[resource].url;
    requestOptions.name = crudAction.config[resource][opType].name || crudAction.config[resource].name;
    // replace all {{placeholders}} in the url with payload props
    requestOptions.url = applyPathParams(requestOptions.url, payload);
    // copy payload to request body if method is PUT or POST
    if (requestOptions.method !== 'GET' && payload) {
      requestOptions.body = { ...payload };
    }
    // append the payload vars to querystring if method is GET
    if (requestOptions.method === 'GET' && payload) {
      requestOptions.url = `${requestOptions.url}?${buildQueryString(payload)}`;
    }
    // append take/skip to querystring regardless of the method
    if (payload?.take && requestOptions.method !== 'GET') {
      const { skip, take } = payload;
      requestOptions.url += `?take=${take}&skip=${skip}`;
    }
    // force a querystring (even in non-GET calls)
    if (payload?.forceQueryString) {
      requestOptions.url += payload.forceQueryString;
    }
    return requestOptions;
  };
  const { payload: { resource, opType, payload } } = crudAction;
  const refresh = payload?.refreshResource ? payload.refreshResource : resource;
  const request = buildRequest(resource, opType, payload);
  const localStorageSkipFcCheck: boolean = get<boolean>(IGNORE_LOCAL_FC, true);
  const skipFcCheck = payload?.ignoreFC || localStorageSkipFcCheck;
  let response;
  // only perform api call if a FC is active
  const activeFC = get(ACTIVE_FULFILLMENT_CENTER, true);

  const { expired } = activeFC || false;
  if (skipFcCheck || (!expired && activeFC)) {
    if (opType === 'retrieve' || payload?.saveState) {
      response = yield call(makeRequestAndSaveState, request);
    } else {
      response = yield call(makeRequest, request);
    }

    if (response.error) {
      if (payload?.errorCallback) {
        payload.errorCallback(response);
      } else {
        toastError(response.error.title || 'generic.serverError');
      }
    } else {
      // call successful: update view
      if (opType !== 'retrieve' && refresh !== 'none') {
        // auto-refresh the updated resource
        const refreshPayload = payload.refreshPayload ? payload.refreshPayload : payload;
        response = yield call(makeRequestAndSaveState, buildRequest(refresh, 'retrieve', refreshPayload));
      }
      if (payload?.log) {
        // eslint-disable-next-line no-console
        console.log(response.data);
      }
      if (payload?.successCallback) {
        payload.successCallback(response.data);
      }
    }
  }
}
export function* watchCrudResource() {
  yield takeEvery(CONST.CRUD_RESOURCE_ACTION, withProgressBar(processCrudResource));
}
export const crud = (payload: CRUDPayload, crudConfig: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  dispatch(createCrudResourceAction(payload, crudConfig));
};
