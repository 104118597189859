import * as React from 'react';
import { formField, IformField } from 'framework/components/hoc/formField';
import {
  RadioGroupPrimitive,
  IRadioGroupPrimitive
} from 'framework/components/ui/FormComponents/Primitives';

const RadioGroup = (props: IRadioGroupPrimitive) => {
  return <RadioGroupPrimitive {...props} />;
};

export default (formField(RadioGroup) as any) as React.StatelessComponent<
IRadioGroupPrimitive & IformField
>;
