export const toKebabCase = (str: string) => (
  str
    .trim()
    .replace(/([A-Z])([A-Z])/g, '$1-$2')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
);

export const toCamelCase = (str: string) => (
  str
    .trim()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
    .replace(/\s+/g, '')
);

export const toPascalCase = (str: string) => (
  str
    .trim()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toUpperCase() : word.toLowerCase()))
    .replace(/\s+/g, '')
);

export const toSentenceCase = (str: string, allUppercase: boolean = false) =>
  str
    .replace(/((?!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 || allUppercase ? word.toUpperCase() : word.toLowerCase()
    );

export const ellipsis = (str: string, maxChars: number) => (
  str && str.length > maxChars ? `${str.substring(0, maxChars)}...` : str
);

export const unescape = (text: string) => (
  new DOMParser().parseFromString(text, 'text/html').querySelector('html').textContent
);
