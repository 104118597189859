import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { translations } from './translations';
import * as constants from './constants';

export const DashboardRoute: AppRoute = {
  pageTitle: translations.reports,
  menuTitle: translations.allReports,
  path: constants.reportDashboardPath,
  authClaim: AuthorizationClaims.REPORTING_REPORTS,
  Component: () => import('./dashboard'),
};

export const PrintReportRoute: AppRoute = {
  pageTitle: translations.reportDetails,
  menuTitle: translations.printReport,
  path: `${constants.reportPrintPath}/:id`,
  authClaim: AuthorizationClaims.REPORTING_REPORTS,
  Component: () => import('./printreport'),
  parent: DashboardRoute,
};

export const reportRoutes: AppRoute[] = [
  DashboardRoute,
  PrintReportRoute,
];
