import classNames from 'classnames';
import * as React from 'react';
import { prefixClassName } from '../_conf';
import { IPanel } from './Panel.interfaces';
// eslint-disable-next-line import/no-cycle
import PanelHeader from './PanelHeader';
// eslint-disable-next-line import/no-cycle
import { PanelFooter } from './PanelFooter';
// eslint-disable-next-line import/no-cycle
import { MessageList } from '../index';
import './_style.scss';

export const clx = prefixClassName('panel');

const Panel = (props: IPanel) => {
  const {
    title,
    subtitle,
    children,
    className,
    classNameBody,
    optional,
    messages,
    tabIndex,
    messageFirst,
    headerControls,
  } = props;

  return (
    <div className={classNames(clx, className)} id={title} tabIndex={tabIndex}>
    {messageFirst 
      ? <>
          <MessageList className={`${clx}__messageList`} messages={messages} />
          <PanelHeader title={title} subtitle={subtitle} optional={optional} headerControls={headerControls} />
        </>
      : <>
          <PanelHeader title={title} subtitle={subtitle} optional={optional} headerControls={headerControls} />
          <MessageList className={`${clx}__messageList`} messages={messages} />
        </>
    }


      <div className={classNames(`${clx}__body`, classNameBody)}>{children}</div>
    </div>
  );
};

Panel.Footer = PanelFooter;

export default Panel;
