// TODO delete this file when all is migrate to stores/toast
import {
  addToast,
  toastError,
  toastSuccess,
  Toast,
} from 'stores/toast';

interface IToastMessages {
  id?: number | string;
  caption: string;
  type?: Toast['type'];
  delay?: number;
}

/**
 * @deprecated use addToast instead
 * @see addToast stores/toast.ts
 */
export const addToastlistMessage = (payload: IToastMessages[]) => {
  payload.forEach((m) => addToast(m.caption, m.type, m.delay));
  return {
    type: 'DEPRECATED_addToastlistMessage',
  };
};

/**
 * @deprecated use toastSuccess instead
 * @see toastSuccess stores/toast.ts
 */
export const addMessage = (message: string | string[], delay?: number) => {
  toastSuccess(message, delay);
  return {
    type: 'DEPRECATED_addMessage',
  };
};

/**
 * @deprecated use toastError instead
 * @see toastError stores/toast.ts
 */
export const addMessageError = (message: string | string[], delay?: number) => {
  toastError(message, delay);
  return {
    type: 'DEPRECATED_addMessageError',
  };
};
