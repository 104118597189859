import { select } from 'redux-saga/effects';
import { safeGet } from 'framework/utils';
import { calendarSelectors } from 'framework/selectors';
import { formatDateTimeISO } from 'framework/utils/datetimes';
import { CALENDAR_NAME, eventTemplates } from '../../../constants';
import { selectors } from '../..';
import * as types from './types';

export function* dataFormated() {
  const storeId = yield select(selectors.getStoreId);
  const updatedSlots = yield select(calendarSelectors.getUpdatedEvents, CALENDAR_NAME);
  const deletedSlots = yield select(calendarSelectors.getDeletedEvents, CALENDAR_NAME);
  const templates = eventTemplates;

  const blockingSlots: types.IblockingSlot[] = updatedSlots
    .filter((slot) => slot.template === templates.blocker)
    .map((slot) => {
      const shoppingModes = safeGet(slot, 'data.shoppingModes', []);

      return {
        storeId,
        id: slot.id,
        shoppingModes: shoppingModes.map((sm) => sm.value),
        start: formatDateTimeISO(slot.start),
        end: formatDateTimeISO(slot.end),
        note: slot.data.note,
      };
    });

  const capacityUpdates: types.IcapacityUpdate[] = updatedSlots
    .filter((slot) => slot.template === templates.slot)
    .map((slot) => ({
      timeSlotId: slot.id,
      slotExpirationTime: formatDateTimeISO(slot.data.endDate),
      newCapacity: slot.data.capacity,
    }));

  const openCloseUpdates: types.IopenCloseUpdate[] = updatedSlots
    .filter((slot) => slot.template === templates.slot)
    .map((slot) => ({
      timeSlotId: slot.id,
      slotExpirationTime: formatDateTimeISO(slot.data.endDate),
      isClosed: !!slot.data.isClosed,
      note: slot.data.isClosedNotes,
    }));

  const deletedBlockingSlots: string[] = deletedSlots
    .filter((slot) => slot.template === templates.blocker)
    .map((slot) => slot.id);

  const body: types.PropsSave = {
    blockingSlots,
    deletedBlockingSlots,
    capacityUpdates,
    openCloseUpdates,
  };

  return body;
}
