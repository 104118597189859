import * as React from 'react';
import { connect } from 'react-redux';
import { inProgress } from './ProgressBar.actions';

export const withProgressBar = Component => {
  const ComponentWrapper = (props: any) => {
    return <Component {...props} />;
  };

  const mapStateToProps = state => {
    return {
      inProgress: state.inProgress
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      setInProgress: (value: boolean) => dispatch(inProgress(value))
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWrapper);
};
