import * as React from 'react';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import {
  GoToTop,
  LayoutFlexBox,
  MessageList,
  LayoutFlexBoxItem,
} from 'framework/components/ui';
import { willChangeState } from 'framework/components/ui/Page/usePageState';
import { PageContext } from './PageContext';
import { PageHeader } from './PageHeader';
import { PageContentProps, PageProps } from './types';
import './styles.scss';

const getPageStateNameFromPath = (path: string) => {
  const [, moduleName, page] = path.split('/');
  return `${moduleName}/${page}`;
};

const clx = prefixClassName('app-page');
const contentClassName = prefixClassName('page');
export const Page = <T extends {}>(props: PageProps<T>) => {
  const {
    appRoute,
    hideBreadCrumbs,
    className,
    title = (appRoute?.pageTitle) || '',
    titleDetails = '',
    pageInfo: { pageTitle = title, pageTitleDetails = titleDetails } = {},
    pageParts = {},
    history,
    location,
    match,
    messages,
    onPageLoad,
  } = props;
  const routeProps = {
    history,
    location,
    match,
  };
  // clears the previous page states if navigated away from page family
  const pageStateName = getPageStateNameFromPath(location.pathname);
  const usePageState = React.useCallback(willChangeState(pageStateName), []);
  
  const [titles, setTitles] = React.useState({ pageTitle, pageTitleDetails });
  const [Gap, setGap] = React.useState<React.ComponentType | React.ComponentType<PageContentProps<T>>>(null);

  const updatePageTitle = React.useCallback((pageTitles) => {
    setTitles((prev) => ({ ...prev, ...pageTitles }));
  }, []);

  const updatePageGap = React.useCallback((newGap: React.ComponentType | React.ComponentType<PageContentProps<T>>) => {
    setGap(newGap);
  }, []);

  React.useEffect(() => {
    // resets scroll position back to the top of the page
    window.scroll(0, 0);

    if (onPageLoad) {
      onPageLoad();
    }
  }, []);

  return (
    <PageContext.Provider value={{ usePageState, updatePageTitle, updatePageGap, appRoute }}>
      <LayoutFlexBox className={classNames(clx, className)} flexWrap="wrap">
        <PageHeader
          appRoute={appRoute}
          hideBreadCrumbs={hideBreadCrumbs}
          title={titles.pageTitle}
          titleDetails={titles.pageTitleDetails}
          headerComponents={pageParts.headerComponents}
        />
        <MessageList messages={messages} className={`${clx}__messages`} />
        {
          Gap && (
            <LayoutFlexBox className={`${contentClassName} mrg-top-10`}>
              <LayoutFlexBoxItem flexGrow={1} flexBasis="100%">
                  {Gap}
                </LayoutFlexBoxItem>
            </LayoutFlexBox>
          )
        }
        <LayoutFlexBox className={contentClassName}>
          <LayoutFlexBoxItem flexGrow={1} flexBasis="100%">
            <pageParts.content routeProps={routeProps} />
          </LayoutFlexBoxItem>
        </LayoutFlexBox>
        <GoToTop />
      </LayoutFlexBox>
    </PageContext.Provider>
  );
};

export default Page;
