/* ********************* */
/* -- Recommendations -- */
/* ********************* */

interface Base {
  id: string;
  name: string;
  headline?: string;
  stores: string[];
  priority: string | number;
  startDate: string | Date;
  endDate: string | Date;
  locations: {
    id: string;
    label: string;
  }[];
  state?: RecommendationState;
  actions?: Action[];
  conditions?: any;
}

export enum SimpleStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface Recommendation extends Base {
  status?: RecommendationStatus;
}

export interface RecommendationDetails extends Recommendation {
  // actions?: Action[];
  conditions?: Condition[];
  strores?: ApiStoreRecommendation[];
  siteId?: string;
  status?: RecommendationStatus;
  // categories: string[];
  resultsCount: number;
  randomize: boolean;
}
export enum RecommendationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
export enum RecommendationState {
  Active = 'active',
  Inactive = 'inactive',
  Scheduled = 'scheduled',
  Expired = 'expired',
}
export enum RecommendationRule {
  // There ar more options available loaded dynamically,
  // but 'similar' needs an special treatment
  Similar = 'MatchesCurrent',
}

export interface Condition {
  operationType: string;
  appliesOnProperty: string;
  appliesOnValues: string[];
}

export interface ProviderParams {
  [k: string]: string;
}

export interface Action {
  filters: ActionFilter[];
  appliesOnProperty: string;
  appliesOnValues: string[];
  externalProviderParameters?: ProviderParams;
}
export interface ActionFilter {
  applyActionOperation: string;
  appliesOnProperty: string;
  appliesOnValues: string[];
}

export interface ApiStoreRecommendation {
  orgHierarchyNodeId: 'string';
  id: 'string';
  label: 'string';
}

export interface ApiLocationOptions {
  label: string;
  value: string;
}

/* ********************* */
/* ------ Recipes ------ */
/* ********************* */

export interface RecipeImage {
  alt: string;
  title: string;
  url: string;
}
// Model used on list page
export interface Recipe {
  id: string;
  name?: string;
  // img?: string;
  galleryImage: RecipeImage;
  category?: string[];
  nutritionTypes?: string[];
  start?: string | Date;
  end?: string | Date;
  priority?: number;
  status?: SimpleStatus | RecipeState;
  tags?: {
    [tag: string]: string[];
  };
  stores?: {
    label?: string;
    value?: string;
  }[];
}

export enum RecipeStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum RecipeState {
  Scheduled = 'Scheduled',
  Expired = 'Expired',
}

export interface Listing extends Base {
  status?: ListingStatus;
}
export enum ListingStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ListingRule {
  // There ar more options available loaded dynamically,
  // but 'similar' needs an special treatment
  Similar = 'MatchesCurrent',
  Attributes = 'attributes',
}
export interface ListingDetails extends Listing {
  // actions?: Action[];
  conditions?: Condition[];
  strores?: ApiStoreRecommendation[];
  siteId?: string;
  // categories: string[];
  resultsCount: number;
}
