import classnames from 'classnames';
import * as React from 'react';
import { IDualSelectItemList } from './ListItem.interfaces';

export const DualSelectListItem = (props: IDualSelectItemList) => {
  const {
    caption,
    index,
    isHighlighted = false,
    disabled = false,
    className,
    onClick,
  } = props;

  const onClickHandler = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <div
      className={classnames(
        className,
        isHighlighted && `${className}--selected`,
        disabled && `${className}--disabled`,
      )}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      role="menuitem"
      tabIndex={index}
    >
      {caption}
    </div>
  );
};

export default DualSelectListItem;
