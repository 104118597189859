import { createSelector } from 'reselect';
import { apiClientSelectors, calendarSelectors } from 'framework/selectors';
import { safeGet } from 'framework/utils';
import {
  API_DATA_SLOTS_TEMPLATES_EDIT,
  API_DATA_SLOTS_TEMPLATES_EDIT_OPTIONS,
  API_DATA_SLOTS_TEMPLATES_EDIT_AREAS,
} from 'framework/modules/slotManagement/constants';
import moment from 'moment';
import { FORM_EDIT_CALENDAR, FulfillmentTypes } from '../pageParts/constants';

export const getTemplateFormInitialData = (state) =>
  apiClientSelectors.selectApiClientData(state, API_DATA_SLOTS_TEMPLATES_EDIT);
export const getTemplateIsFetching = (state) => apiClientSelectors.getIsFetching(state, API_DATA_SLOTS_TEMPLATES_EDIT);
export const getSlotOptions = (state) =>
  apiClientSelectors.selectApiClientState(state, API_DATA_SLOTS_TEMPLATES_EDIT_OPTIONS);

export const getCalendarEvents = (state) => calendarSelectors.getCalendarEvents(state, FORM_EDIT_CALENDAR);

export const getCalendarSettings = (state) => calendarSelectors.getCalendarSettings(state, FORM_EDIT_CALENDAR);

export const getDate = (
  day: number = 1, // 0 = Sunday, ..., 6 = Saturday
  time: string = '00:00:00'
) => {
  // If it is 0, it is next week Sunday
  const weekDay = day !== 0 ? day : 7;

  return new Date(`October ${weekDay}, 2018 ${time}`);
};

export const getDaysCapacity = createSelector([getCalendarEvents], (calendarEvents) => {
  const capacities = calendarEvents.reduce((allEvents, event) => {
    const { data = {} } = event;
    const { day, capacity, fulfillmentType } = data;
    const localEvents = { ...allEvents };
    const isInstacart = fulfillmentType.value === FulfillmentTypes.Instacart;
    // Returns:
    // {
    //   0: capacityNumber,  -> Sunday
    //   1: capacityNumber,  -> Monday
    //   ...
    // }
    localEvents[day] =
      day in localEvents
        ? {
          capacity: isInstacart ? localEvents[day]?.capacity : capacity + localEvents[day]?.capacity,
          ...(isInstacart || localEvents[day]?.type ? { type: FulfillmentTypes.Instacart } : {}),
        }
        : { capacity: !isInstacart ? capacity : 0, ...(isInstacart ? { type: fulfillmentType } : {}) };

    return localEvents;
  }, {});

  const capacitiesByDay = [];
  Object.keys(capacities).forEach((key) => {
    capacitiesByDay.push({
      capacity: capacities[key].capacity,
      fulfillmentType: capacities[key]?.type?.value ?? capacities[key]?.type ?? null,
      weekDay: key,
      day: getDate(parseInt(key, 10)),
    });
  });

  return capacitiesByDay;
});

/* ****************** */
/* -- Tabs - Slots -- */
/* ****************** */
export const getSelectedSlot = (state) => calendarSelectors.getSelectedEvent(state, FORM_EDIT_CALENDAR) || {};
export const getCalendarHasChanges = (state) => !calendarSelectors.isPristine(state, FORM_EDIT_CALENDAR);

/* ******************* */
/* - Tabs - Schedule - */
/* ******************* */
export const areasHierarchy = (state) => {
  const data = apiClientSelectors.selectApiClientState(state, API_DATA_SLOTS_TEMPLATES_EDIT_AREAS);

  return safeGet(data, 'root.children', []);
};

interface Store {
  id?: string;
  value?: string;
  type?: 'root' | 'store' | 'deliveryarea';
  children: Store[];
}

interface StoreMap {
  [storeId: string]: Store;
}

const mapStores = (store: Store): StoreMap => {
  const mapped = store.children.reduce(
    (map, s) => ({
      ...map,
      ...mapStores(s),
    }),
    {}
  );

  if (store.id) {
    mapped[store.id] = store;
  }

  return mapped;
};

export const getMappedDeliveryAreas = (state) => {
  const data = apiClientSelectors.selectApiClientState(state, API_DATA_SLOTS_TEMPLATES_EDIT_AREAS);

  return data && data.root ? mapStores(data.root) : {};
};

export const defaultRange = createSelector([getTemplateFormInitialData], (FormInitialData) => {
  const ranges = FormInitialData.ranges || [];

  // TODO: send just day and month and build a different timepicker
  // with no option for year
  return ranges.map((r) => ({
    iniTime: new Date(`${r.start.month} ${r.start.day}, ${moment().year()}`),
    endTime: new Date(`${r.end.month} ${r.end.day}, ${moment().year()}`),
  }));
});
