import { getApiUrl } from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { FeatureState } from './types';

export const isSearchRulesMigrated = async (): Promise<FeatureState> => {
  const result = await fetchData<{ enableMigratedRules: boolean }>({
    url: getApiUrl('/search/featureflags'),
  });

  // Negating due to logic being that if feature is true, then Preview and positioning
  // route needs to be hidden and drag & drop in search rules needs to show
  // If feature is false, then no changes to route and no drag & drop in search rules
  return {
    isEnabled: !result?.data?.enableMigratedRules ?? false,
  };
};
