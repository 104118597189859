import * as classnames from 'classnames';
import * as React from 'react';
import Text from 'framework/components/ui/Text';
import { ISubTab } from './SubTab.interfaces';

export class SubTab extends React.PureComponent<ISubTab> {
  static defaultProps: Partial<ISubTab> = {
    isOpen: false,
  };

  onClickHandler = (e) => {
    this.props.onClick(this.props.label);
    e.preventDefault();
  };

  render() {
    const { className, isOpen, label } = this.props;

    return (
      <button
        type="button"
        className={classnames(className, isOpen && `${className}--checked`)}
        onClick={this.onClickHandler}
        aria-label={label || 'Sub tab'}
      >
        <Text caption={label} />
      </button>
    );
  }
}

export default SubTab;
