import { join } from 'path';
import i18n from 'i18n';
import { history } from 'framework/store';
import { buildQueryString } from 'framework/utils/queryString';
import { TitleType, AppRouteBase } from './Navigation.interfaces';

export const HOMEURL = '/';
export const getRoutePath = (...paths: string[]): string => join(HOMEURL, ...paths);
export const resolveTitle = (title: TitleType) => (typeof title === 'function' ? title() : i18n.t(title));

const replaceRouteParam = (path, key, value) => path.replace(new RegExp(`:${key}`, 'g'), value);

export const getRouteUrl = (appRoute: AppRouteBase, routeParams: object = {}) => {
  const url = Object.keys(routeParams)
    .reduce((path, param) => replaceRouteParam(path, param, routeParams[param]), appRoute.path);

  return url;
};

export const goToRoute = (appRoute: AppRouteBase, routeParams: object = {}, queryString: object = {}) => {
  history.push(`${getRouteUrl(appRoute, routeParams)}${buildQueryString(queryString)}`);
};
