import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { call, put } from 'redux-saga/effects';
import { config } from 'config';
import { toastListActions } from 'framework/actions';
import { makeRequest } from 'framework/api/make-request';
import { SITEADMIN_LOAD_USER } from '../CommonPage';
import { storeUserAction } from '../CommonPage/CommonPage.Actions';

function* process({ payload }) {
  const { userid } = payload;
  const { data, error } = yield call(makeRequest, {
    url: `${config.apiEndpoints.users}/${userid}`,
    name: SITEADMIN_LOAD_USER,
  });

  if (error) {
    yield put(toastListActions.addMessageError(error));
  } else {
    // store loaded user into redux
    yield put(storeUserAction({ user: data }));
  }
}

export function* watchLoadUser() {
  yield takeEveryWithProgressBar(SITEADMIN_LOAD_USER, process);
}
