/* eslint-disable @typescript-eslint/no-use-before-define */
import { CmsLayoutType, CmsPageRow } from '../CmsComponents.interfaces';
import { resolveTypeOfComponentFromRow, rowIsCarouselBased } from '../common/PageBuilderEngine/PageBuilder/utils';
import { ContentManagementReduxState } from '../contentmanagement.interfaces';

/**
 *
 * @param rowIndex selected row index
 * @param colIndex column index of selected layout. If layout is row or container (carousel-based) instead then colIndex is undefined
 * @param state redux state
 * @returns
 */

export const detachSharedComponentFromReducer = (
  rowIndex: number,
  colIndex: number,
  state: ContentManagementReduxState,
): ContentManagementReduxState => {
  if (state.sharedComponentOpen) {
    return ({
      ...state,
      rowEditorData: {
        ...state.rowEditorData,
        ...(state.rowEditorData.columns && ({
          columns: state.rowEditorData.columns.map((column, _colIndex) => {
            if (colIndex !== _colIndex) {
              return column;
            }
            const { metadata, ...rest } = column;
            return rest;
          }),
        })),
        ...(state.rowEditorData.items && ({
          items: state.rowEditorData.items.map((item, _itemIndex) => {
            if (colIndex !== _itemIndex) {
              return item;
            }
            const { metadata, ...rest } = item;
            return rest;
          }),
        })),
      },
    });
  } 

  return ({
    ...state,
    loadedPage: {
      ...state.loadedPage,
      cmsContent: {
        ...state.loadedPage.cmsContent,
        rows: state.loadedPage.cmsContent.rows.map((row, _rowIndex) => {
          if (rowIndex !== _rowIndex) {
            return row;
          }

          return removeSharedFromRow(row, colIndex);
        }),
      },
    },
    /**
     * If this row is being edited in layout-editor then remove shared component
     * if exists
     */
    rowEditorData: (!state.rowEditorData
      || (resolveTypeOfComponentFromRow(state.rowEditorData) !== 'Row' && colIndex === undefined))
      ? state.rowEditorData : removeSharedFromRow(state.rowEditorData, colIndex),
  });
};

const removeSharedFromRow = (row: CmsPageRow, colIndex: number) => {
  const type: CmsLayoutType = resolveTypeOfComponentFromRow(row);

  if (colIndex === undefined) {
    // detach shared row
    if (type === 'Row') {
      const { metadata, ...rest } = row;

      return {
        ...rest,
      };
    }

    // detach carousel-based or large component
    const { metadata, ...rest } = row.columns[0];
    const column = rest;

    return {
      ...row,
      columns: [column],
    };
  }

  return {
    ...row,
    columns: row.columns.map((col, _colIndex) => {
      // non-carousel based layouts
      if (!rowIsCarouselBased(row)) {
        if (_colIndex !== colIndex) {
          return {
            ...col,
          };
        }

        const { metadata, ...rest } = col;

        return {
          ...rest,
        };
      }

      // carousel bases layouts
      return {
        ...col,
        items: col.items.map((colItem, __index) => {
          if (__index !== colIndex) {
            return {
              ...colItem,
            };
          }

          const { metadata, ...rest } = colItem;

          return {
            ...rest,
          };
        }),
      };
    }),
  };
};
