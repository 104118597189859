import './_style.scss';

import * as React from 'react';

import { IProgressBarProps } from './ProgressBar.interface';


const ProgressBar = (props:IProgressBarProps) => {
  let animationFrameRequestId: number;


  const totalFrames = 90; // 90 frames to cover in 1.5 seconds @ 60fps (avg framerate)
  const limit:number = 0.9; // 90% of progress bar full
  const regularStep:number = limit / totalFrames;
  const quickStep:number = 0.05; // fill the progress bar quickly if finished or failed
  const [active, setActive] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const { inProgress } = props;
  // long decimal numbers are never good with js
  const roundDecimals = (num:number) => Math.floor(num * 100) / 100;

  const updateProgressBar = () => {
    if (roundDecimals(progress + regularStep) < 1) {
      const increment = inProgress ? regularStep : quickStep;
      setProgress(roundDecimals(progress + increment));
      if (inProgress) {
        animationFrameRequestId = window.requestAnimationFrame(updateProgressBar);
      }
    }
  };


  const finishProgressBar = () => {
    window.cancelAnimationFrame(animationFrameRequestId);
    if (progress < 1) {
      animationFrameRequestId = window.requestAnimationFrame(updateProgressBar);
    } else {
      window.cancelAnimationFrame(animationFrameRequestId);
      setActive(false);
      setProgress(0);
    }
  };


  React.useEffect(() => {
    // if loading is set to true in the store AND is not already underway in this component
    // commence the progress bar animation
    if (inProgress && !active) {
      setActive(true);
      animationFrameRequestId = window.requestAnimationFrame(updateProgressBar);
    }

    // if loading is set to false in the store AND the progress bar is currently animating
    // finish the progress bar animation quickly
    if (!inProgress && active) {
      finishProgressBar();
    }
  }, [inProgress, progress]);


  return active ? (
      <progress className="mwg-progress-bar" value={progress} />
  ) : null;
};

export default ProgressBar;
export { withProgressBar } from './withProgressBar';
