import { call, put, select } from 'redux-saga/effects';
import { formSelectors } from 'framework/selectors';
import { makeRequest } from 'framework/api/make-request';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { getDefaultSettingPageDataAction } from '../actions';
import { config } from 'config';
import * as CONST from 'framework/modules/searchadmin/constants';

export default function* watchSaveDefaultSettingAction() {
  yield takeEveryWithProgressBar(CONST.ACTION_DEFAULT_SETTINGS_SAVE_DATA, process);
}

export function* process() {
  const defaultLocaleId = CONST.DEFAULT_LOCALE_ID; // In future, this will come from somewhere else
  const formData = yield select(formSelectors.getFormFieldValues, CONST.FORM_SEARCH_SETTINGS);

  // 1. Make Reindexing Request
  yield call(makeRequest, {
    name: CONST.API_DATA_DEFAULT_SETTINGS_DATA,
    url: `${config.apiEndpoints.search}/settings`,
    method: 'PUT',
    body: {
      defaultLocaleId,
      defaultStoreId: formData.defaultStoreId
    }
  });

  // 2. Get up to date data from server
  yield put(getDefaultSettingPageDataAction());
}
