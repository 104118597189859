import { ISelectPrimitive } from '../Primitives/SelectPrimitive';
import { ISelectPrimitiveOptionsProps } from '../Primitives/SelectPrimitive/SelectPrimitive.interface';

export interface ISelectProps extends ISelectPrimitive {}

export type ISelectOptions<T = string> = ISelectPrimitiveOptionsProps<T>;

export enum SelectMenuPlacements {
  Auto = 'auto',
  Top = 'top',
  Bottom = 'bottom'
}
