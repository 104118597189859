export enum Features {
  DeliverySolutions = 'DeliverySolutions',
  ZetesIntegration = 'ZetesIntegration',
  CMSFreeFormAllowScript = 'CMSFreeFormAllowScript',
  ImmediatePicks = 'immediatePicks',
  ShoppingRulesOption = 'ShoppingRulesOption',
  Brandbank = 'Brandbank',
  Webhooks = 'Webhooks',
  SearchRulesMigrated = 'SearchRulesMigrated',
  FlippEnabled = 'FlippEnabled',
  MultiLingual = 'MultiLingual',
  BusinessAccountMultipleStores = 'BusinessAccountMultipleStores',
  Segmentation = 'Segmentation',
  // CustomersV2 is for Per Site Properties
  CustomersV2 = 'CustomersV2',
  RecommendationsActionFlags = 'RecommendationsActionFlags',
  AllowFeatureFlagsHeaderOverride = 'AllowFeatureFlagsHeaderOverride',
}

export enum DeliveryIntegrationProviderNames {
  DeliverySolutions = 'deliverysolutions',
  ZetesIntegration = 'zetes',
}

export type FeatureState = {
  isEnabled: boolean;
  data?: {};
};

export type AppFeatures = {
  [Features.DeliverySolutions]: FeatureState;
  [Features.ShoppingRulesOption]: FeatureState;
  [Features.CMSFreeFormAllowScript]: FeatureState;
  [Features.FlippEnabled]: FeatureState;
  [Features.ImmediatePicks]: FeatureState;
  [Features.Brandbank]: FeatureState;
  [Features.ZetesIntegration]: FeatureState;
  [Features.Webhooks]: FeatureState;
  [Features.SearchRulesMigrated]: FeatureState;
  [Features.MultiLingual]: FeatureState;
  [Features.BusinessAccountMultipleStores]: FeatureState;
  [Features.Segmentation]: FeatureState;
  [Features.CustomersV2]: FeatureState;
  [Features.RecommendationsActionFlags]: FeatureState;
  [Features.AllowFeatureFlagsHeaderOverride]: FeatureState;
  loadingFeatures: {
    [Features.DeliverySolutions]?: Promise<FeatureState>;
    [Features.ImmediatePicks]?: Promise<FeatureState>;
    [Features.ShoppingRulesOption]?: Promise<FeatureState>;
    [Features.Brandbank]?: Promise<FeatureState>;
    [Features.ZetesIntegration]?: Promise<FeatureState>;
    [Features.Webhooks]?: Promise<FeatureState>;
    [Features.SearchRulesMigrated]?: Promise<FeatureState>;
    [Features.MultiLingual]?: Promise<FeatureState>;
    [Features.BusinessAccountMultipleStores]?: Promise<FeatureState>;
    [Features.Segmentation]?: Promise<FeatureState>;
    [Features.CustomersV2]?: Promise<FeatureState>;
    [Features.RecommendationsActionFlags]?: Promise<FeatureState>;
  };
  isLoading: boolean;
};

export type OFFeatureFlag = {
  id: string;
  name: string;
  enabled: boolean;
};

export type BusinessAccountFeatureFlagResponse = {
  MultiBusinessAccountsEnabled: boolean;
};

export type SegmentationFeatureFlagResponse = {
  Segmentation: boolean;
};

export type DeliveryIntegrationProviders = {
  deliveryProviders: {
    internalName?: string;
    providerName?: string;
  }[];
};
