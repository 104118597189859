import * as React from 'react';

export enum FilterTabs {
  MoreOptions = 'moreOptions',
  Columns = 'columns',
}

export enum FilterState {
  Collapsed = 'collapsed',
  Collapsing = 'collapsing',
  Expanding = 'expanding',
  Expanded = 'expanded',
}

interface FilterBarContextProps {
  state: FilterState;
  canSaveSearch: boolean;
  localStorageName?: string;
  overrideKey?: boolean;
  openTab?: FilterTabs;
  onSaveSearch?: () => void;
  toggle: (tab: FilterTabs, content?: React.ReactNode) => void;
  applyFilters: () => void;
}

const DEFAULT: FilterBarContextProps = {
  state: FilterState.Collapsed,
  canSaveSearch: false,
  onSaveSearch: () => {},
  toggle: () => {},
  applyFilters: () => {},
};

export const FilterBarContext = React.createContext<FilterBarContextProps>(DEFAULT);

export const useFilterBarContext = () => React.useContext(FilterBarContext);
