import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import { hasSameItems } from 'framework/utils/helpers';
import { HierarchyProps, HierarchyContextStoreProps } from './hierarchy.interfaces';
import { HierarchyProvider } from './hierarchy.provider';
import { HierarchyTable } from './hierarchy.table';
import {
  useHierarchyStore,
  exportTopSelectionIds,
  exportSelectionNodes,
  isDirty,
} from './hierarchy.store';
import './style.scss';

const hierarchyClassName = conf.prefixClassName('hierarchy');

export const useHierarchy = (props: HierarchyProps) => {
  // get context store
  const store = useHierarchyStore({
    ...props,
    data: props.data || [],
  } as any);

  const { ...rest } = props;

  // prepare provider context
  const context: Partial<HierarchyContextStoreProps> = {
    ...rest,
    ...store,
    sortBy: props.sortBy,
  };

  // update store in case highlightedId is loaded from backend with delay
  React.useEffect(() => {
    if (context.highlightedId !== props.highlightedId) {
      context.highlightRow({
        id: props.highlightedId,
      });
    }
  }, [props.highlightedId]);

  // If new `selectedIds` are provided to Hierarchy, replace current values
  React.useEffect(() => {
    const selectedIds = props.selectedIds || [];
    if (!hasSameItems(context.initial.selectedIds, selectedIds)) {
      context.setSelectedIds(selectedIds);
    }
  }, [props.selectedIds]);

  const HierarchyComponent = () => (
      <div className={hierarchyClassName}>
        <HierarchyProvider {...context}>
          <HierarchyTable {...props} />
        </HierarchyProvider>
      </div>
  );

  return {
    component: HierarchyComponent,
    highlightedId: context.highlightedId,
    isDirty: isDirty(context.selectedIds, context.initial.selectedIds),
    getSelectedIds: context.selectedIds,
    getTopSelectedIds: () => exportTopSelectionIds({ data: context.data, selectedIds: context.selectedIds }),
    getSelectedNodes: () => exportSelectionNodes({
      hierarchyNodes: context.data,
      selectedIds: context.selectedIds,
    }),
    getExpandedIds: () => context.expandedIds,
    reset: () => context.reset(),
    resetSelections: () => context.resetSelectedIds(),
    toggleRow: (rowId: string) => context.toggleRow(rowId),
    updateData: ({ data }) => context.updateData({ data }),
  };
};
