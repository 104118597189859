import type { InferableFunction, ResolveType } from 'types';

interface LocalStorageData<T = any> {
  timestamp: number;
  expireDate: undefined | number; // null/undefined means no expiration time
  data: T;
}

let userId = 'loggedout';

const getKey = (key: string, global?: boolean) => {
  const prefix = global ? 'global' : window.location.pathname;

  return `${userId}__${prefix}__${key}`;
};

const getPrivate = <T = any>(key: string): LocalStorageData<T> => {
  const savedItem = localStorage.getItem(key);

  if (savedItem) {
    try {
      const parsed = JSON.parse(savedItem) as LocalStorageData<T>;

      // deletes item if it has expired
      if (parsed?.expireDate < Date.now()) {
        localStorage.removeItem(key);
        return undefined;
      }

      // returns the saved item
      return parsed;
    } catch {
      return undefined;
    }
  }

  return undefined;
};

export const start = (user: string) => {
  // clears expired data
  Object.keys(localStorage).forEach(getPrivate);

  // scopes the storage for a specific user
  userId = user;
};

export const save = <T = any>(
  key: string,
  data: T,
  expireDate?: Date,
  global?: boolean,
  overrideKey: boolean = false,
) => {
  const saveData: LocalStorageData = {
    data,
    timestamp: Date.now(),
    expireDate: expireDate?.getTime(),
  };

  localStorage.setItem(overrideKey ? key : getKey(key, global), JSON.stringify(saveData));
};

export const get = <T = any>(key: string, global?: boolean, overrideKey: boolean = false): T => {
  const savedItem = getPrivate(overrideKey ? key : getKey(key, global));

  return savedItem?.data ?? savedItem;
};

export const update = <T = any>(
  key: string,
  updateFn: (prevData: T) => T,
  expireDate?: Date,
  global?: boolean,
  overrideKey: boolean = false,
) => {
  const storageKey = overrideKey ? key : getKey(key, global);
  const data = get<T>(storageKey);
  const updatedData = updateFn(data);

  save(key, updatedData, expireDate, global, overrideKey);
};

export const remove = (key: string, global?: boolean, overrideKey: boolean = false) => {
  localStorage.removeItem(overrideKey ? key : getKey(key, global));
};

export const fromStorage = <T extends InferableFunction>(
  fn: T,
  storageKey: string,
  expireInSeconds: number,
  global?: boolean,
) => async (...args: Parameters<T>): Promise<ResolveType<T>> => {
    const cached = get<ResolveType<T>>(storageKey, global);

    // data exists in localStorage
    if (cached !== undefined) {
      return cached;
    }

    const result = await fn(...args);

    if (result !== undefined) {
      // saves the new data in localStorage
      save(storageKey, result, new Date(Date.now() + expireInSeconds * 1000), global);
    }

    return result;
  };
