import * as React from 'react';
import { view } from '@risingstack/react-easy-state';
import { Redirect } from 'react-router-dom';
import { appState } from 'stores/app';
import { Text } from 'ui';
import { AuthAccessClaim } from './Interfaces';
import { ensureClaims } from './useAuthUser';

interface Props {
  children: any;
  routeClaim: AuthAccessClaim | AuthAccessClaim[];
}

export const AuthorizedRoute = view(({ children, routeClaim }: Props) => {
  const isLoggedIn = !!appState.user?.access_token;
  const isAuthorized = ensureClaims(routeClaim);

  if (!isLoggedIn) {
    return (
      <div className="mrg-left-20">
        <Text tag="h3" caption="generic.loggedOut" />
      </div>
    );
  }
  if (!isAuthorized) {
    return <Redirect to="/nopermission" />;
  }
  return children;
});
