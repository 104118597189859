import { fork } from 'redux-saga/effects';
import watchCreateStoreAction from './createStoreSaga';
import watchGetStoreAction from './getStoreSaga';
import watchSaveStoreAction from './saveStoreSaga';
import watchSaveStoreAttributesAction from './saveStoreAttributesSaga';
import watchSaveGlobalStoreAttributeAction from './saveGlobalStoreAttributeSaga';
import watchGetDeliveryAreasAction from './getDeliveryAreas';
import watchSaveDeliveryAreasAction from './saveDeliveryAreas';
import watchSaveStoreStatusAction from './saveStoreStatus';
import watchDeleteStoreAction from './deleteStoreSaga';
import watchPoolingStoreHierarchy from './poolingStoreHierarchySaga';

export default [
  fork(watchCreateStoreAction),
  fork(watchGetStoreAction),
  fork(watchGetDeliveryAreasAction),
  fork(watchSaveStoreAction),
  fork(watchDeleteStoreAction),
  fork(watchSaveStoreAttributesAction),
  fork(watchSaveGlobalStoreAttributeAction),
  fork(watchSaveDeliveryAreasAction),
  fork(watchSaveStoreStatusAction),
  fork(watchPoolingStoreHierarchy),
];
