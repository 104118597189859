import { call, put, select } from 'redux-saga/effects';
import { config } from 'config';
import { apiClientActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { DEFAULT_LOCALE_ID, FORM_RULES_CREATE_RULES } from 'framework/modules/searchadmin/constants';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { formSelectors } from 'framework/selectors';
import CONST from '../constants';

export default function* watchModalProductSearchGet() {
  yield takeEveryWithProgressBar(CONST.ACTION_MODAL_SEARCH, process);
}

function* process({ payload }) {
  const previewForm = yield select(formSelectors.getFormFieldValues, FORM_RULES_CREATE_RULES);
  const { previewStoreId } = previewForm;
  const { searchTerm = '', localeId = DEFAULT_LOCALE_ID } = payload;

  if (searchTerm !== '') {
    // 1.1 Get productData from GateWay
    yield call(makeRequestAndSaveState, {
      name: CONST.API_DATA_NAME,
      method: 'POST',
      url: `${config.apiEndpoints.search}/rules/locale/${localeId}/store/${previewStoreId}/products`,
      body: {
        q: searchTerm,
        skip: 0,
        take: 20,
      },
    });
  } else {
    // 1.2 Empty the Grid if no searchTerm is provided
    yield put(
      apiClientActions.clearApiClientStateAction({
        name: CONST.API_DATA_NAME,
      }),
    );
  }
}
