export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const createOpenModalAction = (modalName: string) => ({
  type: OPEN_MODAL,
  payload: modalName,
});

export const createCloseModalAction = (modalName: string) => ({
  type: CLOSE_MODAL,
  payload: modalName,
});
