import React from 'react';
import { i18n } from 'i18n';
import { Icon } from 'ui';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import { TagProps, TagValue } from './interfaces';
import './Tag.scss';
import { IconNames } from '../Icon/Icon.interfaces';

const clx = prefixClassName('tag');

type TagIconProps = { icon: IconNames };
const TagIcon = ({ icon }: TagIconProps) => <Icon name={icon} className={`${clx}__icon`} />;

export const Tag = ({
  caption,
  i18nOptions,
  value,
  className,
  icon,
  control: Control,
}: TagProps) => (
  <span className={classNames(clx, `${clx}--${value}`, className)}>
    {i18n.t(caption, i18nOptions)}
    {icon && <TagIcon icon={icon}/>}
    {Control && <Control />}
  </span>
);

Tag.Value = TagValue;
Tag.Icon = TagIcon;
