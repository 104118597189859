import { formActions } from 'framework/actions';
import * as CONSTANTS from 'framework/modules/searchadmin/constants';
import { ProductScore } from 'framework/modules/searchadmin/models/product';
import { ISearchOptions, ISearchGetAttributes } from './types';

export const setRelevanceAtt = (attId: string, value: number = 1) => formActions.setValue(CONSTANTS.FORM_RELEVANCE_RELEVANCE_GENERAL, attId, value);
export const delRelevanceAtt = (attId: string) => setRelevanceAtt(attId, 0);

export const resetRelevanceAttToGlobal = (formData: ISearchOptions) => ({
  type: CONSTANTS.ACTION_RELEVANCE_ATTRIBUTES_RESET_GLOBAL,
  payload: { ...formData },
});
export const resetRelevanceAtt = () => ({
  type: CONSTANTS.ACTION_RELEVANCE_ATTRIBUTES_RESET,
});
export const clearRelevanceData = () => ({
  type: CONSTANTS.ACTION_RELEVANCE_ATTRIBUTES_CLEAR_DATA,
});

export const getRelevancesAtts = (formValues: ISearchGetAttributes) => ({
  type: CONSTANTS.ACTION_RELEVANCE_ATTRIBUTES_GET,
  payload: { ...formValues },
});

export const getSearchResultsCurrent = (data: ISearchOptions) => ({
  type: CONSTANTS.ACTION_RELEVANCE_SEARCH_RESULTS_CURRENT_GET,
  payload: { data, apiDataProviderName: CONSTANTS.API_DATA_PROVIDER_RELEVANCE_CURRENT },
});
export const getSearchResultsPreview = (data: ISearchOptions) => ({
  type: CONSTANTS.ACTION_RELEVANCE_SEARCH_RESULTS_PREVIEW_GET,
  payload: { data, apiDataProviderName: CONSTANTS.API_DATA_PROVIDER_RELEVANCE_PREVIEW },
});

export const saveRelevanceChanges = (data: any) => ({
  type: CONSTANTS.ACTION_RELEVANCE_ATTRIBUTES_SAVE_CHANGES,
  payload: {
    storeId: data.storeId,
    locales: data.locales,
    attributes: data.attributes.map((att) => ({
      id: att.id,
      relevance: att.value,
      visible: att.isVisible,
    })),
  },
});

export const getCurrentScore = ({ productId }: ProductScore, data: ISearchOptions) => ({
  type: CONSTANTS.ACTION_RELEVANCE_CURRENT_SCORE,
  payload: { productId, data },
});

export const getPreviewScore = ({ productId }: ProductScore, data: ISearchOptions) => ({
  type: CONSTANTS.ACTION_RELEVANCE_PREVIEW_SCORE,
  payload: { productId, data },
});
