import * as React from 'react';
import { Field, Validator } from 'redux-form';
import { Translator } from 'framework/components/hoc/withTranslation/Translator';
import FieldWrapper from './fieldWrapper';
import * as formValidators from './validators';
import { IformField } from './fieldWrapper.interface';

const formField = <T extends {}>(WrappedComponent: React.ComponentType<T>) => (props: T & IformField) => {
  const {
    name, placeholder, mandatory = false, validate = [], caption, optional, initValue,
  } = props;

  const validators: Validator[] = [...validate];
  if (mandatory) {
    validators.unshift(formValidators.mandatory);
  }
  return (
    <Translator caption={placeholder ?? ''} render={(translatedPlaceholder) => (
      <Field
        name={name}
        component={FieldWrapper}
        {...props}
        placeholder={translatedPlaceholder}
        wrappedComponent={WrappedComponent}
        __untranslatedPlaceholder={placeholder}
        validate={validators}
        optional={caption && optional}
        initValue={initValue}
      />
    )}
    />
  );
};

export default formField;
export { formField, IformField, formValidators as validators };
