import { call, put } from 'redux-saga/effects';
import { toastListActions } from 'framework/actions';
import { createApiClientUpdateStateAction } from 'framework/api/apiClient/actions';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';

import { config } from 'config';
import { actionTypes } from '../actions';

const { STORES_GET_STORE_ATTRIBUTES } = actionTypes;
const { API_PROVIDER_SAVE_STORE_ATTRIBUTES } = actionTypes;
const baseUri = config.apiEndpoints.stores;

function* process({ payload }) {
  const { data } = payload;

  const response = yield call(makeRequestAndSaveState, {
    name: API_PROVIDER_SAVE_STORE_ATTRIBUTES,
    method: 'PUT',
    url: `${baseUri}/${data.id}/attributes`,
    body: { attributes: data.attributes },
  });

  if (!response.success) {
    yield put(toastListActions.addMessageError('modules.stores.storeSetup.errors.saveError'));
  } else {
    yield put(toastListActions.addMessage('generic.data.savedSuccess'));
    yield put(createApiClientUpdateStateAction({
      attributes: data.attributes,
      name: STORES_GET_STORE_ATTRIBUTES,
      hasError: false,
      isFetching: false,
    }));
  }
}

export default function* watchSaveStoreAttributesAction() {
  yield takeEveryWithProgressBar(actionTypes.STORES_SAVE_STORE_ATTRIBUTES, process);
}
