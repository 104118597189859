export enum popperPlacement {
  auto = 'auto',
  top = 'top-start',
  bottom = 'bottom-start'
}

export interface IDatePickerPrimitive {
  value?: Date | string;
  className?: string;
  id?: string;
  disabled?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  showTimepicker?: boolean;
  showTimeSelectOnly?: boolean;
  selectsRange?: boolean;
  inline?: boolean;
  calendarDirection?: 'auto' | 'top' | 'bottom';
  icon?: 'calendar' | 'clock';
  placeholder?: string;
  formats?: {
    input?: string;
    header?: string;
  };
  overWriteValue?: string;
  onClear?: () => void;
  onChange?: (e?: any, val?:any) => void;
  onFocus?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  timeZone?: string;
  ignoreLocalTimeZone?: boolean;
  convertFormatInput?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export interface IDatePickerPrimitivePrivate {
  startDate?: string;
  endDate?: string;
  isStartSelector?: boolean;
  isEndSelector?: boolean;
}

export enum DateRanges {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  NextSevenDays = 'NextSevenDays',
  LastSevenDays = 'LastSevenDays',
  LastThirtyDays = 'LastThirtyDays',
  Custom = 'Custom'
}

export enum Operations {
  Plus = '+',
  Minus = '-',
}

export type PreselectRange = {
  [K in DateRanges]: {
    name: DateRanges,
    start?: number,
    end?: number,
    operation?: Operations,
  };
};

export const PreselectRanges: PreselectRange = {
  Today: { name: DateRanges.Today, start: 0, end: 0 },
  Tomorrow: { name: DateRanges.Tomorrow, start: 1, end: 1, operation: Operations.Plus },
  Yesterday: { name: DateRanges.Yesterday, start: 1, end: 1, operation: Operations.Minus },
  NextSevenDays: { name: DateRanges.NextSevenDays, start: 1, end: 7, operation: Operations.Plus },
  LastSevenDays: { name: DateRanges.LastSevenDays, start: 7, end: 1, operation: Operations.Minus },
  LastThirtyDays: { name: DateRanges.LastThirtyDays, start: 30, end: 1, operation: Operations.Minus },
  Custom: { name: DateRanges.Custom },
};