import * as React from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { createDeleteFormDataAction, createSetFormIsStencilledPropertyAction } from './Form.actions';
import { FormAdditionalProps } from './Form.interface';

export const Form = <T extends {}>(props: InjectedFormProps<T> & FormAdditionalProps<T>) => {
  const {
    name,
    className,
    children,
    stencilled,
    handleSubmit,
    touch,
    forceTouch,
  } = props;
  const dispatch = useDispatch();

  const toggleStencilledProp = (isStencilled) => {
    dispatch(
      createSetFormIsStencilledPropertyAction({ name, isStencilled }),
    );
  };

  const deleteFormData = () => {
    // destroys formMWG from state
    dispatch(createDeleteFormDataAction({ name }));
  };

  React.useEffect(() => {
    toggleStencilledProp(stencilled);
  }, [stencilled]);

  React.useEffect(() => {
    if (forceTouch) {
      touch(...forceTouch);
    }
    return deleteFormData;
  }, []);

  return (
    <form onSubmit={handleSubmit} className={className}>
      {children}
    </form>
  );
};

export default Form;
