import {
  AppTracker,
  GAEventTypes,
  GASendEvent,
  IGAModalEvent,
  IGAPageViewEvent,
  IGATrackEvent,
  IModalEventActionType,
} from './interface';
import { i18n } from 'i18n';

export const gtm = (params: IGAModalEvent | IGATrackEvent | IGAPageViewEvent) => {
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push(params);
  }
};

const ApplicationMonitoring = (): AppTracker => {
  /**
   * Sends a "page was viewed" event
   * @param eventDetails includes hitType, page, and title. Title is required
   */
  const pageViewEvent = (eventDetails: GASendEvent) => {
    gtm({
      event: GAEventTypes.PageView,
      payload: {
        page_ref: `${window?.location?.pathname || ''}${window?.location?.search || ''}`,
        page_visit: eventDetails.title || '',
      },
    });
  };

  /**
   * Fires a tracking event
   * @param name Event name
   * @param value Event location
   * @param label Event target
   */
  const trackEvent = (name: string, value?: string | number | boolean, label?: string) => {
    gtm({
      event: GAEventTypes.ClickEvent,
      payload: {
        page_ref: name?.toString(),
        click_location: value?.toString(),
        click_target: i18n.t(label?.toString()),
      },
    });
  };

  /**
   * Fires a modal event
   * @param modalName Event modal name
   * @param actionType Event modal open/close
   */
  const modalViewEvent = (modalName: string, actionType: IModalEventActionType = 'open') => {
    gtm({
      event: GAEventTypes.ModalEvent,
      payload: {
        modal_name: modalName,
        modal_action_type: actionType,
      },
    });
  };

  return {
    pageViewEvent,
    trackEvent,
    modalViewEvent,
  };
};

export default ApplicationMonitoring();
