import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { NumericFieldPrimitive } from 'framework/components/ui/FormComponents/Primitives';
import { Icon } from 'ui';
import classNames from 'classnames';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useLocation } from 'react-router-dom';
import { INumericField } from './NumericField.interfaces';
import { autofill } from 'redux-form';
import './_style.scss';

const cnField = prefixClassName('form-field');
const cn = `${cnField}-numeric`;

export const NumericField = (props: INumericField) => {
  const {
    className,
    clearable,
    nullable,
    disabled,
    hasError,
    value = 0,
    step = 1,
    max,
    min,
    readOnly = false,
    showIcons = true,
    allowDecimals = true,
    decimals,
    onChange,
    suffix,
    trackEventProps,
    form,
    name,
    defaultValue,
    dispatch
  } = props;
  const shouldShowClearable = clearable && !!value && value !== 0;
  const hiddeIcons = !showIcons || disabled || readOnly;

  React.useEffect(() => {
    if(defaultValue && !value && form && name && dispatch){
      dispatch(autofill(form,name,defaultValue));
    }
  },[])

  const { pathname } = useLocation();

  const styleNumber = (num: number) => (
  // Remove leading zeros + limit digits when requested
  // FIXME: console.log(num) shows a non leading zeros number,
  // but the return output doesn't display it.
  // num = +num;
    decimals ? Number((num * 1).toFixed(decimals)) : num
  );
  const isNumber = (val) => typeof val !== 'undefined' && val !== null && val !== '';
  const updateValue = (v: number) => {
    const input = !isNumber(v) && !nullable ? 0 : v;
    let val = allowDecimals ? input : Math.floor(input);
    if (nullable && !isNumber(val)) {
      onChange(val);
      return;
    }
    // Fit value into valid range
    if (min !== undefined && min > val) {
      val = min;
    } else if (max !== undefined && max < val) {
      val = max;
    }

    onChange(Number(styleNumber(val)));
  };

  const handleonChange = (e) => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Numeric field',
      trackEventProps?.label || e.target.value
    );
    updateValue(e.target.value);
  };
  const handleClearableOnClick = () => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Numeric field clear',
      trackEventProps?.label
    );
    updateValue(nullable ? null : 0);
  };

  // Force to be numeric
  const getValue = () => value * 1;

  const handleClickIncrease = () => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Numeric field increase',
      trackEventProps?.label || String(getValue() + step)
    );
    updateValue(getValue() + step);
  };
  const handleClickDecrease = () => {
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Numeric field decrease',
      trackEventProps?.label || String(getValue() - step)
    );
    updateValue(getValue() - step);
  };

  return (
    <div className={`${cn}__wrapper`}>
      <NumericFieldPrimitive
        {...props as any}
        onChange={handleonChange}
        onBlur={handleonChange}
        className={classNames(
          cn,
          cnField,
          showIcons && `${cn}--hasIcons`,
          clearable && `${cn}--clearable`,
          hasError && `${cn}--error ${cnField}--error`,
          disabled && `${cn}--disabled`,
          className,
        )}
      />
      {!hiddeIcons && (
        <>
          <div className={`${cn}__icons`}>
            <Icon
              name="dropdownArrowUp"
              className={`${cn}__icon ${cn}__icon--add`}
              onClick={handleClickIncrease}
            />
            <Icon
              name="dropdownArrowDown"
              className={`${cn}__icon ${cn}__icon--decrease`}
              onClick={handleClickDecrease}
            />
            {shouldShowClearable && (
              <Icon
                name="del"
                className={`${cn}__icon ${cn}__icon--clear`}
                onClick={handleClearableOnClick}
              />
            )}
          </div>

          {/*
            When the user clicks the icons quickly, the text after gets sected
            This hidden text will be selected instead.
          */}

          <div className={`${cn}__icons__hiddenText`}>hidden</div>
        </>
      )}
      {suffix && (
        <div className={`${cn}__suffix`}>{suffix}</div>
      )}
    </div>
  );
};

export default NumericField;
