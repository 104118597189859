/* eslint-disable react/prop-types */
import * as React from 'react';
import { Button } from 'ui';
import ConditionsTemplate from './ConditionsTemplate';

export default (props) => {
  const { fields } = props;

  const addDynamicField = () => {
    fields.push({});
  };

  return (
    <div>
      {fields.map((field, index, fieldsRef) => (
        <ConditionsTemplate
          key={field}
          field={field}
          remove={fields.remove}
          fieldIndex={index}
          fieldValues={fieldsRef.get(index)}
          filterConditionOperationOptions={props.filterConditionOperationOptions}
          stores={props.stores}
          conditionFieldOptions={props.conditionFieldOptions}
          setFieldValueToNull={props.setFieldValueToNull}
          fetchCurrentData={props.fetchCurrentData}
          changeFormValue={props.changeFormValue}
        />
      ))}
      <Button
        onClick={addDynamicField}
        caption="modules.merchandising.rules.create.btnAddCondition"
        icon="plus"
        outline
      />
    </div>
  );
};
