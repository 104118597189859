import { connect } from 'react-redux';
import { formatDateTimeISO } from 'framework/utils/datetimes';
import { modalActions, calendarActions } from 'framework/actions';
import Modal from './ModalCreateBlocker';
import { Props, Actions } from './ModalCreateBlocker.interfaces';
import { actions, selectors } from '../../store';
import * as CONST from '../../constants';

const getCurrentDay = formatDateTimeISO(new Date());
const mapStateToProps = (state): Props => {
  const selectedEvent: any = selectors.getEventSelected(state);
  const selectedEventData: any = selectors.getEventSelectedData(state);
  const optionsShoppingMode = selectors.getFilterOptions(state).shoppingModes;
  const  storeTimeZone = selectors.getStoreTimeZone(state);
  const formIsEditMode = !!selectedEvent.id;

  const initialValues = !formIsEditMode
    ? {
      [CONST.FIELD_BLOCKER_SLOT_DAY]: getCurrentDay,
    }
    : {
      [CONST.FIELD_BLOCKER_SLOT_TIME_INI]: selectedEventData.timeStart,
      [CONST.FIELD_BLOCKER_SLOT_TIME_END]: selectedEventData.timeEnd,
      [CONST.FIELD_BLOCKER_SLOT_DAY]: selectedEventData.start,
      [CONST.FIELD_BLOCKER_SLOT_SHOPPINGMODE]: selectedEventData.shoppingModes,
      [CONST.FIELD_BLOCKER_SLOT_NOTE]: selectedEventData.note,
    };

  return {
    slotData: selectedEvent,
    initialValues,
    optionsShoppingMode,
    formIsEditMode,
    storeTimeZone,
  };
};

const mapDispatchToProps = (dispatch): Actions => ({
  addBlocker: () => dispatch(actions.createBlocker()),
  removeBlocker: (slotId: string) => {
    dispatch(modalActions.createCloseModalAction(CONST.MODAL_BLOCKER_SLOT));
    dispatch(calendarActions.removeEvent(CONST.CALENDAR_NAME, slotId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
