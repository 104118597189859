import * as React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '.';
import { ICalendar } from './Calendar.interfaces';
import Calendar from './Calendar';

const mapStateToProps = (state, ownProps: ICalendar) => ({
  isLoading: selectors.isLoading(state, ownProps.name),
  view: selectors.getCalendarView(state, ownProps.name),
  date: selectors.getCalendarDate(state, ownProps.name),
  events: selectors.getCalendarEvents(state, ownProps.name),
  hasInstacartSlots: selectors.getHasInstacartSlots(state, ownProps.name),
  hasOrphanedSlots: selectors.getHasOrphanedSlots(state, ownProps.name),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectorDate: (d) => dispatch(actions.updateSelectorDate(ownProps.name, d)),
  updateSelectorView: (v) => dispatch(actions.updateSelectorView(ownProps.name, v)),
  updateSelectorEvent: (e) => dispatch(actions.updateSelectorEvent(ownProps.name, e)),
  deleteCalendarData: () => dispatch(actions.clear(ownProps.name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar as any) as any as React.StatelessComponent<ICalendar>;
