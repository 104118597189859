import {
  ITabStepsState,
  INIT_TABSTEPS_STORE,
  COMPLETE_TABSTEPS_CURRENT,
  GO_TO_TABSTEPS_PREVIOUS,
  REMOVE_TABSTEPS_INFO,
  UPDATE_TABSTEPS_CURRENTINDEX,
  ITabStep,
} from './TabSteps.interfaces';

const initial: ITabStepsState = [];

const tabsteps = (state: ITabStepsState = initial, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case INIT_TABSTEPS_STORE: {
      return [
        ...state,
        {
          id: payload.id,
          currentStep: 0,
          steps: payload.data.map((tab, i) => ({
            id: tab.id, // step.id = tab.id
            index: i, // step.index
            isCompleted: false,
            isEnabled: i === 0,
          })),
        },
      ];
    }

    case UPDATE_TABSTEPS_CURRENTINDEX: {
      return state.map((tabs) => {
        if (tabs.id !== payload.tabsId) {
          return tabs;
        }

        const step = tabs.steps.find((s) => s.id === payload.stepId);
        const newCurrent = tabs.steps.indexOf(step);

        return {
          ...tabs,
          currentStep: newCurrent,
        };
      });
    }

    case COMPLETE_TABSTEPS_CURRENT: {
      return state.map((tabs) => {
        if (tabs.id !== payload.tabsId) {
          return tabs;
        }

        const updatedSteps = {
          ...tabs,
          steps: tabs.steps.map((step) => {
            const newStep: ITabStep = step;
            if (!step.isCompleted) {
              if (step.index === tabs.currentStep) {
                newStep.isCompleted = newStep.isEnabled ?? false;
              }
              if (step.index === tabs.currentStep + 1) {
                newStep.isEnabled = true;
              }
            }

            return newStep;
          }),
        };

        if (updatedSteps.currentStep < updatedSteps.steps.length) {
          updatedSteps.currentStep += 1;
        }

        // keep track of the previousStep for calling the onTabComplete function
        if (updatedSteps.currentStep > 0 && !updatedSteps.steps[updatedSteps.currentStep]?.isCompleted) {
          updatedSteps.previousStep = updatedSteps.currentStep - 1;
        }

        return updatedSteps;
      });
    }

    case GO_TO_TABSTEPS_PREVIOUS: {
      return state.map((tabs) => {
        if (tabs.id !== payload.tabsId) {
          return tabs;
        }

        const updatedSteps = {
          ...tabs,
          currentStep: tabs.currentStep > 0 ? tabs.currentStep - 1 : tabs.currentStep,
        };

        updatedSteps.openTab = tabs.steps[updatedSteps.currentStep]?.id;

        return updatedSteps;
      });
    }

    case REMOVE_TABSTEPS_INFO: {
      return state.filter((t) => t.id !== payload.id);
    }

    default:
      return state;
  }
};

export default tabsteps;
