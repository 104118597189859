import * as React from 'react';
import { Text } from '../Text';
import type { HiddenColumns, ObjectMetadata } from './Table.interfaces';
import TableText from './Cells/TableText/TableText';
// eslint-disable-next-line import/no-cycle
import { Row } from './Rows';
import { ColumnText, Column } from './TableColumn';
import { interpolateData, hideColumns } from './helpers';
import tableCells from './Cells/TableCells';
import { tableClassName } from './constants';

export const TABLE_DEFAULT_NAME = 'Table_default';

const OptionalText = () => (
  <Text tag="span" caption="components.form.optionalValue" className={`${tableClassName}__th__optional`} />
);

export const defaultCellRenderer = <T extends {}>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _tableName: string = TABLE_DEFAULT_NAME,
  data: T,
  metadata: ObjectMetadata[] = [],
  rowIndex: number,
  hiddenColumns: HiddenColumns[] = [],
  originalWord: string,
  replacementWord: string
): JSX.Element => {
  // interpolate data
  const interpolated = interpolateData(data, metadata);
  const columns = hideColumns(interpolated, hiddenColumns).map((column: ObjectMetadata, columnIndex) => {
    if (column.customRenderer) {
      return <React.Fragment key={columnIndex}>{column.customRenderer(data, columnIndex, rowIndex)}</React.Fragment>;
    }
    if (column.component) {
      const Component: any = tableCells[column.component];
      return (
        <Column key={columnIndex} className={column.className}>
          {typeof Component === 'undefined' ? (
            `Component ${column.component} not found`
          ) : (
            <Component {...column.componentOptions} />
          )}
        </Column>
      );
    }
    const includesOriginalWord = data[column.id]?.includes(originalWord) && originalWord !== null;

    return (
      <Column key={columnIndex}>
        <TableText
          lines={[
            {
              text: includesOriginalWord ? replacementWord : data[column.id] || '',
            },
          ]}
          key={columnIndex}
          className={includesOriginalWord ? `${tableClassName}__cell--empty` : ''}
        />
      </Column>
    );
  });
  return <Row>{columns}</Row>;
};

export const defaultHeaderRenderer = (
  _data: any[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _tableName: string = TABLE_DEFAULT_NAME,
  metadata: ObjectMetadata[] = [],
  hiddenColumns: string[] = []
): JSX.Element => {
  const cells = hideColumns(metadata, hiddenColumns).map((item: ObjectMetadata, i) => {
    if (!item) {
      return <React.Fragment key={i} />;
    }
    const { caption, component, componentOptions, customRenderer, optional, canHide, ...rest } = item;

    if (typeof caption === 'object') {
      if (caption.customRenderer) {
        return <React.Fragment key={i}>{caption.customRenderer(i)}</React.Fragment>;
      }
      const Component: any = tableCells[caption.component];
      return (
        <th key={i} {...rest}>
          {typeof Component === 'undefined' ? (
            `Component ${caption.component} not found`
          ) : (
            <Component {...caption.componentOptions} />
          )}
        </th>
      );
    }

    return (
      <th key={i} {...rest}>
        <TableText key={i} lines={[{ text: caption }]} />
        {optional && <OptionalText />}
      </th>
    );
  });

  return <Row>{cells}</Row>;
};

export const defaultIfEmpty = (columnMetadata: ObjectMetadata[], emptyText = '', useDefault: boolean = true) => (
  <Row>
    <ColumnText
      className={`${tableClassName}__cell--empty`}
      colSpan={columnMetadata.length}
      caption={useDefault ? emptyText || 'generic.dataFetchError' : emptyText}
    />
  </Row>
);
