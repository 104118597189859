import React from 'react';
import { useSafeState } from 'helpers/hooks';

export const useFetch = <T extends {}>(fn: () => Promise<T>, deps: React.DependencyList) => {
  const [isFetching, setIsFetching] = useSafeState(false);
  const [data, setData] = useSafeState<T>();
  const [error, setError] = useSafeState(false);

  React.useEffect(() => {
    setIsFetching(true);
    setData(undefined);
    fn()
      .then(setData)
      .catch(setError)
      .finally(() => setIsFetching(false));
  }, deps);

  return { isFetching, data, error };
};
