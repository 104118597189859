import * as React from 'react';
import { DynamicFields, Icon, LayoutFlexBox, LayoutFlexBoxItem, RangeWithNumerical, Select, TagsField } from 'ui';
import { ISelectPrimitiveOptionsProps } from 'framework/components/ui/FormComponents/Primitives/SelectPrimitive/SelectPrimitive.interface';
import { ActionsHideComponent } from './ActionsHide';
import ActionsPositionComponent from './ActionsPosition';
import { ActionsBoostAndBuryFilterComponent } from './ActionsBoostAndBuryFilter';
import { ActionFieldType } from './ActionsHide/constants';
import { SearchAdminTranslations as translations } from '../../../../translations';

type FieldValues = {
  field: ISelectPrimitiveOptionsProps;
  action: ISelectPrimitiveOptionsProps;
  boost_actions?: ISelectPrimitiveOptionsProps;
  boost_actions_operation?: ISelectPrimitiveOptionsProps;
  description?: string[];
  boostFilter?: {
    field: ISelectPrimitiveOptionsProps;
    operation: ISelectPrimitiveOptionsProps;
    values: string[];
  }[];
};

interface ActionsTemplateProps {
  field: string;
  fieldValues: FieldValues;
  fieldIndex: number;
  actionFieldOptions: ISelectPrimitiveOptionsProps[];
  actionOperationOptions: ISelectPrimitiveOptionsProps[];
  remove: (index: number) => void;
  fetchCurrentData: () => void;
}

export default (props: ActionsTemplateProps) => {
  const {
    field,
    fieldValues,
    fieldIndex,
    actionFieldOptions,
    actionOperationOptions,

    // Actions
    remove,
    fetchCurrentData,
  } = props;

  const onDeleteClickHandler = () => {
    remove(fieldIndex);
    fetchCurrentData();
  };

  const actionType: string | ISelectPrimitiveOptionsProps<string> = fieldValues.field?.value
    ? fieldValues.field?.value
    : fieldValues.field;
  const actionField: { actionField: string | ISelectPrimitiveOptionsProps<string> } = {
    actionField: fieldValues.action?.value ? fieldValues.action?.value : fieldValues.action,
  };

  return (
    <LayoutFlexBox flexWrap="nowrap">
      <LayoutFlexBoxItem className="mwg-widget mrg-btm-20" flexBasis="90%">
        <LayoutFlexBox>
          <LayoutFlexBoxItem flexBasis="49%" className="mrg-right-10">
            <Select
              mandatory
              caption={
                fieldIndex > 0
                  ? 'modules.merchandising.rules.create.andLabel'
                  : 'modules.merchandising.rules.create.thenLabel'
              }
              labelInline
              name={`${field}.field`}
              clearable={false}
              options={[
                { label: 'Boost or Bury', value: 'bust_or_bury' },
                { label: 'Hide', value: 'hide' },
                { label: 'Position', value: 'position' },
              ]}
              placeholder="Select"
            />
          </LayoutFlexBoxItem>
          {actionType && actionType === 'hide' && (
            <LayoutFlexBoxItem flexBasis="49%">
              <Select
                mandatory
                labelInline
                name={`${field}.action`}
                clearable={false}
                options={[
                  { label: 'Product(s)', value: ActionFieldType.Products },
                  { label: 'Categories', value: ActionFieldType.Category },
                ]}
                placeholder="Select"
              />
            </LayoutFlexBoxItem>
          )}
        </LayoutFlexBox>

        {actionType === 'bust_or_bury' && (
          <>
            <LayoutFlexBox justifyContent="space-between" className="mrg-top-20">
              <LayoutFlexBoxItem flexBasis="47%">
                <Select
                  name={`${field}.boost_actions`}
                  clearable={false}
                  options={actionFieldOptions}
                  placeholder="Select"
                  onChange={fetchCurrentData}
                  mandatory
                />
              </LayoutFlexBoxItem>
              <LayoutFlexBoxItem flexBasis="47%">
                <Select
                  name={`${field}.boost_actions_operation`}
                  clearable={false}
                  options={actionOperationOptions}
                  placeholder="Select"
                  onChange={fetchCurrentData}
                  mandatory
                />
              </LayoutFlexBoxItem>
            </LayoutFlexBox>
            <LayoutFlexBoxItem>
              <TagsField
                name={`${field}.description`}
                divider=";"
                className="mrg-top-20"
                onChange={fetchCurrentData}
                placeholder={translations.TagsField_placeholder}
                mandatory
              />
            </LayoutFlexBoxItem>
            <LayoutFlexBoxItem className="mrg-top-20">
              <DynamicFields name={`${field}.boostFilter`} component={ActionsBoostAndBuryFilterComponent} {...props} />
            </LayoutFlexBoxItem>
            <LayoutFlexBoxItem className="mrg-btm-20">
              <RangeWithNumerical
                className="mrg-top-20"
                name={`${field}.boost_actions_weight`}
                caption="By"
                min={-10}
                max={10}
                step={1}
                displayExtraInfo
                onChange={fetchCurrentData}
                minCaption="modules.merchandising.rules.createAndEdit.rangeSliderBury"
                maxCaption="modules.merchandising.rules.createAndEdit.rangeSliderBoost"
              />
            </LayoutFlexBoxItem>
          </>
        )}
        {actionType === 'hide' && (
          <DynamicFields name={`${field}.hide`} component={ActionsHideComponent} {...props} {...actionField} />
        )}
        {actionType === 'position' && (
          <DynamicFields name={`${field}.position`} component={ActionsPositionComponent} {...props} />
        )}
      </LayoutFlexBoxItem>
      <LayoutFlexBoxItem flexBasis="10%">
        <Icon
          onClick={onDeleteClickHandler}
          className="mrg-auto mrg-top-20 block cursor-pointer"
          name="trash"
          size="sm"
          color="#96a0ab"
        />
      </LayoutFlexBoxItem>
    </LayoutFlexBox>
  );
};
