import * as React from 'react';
import { HierarchyProps, HierarchyContextStoreProps } from './hierarchy.interfaces';

export const HierarchyContext = React.createContext<Partial<HierarchyContextStoreProps>>({} as any);

export const HierarchyProvider = (
  props: Partial<HierarchyProps> & {
    children?: any;
  },
) => {
  const { children, ...rest } = props;
  return <HierarchyContext.Provider value={rest}>{children}</HierarchyContext.Provider>;
};
