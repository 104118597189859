export const fixPreviewUrl = (urlValue: string) => {
  let url = urlValue.replace(/\s+/g, '-').toLowerCase();
  if (!url.startsWith('/')) {
    url = `/${url}`;
  }
  if (!url.endsWith('/')) {
    url = `${url}/`;
  }
  return url;
};
