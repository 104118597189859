import * as React from 'react';
import { IApplicationSettingsDocumentProps } from './StoreFrontSettings.interfaces';
import './_styles.scss';

const ApplicationSettingsDocument = (props: IApplicationSettingsDocumentProps) => (
  <a href={props.url} className="application-settings-document">
    <span>
      <p>Name</p>
      <h3>{props.displayName}</h3>
    </span>
    <span>
      <p>Last Updated</p>
      <p>{props.lastUpdated}</p>
    </span>
    <span>
      <p>Scheduled Status</p>
      <p>{props.scheduleStatus}</p>
    </span>
  </a>
);

export default ApplicationSettingsDocument;
