import * as React from 'react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { Message } from 'ui';
import { IToastProps } from './Toast.interfaces';
import './_style.scss';

const className = prefixClassName('toast');

export const Toast = ({ message }: IToastProps) => (
  <div className={className}>
    <Message caption={message.caption} type={message.type} onClose={message.onClose} />
  </div>
);
