import { formField, IformField } from 'framework/components/hoc/formField';
import { IDatePicker } from './DatePicker.interfaces';
import DatePickerComponent from './DatePicker';

const DatePicker = (formField(DatePickerComponent) as any) as React.StatelessComponent<
IDatePicker & IformField
>;

export { DatePicker };
export default DatePicker;
