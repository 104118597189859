import { useState, useRef, useEffect } from 'react';

/**
 * Skips the first run of an effect. This is useful for triggering filter functions where the first value is the same as the initial request.
 * @param effect
 * @param deps
 */
export const useEffectSkipFirstRun = (effect: React.EffectCallback, deps?: any[]): void => {
  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

/**
 * This hook prevents the "Can't perform a React state update on an unmounted component" warning
 * Use it when setting state inside of promises
 * @param initialValue any
 */
export const useSafeState = <S extends {}>(initialValue?: S): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [state, setState] = useState(initialValue);

  const setSafeState = arg => {
    if (isMounted.current) {
      setState(arg);
    }
  };

  return [state, setSafeState];
};

export const useHover = (): [any, boolean] => {
  const [hovered, setHovered] = useState(false);
  const ref = useRef();

  const mouseEnter = () => setHovered(true);
  const mouseLeave = () => setHovered(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mouseenter', mouseEnter);
      ref.current.addEventListener('mouseleave', mouseLeave);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('mouseenter', mouseEnter);
        ref.current.removeEventListener('mouseleave', mouseLeave);
      }
    };
  }, [ref]);
  return [ref, hovered];
};

export const useArray = (defaultValue) => {
  const [array, setArray] = useState(defaultValue);

  const push = (element) => {
    setArray(a => [...a, element]);
  };

  const filter = (callback) => {
    setArray(a => a.filter(callback));
  };

  const update = (index, newElement) => {
    setArray(a => [
      ...a.slice(0, index),
      newElement,
      ...a.slice(index + 1, a.length),
    ]);
  };

  const remove = (index) => {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  };

  const clear = () => {
    setArray([]);
  };

  return { array, set: setArray, push, filter, update, remove, clear };
};
