import * as React from 'react';
import Page from 'framework/components/ui/Page';

export const NoPermissionPage = ({ isFeaturesLoading }: { isFeaturesLoading: boolean }) => {
  const pageParts = {
    content: () => { return !isFeaturesLoading ? <h1>No Permission</h1> : <></>; },
  };

  return <Page hideBreadCrumbs pageParts={pageParts} />;
};
