export enum linkTypes {
  link = 'link',
  button = 'button',
}
export interface ILinkProps {
  caption: any;
  captionAfterChildren?: boolean;
  /** page to link to */
  size?: 'large';
  type?: 'link' | 'button';
  external?: boolean;
  showCopyIcon?: boolean;
}
