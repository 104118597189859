import { createSelector } from 'reselect';

export function selectApiClientState(state: any, apiClientName: string) {
  const apiClientData = state && state.apiClientData
    ? state.apiClientData // whole redux store has been passed ie. from Saga select
    : state; // state.apiClientData has been passed

  return apiClientData[apiClientName] || {};
}

export const selectApiClientData = createSelector(
  [selectApiClientState],
  (clientState) => clientState.data || {},
);

export const getIsFetching = createSelector(
  [selectApiClientState],
  (clientState) => !!clientState.isFetching,
);

export const isBackgroundTaskInProgress = createSelector(
  [selectApiClientState],
  (clientState) => !!clientState.isApplyInProgress,
);

export const getIsSuccess = createSelector(
  [selectApiClientState],
  (clientState) => {
    const { status } = clientState;
    return !!status && (status >= 200 && status <= 299);
  },
);

export const getHasResponse = createSelector(
  [selectApiClientState],
  (clientState) => !!clientState.hasResponse,
);
export const getHasError = createSelector(
  [selectApiClientState],
  (clientState) => !!clientState.hasError,
);
