import { createSelector } from 'reselect';
import { ContentManagementState } from 'framework/modules/contentmanagement/contentmanagement.interfaces';

const getRowEditorData = (state: ContentManagementState) => state.contentmanagement.rowEditorData
  || { width: 'content-width' };

export const getRowWidth = createSelector(
  [getRowEditorData],
  ({ width }) => width,
);
