import { select, put, call } from 'redux-saga/effects';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { formSelectors } from 'framework/selectors';
import { toastListActions } from 'framework/actions';
import * as constants from '../contentemanagement.constants';
import * as cmsActions from '../actions';
import { Uuid } from 'framework/utils/generateId';
import { makeRequest } from 'framework/api/make-request';
import { IFetchDataResponse } from 'framework/api/fetch-data';
import { config } from '../../../../config';
import { getLoadedPage } from './_SagaUtils';
import { history } from 'framework/store';
import * as get from 'lodash.get';

export default function* watchCreateVariant() {
  yield takeEveryWithProgressBar(constants.ACTION_CMS_CREATE_PAGE_VARIANT, process);
}

function* process({ payload }) {
  const state = yield select();
  const documentid = state.contentmanagement.pageId;
  const values = yield select(formSelectors.getFormValues, constants.CMS_CREATE_VARIANT_MODAL_FORM);
  const storeHierarchyNodes = payload.selectedIds;
  const variantid = Uuid();

  // 1. create variant
  let response: IFetchDataResponse = yield remoteCall({
    body: {
      displayName: values.displayName,
      language: values.language,
      storeHierarchyNodes,
      varyBy: values.varyBy
    },
    documentid,
    variantid
  });

  const loadedPage = yield select(getLoadedPage);

  // 2. save document cms content
  if (!response.error) {
    response = yield remoteCall({
      body: {
        cmsContent: {
          ...loadedPage.cmsContent
        }
      },
      documentid,
      variantid,
      endppoint: 'content'
    });
  }

  // 3. save document seo
  if (!response.error) {
    response = yield remoteCall({
      body: {
        seo: {
          ...loadedPage.seo
        }
      },
      documentid,
      variantid,
      endppoint: 'seo'
    });
  }

  // 4. refresh page hierarchy
  if (!response.error) {
    response = yield call(makeRequest, {
      method: 'GET',
      url: `${config.apiEndpoints.cms}/folders/pages`,
      name: constants.RELOAD_PAGE_HIERARCHY
    });

    if (!response.error) {
      yield put(
        cmsActions.storePagesHierarchy({
          cmsPagesHierarchy: get(response, 'data.children', [])
        })
      );
    }
  }

  if (!response.error) {
    yield put(toastListActions.addMessage('generic.data.savedSuccess'));
    history.push(`/contentmanagement/page/${documentid}/variants/${variantid}`);
  } else {
    yield put(toastListActions.addMessageError(response.error as any, 5000));
  }
}

function* remoteCall(props: {
  body: any;
  documentid: string;
  variantid: string;
  endppoint?: string;
}) {
  const url = `${config.apiEndpoints.cms}/documents/${props.documentid}/variants/${
    props.variantid
  }${props.endppoint ? `/${props.endppoint}` : ''}`;

  return yield call(makeRequest, {
    body: props.body,
    method: 'PUT',
    url,
    name: constants.ACTION_CMS_CREATE_PAGE_VARIANT
  });
}
