/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import i18n from 'i18n';
import * as React from 'react';
import classnames from 'classnames';
import LinkPrimitive from 'framework/components/ui/Primitives/LinkPrimitive';
import { IKebabOption } from '../Kebab.interfaces';
import './_style.scss';
import { renderLabel } from '../Utils';

export class KebabOption extends React.PureComponent<IKebabOption> {
  onClickHandler = (e?: any) => {
    const { disabled, onClick } = this.props;

    if (!disabled && onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      hidden, linkTo, onClick, className, label, helperText, disabled, testId, index,
    } = this.props;
    const infoText = helperText || (typeof label === 'string' && i18n.t(label));

    const isHidden = !!(hidden || !(linkTo || onClick));

    return !isHidden ? (
      <div
        role="link"
        tabIndex={index}
        title={infoText}
        aria-label={infoText}
        onClick={this.onClickHandler}
        className={classnames(className, disabled && `${className}--disabled`)}
        data-testid={testId}
      >
        {linkTo ? (
          <LinkPrimitive to={linkTo} className={`${className}__link`}>
            {renderLabel(label)}
          </LinkPrimitive>
        ) : (
          renderLabel(label)
        )}
      </div>
    ) : null;
  }
}

export default KebabOption;
