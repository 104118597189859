import * as React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { actions, selectors, types } from '.';
import { Modal } from './Modal';

const mapStateToProps = (state, ownProps) => {
  const total = selectors.totalModalsOpen(state);
  return {
    isVisible: selectors.isModalOpen(state, ownProps.name),
    totalModalsOpen: total,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModal: () => {
    dispatch(actions.createCloseModalAction(ownProps.name));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal as any) as any as any as React.StatelessComponent<types.IModalProps>;
