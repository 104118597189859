import { put, select } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { apiClientSelectors } from 'framework/selectors';
import * as get from 'lodash.get';
import { actions as toastListActions } from 'framework/components/ui/ToastList';
import { ContentManagementState } from '../contentmanagement.interfaces';

export const getLoadedPage = (state: ContentManagementState) => {
  return state.contentmanagement.loadedPage;
};

export function* displayMessageErrors(props: { action: any; names: string[]; error: string }) {
  if (props.names.find(() => props.action.name)) {
    yield put(toastListActions.addMessageError(props.error, 3000));
  }
}

export function* displayFormSubmitErrors(payload, formName: string, formFields: string[]) {
  const errors = get(payload, 'response.data.errors');
  const isFormErrors =
    errors &&
    !!formFields.find((f) => {
      return !!errors[f];
    });
  if (!isFormErrors) {
    yield put(toastListActions.addMessageError(payload.message));
  } else {
    const _errors = formatErrors(errors);
    yield put(stopSubmit(formName, _errors));
  }
}

export function* processFormErrors(error: any, formName: string, formFields: string[]) {
  const { errors } = error;
  const isFormErrors =
    errors &&
    !!formFields.find((f) => {
      return !!errors[f];
    });
  if (!isFormErrors) {
    yield put(toastListActions.addMessageError(errors));
  } else {
    const _errors = formatErrors(errors);
    yield put(stopSubmit(formName, _errors));
  }
}

const formatErrors = (errors) => {
  return Object.keys(errors).reduce((acc, key) => {
    return {
      ...acc,
      [key]: Array.isArray(errors[key]) ? errors[key][0] : errors[key],
    };
  }, {});
};

export function* callIsSuccessful(callName: string) {
  const state = yield select((s) => s);
  return !apiClientSelectors.getIsFetching(state, callName) && !apiClientSelectors.getHasError(state, callName);
}
