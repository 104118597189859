import * as React from 'react';
import { connect } from 'react-redux';
import { BaseModalPreventNavigation } from 'framework/components/ui/ModalPreventNavigation/BaseModalPreventNavigation';
import { defaultModalName } from 'framework/components/ui/ModalPreventNavigation/ModalPreventNavigation';
import { formBlocker } from 'framework/components/ui/ModalPreventNavigation/ModalPreventNavigation.wrapper';
import { useDispatch } from 'framework/utils/useDispatch';
import { modalActions, tabsActions } from 'framework/actions';
import { ITabGroupProps } from '../Tabs.interface';
// import Tabs from '../Tabs.container';
import TabSteps from '../../TabSteps';

interface ITabGroupPrevNavProps extends ITabGroupProps {
  formNames?: string[];
}

const TabsPreventNavComponent = (props: ITabGroupPrevNavProps & {
  dirtyForms?: boolean
}) => {
  const { id, isEditMode } = props;

  const dispatch = useDispatch();
  /**
   * @dirtyForms checks wheither any associated form is dirty. If dirty then should prevent navigation
   */
  const { dirtyForms, onBeforeChange } = props;

  /**
   *@askConfirmNavigation if true, then displays Prevent Nav Modal
   */
  const [askConfirmNavigation, setAskConfirmNavigation] = React.useState<boolean>(false);

  /**
   *@nextTab holds value of next tab to display in case user chooses to proceed with navigation
   */
  const [nextTab, setNextTab] = React.useState<string>();

  /**
   *@modalName dynamic name of Prevent Modal (in case there are multiple prevent modals in a page)
   */
  const modalName = `${defaultModalName}_${id}`;

  const openModal = () => dispatch(modalActions.createOpenModalAction(modalName));
  const closeModal = () => dispatch(modalActions.createCloseModalAction(modalName));
  const goToNextTab = () => dispatch(tabsActions.setOpenTab(props.id, nextTab));

  /**
   *
   * @param handleBeforeChange if returns false then navigation is prevented
   */
  const handleBeforeChange = (tabId: string) => {
    setNextTab(tabId); // stores next tab

    // returns false if any associated form is dirty or if Dev has passed a callback which returns false
    const canChange = !dirtyForms && (!onBeforeChange || onBeforeChange(tabId));

    // prepare to display modal
    setAskConfirmNavigation(!canChange);

    return canChange;
  };

  const handleCancelNavigation = () => {
    setAskConfirmNavigation(false);
    closeModal();
    goToNextTab();
  };

  const handleAcceptNavigation = () => {
    setAskConfirmNavigation(false);
    closeModal();
  };

  const handleDismiss = () => {
    setAskConfirmNavigation(false);
  };

  React.useEffect(() => {
    // display modal
    if (askConfirmNavigation) {
      openModal();
    }
  }, [askConfirmNavigation]);

  return (
    <>
      <BaseModalPreventNavigation
        modalName={modalName}
        onAcceptNavigation={handleAcceptNavigation}
        onCancelNavigation={handleCancelNavigation}
        onDismiss={handleDismiss}
      />
      <TabSteps {...props} onBeforeChange={handleBeforeChange} isEditMode={isEditMode} />
    </>
  );
};

const mapState = (state, ownProps: ITabGroupPrevNavProps) => ({
  dirtyForms: formBlocker(state, ownProps.formNames),
});

export const TabsPreventNav = connect(mapState)(TabsPreventNavComponent);
