export enum GAEventTypes {
  PageView = 'page_navigation',
  ClickEvent = 'click_event',
  ModalEvent = 'modal_event',
}

export interface GASendEvent {
  hitType?: GAEventTypes;
  page?: string;
  title: string;
}

export interface IGATrackEvent {
  event: GAEventTypes.ClickEvent;
  payload: {
    page_ref: string;
    click_location: string;
    click_target: string;
  };
}

export interface IGAModalEvent {
  event: GAEventTypes.ModalEvent;
  payload: {
    modal_name: string;
    modal_action_type: IModalEventActionType;
  };
}

export interface IGAPageViewEvent {
  event: GAEventTypes.PageView;
  payload: {
    page_ref: string;
    page_visit: string;
  };
}

export type IModalEventActionType = 'open' | 'close';

export interface AppTracker {
  pageViewEvent: (eventDetails: GASendEvent) => void;
  modalViewEvent: (modalName: string, action: IModalEventActionType) => void;
  trackEvent: (name: string, value?: string | number | boolean, label?: string) => void;
}

export enum GaActions {
  NavigationMenu = 'Navigation menu',
  Breadcrumb = 'Breadcrumb',
  ActionMenuOption = 'Actions Menu Option',
}

export interface TrackEventProps {
  name: string;
  value?: string | number | boolean;
  label?: string;
}
