import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { PromotionsTranslations } from './translations';
import { promotionCodeListRoute } from './PromotionCodes/PromotionCodesList/route';
import { promotionCodeCreateRoute } from './PromotionCodes/PromotionCodesEdit/route';
import { promotionListRoute, promotionCreateRoute, promotionsClaims } from './Promotions/constants';

const globalPromotionsClaims = {
  [AuthorizationClaims.PROMOTIONS_GLOBAL]: UserAccessRights.ReadWrite,
  ...promotionsClaims,
};

const promotionsModule: ModuleConfig = {
  name: 'promotions',
  appMenuTitle: PromotionsTranslations.Promotions,
  appMenuIcon: 'promotion',
  modulePath: '/promotions',
  additionalClaims: [
    {
      menuTitle: PromotionsTranslations.GlobalPromotions,
      authClaim: globalPromotionsClaims,
    },
  ],
  pageGroups: [
    {
      title: PromotionsTranslations.Promotions,
      routes: [
        promotionListRoute,
        promotionCreateRoute,
      ],
    },
    {
      title: PromotionsTranslations.PromotionCodes,
      routes: [
        promotionCodeListRoute,
        promotionCodeCreateRoute,
      ],
    },
  ],
};

export default promotionsModule;
