import * as React from 'react';
import { formField, IformField } from 'framework/components/hoc/formField';
import { RangePrimitive, IRangePrimitive } from '../Primitives';

export const Range = (props: IRangePrimitive) => {
  return <RangePrimitive {...props} />;
};

export default (formField(Range) as any) as React.StatelessComponent<
Partial<IRangePrimitive & IformField>
>;
