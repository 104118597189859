import { call, put } from 'redux-saga/effects';
import { setIsFetching } from 'framework/api/apiClient/actions';

export function* withFetching(stateName: string, fn: any, ...args): any {
  // Set isFetching to true
  yield put(setIsFetching(stateName));

  // Make request
  const response = yield call(fn, ...args);

  yield put(setIsFetching(stateName, false));

  return response;
}
