import * as React from 'react';
import { formField, IformField } from 'framework/components/hoc/formField';
import RangeWithNumerical from './RangeWithNumerical';
import { IRangeWithNumerical } from './RangeWithNumerical.interfaces';

interface Props extends IRangeWithNumerical {
  /** @ignore hidde values from primitives */
  value?: number;
}

export class RangeWithNumericalContainer extends React.Component<Props> {
  render() {
    return <RangeWithNumerical className={this.props.className} {...this.props as any} />;
  }
}
export default (formField(RangeWithNumericalContainer as any) as any) as React.StatelessComponent<
Props & IformField
>;
