import React, { useEffect } from 'react';
import { removeRootNode } from 'framework/modules/contentmanagement/Core/Document/utils';
import { hasSameItems } from 'framework/utils/helpers';

type Props = {
  initialUserStores: string[]
};

export const usePageStores = ({ initialUserStores = [] }: Props) => {
  const [initialStores, setInitialStores] = React.useState(removeRootNode(initialUserStores));
  const [selectedStores, setSelectedStores] = React.useState(initialStores || []);
  const [isDirty, setIsDirty] = React.useState(!hasSameItems(initialStores, selectedStores));

  const reset = () => setSelectedStores(initialStores);
  const setDirty = () => setIsDirty(!hasSameItems(initialStores, selectedStores));

  useEffect(() => {
    const stores = removeRootNode(initialUserStores);
    if (!hasSameItems(initialStores, stores)) {
      setInitialStores(removeRootNode(stores));
      setSelectedStores(stores);
    }
  }, [initialUserStores]);

  useEffect(setDirty, [initialStores, selectedStores]);

  return {
    isDirty,
    reset,
    selectedStores,
    setSelectedStores,
  };
};
