import { toastError, toastSuccess } from 'stores/toast';

/**
 * @deprecated not a hook. Use toastSuccess and toastError instead
 * @see toastSuccess stores/toast.ts
 */
export const useToastList = () => ({
  toastSuccess,
  toastError,
});
