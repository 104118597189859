export const translations = {
  reports: 'navigation.reports',
  personalShoppers: 'modules.reports.personalShoppers',
  slotManagement: 'modules.reports.slotManagement',
  orders: 'modules.reports.orders',
  customers: 'modules.reports.customers',
  allReports: 'modules.reports.allReports',
  printReport: 'modules.reports.printReport',
  reportDetails: 'modules.reports.reportDetails',
};
