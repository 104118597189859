import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { AppRoute, AppRouteClaims } from 'framework/components/navigation/Navigation.interfaces';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import { goToRoute } from '../../components/navigation/service';

const userClaims: AppRouteClaims = [
  AuthorizationClaims.SITEADMIN_USER,
  { [AuthorizationClaims.SITEADMIN_ROLE]: UserAccessRights.Read },
  { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
];

export const rolesListRoute: AppRoute = {
  pageTitle: 'modules.siteadmin.rolesListPageTitle',
  menuTitle: 'modules.siteadmin.rolesListMenuTitle',
  path: '/siteadmin/roles',
  authClaim: AuthorizationClaims.SITEADMIN_ROLE,
  // eslint-disable-next-line import/no-cycle
  Component: () => import('./pages/RolesList'),
};

export const roleEditRoute: AppRoute = {
  pageTitle: 'modules.siteadmin.roleEdit',
  breadcrumbTitle: 'modules.siteadmin.roleEditLower',
  path: '/siteadmin/roles/edit/:id',
  authClaim: AuthorizationClaims.SITEADMIN_ROLE,
  Component: () => import('./pages/EditRole'),
  parent: rolesListRoute,
};

export const goToEditRoleRoute = (id: string) => goToRoute(roleEditRoute, { id });

export const roleCreateRoute: AppRoute = {
  pageTitle: 'modules.siteadmin.createRole',
  breadcrumbTitle: 'modules.siteadmin.createRoleLower',
  path: '/siteadmin/roles/create',
  authClaim: AuthorizationClaims.SITEADMIN_ROLE,
  Component: () => import('./pages/EditRole'),
  parent: rolesListRoute,
};

export const userListRoute: AppRoute = {
  pageTitle: 'modules.siteadmin.userListPageTitle',
  menuTitle: 'modules.siteadmin.userListMenuTitle',
  path: '/siteadmin/users',
  authClaim: userClaims,
  Component: () => import('./pages/UserList'),
  extendedClaims: [
    {
      label: 'modules.siteadmin.claims.manageUsers',
      claims:{
        [AuthorizationClaims.SITEADMIN_USER]: UserAccessRights.ReadWrite,
      }
    },
    {
      label:'modules.siteadmin.claims.changePassword',
      claims:{
        [AuthorizationClaims.CUSTOMER_USER_PASSWORD]: UserAccessRights.ReadWrite,
      }
    }
  ],
};

export const userCreateRoute: AppRoute = {
  pageTitle: 'modules.siteadmin.userCreatePageTitle',
  menuTitle: 'modules.siteadmin.userCreatePageTitle',
  path: '/siteadmin/users/create',
  authClaim: userClaims,
  Component: () => import('./pages/UserCreate'),
  parent: userListRoute,
};

export const userEditPageRoute: AppRoute = {
  pageTitle: 'modules.siteadmin.userEditPage',
  breadcrumbTitle: 'modules.siteadmin.userEditPage',
  path: '/siteadmin/users/edit/:userid',
  authClaim: userClaims,
  Component: () => import('./pages/UserEdit'),
  parent: userListRoute,
};

export const siteadminRoutes: AppRoute[] = [
  rolesListRoute,
  userListRoute,
  userCreateRoute,
  userEditPageRoute,
  roleEditRoute,
  roleCreateRoute,
];
