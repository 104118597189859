import { fetchData } from 'framework/api/fetch-data';
import { getApiUrl } from 'config';
import { Product } from '../models/product';

export const fetchProduct = async (sku: string, storeId: string = 'global'): Promise<Product> => {
  if (sku) {
    const result = await fetchData<Product>({
      url: getApiUrl('/search/explanation/store/{storeId}/locale/{locale}/products/{sku}', { sku, storeId }),
      // mock: 'product-explanation-sku',
    });

    return result.data;
  }
  return undefined;
};
