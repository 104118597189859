/* eslint-disable  */
import {
  BoostAction,
  FilterCondition,
  ProductPlacement,
  SearchConfiguration,
} from 'framework/modules/searchadmin/models/searchConfiguration';
import { objectHelper } from 'framework/utils';
import { ExcludeAction } from '../../../models/searchConfiguration';
import { ActionFieldType } from '../components/Actions/ActionsHide/constants';

export const transformServerDataIntoFormData = (serverData: any = {}) => {
  const {
    termsCondition,
    filterConditions,
    boostActions,
    placementActions,
    excludeActions = {},
    scheduleInfo = {},
    name,
    enabled = true,
    status,
  } = serverData;
  const actions = [];
  let toReturn: any = {
    name,
    enabled,
    status,
    // TODO: format dates to work with DatePicker
    startTime: scheduleInfo.startDate || null,
    endTime: scheduleInfo.finishDate || null,
  };

  if (termsCondition) {
    toReturn = {
      ...toReturn,
      termsCondition: 'search',
      termsCondition_operation: termsCondition.operation,
      termsCondition_terms: termsCondition.terms,
    };
  } else {
    toReturn = {
      ...toReturn,
      termsCondition: 'all',
    };
  }

  if (Array.isArray(filterConditions)) {
    const processedFilterCondition = [];
    filterConditions.forEach((condition) => {
      const fieldName = condition.field === 'store.storeId'
        ? 'condition_search_terms_select'
        : 'condition_search_terms_textarea';
      // condition.values is in this format ["one, two"]
      const fieldValue = condition.field === 'store.storeId'
        ? condition.values.toString().split(',')
        : condition.values;

      processedFilterCondition.push({
        field: condition.field,
        operation: condition.operation,
        [fieldName]: fieldValue,
      });
    });

    toReturn = {
      ...toReturn,
      filterConditions: processedFilterCondition,
    };
  }

  if (Array.isArray(boostActions)) {
    boostActions.forEach((boost) => {
      let bustAndBuryAction = {};
      let staticFilter = {};
      const dynamicFilters = [];

      // Process each filter
      if (Array.isArray(boost.filters)) {
        boost.filters.forEach((filter, index) => {
          if (index === 0) {
            // 'static' rule
            staticFilter = {
              boost_actions: filter.field,
              boost_actions_operation: filter.operation,
              description: filter.values,
            };
          } else {
            // 'dynamic' rules
            dynamicFilters.push({
              field: filter.field,
              operation: filter.operation,
              values: filter.values,
            });
          }
        });
      }

      bustAndBuryAction = {
        field: 'bust_or_bury',
        boost_actions_weight: (boost.weight && boost.weight.toString()) || '0',
        ...staticFilter,
      };
      if (dynamicFilters.length > 0) {
        bustAndBuryAction = {
          ...bustAndBuryAction,
          boostFilter: dynamicFilters,
        };
      }

      actions.push(bustAndBuryAction);
    });
  }

  if (Array.isArray(placementActions)) {
    const placements = [];
    placementActions.forEach((placement) => {
      const product = placement.product || {};

      placements.push({
        position: placement.position + 1,
        product: {
          value: product.value,
          label: product.label || product.value,
        },
      });
    });
    if (placements.length > 0) {
      actions.push({
        field: 'position',
        position: placements,
      });
    }
  }

  if (Array.isArray(excludeActions.products)) {
    const hiddenProducts = [];
    excludeActions.products.forEach((exclude) => {
      const product = exclude || {};

      hiddenProducts.push({
        product: {
          value: product.value,
          label: product.label || product.value,
          id: product.productId,
        },
      });
    });
    if (hiddenProducts.length > 0) {
      actions.push({
        field: 'hide',
        action: ActionFieldType.Products,
        hide: hiddenProducts,
      });
    }
  }

  if (Array.isArray(excludeActions.categoryBreadcrumbs)) {
    const hiddenCategories = [];

    excludeActions.categoryBreadcrumbs.forEach((exclude) => {
      const category = exclude || {};
      hiddenCategories.push({
        categoryBreadCrumb: {
          value: category.value,
          label: category.label || category.value,
          id: category.categoryBreadcrumb,
        },
      });
    });

    if (hiddenCategories.length > 0) {
      actions.push({
        field: 'hide',
        action: ActionFieldType.Category,
        // remove duplicates, could happen if rule was created before sending parent category
        // only if all subcategories are selected change
        hide: hiddenCategories.filter((node, i, input) => input.findIndex(
          (n) => n.categoryBreadCrumb.id === node.categoryBreadCrumb.id,
        ) === i),
      });
    }
  }

  if (actions.length > 0) {
    toReturn = {
      ...toReturn,
      actions,
    };
  }

  return toReturn;
};


const handleFormDataItteration = (boostActions, placementActions, excludeActions, formData) =>
  formData.actions.forEach((action) => {
    const field = (action.field && action.field.value) || action.field;
    const type = (action.action && action.action.value) || action.action;
    if (field === 'bust_or_bury') {
      const boostActionsFilters = [];
      // Check for a 'static' filter
      if (action.boost_actions) {
        boostActionsFilters.push({
          field: action.boost_actions.value || action.boost_actions,
          values: action.description,
          operation: action.boost_actions_operation.value || action.boost_actions_operation,
        });
      }
      // Check of a 'dynamic' filters
      if (action.boostFilter) {
        action.boostFilter.forEach((filter) => {
          boostActionsFilters.push({
            field: filter.field.value || filter.field,
            values: filter.values,
            operation: filter.operation.value || filter.operation,
          });
        });
      }

      if (boostActionsFilters.length > 0) {
        boostActions.push({
          filters: boostActionsFilters,
          weight: action.boost_actions_weight || 0,
        });
      }
    } else if (field === 'position') {
      if (Array.isArray(action.position)) {
        action.position.forEach((data) => {
          placementActions.push({
            productId: data.product.value,
            position: data.position - 1,
          });
        });
      }
    } else if (field === 'hide') {
      if (Array.isArray(action.hide) && type === ActionFieldType.Products) {
        action.hide.forEach((data) => {
          excludeActions.productIds.push(data.product.value);
        });
      } else if (Array.isArray(action.hide) && type === ActionFieldType.Category) {
        action.hide.forEach((data) => {
          excludeActions.categoryBreadcrumbs.push(data.categoryBreadCrumb.value);
        });
      }
    }
  });


export const processRulesDataForSave = (formData) => {
  const { startTime, endTime } = formData;
  let toReturn: any = {
    name: formData.name,
    enabled: formData.enabled,
    priority: 0,
  };

  if (startTime || endTime) {
    // only create 'scheduleInfo' if 'startTime' or 'endTime' exist,
    // send an empty ojbect will return an error
    toReturn.scheduleInfo = {};
    if (startTime) {
      toReturn.scheduleInfo.startDate = startTime;
    }
    if (endTime) {
      toReturn.scheduleInfo.finishDate = endTime;
    }
  }

  // Process termsConditions
  const termsConditionValue = formData?.termsCondition?.value
    ? formData?.termsCondition?.value
    : formData?.termsCondition;
  if (termsConditionValue === 'search') {
    toReturn = {
      termsCondition: {
        terms: formData.termsCondition_terms,
        operation: formData?.termsCondition_operation?.value
          ? formData?.termsCondition_operation?.value
          : formData?.termsCondition_operation,
      },
      ...toReturn,
    };
  }

  // Process filterConditions
  if (Array.isArray(formData.filterConditions)) {
    const filterConditions = formData.filterConditions.map((condition) => {
      // if it is store then we need to get values from select
      const condition_term = (condition.field && condition.field.value) || condition.field;
      const operation = (condition.operation && condition.operation.value) || condition.operation;
      const termsSelect = condition.condition_search_terms_select || [];

      if (condition_term === 'store.storeId') {
        const storeIds = termsSelect.map((el) => el.value || el);

        return storeIds.length
          ? {
            field: condition_term,
            values: storeIds,
            operation,
          }
          : {};
      }
      if (condition_term === 'store.defaultCategory.category.raw') {
        return {
          field: condition_term,
          values: condition.condition_search_terms_textarea,
          operation,
        };
      }
      return {};
      // otherwise it is textarea
    });

    toReturn = {
      // Remove empty {} filters
      filterConditions: filterConditions.filter((fc) => !objectHelper.isEmpty(fc)),
      ...toReturn,
    };
  }

  // Process actions
  if (Array.isArray(formData.actions)) {
    const boostActions = [];
    const placementActions = [];
    const excludeActions = { productIds: [], categoryBreadcrumbs: [] };
    handleFormDataItteration(boostActions, placementActions, excludeActions, formData);
    if (boostActions.length > 0) {
      toReturn = {
        boostActions,
        ...toReturn,
      };
    }

    if (placementActions.length > 0) {
      toReturn = {
        placementActions,
        ...toReturn,
      };
    }

    toReturn = {
      excludeActions: {
        ...(excludeActions.productIds.length > 0) && { productIds: excludeActions.productIds },
        ...(excludeActions.categoryBreadcrumbs.length > 0)
            && { categoryBreadcrumbs: excludeActions.categoryBreadcrumbs },
      },
      ...toReturn,
    };
  }

  return toReturn;
};

export const processRulesDataForPreview = (formData) => {
  const toReturn: SearchConfiguration = {
    overrideType: 'Override',
    rulesOverride: {},
  };

  // Process termsConditions
  const termsConditionValue = formData?.termsCondition?.value
    ? formData.termsCondition?.value
    : formData.termsCondition;

  if (termsConditionValue === 'search') {
    toReturn.rulesOverride.termsCondition = {
      terms: formData.termsCondition_terms,
      operation: formData?.termsCondition_operation?.value
        ? formData?.termsCondition_operation?.value
        : formData?.termsCondition_operation,
    };
  }

  // Process filterConditions
  if (Array.isArray(formData.filterConditions)) {
    const filterConditions: FilterCondition[] = formData.filterConditions.map((condition) => {
      // if it is store then we need to get values from select
      const condition_term = (condition.field && condition.field.value) || condition.field;
      const operation = (condition.operation && condition.operation.value) || condition.operation;
      const termsSelect = condition.condition_search_terms_select || [];
      if (condition_term === 'store.storeId') {
        return {
          field: condition_term,
          values: termsSelect.map((el) => el.value || el),
          operation,
        };
      }
      if (condition_term === 'store.defaultCategory.category.raw') {
        return {
          field: condition_term,
          values: [condition.condition_search_terms_textarea],
          operation,
        };
      }
      return {};
      // otherwise it is textarea
    });

    toReturn.rulesOverride.filterConditions = filterConditions;
  }

  // Process actions
  if (Array.isArray(formData.actions)) {
    const boostActions: BoostAction[] = [];
    const placementActions: ProductPlacement[] = [];
    const excludeActions: ExcludeAction = { productIds: [], categoryBreadcrumbs: [] };
    handleFormDataItteration(boostActions, placementActions, excludeActions, formData);
    if (boostActions.length > 0) {
      toReturn.rulesOverride.boostActions = boostActions;
    }

    if (placementActions.length > 0) {
      toReturn.rulesOverride.placementActions = placementActions;
    }

    toReturn.rulesOverride.excludeActions = {
      ...(excludeActions.productIds.length > 0) && { productIds: excludeActions.productIds },
      ...(excludeActions.categoryBreadcrumbs.length > 0)
          && { categoryBreadcrumbs: excludeActions.categoryBreadcrumbs },
    };
  }

  return toReturn;
};
