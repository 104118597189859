import { call, put } from 'redux-saga/effects';
import { toastListActions } from 'framework/actions';
import { makeRequest } from 'framework/api/make-request';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import { actions } from '../index';
import { config } from 'config';
import { ACTION_RELEVANCE_ATTRIBUTES_RESET_GLOBAL } from 'framework/modules/searchadmin/constants';

export default function* watchMerchandisingRelevanceResetToGlobal() {
  yield takeEveryWithProgressBar(ACTION_RELEVANCE_ATTRIBUTES_RESET_GLOBAL, process);
}

function* process({ payload }) {
  const { locales, storeId } = payload;

  // 1. Make Reindexing Request
  const response = yield call(makeRequest, {
    name: 'DeleteRelevanceSettings',
    url: `${config.apiEndpoints.searchRelevance}/locale/${locales}/store/${storeId}`,
    method: 'DELETE'
  });

  if (response.success) {
    // 2.1. Get Up-to-date data for a DataGrid
    yield put(actions.getRelevancesAtts(payload));
  } else {
    yield put(toastListActions.addMessageError('There were reseting to Global'));
  }
}
