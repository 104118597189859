import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import { Icon, Text } from 'ui';
import { ICardPrimitive } from './CardPrimitive.interfaces';
import './_styles.scss';

const clx = conf.prefixClassName('card-primitive');

export default class CardPrimitive extends React.Component<ICardPrimitive> {
  render() {
    const { caption, children, icon } = this.props;
    const content = icon ? <Icon name={icon} size="lg" /> : children;

    return (
      <div className={clx}>
        <div className={`${clx}__content`}>{content}</div>
        <div className={`${clx}__caption`}>
          <Text caption={caption} />
        </div>
      </div>
    );
  }
}
