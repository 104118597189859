import * as React from 'react';
import { connect } from 'react-redux';
import { DatePickerPrimitive } from 'framework/components/ui/FormComponents/Primitives/DatePickerPrimitive';
import { IDatePicker } from './DatePicker.interfaces';
import { selectors } from '../Form';

const mapStateToProps = (state, ownProps: any): IDatePicker => {
  const {
    name,
    rangeStartDate,
    rangeEndDate,

    // props from redux-form
    form: formName,
  } = ownProps;
  let aditionaProps = {};

  // If the name of other field is indicated on 'rangeStartDate' or 'rangeEndDate',
  // get its value from reduxForm to display the range.
  if (rangeStartDate) {
    aditionaProps = {
      isEndSelector: true,
      startDate: selectors.getFieldValue(state, formName, rangeStartDate),
      endDate: selectors.getFieldValue(state, formName, name),
    };
  } else if (rangeEndDate) {
    aditionaProps = {
      isStartSelector: true,
      startDate: selectors.getFieldValue(state, formName, name),
      endDate: selectors.getFieldValue(state, formName, rangeEndDate),
    };
  }

  return aditionaProps;
};

export default (connect(mapStateToProps)(DatePickerPrimitive) as any) as React.StatelessComponent<
IDatePicker
>;
