import { call, put } from 'redux-saga/effects';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { toastListActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';

import { config } from 'config';
import { actionTypes } from '../actions';

const { API_PROVIDER_GET_DELIVERY_AREAS } = actionTypes;
const baseUri = config.apiEndpoints.storeDeliveryAreas;

function* process({ payload }) {
  const response = yield call(makeRequestAndSaveState, {
    name: API_PROVIDER_GET_DELIVERY_AREAS,
    method: 'GET',
    url: `${baseUri}/${payload.id}/deliveryareas`,
  });

  if (!response.success) {
    yield put(toastListActions.addMessageError('modules.stores.storeSetup.errors.loadingError'));
  }
}

export default function* watchGetDeliveryAreasAction() {
  yield takeEveryWithProgressBar(actionTypes.STORES_GET_DELIVERY_AREAS, process);
}
