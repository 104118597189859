import { createSelector } from 'reselect';
import { apiClientSelectors, formSelectors } from 'framework/selectors';
import * as CONST from 'framework/modules/searchadmin/constants';

// Get `value` form reduxForm value
export const formDataValues = (state) => formSelectors.getFormValues(state, CONST.FORM_RELEVANCE_RELEVANCE_GENERAL);
export const formDataValuesAtt = (state) => formSelectors.getFormValues(state, CONST.FORM_RELEVANCE_RELEVANCE_ATT);
export const attributesAreDirty = (state) => formSelectors.getIsFormDirty(state, CONST.FORM_RELEVANCE_RELEVANCE_ATT);
export const attListValues = createSelector(
  formDataValuesAtt,
  (fd) => (fd && fd.attributes) || [],
);

/** ******************** */
/* ---   Attributes  ---*/
/** ******************** */

// Name received from gateway has to be a single word. Added spaces before capital letters to format name
const formatAttName = (name: string) => name.replace(/([a-z])([A-Z])/g, '$1 $2');

const attAPIdata = (state: any) => apiClientSelectors.selectApiClientData(state, CONST.API_DATA_PROVIDER_RELEVANCE_ATTRIBUTES);

export const attList = createSelector(
  [attAPIdata],
  (APIdata) => {
    const attributes = APIdata.items || {};
    const attributesList = {};

    Object.keys(attributes).forEach((key) => {
      const att = attributes[key];
      const { id } = att;
      const originalValue = att.value || 0;

      attributesList[id] = {
        id,
        name: formatAttName(att.name),
        originalValue,
        originIsVisible: att.visible || originalValue > 0 || false,
      };
    });

    return attributesList;
  },
);

export const getRelevanceAttByID = (state, ID) => {
  const attValues = attListValues(state);
  return attValues.find((av) => av.id === ID);
};
export const getRelevanceAtt = createSelector(
  [attList, attListValues],
  (att, attValues) => {
    const attArray = [];

    Object.keys(att).forEach((key) => {
      // Get values from UI list
      const attFormVal = attValues.find((av) => av.id === key);

      attArray.push({
        ...att[key],
        value: (attFormVal && attFormVal.value) || 0,
        isVisible: !!attFormVal,
      });
    });

    return attArray;
  },
);

export const getRelevanceAttVisible = createSelector(
  [getRelevanceAtt],
  (att) => att.filter((a) => !!a.isVisible),
);
export const getRelevanceAttHidden = createSelector(
  [getRelevanceAtt],
  (att) => att.filter((a) => !a.isVisible),
);

export const getRelevanceAttUpdates = createSelector(
  [getRelevanceAtt],
  (att) => att.filter((a) => a.value !== a.originalValue || a.isVisible !== a.originIsVisible),
);

// Locales might not be loaded initially, then it will be undefined
export const getIsGlobal = createSelector(
  [attAPIdata],
  (data) => ['undefined', CONST.DEFAULT_STORE_ID].indexOf(data.storeId) > -1,
);
// Store configuration is a 'child' of global.
export const isFromGlobal = createSelector(
  [attAPIdata],
  (data) => !!data.isFromGlobal,
);

/** ******************** */
/* ---    Products   ---*/
/** ******************** */
const getProductDesc = (name: string, desc?: string) => (desc ? `${name}: ${desc}` : name);

export const getProductsListCurrent = (state) => getProducts(state, CONST.API_DATA_PROVIDER_RELEVANCE_CURRENT);
export const getProductsListPreview = (state) => getProducts(state, CONST.API_DATA_PROVIDER_RELEVANCE_PREVIEW);

const getProducts = (state, dataProvider: string) => {
  const isPreview = dataProvider === CONST.API_DATA_PROVIDER_RELEVANCE_PREVIEW;
  const productsData = apiClientSelectors.selectApiClientData(state, dataProvider);
  const productsList = productsData.items || [];

  return {
    total: productsData.total || 0,
    isFetching: apiClientSelectors.getIsFetching(state, dataProvider),
    list: productsList.map((item) => ({
      ...item,
      name: item.name,
      description: getProductDesc(item.name, item.shortDescription),
      score: item.score,
      sku: item.sku,
      productId: item.productId,
      customPlacement: item.customPlacement,
      positionDifference: isPreview && item.positionDifference,
      imageUrl: item.image,
    })),
  };
};
