/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { InferableFunction } from 'types';
import Modal from '../Modal';
import { Loader } from '../Loader';
import { prefixClassName } from '../_conf';
import { useModal } from '../Modal/useModal';
import './_style.scss';
import { IMessageTypes } from '../Primitives/MessagePrimitive/MessagePrimitve.interface';

const modalClassName = prefixClassName('modal--confirmation');
const processingClassName = prefixClassName('modal-processing--processing');

enum ModalState {
  Confirm,
  Processing,
  Error,
}
interface PropsBase {
  titleCaption: string;
  titleProcessingCaption: string;
  warning: string;
  confirmBtnCaption: string;
  cancelBtnCaption: string;
  errorBtnCaption: string;
  confirmMessageType?: IMessageTypes;
}

interface ModalProps extends PropsBase {
  closeModal: () => void;
  confirmHandler: InferableFunction;
  error: string;
}
/**
 * These are the properties the modal receives in each of its possible states
 */
const modalProps = {
  [ModalState.Confirm]: ({
    titleCaption,
    warning,
    closeModal,
    confirmHandler,
    confirmBtnCaption,
    cancelBtnCaption,
    confirmMessageType = 'warning',
  }: ModalProps) => ({
    className: modalClassName,
    caption: titleCaption,
    canClose: true,
    messages: [{ type: confirmMessageType, caption: warning, showLabel: confirmMessageType === 'warning' }],
    footerOptions: [
      {
        caption: confirmBtnCaption,
        onClick: confirmHandler,
      },
      {
        caption: cancelBtnCaption,
        outline: true,
        onClick: closeModal,
      },
    ],
    children: <></>,
  }),
  [ModalState.Processing]: ({ titleProcessingCaption }: ModalProps) => ({
    className: `${modalClassName} ${processingClassName}`,
    caption: titleProcessingCaption,
    canClose: false,
    children: <Loader />,
  }),
  [ModalState.Error]: ({ titleProcessingCaption, errorBtnCaption, closeModal, error }: ModalProps) => ({
    className: modalClassName,
    caption: titleProcessingCaption,
    canClose: false,
    messages: [{ type: 'warning', caption: error }],
    footerOptions: [
      {
        caption: errorBtnCaption,
        onClick: closeModal,
      },
    ],
    children: <></>,
  }),
};

interface ProcessingModalProps extends PropsBase {
  /**
   * Resolves <any> throws an error message <string>
   */
  onConfirm: () => Promise<any>;
  /**
   * modal name
   */
  name: string;
  onClose?: () => void;
  resetOnClose?: boolean;
}

const ProcessingModal = ({ onConfirm, resetOnClose = false, name, onClose, ...rest }: ProcessingModalProps) => {
  const [state, setState] = React.useState(ModalState.Confirm);
  const [error, setError] = React.useState('');
  const { closeModal } = useModal(name);

  const closeModalHandler = () => {
    setState(ModalState.Confirm);

    if (onClose) {
      onClose();
    }

    closeModal();
  };

  const onError = (errorMsg) => {
    setState(ModalState.Error);
    setError(errorMsg);
  };

  const confirmHandler = () => {
    setState(ModalState.Processing);
    onConfirm()
      .then(() => {
        closeModal();

        if (resetOnClose) {
          setState(ModalState.Confirm);
        }
      })
      .catch(onError);
  };

  const props = modalProps[state]({
    ...rest,
    closeModal: closeModalHandler,
    confirmHandler,
    error,
  });

  return <Modal name={name} size="small" onDismiss={closeModalHandler} {...props} />;
};

export { ProcessingModal };
