import { actionTypes } from '../actions';
import { PollingStoreHierarchyState, PoolingStoreHierarchyAction } from './interfaces';

const pollingStoreHierarchy = (state: PollingStoreHierarchyState = { pollingStatus: 'idle' },
  action: PoolingStoreHierarchyAction): PollingStoreHierarchyState => {
  switch (action.type) {
    case actionTypes.SET_POLLING_STOREHIERARCHY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default pollingStoreHierarchy;
