import { connect } from 'react-redux';
import { selectProfile } from 'framework/authorization';
import { workInProgress, getUser } from '../CommonPage/CommonPage.Selectors';
import { CommonPageDetails as CommonPageDetailsComponent } from './CommonPageDetails';
import { saveUserDataAction } from '../CommonPage/CommonPage.Actions';
import { CommonPageDetailProps, CommonPageDetailActions } from './CommonPageDetails.Interfaces';

export const mapStateToProps = (state): CommonPageDetailProps => ({
  user: getUser(state),
  currentUserProfile: selectProfile(state),
  workInProgress: workInProgress(state),
});

export const mapDispatchToProps = (
  dispatch,
  ownProps: CommonPageDetailProps,
): CommonPageDetailActions => ({
  saveUser: ({ formValues }) => dispatch(saveUserDataAction({ formValues, createFlow: !ownProps.editFlow })),
});

export const CommonPageDetails: React.StatelessComponent<
CommonPageDetailProps & CommonPageDetailActions
> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonPageDetailsComponent) as any;