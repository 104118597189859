/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Text, Modal } from 'ui';
import { useModal } from 'framework/components/ui/Modal/useModal';
import { prefixClassName } from 'framework/components/ui/_conf';
import { createConfirmAction } from './actions';
import { CONFIRMATION_MODAL_NAME } from './constants';
import { IConfirmationModal } from './ConfirmationModal.interfaces';
import { LoadingSpinner } from '../../../modules/contentmanagement/SharedContent/Helpers-Ui';

const modalClass = prefixClassName('modal--confirmation');

const mapDispatchToProps = (dispatch) => ({
  onConfirmDelete: () => {
    dispatch(createConfirmAction(true));
  },
  onCancelDelete: () => {
    dispatch(createConfirmAction(false));
  },
});

/**
 * Exporting this function for testing purposes only
 */
export const ConfirmationModalFn = (props: IConfirmationModal) => {
  const {
    className,
    title = 'generic.confirmation',
    message,
    warning,
    error,
    note,
    name,
    info,
    confirmButton = 'generic.button.confirm',
    cancelButton = 'generic.button.cancel',
    modalBodyComponent = null,
    onConfirm = () => null,
    onConfirmDelete = () => null,
    onCancel = () => null,
    onCancelDelete = () => null,
    hideConfirmationButton = false,
    hideCancelButton = false,
    propagateClick,
    manuallyCloseModal = false,
  } = props;
  const { closeModal } = useModal(name || CONFIRMATION_MODAL_NAME);
  const [showSpinner, setShowSpinner] = React.useState(false);

  const messages = [];
  warning && messages.push({ type: 'warning', caption: warning });
  error && messages.push({ type: 'error', caption: error });
  note && messages.push({ type: 'note', caption: note });
  info && messages.push({ type: 'info', caption: info });

  const handleOnConfirm = (e?: any) => {
    onConfirmDelete(e);
    onConfirm(e);
    if (!manuallyCloseModal) return closeModal();

    // only to be used when the modal closing control is handled by another component or saga
    setShowSpinner(true);
  };
  const handleOnCancel = (e?: any) => {
    onCancelDelete(e);
    onCancel(e);
    closeModal();
  };

  return (
    <Modal
      className={classnames(modalClass, className)}
      name={name || CONFIRMATION_MODAL_NAME}
      caption={title}
      messages={messages}
      onDismiss={handleOnCancel}
      size="small"
      footerOptions={[
        hideConfirmationButton || showSpinner
          ? null
          : {
              caption: confirmButton,
              onClick: handleOnConfirm,
              propagate: propagateClick,
              disabled: showSpinner,
            },
        hideCancelButton || showSpinner
          ? null
          : {
              caption: cancelButton,
              outline: true,
              onClick: handleOnCancel,
              propagate: propagateClick,
            },
      ]}
    >
      {message && <Text caption={message} />}
      {modalBodyComponent && modalBodyComponent()}
      {showSpinner && <LoadingSpinner visible />}
    </Modal>
  );
};

export const ConfirmationModal = connect(null, mapDispatchToProps)(ConfirmationModalFn);
