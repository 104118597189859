import * as React from 'react';
import classNames from 'classnames';
import { prefixClassName } from 'framework/components/ui/_conf';
import LinkPrimitive from 'framework/components/ui/Primitives/LinkPrimitive';
import { Icon, Text, Tooltip } from 'framework/components/ui';
import { className as clxButton } from 'framework/components/ui/FormComponents/Button';
import { i18n } from 'i18n';
import { ILinkProps, linkTypes } from './Link.interfaces';
import './_style.scss';

const clx = prefixClassName('link');

const componentClass = {
  link: clx,
  button: clxButton,
};

interface State {
  showCopyText: string;
  copied: boolean;
}

type PrimitiveProps = React.ComponentProps<typeof LinkPrimitive>;

export class Link extends React.Component<ILinkProps & PrimitiveProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      showCopyText: '',
      copied: false,
    };
  }

  componentDidUpdate() {
    const { showCopyText } = this.state;
    if (showCopyText) {
      setTimeout(() => {
        this.setState({
          showCopyText: '',
          copied: false,
        });
      }, 5000);
    }
  }

  render() {
    const {
      caption,
      children,
      className,
      captionAfterChildren,
      size,
      type = linkTypes.link,
      external,
      showCopyIcon = false,
      ...linkProps
    } = this.props;

    const content = captionAfterChildren ? (
      <>
        {children} <Text caption={caption} />
      </>
    ) : (
      <>
        <Text caption={caption} /> {children}
      </>
    );

    const handleCopy = () => {
      navigator.clipboard
        .writeText(caption)
        .then(() => {
          this.setState({
            showCopyText: i18n.t('generic.copyToClipboardSuccess'),
            copied: true,
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.setState({
              showCopyText: '',
              copied: false,
            });
          }, 2000);
        });
    };

    const { showCopyText, copied } = this.state;
    return (
      <>
        {showCopyText ? (
          <Tooltip position="up-right" active toolTipClassName={`${clx}--fitContent`}>
            {copied ? (
              <span className="display-flex align-center">
                <span className="mrg-right-5 "> {showCopyText}</span>
                <Icon size="sm" name="checkmark" color="#0245ff" />
              </span>
            ) : (
              <span
                onClick={handleCopy}
                onKeyDown={handleCopy}
                role="button"
                tabIndex={0}
                className="cursor-pointer display-flex align-center"
              >
                <span className="mrg-right-5 ">{showCopyText}</span>
                <Icon name="link" size="sm" onClick={handleCopy} title="copy link" color="#0245ff" />
              </span>
            )}
          </Tooltip>
        ) : (
          <></>
        )}
        <LinkPrimitive
          external={external}
          className={classNames(!className && componentClass[type], size && `${clx}--${size}`, className)}
          activeClassName={`${clx}--active`}
          handleMouseEnter={() => {
            if (showCopyIcon) {
              if (!showCopyText) {
                this.setState({
                  showCopyText: i18n.t('generic.copyLinkToClipboard'),
                });
              }
            }
          }}
          {...linkProps}
        >
          {content}
        </LinkPrimitive>
      </>
    );
  }
}

export default Link;
