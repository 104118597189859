import { call, put } from 'redux-saga/effects';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { toastListActions } from 'framework/actions';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';

import { actionTypes } from '../actions';
import { config } from 'config';

const {API_PROVIDER_GET_STORE} = actionTypes;
const baseUri = config.apiEndpoints.stores;

function* process({ payload }) {
  const response = yield call(makeRequestAndSaveState, {
    name: API_PROVIDER_GET_STORE,
    url: `${baseUri}/${payload.id}`,
  });

  if (!response.success) {
    yield put(toastListActions.addMessageError('modules.stores.storeSetup.errors.loadingError'));
  }
}

export default function* watchGetStoreAction() {
  yield takeEveryWithProgressBar(actionTypes.STORES_GET_STORE, process);
}
