import * as React from 'react';
import { validators } from 'framework/components/hoc/formField';
import {
  LayoutFlexBox,
  LayoutFlexBoxItem,
  Modal,
  Form,
  TextArea,
  DatePicker,
  TimePicker,
  Multiselect,
  TextField,
} from 'ui';
import moment from 'moment';
import * as CONST from '../../constants';
import { Props, Actions } from './ModalCreateBlocker.interfaces';

const validateTimeFunction = (values, allValues) => {
  const inputDate = moment(allValues[CONST.FIELD_BLOCKER_SLOT_DAY]);
  return validators.timeIsOnFuture(
    moment().tz(allValues.storeTimeZone),
    moment()
      .tz(allValues.storeTimeZone)
      .set('year', inputDate.year())
      .set('month', inputDate.month())
      .set('date', inputDate.date())
      .set('hours', values.hours)
      .set('minutes', values.minutes)
  );
};

const ModalCreateNewTemplate = (props: Props & Actions) => {
  const {
    // Form props
    initialValues = {},
    formIsEditMode = false,
    optionsShoppingMode = [],
    slotData = {},
    storeTimeZone,

    // Actions
    addBlocker,
    removeBlocker,
  } = props;

  const handleRemove = () => {
    removeBlocker(slotData.id);
  };

  return (
    <Modal
      name={CONST.MODAL_BLOCKER_SLOT}
      caption={
        formIsEditMode
          ? 'modules.slotManagement.storeCalendar.edit.modalBlockingSlot.titleEdit'
          : 'modules.slotManagement.storeCalendar.edit.modalBlockingSlot.title'
      }
      messages={[
        {
          type: 'warning',
          caption: 'modules.slotManagement.storeCalendar.edit.modalBlockingSlot.message_01',
        },
      ]}
      footerOptions={[
        {
          formToSubmit: CONST.FORM_BLOCKER_SLOT,
          caption: formIsEditMode
            ? 'modules.slotManagement.storeCalendar.edit.modalBlockingSlot.btnEdit'
            : 'modules.slotManagement.storeCalendar.edit.modalBlockingSlot.btnAdd',
        },
        {
          formToReset: CONST.FORM_BLOCKER_SLOT,
        },
        {
          caption: 'generic.options.delete',
          onClick: handleRemove,
          styleless: true,
          show: !!formIsEditMode,
        },
      ]}
    >
      <LayoutFlexBox flexWrap="wrap" className="mrg-btm-20">
        <Form name={CONST.FORM_BLOCKER_SLOT} onSubmit={addBlocker} initialValues={{ ...initialValues, storeTimeZone }}>
          <Multiselect
            name={CONST.FIELD_BLOCKER_SLOT_SHOPPINGMODE}
            options={optionsShoppingMode}
            className="mrg-btm-15"
            caption="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldShoppingModeLabel"
            placeholder="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldShoppingModePlaceholder"
            explainerText=" "
          />

          <DatePicker
            name={CONST.FIELD_BLOCKER_SLOT_DAY}
            validate={formIsEditMode ? [validators.mandatory] : [validators.mandatory, validators.dateIsOnFuture]}
            className="mrg-btm-15"
            caption="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldTimeDayLabel"
            explainerText=" "
            showTimepicker={false}
          />
          <TextField name="storeTimeZone" value={storeTimeZone} className="mwg-hidden" />
          <LayoutFlexBox className="mrg-btm-15">
            <LayoutFlexBoxItem flexGrow={0} flexBasis="25%" className="mrg-right-10">
              <TimePicker
                mandatory
                name={CONST.FIELD_BLOCKER_SLOT_TIME_INI}
                caption="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldTimeIniLabel"
                validate={[validateTimeFunction]}
              />
            </LayoutFlexBoxItem>
            <LayoutFlexBoxItem flexGrow={0} flexBasis="75%">
              <TimePicker
                mandatory
                name={CONST.FIELD_BLOCKER_SLOT_TIME_END}
                caption="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldTimeEndLabel"
                explainerText=" "
                explainerWidth={68}
              />
            </LayoutFlexBoxItem>
          </LayoutFlexBox>

          <TextArea
            name={CONST.FIELD_BLOCKER_SLOT_NOTE}
            mandatory
            caption="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldNoteLabel"
            placeholder="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldNotePlaceholder"
            explainerText="modules.slotManagement.storeCalendar.edit.modalBlockingSlot.fieldNoteExplainer"
          />
        </Form>
      </LayoutFlexBox>
    </Modal>
  );
};

export default ModalCreateNewTemplate;
