import * as React from 'react';
import * as classNames from 'classnames';
import { prefixClassName } from '../../_conf';
import { InputSize } from './GroupedField.interface';
import TextComponent from '../../Text';
import './style.scss';

const groupedClassName = prefixClassName('grouped-field');
const explainerClass = prefixClassName('form-field-wrapper-grid__explainer');

const mapSizes = {
  large: '50%',
  small: '25%',
  tiny: '15%',
  auto: 'auto',
};

const GroupedField = ({
  children,
  columnSizes,
  className,
  explainer,
}: {
  children: React.ReactNode;
  columnSizes: InputSize[];
  className?: string;
  explainer?: string;
}) => {
  // if explainer is set, adds an 'auto' at the end
  const columns = explainer ? [...columnSizes, mapSizes.auto] : columnSizes;

  return (
    <div
      className={classNames(groupedClassName, className)}
      style={{
        gridTemplateColumns: columns.map((s) => mapSizes[s]).join(' '),
        maxWidth: `calc(1024px - ${columns.length - 1}rem)`, // 1024px is the $form-max-width (sass variable)
      }}
    >
      {children}
      {explainer && <TextComponent tag="span" caption={explainer} className={explainerClass} />}
    </div>
  );
};

export { GroupedField };
