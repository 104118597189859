/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { getUser, workInProgress } from '../CommonPage/CommonPage.Selectors';
import { saveUserDataAction, saveUserStatusAction } from '../CommonPage/CommonPage.Actions';
import { CommonPageStoresProps, CommonPageStoresActions } from './CommonPageStores.Interfaces';
import { CommonPageStores as CommonPageStoresComponent } from './CommonPageStores';

const mapStateToProps = (state): CommonPageStoresProps => ({
  user: getUser(state),
  workInProgress: workInProgress(state),
});

const mapDispatchToProps = (
  dispatch,
  ownProps: CommonPageStoresProps,
): CommonPageStoresActions => ({
  saveStores: ({ storeIds }) => dispatch(saveUserDataAction({ storeIds, createFlow: !ownProps.editFlow })),
  saveUserStatus: ({ status }) => dispatch(saveUserStatusAction({ status })),
});

export const CommonPageStores: React.StatelessComponent<
CommonPageStoresProps & CommonPageStoresActions
> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonPageStoresComponent) as any;
