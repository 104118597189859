import * as React from 'react';
import withTranslation from 'framework/components/hoc/withTranslation';
import type { WithTranslationWrapped } from 'framework/components/hoc/withTranslation/withTranslation';

const DocumentTitle = withTranslation(({ caption }: WithTranslationWrapped) => {
  document.title = caption;
  return <></>;
});

export const PageDocumentTitle = React.memo(DocumentTitle);
