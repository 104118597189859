import * as React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { prefixClassName } from 'framework/components/ui/_conf';
import { i18n } from 'i18n';
import { RichEditorProps } from './Types';
import {
  defaultPlugins,
  defaultToolbarButtons,
  defaultCustomMenus,
  COLOR_MENU,
  FORE_COLOR_MENU_ITEM,
  BACK_COLOR_MENU_ITEM,
} from './Defaults';
import './RichEditor.scss';

const cssClass = prefixClassName('rich-editor');
const editorInvalidElements =
  'table,img,h1,h2,h3,h4,h5,h6,br,div,style,html,head,body,canvas,audio,video,iframe,object,form';
const scriptElement = 'script[language|type]';

export const RichEditor = (props: RichEditorProps) => {
  const {
    value,
    onChange,
    excludeOnlyPlugins,
    includeOnlyPlugins,
    includeOnlyButtons,
    excludeOnlyButtons,
    onOpenDam,
    disabled,
    advancedSettings,
    restrictInvalidElements,
    disableImagePasting,
    valid_children,
    disableRootBlock,
    customSetup,
    allowScript,
    allowColorPicker,
  } = props;
  const buildPlugins = () => {
    let plugins = includeOnlyPlugins || defaultPlugins;
    if (excludeOnlyPlugins) {
      plugins = plugins.filter((p) => !excludeOnlyPlugins.find((s) => s === p));
    }
    return plugins.join(' ');
  };

  const buildToolbarButtons = () => {
    let toolbar = '';
    Object.keys(defaultToolbarButtons).forEach((key) => {
      if (!allowColorPicker && key === COLOR_MENU) return;

      const menus = defaultToolbarButtons[key].filter((m) => {
        /*
          select buttom:
            1. if "includeOnlyButtons" provided then should be included in "includeOnlyButtons" list
            2. if "excludeOnlyButtons" provided then should be excluded if it is found in "excludeOnlyButtons" list
        */
        const include =
          (!includeOnlyButtons || !!includeOnlyButtons.find((b) => b === m)) &&
          !excludeOnlyButtons?.find((b) => b === m);
        return include;
      });
      toolbar = `${toolbar} ${menus.join(' ')} |`;
    });
    return `${toolbar}`;
  };

  const customiseMenuBar = () => {
    const { includeOnlyMenuItems, excludeOnlyMenuItems } = props;

    const menuBar = Object.keys(defaultCustomMenus).reduce((acc, key) => {
      const { items } = defaultCustomMenus[key];

      const stringified = items.reduce((ac, list) => {
        const menus = list.filter((m) => {
          const includeMenu = !includeOnlyMenuItems || includeOnlyMenuItems.some((s) => s === m);
          const excludeMenu = !!excludeOnlyMenuItems && excludeOnlyMenuItems.some((s) => s === m);
          if (!allowColorPicker) {
            return includeMenu && !excludeMenu && m !== FORE_COLOR_MENU_ITEM && m !== BACK_COLOR_MENU_ITEM;
          }
          return includeMenu && !excludeMenu;
        });

        return `${ac} ${menus.join(' ')} |`;
      }, '');

      return {
        ...acc,
        [key]: {
          title: defaultCustomMenus[key].title(),
          items: stringified,
        },
      };
    }, {});
    return menuBar;
  };

  const handleChange = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };

  const handleAction = (editor: any): void => {
    if (onOpenDam) {
      onOpenDam(editor);
    }
  };

  const { hideMenuBar, pasteAsText, height } = props;
  const hideMenu = hideMenuBar ? { menubar: false } : {};
  const pasteAsTextOption = pasteAsText ? { paste_as_text: true } : {};
  const disableRootBlockOption = disableRootBlock ? { forced_root_block: '' } : {};

  const advanceSettingsOptions = advancedSettings
    ? {
        forced_root_block: '',
        cleanup: false,
        verify_html: false,
        paste_auto_cleanup_on_paste: false,
        apply_source_formatting: false,
        // Use protect to restrict solve issues https://handlebarsjs.com/builtin_helpers.html
        protect: [
          /{{#if.+?}}/g, // protect {{if*}}
          /{{\/if}}/g, // protect closing {{if}}
          /{{else}}/g, // protect {{else}}
          /{{#each.+?}}/g, // protect {{each*}}
          /{{\/each}}/g, // protect closing {{each}}
          /{{#unless.+?}}/g, // protect {{unless*}}
          /{{\/unless}}/g, // protect closing {{unless}}
          /{{#with.+?}}/g, // protect {{with*}}
          /{{\/with}}/g, // protect closing {{with}}
          /{{this}}/g, // protect {{this}},
          /{{#ifcond.+?}}/g, // protect {{ifcond*}}
          /{{\/ifcond}}/g, // protect closing {{ifcond}}
        ],
      }
    : {};

  const invalidElements = restrictInvalidElements ? { invalid_elements: editorInvalidElements } : {};
  const extendedValidElements = allowScript ? scriptElement : undefined;

  const handlePaste = (e) => {
    const { items } = e.clipboardData;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        e.preventDefault();
        return;
      }
    }
  };

  const handleDeleteCustomScriptTag = (e) => {
    e.content = e.content.replace(/{{scriptStart}}.*?{{scriptEnd}}/gi, '');
  };

  return (
    <div className={cssClass}>
      <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/jscripts/tinymce/tinymce.min.js`}
        init={{
          plugins: buildPlugins(),
          toolbar: buildToolbarButtons(),
          document_base_url: `${window.location.protocol}//${window.location.host}/`,
          convert_urls: false,
          height,
          ...hideMenu,
          menu: customiseMenuBar(),
          quickbars_insert_toolbar: false,
          ...pasteAsTextOption,
          file_picker_types: 'image',
          ...advanceSettingsOptions,
          ...invalidElements,
          ...disableRootBlockOption,
          valid_children,
          extended_valid_elements: extendedValidElements,
          setup: (editor) => {
            if (disableImagePasting) {
              editor.on('paste', handlePaste);
            }
            if (!allowScript) {
              editor.on('BeforeSetContent', handleDeleteCustomScriptTag);
            }
            editor.ui.registry.addButton('dam', {
              icon: 'image',
              onAction: () => handleAction(editor),
            });
            editor.ui.registry.addMenuItem('dam', {
              icon: 'image',
              text: `${i18n.t('components.richeditor.image')}`,
              onAction: () => handleAction(editor),
            });
            if (customSetup) {
              customSetup(editor);
            }
          },
        }}
        value={value}
        disabled={disabled}
        onEditorChange={handleChange}
      />
    </div>
  );
};
