import { ContentManagementReduxState } from '../contentmanagement.interfaces';
import { CmsLayoutType } from '../CmsComponents.interfaces';
import { internalGetLayoutInspector } from '../contentmanagement.selectors';
import { initializeLayout } from '../utils/utils.initializeLayout';

type Action = {
  type: 'ACTION_CMS_ADD_COLUMN_CONTENT',
  payload: {
    columnIndex: number;
    layoutType: CmsLayoutType;
  }
};
export const addColumnContent = (
  state: ContentManagementReduxState, { payload: { columnIndex, layoutType } }: Action,
): ContentManagementReduxState => ({
  ...state,
  rowEditorData: {
    ...state.rowEditorData,
    columns: state.rowEditorData.columns.map((col, i) => {
      if (i !== columnIndex) {
        return col;
      }

      const insp = internalGetLayoutInspector(
        state.templates,
        'Template',
        layoutType,
      );

      const layout = initializeLayout(insp);

      return {
        ...col,
        ...layout,
      };
    }),
  },
});
