import * as React from 'react';
import { Button } from 'ui';
import { Uuid } from 'framework/utils/generateId';
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from 'redux-form';
import {
  openProductSearchModal,
  closeProductSearchModal,
} from 'framework/modules/searchadmin/components/ModalProductSearch/constants';
import { ModalProductSearch } from 'framework/modules/searchadmin/components/ModalProductSearch';
import { ProductScore, ProductPosition } from 'framework/modules/searchadmin/models/product';
import { store } from '@risingstack/react-easy-state';
// eslint-disable-next-line import/no-cycle
import ActionsPositionTemplate from './ActionsPosition.template';
import { MODAL_PRODUCTSEARCH_POSITION } from '../../../../../constants';
import { addDynamicFieldPosition } from './utils/addDynamicFieldPosition';

interface Props extends WrappedFieldArrayProps<ProductPosition> {
  fetchCurrentData: () => void;
}

export const positionsStore = store<{
  fields: FieldArrayFieldsProps<ProductPosition>;
  newPositions: string[];
}>();

export const ActionsPosition = (props: Props) => {
  const { fields, fetchCurrentData } = props;

  const [modalId] = React.useState<string>(`${MODAL_PRODUCTSEARCH_POSITION}${Uuid()}`);

  const onDelete = (index: number) => {
    fields.remove(index);
    positionsStore.newPositions = positionsStore.newPositions.filter((_v, i) => i === index);
    fetchCurrentData();
  };

  React.useEffect(() => {
    positionsStore.fields = fields;
  }, [fields]);

  return (
    <>
      <ModalProductSearch
        name={modalId}
        onSelectProducts={(products: ProductScore[]) => {
          addDynamicFieldPosition(products, fields, fetchCurrentData);
        }}
        selected={[]}
        closeModal={() => closeProductSearchModal(modalId)}
      />
      <div className="mrg-top-10">
        {fields.map((field, index) => (
          <ActionsPositionTemplate
            key={field}
            field={field}
            productPosition={fields.get(index)}
            onDelete={() => onDelete(index)}
            fetchCurrentData={fetchCurrentData}
          />
        ))}
        <Button
          onClick={() => openProductSearchModal(modalId)}
          caption="modules.merchandising.rules.create.btnAddProduct"
          outline
        />
      </div>
    </>
  );
};
