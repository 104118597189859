/**
 * This folder contains shared "advanced" components made available accross the application
 */

import { DamModal } from 'framework/modules/contentmanagement/DamModal/DamModal';
import { FileManager } from 'framework/modules/contentmanagement/FileManager/FileManager';
import { useFileManager } from 'framework/modules/contentmanagement/FileManager/useFileManager';
import * as DamModalTypes from 'framework/modules/contentmanagement/DamModal/Types';
import { useDamModal } from 'framework/modules/contentmanagement/DamModal/useDamModal';
import { useDamModalRichEditor } from 'framework/modules/contentmanagement/DamModal/useDamModalRichEditor';
import { StoresFilter } from 'framework/modules/stores/components/StoresFilter/StoresFilter';
import { StoresField } from 'framework/modules/stores/components/StoresField/StoresField';
import { StoresFilterSearchable } from 'framework/modules/stores/components/StoresFilter/StoresFilterSearchable';
import { OrgHierarchy } from 'framework/modules/stores/models/orghierarchy';
import { ListPageMetadata } from 'framework/components/ui/Table/Table.interfaces';
import type { Order } from 'framework/modules/orderManagement/models/order';

// workaround to avoid circular dependencies, as this place imports and exports several modules interchangeably
let ordersListMod: Promise<{ getOrdersListMetadata: (withCustomer: boolean) => ListPageMetadata<Order> }>;
const getOrdersListMetadata = async (withCostumerInfo): Promise<ListPageMetadata<Order>> => {
  if (!ordersListMod) {
    ordersListMod = import('framework/modules/orderManagement/pages/OrdersList/components/OrdersGrid/OrdersGrid');
  }

  const mod = await ordersListMod;
  return mod.getOrdersListMetadata(withCostumerInfo);
};

export {
  DamModal,
  DamModalTypes,
  useDamModal,
  useDamModalRichEditor,
  StoresFilter,
  OrgHierarchy,
  StoresField,
  StoresFilterSearchable,
  FileManager,
  useFileManager,
  getOrdersListMetadata,
};
