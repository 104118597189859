/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import i18n from 'i18n';
import * as React from 'react';
import { Text } from 'ui';
import classnames from 'classnames';
import LinkPrimitive from 'framework/components/ui/Primitives/LinkPrimitive';
import { IMenuOption } from '../Menu.interfaces';
import './_style.scss';

export const MenuOption = (props: IMenuOption) => {
  const {
    onClick,
    linkTo,
    className,
    label,
  } = props;

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  const onKeyPress = (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      onClick();
    }
  };

  const infoText = (typeof label === 'string' && i18n.t(label));

  return (
    <li
      title={infoText}
      aria-label={infoText}
      className={classnames(className, `${className}--disabled`)}
    >
      {linkTo ? (
        <LinkPrimitive to={linkTo} className={`${className}__link`}>
          <Text caption={label} />
        </LinkPrimitive>
      ) : (
        <div
          className={`${className}__link`}
          role="button"
          aria-label={label}
          tabIndex={0}
          onKeyDown={onKeyPress}
          onClick={onClickHandler}
        >
          <Text caption={label} />
        </div>
      )}
    </li>
  );
};

export default MenuOption;
