import * as utils from '../utils';
import { internalGetLayoutInspector } from '../contentmanagement.selectors';

// Add new component
// This can be either an explicite Row, a component within a Row
// a direct component (implicite Row)
export const addLayoutToPage = (state, action) => ({
  ...state,
  loadedPage: {
    ...state.loadedPage,
    cmsContent: {
      ...state.loadedPage.cmsContent,
      rows: utils.addSelectedLayoutToPage(
        internalGetLayoutInspector(state.templates, 'Template', action.payload.componentName),
        state.loadedPage.cmsContent,
        state.insertIndex,
      ),
    },
  },
});
