import * as React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { prefixClassName } from 'framework/components/ui/_conf';
// eslint-disable-next-line import/no-cycle
import { tooltipInterfaces } from '.';
import './_style.scss';

const className = prefixClassName('tooltip');

const CONTENT_WRAPPER_ID = 'contentWrapper';
export const TOOLTIP_PORTAL_ID = 'toolTipPortal';

const Tooltip = (props:tooltipInterfaces.ITooltipProps) => {
  const {
    active = false,
    toolTipClassName,
    children,
    dismissText,
    position,
    portal = false,
    parentId,
  } = props;

  const [isOpen, setIsOpen] = React.useState(active);

  React.useEffect(() => {
    setIsOpen(active);
  }, [active]);

  const dismissTooltip = () => {
    setIsOpen(false);
  };

  const tooltipContent = (
    <div
      className={classNames(
        toolTipClassName,
        className,
        position && `${className}--${position}`,
        portal && `${className}--portal`,
        (!isOpen && portal) && `${className}--hidden`)}
      id={CONTENT_WRAPPER_ID}
    >
      {children}
      {dismissText && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span className={classNames(`${className}__dismiss-tooltip`)} onClick={dismissTooltip}>
          {` ${dismissText}`}
        </span>
      )}
    </div>);

  if (portal && parentId) {
    const renderPortal = () => {
      const rect = document?.getElementById(parentId)?.getBoundingClientRect();
      const tooltipElement = document?.getElementById(TOOLTIP_PORTAL_ID);
      
      const w = document?.getElementById(CONTENT_WRAPPER_ID);
  
      if (tooltipElement && rect && w) {
        tooltipElement.style.position = 'absolute';
        tooltipElement.style.left = `${rect?.x - w?.getBoundingClientRect()?.width}px`;
        tooltipElement.style.top = `${rect?.y + window?.scrollY - (w?.getBoundingClientRect()?.height + 10)}px`;
      }
    };

    document.addEventListener('click', renderPortal);

    return active ? ReactDOM.createPortal(tooltipContent, document.getElementById(TOOLTIP_PORTAL_ID)) : null;
  }

  return isOpen ? tooltipContent : null;
};

export default Tooltip;
