import * as React from 'react';
import { Props, MapperProps } from './ApiMakeRequest.interfaces';

const ApiMakeRequest = (props: Props & MapperProps) => {
  const {
    body,
    endpoint,
    endpointPath,
    headers,
    method = 'GET',
    name,
    query,
    requestImmediately = true,
    // Actions
    makeRequestAction,
    deleteApiData
  } = props;

  React.useEffect(() => {
    if (requestImmediately) {
      makeRequest();
    }

    // Remove data on unmount
    return deleteApiData;
  }, []);

  const makeRequest = () => {
    const requestDetails = {
      body,
      endpoint,
      endpointPath,
      headers,
      method,
      name,
      query
    };

    makeRequestAction(requestDetails);
  };

  return null;
};

export { ApiMakeRequest };
export default ApiMakeRequest;
