import { call, put, delay } from 'redux-saga/effects';
import { getApiClientData } from 'framework/api/apiClient/service';
import { apiClientActions, toastListActions } from 'framework/actions';
import { config } from 'config';
import { history } from 'framework/store';
import { takeEveryWithProgressBar } from 'framework/sagas/extensions/progress';
import * as CONST from 'framework/modules/slotManagement/constants';
// eslint-disable-next-line import/no-cycle
import { actions, ACTIONS } from '..';

function* process({ payload }) {
  const { templateId } = payload;

  // 1. Show Loaders
  yield put(
    apiClientActions.createApiClientUpdateStateAction({
      name: CONST.API_DATA_SLOTS_TEMPLATES_EDIT,
      isFetching: true,
    }),
  );

  yield delay(1500);

  // 2. Make Request
  const requestDetails = {
    name: 'getDataRule',
    url: `${config.apiEndpoints.slotTemplatesv2}/templates/${templateId}`,
    method: 'GET',
  };
  const responseData = yield call(getApiClientData, requestDetails, true);

  if (responseData.success) {
    // 3.1. fetch data into redux -> API
    yield put(
      apiClientActions.createApiClientUpdateStateAction({
        data: {
          templateId,
          ...responseData.data,
        },
        name: CONST.API_DATA_SLOTS_TEMPLATES_EDIT,
        hasError: !!responseData.hasError,
        isFetching: false,
        hasResponse: !!responseData,
      }),
    );

    // 3.2. fetch data into redux -> Calendar
    yield put(actions.fetchCalendarIniData());
  } else {
    // Redirect to tempaltesList page when Template ID doesn't exist
    history.push(CONST.PATH_TEMPLATES_FULLPATH);
    yield put(toastListActions.addMessageError(`Template not found - ${templateId}`));
  }
}

export default function* watchFetchSlotTemplatesAction() {
  yield takeEveryWithProgressBar(ACTIONS.TEMPLATE_FETCH_DATA, process);
}
