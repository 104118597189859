import { Uuid } from 'framework/utils/generateId';
import { RuleHideCategory, CategoryHierarchyNode } from './types';

const findCategory = (category: CategoryHierarchyNode, id, allIds: string[]): CategoryHierarchyNode => {
  if (category.id === id) {
    const allChildrenSelected: boolean = category.children.length === 0
    && category.children.every((child) => allIds.find((childId) => childId === child.id));

    return {
      ...category,
      allChildrenSelected,
    };
  }

  if (category.children) {
    const categories: CategoryHierarchyNode[] = category.children
      .map((s) => findCategory(s, id, allIds)).filter((s) => !!s);

    const allChildrenSelected: boolean = categories[0]?.children?.length > 0
      && categories[0]?.children?.every((child) => allIds.find((childId) => childId === child.id));

    if (categories.length) {
      return {
        ...categories[0],
        allChildrenSelected,
      };
    }
  }

  return null;
};

export const getCategoriesByIds = (ids: string[], allCategories: CategoryHierarchyNode[]): CategoryHierarchyNode[] => {
  const root = {
    id: '__fakeroot__',
    caption: '__fakeroot__',
    parent: null,
    children: allCategories,
  } as CategoryHierarchyNode;

  const foundCategories: CategoryHierarchyNode[] = ids.map((id) => findCategory(root, id, ids));

  return foundCategories.filter((category) => !!category && !foundCategories.find((c) => c.id === category.parent?.id));
};

export const transformToCategoryHierarchyNode = (
  categories: RuleHideCategory[],
  breadCrumb?: string,
  parent?: CategoryHierarchyNode,
): CategoryHierarchyNode[] => {
  let categoryHierarchyNodes: CategoryHierarchyNode[] = [];

  // transform categories into CategoryHierarchyNode, create breadcrumb for each
  categories.forEach((category: RuleHideCategory) => {
    const id: string = Uuid();
    const breadCrumbContinued: string = `${breadCrumb ? `${breadCrumb}/` : ''}${category.categoryName}`;
    categoryHierarchyNodes = [
      ...categoryHierarchyNodes,
      {
        id,
        caption: category.categoryName,
        children: transformToCategoryHierarchyNode(category.subcategories, breadCrumbContinued, {
          id,
          caption: category.categoryName,
          children: [],
          parent: null,
        }),
        parent: parent ?? null,
        breadCrumb: parent ? breadCrumbContinued : category.categoryName,
      },
    ];
  });

  return categoryHierarchyNodes;
};

export const getSelectedNodeIds = (savedBreadcrumbs: string[], allNodes: CategoryHierarchyNode[]): string[] => {
  const selectedIds: string[] = [];

  // use already saved breadcrumbs to select nodes
  savedBreadcrumbs.forEach((breadCrumb: string) => {
    const node: CategoryHierarchyNode = allNodes.find((n: CategoryHierarchyNode) => n.breadCrumb === breadCrumb);

    if (node) {
      selectedIds.push(node.id);
    }
  });

  return selectedIds;
};

export const flatNodes = (nodesToFlatten: CategoryHierarchyNode[]): CategoryHierarchyNode[] => {
  let result: CategoryHierarchyNode[] = [];

  nodesToFlatten.forEach((a) => {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flatNodes(a.children));
    }
  });

  return result;
};
