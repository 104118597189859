import * as React from 'react';
import * as classnames from 'classnames';
import { Icon } from 'ui';
import { prefixClassName } from 'framework/components/ui/_conf';
import { toCamelCase, toKebabCase } from 'framework/utils/string';
import i18n from 'i18n';
import { IStatusIndicator } from './StatusIndicator.interfaces';
import './style.scss';

const clx = prefixClassName('status-indicator');

const defaultTranslation = 'generic.status.';

const StatusIndicator = ({
  caption,
  className,
  icon,
  i18nPrefix = defaultTranslation,
}: IStatusIndicator) => (
    <>
      {caption && (
        <span
          className={classnames(
            clx,
            icon && `${clx}--has-icon`,
            `${clx}--${toKebabCase(caption)}`,
            className,
          )}
        >
          {i18n.t(`${i18nPrefix}${toCamelCase(caption)}`)}
          {icon && <Icon name={icon} className={`${clx}__icon`} />}
        </span>
      )}
    </>
);

export { StatusIndicator };
