import * as React from 'react';

// Initialize a context
const Context = React.createContext({
  tabsName: '',
  registerAnchor: () => {},
  unRegisterAnchor: () => {}
});

export default Context;
