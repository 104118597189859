import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { AppRouteBase, AppRouteClaims } from 'framework/components/navigation/Navigation.interfaces';
import { UserAccessRights } from 'framework/authorization/Interfaces';

// ROUTES PATHS
export const PATH_MAIN = '/slotmanagement';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_TEMPLATES = '/templates';

// CALENDAR
export const PATH_LIVE_TIME_SLOT_CALENDAR = '/liveSlotCalendar';
export const PATH_TEMPLATES_FULLPATH = `${PATH_MAIN}${PATH_TEMPLATES}`;
export const PATH_TEMPLATES_EDIT = `${PATH_TEMPLATES}/edit`; // path needs /:templateId
export const PATH_TEMPLATES_EDIT_FULLPATH = `${PATH_MAIN}${PATH_TEMPLATES_EDIT}`; // path needs /:templateId
export const PATH_TEMPLATES_CREATE = `${PATH_TEMPLATES}/create`;
export const PATH_TEMPLATES_CREATE_FULLPATH = `${PATH_MAIN}${PATH_TEMPLATES_CREATE}`;
export const PATH_LIVE_TIME_SLOT_CALENDAR_FULLPATH = `${PATH_MAIN}${PATH_LIVE_TIME_SLOT_CALENDAR}`;

// ACTIONS SPECIFIC TO THIS MODULE
export const ACTION_SLOTMANAGEMENT_TEMPLATES_EDIT = 'ACTION_SLOTMANAGEMENT_TEMPLATES_EDIT';

// API DATA PROVIDERS
export const API_DATA_SLOTS_TEMPLATES_LIST = 'ApiDataProviderSlotsTemplatesList';
export const API_DATA_SLOTS_TEMPLATES_LIST_OPTIONS = 'ApiDataProviderSlotsTemplatesListOptions';
export const API_DATA_SLOTS_TEMPLATES_EDIT = 'ApiDataProviderSlotsTemplatesEdit';
export const API_DATA_SLOTS_TEMPLATES_EDIT_OPTIONS = 'ApiDataProviderSlotsTemplatesEditOptions';
export const API_DATA_SLOTS_TEMPLATES_EDIT_AREAS = 'ApiDataProviderSlotsTemplatesEditAreas';
export const API_DATA_SLOTS_CALENDAR_OPTIONS = 'ApiDataProviderSlotsCalendarOptions';
export const API_DATA_SLOTS_CALENDAR_EDIT = 'ApiDataProviderSlotsCalendarEdit';

// DATA GRIDS
export const DATA_GRID_SLOTS_TEMPLATES_LIST = 'DataGridSlotsTemplatesList';

// FORMS
export const FORM_SLOTS_TEMPLATES_LIST = 'FormSlotsTemplatesList';
export const FORM_SLOTS_TEMPLATES_NEW = 'FormSlotsTemplatesNew';
export const FORM_SLOTS_TEMPLATES_EDIT = 'FormSlotsTemplatesEdit';
export const FORM_SLOTMANAGEMENT_EDIT_SLOT = 'FormSlotsmanagementEditSlot';
export const FORM_SLOTMANAGEMENT_SCHEDULING = 'FormSlotsmanagementSchedule';
export const FORM_SLOTMANAGEMENT_EDIT_DETAILS = 'FormSlotsmanagementEditDetails';

// Create New
export const FORM_NEW_NAME = 'slotManagementTemplateNewName';
export const FORM_NEW_SHOPPINGMODE = 'slotManagementTemplateNewShoppingMode';

// CALENDAR
export const CALENDAR_SLOTS_TEMPLATES_EDIT = 'CalendarSlotsTemplatesEdit';

// MODALS
export const MODAL_TEMPLATE_CREATE = 'ModalSlotsTemplateCreate';
export const MODAL_TEMPLATE_EDIT_SLOT = 'ModalSlotsTemplateEditSlot';

// DEFAULT VALUES
export { DEFAULT_LOCALE_ID, DEFAULT_STORE_ID } from 'config';

const templateClaims: AppRouteClaims = [
  AuthorizationClaims.SLOTMANAGEMENT_SLOTS_TEMPLATES,
  { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
];

// routes
export const templatesRoute: AppRouteBase = {
  pageTitle: 'modules.slotManagement.templates.list.title',
  menuTitle: 'modules.slotManagement.templates.list.menuTitle',
  path: PATH_TEMPLATES_FULLPATH,
  authClaim: templateClaims,
};

export const templatesEditRoute: AppRouteBase = {
  pageTitle: 'modules.slotManagement.templates.edit.title',
  path: `${PATH_TEMPLATES_EDIT_FULLPATH}/:templateId`,
  authClaim: templateClaims,
  parent: templatesRoute,
};

export const liveTimeslotRoute: AppRouteBase = {
  pageTitle: 'modules.slotManagement.storeCalendar.edit.title',
  menuTitle: 'modules.slotManagement.storeCalendar.edit.menuTitle',
  path: PATH_LIVE_TIME_SLOT_CALENDAR_FULLPATH,
  authClaim: [
    AuthorizationClaims.SLOTMANAGEMENT_CALENDAR,
    { [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read },
  ],
};
