import { ModuleConfig } from 'framework/components/navigation/Navigation.interfaces';
import * as CONST from './constants';
import {
  rankingSettingsRoute, searchResultsPreviewRoute, synonymsListRoute,
  synonymsCreateRoute, rulesListRoute, rulesCreateRuleRoute, defaultSettingsRoute,
} from './routes';

const MerchandisingModule: ModuleConfig = {
  name: 'searchadmin',
  appMenuTitle: 'navigation.merchandising',
  appMenuIcon: 'search',
  modulePath: CONST.PATH_MAIN,
  pageGroups: [
    {
      title: 'modules.merchandising.rankingSettings.relevanceTitle',
      routes: [
        rankingSettingsRoute,
      ],
    },
    {
      title: 'modules.merchandising.searchResults.groupTitle',
      routes: [
        searchResultsPreviewRoute,
      ],
    },
    {
      title: 'modules.merchandising.synonyms.list.title',
      routes: [
        synonymsListRoute,
        synonymsCreateRoute,
      ],
    },
    {
      title: 'modules.merchandising.rules.list.title',
      routes: [
        rulesListRoute,
        rulesCreateRuleRoute,
      ],
    },
    {
      title: 'modules.merchandising.searchSettings.groupTitle',
      routes: [
        defaultSettingsRoute,
      ],
    },
  ],
};

export default MerchandisingModule;
