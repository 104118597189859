// import { addProgressBar } from 'framework/components/ui/ProgressBar/useProgressBar';
import config from 'config';
import { fetchData } from 'framework/api/fetch-data';
import { FileFolder, FileFolderType } from './types';

// fetch folders or fetch subfolders if includeFiles flag is true
export const fetchFolders = async (path?: string, includeFiles?: boolean) => fetchData<FileFolder[]>({
  url: `${config.apiEndpoints.cms}/dam${path ? `/${path}` : ''}`,
  query: {
    type: includeFiles ? undefined : 'directory',
  },
});

export const deleteFileFolder = async (fileFolderReference: string) => fetchData({
  url: `${config.apiEndpoints.cms}/dam/${fileFolderReference}`,
  method: 'DELETE',
});

export const formatChildren = (folders?: FileFolder[]) => (!folders ? [] : folders.map((f) => ({
  ...f,
  children: (f.type === FileFolderType.DIRECTORY ? f.children ?? [] : undefined),
})));

// after folders have been fetched, attach them as sub-children to currently highlighted element
export const attachChildren = (reference: string, folders: FileFolder[],
  attach: FileFolder[], parent?: FileFolder) => folders.map((f) => ({
  ...f,
  parent,
  children: (f.reference === reference ? [...attach] : [
    ...attachChildren(reference, f.children ?? [], attach, f),
  ]),
}));
