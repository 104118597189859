import * as React from 'react';
import classNames from 'classnames';
import { Header, LayoutFlexBox, LayoutFlexBoxItem } from 'ui';
import { resolveTitle } from 'framework/components/navigation/service';
import { prefixClassName } from 'framework/components/ui/_conf';
import { PageCrumbs } from './PageCrumbs';
import { UserBlock } from '../../UserBlock/UserBlock';
import { IPageTitle } from './PageHeader.interfaces';
import { PageDocumentTitle } from './PageDocumentTitle';
import './styles.scss';
import { Notifications } from '../../Notifications/Notifications';

const clx = prefixClassName('page-header');

export const PageHeader = (props: IPageTitle) => {
  const {
    appRoute,
    title,
    titleDetails,
    headerComponents: HeaderComponents,
    hideBreadCrumbs = false,
    className,
  } = props;
  const resolvedTitle = resolveTitle(title);
  const titleForHeader = titleDetails ? `${resolvedTitle}:` : resolvedTitle;

  return (
    <LayoutFlexBox className={classNames(clx, className)} flexWrap="wrap">
      <PageDocumentTitle caption={resolvedTitle} />
      {!hideBreadCrumbs && (
        <LayoutFlexBoxItem flexGrow={0} flexBasis="50%">
          <PageCrumbs appRoute={appRoute} />
        </LayoutFlexBoxItem>
      )}
      <LayoutFlexBoxItem flexGrow={0} flexBasis={hideBreadCrumbs ? '100%' : '50%'}>
        <LayoutFlexBox flexWrap="nowrap" justifyContent="flex-end">
          <Notifications />
          <UserBlock />
        </LayoutFlexBox>
      </LayoutFlexBoxItem>

      <LayoutFlexBoxItem flexGrow={1} flexBasis="100%">
        <LayoutFlexBox alignItems="center" className={`${clx}page-title-container`}>
          <LayoutFlexBoxItem className={`${clx}__titles ${!HeaderComponents && `${clx}__long-title`}`} flexBasis="auto">
            <Header caption={titleForHeader} headerLevel={1} className={`${clx}__title`} />
            {titleDetails && <Header caption={titleDetails} headerLevel={1} className={`${clx}__title-details`} />}
          </LayoutFlexBoxItem>
          <LayoutFlexBoxItem className={`${clx}__comp`} flexGrow={1}>
            {HeaderComponents && <HeaderComponents />}
          </LayoutFlexBoxItem>
        </LayoutFlexBox>
      </LayoutFlexBoxItem>
      {title && <div className={`${clx}__underline`} />}
    </LayoutFlexBox>
  );
};

export default PageHeader;
