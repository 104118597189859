/* eslint-disable import/no-cycle */
import React from 'react';
import {
  Form, Panel,
} from 'framework/components/ui';
import { StoresFilterSearchable } from 'framework/shared';
import { CommonPageStoresProps, CommonPageStoresActions } from './CommonPageStores.Interfaces';
import { usePageStores } from './usePageStores';
import { USER_STORES_FORM } from '../CommonPage/CommonPage.Constants';
import { CommonStatusDropdown } from '../CommonStatusDropdown';

const translation = 'modules.siteadmin';

export const CommonPageStores = (props: CommonPageStoresProps & CommonPageStoresActions) => {
  const {
    user, editFlow, onStoreChange, saveStores, workInProgress = false, saveUserStatus,
  } = props;
  const {
    selectedStores, setSelectedStores, isDirty, reset,
  } = usePageStores({ initialUserStores: user ? user.orgHierarchyNodeIds : [] });

  const handleSaveStore = () => {
    saveStores({ storeIds: selectedStores });
    onStoreChange(false);
  };

  const onReset = () => {
    reset();
    onStoreChange(false);
  };

  const onStoreSelect = (nodes: any) => {
    setSelectedStores(nodes.map((n) => n.id));
    if (nodes.length === selectedStores.length && nodes.every((node) => selectedStores.includes(node.id))) {
      onStoreChange(false);
    } else {
      onStoreChange(true);
    }
  };

  return (
    <Form
      name={USER_STORES_FORM}
      initialValues={user}
      stencilled={workInProgress}
    >
      <Panel title={`${translation}.assignStores`}>
        <StoresFilterSearchable
          selectedStores={selectedStores}
          onChange={onStoreSelect}
        />
        <Panel.Footer
          options={[
            {
              caption: editFlow
                ? `${translation}.saveRolesEdit`
                : `${translation}.saveAndContinueRoles`,

              disabled: !isDirty,
              onClick: handleSaveStore,
            },
            {
              caption: 'components.form.reset',
              disabled: !isDirty,
              outline: true,
              onClick: onReset,
            },
          ]}>
          <CommonStatusDropdown formName={USER_STORES_FORM} saveUserStatus={saveUserStatus}/>
         </Panel.Footer>
      </Panel>
    </Form>
  );
};
