import * as React from 'react';
import TabsContext from '../../TabsContext';
import TabsAnchoredContent from './TabsAnchoredContent';

export default props => (
  <TabsContext.Consumer>
    {({ tabsName, registerAnchor, unRegisterAnchor }) => (
      <TabsAnchoredContent
        {...props}
        registerAnchor={registerAnchor}
        unRegisterAnchor={unRegisterAnchor}
        tabsName={tabsName}
      />
    )}
  </TabsContext.Consumer>
);
