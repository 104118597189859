import React from 'react';
import withTranslation from 'framework/components/hoc/withTranslation';
import { WithTranslationWrapped } from 'framework/components/hoc/withTranslation/withTranslation';

type ShowingNoOfNoProps = WithTranslationWrapped & {
  className: string;
};
const ShowingNoOfNoFn = ({ className, caption }: ShowingNoOfNoProps) => (
  <span className={className}>
    {caption}
  </span>
);
export const ShowingNoOfNo = withTranslation(ShowingNoOfNoFn);
