import * as React from 'react';
import { i18n } from 'i18n';
import { SelectPrimitive } from 'framework/components/ui/FormComponents/Primitives';
import { store } from '@risingstack/react-easy-state';
import { calToolbarClx } from '../constants';
import { ICalendarViews } from '../../../Calendar.interfaces';

interface ViewSelectorProps {
  setListView: (viewList: boolean) => void;
  options: ICalendarViews[];
  view: ICalendarViews;
  onChange: (e) => void;
  updateSelectorView: (e) => void;
}

export const viewStore = store<{ view: ICalendarViews }>({ view: 'week' });

export const ViewSelector = (props: ViewSelectorProps) => {
  const { options, setListView, view, onChange, updateSelectorView } = props;

  const [localView, setLocalView] = React.useState<ICalendarViews>(viewStore.view);

  const updateView = (e) => {
    const listView: boolean = e?.value === 'list';

    setLocalView(e.value);
    viewStore.view = e.value;

    setListView(listView);
    updateSelectorView(!listView ? e.value : 'week');
    onChange(e);
  };

  const optionsLabels = {
    day: i18n.t('components.calendar.views.day'),
    week: i18n.t('components.calendar.views.week'),
    month: i18n.t('components.calendar.views.month'),
    agenda: i18n.t('components.calendar.views.agenda'),
    list: i18n.t('components.calendar.views.list'),
  };

  const viewOptions = options
    .filter((op) => !!optionsLabels[op])
    .map((op) => ({
      label: optionsLabels[op],
      value: op,
    }));

  React.useEffect(() => {
    if (localView !== 'list') setLocalView(view);
  }, [view]);

  return viewOptions.length ? (
    <SelectPrimitive
      name="calendarViewSelector"
      className={`${calToolbarClx}__viewSelector`}
      clearable={false}
      value={localView}
      searchable={false}
      onChange={updateView}
      options={viewOptions}
    />
  ) : null;
};
