import { ensureFeatures, featuresState } from 'stores/features';
import { AppRoute } from 'framework/components/navigation/Navigation.interfaces';
import { featureFlag } from 'featureFlag';
import { homeRoutes } from './home';
import { cmsRoutes } from './contentmanagement/routes';
import { slotManagementeRoutes } from './slotManagement/routes';
import { storesRoutes } from './stores/routes';
import { productsRoutes } from './products/routes';
import { searchRoutes } from './searchadmin/routes';
import { merchandisingRoutes } from './merchandising/routes';
import { promotionsRoutes } from './promotions/routes';
import { orderManagementRoutes } from './orderManagement/routes';
import { crmRoutes } from './crm/routes';
import { orderFulfillmentRoutes } from './orderFulfillment/routes';
import { siteadminRoutes } from './siteadmin/routes';
import { reportRoutes } from './reports/routes';
import { siteSettingsRoutes } from './siteSettings/routes';

export const useAppRoutes = () => {
  const allRoutes: AppRoute[] = [
    ...homeRoutes,
    ...cmsRoutes,
    ...slotManagementeRoutes,
    ...storesRoutes,
    ...productsRoutes,
    ...searchRoutes,
    ...merchandisingRoutes,
    ...promotionsRoutes,
    ...orderManagementRoutes,
    ...crmRoutes,
    ...orderFulfillmentRoutes,
    ...siteadminRoutes,
    ...reportRoutes,
    ...siteSettingsRoutes,
  ];

  const localRoutes: AppRoute[] = allRoutes.filter((appRoute) => {
    const { requiredFeatures, alwaysShowInDev } = appRoute;

    // If route has no required features, then show it
    if (!requiredFeatures) return true;

    return featureFlag({
      dev: () => alwaysShowInDev ?? ensureFeatures(requiredFeatures),
      prod: () => ensureFeatures(requiredFeatures),
    });
  });

  return { localRoutes, isLoading: featuresState.isLoading };
};
