import { API_PROVIDER_GET_ORDERS } from '../../constants';

export default {
  SEARCH_ORDERS_FIELD: 'orderFulfillmentSearchOrder',
  SEARCH_ORDERS_FORM: 'orderFulfillmentSearchOrderForm',
  MODAL_CHANGE_ORDER_STATUS: 'orderFulfillmentModalCancelOrder',
  MODAL_CHANGE_PICKBY_DATE: 'orderFulfillmentModalChangePickByTime',
  PICKBY_DATE_FORM: 'orderFulfillmentFormPickByDate',
  PICKBY_DATE_DATE_PICKER: 'orderFulfillmentDatePickerPickByDate',
  PICKBY_DATE_TIME_PICKER: 'orderFulfillmentTimePickerPickByDate',
  MODAL_SINGLE_PICK_ORDER: 'MODAL_SINGLE_PICK_ORDER',
  MODAL_MULTI_PICK_ORDER: 'MODAL_MULTI_PICK_ORDER',
  FULFILLMENT_CENTERS_LIST_API_DATA: 'FULFILLMENT_CENTERS_LIST_API_DATA',
  FULFILLMENT_CENTERS_LIST_FILTER: 'FULFILLMENT_CENTERS_LIST_FILTER',
  ORDERS_DASH_FILTERS: 'ORDERS_DASH_FILTERS',
  MODAL_DOWNLOAD_LABELS: 'MODAL_DOWNLOAD_LABELS',
  MODAL_DOWNLOAD_OVERSIZED_LABELS: 'MODAL_DOWNLOAD_OVERSIZED_LABELS',
  POLLING_INTERVAL_SHORT: 5000,
  POLLING_INTERVAL_LONG: 120,
  PAGESIZE_FIELD: 'PAGESIZE_FIELD',
  PAGESIZE_FORM: 'PAGESIZE_FORM',
  ORDERS_DASH_PAGESIZE: 'ORDERS_DASH_PAGESIZE',
  ORDERS_COLUMNS: 'ORDERS_COLUMNS',
  ORDERS_BULK_ACTIONS: 'ORDERS_BULK_ACTIONS',
  BULK_FORCE_STATUS_MODAL: 'BULK_FORCE_STATUS_MODAL',
  BULK_FORCE_STATUS_FORM: 'BULK_FORCE_STATUS_FORM',
};
export const FULFILLMENT_CENTERS_LIST_API_DATA = 'FULFILLMENT_CENTERS_LIST_API_DATA';
export const ORDERS_DASH_REDUX_PATH = [`${API_PROVIDER_GET_ORDERS}`, 'data', 'orders'];
export const LOADING = 'update-processing';
export const STATUSES = {
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  ORDERED: 'ordered',
  MANUAL_PICKING: 'manualPicking',
  PAPER_PICKING: 'paperPicking ',
  MPS_PICKING: 'picking',
  READY_FOR_PICKING: 'readyForPicking',
  COMPLETE: 'complete',
  ABANDONED: 'abandoned',
  OUT_OF_STOCK: 'outOfStock',
};
export const contentByStatus = {
  CancelOrder: {
    endpointPath: 'cancel',
    title: 'modules.orderFulfillment.ordersList.modalCancelOrder.title',
    status: 'cancelled',
    messages: [
      {
        type: 'warning',
        caption: 'modules.orderManagement.cancelOrderMessage'
      }
    ],
    buttonCaption: {
      confirm: 'modules.orderManagement.cancelOrder',
      cancel: 'modules.orderManagement.doNotCancelOrder'
    }
  },
  ResumeCancelledOrder: {
    endpointPath: 'reactivate',
    title: 'modules.orderFulfillment.ordersList.modalResumeCancelledOrder.title',
    status: 'active',
  },
  StartManualPicking: {
    endpointPath: 'start-manual-picking',
    title: 'modules.orderFulfillment.ordersList.modalStartManualPicking.title',
    status: 'manualPicking',
    messages: [
      {
        type: 'warning',
        caption: 'modules.orderFulfillment.ordersList.modalStartManualPicking.message',
      },
    ],
  },
  StartMPSPicking: {
    endpointPath: 'move-to-mps-picking',
    title: 'modules.orderFulfillment.ordersList.modalStartMPSPicking.title',
    status: 'picking',
  },
  CompleteManualPicking: {
    endpointPath: 'complete-manual-picking',
    title: 'modules.orderFulfillment.ordersList.modalCompleteManualPicking.title',
    status: 'completed',
    messages: [
      {
        type: 'warning',
        caption: 'modules.orderFulfillment.ordersList.modalCompleteManualPicking.message',
      },
    ],
  },
  ForceReadyForPickingStatus: {
    endpointPath: 'move-to-ready-for-picking',
    title: 'modules.orderFulfillment.ordersList.modalForceReadyForPickingStatus.title',
    messages: [
      {
        type: 'warning',
        caption: 'modules.orderFulfillment.ordersList.modalForceReadyForPickingStatus.message',
      },
    ],
    buttonCaption: {
      confirm: 'modules.orderFulfillment.orderDetails.orderActions.moveToReadyForPicking',
      cancel: 'generic.options.cancel'
    },
    status: 'readyForPicking',
  },
  ForceOrderedStatus: {
    endpointPath: 'move-to-ordered',
    title: 'modules.orderFulfillment.ordersList.modalForceOrderedStatus.title',
    messages: [
      {
        type: 'warning',
        caption: 'modules.orderFulfillment.ordersList.modalForceOrderedStatus.message',
      },
    ],
    status: 'ordered',
  },
  ForceBulkCanceledStatus: {
    status: 'cancelBulk',
    buttonCaption: {
      confirm: 'generic.options.confirm',
      cancel: 'generic.options.cancel'
    }
  },
};
export const PickingTypes = {
  SinglePick: 'singlePick',
  MultiPick: 'multiPick',
};
export const FCID_PATH_PARAM = ':fcId';

export const ORDER_FULFILLMENT_ORDER_RISK_FILTER_OPTIONS = 'ORDER_FULFILLMENT_ORDER_RISK_FILTER_OPTIONS';

export const MAIN_TEXT_COLOR = '#0245ff';

export const RESPONSIVE_CLASSNAME = 'responsive-table';

export const TABS_ID = 'DASHBOARD_TABS';
export const ORDERS_TAB = 'orders';
export const WAVES_TAB = 'waves';
