import * as React from 'react';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { PageProps } from 'framework/components/ui/Page/types';
import { CommonPageProps, CommonPageActions } from './CommonPage.Interfaces';
import { storeUserAction } from './CommonPage.Actions';

export type Props = PageProps<any> & CommonPageProps & CommonPageActions;

const useSiteAdmin: TypedUseSelectorHook<{siteadmin: { user: {
  orgHierarchyNodeIds: string[]
} }}> = useSelector;

export const useCommonPage = (props: Props) => {
  const {
    userid, loadUser, clearUser, storePageFlow, resetTabs,
  } = props;

  const [selectionFixed, setSelectionFixed] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const user = useSiteAdmin((state) => state.siteadmin.user);

  React.useEffect(() => {
    resetTabs();
    if (userid) {
      loadUser({ userid });
    }
  }, [userid]);

  React.useEffect(() => {
    if (user && !selectionFixed) {
      const { orgHierarchyNodeIds } = user;

      dispatch(storeUserAction({
        user: {
          ...user,
          orgHierarchyNodeIds,
        },
      }));
      setSelectionFixed(true);
    }
  }, [user]);

  React.useEffect(() => {
    if (props.flow === 'create') {
      clearUser();
    }
  }, [clearUser, props.flow]);

  React.useEffect(() => {
    storePageFlow({ pageFlow: props.flow });
  }, [props.flow, storePageFlow]);
};
