// module
export const CMS_MODULENAME = 'contentmanagement';

// url paths
export const PATH_CMS_BASEURL = '/contentmanagement';
export const PATH_CMS_DASHBOARD_URL = '/dashboard';
export const PATH_CMS_ALLPAGES_URL = '/allpages';
export const PATH_CMS_CREATE_PAGE = '/createpage';
export const PATH_CMS_ENDPOINT = 'cms';
export const PATH_ORGHIERARCHIES = 'orghierarchies';
export const PATH_CMS_EDIT_PAGE = '/page/:pageId/variants/:variantId';

export const PATH_CMS_CREATE_PAGE_FULL = `${PATH_CMS_BASEURL}${PATH_CMS_CREATE_PAGE}`;
export const PATH_CMS_ALLPAGES_URL_FULL = `${PATH_CMS_BASEURL}${PATH_CMS_ALLPAGES_URL}`;

// redux actions
export const ACTION_CMS_CREATE_PAGE = 'ACTION_CMS_CREATE_PAGE';
export const ACTION_CMS_PAGE_SAVE_CONTENT = 'ACTION_CMS_PAGE_SAVE_CONTENT';
export const ACTION_CMS_STORE_PAGE = 'ACTION_CMS_STORE_PAGE';
export const ACTION_CMS_ADD_LAYOUT_TO_PAGE = 'ACTION_CMS_ADD_LAYOUT_TO_PAGE';
export const ACTION_CMS_REMOVE_ROW = 'ACTION_CMS_REMOVE_ROW';
export const ACTION_CMS_TOGGLE_DISPLAY_PROMOTIONS_CAROUSEL = 'ACTION_CMS_TOGGLE_MISSED_PROMOTION';
export const ACTION_CMS_REORDER_ROWS = 'ACTION_CMS_REORDER_ROWS';
export const ACTION_CMS_SET_EDITROWINDEX = 'ACTION_CMS_SET_EDITROWINDEX';
export const ACTION_CMS_PAGE_PUBLISH = 'ACTION_CMS_PAGE_PUBLISH';
export const ACTION_CMS_SET_INSERT_LAYOUT_INDEX = 'ACTION_CMS_SET_INSERT_LAYOUT_INDEX';
export const ACTION_SET_SUBCONTAINER_EDITING = 'ACTION_SET_SUBCONTAINER_EDITING';
export const ACTION_SET_SELECTED_COMPONENT_IN_MANAGER = 'ACTION_SET_SELECTED_COMPONENT_IN_MANAGER';
export const ACTION_SET_ALL_PAGES_STATE = 'ACTION_SET_ALL_PAGES_STATE';

// cms documents
export const ACTION_CMS_LOAD_DOCUMENT_VARIANT = 'ACTION_CMS_LOAD_DOCUMENT_VARIANT';
export const ACTION_CMS_LOAD_DOCUMENT_VARIANT_CONTENT = 'ACTION_CMS_LOAD_DOCUMENT_VARIANT_CONTENT';
export const ACTION_CMS_LOAD_DOCUMENT = 'ACTION_CMS_LOAD_DOCUMENT';
export const ACTION_CMS_LOAD_DOCUMENT_FOR_INFO = 'ACTION_CMS_LOAD_DOCUMENT_FOR_INFO';
export const ACTION_CMS_ADD_DOCUMENT_TO_FOLDER = 'ACTION_CMS_ADD_DOCUMENT_TO_FOLDER';

export const ACTION_CMS_CREATE_DOCUMENT_VARIANT_CONTENT = 'ACTION_CMS_CREATE_DOCUMENT_VARIANT_CONTENT';

export const ACTION_CMS_STORE_PAGEINFO = 'ACTION_CMS_STORE_PAGEINFO';

export const ACTION_CMS_CLEAR_CURRENTPAGE = 'ACTION_CMS_CLEAR_CURRENTPAGE';
export const ACTION_CMS_STORE_TEMPLATES = 'ACTION_CMS_STORE_TEMPLATES';
export const ACTION_CMS_SAVE_LAYOUT_EDITOR = 'ACTION_CMS_SAVE_LAYOUT_EDITOR'; // to be deleted
export const ACTION_CMS_STORE_LAYOUT_EDITOR_FORMDATA = 'ACTION_CMS_STORE_LAYOUT_EDITOR_FORMDATA';

export const ACTION_CMS_SET_ACTIVE_COLUMNINDEX = 'ACTION_CMS_SET_ACTIVE_COLUMNINDEX';
export const ACTION_CMS_SHOULDREOPEN_LAYOUTEDITOR_MODAL = 'ACTION_CMS_SHOULDREOPEN_LAYOUTEDITOR_MODAL';
export const ACTION_CMS_CREATE_PAGE_VARIANT = 'ACTION_CMS_CREATE_PAGE_VARIANT';

// row editor
export const ACTION_CMS_INIT_ROWEDITOR_DATA = 'ACTION_CMS_INIT_ROWEDITOR_DATA';
export const ACTION_CMS_CLEAR_ROWEDITOR_DATA = 'ACTION_CMS_CLEAR_ROWEDITOR_DATA';
export const ACTION_CMS_STORE_ROWEDITOR_DATA = 'ACTION_CMS_STORE_ROWEDITOR_DATA';
export const ACTION_CREATE_SHARED_COMPONENT_PLACEHOLDER = 'ACTION_CREATE_SHARED_COMPONENT_PLACEHOLDER';
export const ACTION_INSERT_SHARED_COMPONENT_COLUMN = 'ACTION_INSERT_SHARED_COMPONENT_COLUMN';
export const DETACH_SHARED_COMPONENT = 'DETACH_SHARED_COMPONENT';

// carousel editor
export const ACTION_CMS_SET_CAROUSEL_IS_EDITING = 'ACTION_CMS_SET_CAROUSEL_IS_EDITING';
export const ACTION_CMS_UPDATE_CAROUSEL_DATA = 'ACTION_CMS_UPDATE_CAROUSEL_DATA';
export const ACTION_CMS_SET_CAROUSEL_COLUMNINDEX = 'ACTION_CMS_SET_CAROUSEL_COLUMNINDEX';
export const ACTION_CMS_SET_CAROUSEL_TIMEOUT = 'ACTION_CMS_SET_CAROUSEL_TIMEOUT';
export const ACTION_CMS_SET_EDIT_SHARED_COMPONENT = 'ACTION_CMS_SET_EDIT_SHARED_COMPONENT';

export const ACTION_CMS_ADD_EMPTY_COLUMN = 'ACTION_CMS_ADD_EMPTY_COLUMN';
export const ACTION_CMS_REMOVE_COLUMN = 'ACTION_CMS_REMOVE_COLUMN';
export const ACTION_CMS_REMOVE_COLUMN_PAGE_VIEW = 'ACTION_CMS_REMOVE_COLUMN_PAGE_VIEW';
export const ACTION_CMS_REMOVE_DELETED_TYPE = 'ACTION_CMS_REMOVE_DELETED_TYPE';
export const ACTION_CMS_ADD_COLUMN_CONTENT = 'ACTION_CMS_ADD_COLUMN_CONTENT';

// forms
export const CMS_FORM_CREATE_PAGE = 'CMS_FORM_CREATE_PAGE';
export const CMS_FORM_ROWEDITOR = 'CMS_FORM_ROWEDITOR';
export const FORM_LAYOUT_INSPECTOR = 'CMS_FORM_LAYOUT_INSPECTOR';
export const CMS_FORM_SEO_TAB = 'CMS_FORM_SEO_TAB';
export const CMS_CAROUSEL_TIMEOUT_FORM = 'CMS_CAROUSEL_TIMEOUT_FORM';

// modals
export const CMS_SELECT_COMPONENTS_MODAL = 'CMS_SELECT_COMPONENTS_MODAL';
export const CMS_COMPONENT_CLASSNAME = 'CMS_COMPONENT_CLASSNAME';
export const CMS_LAYOUT_EDITOR_MODAL = 'CMS_LAYOUT_EDITOR_MODAL';
export const CMS_CREATE_VARIANT_MODAL = 'CMS_CREATE_VARIANT_MODAL';
export const CMS_CREATE_VARIANT_MODAL_FORM = 'CMS_CREATE_VARIANT_MODAL_FORM';
export const CMS_VARIANT_STORES_HIERARCHY = 'CMS_VARIANT_STORES_HIERARCHY';
export const CMS_VARIANT_HISTORY_MODAL = 'DOCUMENT_VARIANT_HISTORY_MODAL';

// Cms Page Tabs
export const CMS_COMMON_PAGE = 'cms-common-page';
export const CMS_TABS = 'cms-tabs';

export const CMS_PAGE_TABS = 'cms_page_tabs';
export const CMS_PAGE_TEMPLATES_CLASSNAME = 'cms-page-templates';
export const CMS_EDITOR_CLASSNAME = 'cms-editor';

export const CMSPAGE_TAB_CHOOSE_CONTENT = 'CMSPAGE_TAB_CHOOSE_CONTENT';
export const CMSPAGE_TAB_CMS_CONTENT = 'CMSPAGE_TAB_CMS_CONTENT';

// seo tab
export const CMSPAGE_TAB_SEO_CONTENT = 'CMSPAGE_TAB_SEO_CONTENT';
export const ACTION_CMS_CREATE_SEO_FOR_VARIANT = 'ACTION_CMS_CREATE_SEO_FOR_VARIANT';
export const ACTION_CMS_FORM_SEO_TAB_SAVEDATA = 'ACTION_CMS_FORM_SEO_TAB_SAVEDATA';
export const ACTION_CMS_LOAD_DOCUMENT_VARIANT_SEO = 'ACTION_CMS_LOAD_DOCUMENT_VARIANT_SEO';

// stores tab
export const ACTION_CMS_SAVE_STORES_TAB = 'ACTION_CMS_SAVE_STORES_TAB';

// scheduling tab
export const CMSPAGE_TAB_CMS_SCHEDULING = 'CMSPAGE_TAB_CMS_SCHEDULING';
export const CMSPAGE_TAB_CMS_SCHEDULING_FORM = 'CMSPAGE_TAB_CMS_SCHEDULING_FORM';
export const ACTION_CMS_SUBMIT_SCHEDULING_FORM = 'ACTION_CMS_SUBMIT_SCHEDULING_FORM';
export const ACTION_CMS_SAVE_SCHEDULING_FORM_DATA = 'ACTION_CMS_SAVE_SCHEDULING_FORM_DATA';

// edit page details tab
export const CMSPAGE_TAB_EDITDETAIL = 'CMSPAGE_TAB_EDITDETAIL';
export const CMSPAGE_TAB_EDITDETAIL_FORM = 'CMSPAGE_TAB_EDITDETAIL_FORM';
export const ACTION_CMS_SUBMIT_TAB_EDITDETAIL_FORM = 'ACTION_CMS_SUBMIT_TAB_EDITDETAIL_FORM';
export const ACTION_CMS_CREATE_PAGE_DOCUMENT = 'ACTION_CMS_CREATE_PAGE_DOCUMENT ';
export const ACTION_CMS_SAVE_PAGEDETAILS_VARIANT_DATA = 'ACTION_CMS_SAVE_PAGEDETAILS_VARIANT_DATA';
export const ACTION_CMS_SAVE_PAGEDETAILS_DOCUMENT_DATA = 'ACTION_CMS_SAVE_PAGEDETAILS_DOCUMENT_DATA';
export const ACTION_CMS_SAVE_PAGEDETAILS = 'ACTION_CMS_SAVE_PAGEDETAILS';
export const CMSPAGE_TAB_EDITDETAIL_HIERARCHY = 'CMSPAGE_TAB_EDITDETAIL_HIERARCHY';
export const ACTION_CMS_CLEAR_PAGECREATED_FLAG = 'ACTION_CMS_CLEAR_PAGECREATED_FLAG';
export const ACTION_CMS_STORE_HIGHLIGHTED_FOLDERID = 'ACTION_CMS_STORE_HIGHLIGHTED_FOLDERID';

// hierarchy
export const ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY = 'ACTION_CMS_LOAD_CMS_PAGES_HIERARCHY';
export const CMS_ORG_STORE_HIERARCHY = 'CMS_ORG_STORE_HIERARCHY';
export const CMS_PAGES_HIEREARCHY = 'CMS_PAGES_HIEREARCHY';
export const CMS_STORE_PAGES_HIERARCHY = 'CMS_STORE_PAGES_HIERARCHY';
export const ACTION_CMS_UPDATE_PAGE_HIERARCHY = 'ACTION_CMS_UPDATE_PAGE_HIERARCHY';
export const ACTION_CMS_STORE_PAGES_HIERARCHY = 'ACTION_CMS_STORE_PAGES_HIERARCHY';
export const ACTION_CMS_UPDATE_STORED_PAGE_HIERARCHY = 'ACTION_CMS_UPDATE_STORED_PAGE_HIERARCHY';
export const RELOAD_PAGE_HIERARCHY = 'RELOAD_PAGE_HIERARCHY';

// cmsPage status
export const ACTION_CMS_PAGE_UPDATE_STATUS = 'ACTION_CMS_PAGE_UPDATE_STATUS';
export const ACTION_CMS_DELETE_PAGE = 'ACTION_CMS_DELETE_PAGE';

export const DELETE_CMS_PAGE = 'DELETE_CMS_PAGE';
export const SAVE_CMS_CONTENT = 'SAVE_CMS_CONTENT';

// cms emails
export const CMS_PATH_ALL_EMAILS = '/emails';
export const CMS_PATH_EDIT_EMAIL = '/emails/edit/:documentId/variants/:variantId';
export const CMS_PATH_NEW_EMAIL = '/emails/create';

// cms Page Settings
export const INTERIM_MODAL_ID = '98dceb7a-2407-993d-5781-f35c504c46c0';
