import { AuthorizationClaims, UserAccessRights } from 'framework/authorization/Interfaces';
import { AppRouteBase } from 'framework/components/navigation/Navigation.interfaces';
import { goToRoute } from 'framework/components/navigation/service';
import { PromotionsTranslations } from '../translations';
import { PromotionType } from './models/promotion';

export const promotionsClaims = {
  [AuthorizationClaims.PROMOTIONS_PROMOTIONS]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.DAM]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
};

export const promotionListRoute: AppRouteBase = {
  path: '/promotions/promotions/',
  authClaim: promotionsClaims,
  pageTitle: PromotionsTranslations.Promotions,
  menuTitle: PromotionsTranslations.AllPromotions,
};

export const goToPromotionListRoute = () => goToRoute(promotionListRoute);

export const promotionEditRoute: AppRouteBase = {
  path: '/promotions/promotions/edit/:type/:promotionRetailerId',
  authClaim: promotionsClaims,
  pageTitle: PromotionsTranslations.EditPromotion,
  parent: promotionListRoute,
};

export const goToPromotionEditRoute = (promotionRetailerId: string, type: PromotionType) => (
  goToRoute(promotionEditRoute, { promotionRetailerId, type })
);

export const promotionCreateRoute: AppRouteBase = {
  path: '/promotions/promotions/create',
  authClaim: promotionsClaims,
  pageTitle: PromotionsTranslations.CreatePromotion,
  parent: promotionListRoute,
};
