import * as React from 'react';
import * as conf from 'framework/components/ui/_conf';
import { HierarchyProps } from './hierarchy.interfaces';
import { HierarchyTableHeaders } from './hierarchy.headers';
import { HierarchyRow } from './hierarchy.row';
import { HierarchyContext } from './hierarchy.provider';

const hierarchyClassName = conf.prefixClassName('hierarchy');

export const HierarchyTable = (props: HierarchyProps) => {
  const {
    columns, showHeader = true, selectable, singleSelect, selectLeavesOnly, disabled, hideIntermediary = false,
    allowSingleLeaf = true, allowShowMore = false,
  } = props;
  const {
    sortBy, data, allSelected, toggleCheckAll,
  } = React.useContext(HierarchyContext);

  const nodeData = sortBy ? [...data].sort(sortBy) : data;

  return (
    <table className={`${hierarchyClassName}__table`}>
      {showHeader && (
        <HierarchyTableHeaders
          columns={columns}
          selectable={selectable}
          singleSelect={singleSelect}
          allSelected={allSelected}
          handleAllSelected={toggleCheckAll}
        />
      )}
      <tbody>
        {nodeData.map((node) => (
          <HierarchyRow
            key={node.id}
            filteredData={nodeData}
            currentNode={node}
            level={0}
            singleSelect={singleSelect}
            selectLeavesOnly={selectLeavesOnly}
            disabled={disabled}
            hideIntermediary={hideIntermediary}
            allowSingleLeaf={allowSingleLeaf}
            allowShowMore={allowShowMore}
          />
        ))}
      </tbody>
    </table>
  );
};
