import * as React from 'react';
import { ApiRequestContextProps } from './useApiRequest.interfaces';

export const ApiRequestContext = React.createContext({});

type Props = Partial<ApiRequestContextProps> & {
  children: any;
} & {
  apiClientData: any;
};

// This provider exposes the apiClient.createApiClientMakeRequestAction action
export const ApiRequestProvider = (props: Props) => {
  const context: Partial<ApiRequestContextProps> = {
    makeApiCall: props.makeApiCall,
    apiClientData: props.apiClientData
  };
  return <ApiRequestContext.Provider value={context}>{props.children}</ApiRequestContext.Provider>;
};
