import * as React from 'react';
import './_styles.scss';
import { Loader } from 'ui';
import { ILoader } from 'framework/components/ui/Loader';

type LoadingProps = {
  loading: boolean;
};
type ComponentWithLoadingProps<K> = LoadingProps & K ;
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withLoading = (loaderProps: ILoader) => {
  function wrapComponent<K = any>(component): React.ComponentType<ComponentWithLoadingProps<K>> {
    const Component = component;
    function ComponentWithLoading(props: ComponentWithLoadingProps<K>) {
      const { loading = false } = props;
      if (!loading) {
        return <Component {...props} />;
      }
      return <Loader {...loaderProps}/>;
    }
    ComponentWithLoading.displayName = `withLoading(${getDisplayName(Component)})`;
    return ComponentWithLoading;
  }
  return wrapComponent;
};

export { withLoading };
