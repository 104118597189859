import { Store, OrgHierarchyNode, Status } from '../Store';
import { StoreAttribute } from '../StoreAttribute';
import { DeliveryAreaType } from '../DeliveryArea.interface';
import { PollingStoreHierarchyState } from '../StoreDetails/interfaces';

const actionTypes = {
  API_PROVIDER_GET_STORE: 'API_PROVIDER_GET_STORE',
  API_PROVIDER_SAVE_STORE: 'API_PROVIDER_SAVE_STORE',
  API_PROVIDER_DELETE_STORE: 'API_PROVIDER_DELETE_STORE',
  API_PROVIDER_GET_STORE_ATTRIBUTES: 'API_PROVIDER_GET_STORE_ATTRIBUTES',
  API_PROVIDER_SAVE_STORE_ATTRIBUTES: 'API_PROVIDER_SAVE_STORE_ATTRIBUTES',
  API_PROVIDER_SAVE_GLOBAL_STOREATTRIBUTE: 'API_PROVIDER_SAVE_GLOBAL_STOREATTRIBUTE',
  API_PROVIDER_GET_DELIVERY_AREAS: 'API_PROVIDER_GET_DELIVERY_AREAS',
  API_PROVIDER_SAVE_DELIVERY_AREAS: 'API_PROVIDER_SAVE_DELIVERY_AREAS',
  API_PROVIDER_GET_STORE_STATUS: 'API_PROVIDER_GET_STORE_STATUS',
  STORES_FETCH_SITES: 'STORES_FETCH_SITES',
  STORE_FETCH_STORE_SITE: 'STORES_FETCH_STORE_SITE',
  STORES_FETCH_TIMEZONES: 'STORES_FETCH_TIMEZONES',
  STORES_FETCH_CATEGORIES: 'STORES_FETCH_CATEGORIES',
  STORES_CREATE_STORE: 'STORES_CREATE_STORE',
  STORES_GET_STORE: 'STORES_GET_STORE',
  STORES_DELETE_STORE: 'STORES_DELETE_STORE',
  STORES_GET_STORE_SITE: 'STORES_GET_STORE_SITE',
  STORES_SAVE_STORE: 'STORES_SAVE_STORE',
  STORES_SAVE_STORE_URLS: 'STORES_SAVE_STORE_URLS',
  STORES_GET_STORE_ATTRIBUTES: 'STORES_GET_STORE_ATTRIBUTES',
  STORES_SAVE_STORE_ATTRIBUTES: 'STORES_SAVE_STORE_ATTRIBUTES',
  STORES_GET_GLOBAL_STOREATTRIBUTES: 'STORES_GET_GLOBAL_STOREATTRIBUTES',
  STORES_CREATE_GLOBAL_STOREATTRIBUTE: 'STORES_CREATE_GLOBAL_STOREATTRIBUTE',
  STORES_GET_DELIVERY_AREAS: 'STORES_GET_DELIVERY_AREAS',
  STORES_SAVE_DELIVERY_AREAS: 'STORES_CREATE_DELIVERY_AREAS',
  STORES_SAVE_STORE_HIERARCHY: 'STORES_SAVE_STORE_HIERARCHY',
  STORES_SAVE_STORE_STATUS: 'STORES_SAVE_STORE_STATUS',
  STORE_SHOPPING_METHOD: 'STORE_SHOPPING_METHOD',
  START_POLLING_STOREHIERARCHIES: 'START_POLLING_STOREHIERARCHIES',
  SET_POLLING_STOREHIERARCHY: 'SET_POLLING_STOREHIERARCHY',
};

export type PollingStoreHierarchyProps = {
  storeId: string;
  parentId: string;
};

const startStoreHierarchyPolling = (payload: PollingStoreHierarchyProps) => ({
  type: actionTypes.START_POLLING_STOREHIERARCHIES,
  payload: {
    ...payload,
  },
});

const setStoreHierarchyPolling = (payload: PollingStoreHierarchyState) => ({
  type: actionTypes.SET_POLLING_STOREHIERARCHY,
  payload,
});

const createStoreAction = () => ({
  type: actionTypes.STORES_CREATE_STORE,
});

const getStoreAction = (id: string) => ({
  type: actionTypes.STORES_GET_STORE,
  payload: { id },
});

const saveStoreAction = (data: Partial<Store>, orgHierarchyNode: OrgHierarchyNode, formName: string) => ({
  type: actionTypes.STORES_SAVE_STORE,
  payload: { data, orgHierarchyNode, formName },
});

const deleteStoreAction = (id: string) => ({
  type: actionTypes.STORES_DELETE_STORE,
  payload: { id },
});

// TODO: type url
const saveStoreUrlsAction = (data: Partial<Store>, formName: string) => ({
  type: actionTypes.STORES_SAVE_STORE_URLS,
  payload: { data, formName },
});

const getStoreAttributesAction = (id: string) => ({
  type: actionTypes.STORES_GET_STORE_ATTRIBUTES,
  payload: { id },
});

const saveStoreAttributesAction = (data: any) => ({
  type: actionTypes.STORES_SAVE_STORE_ATTRIBUTES,
  payload: { data },
});

const saveGlobalStoreAttributeAction = (data: Partial<StoreAttribute>, modalName?: string) => ({
  type: actionTypes.STORES_CREATE_GLOBAL_STOREATTRIBUTE,
  payload: { data, modalName },
});

const getDeliveryAreasAction = (id: string) => ({
  type: actionTypes.STORES_GET_DELIVERY_AREAS,
  payload: { id },
});

const saveDeliveryAreaAction = (previous: DeliveryAreaType[], formName: string) => ({
  type: actionTypes.STORES_SAVE_DELIVERY_AREAS,
  payload: { previous, formName },
});

const saveStoreStatusAction = (id: string, status: Status) => ({
  type: actionTypes.STORES_SAVE_STORE_STATUS,
  payload: { id, status },
});

export {
  actionTypes,
  createStoreAction,
  getStoreAction,
  saveStoreAction,
  saveGlobalStoreAttributeAction,
  saveStoreUrlsAction,
  getStoreAttributesAction,
  saveStoreAttributesAction,
  getDeliveryAreasAction,
  saveDeliveryAreaAction,
  saveStoreStatusAction,
  deleteStoreAction,
  startStoreHierarchyPolling,
  setStoreHierarchyPolling,
};
