import { call, put } from 'redux-saga/effects';
import { createApiClientUpdateStateAction } from 'framework/api/apiClient/actions';
import { makeRequestAndSaveState } from 'framework/api/make-request';
import { toastListActions } from 'framework/actions';
import { takeEveryWithConfirmation } from 'framework/sagas/extensions/confirmation';
import { history } from 'framework/store';

import { config } from 'config';
import { actionTypes } from '../actions';
import { STORES_PATH } from '../constants';

const { API_PROVIDER_DELETE_STORE } = actionTypes;
const baseUri = config.apiEndpoints.stores;

function* process({ payload }) {
  const { id } = payload;
  const response = yield call(makeRequestAndSaveState, {
    name: API_PROVIDER_DELETE_STORE,
    method: 'DELETE',
    url: `${baseUri}/${id}`,
  });

  if (!response.success) {
    yield put(toastListActions.addMessageError('modules.stores.storeSetup.confirmationModal.deleteError'));
  } else {
    yield put(
      createApiClientUpdateStateAction({
        name: API_PROVIDER_DELETE_STORE,
        hasError: false,
        isFetching: false,
      }),
    );
    yield put(toastListActions.addMessage('modules.stores.storeSetup.confirmationModal.deleteSuccess'));
  }

  // if(history.location.pathname.includes(`${STORES_PATH}/${id}`)) {
  // TODO: reload table component on delete. For now it reloads but doesn't persist the page object.
  const path = history.location.pathname.replace(`${STORES_PATH}/${id}`, `${STORES_PATH}`);
  history.replace(path);
}

export default function* watchDeleteStoreAction() {
  yield takeEveryWithConfirmation([actionTypes.STORES_DELETE_STORE], process);
}
