import * as React from 'react';
import classnames from 'classnames';
import { useHover } from 'helpers/hooks';
import OutsideClickHandler from 'react-outside-click-handler';
import { isFunction } from 'framework/utils/helpers';
import { prefixClassName } from 'framework/components/ui/_conf';
import Tooltip from 'framework/components/ui/Tooltip';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useLocation } from 'react-router-dom';
import { IWithTooltipProps } from './interfaces';

import './_styles.scss';

const clx = prefixClassName('with-tooltip');

export const withTooltip = <P extends {}>(WrappedComponent: React.ComponentType<P>) => (props:IWithTooltipProps & P) => {
  const {
    className, position, styles, onClick, tooltipBody, showOnHover = false, trackEventProps, parentId,
  } = props;

  const { pathname } = useLocation();

  const [isVisible, setIsVisible] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [ref, isHover] = useHover();

  const onClickHandler = () => {
    setIsActive(!isActive);
    if (isFunction(onClick)) onClick();
  };

  const onOutsideClickHandler = () => {
    setIsVisible(false);
    setIsActive(false);
  };

  React.useEffect(() => {
    if (showOnHover) {
      setIsVisible(isHover);
    } else {
      setIsVisible(isActive);
    }
    if (showOnHover && isHover || isActive) {
      ApplicationMonitoring.trackEvent(
        trackEventProps?.name || pathname,
        trackEventProps?.value || 'Tooltip',
        trackEventProps?.label || 'Toggle'
      );
    }
  }, [isActive, isHover, showOnHover]);

  return (
    <OutsideClickHandler
      onOutsideClick={onOutsideClickHandler}
      disabled={!isActive}
    >
      <div className={classnames(clx, className)} onClickCapture={onClickHandler} ref={ref} id={parentId}>
        <WrappedComponent {...props} />
        <div
          style={styles}
          className={classnames(
            `${clx}--body`,
            position && `${clx}--body-${position}`,
          )}
        >
          <Tooltip {...props} active={isVisible}>
            {tooltipBody}
          </Tooltip>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default withTooltip;
