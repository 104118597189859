import * as React from 'react';
import classnames from 'classnames';
import { i18n } from 'i18n';
import Select, { OptionProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { isArray, isString } from 'framework/utils/helpers';
import { Text, Icon } from 'framework/components/ui';
import { toKebabCase } from 'framework/utils/string';
import * as conf from 'framework/components/ui/_conf';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { useWindow } from 'framework/utils/useWindow';
import { ReadOnlyField } from '../ReadOnlyField';
import { ISelectPrimitive, ISelectPrimitiveOptionsProps } from './SelectPrimitive.interface';
import { multiSelect, singleSelect, transformOptions } from './service';
import './_style.scss';

const clx = conf.prefixClassName('select');
const clxMulti = conf.prefixClassName('multi-select');
const customOption = 'Select__option';

type IsMulti = boolean;

const CustomOptionWrapper = React.memo(
  ({
    innerProps,
    innerRef,
    label,
    isDisabled,
    isFocused,
    isSelected,
  }: OptionProps<ISelectPrimitiveOptionsProps, IsMulti>) => (
    <div
      ref={innerRef}
      className={classnames(
        {
          [`${customOption}`]: true,
          [`${customOption}--is-disabled`]: isDisabled,
          [`${customOption}--is-focused`]: isFocused,
          [`${customOption}--is-selected`]: isSelected,
        },
        isString(label) && `${customOption}--${toKebabCase(label)}`
      )}
      {...innerProps}
    >
      {label}
    </div>
  )
);
const components = { Option: CustomOptionWrapper };

const formatCreateLabel = (input) => (
  <div className={`${clx}__create`}>
    <Icon className={`${clx}__create__icon`} name="plus" />
    <span className={`${clx}__create__text`}>{i18n.t('generic.createX', { x: input })}</span>
  </div>
);

// TODO: See if this can be cleaner with https://react-select.com/props#group
const getOptionsWithSuggestions = (opts = [], suggestions): ISelectPrimitiveOptionsProps[] => {
  const optionsWithSuggestions = [...opts];

  suggestions.forEach((suggestion, i) => {
    const index = optionsWithSuggestions.findIndex((op) => op.value === suggestion);
    if (index !== -1) {
      const [suggestedOption] = optionsWithSuggestions.splice(index, 1);
      optionsWithSuggestions.splice(i, 0, suggestedOption);
    }
  });
  return optionsWithSuggestions;
};

const emptySuggestions = [];

const arrowRenderer = () => <Icon className={`${clx}__icon-opener`} name="dropdownArrowDown" />;

const SelectPrimitive = React.memo((props: ISelectPrimitive) => {
  const {
    value,
    name,
    options: optionsIni,
    multi = false,
    allowCreate = false,
    disabled = false,
    matchProp = 'both',
    menuPlacement = 'auto',
    placeholder = 'components.select.placeholder',
    className,
    searchable = false,
    clearable = true,
    onBlur,
    onFocus,
    readOnly = false,
    onChange = null,
    suggestions = emptySuggestions,
    filterOption,
    id,
    trackEventProps,
  } = props;
  const getEmptyValue = () => (multi ? [] : '');
  const service = multi ? multiSelect : singleSelect;

  const {
    location: { pathname = '' },
  } = useWindow();

  const transformedOptions = React.useMemo(() => {
    const allOpts = suggestions.length ? getOptionsWithSuggestions(optionsIni, suggestions) : optionsIni;
    return transformOptions(allOpts);
  }, [optionsIni, suggestions]);
  // transform the initial value string property to the object used by react-select
  const transformedValues = React.useMemo(() => service.transformValues(value as any), [value]);
  const { selectedValues = null, availableOptions: options } = React.useMemo(
    () => service.getAvailableOptions(transformedValues as any, transformedOptions),
    [transformedValues, transformedOptions]
  );

  const isReadOnly = () =>
    // When there is only one option and it is impossible to remove,
    // don't show it as a selectable dropdown, as is it impossible to change
    (value && options && options.length === 1 && value === options[0].value && !clearable) || readOnly;

  const handleOnChange = (values) => {
    if (disabled) {
      return;
    }
    ApplicationMonitoring.trackEvent(
      trackEventProps?.name || pathname,
      trackEventProps?.value || 'Select',
      trackEventProps?.label || props.name
    );
    // When clearing value of dropdown we need to send empty string
    let val = values !== null ? { ...values, name } : getEmptyValue();
    if (isArray(values)) {
      // multiselect
      val = values.length !== 0 ? [...values] : values;
    }

    onChange(val);
  };

  const handleOnInputChange = (input: string) => {
    if (props.onInputChange) {
      props.onInputChange(input);
    }
  };

  const suggestionStyle = (state) => {
    const index = suggestions.indexOf(state.value);
    if (index > -1) {
      return {
        fontWeight: 'bold',
        borderBottom: index === suggestions.length - 1 ? '1px solid grey' : '',
      };
    }
    return {};
  };

  const customStyles = React.useMemo(() => {
    if (suggestions.length > 0) {
      return {
        option: (provided, state) => ({
          ...provided,
          ...suggestionStyle(state),
        }),
      };
    }
    return {
      option: (provided) => provided,
    };
  }, [suggestions]);

  const Tag = !allowCreate ? Select : CreatableSelect;

  const TagComponent = (
    <Tag
      arrowRenderer={!disabled ? (arrowRenderer as any) : null}
      name={name}
      options={options}
      isMulti={multi}
      classNamePrefix="Select"
      className={classnames(multi ? clxMulti : clx, className)}
      styles={customStyles}
      isSearchable={allowCreate ? true : searchable}
      isClearable={clearable}
      value={selectedValues}
      isDisabled={disabled}
      menuPlacement={menuPlacement}
      onChange={handleOnChange}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={(<Text caption={placeholder} />) as any}
      matchProp={matchProp}
      onInputChange={handleOnInputChange}
      formatCreateLabel={formatCreateLabel}
      components={components}
      filterOption={filterOption}
      id={id}
      aria-label={name}
    />
  );

  if (isReadOnly()) {
    return (
      <ReadOnlyField
        value={service.printLabels(selectedValues as any)}
        placeholder={placeholder}
        className={className}
      />
    );
  }

  if (disabled) {
    return <label className="w-full">{TagComponent}</label>;
  }

  return TagComponent;
});

export default SelectPrimitive;
