import classNames from 'classnames';
import { urlParam } from 'featureFlag';
import { Button } from 'framework/components/ui';
import ApplicationMonitoring from 'framework/applicationMonitoring/ApplicationMonitoring';
import { prefixClassName } from 'framework/components/ui/_conf';
import debounce from 'lodash.debounce';
import * as React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { i18n } from 'i18n';
import { Anchors, Tab } from './components';
import './style/index.scss';
import { ITabGroupProps, ITabProps } from './Tabs.interface';
import { TABS_URL_PARAM } from './Tabs.types';
import TabsContext from './TabsContext';

const classNameTabs = prefixClassName('tabs');

export class Tabs extends React.Component<ITabGroupProps, any> {
  static defaultProps: Partial<ITabGroupProps> = {
    data: [],
    disabled: false,
    sticky: true,
    addTabDefaults: null,
    onTabChange: () => null,
    onAddTab: () => null,
    isEditMode: true,
    disableUrlEdit: false,
    hideSingleTab: false,
    unshiftLastAnchor: false,
  };

  registeredAnchors = {};

  myRef: any;

  scrollHandler = undefined;

  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.state = {
      contextValue: {
        tabsName: props.id,
        registerAnchor: this.registerAnchor,
        unRegisterAnchor: this.unRegisterAnchor,
      },
      isTabsHeaderFixed: false,
      lastScrollPosition: 0,
      isScrollingUp: false,
      timeoutId: undefined,
    };
  }

  componentDidUpdate(prevProps: Readonly<ITabGroupProps>): void {
    const { openTab, isEditMode } = this.props;

    if (prevProps.openTab !== openTab && isEditMode) {
      this.changeUrl(openTab);
    }
  }

  componentDidMount = () => {
    const {
      id, defaultTab, onTabChange, openTab, data, isEditMode, afterTabChanged,
    } = this.props;

    if (data.length === 0) return;

    const selectedTab = new URLSearchParams(window.location.search).get(TABS_URL_PARAM);

    if (isEditMode) {
      if (selectedTab) {
        // Open selectedTab from URL if found
        // Else open defaultTab if provided and found
        // Else open first tab
        if (data.find((tab) => tab?.id === selectedTab)) {
          onTabChange(id, selectedTab, true);
        } else if (defaultTab && data.find((tab) => tab?.id === defaultTab)) {
          onTabChange(id, defaultTab, true);
        } else {
          this.changeUrl(data[0].id);
        }
      } else if (defaultTab) {
        onTabChange(id, defaultTab, true);

        this.changeUrl(defaultTab);
      } else {
        const tabOpen = openTab || data[0].id;

        const foundTab: ITabProps = data?.find((tab) => tab.id === tabOpen);

        if (foundTab && !foundTab.hidden) {
          onTabChange(id, tabOpen, true);

          this.changeUrl(tabOpen);
        }
      }
    } else {
      const tabOpen = data[0].id;

      onTabChange(tabOpen);

      this.changeUrl('');

      if (afterTabChanged) {
        afterTabChanged(id, tabOpen);
      }
    }

    this.registerScroll();
  };

  componentWillUnmount = () => {
    this.unregisterScroll();
  };

  handleTabChange = (id: string) => {
    const {
      onBeforeChange, openTab, data, id: tabsId, onTabChange, afterTabChanged, isEditMode,
    } = this.props;

    const tabOpen = openTab || data[0].id;
    if (tabOpen === id) {
      return;
    }

    const tabData = data.find((tab) => tab.id === id);

    // if there exists a "onBeforeChange" callback then check wheither tab change should be cancelled
    const canChangeTab = !onBeforeChange || onBeforeChange(id);
    if (canChangeTab) {
      onTabChange(tabsId, id);
      this.sendTracking(id);

      if (isEditMode) {
        this.changeUrl(tabData?.id);
      }

      if (afterTabChanged) {
        afterTabChanged(tabsId, id);
      }
    }

    ApplicationMonitoring.pageViewEvent({ title: `${i18n.t(tabData.label)} - ${document.title}` }); // Add title of page
  };

  handleAddTab = () => {
    const { addTabDefaults, onAddTab, data } = this.props;

    const { name } = addTabDefaults;

    const newTab: ITabProps = {
      id: `${name} ${data.length + 1}`,
      label: `${name} ${data.length + 1}`,
    };

    onAddTab(newTab);
  };

  onAnchorClickHandler = (caption) => {
    // for anchor clicks, we remove the scroll listener, so the user can still see the tabs
    // if he wants to scroll to another section again using the anchors
    this.unregisterScroll();
    const { isTabsHeaderFixed } = this.state;
    const element = this.registeredAnchors[caption]?.current;
    const tabsHeight = (this.myRef?.current && this.myRef?.current.offsetHeight) || 0;
    // 10 is a top / bottom padding of navigation
    const tabPaddingTopBottom = 10;
    const fixedOffset = isTabsHeaderFixed ? 0 : tabPaddingTopBottom;
    const finalOffsetTop = element.offsetTop - fixedOffset - tabsHeight;
    window.scrollTo({ top: finalOffsetTop, behavior: 'smooth' });
    // saves enough time for the scroll to happen before we apply the tabs scrolling behaviour again
    const timeoutId = setTimeout(() => {
      this.registerScroll();
    }, 1000);

    this.setState((prev) => ({
      ...prev, timeoutId, selectedAnchor: caption, anchorPosition: finalOffsetTop,
    }));
  };

  getOpenTabIndex() {
    const { openTab, data } = this.props;
    if (!openTab) {
      return 0;
    }

    const index = data.findIndex((element) => element.id === openTab);
    return index === -1 ? 0 : index;
  }

  changeUrl = (selectedTab: string) => {
    const { disableUrlEdit, location } = this.props;

    if (disableUrlEdit) return;

    const { search } = location;

    const { pathname } = location;

    // keep dev flag when changing tabs
    const devFlag: string[] = new URLSearchParams(search).getAll(urlParam);

    let editedPathname: string = selectedTab.length === 0 ? pathname : `${pathname}?${TABS_URL_PARAM}=${selectedTab}`;

    if (devFlag.length > 0) {
      devFlag.forEach((flag: string) => {
        editedPathname = `${editedPathname}${selectedTab.length === 0 ? '?' : '&'}${urlParam}=${flag}`;
      });
    }

    window.history.replaceState({ path: editedPathname }, '', editedPathname);
  };

  registerAnchor = (caption, ref) => {
    const { onRegisterAnchor, id } = this.props;
    onRegisterAnchor({ tabGroupId: id, anchorName: caption });
    this.registeredAnchors[caption] = ref;
  };

  unRegisterAnchor = (caption) => {
    const { onUnRegisterAnchor, id } = this.props;
    delete this.registeredAnchors[caption];
    onUnRegisterAnchor({ tabGroupId: id, anchorName: caption });
  };

  /**
   * Sticky tabs behaviour: we show them only when the user is scrolling back to the top of the page
   * to save space, so the user sees more content while scrolling down
   */
  registerScroll = () => {
    const { sticky } = this.props;
    if (sticky) {
      this.setState((prev) => ({
        ...prev,
        lastScrollPosition: window.scrollY,
      }));
      this.scrollHandler = debounce(() => {
        const { anchorPosition } = this.state;
        this.setState((prev) => ({
          ...prev,
          lastScrollPosition: window.scrollY,
          isScrollingUp: window.scrollY > 0 && prev.lastScrollPosition > window.scrollY,
          ...((Math.abs(anchorPosition - window.scrollY) > 100) && { selectedAnchor: null }),
        }));
        // this.forceUpdate();
      }, 100, { maxWait: 300 });

      window.addEventListener('scroll', this.scrollHandler);
    }
  };

  unregisterScroll = () => {
    if (this.scrollHandler) {
      const { timeoutId } = this.state;

      clearTimeout(timeoutId);
      this.setState((prev) => ({
        ...prev,
        lastScrollPosition: 0,
        isScrollingUp: true,
      }));
      window.removeEventListener('scroll', this.scrollHandler);
    }
  };

  sendTracking = (tabId: string) => {
    const { id: tabGroupId } = this.props;
    ApplicationMonitoring.trackEvent(window.location.pathname, tabGroupId, tabId);
  };

  inVisibilityChangeHandler = (isVisible) => {
    this.setState({ isTabsHeaderFixed: !isVisible });
  };

  renderTabs() {
    const { data, openTab, disabled, addTabDefaults, onAddTab } = this.props;

    // Render a disabled tab for when there are no tabs yet and add tabs functionality is implemented
    if (data.length === 0 && addTabDefaults && onAddTab) {
      return (
        <Tab
          label={`0 ${addTabDefaults.name}s`}
          onClick={() => {}}
          disabled
        />
      );
    }

    // Set tab 0 as default
    const tabOpen = openTab || data[0].id;

    return data.map((tabItem) => {
      if (!tabItem || tabItem?.hidden) {
        return null;
      }
      return (
      <Tab
        key={tabItem.id}
        label={tabItem.label}
        onClick={() => this.handleTabChange(tabItem.id)}
        className={
          classNames(
            tabItem.className,
            // Add ellipsis to label if add tabs functionality is implemented
            (addTabDefaults && onAddTab) && `${classNameTabs}__tab--allows-user-caption`,
          )
        }
        completed={tabItem.completed}
        isOpen={tabOpen === tabItem.id}
        disabled={tabItem.disabled || disabled}
        authorize={tabItem.authorize}
      />
      );
    });
  }

  renderContent() {
    const { data } = this.props;

    if (data.length === 0) return;

    const tabData = data[this.getOpenTabIndex()];
    const Content = tabData.content;
    return typeof Content === 'function' ? <Content /> : Content;
  }

  renderAddTab() {
    const { addTabDefaults, id, data } = this.props;

    if (addTabDefaults.readOnly) return null;

    return (
      <>
        <Button
          icon="plus"
          onClick={this.handleAddTab}
          outline
          className={`${classNameTabs}__add-tab-btn`}
          testId={`${id}-add-tab`}
        />
        {data.length > 0 && (
          <Button
            caption={addTabDefaults.editSequenceCaption}
            onClick={addTabDefaults.editSequenceAction}
            testId={`${id}-edit-sequence`}
            outline
          />
        )}
      </>
    );
  }

  render() {
    const {
      id, data, className, summary, sticky, hideSingleTab, unshiftLastAnchor, addTabDefaults, onAddTab,
    } = this.props;
    const {
      isTabsHeaderFixed, isScrollingUp, contextValue, selectedAnchor
    } = this.state;

    let style;
    if (sticky && isTabsHeaderFixed && this.myRef?.current && !isScrollingUp) {
      style = {
        top: `-${this.myRef?.current.offsetHeight + 20}px`,
      };
    }

    const tabData = data[this.getOpenTabIndex()];

    // Render tabs component if data length is not 0 or add tabs functionality is implemented
    return data.length !== 0 || (addTabDefaults && onAddTab) ? (
      <TabsContext.Provider value={contextValue}>
        <div className={className}>
          {sticky && (
            <VisibilitySensor
              onChange={this.inVisibilityChangeHandler}
              scrollDelay={0}
              intervalDelay={0}
            >
              <div className={`${classNameTabs}__visibility-sensor`} />
            </VisibilitySensor>
          )}

          { !(data.length === 1 && hideSingleTab) && (
            <div
              ref={this.myRef}
              className={classNames(classNameTabs, {
                [`${classNameTabs}--sticky`]: sticky,
                [`${classNameTabs}--hide`]: sticky && isTabsHeaderFixed && !isScrollingUp,
                [`${classNameTabs}--show`]: sticky && isTabsHeaderFixed && isScrollingUp,
              })}
              style={style}
            >
              <div className={`${classNameTabs}--flex`}>
                {this.renderTabs()}
                {(addTabDefaults && onAddTab) && this.renderAddTab()}
              </div>

              <Anchors
                tabGroupId={id}
                onAnchorClick={this.onAnchorClickHandler}
                selectedAnchor={selectedAnchor}
                unshiftLastAnchor={unshiftLastAnchor}
                ifFixed
              />
            </div>
          )}

          <div
            className={classNames(
              `${classNameTabs}__wrapper`,
              summary && `${classNameTabs}__wrapper--with-summary`,
            )}
          >
            <div className={classNames(`${classNameTabs}__content`)}>{this.renderContent()}</div>
            {(summary && !tabData.hideSummary) && <div className={`${classNameTabs}__content__summary`}>{summary}</div>}
          </div>
        </div>
      </TabsContext.Provider>
    ) : null;
  }
}

export default Tabs;
