import * as React from 'react';
import { CheckboxPrimitive } from 'framework/components/ui/FormComponents/Primitives';
import { prefixClassName } from 'framework/components/ui/_conf';
import { FilterTabs } from '../../context';
// eslint-disable-next-line import/no-cycle
import {
  ButtonList,
  Message,
  Text,
} from '../../..';
import './_styles.scss';
import { TabButton } from '../TabButton/TabButton';

export type ColumnKey = string | number;
interface HideColumnsProps {
  hidden: ColumnKey[];
  onToggle: (index: ColumnKey) => void;
  columns: { caption: string; key: ColumnKey; canHide: boolean; }[];
  onReset: () => void;
  selectableTable?: boolean;
}

const clxColumns = prefixClassName('filter-bar__columns');
const clxColumnsList = `${clxColumns}__list`;
const clxColumnsListItem = `${clxColumnsList}__item`;
const clxColumnsButtonsBar = `${clxColumns}__buttons-bar`;
const clxColumnsButtonsBarTotal = `${clxColumnsButtonsBar}__total`;

export const HideColumnsPanel = ({
  hidden = [],
  columns = [],
  onToggle,
  onReset,
  selectableTable = false,
}: HideColumnsProps) => (
  <>
    <Message
      type="info"
      caption="generic.columnsHint"
      showLabel={false}
      className="mrg-btm-20"
    />
    <ul className={clxColumnsList}>
      {columns.map((c, i) => {
        const checkBoxIndex = selectableTable ? i + 1 : i;

        return (
          <li key={c.key} className={clxColumnsListItem}>
            <CheckboxPrimitive
              name={`${checkBoxIndex}`}
              disabled={!c.canHide}
              value={!hidden.includes(checkBoxIndex)}
              label={c.caption}
              onChange={() => onToggle(checkBoxIndex)}
            />
          </li>
        );
      })}
    </ul>
    <div className={clxColumnsButtonsBar}>
      <div className={clxColumnsButtonsBarTotal}>
        <Text
          caption="generic.tableColumnsFilter.columnsCount"
          i18nOptions={{
            num: columns.length - hidden.length,
            tot: columns.length,
          }}
        />
      </div>
      <ButtonList
        buttons={[
          {
            caption: 'generic.button.reset',
            onClick: onReset,
            outline: true,
          },
        ]}
        textRight
      />
    </div>
  </>
);

export const HideColumnsTab = () => (
  <TabButton
    caption="generic.columns"
    className={clxColumns}
    tab={FilterTabs.Columns}
  />
);
