import * as utils from '../utils';

export const removeRow = (state, action) => {  
  return {
    ...state,
    loadedPage: {
      ...state.loadedPage,
      cmsContent: {
        ...state.loadedPage.cmsContent,
        rows: utils.RemoveCmsPageRow(state.loadedPage.cmsContent.rows, action.payload.rowIndex)
      }
    }
  };
};
