import { autoEffect, store } from '@risingstack/react-easy-state';
import type { PaginationStore } from './Pagination.types';

const validateDeps = (...args) => args;
type OnPageChangedFn = (skipTake: { skip: number, take: number }) => void;

export type PaginationStoreArgs = {
  skip?: number,
  take?: number,
  count?: number,
  total?: number,
  onPageChanged?: OnPageChangedFn;
};

export interface PaginationStoreSkipTake {
  props: PaginationStore;
  skip: number;
  take: number;
  updatePagination: (args: PaginationStoreArgs) => void;
  onPageChanged: (fn: OnPageChangedFn) => void;
}

const getPage = (s, t) => Math.ceil((s + t) / t);
const isDefined = (value) => value !== undefined && value !== null;

export const createPaginationStore = ({
  skip = 0,
  take = 10,
  count,
  total,
  onPageChanged,
}: PaginationStoreArgs): Readonly<PaginationStoreSkipTake> => {
  let paginationChanged: OnPageChangedFn = onPageChanged;

  const localStore = store<PaginationStoreSkipTake>({
    props: {
      pageSize: take,
      currentPage: getPage(skip, take),
      recordCount: total,
      visibleRecordsCount: count,
      onGetPage(page) {
        if (page > 0) {
          localStore.props.currentPage = page;
          localStore.skip = (page - 1) * localStore.take;
        }
      },
      onNext() {
        localStore.props.onGetPage(localStore.props.currentPage + 1);
      },
      onPrevious() {
        localStore.props.onGetPage(localStore.props.currentPage - 1);
      },
      onSetPageSizeHandler(option) {
        if (option?.value) {
          localStore.props.onSetPageSize(option.value);
        }
      },
      onSetPageSize(pageSize: number | string) {
        const newPageSize = Number(pageSize);
        localStore.take = newPageSize;
        localStore.props.pageSize = newPageSize;
        localStore.props.onGetPage(1);
      },
      onLoadMore() {
        localStore.take += localStore.props.pageSize;
      },
      onViewAll() {
        localStore.skip = 0;
        localStore.take = localStore.props.recordCount;
      },
    },
    skip,
    take,
    updatePagination(args) {
      const newSkip = isDefined(args.skip) ? args.skip : localStore.skip;
      const newTake = isDefined(args.take) ? args.take : localStore.take;
      localStore.skip = newSkip;
      localStore.take = newTake;
      localStore.props.pageSize = newTake;
      localStore.props.currentPage = getPage(newSkip, newTake);
      localStore.props.recordCount = isDefined(args.total) ? args.total : localStore.props.recordCount;
      localStore.props.visibleRecordsCount = isDefined(args.count) ? args.count : localStore.props.visibleRecordsCount;
    },
    onPageChanged(fn) {
      paginationChanged = fn;
    },
  });

  autoEffect(() => {
    validateDeps(localStore.skip, localStore.take);
    if (paginationChanged) {
      paginationChanged({
        skip: localStore.skip,
        take: localStore.take,
      });
    }
  }, [localStore.skip, localStore.take]);

  return localStore;
};
