import { put, takeEvery, select } from 'redux-saga/effects';
import { calendarActions, modalActions } from 'framework/actions';
import { formSelectors } from 'framework/selectors';
import { VIEWCALENDAR_SLOT_ADD } from '../actions.constants';
import * as CONST_PAGE from '../../constants';
import { selectors } from '..';
import { liveCalendarBulkActionStore } from '../../pageParts/content';
import { SlotBulkActions } from '../../pageParts/constants';

export function* process() {
  const formValues = yield select(formSelectors.getFormValues, CONST_PAGE.FORM_EDIT_SLOT);
  const selectedSlot: any = yield select(selectors.getEventSelected);
  const selectedSlotData: any = yield select(selectors.getEventSelectedData);

  const capacity = formValues[CONST_PAGE.FIELD_EDIT_SLOT_CAPACITY];
  const closeSlotNote = formValues[CONST_PAGE.FIELD_CLOSE_SLOT_NOTE];

  const updatedSlot: any = {
    ...selectedSlot,
    data: {
      ...selectedSlotData,
      capacity,
    },
  };

  const updatedSlots = liveCalendarBulkActionStore?.selectedSlots?.map((slot) => {
    const data = { ...slot.data };

    switch (liveCalendarBulkActionStore.action) {
      case SlotBulkActions.EditTotalCapacity:
        data.capacity = capacity;
        break;
      case SlotBulkActions.CloseSlot:
        data.isClosed = true;
        data.isClosedNotes = closeSlotNote;
        break;
      case SlotBulkActions.OpenSlot:
        data.isClosed = false;
        data.isClosedNotes = closeSlotNote;
        break;
    }

    return { ...slot, data };
  });

  yield put(modalActions.createCloseModalAction(CONST_PAGE.MODAL_EDIT_SLOT));
  yield put(modalActions.createCloseModalAction(CONST_PAGE.MODAL_BULK_EDIT_SLOT_CAPACITY));

  if (updatedSlots?.length > 0 && Object.keys(selectedSlot).length < 1) {
    yield put(calendarActions.addEvents(CONST_PAGE.CALENDAR_NAME, updatedSlots));
    liveCalendarBulkActionStore.selectedSlots = [];
    liveCalendarBulkActionStore.action = null;
  } else {
    yield put(calendarActions.addEvent(CONST_PAGE.CALENDAR_NAME, updatedSlot));
  }
}

export default function* watchEditCalendarSlotAction() {
  yield takeEvery(VIEWCALENDAR_SLOT_ADD, process);
}