import { selectAccessToken } from 'framework/authorization';
import store from 'framework/store';
import {
  ContentTypes,
  IFetchDataRequest,
  IFetchDataResponse,
  GridResponseData,
  CustomHeaders,
  MetadataObject,
} from './types';
import { handleError } from './fetchError';
import { Features, isFeatureEnabled } from '../../stores/features';
import { getGrowthBookOverrideFlags } from '../../growthbook/getGrowthBookOverrideFlags';

export { ContentTypes };
export type { IFetchDataRequest };

export type { IFetchDataResponse };
export type { GridResponseData };

const getQueryStringValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((v) => encodeURIComponent(v)).join(',');
  }
  return encodeURIComponent(value);
};

const getQueryStringKeyPairValue = (key, value) => `${encodeURIComponent(key)}=${getQueryStringValue(value)}`;

const filterValues = (value) => {
  if (value === null || value === undefined) {
    return false;
  }
  if (Array.isArray(value) && !value.length) {
    return false;
  }
  return true;
};

const getQueryString = (obj) =>
  (obj &&
    `?${Object.keys(obj)
      .filter((key) => filterValues(obj[key]))
      .map((key) => getQueryStringKeyPairValue(key, obj[key]))
      .join('&')}`) ||
  '';

const getMetadata = (response: Response): MetadataObject => ({
  eTag: response.headers.get('etag'),
});

/**
 * Handle success returned from the api Gateway
 * @param data
 */
export function handleSuccess<T>(data, metadata, status): IFetchDataResponse<T> {
  return {
    success: true,
    // FIXME cant do this! for retro compatibility only
    data,
    metadata,
    status,
  };
}

/**
 * Returns the options used by fetch
 */
const getCommonOptions = (request: IFetchDataRequest, accessToken, body) => {
  const options = {
    method: request.method || 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...request.headers,
    },
    body,
  };

  if (request.eTag) {
    options.headers['If-Match'] = request.eTag;
  }

  if (isFeatureEnabled(Features.AllowFeatureFlagsHeaderOverride)) {
    const growthBookFlagOverrides = getGrowthBookOverrideFlags(window?.navigator?.userAgent);
    if (growthBookFlagOverrides) {
      options.headers['X-AQA-Feature-Flags-Override'] = growthBookFlagOverrides;
    }
  }
  return options;
};

const getOptionsForFetchData = (request, accessToken) => {
  const options = getCommonOptions(request, accessToken, JSON.stringify(request.body));
  options.headers['Content-Type'] =
    request.contentType || (options.method === 'PATCH' ? ContentTypes.PatchJson : ContentTypes.Json);
  return options;
};

const getOptionsForMultiData = (request, accessToken) => getCommonOptions(request, accessToken, request.body);
const getOptionsForDowloadFile = (fileType: ContentTypes) => {
  const customHeaders: CustomHeaders = {};
  customHeaders.responseType = fileType;
  return (request, accessToken) => {
    const options = getOptionsForFetchData(request, accessToken);
    options.headers = { ...options.headers, ...customHeaders };
    return options;
  };
};

async function fetchDataCommon<T, E>(
  request: IFetchDataRequest,
  getOptions,
  token?: string
): Promise<IFetchDataResponse<T, E>> {
  let result = '';
  let output;
  let httpStatus = -1;
  let metadata: MetadataObject;

  try {
    const accessToken = token ?? selectAccessToken(store.getState());
    // Prepares the query string params
    const queryString = getQueryString(request.query);
    // builds the url with the query string
    const resourceUrl = request.mock ? `/__mocks__/${request.mock}.json` : request.url;
    const url = `${resourceUrl}${queryString}`;

    // fetchs the api
    const fetchOptions = getOptions(request, accessToken);

    const response = await fetch(url, fetchOptions);

    // parses the response
    httpStatus = response.status;
    metadata = getMetadata(response);

    switch (fetchOptions.headers.responseType) {
      case ContentTypes.Apk:
        output = await response.blob();
        break;
      case ContentTypes.PDF:
        output = await response.blob();
        break;

      default:
        result = await response.text();
        try {
          output = result ? JSON.parse(result) : {};
        } catch {
          output = result; // not json
        }
        break;
    }

    // handle success
    if (httpStatus >= 200 && httpStatus < 299) {
      return handleSuccess<T>(output, metadata, httpStatus);
    }

    // handle errors
    return handleError(output, metadata, request, httpStatus, result);
  } catch (e) {
    return handleError(e, metadata, request, httpStatus, result);
  }
}

export async function fetchData<T, E = any>(
  request: IFetchDataRequest<E>,
  token?: string
): Promise<IFetchDataResponse<T, E>> {
  return fetchDataCommon<T, E>(request, getOptionsForFetchData, token);
}

export async function fileUpload<T, E = any>(request: IFetchDataRequest<E>): Promise<IFetchDataResponse<T, E>> {
  return fetchDataCommon<T, E>(request, getOptionsForMultiData);
}
export async function fileDownload<Blob, E = any>(
  request: IFetchDataRequest<E>,
  fileType: ContentTypes
): Promise<IFetchDataResponse<Blob, E>> {
  return fetchDataCommon<Blob, E>(request, getOptionsForDowloadFile(fileType));
}
