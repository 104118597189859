import { AppRouteBase, AppRouteClaims } from 'framework/components/navigation/Navigation.interfaces';
import { AuthorizationClaims } from 'framework/authorization/AuthorizationClaims';
import { UserAccessRights } from 'framework/authorization/Interfaces';
import {  Features } from 'stores/features';
import { ProductTranslations } from './translations';

export const ROOT = '/productManagement';
export const LIST_URL_PATH = '/products';
export const LIST_URL_FULL = `${ROOT}${LIST_URL_PATH}`;
export const CREATE_URL_PARAM = 'create';

const productClaims: AppRouteClaims = {
  [AuthorizationClaims.PRODUCTS_PRODUCT]: UserAccessRights.ReadWrite,
  [AuthorizationClaims.STORES_ORGHIERARCHY]: UserAccessRights.Read,
};

export const VIEW_PRODUCT_CLAIM = {
  label: ProductTranslations.ViewProducts,
  claims: productClaims,
};

export const REQUEST_PRODUCT_RESEND = {
  label: ProductTranslations.BrandbankControls,
  claims: {
    [AuthorizationClaims.PRODUCT_REQUEST_PRODUCT_RESEND]: UserAccessRights.ReadWrite,
  },
};

export const MANAGE_ATTRIBUTES_CLAIM = {
  label: ProductTranslations.ManageAttributeOption,
  claims: {
    [AuthorizationClaims.PRODUCTS_ATTRIBUTE]: UserAccessRights.ReadWrite,
  },
};

export const MANAGE_OUT_OF_STOCK_CLAIM = {
  label: ProductTranslations.ManageOutOfStock,
  claims: {
    [AuthorizationClaims.PRODUCT_MANAGE_OUT_OF_STOCK]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.PRODUCTS_ATTRIBUTE]: UserAccessRights.ReadWrite,
  },
};

export const MANAGE_OUT_OF_STOCK_UNTIL_CLAIM = {
  label: ProductTranslations.ManageOutOfStockUntil,
  claims: {
    [AuthorizationClaims.PRODUCT_MANAGE_OUT_OF_STOCK_UNTIL]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.PRODUCTS_ATTRIBUTE]: UserAccessRights.ReadWrite,
  },
};

export const MANAGE_PRICING_CLAIM = {
  label: ProductTranslations.ManagePricing,
  claims: {
    [AuthorizationClaims.PRODUCTS_MANGE_PRICING]: UserAccessRights.ReadWrite,
  },
};

export const MANAGE_CUSTOMIZATIONS_CLAIM = {
  label: ProductTranslations.ManageCustomizations,
  claims: {
    [AuthorizationClaims.PRODUCTS_MANAGE_CUSTOMIZATIONS]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.DAM]: UserAccessRights.ReadWrite,
  },
};

export const MANAGE_UNIT_PRICE_CLAIM = {
  label: ProductTranslations.ManageUnitPrice,
  claims: {
    [AuthorizationClaims.PRODUCTS_MANAGE_UNIT_PRICE]: UserAccessRights.ReadWrite,
    [AuthorizationClaims.PRODUCTS_MANGE_PRICING]: UserAccessRights.ReadWrite,
  },
};
// routes
export const productsListRoute: AppRouteBase = {
  menuTitle: ProductTranslations.AllProducts,
  pageTitle: ProductTranslations.Products,
  path: '/productManagement/products',
  authClaim: productClaims,
  extendedClaims: [
    VIEW_PRODUCT_CLAIM,
    MANAGE_PRICING_CLAIM,
    MANAGE_UNIT_PRICE_CLAIM,
    MANAGE_CUSTOMIZATIONS_CLAIM,
  ],
  claimUsedByFeatures: [
    {
      feature: Features.ShoppingRulesOption,
      extendedClaims: [MANAGE_ATTRIBUTES_CLAIM, MANAGE_OUT_OF_STOCK_CLAIM, MANAGE_OUT_OF_STOCK_UNTIL_CLAIM],
    },
    { feature: Features.Brandbank, extendedClaims: [REQUEST_PRODUCT_RESEND] },
  ],
  alwaysShowInDev: true,
};

export const productsCreateRoute: AppRouteBase = {
  pageTitle: ProductTranslations.CreateNewProduct,
  path: '/productManagement/products/create',
  authClaim: productClaims,
  parent: productsListRoute,
};

export const productsEditRoute: AppRouteBase = {
  pageTitle: ProductTranslations.Product,
  path: '/productManagement/products/:id',
  authClaim: productClaims,
  parent: productsListRoute,
};
