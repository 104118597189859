import { safeGet } from 'framework/utils';

export default (
  state,
  payload: {
    calendarName: string;
    view: string;
  }
) => {
  const { calendarName, view = 'week' } = payload;
  const stateCalendar = safeGet(state, calendarName, {});

  return {
    ...state,
    [calendarName]: {
      ...stateCalendar,
      settings: {
        ...stateCalendar.settings,
        view
      }
    }
  };
};
