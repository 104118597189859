import * as React from 'react';
import {
  Modal,
  Img,
  Header,
  Table,
  Loader,
  Text,
} from 'ui';
import { createOpenModalAction, createCloseModalAction } from 'framework/components/ui/Modal/Modal.actions';
import { MODAL_PRODUCT } from 'framework/modules/searchadmin/constants';
import store from 'framework/store';
import { useFetch } from 'framework/api/useFetch';
import { prefixClassName } from 'framework/components/ui/_conf';
import { ellipsis } from 'framework/utils/string';
import { fetchProduct } from '../../api/product';
import { Product } from '../../models/product';
import { SearchAdminTranslations } from '../../translations';
import './_style.scss';

const clx = prefixClassName('search-product-modal');
const explainerClx = `${clx}__explainer`;

interface ProductModalProps {
  sku: string;
  storeId: string;
  onDismiss?: () => void;
}

export const openProductModal = () => store.dispatch(createOpenModalAction(MODAL_PRODUCT));
const closeProductModal = () => store.dispatch(createCloseModalAction(MODAL_PRODUCT));

export const ProductModal = React.memo(({ sku, storeId, onDismiss }: ProductModalProps) => {
  const { isFetching, data } = useFetch(() => fetchProduct(sku, storeId), [sku, storeId]);
  const onDismissHandler = () => {
    closeProductModal();
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <Modal
      onDismiss={onDismissHandler}
      name={MODAL_PRODUCT}
      caption={(data && `${data.brand} ${data.name}`) || ''}
      footerOptions={[
        {
          caption: 'generic.button.close',
          onClick: closeProductModal,
          outline: true,
        },
      ]}
    >
      {isFetching && <Loader />}
      {data && (
        <>
          <Header headerLevel={3} caption={SearchAdminTranslations.ProductInformation} />
          <div className={clx}>
            <div>
              <Img src={data.image} alt={data.name} width="180px" height="180px" />
            </div>

            <div>
              <Table
                columnMetadata={[
                  SearchAdminTranslations.SKU,
                  SearchAdminTranslations.UnitOfSize,
                  SearchAdminTranslations.Brand,
                ]}
                data={[data]}
                cellRenderer={(_t, d: Product) => (
                  <Table.Row>
                    <Table.Column>{d.sku}</Table.Column>
                    <Table.Column>{d.unitOfSize?.size} {d.unitOfSize?.type}</Table.Column>
                    <Table.Column>{d.brand}</Table.Column>
                  </Table.Row>
                )}
              />
              <Table
                columnMetadata={[
                  SearchAdminTranslations.Price,
                  SearchAdminTranslations.PricePerUnit,
                  SearchAdminTranslations.Promotion,
                ]}
                data={[data]}
                cellRenderer={(_t, d: Product) => (
                  <Table.Row>
                    <Table.Column>
                      {(d.store?.variantPrices && d.store?.variantPrices[0]?.toFixed(2)) || ''}
                      {!!d.store?.wasPrice && (
                        <Text
                          caption={SearchAdminTranslations.WasPrice}
                          i18nOptions={{ price: d.store.wasPrice.toFixed(2) }}
                          tag="span"
                          className={explainerClx}
                        />
                      )}
                    </Table.Column>
                    <Table.Column />
                    <Table.Column />
                  </Table.Row>
                )}
              />
              <Table
                columnMetadata={[
                  { caption: SearchAdminTranslations.ShortDescription, id: 'shortDescription' },
                  { caption: SearchAdminTranslations.LongDescription, id: 'longDescription' },
                ]}
                data={[data]}
              />
              <Table
                columnMetadata={[
                  SearchAdminTranslations.DefaultCategory,
                  SearchAdminTranslations.CategoryBreadcrumb,
                  SearchAdminTranslations.AllCategories,
                ]}
                data={[data]}
                cellRenderer={(_t, d: Product) => (
                  <Table.Row>
                    <Table.Column>
                      {(d.store?.defaultCategory && d.store?.defaultCategory[0].category) || ''}
                    </Table.Column>
                    <Table.Column>
                      {(d.store?.defaultCategory && d.store?.defaultCategory[0].categoryBreadcrumb) || ''}
                    </Table.Column>
                    <Table.ColumnSummarizer
                      caption={SearchAdminTranslations.CountCategories}
                      items={d.store?.categories}
                      render={(i) => i.categoryBreadcrumb}
                      maxItems={d.store?.categories.length}
                    />
                  </Table.Row>
                )}
              />
            </div>
          </div>

          <Table
            columnMetadata={[
              SearchAdminTranslations.Keywords,
              SearchAdminTranslations.Attributes,
            ]}
            data={[data]}
            cellRenderer={(_t, d: Product) => (
              <Table.Row>
                <Table.Column>{data.keywords}</Table.Column>
                <Table.Column>
                  {Object.keys(d.attributes).map((attr) => (
                    <>
                      <b>{attr}:</b>&nbsp;<span>{ellipsis(`${d.attributes[attr]}`, 100)}</span>&nbsp;
                    </>
                  ))}
                </Table.Column>
              </Table.Row>
            )}
          />
          <Header headerLevel={3} caption={SearchAdminTranslations.Metrics} />
          <Table
            columnMetadata={[
              SearchAdminTranslations.UnitSales,
              SearchAdminTranslations.Revenue,
              SearchAdminTranslations.Views,
              SearchAdminTranslations.Clicks,
            ]}
            data={[data]}
            cellRenderer={(_t, d: Product) => (
              <Table.Row>
                <Table.Column>{d.store?.ecommerceOffline?.unitSales}</Table.Column>
                <Table.Column>{d.store?.ecommerceOffline?.revenue}</Table.Column>
                <Table.Column>{d.store?.ecommerceOffline?.views}</Table.Column>
                <Table.Column>{d.store?.ecommerceOffline?.clicks}</Table.Column>
              </Table.Row>
            )}
          />
          <Table
            columnMetadata={[
              SearchAdminTranslations.Margin,
              SearchAdminTranslations.Other,
            ]}
            data={[data]}
            cellRenderer={(_t, d: Product) => (
              <Table.Row>
                <Table.Column>{d.store?.ecommerceOffline?.margin}</Table.Column>
                <Table.Column>{d.store?.ecommerceOffline?.other}</Table.Column>
              </Table.Row>
            )}
          />
        </>
      )}
    </Modal>
  );
});
