import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { SVGProps } from './SVG.interfaces';

export const SVG = (props: SVGProps) => {
  const {
    src, alt, className, ClassNameSVG,
  } = props;

  return <ReactSVG src={src} className={className} svgClassName={ClassNameSVG} title={alt} />;
};
