import * as React from 'react';
import withTooltip from 'framework/components/hoc/withTooltip';
import { i18nWithParams } from 'i18n';
import classNames from 'classnames';
import { Icon, Text, Button } from 'ui';
import { prefixClassName } from '../_conf';
import { SummarizerProps } from './Summarizer.interfaces';
import './styles.scss';

const summarizerClass = prefixClassName('summarizer');

const DefaultRender = (item) => <Text caption={item} />;

const SummarizerBody = ({ caption, items }: SummarizerProps<any>) => (
  <>
    <span>{i18nWithParams(caption, { count: items.length })}</span>
    <Icon className={`${summarizerClass}__icon`} name="info" />
  </>
);
const SummaryzerWithTooltip = withTooltip(SummarizerBody);

const Summarizer = (props: SummarizerProps<any>) => {
  const {
    items,
    render = DefaultRender,
    onViewAll,
    maxItems = 3,
    alwaysWrap = false,
    title,
    position = 'up-right',
    tooltipClassName,
  } = props;
  const itemsSize = items?.length;

  if (!itemsSize) {
    return null;
  }
  if (!alwaysWrap && itemsSize === 1) {
    return render(items[0]);
  }

  return (
    <SummaryzerWithTooltip
      className={summarizerClass}
      {...props}
      position={position}
      tooltipBody={
        <div className={classNames(`${summarizerClass}__tooltip`, tooltipClassName)}>
          {title && <Text tag="div" caption={title} className={`${summarizerClass}__tooltip__title`} />}
          <ul className={`${summarizerClass}__tooltip__list`}>
            {items.slice(0, maxItems).map((i, index) => (
              <li key={index} className={`${summarizerClass}__tooltip__list__item`}>
                {render(i, index)}
              </li>
            ))}
          </ul>
          <Button
            show={itemsSize > maxItems}
            caption="generic.viewAll"
            className={`${summarizerClass}__tooltip__view-all`}
            onClick={onViewAll}
            styleless
          />
        </div>
      }
    />
  );
};

export { Summarizer };
