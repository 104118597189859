import config from 'config';
import { fetchData, IFetchDataResponse } from 'framework/api/fetch-data';
import { AuthorizationClaims, ensureClaims, UserAccessRights } from 'framework/authorization';
import { DeliveryIntegrationProviders, FeatureState, DeliveryIntegrationProviderNames } from './types';

export const isZetesIntegrationEnabled= async (
  providers: IFetchDataResponse<DeliveryIntegrationProviders, any>,
): Promise<FeatureState> => {
  const userHasAccess = ensureClaims({
    [AuthorizationClaims.ORDERMANAGEMENT_DELIVERYINTEGRATION]: UserAccessRights.Read,
  });

  if (!userHasAccess) {
    return {
      isEnabled: false,
    };
  }
 
  if (providers.data?.deliveryProviders?.find(
    (e) => e.providerName.toLowerCase() === DeliveryIntegrationProviderNames.ZetesIntegration,
  )) {
    const result = await fetchData<{enabled: boolean}>({
      url: config.apiEndpoints.zetesIntegration.checkEnabled,
    });
    return {
      isEnabled: result.data?.enabled && !!result.data,
    };
  }
  return {
    isEnabled: false
  };
};
